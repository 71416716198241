import API from '../api/api';

import { timerStart, timerStop } from './timers';
import { callToWithParams } from './twilio';
import { CHANGE_CALL_STATUS } from './clientChats';
import { isMineInteraction } from '../utils';
import { CHANGE_CHAT_CALL_STATUS } from './roomChats';
import { closeModal, openModal, MODAL_TYPES } from './activeWindows';
import { acceptWarmTransfer, CONFERENCE, acceptConference, WARM_TRANSFER } from './conferences';


export const NEW_INCOMING_CALL = 'NEW_INCOMING_CALL';
export const NEW_OUTGOING_CALL = 'NEW_OUTGOING_CALL';

export const NEW_INCOMING_OPERATOR_CALL = 'NEW_INCOMING_OPERATOR_CALL';
export const NEW_OUTGOING_OPERATOR_CALL = 'NEW_OUTGOING_OPERATOR_CALL';

export const FINISH_CALL = 'FINISH_CALL';
export const NEW_ACTIVE_CALL = 'NEW_ACTIVE_CALL';
export const ADD_CONNECTED_CALLER = 'ADD_CONNECTED_CALLER';

export const CALL_ON_HOLD = 'CALL_ON_HOLD';
export const UNHOLD_CALL = 'UNHOLD_CALL';
export const FINISH_CALL_ON_HOLD = 'FINISH_CALL_ON_HOLD';

export const TOGGLE_MUTE_ACTIVE_CALL = 'TOGGLE_MUTE_ACTIVE_CALL';

export const CREATE_TRANSFERED_CALL = 'CREATE_TRANSFERED_CALL';
export const NEW_TRANSFERED_CALL = 'NEW_TRANSFERED_CALL';
export const ACCEPT_TRANSFERED_CALL = 'ACCEPT_TRANSFERED_CALL';
export const REMOVE_TRANSFERED_CALL = 'REMOVE_TRANSFERED_CALL';

export const NEW_INCOMING_QUEUE_CALL = 'NEW_INCOMING_QUEUE_CALL';
export const ACCEPT_INCOMING_QUEUE_CALL = 'ACCEPT_INCOMING_QUEUE_CALL';
export const REMOVE_INCOMING_QUEUE_CALL = 'REMOVE_INCOMING_QUEUE_CALL';

export const GET_ALL_CALLS = 'GET_ALL_CALLS';
export const GET_CONNECTED_CONTACTS = 'GET_CONNECTED_CONTACTS';


// // export const NEW_WARM_TRANSFER = 'NEW_WARM_TRANSFER';
// export const ACCEPT_WARM_TRANSFER = 'ACCEPT_WARM_TRANSFER';
// export const CONFERENCE_PARTICIPANT_JOIN = 'CONFERENCE_PARTICIPANT_JOIN';
// export const CONFERENCE_PARTICIPANT_LEAVE = 'CONFERENCE_PARTICIPANT_LEAVE';
// export const REMOVE_CONFERENCE = 'REMOVE_CONFERENCE';


export const getAllCalls = () => dispatch => {
  API.getAllCalls()
    .then(res => {
      if (Object.keys(res.data).length) {
        dispatch({
          type: GET_ALL_CALLS,                                     //backend return missed array sorted by date created
          payload: res.data
        });
      }
      // start timers for gotten calls (main and queue timer)
      if (res.data.transfers) {
        Object.values(res.data.transfers).forEach(call => {
          dispatch(timerStart(call.id, call.callBegin));
          dispatch(timerStart('queue_' + call.id, call.queueData.startedAt));
        });
      }

      if (res.data.hold) {
        Object.values(res.data.hold).forEach(call => {
          dispatch(timerStart(call.id, call.callBegin));
          dispatch(timerStart('queue_' + call.id, call.queueData.startedAt));
        });
      }
      if (res.data.incomingQueue) {
        Object.values(res.data.incomingQueue).forEach(call => {
          dispatch(timerStart(call.id, call.callBegin));
        });
      }
    })
    .catch(err => {
      console.log(err);
    });
};

export const getConnectedContacts = () => (dispatch, getState) => {
  API.getConnectedContacts()
    .then(res => {
      if (!res.data.length) {
        return;
      }

      const profileId = getState().user.id;
      const connectedContacts = {};

      res.data.forEach(call => (
        connectedContacts[call.callerId] = {
          ...call,
          isMine: isMineInteraction(profileId, call.userId)
        }
      ));

      dispatch({
        type: GET_CONNECTED_CONTACTS,
        payload: connectedContacts
      });
    })
    .catch(console.error);
};

export const newOutgoingCall = (call, userTimezone) => dispatch => {
  dispatch({
    type: NEW_OUTGOING_CALL,
    payload: {
      interaction: call,
      userTimezone
    }
  });
};

export const newIncomingCall = (call, userTimezone) => dispatch => {
  dispatch({
    type: NEW_INCOMING_CALL,
    payload: {
      interaction: call,
      userTimezone
    }
  });
};

export const finishCall = () => dispatch => {
  dispatch(closeModal('full-screen-call'));
  dispatch({ type: FINISH_CALL });
};

// TODO: remove this action
export const newActiveCall = (call) => dispatch => {
  dispatch({
    type: NEW_ACTIVE_CALL,
    payload: call
  });
};

export const newOutgoingOperatorCall = (call, userTimezone) => dispatch => {
  dispatch({
    type: NEW_OUTGOING_OPERATOR_CALL,
    payload: {
      interaction: call,
      userTimezone
    }
  });
};

export const newIncomingOperatorCall = (call, userTimezone) => dispatch => {
  dispatch({
    type: NEW_INCOMING_OPERATOR_CALL,
    payload: {
      interaction: call,
      userTimezone
    }
  });
};

export const putCallOnHold = (call) => dispatch => {
  API.putCallOnHold(call.id)
    .then(() => {
      dispatch({
        type: CALL_ON_HOLD,
        payload: { ...call, heldAt: Date.now() }
      });
      dispatch(timerStart('queue_' + call.id));
    })
    .catch(err => console.log(err));
};

export const unholdCall = (call) => dispatch => {
  dispatch({
    type: UNHOLD_CALL,
    payload: call
  });
  dispatch(timerStop('queue_' + call.id));
};

export const finishCallOnHold = (callId) => dispatch => {
  dispatch({
    type: FINISH_CALL_ON_HOLD,
    payload: callId
  });
  dispatch(timerStop('queue_' + callId));
};

export const hangupCallOnHold = (call) => {
  API.hangUpCallOnHold(call.id)
    .then(res => {
      console.log('all right!!', res);
    })
    .catch(err => console.log(err));
};

export const acceptTransferedCall = (call) => (dispatch, getState) => {
  const device = getState().twilio.device;
  const { audio, activeModals } = getState().activeWindows;

  if (audio && !audio.paused) {
    audio.pause();
    audio.currentTime = 0;
  }

  if (call.type === WARM_TRANSFER) {
    device.connect(callToWithParams('accept_warm_transfer', call.id));
    dispatch(acceptWarmTransfer(call));
    // dispatch(timerStart(call.id));

    return;
  }
  else if (call.type === CONFERENCE) {
    device.connect(callToWithParams('accept_conference', call.id));
    dispatch(acceptConference(call));
    // dispatch(timerStart(call.id));

    if (!activeModals.find(modal => modal.type === MODAL_TYPES.conferenceFullMode)) {
      dispatch(openModal(MODAL_TYPES.conferenceFullMode, { conferenceId: call.id }));
    }

    return;
  }

  device.connect(callToWithParams('accept_transfer', call.id));

  dispatch({
    type: ACCEPT_TRANSFERED_CALL,
    payload: call
  });

  dispatch(timerStop('queue_' + call.id));
  dispatch(timerStart(call.id));
};

export const createTransferedCall = (call, info) => dispatch => {
  return API.createTransferredCall(call.id, info)
    .catch(err => console.log(err));
};

export const createWarmTransferCall = (call, info) => dispatch => {
  return API.createWarmTransferCall(call.id, info)
    .catch(err => console.log(err));
};

export const newTransferedCall = (call) => dispatch => {
  dispatch({
    type: NEW_TRANSFERED_CALL,
    payload: call
  });
  dispatch(timerStart(call.id, call.callBegin));
  dispatch(timerStart('queue_' + call.id));
};

export const removeTransferedCall = (callId) => dispatch => {
  dispatch({
    type: REMOVE_TRANSFERED_CALL,
    payload: callId
  });
  dispatch(timerStop(callId));
  dispatch(timerStop('queue_' + callId));
};

export const newIncomingQueueCall = (call) => dispatch => {
  dispatch({
    type: NEW_INCOMING_QUEUE_CALL,
    payload: { interaction: call }
  });
  dispatch(timerStart(call.id, call.callBegin));
};

export const acceptIncomingQueueCall = (call) => dispatch => {
  dispatch({
    type: ACCEPT_INCOMING_QUEUE_CALL,
    payload: call
  });
};

export const removeIncomingQueueCall = (callId) => dispatch => {
  dispatch({
    type: REMOVE_INCOMING_QUEUE_CALL,
    payload: callId
  });
  dispatch(timerStop(callId));
};

export const toggleMuteActiveCall = (isMute) => dispatch => {
  dispatch({
    type: TOGGLE_MUTE_ACTIVE_CALL,
    payload: isMute
  });
};

// export const newWarmTransfer = (conf) => dispatch => {
//   dispatch({
//     type: NEW_WARM_TRANSFER,
//     payload: conf
//   });
// }

// export const acceptWarmTransfer = (conf) => dispatch => {
//   dispatch({
//     type: ACCEPT_WARM_TRANSFER,
//     payload: conf
//   });
// }

// export const conferenceParticipantJoin = (conf) => dispatch => {
//   dispatch({
//     type: CONFERENCE_PARTICIPANT_JOIN,
//     payload: conf
//   });
// }

// export const conferenceParticipantLeave = (conf) => dispatch => {
//   dispatch({
//     type: CONFERENCE_PARTICIPANT_LEAVE,
//     payload: conf
//   });
// }

// export const removeConference = (confId) => dispatch => {
//   dispatch({
//     type: REMOVE_CONFERENCE,
//     payload: confId
//   });
// }



const initialState = {
  incomingCall: false,
  outgoingCall: false,
  callInProcess: false,

  activeCall: {
    // attachments: null,
    // body: null,
    // callBegin: "2019-04-24 06:20:14",
    // caller: { adr: null, date_created: null, email: null, fn: "Vlad MTS", id: 2, type: 9, callerTags: [] },
    // caller_id: 2,
    // dateCreated: "2019-04-24 06:20:04",
    // description: null,
    // direction: null,
    // duration: null,
    // hasUrls: 0,
    // id: 1650,
    // isRead: 1,

    // media_url: null,
    // onTransfer: 1,
    // queueData: { description: "d f;laksj d", sender: { username: "aristotel" }, sender_id: 2, recipient: null, startedAt: "2019-04-24 06:20:20", type: 2 },
    // status: "in-progress",
    // type: 9,
    // user: { id: 2, username: "admin", photo: "https://s3.eu-west-2.amazonaws.com/cdn.commdiva.ab…nnect-user-2.png?e43171c600355c0698dcc33b10753e0d", unreadCount: 0, status: "busy" },
    // user_id: 2,
    // isMute: false
  },

  connectedCaller: {},

  connectedContacts: {
    // 16: {
    //   callId: 12767,
    //   callerId: 16,
    //   duration: null,
    //   isMine: true,
    //   prevStatus: null,
    //   status: "on-hold",
    //   type: 2,
    //   userId: 3,
    // }
  },

  callOnHold: false,

  callsOnHold: {
    // 2: {
    //   attachments: null,
    //   body: null,
    //   callBegin: "2019-04-24 06:20:14",
    //   caller: { id: 2, type: 9, username: "admin", photo: "https://s3.eu-west-2.amazonaws.com/cdn.commdiva.ab…nnect-user-2.png?e43171c600355c0698dcc33b10753e0d", unreadCount: 0, status: "busy" },
    //   // caller: { adr: null, date_created: null, email: null, fn: "admin", id: 2, type: 3 },
    //   caller_id: 2,
    //   dateCreated: "2019-04-24 06:20:04",
    //   description: null,
    //   direction: null,
    //   duration: null,
    //   hasUrls: 0,
    //   id: 2,
    //   isRead: 1,
    //   media_url: null,
    //   onTransfer: 1,
    //   queueData: { description: "d f;laksj d", sender: { username: "aristotel" }, sender_id: 2, recipient: null, startedAt: "2019-04-24 06:20:20", type: 2 },
    //   status: "in-progress",
    //   type: 2,
    //   user: { id: 3, username: "myname", photo: "https://s3.eu-west-2.amazonaws.com/cdn.commdiva.ab…nnect-user-2.png?e43171c600355c0698dcc33b10753e0d", unreadCount: 0, status: "busy" },
    //   user_id: 3
    // }
    // 1: {
    //   attachments: null,
    //   body: null,
    //   callBegin: "2019-04-24 06:20:14",
    //   caller: { adr: null, date_created: null, email: null, fn: "Vlad MTS", id: 691, type: 1 },
    //   caller_id: 691,
    //   dateCreated: "2019-04-24 06:20:04",
    //   description: null,
    //   direction: null,
    //   duration: null,
    //   hasUrls: 0,
    //   id: 1650,
    //   isRead: 1,
    //   media_url: null,
    //   onTransfer: 1,
    //   queueData: { description: "d f;laksj d", sender: { username: "aristotel" }, sender_id: 2, recipient: null, startedAt: "2019-04-24 06:20:20", type: 2 },
    //   status: "in-progress",
    //   type: 2,
    //   user: { id: 2, username: "vlad", photo: "https://s3.eu-west-2.amazonaws.com/cdn.commdiva.ab…nnect-user-2.png?e43171c600355c0698dcc33b10753e0d", unreadCount: 0, status: "busy" },
    //   user_id: 2
    // }
  },

  callOnTransfer: false,

  callsOnTransfer: {
    // 2: {
    //   attachments: null,
    //   body: null,
    //   callBegin: "2019-04-24 06:20:14",
    //   caller: { id: 2, type: 9, username: "admin", photo: "https://s3.eu-west-2.amazonaws.com/cdn.commdiva.ab…nnect-user-2.png?e43171c600355c0698dcc33b10753e0d", unreadCount: 0, status: "busy" },
    //   // caller: { adr: null, date_created: null, email: null, fn: "admin", id: 2, type: 3 },
    //   caller_id: 2,
    //   dateCreated: "2019-04-24 06:20:04",
    //   description: null,
    //   direction: null,
    //   duration: null,
    //   hasUrls: 0,
    //   id: 2,
    //   isRead: 1,
    //   media_url: null,
    //   onTransfer: 1,
    //   queueData: { description: "d f;laksj d", sender: { username: "aristotel" }, sender_id: 2, recipient: null, startedAt: "2019-04-24 06:20:20", type: 2 },
    //   status: "in-progress",
    //   type: 2,
    //   user: { id: 3, username: "myname", photo: "https://s3.eu-west-2.amazonaws.com/cdn.commdiva.ab…nnect-user-2.png?e43171c600355c0698dcc33b10753e0d", unreadCount: 0, status: "busy" },
    //   user_id: 3
    // }
    // 1: {
    //   attachments: null,
    //   body: null,
    //   callBegin: "2019-04-24 06:20:14",
    //   caller: { adr: null, date_created: null, email: null, fn: "Vlad MTS", id: 691, type: 1 },
    //   caller_id: 691,
    //   dateCreated: "2019-04-24 06:20:04",
    //   description: null,
    //   direction: null,
    //   duration: null,
    //   hasUrls: 0,
    //   id: 1650,
    //   isRead: 1,
    //   media_url: null,
    //   onTransfer: 1,
    //   queueData: { description: "d f;laksj d", sender: { username: "aristotel" }, sender_id: 2, recipient: null, startedAt: "2019-04-24 06:20:20", type: 2 },
    //   status: "in-progress",
    //   type: 2,
    //   user: { id: 2, username: "vlad", photo: "https://s3.eu-west-2.amazonaws.com/cdn.commdiva.ab…nnect-user-2.png?e43171c600355c0698dcc33b10753e0d", unreadCount: 0, status: "busy" },
    //   user_id: 2
    // },
    // 2: {
    //   attachments: null,
    //   body: null,
    //   callBegin: "2019-04-24 06:20:14",
    //   caller: { adr: null, date_created: null, email: null, fn: "Vlad MTS", id: 691, type: 1 },
    //   caller_id: 691,
    //   dateCreated: "2019-04-24 06:20:04",
    //   description: null,
    //   direction: null,
    //   duration: null,
    //   hasUrls: 0,
    //   id: 1650,
    //   isRead: 1,
    //   media_url: null,
    //   onTransfer: 1,
    //   queueData: { description: "d f;laksj d", sender: { username: "aristotel" }, sender_id: 2, recipient: null, startedAt: "2019-04-24 06:20:20", type: 2 },
    //   status: "in-progress",
    //   type: 2,
    //   user: { id: 2, username: "vlad", photo: "https://s3.eu-west-2.amazonaws.com/cdn.commdiva.ab…nnect-user-2.png?e43171c600355c0698dcc33b10753e0d", unreadCount: 0, status: "busy" },
    //   user_id: 2
    // }
  },

  incomingCalls: {
    // 2: {
    //   attachments: null,
    //   body: null,
    //   callBegin: "2019-04-24 06:20:14",
    //   caller: { id: 2, type: 9, username: "admin", photo: "https://s3.eu-west-2.amazonaws.com/cdn.commdiva.ab…nnect-user-2.png?e43171c600355c0698dcc33b10753e0d", unreadCount: 0, status: "busy" },
    //   // caller: { adr: null, date_created: null, email: null, fn: "admin", id: 2, type: 3 },
    //   caller_id: 2,
    //   dateCreated: "2019-04-24 06:20:04",
    //   description: null,
    //   direction: null,
    //   duration: null,
    //   hasUrls: 0,
    //   id: 2,
    //   isRead: 1,
    //   media_url: null,
    //   onTransfer: 1,
    //   queueData: { description: "d f;laksj d", sender: { username: "aristotel" }, sender_id: 2, recipient: null, startedAt: "2019-04-24 06:20:20", type: 2 },
    //   status: "online",
    //   type: 2,
    //   user: { id: 3, username: "myname", photo: "https://s3.eu-west-2.amazonaws.com/cdn.commdiva.ab…nnect-user-2.png?e43171c600355c0698dcc33b10753e0d", unreadCount: 0, status: "busy" },
    //   user_id: 3
    // }
  }
};


export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CALLS: {
      return {
        ...state,
        callOnHold: action.payload.hold ? true : false,
        callsOnHold: action.payload.hold || state.callsOnHold,
        callOnTransfer: action.payload.transfers ? true : false,
        callsOnTransfer: action.payload.transfers || state.callsOnTransfer,
        incomingCalls: action.payload.incomingQueue || state.incomingCalls,
      };
    }

    case NEW_INCOMING_OPERATOR_CALL: {
      if (action.payload.interaction.isMine) {
        return state;
      }

      const connectedCaller = action.payload.interaction.caller || action.payload.interaction.sender;

      return {
        ...state,
        incomingCall: true,
        // connectedCaller,
        // activeCall: action.payload.interaction,

        incomingCalls: {
          ...state.incomingCalls,
          [action.payload.interaction.id]: {
            ...action.payload.interaction,
            caller: connectedCaller
          }
        }
      };
    }


    case NEW_INCOMING_CALL: {
      if (!action.payload.interaction.isMine) {
        return state;
      }

      const connectedCaller = action.payload.interaction.caller || action.payload.interaction.sender;

      return {
        ...state,
        incomingCall: true,
        // connectedCaller,
        activeCall: action.payload.interaction,

        incomingCalls: {
          ...state.incomingCalls,
          [action.payload.interaction.id]: {
            ...action.payload.interaction,
            caller: connectedCaller
          }
        }
      };
    }

    case NEW_OUTGOING_OPERATOR_CALL:
    case NEW_OUTGOING_CALL: {
      if (!action.payload.interaction.isMine) {
        return state;
      }

      return {
        ...state,
        outgoingCall: true,
        activeCall: action.payload.interaction,
        callInProcess: true
      };
    }

    case GET_CONNECTED_CONTACTS: {
      return {
        ...state,
        connectedContacts: action.payload
      };
    }

    case CHANGE_CALL_STATUS: {
      const updatedActiveContacts = { ...state.connectedContacts };

      ['completed', 'failed', 'no-answer', 'busy'].indexOf(action.payload.status) !== -1
        ? delete updatedActiveContacts[action.payload.callerId]
        : updatedActiveContacts[action.payload.callerId] = action.payload;

      if (action.payload.isMine && action.payload.prevStatus === 'ringing' && action.payload.status === 'in-progress') {
        let updatedIncomingCalls = state.incomingCalls;
        let updatedActiveCall = state.activeCall;

        if (updatedIncomingCalls[action.payload.callId]) {
          updatedIncomingCalls = { ...state.incomingCalls };

          delete updatedIncomingCalls[action.payload.callId];
        }

        if (action.payload.callId === state.activeCall.id) {
          updatedActiveCall = {
            ...state.activeCall,
            status: action.payload.status
          };
        }

        return {
          ...state,
          activeCall: updatedActiveCall,
          connectedContacts: updatedActiveContacts,
          incomingCalls: updatedIncomingCalls
        };
      }
      else if (action.payload.isMine && action.payload.prevStatus === null && action.payload.status === 'completed') {
        delete updatedActiveContacts[action.payload.callerId];

        return {
          ...state,
          activeCall: {},
          connectedContacts: updatedActiveContacts
        };
      }

      return {
        ...state,
        connectedContacts: updatedActiveContacts
      };
    }

    case CHANGE_CHAT_CALL_STATUS: {
      const updatedActiveContacts = { ...state.connectedContacts };

      ['completed', 'failed', 'no-answer', 'busy'].indexOf(action.payload.status) !== -1
        ? delete updatedActiveContacts[action.payload.senderId]
        : updatedActiveContacts[action.payload.senderId] = action.payload;

      if (!action.payload.isMine && ['connecting', 'in-progress'].includes(action.payload.status)) {
        let updatedIncomingCalls = state.incomingCalls;

        if (updatedIncomingCalls[action.payload.id]) {
          updatedIncomingCalls = { ...state.incomingCalls };

          delete updatedIncomingCalls[action.payload.id];
        }

        return {
          ...state,
          activeCall: action.payload,
          connectedContacts: updatedActiveContacts,
          incomingCalls: updatedIncomingCalls
        };
      }
      else if (action.payload.isMine && ['connecting', 'in-progress'].includes(action.payload.status)) {
        return {
          ...state,
          activeCall: {
            ...state.activeCall,
            status: action.payload.status,
          },
          // callInProcess: true,
          connectedContacts: updatedActiveContacts,
        };
      }

      let updatedIncomingCalls = state.incomingCalls;

      if (!action.payload.isMine && action.payload.status === 'no-answer') {
        if (updatedIncomingCalls[action.payload.id]) {
          updatedIncomingCalls = { ...state.incomingCalls };

          delete updatedIncomingCalls[action.payload.id];
        }
      }

      return {
        ...state,
        connectedContacts: updatedActiveContacts,
        incomingCalls: updatedIncomingCalls,
      };
    }

    case FINISH_CALL: {
      let updatedIncomings = state.incomingCalls;

      if (state.activeCall.id && updatedIncomings[state.activeCall.id]) {
        updatedIncomings = { ...state.incomingCalls };

        delete updatedIncomings[state.activeCall.id];
      }

      return {
        ...state,
        incomingCall: false,
        outgoingCall: false,
        // connectedCaller: {},
        activeCall: {},
        callInProcess: false,

        incomingCalls: updatedIncomings
      };
    }

    case CALL_ON_HOLD: {
      const newCallsOnHold = {
        ...state.callsOnHold,
        [action.payload.id]: action.payload
      };

      return {
        ...state,
        activeCall: {},
        callInProcess: false,
        // connectedCaller: {},
        callOnHold: true,
        callsOnHold: newCallsOnHold,
      };
    }

    case UNHOLD_CALL: {
      const holdLength = Object.keys(state.callsOnHold).length;
      const newCallsOnHold = { ...state.callsOnHold };
      const callOnHold = holdLength <= 1 ? false : true;

      if (holdLength) {
        delete newCallsOnHold[action.payload.id];
      }


      return {
        ...state,
        activeCall: action.payload,
        callInProcess: true,
        // connectedCaller: action.payload.caller,
        callOnHold,
        callsOnHold: newCallsOnHold,
      };
    }

    case FINISH_CALL_ON_HOLD: {
      const newCallsOnHold = { ...state.callsOnHold };

      delete newCallsOnHold[action.payload];

      const callOnHold = Object.keys(newCallsOnHold).length ? true : false;

      return {
        ...state,
        callOnHold,
        callsOnHold: newCallsOnHold
      };
    }

    case NEW_TRANSFERED_CALL: {
      const callWasActive = state.activeCall.id === action.payload.id ? true : false;

      return {
        ...state,
        activeCall: callWasActive ? {} : state.activeCall,
        callInProcess: callWasActive ? false : state.callInProcess,
        callOnTransfer: true,
        callsOnTransfer: {
          ...state.callsOnTransfer,
          [action.payload.id]: action.payload
        }
      };
    }

    case ACCEPT_TRANSFERED_CALL: {
      const transferLength = Object.keys(state.callsOnTransfer).length;
      const newCallsOnTransfer = { ...state.callsOnTransfer };

      delete newCallsOnTransfer[action.payload.id];

      return {
        ...state,
        activeCall: action.payload,
        callInProcess: true,
        // connectedCaller: action.payload.caller,
        callOnTransfer: transferLength <= 1 ? false : true,
        callsOnTransfer: newCallsOnTransfer
      };
    }

    case REMOVE_TRANSFERED_CALL: {
      const transferLength = Object.keys(state.callsOnTransfer).length;
      const newCallsOnTransfer = { ...state.callsOnTransfer };


      if (transferLength) {
        delete newCallsOnTransfer[action.payload];
      }

      return {
        ...state,
        callOnTransfer: transferLength <= 1 ? false : true,
        callsOnTransfer: newCallsOnTransfer,
      };
    }

    case NEW_INCOMING_QUEUE_CALL: {
      return {
        ...state,
        incomingCalls: {
          ...state.incomingCalls,
          [action.payload.interaction.id]: action.payload.interaction
        }
      };
    }

    case ACCEPT_INCOMING_QUEUE_CALL: {
      const updatedIncoming = { ...state.incomingCalls };

      delete updatedIncoming[action.payload.id];

      return {
        ...state,
        incomingCalls: updatedIncoming,
        callInProcess: true,
        activeCall: { ...action.payload, status: "in-progress" },
        incomingCall: true,
        // connectedCaller: action.payload.caller
      };
    }

    case REMOVE_INCOMING_QUEUE_CALL: {
      const updatedIncoming = { ...state.incomingCalls };

      delete updatedIncoming[action.payload];

      return {
        ...state,
        incomingCalls: updatedIncoming,
      };
    }

    case TOGGLE_MUTE_ACTIVE_CALL: {
      if (!state.activeCall.id) {
        return state;
      }

      return {
        ...state,
        activeCall: {
          ...state.activeCall,
          isMute: action.payload
        }
      };
    }

    default:
      return state;
  }
};
