import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import API from '../../../../../api/api';
import { selectObjectOperators } from '../../../../../selectors/selectors';
import { classModifier } from '../../../../../utils';
import { useDidMount, useToggle, useWillUnmount } from '../../../../../hooks';

import SvgIcon from '../../../../../components/SvgIcon/SvgIcon';
import TimeAgo from '../../../../../components/TimeAgo';
import Spinner from '../../../../../components/UI/Spinner/Spinner';
import AttachmentBooking from '../../ChatAttachments/AttachmentBooking';
import AttachmentEmail from '../../ChatAttachments/AttachmentEmail';


const SystemMsg = (props) => {
  const {
    userId,
    interaction,
    subjectOperator,
    objectOperators,
    returnOnlyMsgString = false,
  } = props;

  const chatUpdate = interaction.attachments && interaction.attachments.chatUpdate;

  const isMail = chatUpdate && chatUpdate.action.includes('newMail');
  const isSession = chatUpdate && chatUpdate.action.includes('session');
  const isBooking = chatUpdate && (
    chatUpdate.action === "newBooking" ||
    chatUpdate.action === "futureBookingActive"
  );

  const history = useHistory();

  const [session, setSession] = useState({});
  const [pending, togglePending] = useToggle(true);

  let isUnmount = useRef(false);

  useDidMount(() => {
    if (
      chatUpdate && 
      chatUpdate.sessionId && 
      (chatUpdate.action === 'futureBookingActive' || chatUpdate.action === 'newBooking')
    ) {
      API.getSessionById(chatUpdate.sessionId)
        .then(res => !isUnmount.current && setSession(res.data))
        .catch(console.error)
        .finally(() => togglePending(false));
    }
    else {
      togglePending(false);
    }
  });

  useWillUnmount(() => isUnmount.current = true);

  const renderSystemMsg = () => {
    if (!interaction.attachments) {
      return interaction.body;
    }

    switch (chatUpdate.action) {
      case 'edit': {
        const selectEdited = () => {
          let stringEditedMeta = null;

          if (chatUpdate.edit.title) {
            stringEditedMeta = `room title to ${chatUpdate.edit.title}.`;
          }
          if (chatUpdate.edit.photo) {
            if (stringEditedMeta) {
              stringEditedMeta = stringEditedMeta + ' Room photo was updated.';
            }
            else {
              stringEditedMeta = 'room photo';
            }
          }
          return stringEditedMeta;
        };

        return `${(subjectOperator && subjectOperator.username) || `You`} changed ${selectEdited()}`;
      }

      case 'removeUsers': {
        return `${(subjectOperator && subjectOperator.username) || `You`} 
        removed  ${(objectOperators && objectOperators.username) || 'You'} from room.`;
      }

      case 'leave': {
        const nameLeaved = (subjectOperator && subjectOperator.username) || 'You';

        return `${nameLeaved} leaved chat room.`;
      }

      case 'addUser': {
        const addedOperators = objectOperators
          .map(operator => {
            if (operator === undefined) {
              return 'You';
            }
            return operator.username;
          })
          .join(', ');

        return `Users ${addedOperators} was added to chat by ${(subjectOperator && subjectOperator.username) || `You`} `;
      }

      case 'chatCreated': {
        return `${(subjectOperator && subjectOperator.username) || `You`} create chat.`;
      }

      case 'updateNumber': {
        if ((subjectOperator === undefined) && interaction.attachments.chatUpdate.userId) {
          return `You ${chatUpdate.edit.title}`
        }
        else if (subjectOperator && subjectOperator.username) {
          return `${subjectOperator.username} ${chatUpdate.edit.title}`
        }
        return `${chatUpdate.edit.title}`;
      }
      case 'session_delete':
      case 'session_start':
      case 'session_close': {
        const isSessionStart = chatUpdate.action === 'session_start';

        const getActionTitle = () => {
          switch (chatUpdate.action) {
            case 'session_start':
              return ' start'
            case 'session_close':
              return ' close'
            case 'session_delete':
              return ' remove'
            default:
              return ' unknown action';
          }
        }
        let operatorName = '';

        if (!subjectOperator && !chatUpdate.userId) {
          operatorName = 'Unknown operator';
        }
        else if (!subjectOperator) {
          operatorName = 'You';
        }
        else {
          operatorName = subjectOperator.username;
        }
        return operatorName + getActionTitle() + ' session';
      }
      case "session_assistance":
      case "session_assistance_delete":
      case "session_transfer": {
        const [who, whom] = objectOperators.map((operator, index) => {
          if (!operator) {
            return +chatUpdate.usersIds[index] === userId
              ? 'You'
              : 'DELETED_OPERATOR';
          }
          return operator.username;
        });

        switch (chatUpdate.action) {
          case "session_assistance":
            return `${who} added ${whom} to session`;
          case "session_assistance_delete":
            return `${who} deleted ${whom} from session`;
          case "session_transfer":
            return `${who} transfered session to ${whom}`;
        }
      }

      case "newBooking": {
        if (returnOnlyMsgString) {
          return `New booking was created by ${(subjectOperator && subjectOperator.username) || `You`}`
        }

        const booking = session.bookings && session.bookings.find(booking => booking.id === chatUpdate.bookingId);

        return <AttachmentBooking
          chatUpdate={chatUpdate}
          subjectOperator={subjectOperator}
          userTimezone={props.userTimezone}
          userHour12={props.userHour12}
          session={session}
          booking={booking}
        />
      }

      case "futureBookingActive": {
        if (returnOnlyMsgString) {
          return "Future booking is available"
        }

        const booking = session.bookings && session.bookings.find(booking => booking.id === chatUpdate.bookingId);

        return <AttachmentBooking
          chatUpdate={chatUpdate}
          subjectOperator={subjectOperator}
          userTimezone={props.userTimezone}
          userHour12={props.userHour12}
          session={session}
          booking={booking}
          isFutureBookingAvailable
        />
      }

      case "newReminder": {
        return `${(subjectOperator && subjectOperator.username) || `You`} create new msgs reminder`;
      }

      case "newMail": {
        return <AttachmentEmail />
      }

      default:
        return interaction.body;
    }
  };

  const onClick = () => {
    if (isMail) {
      history.push(`/client/mail/inbox/email/${chatUpdate.mail.conversationId}`);
    }
  }

  if (returnOnlyMsgString) {
    return renderSystemMsg();
  }

  return (
    <div
      className={classModifier('timeline__system-msg-wrapper', [isSession && 'right'])}
    >
      <span
        className={classModifier('timeline__system-msg', [
          isSession && 'session', 
          isBooking && 'booking',
          isMail && 'mail',
        ])}
        onClick={onClick}
      >
        {isSession &&
          <TimeAgo
            className='timeline__system-msg-timeago'
            minPeriod={60}
            date={interaction.dateCreated}
          />
        }

        {(pending && isBooking)
          ? <div className='timeline__system-msg--booking-pending'>
            <Spinner spinnerSize={30}/>
          </div>
          : renderSystemMsg()}

        {isSession &&
          <SvgIcon
            className={classModifier('', [
              chatUpdate.action.slice(8), // session_
            ])}
            width="14px"
            height="14px"
            icon="puzzle"
          />
        }
      </span>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  userId: state.user.id,
  subjectOperator: ownProps.interaction.attachments
    ? state.operators.entities[ownProps.interaction.attachments.chatUpdate.userId]
    : null,
  objectOperators: ownProps.interaction.attachments
    ? selectObjectOperators(state, ownProps.interaction.attachments.chatUpdate.usersIds)
    : null,
});

SystemMsg.propTypes = {
  interaction: PropTypes.object,
  subjectOperator: PropTypes.object,
  objectOperators: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object,
  ]),
};

export default connect(mapStateToProps)(SystemMsg);