import React from 'react';
import PropTypes from 'prop-types';

import { classModifier } from '../../utils';
import withDropdownMenu from '../../HOCs/withDropdownMenu';

import './Menu.scss';
import SvgIcon from '../SvgIcon/SvgIcon';

const Menu = props => {
  console.log('MENU RENDER ' + props.icon);

  return (
    <button
      className={classModifier('burger-menu__toggler', props.submenuStatus)}
      onClick={props.submenuToggle}>

      <SvgIcon
        icon={props.icon || "bars"}
        width={props.width || "16"}
        height={props.width || "16"}
        title="menu"
        fill={props.submenuStatus === 'opened' ? "#0092F2" : "#808080"} />
      {/* <SvgIcon
        icon={props.icon}
        width={props.width}
        fill={props.submenuStatus === 'opened' ? 'icon-color-accent' : props.fill}
        alt="menu toggle" /> */}
    </button>
  );
};

// Menu.defaultProps = {
//   width: 15,
//   fill: 'icon-color-primary',
// };

// Menu.propTypes = {
//   icon: PropTypes.string.isRequired,
//   width: PropTypes.number,
//   fill: PropTypes.string,
// };

export default withDropdownMenu(Menu);
