import React, { useRef, useEffect } from "react";
import { connect } from "react-redux";
import { faVoicemail, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./VoicemailsHub.scss";
import { useToggle } from "../../../../hooks";
import { getAllVoicemails } from "../../../../ducks/missedCalls";
import { playMedia } from "../../../../ducks/activeWindows";
import { getContactMessageContext } from "../../../../ducks/clientChats";

import DropWrapper from "../../../../components/DropWrapper/DropWrapper";
import Voicemail from "./components/Voicemail";
import List from "../../../../components/List/List";
import { selectUserTimezone } from "../../../../selectors/selectors";

const VoicemailsHub = ({
  getAllVoicemails,
  playMedia,
  userTimezone,
  voicemails,
  voicemailsCount,
  voicemailsPending,
  getContactMessageContext
}) => {
  const [isOpen, toggleOpen] = useToggle(false);
  const wrapperRef = useRef();

  useEffect(() => {
    if (isOpen) {
      getAllVoicemails();
    }
  }, [isOpen]);

  return (
    <>
      <div className='voicemails__icons' onMouseDown={toggleOpen}>
        {!!voicemailsCount && (
          <span className='voicemails__unread'>
            {voicemailsCount > 99 ? "+99" : voicemailsCount}
          </span>
        )}

        <FontAwesomeIcon
          color='#404040'
          icon={faVoicemail}
          title='voicemails'
          className='voicemails__cell-icon-top'
        />

        <FontAwesomeIcon
          color='#404040'
          icon={faPhone}
          title='voicemails'
          className='voicemails__cell-icon'
        />
      </div>

      {isOpen && (
        <div ref={wrapperRef} className='voicemails__list-wrap'>
          <DropWrapper dropWrapperRef={wrapperRef} closeDropWrapper={toggleOpen}>
            <List
              list={voicemails}
              scrollInitialPosition='top'
              classPrefix='voicemails'
              listItem={Voicemail}
              listItemProps={{
                userTimezone,
                playMedia,
                getContactMessageContext
              }}
              listLoadPending={voicemailsPending}
            />
          </DropWrapper>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  voicemailsPending: state.missedCalls.voicemailsPending,
  voicemails: state.missedCalls.voicemails,
  voicemailsCount: state.missedCalls.voicemailsCount,
  userTimezone: selectUserTimezone(state),
});

const mapDispatchToProps = {
  getAllVoicemails,
  playMedia,
  getContactMessageContext
};

export default connect(mapStateToProps, mapDispatchToProps)(VoicemailsHub);
