import React, { useRef, useEffect, useState, useMemo, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faStar } from '@fortawesome/free-solid-svg-icons';

import API from "../../../api/api";
import { selectLockedOperator, selectUserTimezone } from '../../../selectors/selectors';
import { getContactsById, addContactsToEntities } from '../../../ducks/contacts';
import { updateActiveContact } from '../../../ducks/clientChats';
import { useDidMount, useDidUpdate, useToggle } from '../../../hooks';
import {
  showSessionFromHistory,
  normalizeSession,
  BOOKING_STATUSES
} from "../../../ducks/sessions";
import { getDivaGirls } from "../../../ducks/divaGirls";
import { classModifier, formatDateToTime, getDateByTimezoneOffset } from "../../../utils";

import SvgIcon from "../../../components/SvgIcon/SvgIcon";
import BookedEditor from "../../../components/BookedEditor/BookedEditor";
import DropWrapper from "../../../components/DropWrapper/DropWrapper";
import Spinner from "../../../components/UI/Spinner/Spinner";
import { Portal } from '../../../components/Portal';
import Title from '../../../components/SvgIcon/components/Title/Title';

const BookingsListItem = (props) => {
  const {
    booking,
    // client,
    // girlProfile,
    isAutoPositionOfEditor,
    askConfirmOnClose
  } = props;

  const client = booking.caller;
  const bookingDateByTimezone = getDateByTimezoneOffset(props.userTimezone, booking.date);

  const [animation, setAnimation] = useState({
    className: '',
    isProcessGoes: false,
    styles: {},
  });

  const [tooltip, setTooltip] = useState({
    isShowTitle: false,
    coords: {
      x: 0,
      y: 0,
    }
  });

  const [isEditing, toggleEditing] = useToggle(false);
  const [isBookingOpen, toggleBookingOpen] = useToggle();
  const [isCancelInfoShow, toggleCancelInfoShow] = useToggle(false);
  const [isConfirmOnClose, toggleConfirmOnClose] = useToggle(false);

  const bookingListItemRowRef = useRef();
  const bookingEditorRef = useRef();
  const bookingEditorBtnRef = useRef();

  const handleMouseEnter = (e) => {
    setTooltip({
      coords: {
        x: e.clientX,
        y: e.clientY,
      },
      isShowTitle: true,
    })
  };

  const handleMouseLeave = () => setTooltip({ isShowTitle: false });

  const isMember = useMemo(() => {
    if (!client) return;

    for (let tag of client.callerTags) {
      if (tag.title === "Member") return true
    }
    return false;
  }, [client]);

  useEffect(() => { // open or close
    const isNotInit = isBookingOpen !== undefined;

    if (isBookingOpen && isNotInit) {
      setAnimation({
        className: 'open',
        isProcessGoes: true,
      });

      setTimeout(() => setAnimation({
        className: '',
        isProcessGoes: false,
        styles: {
          height: '135px',
        },
      }), 500);
    }
    else if (!isBookingOpen && isNotInit) {
      setAnimation({
        className: 'close',
        isProcessGoes: true,
      });

      setTimeout(() => setAnimation({
        className: '',
        isProcessGoes: false,
        styles: {
          height: '51px',
        },
      }), 500);
    }
  }, [isBookingOpen]);

  useEffect(() => {
    if (props.toggleConfirmOnClose) {
      props.toggleConfirmOnClose(isConfirmOnClose);
    }
  }, [isConfirmOnClose])

  if (!client) {
    return (
      <div className='booking-mini__wrap'>
        <li className='booking-mini'>
          <div className="booking-mini__spinner-wrap">
            <Spinner spinnerSize={44} />
          </div>
        </li>
      </div>
    )
  }

  const openSession = () => {
    API.getSessionById(booking.session_id, 1)
      .then(res => {
        props.showSessionFromHistory(normalizeSession(res.data))
        props.onClose && props.onClose();

        if (props.history.pathname !== '/client/sales') {
          props.history.push('/client/sales');
        }
      })
      .catch(console.log);
  }

  const startEdit = () => {
    toggleEditing();
  }

  const getBookingTime = () => {
    const date = bookingDateByTimezone;

    const prettyDate = date.toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' });
    const time = formatDateToTime(date, props.userHour12, false);

    return prettyDate + ' at ' + time;
  }

  const onEdit = (booking) => {
    props.updateBookings(booking, props.hour);
  }

  const openOrCloseBooking = (e) => {
    if (animation.isProcessGoes || e.target.dataset.isBtn || !booking.requirements.length) {
      return;
    }

    toggleBookingOpen();
  }

  const onCheckLabel = (id, value) => {
    const { profile_name } = booking;

    API.updateBookingsRequirementChecked(id, value)
      .then(({ data }) => onEdit({...data, profile_name}))
      .catch(console.log);
  }

  const [request] = booking.requests;

  const handleContactClick = (e, type) => {
    e.stopPropagation();

    const isGirl = type === "girl";
    const contact = isGirl ? props.girl : props.client;

    if (isGirl && props.booking.girlContactId === null) {
      return;
    }

    if (contact) {
      props.updateActiveContact(contact);
    } else {
      API.getContactsById(isGirl ? [booking.girlContactId] : [booking.callerId])
        .then(({ data }) => {
        if (data && data[0]) {
          props.addContactsToEntities(data);
          props.updateActiveContact(data[0]);
        }
      });
    }
  };

  const handleBookedEditorClose = () => {
    const confirmMsg = "There are unsaved changes. Do you want to close the dialog anyway?";
    return askConfirmOnClose && isConfirmOnClose && !window.confirm(confirmMsg);
  }

  return (
    <div className='booking-mini__wrap'>
      <li
        className={classModifier('booking-mini', [
          booking.is_prebooking && 'pre-booking',
          animation.className,
          booking.type === 'outcall' && 'outcall',
        ])}
        onClick={openOrCloseBooking}
        style={animation.styles}
      >
        <div className="booking-mini__row" ref={bookingListItemRowRef}>
          <div className="booking-mini__column">
            <div 
              style={{ cursor: props.isChat ? 'pointer' : 'auto'}} 
              className="booking-mini__name booking-mini__name--client" 
              title={client.fn} onClick={(e) => props.isChat && handleContactClick(e, "client")}>
                {client.fn}
            </div>

            <div className="booking-mini__name booking-mini__name--girl"
              title={booking.profile_name}
              style={{ cursor: props.isChat ? "pointer" : "auto" }}
              onClick={(e) => props.isChat && handleContactClick(e, "girl")}>
              {booking.profile_name}
              {booking.profile_exclusive && <FontAwesomeIcon icon={faStar} title="exclusive" />}
            </div>

            <div className={classModifier('booking-mini__unchecked-labels', [isBookingOpen && 'hidden'])}>
              {booking.requirements.map(label => label.isChecked
                ? null
                : (
                  <div
                    key={label.id}
                    className="booking-mini__unchecked-indicator"
                    style={{ backgroundColor: label.color }}
                  />
                )
              )}
            </div>
          </div>

          <div className="booking-mini__column">
            <div>{getBookingTime()}</div>
            <div>
              {booking.type} / {booking.duration} /
              <span className={!!booking.discount_rate ? 'booking-mini__price--old' : null}>£{booking.rate}</span>
              {!!booking.discount_rate && <span className="booking-mini__price--new">  £{booking.discount_rate}</span>}
            </div>
          </div>

          <div className="booking-mini__column">
            <div className="booking-mini__status-list">
              {booking.overdue &&
                <span className='booking-mini__overdue'>
                  overdue
                </span>
              }

              <span
                className={classModifier('booking-mini__status', [
                  booking.status === 0 && 'cancelled',
                  (booking.status === 1 || booking.status === 5) && 'pending',
                  booking.status === 2 && 'in-progress',
                  booking.status === 3 && 'finished',
                  booking.status === 4 && 'feedback-received',
                ])}
                onMouseEnter={booking.status === 0 ? () => toggleCancelInfoShow(true) : null}
                onMouseLeave={booking.status === 0 ? () => toggleCancelInfoShow(false) : null}
              >
                {BOOKING_STATUSES[booking.status]}
              </span>

              {!!booking.requests.length &&
                <span
                  className='booking-mini__request'
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  request
                  {tooltip.isShowTitle &&
                    <Title
                      title={request.text}
                      coords={tooltip.coords}
                    />}
                </span>
              }
            </div>

            <div className="booking-mini__name-buttons">
              <div className="booking-mini__name booking-mini__name--operator">
                {props.operatorName}
              </div>

              <div className="booking-mini__buttons">
                <button
                  ref={bookingEditorBtnRef}
                  className='booking-mini__btn'
                  onMouseDown={startEdit}
                  data-is-btn
                  type="button"
                >
                  <SvgIcon
                    title="edit"
                    width='16px'
                    height='16px'
                    icon='edit'
                    fill='#0092f2'
                  />
                </button>

                <button
                  className='booking-mini__btn booking-mini__btn-session'
                  onClick={openSession}
                  data-is-btn
                  type="button"
                >
                  <SvgIcon
                    title="open session"
                    width='18px'
                    height='18px'
                    icon='puzzle'
                    fill='#0092f2'
                  />
                </button>
              </div>
            </div>

            {isCancelInfoShow &&
              <div
                className='booking-mini__cancel-info'
                onMouseEnter={() => toggleCancelInfoShow(true)}
                onMouseLeave={() => toggleCancelInfoShow(false)}
              >
                <div className="booking-mini__cancel-info--reason">
                  <span className='booking-mini__cancel-info--title'>
                    Reason:
                  </span>

                  <span
                    className='booking-mini__cancel-info--value'
                    title={booking.reason ? booking.reason : ''}
                  >
                    {booking.reason ? booking.reason : ''}
                  </span>
                </div>

                <div className="booking-mini__cancel-info--description">
                  {!!booking.description &&
                    <>
                      <span className='booking-mini__cancel-info--title'>
                        Description:
                      </span>

                      <span
                        className='booking-mini__cancel-info--value'
                        title={booking.description}
                      >
                        {booking.description}
                      </span>
                    </>
                  }
                </div>
              </div>
            }
          </div>
        </div>

        <div className="booking-mini__labels">
          {booking.requirements.map(label =>
            <div
              className='booking-mini__label'
              onClick={() => onCheckLabel(label.requirementId, !label.isChecked)}
              key={label.id}
              data-is-btn
            >
              <div className={classModifier('booking-mini__label-check', [label.isChecked && 'active'])}>
                {!!label.isChecked && <FontAwesomeIcon icon={faCheck} title="selected" />}
              </div>

              {label.title}
            </div>
          )}
        </div>
      </li>

      {isEditing &&
        <Portal isPortal={props.isChat || props.isModal}>
          <div ref={bookingEditorRef}>
            <DropWrapper
              dropWrapperRef={bookingEditorRef}
              closeDropWrapper={toggleEditing}
              onClose={handleBookedEditorClose}
            >
              <BookedEditor
                isChat={props.isChat}
                isModal={props.isModal}
                parentRef={props.isChat && bookingEditorBtnRef}
                profileId={booking.profile_id}
                handleCancel={toggleEditing}
                handleClose={toggleEditing}
                onSubmit={onEdit}
                onBookingDeleted={props.onBookingDeleted}
                isEditingMode={true}
                isFromHistory
                isAnimatedInit
                isMember={isMember}
                booking={booking}
                isAutoPosition={isAutoPositionOfEditor}
                bookingListItemRowRef={bookingListItemRowRef}
                toggleConfirmOnClose={toggleConfirmOnClose}
              />
            </DropWrapper>
          </div>
        </Portal>
      }
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  client: state.contacts.entities[ownProps.booking.callerId],
  girl: state.contacts.entities[ownProps.booking.girlContactId],
  operatorName: selectLockedOperator(state, ownProps.booking.usersIds[0]),
  girlProfile: state.divaGirls.entities[ownProps.booking.profile_id],
  userTimezone: selectUserTimezone(state),
  userHour12: state.user.hour12
});

const mapDispatchToProps = {
  getContactsById,
  showSessionFromHistory,
  getDivaGirls,
  updateActiveContact,
  addContactsToEntities,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BookingsListItem));