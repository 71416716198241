import React from 'react';

import './NotificationComponent.scss';

import { NOTIFICATION_TYPES } from '../../ducks/notifications';

import Notification from './components/Notification';
import ContactsNotification from './components/ContactNotification';
import SessionNotification from './components/SessionNotification';


const SESSION_NOTIFICATIONS = [
  NOTIFICATION_TYPES.sessionProposed,
  NOTIFICATION_TYPES.sessionAssistanceRequest,
  NOTIFICATION_TYPES.sessionAssistanceDelete,
  NOTIFICATION_TYPES.sessionAssistanceResponse,
  NOTIFICATION_TYPES.sessionTransferRequest,
  NOTIFICATION_TYPES.sessionTransferResponse,
  NOTIFICATION_TYPES.sessionView,
];

const getDate = (date) => {
  if (!date) return;
  const currentDate = new Date().getDate();
  const yesterdayDate = new Date(new Date().getTime() - 86400000).getDate();

  if (date.getDate() === currentDate) {
    return 'Today';
  }
  else if (date.getDate() === yesterdayDate) {
    return 'Yesterday';
  }

  let prettyDate = date.toJSON().split('T')[0]; // 2019-01-01

  prettyDate = prettyDate.split('-').join('.'); // 2019.01.01;

  return prettyDate;
};


const NotificationComponent = props => {
  const {
    notification,
  } = props;

  let Component;

  if (SESSION_NOTIFICATIONS.includes(notification.type)) {
    Component = SessionNotification;
  }
  else if (notification.type === NOTIFICATION_TYPES.bufferedProfileBecomeOff) {
    Component = ContactsNotification;
  }
  else {
    Component = Notification
  }

  return <Component
    getDate={getDate}
    {...props}
  />;
};

export default NotificationComponent;