import API from "../api/api";

import { NEW_OUTGOING_CALL, NEW_INCOMING_CALL, NEW_INCOMING_QUEUE_CALL } from "./calls";
import {
  CHANGE_MESSAGE_STATUS,
  CHANGE_CALL_STATUS,
  NEW_OUTGOING_MESSAGE,
  NEW_INCOMING_MESSAGE,
  NEW_SCHEDULED_MESSAGE,
  UNREAD_CLIENT_MESSAGES,
  NEW_MESSAGE_ATTACHMENTS,
  PIN_CLIENT_MSG,
  PIN_GIRL_MSG,
  onUpdateTimeline,
  onInteraction,
  updateUnreadMsgsInTimeline,
  groupInteractionsByTimezone,
  onStatusChange,
  onAttachmentsAdding,
  onRemoveTab,
  onRemoveTabUpdateActiveContact,
  UPDATE_CLIENT_MSG,
  DELETE_CLIENT_MSG,
  DELETE_TELEGRAM_MSG,
  removeFromTimeline,
} from "./clientChats";
import {
  DELETE_GIRL_MSG,
  UNREAD_GIRL_MESSAGES,
  UPDATE_GIRL_MSG,
  NEW_SERVICE_MESSAGE,
} from "./girlChats";
import { onRemoveChatTab } from "./roomChats";
import { NEW_MISSED_CALL, UPDATE_VOICEMAIL_STATUS } from "./missedCalls";

import { convertDateFromUTC } from "../utils";
import { CHAT_SOURCES, CHAT_TYPES, INTERACTION_TYPES } from "../config/constants";


const duckName = 'saleSessionChats/';


export const INIT_SESSION_CHATS = duckName + 'INIT_SESSION_CHATS';

export const SET_CLIENT_UPDATE_PENDING = duckName + 'SET_CLIENT_UPDATE_PENDING';
export const SET_GIRL_UPDATE_PENDING = duckName + 'SET_GIRL_UPDATE_PENDING';

export const GET_SEARCHED_CLIENT_MESSAGES = duckName + 'GET_SEARCHED_CLIENT_MESSAGES';
export const UPDATE_SEARCHED_CLIENT_MESSAGES = duckName + 'UPDATE_SEARCHED_CLIENT_MESSAGES';
export const GLOBAL_CLIENT_MESSAGE_SEARCH = duckName + 'GLOBAL_CLIENT_MESSAGE_SEARCH';
export const UPDATE_GLOBAL_CLIENT_MESSAGE_SEARCH = duckName + 'UPDATE_GLOBAL_CLIENT_MESSAGE_SEARCH';
export const STOP_SEARCH_CLIENT_MESSAGES = duckName + 'STOP_SEARCH_CLIENT_MESSAGES';

export const GET_SEARCHED_GIRL_MESSAGES = duckName + 'GET_SEARCHED_GIRL_MESSAGES';
export const UPDATE_SEARCHED_GIRL_MESSAGES = duckName + 'UPDATE_SEARCHED_GIRL_MESSAGES';
export const GLOBAL_GIRL_MESSAGE_SEARCH = duckName + 'GLOBAL_GIRL_MESSAGE_SEARCH';
export const UPDATE_GLOBAL_GIRL_MESSAGE_SEARCH = duckName + 'UPDATE_GLOBAL_GIRL_MESSAGE_SEARCH';
export const STOP_SEARCH_GIRL_MESSAGES = duckName + 'STOP_SEARCH_GIRL_MESSAGES';


export const UPDATE_ACTIVE_CLIENT = duckName + 'UPDATE_ACTIVE_CLIENT';
export const UPDATE_ACTIVE_GIRL = duckName + 'UPDATE_ACTIVE_GIRL';

export const GET_CLIENT_TIMELINE_PENDING = duckName + 'GET_CLIENT_TIMELINE_PENDING';
export const GET_CLIENT_TIMELINE = duckName + 'GET_CLIENT_TIMELINE';
export const GET_CLIENT_TIMELINE_ERROR = duckName + 'GET_CLIENT_TIMELINE_ERROR';
export const UPDATE_CLIENT_TIMELINE = duckName + 'UPDATE_CLIENT_TIMELINE';

export const GET_GIRL_TIMELINE_PENDING = duckName + 'GET_GIRL_TIMELINE_PENDING';
export const GET_GIRL_TIMELINE = duckName + 'GET_GIRL_TIMELINE';
export const GET_GIRL_TIMELINE_ERROR = duckName + 'GET_GIRL_TIMELINE_ERROR';
export const UPDATE_GIRL_TIMELINE = duckName + 'UPDATE_GIRL_TIMELINE';


export const REMOVE_CLIENT_TAB = duckName + 'REMOVE_CLIENT_TAB';
export const REMOVE_CLIENT_TABS = duckName + 'REMOVE_CLIENT_TABS';
export const SET_CLIENT_TABS = duckName + 'SET_CLIENT_TABS';

export const REMOVE_GIRL_TAB = duckName + 'REMOVE_GIRL_TAB';
export const REMOVE_GIRL_TABS = duckName + 'REMOVE_GIRL_TABS';
export const SET_GIRLS_TABS = duckName + 'SET_GIRLS_TABS';

export const GET_CLIENT_CONVERSATION_MEDIA = duckName + 'GET_CLIENT_CONVERSATION_MEDIA';
export const UPDATE_CLIENT_CONVERSATION_MEDIA = duckName + 'UPDATE_CLIENT_CONVERSATION_MEDIA';
export const CLEAN_CLIENT_CONVERSATION_MEDIA = duckName + 'CLEAN_CLIENT_CONVERSATION_MEDIA';

export const GET_GIRL_CONVERSATION_MEDIA = duckName + 'GET_GIRL_CONVERSATION_MEDIA';
export const UPDATE_GIRL_CONVERSATION_MEDIA = duckName + 'UPDATE_GIRL_CONVERSATION_MEDIA';
export const CLEAN_GIRL_CONVERSATION_MEDIA = duckName + 'CLEAN_GIRL_CONVERSATION_MEDIA';

export const GET_CLIENT_MSG_REMINDERS = duckName + 'GET_CLIENT_MSG_REMINDERS';
export const UPDATE_CLIENT_MSG_REMINDERS = duckName + 'UPDATE_CLIENT_MSG_REMINDERS';
export const CLEAN_CLIENT_MSG_REMINDERS = duckName + 'CLEAN_CLIENT_MSG_REMINDERS';
export const REMOVE_CLIENT_MSG_REMIND = duckName + 'REMOVE_CLIENT_MSG_REMIND';
export const REMOVE_CLIENT_REMINDER_FROM_MAIN_TIMELINE = duckName + 'REMOVE_CLIENT_REMINDER_FROM_MAIN_TIMELINE';
export const REMOVE_GIRL_REMINDER_FROM_MAIN_TIMELINE = duckName + 'REMOVE_GIRL_REMINDER_FROM_MAIN_TIMELINE';

export const GET_GIRL_MSG_REMINDERS = duckName + 'GET_GIRL_MSG_REMINDERS';
export const UPDATE_GIRL_MSG_REMINDERS = duckName + 'UPDATE_GIRL_MSG_REMINDERS';
export const CLEAN_GIRL_MSG_REMINDERS = duckName + 'CLEAN_GIRL_MSG_REMINDERS';
export const REMOVE_GIRL_MSG_REMIND = duckName + 'REMOVE_GIRL_MSG_REMIND';

export const GET_CLIENT_MSG_CONTEXT = duckName + 'GET_CLIENT_MSG_CONTEXT';
export const UPDATE_CLIENT_MSG_CONTEXT = duckName + 'UPDATE_CLIENT_MSG_CONTEXT';
export const CLEAN_CLIENT_MSG_CONTEXT = duckName + 'CLEAN_CLIENT_MSG_CONTEXT';

export const GET_GIRL_MSG_CONTEXT = duckName + 'GET_GIRL_MSG_CONTEXT';
export const UPDATE_GIRL_MSG_CONTEXT = duckName + 'UPDATE_GIRL_MSG_CONTEXT';
export const CLEAN_GIRL_MSG_CONTEXT = duckName + 'CLEAN_GIRL_MSG_CONTEXT';

export const GET_CLIENT_DATE_MSG_CONTEXT = duckName + 'GET_CLIENT_DATE_MSG_CONTEXT';
export const UPDATE_CLIENT_DATE_MSG_CONTEXT = duckName + 'UPDATE_CLIENT_DATE_MSG_CONTEXT';
export const CLEAN_CLIENT_DATE_MSG_CONTEXT = duckName + 'CLEAN_CLIENT_DATE_MSG_CONTEXT';

export const GET_GIRL_DATE_MSG_CONTEXT = duckName + 'GET_GIRL_DATE_MSG_CONTEXT';
export const UPDATE_GIRL_DATE_MSG_CONTEXT = duckName + 'UPDATE_GIRL_DATE_MSG_CONTEXT';
export const CLEAN_GIRL_DATE_MSG_CONTEXT = duckName + 'CLEAN_GIRL_DATE_MSG_CONTEXT';

export const GET_CLIENT_SCHEDULED_MSGS_COUNT = duckName + 'GET_CLIENT_SCHEDULED_MSGS_COUNT';
export const GET_CLIENT_SCHEDULED_MSGS = duckName + 'GET_CLIENT_SCHEDULED_MSGS';
export const UPDATE_CLIENT_SCHEDULED_MSGS = duckName + 'UPDATE_CLIENT_SCHEDULED_MSGS';
export const CLEAN_CLIENT_SCHEDULED_MSGS = duckName + 'CLEAN_CLIENT_SCHEDULED_MSGS';

export const GET_GIRL_SCHEDULED_MSGS_COUNT = duckName + 'GET_GIRL_SCHEDULED_MSGS_COUNT';
export const GET_GIRL_SCHEDULED_MSGS = duckName + 'GET_GIRL_SCHEDULED_MSGS';
export const UPDATE_GIRL_SCHEDULED_MSGS = duckName + 'UPDATE_GIRL_SCHEDULED_MSGS';
export const CLEAN_GIRL_SCHEDULED_MSGS = duckName + 'CLEAN_GIRL_SCHEDULED_MSGS';

export const GET_GIRL_SERVICE_MSGS = duckName + 'GET_GIRL_SERVICE_MSGS';
export const UPDATE_GIRL_SERVICE_MSGS = duckName + 'UPDATE_GIRL_SERVICE_MSGS';
export const CLEAN_GIRL_SERVICE_MSGS = duckName + 'CLEAN_GIRL_SERVICE_MSGS';
export const GET_GIRL_SERVICE_MSGS_COUNT = duckName + 'GET_GIRL_SERVICE_MSGS_COUNT';

export const LIMIT = 15;

const CLIENTS = 'clients';
const GIRLS = 'girls';


export const initSessionChats = (clientId, girlIds = [], activeGirlChat) => dispatch => {
  dispatch({
    type: INIT_SESSION_CHATS,
    payload: {
      clientId,
      girlIds,
      activeGirlChat: girlIds.includes(activeGirlChat) && activeGirlChat,
    }
  });
}

export const getContactTimeline = (contactId, chatType, userTimezone, withCancel = true) => dispatch => {

  dispatch({
    type:
      chatType === 1
        ? GET_CLIENT_TIMELINE_PENDING
        : GET_GIRL_TIMELINE_PENDING
  });

  API.getInteractions(contactId, null, withCancel)
    .then(res => {
      dispatch({
        type: chatType === 1 ? GET_CLIENT_TIMELINE : GET_GIRL_TIMELINE,
        payload: {
          ...res.data,
          messages: groupInteractionsByTimezone(res.data.messages, userTimezone),
          contactId
        }
      });
    })
    .catch(err => {
      console.log(err);
      dispatch({
        type:
          chatType === 1
            ? GET_CLIENT_TIMELINE_ERROR
            : GET_GIRL_TIMELINE_ERROR,
        payload: contactId
      });
    });
};

export const updateContactTimeline = (contact, page, loadDirection, userTimezone) => dispatch => {
  API.getInteractions(contact.id, page, true)
    .then(res => {
      // if we have 10 messages only and offset = 10 we will get []
      if (!res.data.messages.length) return;

      dispatch({
        type: contact.type === 1 ? UPDATE_CLIENT_TIMELINE : UPDATE_GIRL_TIMELINE,
        payload: {
          ...res.data,
          messages: groupInteractionsByTimezone(res.data.messages, userTimezone),
          loadDirection,
          userTimezone
        }
      });
    })
    .catch(err => console.log(err));
};

export const updateActiveContact = (contact, fromMoreTabs = false) => dispatch => {
  // Contact in tabs but not active
  dispatch({
    type: contact.type === 1 ? UPDATE_ACTIVE_CLIENT : UPDATE_ACTIVE_GIRL,
    payload: {
      contact,
      fromMoreTabs
    }
  });
};

export const removeContactTab = (removedId, type) => dispatch => {
  type === 1 || type === 2 || type === 3
    ? dispatch(onRemoveContactTab(removedId, type)) // else only delete from tabs
    : dispatch(onRemoveChatTab(removedId));
};

export const onRemoveContactTab = (removedId, type) => dispatch => {
  dispatch({
    type: type === 1 ? REMOVE_CLIENT_TAB : REMOVE_GIRL_TAB,
    payload: removedId
  });
};

export const getContactReminders = (contact, userTimezone) => dispatch => {
  dispatch({
    type:
      contact.type === 1
        ? GET_CLIENT_TIMELINE_PENDING
        : GET_GIRL_TIMELINE_PENDING
  });

  return API.getContactReminders(contact.id)
    .then(res => {
      dispatch({
        type:
          contact.type === 1
            ? GET_CLIENT_MSG_REMINDERS
            : GET_GIRL_MSG_REMINDERS,
        payload: {
          ...res.data,
          messages: groupInteractionsByTimezone(res.data.messages, userTimezone),
          chatSource: CHAT_SOURCES.REMINDERS,
        }
      });
    })
    .catch(console.error);
};

export const updateContactReminders = (contact, page, loadDirection, userTimezone) => dispatch => {
  return API.getContactReminders(contact.id, page)
    .then(res =>
      dispatch({
        type:
          contact.type === 1
            ? UPDATE_CLIENT_MSG_REMINDERS
            : UPDATE_GIRL_MSG_REMINDERS,
        payload: {
          ...res.data,
          messages: groupInteractionsByTimezone(res.data.messages, userTimezone),
          userTimezone,
          loadDirection
        }
      })
    )
    .catch(console.error);
};

export const cleanContactReminders = contactType => dispatch => {
  dispatch({
    type:
      contactType === 1
        ? CLEAN_CLIENT_MSG_REMINDERS
        : CLEAN_GIRL_MSG_REMINDERS
  });
};

export const removeMessageReminder = (remindId, type, isNotificationEnd = false, isMainTimeline = false) => dispatch => {
  if (!isMainTimeline) {
    if (isNotificationEnd) {
      dispatch({
        type: type === 1
          ? REMOVE_CLIENT_MSG_REMIND
          : REMOVE_GIRL_MSG_REMIND,
        payload: remindId
      });
    }
    else {
      return API.removeMessageReminder(remindId)
        .then(() => {
          dispatch({
            type: type === 1
              ? REMOVE_CLIENT_MSG_REMIND
              : REMOVE_GIRL_MSG_REMIND,
            payload: remindId
          });
        })
        .catch(console.error);
    }
  }
  else {
    dispatch({
      type: type === 1
        ? REMOVE_CLIENT_REMINDER_FROM_MAIN_TIMELINE
        : REMOVE_GIRL_REMINDER_FROM_MAIN_TIMELINE,
      payload: remindId
    })
  }
};

export const getTimelineMedia = (contact, userTimezone) => dispatch => {
  dispatch({
    type: contact.type === 1
      ? GET_CLIENT_TIMELINE_PENDING
      : GET_GIRL_TIMELINE_PENDING
  });

  return API.getConversationMedia(contact.id)
    .then(res => {
      dispatch({
        type:
          contact.type === 1
            ? GET_CLIENT_CONVERSATION_MEDIA
            : GET_GIRL_CONVERSATION_MEDIA,
        payload: {
          ...res.data,
          messages: groupInteractionsByTimezone(res.data.messages, userTimezone),
          chatSource: CHAT_SOURCES.MEDIA,
        }
      });
    })
    .catch(console.error);
};

export const cleanTimelineMedia = contactType => dispatch => {
  dispatch({
    type:
      contactType === 1
        ? CLEAN_CLIENT_CONVERSATION_MEDIA
        : CLEAN_GIRL_CONVERSATION_MEDIA
  });
};

export const updateContactTimelineMedia = (contact, page, loadDirection, userTimezone) => dispatch => {
  return API.getConversationMedia(contact.id, page)
    .then(res =>
      dispatch({
        type:
          contact.type === 1
            ? UPDATE_CLIENT_CONVERSATION_MEDIA
            : UPDATE_GIRL_CONVERSATION_MEDIA,
        payload: {
          ...res.data,
          messages: groupInteractionsByTimezone(res.data.messages, userTimezone),
          userTimezone,
          loadDirection
        }
      })
    )
    .catch(console.error);
};

export const getContactMessageContext = (contextMsgId, type, contact = null, query = "", userTimezone) => async dispatch => {
  let contactType = contact ? contact.type : type;

  // TODO: pass instead int array and check like type.includes(contact.type)
  if (contactType === 3 || contactType === 4 || contactType === 5) {
    contactType = 2;
  }

  // cleanContactMsgContext
  if (!contextMsgId) {
    if (query) {
      return dispatch(searchContactMessage(query, contact));
    }
    return dispatch({
      type: contactType === 1 ? CLEAN_CLIENT_MSG_CONTEXT : CLEAN_GIRL_MSG_CONTEXT
    });
  }

  // if contact passed it is globalMsgSearch so we need update contact => get contact timeline => make search if query passed => and get contact msg context
  if (contact) {
    dispatch({
      type: contactType === 1 ? UPDATE_ACTIVE_CLIENT : UPDATE_ACTIVE_GIRL,
      payload: {
        contact,
        isGlobalMsgSearch: true,
        showSearchQuery: contactType !== type
      }
    });

    // search contact message if query passed
    // if (query) {
    //   await dispatch(searchContactMessage(query, contact));
    // }

  } else {
    dispatch({
      type:
        contactType === 1
          ? GET_CLIENT_TIMELINE_PENDING
          : GET_GIRL_TIMELINE_PENDING
    });
  }

  API.getMessageContext(contextMsgId)
    .then(res => {
      dispatch({
        type: contactType === 1 ? GET_CLIENT_MSG_CONTEXT : GET_GIRL_MSG_CONTEXT,
        payload: {
          contextMsgId,
          ...res.data,
          messages: groupInteractionsByTimezone(res.data.messages, userTimezone)
        }
      });
    })
    .catch(console.error);
};

export const updateContactMessageContext = (contextMsgId, page, loadDirection, contact, userTimezone) => dispatch => {
  API.getMessageContext(contextMsgId, page)
    .then(res => {
      dispatch({
        type: contact.type === 1 ? UPDATE_CLIENT_MSG_CONTEXT : UPDATE_GIRL_MSG_CONTEXT,
        payload: {
          ...res.data,
          messages: groupInteractionsByTimezone(res.data.messages, userTimezone),
          userTimezone,
          loadDirection
        }
      });
    })
    .catch(console.error);
}

export const searchContactMessage = (query, contact, userTimezone) => dispatch => {
  dispatch({
    type: contact.type === 1
      ? GET_CLIENT_TIMELINE_PENDING
      : GET_GIRL_TIMELINE_PENDING
  })

  return API.searchContactMessage(contact.id, query)
    .then(res => {
      dispatch({
        type: contact.type === 1
          ? GET_SEARCHED_CLIENT_MESSAGES
          : GET_SEARCHED_GIRL_MESSAGES,
        payload: {
          ...res.data,
          messages: groupInteractionsByTimezone(res.data.messages, userTimezone),
          query
        }
      });
    })
    .catch(err => console.log(err));
};

export const updateContactMessageSearch = (contact, page, loadDirection, query, userTimezone) => dispatch => {
  dispatch({
    type: contact.type === 1
      ? SET_CLIENT_UPDATE_PENDING
      : SET_GIRL_UPDATE_PENDING,
    payload: true,
  });

  API.searchContactMessage(contact.id, query, page)
    .then(res => {
      dispatch({
        type: contact.type === 1
          ? UPDATE_SEARCHED_CLIENT_MESSAGES
          : UPDATE_SEARCHED_GIRL_MESSAGES,
        payload: {
          ...res.data,
          messages: groupInteractionsByTimezone(res.data.messages, userTimezone),
          userTimezone,
          loadDirection
        }
      });
    })
    .catch(err => console.log(err));
};

export const stopContactMessageSearch = (contact, status) => dispatch => {
  dispatch({
    type: contact.type === 1
      ? STOP_SEARCH_CLIENT_MESSAGES
      : STOP_SEARCH_GIRL_MESSAGES,
    payload: status
  });
};

export const getContactDateMsgContext = (contextDate, contactId, chatType, userTimezone) => dispatch => {
  dispatch({
    type: chatType === 1
      ? GET_CLIENT_TIMELINE_PENDING
      : GET_GIRL_TIMELINE_PENDING
  })

  return API.getContactDateMsgContext(contextDate, contactId)
    .then(res => {
      dispatch({
        type: chatType === 1 ? GET_CLIENT_DATE_MSG_CONTEXT : GET_GIRL_DATE_MSG_CONTEXT,
        payload: {
          ...res.data,
          messages: groupInteractionsByTimezone(res.data.messages, userTimezone)
        }
      });
    })
}

export const updateContactDateMsgContext = (contact, page, loadDirection, contextDate, userTimezone) => dispatch => {
  return API.getContactDateMsgContext(contextDate, contact.id, page)
    .then(res => {
      // if we have 10 messages only and offset = 10 we will get []
      if (!res.data.messages.length) return;

      dispatch({
        type: contact.type === 1
          ? UPDATE_CLIENT_DATE_MSG_CONTEXT
          : UPDATE_GIRL_DATE_MSG_CONTEXT,
        payload: {
          ...res.data,
          messages: groupInteractionsByTimezone(res.data.messages, userTimezone),
          userTimezone,
          loadDirection
        }
      });
    });
}

export const cleanContactDateMsgContext = chatType => dispatch => {
  dispatch({
    type:
      chatType === 1
        ? CLEAN_CLIENT_DATE_MSG_CONTEXT
        : CLEAN_GIRL_DATE_MSG_CONTEXT
  });
};


export const getContactChatOfHistorySession = (sessionId, contactId, chatType, userTimezone) => dispatch => {
  dispatch({
    type: chatType === CHAT_TYPES.CLIENT
      ? GET_CLIENT_TIMELINE_PENDING
      : GET_GIRL_TIMELINE_PENDING
  })

  return API.getContactChatOfHistorySession(sessionId, contactId)
    .then(res => {
      dispatch({
        type: chatType === 1 ? GET_CLIENT_TIMELINE : GET_GIRL_TIMELINE,
        payload: {
          ...res.data,
          messages: groupInteractionsByTimezone(res.data.messages, userTimezone),
          contactId
        }
      });
    })
};

export const updateContactChatOfHistorySession = (sessionId, contactId, page, loadDirection, chatType, userTimezone) => dispatch => {
  return API.getContactChatOfHistorySession(sessionId, contactId, page)
    .then(res => {
      dispatch({
        type: chatType === 1
          ? UPDATE_CLIENT_TIMELINE
          : UPDATE_GIRL_TIMELINE,
        payload: {
          ...res.data,
          messages: groupInteractionsByTimezone(res.data.messages, userTimezone),
          loadDirection,
          userTimezone
        }
      });
    });
}

export const getScheduledMsgs = (contact, userTimezone) => dispatch => {
  dispatch({
    type:
      contact.type === 1
        ? GET_CLIENT_TIMELINE_PENDING
        : GET_GIRL_TIMELINE_PENDING
  });

  return API.getContactScheduledMsgs(contact.id)
    .then(res => {
      dispatch({
        type:
          contact.type === 1
            ? GET_CLIENT_SCHEDULED_MSGS
            : GET_GIRL_SCHEDULED_MSGS,
        payload: {
          ...res.data,
          messages: groupInteractionsByTimezone(res.data.messages, userTimezone),
          chatSource: CHAT_SOURCES.SCHEDULED_MSGS,
        }
      });
    })
    .catch(console.error);
}

export const updateScheduledMsgs = (contact, page, loadDirection, userTimezone) => dispatch => {
  return API.getContactScheduledMsgs(contact.id, page)
    .then(res => {
      dispatch({
        type:
          contact.type === 1
            ? UPDATE_CLIENT_SCHEDULED_MSGS
            : UPDATE_GIRL_SCHEDULED_MSGS,
        payload: {
          ...res.data,
          messages: groupInteractionsByTimezone(res.data.messages, userTimezone),
          userTimezone,
          loadDirection
        }
      });
    })
    .catch(console.error);
}

export const cleanScheduledMsgs = chatType => dispatch => {
  dispatch({
    type:
      chatType === 1
        ? CLEAN_CLIENT_SCHEDULED_MSGS
        : CLEAN_GIRL_SCHEDULED_MSGS
  });
};

export const getScheduledMsgsCount = (contactId, contactType) => dispatch => {
  return API.getScheduledMsgsCount(contactId)
    .then(res => {
      dispatch({
        type: contactType === CHAT_TYPES.CLIENT
          ? GET_CLIENT_SCHEDULED_MSGS_COUNT
          : GET_GIRL_SCHEDULED_MSGS_COUNT,
        payload: res.data,
      })
    });
};

export const getServiceMsgs = (contact, userTimezone, withCancel = true) => dispatch => {
  dispatch({ type: GET_GIRL_TIMELINE_PENDING });

  API.getContactServiceMsgs(contact.id, null, withCancel)
    .then(res => {
      dispatch({
        type: GET_GIRL_SERVICE_MSGS,
        payload: {
          ...res.data,
          messages: groupInteractionsByTimezone(res.data.messages, userTimezone),
          chatSource: CHAT_SOURCES.SYSTEM_MSGS,
        }
      });
    })
    .catch(console.error);
};

export const updateServiceMsgs = (contact, page, loadDirection, userTimezone) => dispatch => {
  API.getContactServiceMsgs(contact.id, page, true)
    .then(res => {
      dispatch({
        type: UPDATE_GIRL_SERVICE_MSGS,
        payload: {
          ...res.data,
          messages: groupInteractionsByTimezone(res.data.messages, userTimezone),
          userTimezone,
          loadDirection,
        }
      })
    })
    .catch(err => console.log(err));
};

export const cleanServiceMsgs = () => dispatch => dispatch({ type: CLEAN_GIRL_SERVICE_MSGS });

export const getServiceMsgsCount = contactId => dispatch => {
  API.getScheduledMsgsCount(contactId)
    .then(res => {
      dispatch({
        type: GET_GIRL_SERVICE_MSGS_COUNT,
        payload: res.data,
      })
    });
};


const initialState = {
  clients: {
    active: null,
    tabs: [],
    pinnedMsg: null,

    timelinePending: false,
    updatePending: false,

    timeline: [],
    timelinePageCount: null,
    timelineLowerLoadedPage: null,
    timelineCurrentPage: null,
    newInteractionType: null,

    search: '',
    isGlobalMsgSearch: false,
    showSearchQuery: false,

    contextMsgId: null,
    contextDate: null,

    chatSource: CHAT_SOURCES.MSGS,

    scheduledMsgsCount: 0,

    auxiliaryTimeline: [],
    auxiliaryPageCount: null,
    auxiliaryLowerLoadedPage: null,
    auxiliaryHigherLoadedPage: null,
    auxiliaryCurrentPage: null,
  },
  girls: {
    active: null,
    tabs: [],
    pinnedMsg: null,

    timelinePending: false,
    updatePending: false,
    timelinePageCount: null,
    timelineLowerLoadedPage: null,
    timelineCurrentPage: null,
    newInteractionType: null,

    timeline: [],

    search: '',
    isGlobalMsgSearch: false,
    showSearchQuery: false,

    contextMsgId: null,
    contextDate: null,

    chatSource: CHAT_SOURCES.MSGS,

    serviceMsgCount: 0,

    auxiliaryTimeline: [],
    auxiliaryPageCount: null,
    auxiliaryLowerLoadedPage: null,
    auxiliaryHigherLoadedPage: null,
    auxiliaryCurrentPage: null,
  }
};

export default (state = initialState, action) => {
  switch (action.type) {

    case INIT_SESSION_CHATS: {
      return {
        ...state,
        [CLIENTS]: {
          ...state[CLIENTS],
          active: action.payload.clientId,
          tabs: [action.payload.clientId],
          timeline: action.payload.clientId === state[CLIENTS].active
            ? state[CLIENTS].timeline
            : []
        },
        [GIRLS]: {
          ...state[GIRLS],
          active: action.payload.girlIds.length
            ? action.payload.activeGirlChat || action.payload.girlIds[0]
            : null,
          tabs: action.payload.girlIds.length
            ? action.payload.girlIds
            : [],
          timeline: action.payload.girlIds.length && action.payload.girlIds[0] === state[GIRLS].active
            ? state[GIRLS].timeline
            : []
        },
      }
    }

    // case GET_GIRLS_FROM_TABS:
    // case GET_CLIENTS_FROM_TABS: {
    //   const type = getContactStateTypeByClientActionType(action.type, GET_CLIENTS_FROM_TABS)

    //   const getUpdatedActive = () => {
    //     if (action.payload.ids.includes(state[type].active)) {
    //       // if we already have our active contact
    //       return state[type].active;
    //     } else if (
    //       !!action.payload.ids.length &&
    //       !action.payload.ids.includes(state[type].active)
    //     ) {
    //       // if our active has been changed => chose new active from tabs
    //       return action.payload.ids[0];
    //     } else return null;
    //   };

    //   return {
    //     ...state,
    //     [type]: {
    //       ...state[type],
    //       tabs: action.payload.ids,
    //       active: getUpdatedActive(),
    //     }
    //   };
    // }

    case SET_CLIENT_UPDATE_PENDING:
      return {
        ...state,
        [CLIENTS]: {
          ...state[CLIENTS],
          updatePending: action.payload,
        },
      };

    case SET_GIRL_UPDATE_PENDING:
      return {
        ...state,
        [GIRLS]: {
          ...state[GIRLS],
          updatePending: action.payload,
        },
      };

    case UPDATE_ACTIVE_CLIENT:
    case UPDATE_ACTIVE_GIRL: {
      const type = getContactStateTypeByClientActionType(action.type, UPDATE_ACTIVE_CLIENT)

      // if we mark msgs as read
      const contactId = action.payload.contact.id;

      if (contactId === state[type].active && !action.payload.showSearchQuery && !action.payload.fromMoreTabs) {
        return state;
      }
      if (contactId === state[type].active && action.payload.showSearchQuery) {
        return {
          ...state,
          [type]: {
            ...state[type],
            isGlobalMsgSearch: action.payload.isGlobalMsgSearch ? true : false,
            showSearchQuery: action.payload.showSearchQuery ? true : false,
            search: action.payload.isGlobalMsgSearch ? state[type].search : '',
          }
        };
      }

      const isInTabs = state[type].tabs.indexOf(contactId) !== -1;

      let updatedTabs = [...state[type].tabs];

      if (action.payload.fromMoreTabs) {
        updatedTabs = [contactId, ...state[type].tabs.filter(id => id !== contactId)];

      }
      else if (!isInTabs) {
        if (updatedTabs.length === 20) { // limit - 20 msgs;
          updatedTabs[19] = contactId;
        }
        else {
          updatedTabs.push(contactId);
        }
      }

      return {
        ...state,
        [type]: {
          ...state[type],
          tabs: updatedTabs,
          active: contactId,
          timeline: [],
          timelinePageCount: null,
          timelineLowerLoadedPage: null,
          timelineCurrentPage: null,

          auxiliaryTimeline: state[type].auxiliaryTimeline.length ? [] : state[type].auxiliaryTimeline,
          auxiliaryCurrentPage: null,
          auxiliaryLowerLoadedPage: null,
          auxiliaryHigherLoadedPage: null,
          auxiliaryPageCount: null,

          contextMsgId: null,
          contextDate: null,
          chatSource: CHAT_SOURCES.MSGS,
          scheduledMsgsCount: 0,

          isGlobalMsgSearch: action.payload.isGlobalMsgSearch ? true : false,
          showSearchQuery: action.payload.showSearchQuery ? true : false,
          search: action.payload.isGlobalMsgSearch ? state[type].search : '',
        }
      };
    }

    case GET_CLIENT_TIMELINE_PENDING:
    case GET_GIRL_TIMELINE_PENDING: {
      const type = getContactStateTypeByClientActionType(action.type, GET_CLIENT_TIMELINE_PENDING)

      return {
        ...state,
        [type]: {
          ...state[type],
          timelinePending: true
        }
      };
    }

    case GET_CLIENT_TIMELINE:
    case GET_GIRL_TIMELINE: {
      const type = getContactStateTypeByClientActionType(action.type, GET_CLIENT_TIMELINE)

      // if (action.payload.contactId === "new_chat") {
      //   return {
      //     ...state,
      //     [type]: {
      //       ...state[type],
      //       timeline: []
      //     }
      //   };
      // }
      if (action.payload.contactId !== state[type].active) {
        return state;
      }

      if (!action.payload.messages.length) {
        return {
          ...state,
          [type]: {
            ...state[type],
            timelinePending: false,
            timelinePageCount: action.payload.pageCount,
            timelineLowerLoadedPage: action.payload.currentPage,
            timelineCurrentPage: action.payload.currentPage,
          }
        };
      }


      return {
        ...state,
        [type]: {
          ...state[type],
          timelinePending: false,
          timeline: action.payload.messages,
          timelinePageCount: action.payload.pageCount,
          timelineLowerLoadedPage: action.payload.currentPage,
          timelineCurrentPage: action.payload.currentPage,
          newInteractionType: null,
          pinnedMsg: action.payload.pinnedMessage,

          search: '',

          contextMsgId: null,
          contextDate: null,
          chatSource: CHAT_SOURCES.MSGS,
        }
      };
    }

    case UPDATE_CLIENT_TIMELINE:
    case UPDATE_GIRL_TIMELINE: {
      const type = getContactStateTypeByClientActionType(action.type, UPDATE_CLIENT_TIMELINE)

      return {
        ...state,
        [type]: {
          ...state[type],
          timeline: onUpdateTimeline(state[type].timeline, action.payload.messages, action.payload.loadDirection, action.payload.userTimezone),
          timelineCurrentPage: action.payload.currentPage,
          // do not update page count if we scroll to top because new msgs can update it dynamically
          timelinePageCount: action.payload.loadDirection === 'up'
            ? state[type].timelinePageCount
            : action.payload.pageCount,
          newInteractionType: null
        }
      };
    }

    case REMOVE_CLIENT_TAB:
    case REMOVE_GIRL_TAB: {
      const type = getContactStateTypeByClientActionType(action.type, REMOVE_CLIENT_TAB)

      return {
        ...state,
        [type]: {
          ...state[type],
          timeline: action.payload === state[type].active ? [] : state[type].timeline,
          tabs: onRemoveTab(state[type].tabs, action.payload),
          active: onRemoveTabUpdateActiveContact(
            state[type].tabs,
            state[type].active,
            action.payload
          )
        }
      };
    }

    case SET_CLIENT_TABS:
    case SET_GIRLS_TABS: {
      const type = getContactStateTypeByClientActionType(action.type, SET_CLIENT_TABS)

      return {
        ...state,
        [type]: {
          ...state[type],
          tabs: action.payload,
        }
      }
    }

    case NEW_OUTGOING_CALL:
    case NEW_INCOMING_QUEUE_CALL:
    case NEW_INCOMING_CALL:
    case NEW_INCOMING_MESSAGE:
    case NEW_OUTGOING_MESSAGE: {
      const contactId = action.payload.interaction.caller_id;
      const animatedMessage = { ...action.payload.interaction, isNew: true };

      // update timeline if contact chat open
      if (state[CLIENTS].active === contactId) {
        return {
          ...state,
          [CLIENTS]: {
            ...state[CLIENTS],
            timeline: onInteraction(state[CLIENTS].timeline, animatedMessage, action.payload.userTimezone),
            newInteractionType: animatedMessage.type
          }
        };
      }
      else if (state[GIRLS].active === contactId) {
        return {
          ...state,
          [GIRLS]: {
            ...state[GIRLS],
            timeline: onInteraction(state[GIRLS].timeline, animatedMessage, action.payload.userTimezone),
            newInteractionType: animatedMessage.type
          }
        };
      }
      // if contact in chatTabs and new incoming message from him => move him to the begging of the tabs
      if (action.type === NEW_INCOMING_MESSAGE && state[CLIENTS].tabs.includes(contactId)) {
        const updatedTabs = state[CLIENTS].tabs.filter(id => id !== contactId);

        updatedTabs.unshift(contactId);

        return {
          ...state,
          [CLIENTS]: {
            ...state[CLIENTS],
            tabs: updatedTabs,
          }
        }
      }
      else if (action.type === NEW_INCOMING_MESSAGE && state[GIRLS].tabs.includes(contactId)) {
        const updatedTabs = state[GIRLS].tabs.filter(id => id !== contactId);

        updatedTabs.unshift(contactId);

        return {
          ...state,
          [GIRLS]: {
            ...state[GIRLS],
            tabs: updatedTabs,
          }
        }
      }

      return state;
    }

    case NEW_SCHEDULED_MESSAGE: {
      const contactId = action.payload.interaction.caller_id;

      // update timeline if contact chat open
      if (state[CLIENTS].active === contactId && state[CLIENTS].chatSource === CHAT_SOURCES.SCHEDULED_MSGS) {
        return {
          ...state,
          [CLIENTS]: {
            ...state[CLIENTS],
            auxiliaryTimeline: onInteraction(state[CLIENTS].auxiliaryTimeline, action.payload.interaction, action.payload.userTimezone),
            newInteractionType: action.payload.interaction.type,
            scheduledMsgsCount: state[GIRLS].scheduledMsgsCount + 1
          }
        };
      }
      else if (state[GIRLS].active === contactId) {
        return {
          ...state,
          [GIRLS]: {
            ...state[GIRLS],
            auxiliaryTimeline: onInteraction(state[GIRLS].auxiliaryTimeline, action.payload.interaction, action.payload.userTimezone),
            newInteractionType: action.payload.interaction.type,
            scheduledMsgsCount: state[GIRLS].scheduledMsgsCount + 1
          }
        };
      }
      return state;
    }

    case NEW_SERVICE_MESSAGE: {
      const contactId = action.payload.interaction.caller_id;

      if (state[GIRLS].active === contactId) {
        return {
          ...state,
          [GIRLS]: {
            ...state[GIRLS],
            auxiliaryTimeline: onInteraction(state[GIRLS].auxiliaryTimeline, action.payload.interaction, action.payload.userTimezone),
            newInteractionType: action.payload.interaction.type,
            serviceMsgCount: state[GIRLS].serviceMsgCount + 1
          }
        };
      }
      return state;
    }

    case CHANGE_MESSAGE_STATUS:
    case CHANGE_CALL_STATUS: {
      if (
        state[CLIENTS].active &&
        (state[CLIENTS].active === action.payload.caller_id || // if smsStatus
          state[CLIENTS].active === action.payload.callerId)
      ) {
        return {
          ...state,
          [CLIENTS]: {
            ...state[CLIENTS],
            timeline: onStatusChange(state[CLIENTS].timeline, action.payload)
          }
        };
      }
      else if (
        state[GIRLS].active &&
        (state[GIRLS].active === action.payload.caller_id || // if smsStatus
          state[GIRLS].active === action.payload.callerId)
      ) {
        return {
          ...state,
          [GIRLS]: {
            ...state[GIRLS],
            timeline: onStatusChange(state[GIRLS].timeline, action.payload)
          }
        };
      }

      return state;
    }

    case NEW_MESSAGE_ATTACHMENTS: {
      if (state[CLIENTS].active && state[CLIENTS].active === action.payload.caller_id) {
        return {
          ...state,
          [CLIENTS]: {
            ...state[CLIENTS],
            timeline: onAttachmentsAdding(state[CLIENTS].timeline, action.payload),
            newInteractionType: INTERACTION_TYPES.MSG_ATTACHMENT
          }
        };
      }
      else if (state[GIRLS].active && state[GIRLS].active === action.payload.caller_id) {
        return {
          ...state,
          [GIRLS]: {
            ...state[GIRLS],
            timeline: onAttachmentsAdding(state[GIRLS].timeline, action.payload),
            newInteractionType: INTERACTION_TYPES.MSG_ATTACHMENT
          }
        };
      }

      return state;
    }

    case UNREAD_CLIENT_MESSAGES:
    case UNREAD_GIRL_MESSAGES: {
      const type = getContactStateTypeByClientActionType(action.type, UNREAD_CLIENT_MESSAGES)

      return {
        ...state,
        [type]: {
          ...state[type],
          timeline: updateUnreadMsgsInTimeline(state[type].timeline, action.payload.interaction)
        }
      };
    }

    case GET_CLIENT_MSG_REMINDERS:
    case GET_GIRL_MSG_REMINDERS:
    case GET_CLIENT_SCHEDULED_MSGS:
    case GET_GIRL_SCHEDULED_MSGS:
    case GET_GIRL_SERVICE_MSGS: {
      const type = getContactStateTypeByClientActionType(action.type, [GET_CLIENT_MSG_REMINDERS, GET_CLIENT_SCHEDULED_MSGS])

      return {
        ...state,
        [type]: {
          ...state[type],
          timelinePending: false,

          contextDate: null,
          chatSource: action.payload.chatSource,

          auxiliaryTimeline: action.payload.messages,
          auxiliaryPageCount: action.payload.pageCount,
          auxiliaryLowerLoadedPage: action.payload.currentPage,
          auxiliaryHigherLoadedPage: action.payload.currentPage,
          auxiliaryCurrentPage: action.payload.currentPage,
        }
      };
    }
    case UPDATE_CLIENT_SCHEDULED_MSGS:
    case UPDATE_GIRL_SCHEDULED_MSGS:
    case UPDATE_CLIENT_MSG_REMINDERS:
    case UPDATE_GIRL_MSG_REMINDERS:
    case UPDATE_GIRL_SERVICE_MSGS: {
      const type = getContactStateTypeByClientActionType(action.type, [UPDATE_CLIENT_MSG_REMINDERS, UPDATE_CLIENT_SCHEDULED_MSGS])

      return {
        ...state,
        [type]: {
          ...state[type],
          auxiliaryTimeline: onUpdateTimeline(state[type].auxiliaryTimeline, action.payload.messages, action.payload.loadDirection, action.payload.userTimezone),
          auxiliaryPageCount: action.payload.loadDirection === 'up'
            ? state[type].auxiliaryPageCount
            : action.payload.pageCount,
          auxiliaryHigherLoadedPage: state[type].auxiliaryHigherLoadedPage > action.payload.currentPage
            ? action.payload.currentPage
            : state[type].auxiliaryHigherLoadedPage,
          auxiliaryLowerLoadedPage: state[type].auxiliaryLowerLoadedPage > action.payload.currentPage
            ? state[type].auxiliaryLowerLoadedPage
            : action.payload.currentPage,
          auxiliaryCurrentPage: action.payload.currentPage,
        }
      };
    }

    case REMOVE_CLIENT_MSG_REMIND:
    case REMOVE_GIRL_MSG_REMIND: {
      const type = getContactStateTypeByClientActionType(action.type, REMOVE_CLIENT_MSG_REMIND)

      const updatedReminders = [];

      state[type].auxiliaryTimeline.forEach(remindersByDate => {
        const updatedRemindersByDate = remindersByDate.filter(remind => remind.id !== action.payload);

        if (updatedRemindersByDate.length) {
          updatedReminders.push(updatedRemindersByDate);
        }
      })

      return {
        ...state,
        [type]: {
          ...state[type],
          auxiliaryTimeline: updatedReminders
        }
      }
    }

    case REMOVE_CLIENT_REMINDER_FROM_MAIN_TIMELINE:
    case REMOVE_GIRL_REMINDER_FROM_MAIN_TIMELINE: {
      const type = getContactStateTypeByClientActionType(action.type, REMOVE_CLIENT_REMINDER_FROM_MAIN_TIMELINE);

      const updatedTimeline = [...state[type].timeline];
      const updatedLastGroup = updatedTimeline[updatedTimeline.length - 1]
        .filter(interaction => interaction.type !== 7 && interaction.id !== action.payload);

      updatedTimeline[updatedTimeline.length - 1] = updatedLastGroup;

      return {
        ...state,
        [type]: {
          ...state[type],
          timeline: updatedTimeline,
        }
      };
    }

    case CLEAN_CLIENT_SCHEDULED_MSGS:
    case CLEAN_GIRL_SCHEDULED_MSGS:
    case CLEAN_CLIENT_MSG_REMINDERS:
    case CLEAN_GIRL_MSG_REMINDERS:
    case CLEAN_GIRL_SERVICE_MSGS: {
      const type = getContactStateTypeByClientActionType(action.type, [CLEAN_CLIENT_MSG_REMINDERS, CLEAN_CLIENT_SCHEDULED_MSGS])

      if (state[type].search) {
        return state;
      }

      return {
        ...state,
        [type]: {
          ...state[type],

          chatSource: CHAT_SOURCES.MSGS,

          auxiliaryCurrentPage: null,
          auxiliaryLowerLoadedPage: null,
          auxiliaryHigherLoadedPage: null,
          auxiliaryPageCount: null,
          auxiliaryTimeline: state[type].auxiliaryTimeline.length ? [] : state[type].auxiliaryTimeline,
          serviceMsgCount: state[type].chatSource === CHAT_SOURCES.SYSTEM_MSGS
            ? 0
            : state[type].serviceMsgCount
        }
      };
    }

    case GET_CLIENT_CONVERSATION_MEDIA:
    case GET_GIRL_CONVERSATION_MEDIA: {
      const type = getContactStateTypeByClientActionType(action.type, GET_CLIENT_CONVERSATION_MEDIA)

      return {
        ...state,
        [type]: {
          ...state[type],
          timelinePending: false,

          contextDate: null,
          chatSource: action.payload.chatSource,

          auxiliaryTimeline: action.payload.messages,
          auxiliaryPageCount: action.payload.pageCount,
          auxiliaryLowerLoadedPage: action.payload.currentPage,
          auxiliaryHigherLoadedPage: action.payload.currentPage,
          auxiliaryCurrentPage: action.payload.currentPage,
        }
      };
    }

    case UPDATE_CLIENT_CONVERSATION_MEDIA:
    case UPDATE_GIRL_CONVERSATION_MEDIA: {
      const type = getContactStateTypeByClientActionType(action.type, UPDATE_CLIENT_CONVERSATION_MEDIA)

      return {
        ...state,
        [type]: {
          ...state[type],
          auxiliaryTimeline: onUpdateTimeline(state[type].auxiliaryTimeline, action.payload.messages, action.payload.loadDirection, action.payload.userTimezone),
          auxiliaryPageCount: action.payload.loadDirection === 'up'
            ? state[type].auxiliaryPageCount
            : action.payload.pageCount,
          auxiliaryHigherLoadedPage: state[type].auxiliaryHigherLoadedPage > action.payload.currentPage
            ? action.payload.currentPage
            : state[type].auxiliaryHigherLoadedPage,
          auxiliaryLowerLoadedPage: state[type].auxiliaryLowerLoadedPage > action.payload.currentPage
            ? state[type].auxiliaryLowerLoadedPage
            : action.payload.currentPage,
          auxiliaryCurrentPage: action.payload.currentPage,
        }
      };
    }

    case CLEAN_CLIENT_CONVERSATION_MEDIA:
    case CLEAN_GIRL_CONVERSATION_MEDIA: {
      const type = getContactStateTypeByClientActionType(action.type, CLEAN_CLIENT_CONVERSATION_MEDIA)

      if (state[type].search) {
        return state;
      }

      return {
        ...state,
        [type]: {
          ...state[type],

          auxiliaryTimeline: state[type].auxiliaryTimeline.length ? [] : state[type].auxiliaryTimeline
        }
      };
    }

    case GET_CLIENT_MSG_CONTEXT:
    case GET_GIRL_MSG_CONTEXT: {
      const type = getContactStateTypeByClientActionType(action.type, GET_CLIENT_MSG_CONTEXT)

      return {
        ...state,
        [type]: {
          ...state[type],
          timelinePending: false,
          isGlobalMsgSearch: false,
          showSearchQuery: false,

          contextMsgId: action.payload.contextMsgId,
          contextDate: null,
          chatSource: CHAT_SOURCES.MSGS,

          auxiliaryCurrentPage: action.payload.currentPage,
          auxiliaryLowerLoadedPage: action.payload.currentPage,
          auxiliaryHigherLoadedPage: action.payload.currentPage,
          auxiliaryPageCount: action.payload.pageCount,
          auxiliaryTimeline: action.payload.messages
        }
      };
    }

    case UPDATE_CLIENT_MSG_CONTEXT:
    case UPDATE_GIRL_MSG_CONTEXT: {
      const type = getContactStateTypeByClientActionType(action.type, UPDATE_CLIENT_MSG_CONTEXT)

      //exit from context
      // if (action.payload.currentPage === action.payload.pageCount && action.payload.loadDirection === 'down') {
      //   return {
      //     ...state,
      //     [type]: {
      //       ...state[type],
      //       timeline: onUpdateTimeline(state[type].auxiliaryTimeline, action.payload.messages, action.payload.loadDirection, action.payload.userTimezone),
      //       timelinePageCount: action.payload.pageCount,
      //       timelineLowerLoadedPage: action.payload.currentPage,
      //       timelineCurrentPage: state[type].auxiliaryHigherLoadedPage,

      //       auxiliaryCurrentPage: null,
      //       auxiliaryLowerLoadedPage: null,
      //       auxiliaryHigherLoadedPage: null,
      //       auxiliaryPageCount: null,
      //       auxiliaryTimeline: state[type].auxiliaryTimeline.length ? [] : state[type].auxiliaryTimeline,

      //       contextMsgId: null,
      //       search: '',
      //     }
      //   }
      // }

      return {
        ...state,
        [type]: {
          ...state[type],
          auxiliaryTimeline: onUpdateTimeline(state[type].auxiliaryTimeline, action.payload.messages, action.payload.loadDirection, action.payload.userTimezone),
          auxiliaryCurrentPage: action.payload.currentPage,

          auxiliaryHigherLoadedPage: state[type].auxiliaryHigherLoadedPage > action.payload.currentPage
            ? action.payload.currentPage
            : state[type].auxiliaryHigherLoadedPage,
          auxiliaryLowerLoadedPage: state[type].auxiliaryLowerLoadedPage > action.payload.currentPage
            ? state[type].auxiliaryLowerLoadedPage
            : action.payload.currentPage,
          auxiliaryPageCount: action.payload.loadDirection === 'up'
            ? state[type].auxiliaryPageCount
            : action.payload.pageCount,
        }
      }
    }

    case CLEAN_CLIENT_MSG_CONTEXT:
    case CLEAN_GIRL_MSG_CONTEXT: {
      const type = getContactStateTypeByClientActionType(action.type, CLEAN_CLIENT_MSG_CONTEXT)

      return {
        ...state,
        [type]: {
          ...state[type],
          contextMsgId: null,
          auxiliaryCurrentPage: null,
          auxiliaryLowerLoadedPage: null,
          auxiliaryHigherLoadedPage: null,
          auxiliaryPageCount: null,
          auxiliaryTimeline: state[type].auxiliaryTimeline.length ? [] : state[type].auxiliaryTimeline,
        }
      }
    }

    case GET_CLIENT_DATE_MSG_CONTEXT:
    case GET_GIRL_DATE_MSG_CONTEXT: {
      const type = getContactStateTypeByClientActionType(action.type, GET_CLIENT_DATE_MSG_CONTEXT)

      return {
        ...state,
        [type]: {
          ...state[type],
          timelinePending: false,
          search: '',
          contextMsgId: null,
          chatSource: CHAT_SOURCES.MSGS,

          contextDate: action.payload.contextDate,
          auxiliaryCurrentPage: action.payload.currentPage,
          auxiliaryLowerLoadedPage: action.payload.currentPage,
          auxiliaryHigherLoadedPage: action.payload.currentPage,
          auxiliaryPageCount: action.payload.pageCount,
          auxiliaryTimeline: action.payload.messages
        }
      }
    }

    case UPDATE_CLIENT_DATE_MSG_CONTEXT:
    case UPDATE_GIRL_DATE_MSG_CONTEXT: {
      const type = getContactStateTypeByClientActionType(action.type, UPDATE_CLIENT_DATE_MSG_CONTEXT)

      // if (action.payload.currentPage === action.payload.pageCount && action.payload.loadDirection === 'down') {
      //   return {
      //     ...state,
      //     [type]: {
      //       ...state[type],
      //       timeline: onUpdateTimeline(state[type].auxiliaryTimeline, action.payload.messages, action.payload.loadDirection, action.payload.userTimezone),
      //       timelinePageCount: action.payload.pageCount,
      //       timelineLowerLoadedPage: action.payload.currentPage,
      //       timelineCurrentPage: state[type].auxiliaryHigherLoadedPage,

      //       auxiliaryCurrentPage: null,
      //       auxiliaryLowerLoadedPage: null,
      //       auxiliaryHigherLoadedPage: null,
      //       auxiliaryPageCount: null,
      //       auxiliaryTimeline: state[type].auxiliaryTimeline.length ? [] : state[type].auxiliaryTimeline,

      //       contextDate: null,
      //       search: '',
      //     }
      //   }
      // }
      return {
        ...state,
        [type]: {
          ...state[type],

          auxiliaryTimeline: onUpdateTimeline(state[type].auxiliaryTimeline, action.payload.messages, action.payload.loadDirection, action.payload.userTimezone),
          auxiliaryCurrentPage: action.payload.currentPage,

          auxiliaryHigherLoadedPage: state[type].auxiliaryHigherLoadedPage > action.payload.currentPage
            ? action.payload.currentPage
            : state[type].auxiliaryHigherLoadedPage,
          auxiliaryLowerLoadedPage: state[type].auxiliaryLowerLoadedPage > action.payload.currentPage
            ? state[type].auxiliaryLowerLoadedPage
            : action.payload.currentPage,
          auxiliaryPageCount: action.payload.loadDirection === 'up'
            ? state[type].auxiliaryPageCount
            : action.payload.pageCount,
        }
      }
    }

    case CLEAN_CLIENT_DATE_MSG_CONTEXT:
    case CLEAN_GIRL_DATE_MSG_CONTEXT: {
      const type = getContactStateTypeByClientActionType(action.type, CLEAN_CLIENT_DATE_MSG_CONTEXT)

      return {
        ...state,
        [type]: {
          ...state[type],
          contextDate: null,
          auxiliaryCurrentPage: null,
          auxiliaryLowerLoadedPage: null,
          auxiliaryHigherLoadedPage: null,
          auxiliaryPageCount: null,
          auxiliaryTimeline: state[type].auxiliaryTimeline.length ? [] : state[type].auxiliaryTimeline,
        }
      }
    }

    case GET_SEARCHED_CLIENT_MESSAGES:
    case GET_SEARCHED_GIRL_MESSAGES: {
      const type = getContactStateTypeByClientActionType(action.type, GET_SEARCHED_CLIENT_MESSAGES)

      return {
        ...state,
        [type]: {
          ...state[type],
          search: action.payload.query,
          timelinePending: false,

          auxiliaryCurrentPage: action.payload.currentPage,
          auxiliaryLowerLoadedPage: action.payload.currentPage,
          auxiliaryHigherLoadedPage: action.payload.currentPage,
          auxiliaryPageCount: action.payload.pageCount,
          auxiliaryTimeline: action.payload.messages,

          contextMsgId: null,
          contextDate: null,
          chatSource: CHAT_SOURCES.MSGS,
          isGlobalMsgSearch: state[type].isGlobalMsgSearch
            ? false
            : state[type].isGlobalMsgSearch
        }
      }
    }

    case UPDATE_GLOBAL_CLIENT_MESSAGE_SEARCH:
    case UPDATE_SEARCHED_CLIENT_MESSAGES:
    case UPDATE_GLOBAL_GIRL_MESSAGE_SEARCH:
    case UPDATE_SEARCHED_GIRL_MESSAGES: {
      const type = getContactStateTypeByClientActionType(
        action.type, [UPDATE_GLOBAL_CLIENT_MESSAGE_SEARCH, UPDATE_SEARCHED_CLIENT_MESSAGES]
      );

      return {
        ...state,
        [type]: {
          ...state[type],
          auxiliaryCurrentPage: action.payload.currentPage,
          auxiliaryHigherLoadedPage: state[type].auxiliaryHigherLoadedPage > action.payload.currentPage
            ? action.payload.currentPage
            : state[type].auxiliaryHigherLoadedPage,
          auxiliaryLowerLoadedPage: state[type].auxiliaryLowerLoadedPage > action.payload.currentPage
            ? state[type].auxiliaryLowerLoadedPage
            : action.payload.currentPage,
          auxiliaryTimeline: onUpdateTimeline(state[type].auxiliaryTimeline, action.payload.messages, action.payload.loadDirection, action.payload.userTimezone),
          auxiliaryPageCount: action.payload.loadDirection === 'up'
            ? state[type].auxiliaryPageCount
            : action.payload.pageCount,
          updatePending: false,
        }
      }
    }

    case STOP_SEARCH_CLIENT_MESSAGES:
    case STOP_SEARCH_GIRL_MESSAGES: {
      const type = getContactStateTypeByClientActionType(action.type, STOP_SEARCH_CLIENT_MESSAGES);

      return {
        ...state,
        [type]: {
          ...state[type],
          search: '',
          auxiliaryTimeline: state[type].auxiliaryTimeline.length ? [] : state[type].auxiliaryTimeline,
          auxiliaryPageCount: null,
          auxiliaryLowerLoadedPage: null,
          auxiliaryHigherLoadedPage: null,
          auxiliaryCurrentPage: null,

          contextMsgId: null,
          isGlobalMsgSearch: false
        }
      }
    }

    case PIN_CLIENT_MSG:
    case PIN_GIRL_MSG: {
      const type = getContactStateTypeByClientActionType(action.type, PIN_CLIENT_MSG);

      return {
        ...state,
        [type]: {
          ...state[type],
          pinnedMsg: action.payload
        }
      }
    }

    case NEW_MISSED_CALL: {
      const isClientActive = state[CLIENTS].active === +action.payload.id.split('_')[0];
      const isGirlActive = state[GIRLS].active === +action.payload.id.split('_')[0];

      if (isClientActive) {
        const newTimeline = state[CLIENTS].timeline.map(group => {
          return group.map(msg => {
            if (msg.id === action.payload.entity.id) {
              return action.payload.entity;
            }
            return msg;
          });
        });

        return {
          ...state,
          [CLIENTS]: {
            ...state[CLIENTS],
            timeline: newTimeline
          }
        }
      }
      else if (isGirlActive) {
        const newTimeline = state[GIRLS].timeline.map(group => {
          return group.map(msg => {
            if (msg.id === action.payload.entity.id) {
              return action.payload.entity;
            }
            return msg;
          });
        });

        return {
          ...state,
          [GIRLS]: {
            ...state[GIRLS],
            timeline: newTimeline
          }
        }
      }

      return state;
    }

    case UPDATE_VOICEMAIL_STATUS: {
      const isClientActive = state[CLIENTS].active === action.payload.callerId;
      const isGirlActive = state[GIRLS].active === action.payload.callerId;

      if (isClientActive) {
        const newTimeline = state[CLIENTS].timeline.map(group => {
          return group.map(msg => {
            if (msg.id === action.payload.callId) {
              return { ...msg, voicemail: action.payload.voicemail };
            }
            return msg;
          });
        });

        return {
          ...state,
          [CLIENTS]: {
            ...state[CLIENTS],
            timeline: newTimeline
          }
        }
      }
      else if (isGirlActive) {
        const newTimeline = state[GIRLS].timeline.map(group => {
          return group.map(msg => {
            if (msg.id === action.payload.callId) {
              return { ...msg, voicemail: action.payload.voicemail };
            }
            return msg;
          });
        });

        return {
          ...state,
          [GIRLS]: {
            ...state[GIRLS],
            timeline: newTimeline
          }
        }
      }

      return state;
    }

    case UPDATE_CLIENT_MSG:
    case UPDATE_GIRL_MSG: {
      const type = getContactStateTypeByClientActionType(action.type, UPDATE_CLIENT_MSG);
      let updatedTimeline = 'timeline';

      if (action.payload.type === 11 && state[type].chatSource === CHAT_SOURCES.SCHEDULED_MSGS) {
        updatedTimeline = 'auxiliaryTimeline'
      }

      return {
        ...state,
        [type]: {
          ...state[type],
          [updatedTimeline]: state[type][updatedTimeline].map(group => {
            return group.map(msg => {
              if (msg.id === action.payload.id) {
                return {
                  ...msg,
                  body: action.payload.body,
                  edited: action.payload.edited || true,
                  date: action.payload.date ? action.payload.date : null,
                  hasUrls: action.payload.hasUrls || msg.hasUrls,
                };
              }
              return msg;
            });
          }),
        }
      };
    }

    case DELETE_CLIENT_MSG:
    case DELETE_GIRL_MSG: {
      const type = getContactStateTypeByClientActionType(action.type, DELETE_CLIENT_MSG);
      let updatedTimelineName = 'timeline';

      if (action.payload.type === 11 && state[type].chatSource === CHAT_SOURCES.SCHEDULED_MSGS ||
        action.payload.type === 4 && state[type].chatSource === CHAT_SOURCES.SYSTEM_MSGS
      ) {
        updatedTimelineName = 'auxiliaryTimeline'
      }

      const updatedTimeline = [];

      state[type][updatedTimelineName].forEach(group => {
        const updatedGroup = group.filter(msg => msg.id !== action.payload.id);

        if (updatedGroup.length) {
          updatedTimeline.push(updatedGroup);
        }
      })

      return {
        ...state,
        [type]: {
          ...state[type],
          [updatedTimelineName]: updatedTimeline,
          scheduledMsgsCount: action.payload.type === 11 && state[type].scheduledMsgsCount >= 1
            ? state[type].scheduledMsgsCount - 1
            : state[type].scheduledMsgsCount
        }
      };
    }

    case GET_CLIENT_SCHEDULED_MSGS_COUNT:
    case GET_GIRL_SCHEDULED_MSGS_COUNT: {
      const type = getContactStateTypeByClientActionType(action.type, GET_CLIENT_SCHEDULED_MSGS_COUNT);

      if (action.payload === state[type].scheduledMsgsCount) return state;

      return {
        ...state,
        [type]: {
          ...state[type],
          scheduledMsgsCount: action.payload,
        }
      }
    }

    case GET_GIRL_SERVICE_MSGS_COUNT: {
      const type = getContactStateTypeByClientActionType(action.type, []);

      if (action.payload === state[type].serviceMsgCount) return state;

      return {
        ...state,
        [type]: {
          ...state[type],
          serviceMsgCount: action.payload,
        }
      }
    }

    case DELETE_TELEGRAM_MSG: {
      const { clients, girls } = state;
      const { id } = action.payload;

      return {
        ...state,
        clients: {
          ...clients,
          timeline: removeFromTimeline(clients.timeline, id),
          auxiliaryTimeline: removeFromTimeline(clients.auxiliaryTimeline, id),
        },
        girls: {
          ...girls,
          timeline: removeFromTimeline(girls.timeline, id),
          auxiliaryTimeline: removeFromTimeline(girls.auxiliaryTimeline, id),
        },
      };
    }

    default:
      return state;
  }
};

// Redux helpers start

const getContactStateTypeByClientActionType = (actionType, clientActionType) => {
  if (Array.isArray(clientActionType)) {
    return clientActionType.includes(actionType)
      ? CLIENTS
      : GIRLS
  }

  return actionType === clientActionType
    ? CLIENTS
    : GIRLS;
}
// Redux helpers stop
