import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import Api from '../../../../api/api';
import { getDefaultField } from '../../../../utils';
import { openModal, MODAL_TYPES } from '../../../../ducks/activeWindows';
import { CARD_TABS } from '../../../../config/constants';

import Select from '../../../../components/Select/Select';
import CallButton from '../../../../components/CallButton/CallButton';
// import CopyToClipboardButton from '../../../../components/CopyToClipboardButton/CopyToClipboardButton';

const ContactTels = (props) => {
  const { tels = [] } = props.activeRecipient;

  const [defaultTel, setDefaultTel] = useState(getDefaultField(tels, 'tel'));
  const dispatch = useDispatch();

  useEffect(() => {
    setDefaultTel(getDefaultField(tels, 'tel'));
  }, [props.activeRecipient]);


  const onChange = (option) => {
    if (defaultTel === option.value) return;

    Api.changeDefaultContactTel(option.id)
      .then(res => setDefaultTel(option.value))
      .catch(err => console.error(err.response.data.message));
  }

  const openEditContactForm = () => {
      return dispatch(openModal(MODAL_TYPES.contactCard, {
          contact: props.activeRecipient.id,
          defaultTab: CARD_TABS.EDIT,
          isAddPhoneNumberOnMount: true,
      }));
  };

  const options = useMemo(() => tels.length === 1
      ? [ { value: '' }, { action: openEditContactForm, value: 'Add New Number' }, { value: '' } ]
      : [ ...tels.map(tel => ({ id: tel.id, value: tel.tel, })), { action: openEditContactForm, value: 'Add New Number' }]
      , [tels]);

  return (
    <div className="chat-header__row">
      {props.callFromChat &&
        <CallButton activeRecipient={props.activeRecipient} isConfirm />
      }

      <div
          onMouseDown={() => !defaultTel ? openEditContactForm() : null}
          style={defaultTel ? null : { cursor: 'pointer' }}
      >
        <Select
          classPrefix={defaultTel ? "tel" : "empty-tel"}
          options={options}
          getOptionValue={option => option.value}
          isDisabled={!tels.length}
          defaultValue={defaultTel || "Add Number"}
          onInputChange={onChange}
        />
      </div>

      {/* {props.isCopyEnable &&
        <CopyToClipboardButton
          className='tel__copy'
          value={defaultTel}
          disabled={!defaultTel}
          svgWidth='12'
          svgHeight='12'
        />
      } */}

    </div>
  )
}

export default ContactTels;