import React, { useState, useMemo } from 'react';
import { connect } from 'react-redux';

import { classModifier } from '../../../../utils';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { openModal, MODAL_TYPES } from '../../../../ducks/activeWindows';

import LazyLoadImage from '../../../../components/LazyLoadImage/LazyLoadImage';


const AttachmentImages = ({ images, openModal }) => {

  const contentLimitCount = 6;

  const [isHidden, setIsHidden] = useState(images.length > contentLimitCount);

  const handleOnClick = (e) => {
    if (e.target.hasAttribute('data-img-src')) {
      const src = e.target.getAttribute('data-img-src');

      openModal(MODAL_TYPES.image, { src });
    }
  };

  const download = (e, imgUrlsList) => {
    e.preventDefault();

    imgUrlsList.forEach(src => {
      fetch(src)
        .then(response => {
          response.blob().then(blob => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            const imageName = src.split('/')
              .reverse()[0]
              .split('.')[0];

            a.href = url;
            a.download = `${imageName}.jpg`;
            a.click();
          });
        })
        .catch(console.log);
    })
  }

  const currImgs = useMemo(() => images.map(img => img.src), []);

  return (
    <div className="attachments__images">
      <h4 className="attachments__title">Attached images</h4>

      {currImgs.length > 1 && 
        <button className={classModifier("attachment-img__btn", "download-all")} title="download all">
          <FontAwesomeIcon size="lg" icon={faArrowDown} onClick={(e) => download(e, currImgs)}/>
        </button>
      }

      <div className={classModifier("attachment-img", isHidden && 'hidden')}>
        {images.map((img, idx) => (
          <div key={idx} className="attachment-img__img-wrap">
            <LazyLoadImage
              src={img.url}
              data-img-src={img.url}
              data-attach-img
              onClick={handleOnClick}
              className="attachment-img__img"
              alt="msg img"
              spinnerSize={20}
            />
            <button 
              className={classModifier("attachment-img__btn", "download")} 
              onClick={(e) => download(e, [img.src])} 
              title="download"
            >
              <FontAwesomeIcon size="lg" icon={faArrowDown} />
            </button>
          </div>
        ))}
        {isHidden && 
          <button className="attachment-img__btn" onClick={() => setIsHidden(!isHidden)}>
            +{images.length - contentLimitCount}
          </button>
        }
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  openModal,
};

export default connect(null, mapDispatchToProps)(AttachmentImages);