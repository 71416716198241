import React, { useEffect } from 'react';

import './DropWrapper.scss';


const DropWrapper = ({ dropWrapperRef, closeDropWrapper, className, style, onClose, ...props }) => {
  useEffect(() => {
    const closeModalOnOutsideClick = (e) => {
      if (!dropWrapperRef.current.contains(e.target) || e.key === 'Escape') {
        if (onClose && onClose()){
          return;
        }
        closeDropWrapper(false);
      }
    };

    document.addEventListener('mousedown', closeModalOnOutsideClick);
    document.addEventListener('keyup', closeModalOnOutsideClick);

    return () => {
      document.removeEventListener('mousedown', closeModalOnOutsideClick);
      document.removeEventListener('keyup', closeModalOnOutsideClick);
    };
  }, [onClose]);

  return (
    <div className={className} style={style}>
      {props.children}
    </div>
  );
};

export default DropWrapper;