import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';

import './DropdownMenu.scss';
import { classModifier } from '../../utils';

const DropdownMenu = props => {
  const [submenu, setSubmenu] = useState(null);

  useEffect(() => {
    document.addEventListener('click', props.closeDropdownMenu);

    return () => {
      document.removeEventListener('click', props.closeDropdownMenu);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submenuSwitcher = (e, idx) =>
    e.type === 'mouseenter'
      ? setSubmenu(idx)
      : setSubmenu(null);

  const { items = [], header: Header } = props;

  console.log('DROPDOWN MENU - render');

  return (
    <div
      className={classModifier('dropdown-menu', props.position)}
      style={props.style}>
      {Header && <div className="dropdown-menu__header">
        <Header />
      </div>}
      <ul className="dropdown-menu__list">
        {items.map((menuItem, idx) => {
          const { icon, isDisable, name, submenu: Submenu, submenuProps = {} } = menuItem;

          return (
            <li
              className={classModifier(
                'dropdown-menu__item',
                [
                  icon && menuItem.iconPosition,
                  isDisable && 'disabled'
                ]
              )}
              onMouseEnter={Submenu && !isDisable && (e => submenuSwitcher(e, idx))}
              onMouseLeave={Submenu && !isDisable && (e => submenuSwitcher(e, idx))}
              onMouseOver={menuItem.onMouseOver}
              key={name}>

              <button
                className="dropdown-menu__item-btn"
                onClick={menuItem.action}
                disabled={isDisable}>
                {icon && <span className="dropdown-menu__item-icon">
                  <img src={icon} alt="" />
                </span>}
                {name}
              </button>
              {Submenu && submenu === idx
                && <div className="dropdown-menu__submenu">
                  <Submenu {...submenuProps} />
                </div>}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

// DropdownMenu.defaultProps = {
//   position: '',
// };

// DropdownMenu.propTypes = {
//   items: PropTypes.arrayOf(PropTypes.shape({
//     name: PropTypes.string.isRequired,
//     action: PropTypes.func,
//     submenu: PropTypes.elementType,
//     icon: PropTypes.string, // url
//     iconPosition: PropTypes.oneOf(['right']),
//     onMouseOver: PropTypes.func,
//     isDisable: PropTypes.any,
//   })).isRequired,
//   header: PropTypes.elementType,
//   closeDropdownMenu: PropTypes.func.isRequired,
//   position: PropTypes.oneOf(['right', 'center', 'left', 'fixed', 'fixed-right', '', 'top']),
// };

export default DropdownMenu;
