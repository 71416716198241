import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReply, faMicrophone, faVideo, faImage } from '@fortawesome/free-solid-svg-icons';

import { formatNotificationDate, getContactAvatar, getDateByTimezoneOffset, isMineInteraction } from '../../../../utils';

import MsgText from '../ChatTimeline/interactions/MsgText';

const AttachmentRepliedMessage = ({ interaction, profileId, ...props }) => {
  const getSender = () => {
    let sender;
    let senderName;

    if (interaction.user) {
      sender = interaction.user;
      senderName = interaction.user.id === profileId ? 'You' : interaction.user.username;
    }
    else {
      sender = interaction.caller || {};
      senderName = interaction.caller?.fn || 'Unknown';
    }

    return { sender, senderName };
  };

  const replyBtnClickHandler = () => {
    props.getMessageContext(interaction.id, interaction.caller, '')
  }

  const { sender, senderName } = getSender();

  return (
    <div className={`${props.className ? props.className : ""} attachment-reply`}>
      <div className="attachment-reply__header">
        <button className="attachment-reply__reply-btn" type="button" onClick={replyBtnClickHandler}>
          <FontAwesomeIcon icon={faReply} />
        </button>

        <div className="attachment-reply__ava">
          <img src={getContactAvatar(sender)} alt="ava" />
        </div>

        <div>
          <p className="attachment-reply__name">{senderName}</p>
          <time className="attachment-reply__time">
            {formatNotificationDate(getDateByTimezoneOffset(props.userTimezone, interaction.dateCreated), props.userHour12)}
          </time>
        </div>
      </div>

      <div className="attachment-reply__content">
        {interaction.body && <MsgText interaction={interaction} />}

        {interaction.attachments &&
          <div className="attachment-reply__attachments">
            {interaction.attachments?.videos && <FontAwesomeIcon icon={faVideo} />}
            {interaction.attachments?.images && <FontAwesomeIcon icon={faImage} />}
            {interaction.attachments?.voiceMsg && <FontAwesomeIcon icon={faMicrophone} />}
          </div>
        }
      </div>
    </div>
  );
}

export default AttachmentRepliedMessage;