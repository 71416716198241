import React from 'react';
import { connect } from 'react-redux';
import { faRobot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { openModal, MODAL_TYPES } from '../../../../ducks/activeWindows';
import { setNightBotUnviewedSessionsCount } from '../../../../ducks/counters';

import './NightBotReportButton.scss';

const NightBotReportButton = (props) => {
  const { sessionsCount } = props;

  if (!sessionsCount) {
    return null;
  }

  return (
    <li className='navbar__item navbar__item--splitter'>
      <button
        className="night-bot-report-btn"
        onClick={() => props.openModal(
          MODAL_TYPES.nightBotReport, {
            sessionId: props.activeSession,
            onChangeSessionCount: props.setNightBotUnviewedSessionsCount,
          }
        )}
      >
        {sessionsCount && <span className="night-bot-report-btn__counter">
          {sessionsCount}
        </span>}

        <FontAwesomeIcon
          icon={faRobot}
          color="#404040"
          className='navbar__icon'
        />
      </button>
    </li>
  )
}

const mapStateToProps = (state) => ({
  sessionsCount: state.counters.nightBotUnviewedSessions,
  activeSession: state.sessions.activeSession,
})

const mapDispatchToProps = {
  openModal,
  setNightBotUnviewedSessionsCount
};

export default connect(mapStateToProps, mapDispatchToProps)(NightBotReportButton);