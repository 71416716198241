import React, { useEffect, useState, useRef, useCallback } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

import {
  showSessionFromHistory,
  toggleHistorySessionSuccess,
  duplicateClosedSession,
  continueClosedSession
} from "../../ducks/sessions";
import formatTime from "../../utils/formatTime";
import { getContactsById } from "../../ducks/contacts";
import { closeModal } from "../../ducks/activeWindows";
import { selectNameForSessionFromHistory, selectUserTimezone } from "../../selectors/selectors";
import { useHideTemporaryTooltip } from "../../hooks";

import './SessionFromHistory.scss';
import Menu from "../../components/Menu/Menu";
import SearchByClients from "../../components/SearchByClients/SearchByClients";
import { getDateByTimezoneOffset } from "../../utils";

const SessionFromHistory = (props) => {
  console.log('SESSION FROM HISTORY RENDER');

  const {
    item: sessionId,
    session = {},
    isClient,
    isOperator,
  } = props;
  const isMySession = isOperator
    ? true
    : props.name === 'You';

  const [tooltipStatus, setTooltipStatus] = useHideTemporaryTooltip('', 2000);

  const [clientsMenuModal, setClientsMenuModal] = useState(false);

  const actionsWrapRef = useRef();
  const clientsMenuModalRef = useRef();

  const onSuccessDuplicateWithAnotherClient = useCallback(() => {
    if (isClient) {
      props.closeModal();
    }

    props.history.push('/client/sales');
  }, []);

  useEffect(() => {
    if (!props.name && isOperator) {
      props.getContactsById(session.callerId, 1);
    }
  }, [props.name]);

  const onShowSession = e => {
    if (actionsWrapRef.current.contains(e.target)) return;

    if (!session.dateClosed && !isMySession) {
      return setTooltipStatus('Session continue yet');
    }

    props.showSessionFromHistory(session);

    if (isClient) {
      props.closeModal();

      return props.history.push('/client/sales');
    }

    return props.history.location.pathname === '/client/sales'
      ? props.closeHistory()
      : props.history.push('/client/sales');
  };

  const renderSessionColor = () => {
    if (!session.dateClosed) return '#ffc107'; // session is still ongoing

    return session.isSuccess ? '#28a745' : '#dc3545';
  }

  const duplicateOrContinueSession = (isDuplicate, clientId) => () => { // Closure
    const action = isDuplicate
      ? props.duplicateClosedSession
      : props.continueClosedSession;

    action(sessionId, clientId)
      .then(res => {
        if (res.errorMsg === 'Caller can have only one active session') {
          return setTooltipStatus('Session with this client already exist');
        }

        isClient && props.closeModal();

        props.history.push('/client/sales');
      });
  };

  const closeClientsMenuModal = () => setClientsMenuModal(false);

  const renderMenu = () => {
    const menu = [];

    const howMuchTimePassed = new Date() - new Date(session.dateClosed);
    const howMuchTimePassedInHours = howMuchTimePassed / 1000 / 60 / 60;

    const sessionEnded = !!session.dateClosed;

    const addNewMenuItem = (action, name) => menu.push({ action, name });

    if (isMySession && howMuchTimePassedInHours < 24 && sessionEnded) {
      addNewMenuItem(duplicateOrContinueSession(false), 'Continue');
    }

    if (sessionEnded) {
      addNewMenuItem(duplicateOrContinueSession(true), 'Duplicate');

      addNewMenuItem(() => setClientsMenuModal(true), 'Duplicate with');
    }

    return menu.length ? menu : false;
  }

  return (
    <div
      className='session-history'
      style={{ border: '2px solid ' + renderSessionColor() }}
      onClick={onShowSession}
    >
      <div className='session-history__info'>
        <div className='session-history__name-wrap'>
          <span>
            {isClient
              ? ('Operator: ')
              : ('Client: ')
            }
          </span>

          <span className='session-history__name'>
            {props.name}
          </span>
        </div>

        <div className='session-history__date'>
          <div className='session-history__key-value'>
            <span className='session-history__date-interval'>Started At: </span>

            {getDateByTimezoneOffset(props.userTimezone, session.dateCreated).toLocaleString('ru-RU', {
              hour12: props.userHour12,
            })}
          </div>

          {session.dateClosed &&
            <div className='session-history__key-value'>
              <span className='session-history__date-interval'>Duration: </span>

              {formatTime(new Date(session.dateClosed) - new Date(session.dateCreated))}
            </div>
          }
        </div>
      </div>

      <div
        className='session-history__actions'
        ref={actionsWrapRef}
      >
        <span
          className="session-history__tooltip"
          style={{
            padding: tooltipStatus ? '2px 4px' : '0'
          }}
        >
          {tooltipStatus}
        </span>

        {renderMenu() &&
          <Menu
            menuItems={renderMenu()}
            menuPosition="right"
          />
        }
        {/* FIXME: change SearchByClients to SearchContacts */}
        <div ref={clientsMenuModalRef}>
          {clientsMenuModal &&
            <SearchByClients
              onClose={closeClientsMenuModal}
              sessionId={sessionId}
              onSubmit={onSuccessDuplicateWithAnotherClient}
              wrapperRef={clientsMenuModalRef}
              type='duplicate'
              title='Duplicate session with another client:'
            />
          }
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => ({
  session: state.sessions.entities[ownProps.item], // item it's id
  name: selectNameForSessionFromHistory(state, ownProps.isClient, state.sessions.entities[ownProps.item]), // operator or client name
  userTimezone: selectUserTimezone(state),
  userHour12: state.user.hour12
});

const mapDispatchToProps = {
  showSessionFromHistory,
  toggleHistorySessionSuccess,
  getContactsById,
  duplicateClosedSession,
  continueClosedSession,
  closeModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SessionFromHistory));