import React from 'react';

import './Toggle.scss';


const Toggle = (props) => {
  const {
    isActive,
    onToggle,
    title,
    isDisabled,
  } = props;

  const getClassName = () => {
    let className = 'toggle';
    className += isActive
      ? ' toggle--true'
      : ' toggle--false';

    if (isDisabled) {
      className += ' toggle--disabled';
    }

    return className;
  }

  return (
    <div className='toggle__wrap'>
      <span className='toggle__title'>{title}</span>

      <div
        className={getClassName()}
        onClick={() => isDisabled ? null : onToggle()}
      >
        <div className={'toggle__indicator toggle__indicator--' + (isActive ? 'true' : 'false')}></div>
      </div>
    </div>
  );
};

export default Toggle;