import React, { useCallback } from 'react';
import { Scrollbars } from "react-custom-scrollbars";


const CustomScrollbars = ({ onScroll, customOnScroll, forwardedRef, style, children }) => {
  const refSetter = useCallback(scrollbarsRef => {
    if (scrollbarsRef) {
      forwardedRef(scrollbarsRef.view);
    }
    else {
      forwardedRef(null);
    }
  }, []);

  const handleScroll = (e) => {
    onScroll(e);
    customOnScroll(e);
  }

  return (
    <Scrollbars
      ref={refSetter}
      style={{ ...style, overflow: "hidden" }}
      onScroll={handleScroll} >
      {children}
    </Scrollbars>
  )
};

export default CustomScrollbars;