import React, { useRef } from "react";
import { connect } from "react-redux";

import {
  addProposedId,
  removeProposedId,
} from "../../ducks/sessions";
import {
  selectCanIEditSession,
  selectIsCurrentOperatorProposedProfile,
  selectIsPartnersSession,
  selectIsProfileInBooked
} from "../../selectors/selectors";
import { useToggle } from "../../hooks";
import { classModifier } from "../../utils";

import "./BookButton.scss";
import BookedEditor from "../BookedEditor/BookedEditor";
import DropWrapper from "../DropWrapper/DropWrapper";


const BookButton = ({ profileId, isFromSessionChat = false, className, ...props }) => {
  const [isBookedEditor, toggleBookedEditor] = useToggle(false);
  const [isEditing, toggleEditing] = useToggle(false);
  const [isConfirmOnClose, toggleConfirmOnClose] = useToggle(false);

  const isBookedBtn = className === 'booked-button';

  const editorRef = useRef();
  const dropdownRef = useRef();

  const onChangeBooked = e => {
    if (props.isPartners) {
      return isChecked
        ? props.removeProposedId(profileId, props.activeSession, props.myId)
        : props.addProposedId(profileId, props.activeSession, props.myId);
    }
    else {
      toggleBookedEditor();
    }
  };

  const handleBookedEditorClose = () => {
    return isConfirmOnClose && !window.confirm(
      'There are unsaved changes. Do you want to close the dialog anyway?'
    );
  }

  const getStylesIfProfileInBooked = () => isChecked && !isFromSessionChat
    ? { height: '25px', opacity: '1' }
    : null;

  const isChecked = props.isPartners
    ? props.isInProposed
    : props.isInBooked;

  const closeEditor = () => {
    toggleBookedEditor();
    toggleEditing(false);
  }

  const getButtonText = () => {
    if (!isChecked) return 'Book';

    if (isChecked && isBookedBtn) {
      return (
        <p className={`${className}__checkbox-text`}>
          Booked
          <span className={`${className}__checkbox-icon`}>✔</span>
        </p>
      );
    } else {
      return 'Booked';
    }
  }

  return (
    <>
      <div
        className={className}
        style={getStylesIfProfileInBooked()}
        ref={dropdownRef}
      >
        <div
          className={classModifier(`${className}__checkbox`, isChecked && 'active')}
          onClick={onChangeBooked}
        >
          {props.isPartners
            ? 'Propose'
            : getButtonText()
          }
        </div>
      </div>

      {isBookedEditor &&
        <div
          className={`${className}__editor-wrap`}
          ref={editorRef}
        >
          <DropWrapper
            closeDropWrapper={closeEditor}
            dropWrapperRef={editorRef}
            onClose={handleBookedEditorClose}
          >
            <BookedEditor
              profileId={profileId}
              handleCancel={toggleEditing}
              handleClose={closeEditor}
              parentRef={dropdownRef}
              isEditingMode={!props.isInBooked || isEditing}
              changeEditMode={toggleEditing}
              isAnimatedInit
              showAnimation={props.showAnimation}
              toggleConfirmOnClose={toggleConfirmOnClose}
            />
          </DropWrapper>
        </div>
      }
    </>
  );
};

const mapStateToProps = (state, ownProps) => ({
  isInProposed: selectIsCurrentOperatorProposedProfile(state, ownProps.profileId),
  isInBooked: selectIsProfileInBooked(state, ownProps.profileId),
  activeSession: state.sessions.activeSession,
  canIEditSession: selectCanIEditSession(state),
  isPartners: selectIsPartnersSession(state),
  myId: state.user.id // FIXME: Backend should take operator id from connectToken
});

const mapDispatchToProps = {
  addProposedId,
  removeProposedId
};

export default connect(mapStateToProps, mapDispatchToProps)(BookButton);