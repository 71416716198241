export const CHANGE_TYPING_STATUS = 'CHANGE_TYPING_STATUS';

export const debouncedChangeTypingStatus = (() => {
  let timerList = {};

  return (data, isNowDelete) => dispatch => {
    const timerId = data.id + '_' + data.userId;

    if (timerList[timerId]) {
      clearTimeout(timerList[timerId]);
    }

    if (isNowDelete) {
      dispatch(changeTypingStatus(data, false));
      return;
    }

    const functionCall = (data => () =>
      dispatch(changeTypingStatus(data, false))
    )(data);

    timerList[timerId] = setTimeout(functionCall, 4000);
    console.log(timerId);
  };
})()


export const changeTypingStatus = (data, isTyping) => dispatch => {
  dispatch({
    type: CHANGE_TYPING_STATUS,
    payload: { data, isTyping },
  });
};


const initialState = {
  typingOperators: {},
};


export default (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_TYPING_STATUS: {
      const typingChat = state.typingOperators[action.payload.data.id + '_' + action.payload.data.type];

      if (action.payload.isTyping) {
        const isInTypingArray = !!typingChat
          ? typingChat
            .some(id => id === action.payload.data.userId)
          : false

        if (isInTypingArray) return state;

        const updatedTypingIds = !!typingChat
          ? [...typingChat, action.payload.data.userId]
          : [action.payload.data.userId];

        return {
          ...state,
          typingOperators: {
            ...state.typingOperators,
            [action.payload.data.id + '_' + action.payload.data.type]: updatedTypingIds
          }
        }
      }
      else {
        if (!typingChat) return state;

        const updatedTypingOperatorsInChat = typingChat.filter(userId => userId !== action.payload.data.userId)

        if (!updatedTypingOperatorsInChat.length) {
          const updatedTypingStatuses = { ...state.typingOperators };

          delete updatedTypingStatuses[action.payload.data.id + '_' + action.payload.data.type]

          return {
            ...state,
            typingOperators: updatedTypingStatuses,
          };
        }

        return {
          ...state,
          typingOperators: {
            ...state.typingOperators,
            [action.payload.data.id + '_' + action.payload.data.type]: updatedTypingOperatorsInChat
          }
        }
      }
    }
    default:
      return state;
  }
}