import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { classModifier, formatDateToTime, getDateByTimezoneOffset } from '../../../utils';
import { useDidMount, useWillUnmount } from '../../../hooks';
import { removeNotification } from '../../../ducks/notifications';
import { updateActiveSession } from '../../../ducks/sessions';
import { getContactsById } from '../../../ducks/contacts';
import { openModal, MODAL_TYPES } from '../../../ducks/activeWindows';
import { selectContactsByIds, selectSessionsByContactIds, selectUserTimezone } from '../../../selectors/selectors';

import Spinner from '../../../components/UI/Spinner/Spinner';


const ContactsNotification = props => {
  const {
    className = '',
    contacts,
    activeSession,
    girl,
    sessions,
    notification,
  } = props;

  const {
    color,
    date_created,
  } = notification;
  const {
    girlId,
    contactIds,
  } = notification.data;

  const isSalesPage = props.history.location.pathname === '/client/sales';
  const date = getDateByTimezoneOffset(props.userTimezone, date_created);

  const [isReadPending, setReadPending] = useState(false);
  const [isLoadedContacts, setLoadedContacts] = useState(false);

  useDidMount(() => { // load clients and girls
    const loadedContacts = contacts.filter(contact => !!contact).map(contact => contact.id);
    const notLoadedContacts = contactIds.filter(id => !loadedContacts.includes(id));

    if (notLoadedContacts.length) {
      props.getContactsById(notLoadedContacts, 1)
        .finally(() => setLoadedContacts(true));
    }
    else {
      setLoadedContacts(true);
    }

    if (!girl) {
      props.getContactsById(girlId, 1);
    }
  });

  useWillUnmount(() => setReadPending(false));

  const contactAction = (contactId) => {
    const session = sessions.find(session => session.callerId === contactId);

    if (!session) return;

    if (props.isNewNotification) {
      readNotification();
    }

    if (activeSession !== session.id) {
      props.updateActiveSession(session.id)
    }
    if (!isSalesPage) {
      props.history.push('/client/sales');
    }

    props.toggleOpen && props.toggleOpen(false);

    props.openModal(MODAL_TYPES.profilesBuffer, {
      sessionId: session.id,
      activeGirlChat: girl.id,
    });
  };

  const readNotification = () => {
    setReadPending(true);
    props.readNotification(notification.id)
  };

  if (!girl || !isLoadedContacts) {
    return <div className={classModifier('notification__pending', color)}>
      <Spinner spinnerSize={30} />
    </div>
  }

  return (
    <div
      className={`${className} ${classModifier('notification__item', [
        color,
        notification.viewed && 'viewed',
        props.isNewNotification && 'new',
        (props.isNewNotification && color) && `new-${color}`
      ])}`}
    >
      <div className="notification__item-text">
        <span
          className="notification__link--name"
          title={girl.fn}
        >
          {girl.fn}
        </span>

        in buffer has become off today, see this

        {contacts.map((contact, index) =>
          <button
            className="notification__link"
            key={contact.id}
            onClick={() => contactAction(contact.id)}
          >
            {contact
              ? contact.fn
              : 'DELETED CONTACT'
            }

            {index !== contacts.length - 1 && ' ,'}
          </button>
        )}
      </div>

      <div className="notification__item-date">
        <span>{props.getDate(date)}</span>
        <span>{formatDateToTime(date, props.userHour12, false)}</span>

        {!notification.viewed &&
          <button
            className="notification__read"
            onClick={readNotification}
            disabled={isReadPending}
          >
            Read
          </button>
        }
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  activeSession: state.sessions.activeSession,
  contacts: selectContactsByIds(state, ownProps.notification.data.contactIds),
  girl: state.contacts.entities[ownProps.notification.data.girlId],
  sessions: selectSessionsByContactIds(state, ownProps.notification.data.contactIds),
  userTimezone: selectUserTimezone(state),
  userHour12: state.user.hour12,
});

const mapDispatchToProps = {
  removeNotification,
  updateActiveSession,
  getContactsById,
  openModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ContactsNotification));