import React, { useMemo } from 'react';
import { connect } from 'react-redux';

import { isEmptyObj, LS } from '../../utils';
import { selectWebrtcConferenceWithInvitation } from '../../selectors/selectors';
import { CONFERENCE_TYPES } from '../../ducks/webrtc';

import './CallsSection.scss';
import CallsList from './components/CallsList';
import Title from '../../components/UI/Title/Title';
import API from '../../api/api';
import { deleteAllMissedForMe } from '../../ducks/missedCalls';
import BlinkIndicator from '../../components/BlinkIndicator/BlinkIndicator';
import { useDidMount, useDidUpdate } from '../../hooks';
import { faPause, faRandom, faUsers } from '@fortawesome/free-solid-svg-icons';


const CallsSection = ({ isShowCallSection, userId, ...props }) => {
	useDidMount(() => {
		if (LS.getItem('isShowCallSection', userId)) {
			props.toggleCallSection()
		}
	});

	useDidUpdate(() => {
		if (isShowCallSection) {
			LS.setItem('isShowCallSection', true, userId);
		}
		else {
			LS.deleteItem('isShowCallSection', userId);
		}
	}, [isShowCallSection, userId]);



	const clearAllMissedForMe = () => {
		API.clearAllMissedForMe()
			.then(() => {
				props.deleteAllMissedForMe();
			})
			.catch(console.log);
	};

	const webrtcConferences = useMemo(() =>
		Object.values(props.webrtcConferences)
			.sort((first, second) => {
				if (first.type === CONFERENCE_TYPES.general && second.type !== CONFERENCE_TYPES.general) {
					return -1;
				}
				else if (first.type !== CONFERENCE_TYPES.general && second.type === CONFERENCE_TYPES.general) {
					return 1;
				}
			}), [props.webrtcConferences]);

	console.log('callSection render', isShowCallSection);

	return (
		<div className="calls-info-section">
			<div className="calls-info-section__item">
				<div className="calls-info-section__header">
					<Title
						classPrefix="chat-title"
						icon={faPause}
						iconType="fa"
						text="On Hold"
						count={Object.keys(props.callsOnHold).length}
					/>
				</div>

				{!isEmptyObj(props.callsOnHold) && isShowCallSection &&
					<CallsList
						callList={Object.values(props.callsOnHold)}
						onHold />
				}
			</div>

			<div className="calls-info-section__item">
				<div className="calls-info-section__header">
					<Title
						classPrefix="chat-title"
						icon={faRandom}
						iconType="fa"
						text="Transfers"
						count={Object.keys(props.callsOnTransfer).length}
					/>


					{!isEmptyObj(props.callsOnTransfer) && !isShowCallSection &&
						<div className="calls-info-section__blink">
							<BlinkIndicator
								handleClick={props.toggleCallSection}
								size={8}
								color="#F2CC0C" />
						</div>
					}
				</div>

				{!isEmptyObj(props.callsOnTransfer) && isShowCallSection &&
					<CallsList
						callList={Object.values(props.callsOnTransfer)}
						onTransfer />
				}
			</div>

			<div className="calls-info-section__item">
				<div className="calls-info-section__header">
					<Title
						classPrefix="chat-title"
						icon="missedCall"
						iconType="svg"
						text="Missed Calls"
						count={props.missedCallsIds.length}
					/>

					{!!props.missedCallsIds.length && !isShowCallSection &&
						<div className="calls-info-section__blink">
							<BlinkIndicator
								handleClick={props.toggleCallSection}
								size={8}
								color="#F8737F" />
						</div>
					}

					<button
						onClick={clearAllMissedForMe}
						// disabled={true}
						className="calls-info-section__clear-all">
						Clear All
					</button>
				</div>

				{!!props.missedCallsIds.length && isShowCallSection &&
					<CallsList
						callList={props.missedCallsIds}
						missed />
				}
			</div>

			<div className="calls-info-section__item">
				<div className="calls-info-section__header">
					<Title
						classPrefix="chat-title"
						icon={faUsers}
						iconType="fa"
						text="Conferences"
						count={Object.keys(props.conferences).length + Object.keys(props.webrtcConferences).length}
					/>

					{(!isEmptyObj(props.conferences) || props.isWebrtcConferencesWithInvitation) &&
						!isShowCallSection &&
						<div className="calls-info-section__blink">
							<BlinkIndicator
								handleClick={props.toggleCallSection}
								size={8}
								color="#01DF85" />
						</div>
					}
				</div>

				{(!isEmptyObj(props.conferences) || !isEmptyObj(props.webrtcConferences)) &&
					isShowCallSection &&
					<CallsList
						callList={Object.values(props.conferences)}
						additionalCallList={webrtcConferences}
						conf
					/>
				}
			</div>

			<div className="calls-info-section__item">
				<div className="calls-info-section__header">
					<Title
						classPrefix="chat-title"
						icon="incommingCall"
						iconType="svg"
						text="Incoming Calls"
						count={Object.keys(props.incomingCalls).length}
					/>

					{!isEmptyObj(props.incomingCalls) && !isShowCallSection &&
						<div className="calls-info-section__blink">
							<BlinkIndicator
								handleClick={props.toggleCallSection}
								size={8}
								color="#01DF85" />
						</div>
					}

				</div>

				{!isEmptyObj(props.incomingCalls) && isShowCallSection &&
					<CallsList
						callList={Object.values(props.incomingCalls)}
						inQueue
					/>
				}

				<button
					onClick={() => props.toggleCallSection()}
					className="calls-info-section__btn--hide">

					{isShowCallSection ? "Hide" : "Show"}
					<svg
						width='12px'
						fill="#fff"
						style={isShowCallSection ? { transform: "rotate(90deg)" } : { transform: "rotate(-90deg)" }}
						height='12px'
						viewBox="0 0 371 371" >
						<path d="M293 25L269 0 78 185l191 186 24-25-165-161z" />
					</svg>
				</button>
			</div>
		</div>
	);
};


const mapStateToProps = state => ({
	callsOnHold: state.calls.callsOnHold,
	callsOnTransfer: state.calls.callsOnTransfer,
	missedCallsIds: state.missedCalls.ids,
	incomingCalls: state.calls.incomingCalls,
	conferences: state.conferences.conferences,
	webrtcConferences: state.webrtc.conferences,
	isWebrtcConferencesWithInvitation: !!selectWebrtcConferenceWithInvitation(state),
	userId: state.user.id
});

const mapDispatchToProps = {
	deleteAllMissedForMe,
};

export default connect(mapStateToProps, mapDispatchToProps)(CallsSection);