import React, { useRef, useCallback, useState } from 'react';

import API from '../../api/api';
import { useDidMount, useToggle } from '../../hooks';

import DropWrapper from '../DropWrapper/DropWrapper';
import SearchableList from '../SearchableList/SearchableList';
import SearchContactItem from '../SearchByClients/SearchContactItem';


const SearchContacts = ({
  type = 0,
  classPrefix,
  setSearchContacts,
  searchContacts,
  ignoredIds = [],
  initialQuery = '',
  onClickAction,
  closeModal,
  limit = 20,
  highlightNonTelsContacts = false,
  isPopup = false,
  isGirlsTab,
  listMode,
  ...props
}) => {
  const searchContactsRef = useRef();
  const [pending, togglePending] = useToggle(true);
  const [ignoredListItems, setIgnoredListItems] = useState([]);

  const currentSetIgnoredListItems = props.setIgnoredListItems !== undefined
    ? props.setIgnoredListItems
    : setIgnoredListItems;
  const currentIgnoredListItems = props.ignoredListItems !== undefined
    ? props.ignoredListItems
    : ignoredListItems;

  useDidMount(() => {
    startSearchContacts(initialQuery);
  });

  const filterContactsByIgnoredIds = (arr) => {
    return arr.filter(item => {
      if (!ignoredIds.includes(item.id)) return true;

      currentSetIgnoredListItems(prev => [...prev, item]);
      return false;
    })
  };

  const filterContactsByPhoneNumberExisting = (arr) => {
    return highlightNonTelsContacts
      ? arr.map(item => item.tels && item.tels.length ? item : { ...item, withoutNumber: true })
      : arr;
  }
  const filterContacts = (arr) => filterContactsByPhoneNumberExisting(filterContactsByIgnoredIds(arr));

  const startSearchContacts = (query) => {
    togglePending(true);

    // if (!type) {
    //   Promise.all([API.searchContacts(1, query), API.searchContacts(2, query)])
    //     .then(results => {
    //       const wholeTypesList = results.map(res => filterContacts(res.data));

    //       setSearchContacts({ ...searchContacts, list: wholeTypesList.flat(), query });
    //     })
    //     .catch(console.error)
    //     .finally(() => togglePending(false));

    //   return;
    // }

    API.searchContacts(type, query)
      .then(res => {
        const filteredContacts = filterContacts(res.data);

        setSearchContacts((prevSearchContacts) =>
          ({
            ...prevSearchContacts,
            list: filteredContacts,
            query,
          }));
      })
      .catch(console.error)
      .finally(() => togglePending(false));
  };

  const loadMoreContacts = (offset) => {
    // if (!type) {
    //   Promise.all([API.searchContacts(1, searchContacts.query, offset), API.searchContacts(2, searchContacts.query, offset)])
    //     .then(results => {
    //       const wholeTypesList = results.map(res => filterContacts(res.data));

    //       setSearchContacts({ ...searchContacts, list: [...searchContacts.list, ...wholeTypesList.flat()] });
    //     })
    //     .catch(console.error)

    //   return;
    // }
    API.searchContacts(type, searchContacts.query, offset)
      .then(res => {
        const filteredContacts = filterContacts(res.data);

        setSearchContacts((prevSearchContacts) =>
          ({
            ...prevSearchContacts,
            list: [
              ...prevSearchContacts.list,
              ...filteredContacts,
            ]
          }));
      })
      .catch(console.error)
  };

  const loadMore = useCallback((offset) => loadMoreContacts(offset), [searchContacts, ignoredIds]);
  const startSearch = useCallback((query) => startSearchContacts(query), [searchContacts.isOpen, ignoredIds]);
  const stopSearch = useCallback((query) => startSearchContacts(query), [searchContacts.isOpen, ignoredIds]);

  if (!isPopup) {
    return <div ref={searchContactsRef} className={classPrefix + "__wrap"}>
      <SearchableList
        classPrefix={classPrefix}
        list={searchContacts.list}
        listLimit={limit}
        scrollInitialPosition='top'
        loadMore={loadMore}
        listItem={SearchContactItem}
        listLoadPending={pending}
        listMode={listMode}
        ignoredListItemsCount={currentIgnoredListItems.length}
        listItemProps={{
          closeModal,
          onClickAction,
          classPrefix,
        }}
        startSearch={startSearch}
        stopSearch={stopSearch}
      />
    </div>
  }

  return (
    <DropWrapper
      dropWrapperRef={searchContactsRef}
      className={`${classPrefix}__drop-wrap ${isGirlsTab ? classPrefix + '__drop-wrap--girls' : ''}`}
      closeDropWrapper={() => setSearchContacts({ ...searchContacts, isOpen: false })} >
      <div ref={searchContactsRef} className={classPrefix + "__wrap"}>
        <SearchableList
          classPrefix={classPrefix}
          list={searchContacts.list}
          listLimit={20}
          scrollInitialPosition='top'
          loadMore={loadMore}
          listItem={SearchContactItem}
          listLoadPending={pending}
          listMode={listMode}
          ignoredListItemsCount={currentIgnoredListItems.length}
          listItemProps={{
            closeModal,
            onClickAction,
            classPrefix,
          }}
          startSearch={startSearch}
          stopSearch={stopSearch}
        />
      </div>
    </DropWrapper>
  )
};

export default SearchContacts;