import React from 'react';

import {
  convertNumberToSeconds,
  getContactAvatar,
  formatDateToTime,
  getDefaultField,
  getDateByTimezoneOffset
} from '../../../../../utils';


const OutgoingCall = ({ interaction, profileId, activeRecipient, ...props }) => {

  const status = interaction.status
    ? interaction.status
    : null;

  const duration = +interaction.duration > 0
    ? convertNumberToSeconds(+interaction.duration)
    : null;

  const renderHeader = () => {
    let operator;
    let isMineHeader = false;

    if (interaction.user_id === profileId) {
      operator = interaction.user;
      isMineHeader = true;
    }
    else if (interaction.senderId === profileId) {
      operator = interaction.sender;
      isMineHeader = true;
    }
    else {
      operator = interaction.user;
    }

    return isMineHeader
      ? <span className="interaction__name">You</span>
      : <React.Fragment>
        <div className="interaction__ava">
          <img src={getContactAvatar(operator)} alt="ava" />
        </div>
        <span className="interaction__name">{operator.username}</span>
      </React.Fragment>;
  }

  const isSentOnNotDefaultNumber = interaction.channel && getDefaultField(activeRecipient.tels, 'tel') !== interaction.channel;

  return (
    <li className="interaction interaction--out">
      <header className="interaction__header interaction__header--reverse">
        {renderHeader()}
        <span className="interaction__time interaction__time--reverse">
          {formatDateToTime(getDateByTimezoneOffset(props.userTimezone, interaction.dateCreated), props.userHour12)}
        </span>
        <span className="interaction__status--reverse">{status} {duration ? `- (${duration})` : ''}</span>
      </header>
      <div className="interaction__body-wrap">
        <div className="interaction__text-wrap">
          <p className="interaction__text">
            Outgoing Call
          {isSentOnNotDefaultNumber && <span className="interaction__channel">(to: {interaction.channel})</span>}
          </p>
        </div>
      </div>
    </li>
  );
};

export default OutgoingCall;