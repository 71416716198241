import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import chroma from 'chroma-js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

import API from '../../../../api/api';
import { useDidMount, useToggle, useWillUnmount } from '../../../../hooks';
import {
  updateSession,
  updateActiveSession,
  normalizeSession,
  showSessionFromHistory,
  getSessionsHistory,
} from '../../../../ducks/sessions';
import { DIVA_GIRLS_IMG_HOST } from '../../../../ducks/divaGirls';
import { formatDateToTime, getBookingColorByStage, getDateByTimezoneOffset } from '../../../../utils';

import BookingIcon from '../../../../components/BookingIcon';
import LazyLoadImage from '../../../../components/LazyLoadImage/LazyLoadImage';
import Spinner from '../../../../components/UI/Spinner/Spinner';


const AttachmentBooking = props => {
  const {
    className = '',
    subjectOperator,
    girl,
    booking,
    userTimezone,
    userHour12,
    isFutureBookingAvailable = false,
    currentUserId,
    sessionOperator,
  } = props;

  const [divaProfile, setDivaProfile] = useState({});
  const [girlPending, toggleGirlPending] = useToggle(false);

  let history = useHistory();
  let isUnmount = useRef(false);

  useDidMount(() => {
    if (booking && !girl) {
      toggleGirlPending(true);

      API.getDivaGirls({ 'filter-ids': booking.profile_id })
        .then(({ data }) => {
          !isUnmount.current && setDivaProfile(data.result[0]);
        })
        .catch(err => console.log(err))
        .finally(() => !isUnmount.current && toggleGirlPending(false));
    }
  });

  useWillUnmount(() => isUnmount.current = true);

  const onBookingClick = () => {
    if (props.bookingSession) {
      props.updateActiveSession(props.bookingSession.id);
    }
    else {
      API.getSessionById(booking.session_id)
        .then(({ data }) => props.showSessionFromHistory(normalizeSession(data)))
        .catch(err => console.log(err));
    }

    history.push('/client/sales');
  };

  const onTakeFutureBooking = () => {
    API.takeFutureBooking(booking.session_id, currentUserId)
      .then(({ data }) => {
        props.updateSession(normalizeSession(data));

        onBookingClick();
      })
      .catch(err => console.log(err))
  };

  const getProfileAvatar = () => {
    if (girl && girl.avatar) {
      return DIVA_GIRLS_IMG_HOST + girl.avatar;
    }
    else if (divaProfile && divaProfile.avatar) {
      return DIVA_GIRLS_IMG_HOST + divaProfile.avatar;
    }

    return '/media/avatars/girl.png';
  };

  const renderGirlName = () => {
    if (girlPending) {
      return "Loading...";
    }
    else {
      return girl ? girl.name : divaProfile.name;
    }
  };

  if (!booking) {
    return (
      <div className="attachment-booking--removed">
        <span> Booking was removed </span>
      </div>
    );
  }

  return (
    <div className={`attachment-booking ${className}`}>
      <>
        <div className='attachment-booking__header'>
          {isFutureBookingAvailable
            ? <div className="attachment-booking__header--fb-available">
              Girl is available
            </div>
            : <span className="attachment-booking__header--date">
              {`Booked at ${formatDateToTime(getDateByTimezoneOffset(userTimezone, booking.date_created), userHour12)}`}
            </span>
          }
          <span>
            <BookingIcon
              booking={booking}
              userTimezone={userTimezone}
            />
          </span>
        </div>

        <div
          className="attachment-booking__body"
          style={{ backgroundColor: getBookingColorByStage(booking) }}
          onClick={() => onBookingClick()}
        >
          <div className="attachment-booking__body--avatar">
            {girlPending
              ? <Spinner spinnerSize={32} />
              : <LazyLoadImage
                src={getProfileAvatar()}
                alt="ava"
                className="attachment-booking__body--img"
              />
            }
          </div>
          <div className="attachment-booking__body--info">
            <div className="attachment-booking__body--details">
              <span
                className="attachment-booking__body--girl"
                style={{ color: chroma.contrast(getBookingColorByStage(booking), '#fff') > 2 ? '#fff' : '#0092f2' }}
              >
                {renderGirlName()}

                {(girl && girl.exclusive || divaProfile.exclusive) &&
                  <FontAwesomeIcon icon={faStar} title="exclusive"/>
                }
              </span>
              <span className="attachment-booking__body--type">
                {booking.type}
              </span>/
              <span className="attachment-booking__body--time">
                {booking.date
                  ? getDateByTimezoneOffset(userTimezone, booking.date).toLocaleString('ru-RU', {
                    year: '2-digit',
                    month: '2-digit',
                    day: "2-digit",
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: userHour12
                  })
                  : 'Future booking'
                }
              </span>/
              <span className="attachment-booking__body--duration">
                {booking.duration}
              </span>/
              <span className="attachment-booking__body--price">
                {booking.discount_rate ? booking.discount_rate : booking.rate}
              </span>
            </div>
            <div>
              <span
                className="attachment-booking__body--operator"
                style={{ color: chroma.contrast(getBookingColorByStage(booking), '#fff') > 2 ? '#fff' : '#0092f2' }}
              >
                {subjectOperator ? subjectOperator.username : 'You'}
              </span>
            </div>
          </div>
        </div>

        {isFutureBookingAvailable &&
          props.bookingSession &&
          !props.bookingSession.usersIds.includes(currentUserId) &&
          sessionOperator &&
          sessionOperator.status === 'offline' &&
          <div className="attachment-booking__fb-action">
            <button
              className="attachment-booking__fb-action--btn"
              onClick={onTakeFutureBooking}
            >
              Take Booking
            </button>
          </div>
        }
      </>
    </div>
  )
};

const mapStateToProps = (state, ownProps) => ({
  girl: ownProps.booking && state.divaGirls.entities[ownProps.booking.profile_id],
  currentUserId: state.user.id,
  sessionOperator: state.operators.entities[ownProps.session.userId],
  bookingSession: state.sessions.entities[ownProps.chatUpdate.sessionId],
});

const mapDispatchToProps = {
  updateActiveSession,
  updateSession,
  getSessionsHistory,
  showSessionFromHistory,
};

export default connect(mapStateToProps, mapDispatchToProps)(AttachmentBooking);