import React, { Fragment, useState, useRef } from "react";
import { connect } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

import LS from '../../utils/localStorageAPI';
import { selectIdsForSession } from "../../selectors/selectors";
import { useDidUpdate, useDidMount } from "../../hooks";

import Session from "./components/Session";
import OperatorSessionsHistory from "./components/OperatorSessionsHistory";
import Title from "../../components/UI/Title/Title";


const Sessions = (props) => {
  console.log("SESSIONS RENDER");
  const {
    ids,
    type = {},
    userId,
    activeSession,
    accordion,
  } = props;

  const [isSessionsHistoryOpen, toggleOperatorSessionsHistory] = useState(false);

  const openHistoryLinkRef = useRef();

  useDidMount(() => {
    if (activeSession) {
      LS.setItem("aSession", activeSession, userId);
    }

    if (accordion && ids.length) {
      accordion.toggle(accordion.id, true);
    }
  });

  useDidUpdate(() => {
    LS.setItem("aSession", activeSession, userId);
  }, [activeSession, userId]);

  if (!ids.length && !type.isMine && !accordion) {
    return null;
  }

  const getTitleText = () => {
    if (type.isMine) {
      return 'My Sessions';
    }
    else if (type.isPartners) {
      return 'Partners Sessions';
    }
    else if (type.isDefault) {
      return 'Default Session';
    }
    else if (type.isHistory) {
      return 'History Sessions';
    }
    else if (type.isAssistance) {
      return 'Assistance Sessions';
    }
  };

  const titleClass = type.isMine
    ? "sale-sidebar__title sale-sidebar__title--has-link"
    : "sale-sidebar__subtitle";

  const getHeightPropsForWrapper = () => {
    if (!accordion) {
      return {
        autoHeight: true,
        autoHeightMin: type.isDefault ? 50 : 118,
        autoHeightMax: 261,
      }
    }

    return accordion.isOpen
      ? { style: { height: '160px' } }
      : { style: { height: '0px' } }
  }

  const toggleAccordion = () => {
    if (ids.length) {
      return accordion.toggle(accordion.id);
    }

    return accordion.isOpen
      ? accordion.toggle(accordion.id)
      : null;
  }

  return (
    <Fragment>
      <div
        className={titleClass}
        onClick={!!accordion
          ? toggleAccordion
          : null
        }
      >
        <Title
          classPrefix="chat-title"
          icon="puzzle"
          isShowIcon={(type.isMine || !!accordion)}
          text={getTitleText()}
          count={ids.length}
        />

        {type.isMine && !accordion &&
          <button
            className="sale-sidebar__link"
            onClick={() => toggleOperatorSessionsHistory(!isSessionsHistoryOpen)}
            ref={openHistoryLinkRef}
          >
            History
          </button>
        }

        {isSessionsHistoryOpen &&
          <OperatorSessionsHistory
            closeHistory={() => toggleOperatorSessionsHistory(false)}
            openHistoryLinkRef={openHistoryLinkRef.current}
            isFixed={window.location.pathname === '/'}
          />
        }

        {!!accordion &&
          <FontAwesomeIcon
            icon={accordion.isOpen
              ? faChevronUp
              : faChevronDown
            }
            color={accordion.isOpen
              ? '#0092F2'
              : '#404040'
            }
            className='sale-sidebar__accordion-indicator'
          />
        }
      </div>

      <div>
        <Scrollbars
          renderThumbVertical={() => <div className="sale-sidebar__scrollbar-thumb" />}
          className='sale-sidebar__items-wrap'
          {...getHeightPropsForWrapper()}
        >
          <ul className='sale-sidebar__items'>
            {ids.map(sessionId => (
              <Session
                id={sessionId}
                isActive={sessionId === activeSession}
                isMine={type.isMine}
                isPartners={type.isPartners}
                isDefault={type.isDefault}
                isHistory={type.isHistory}
                isAssistance={type.isAssistance}
                key={sessionId}
              />
            ))}
          </ul>
        </Scrollbars>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state, ownProps) => ({
  activeSession: state.sessions.activeSession,
  ids: selectIdsForSession(state, ownProps.type),
  userId: state.user.id
});

export default connect(mapStateToProps)(Sessions);