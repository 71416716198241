import React from 'react';
import { connect } from 'react-redux';

const RoomParticipantTag = ({ operator }) => (
  <li data-isonline={operator && operator.status}>
    {operator && operator.username}
  </li>
);


const mapStateToProps = (state, { userId }) => ({
  operator: state.operators.entities[userId],
});

export default connect(mapStateToProps)(RoomParticipantTag);
