/* eslint-disable no-case-declarations */
import { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

import { SOCKET_ROOT } from '../config/socket-config';
import { isMineInteraction, showNotification, prepareConference, isEmptyObj, convertToAudio, LS } from '../utils';

import {
  sendOfferToOperator,
  createPeerConnection,
  deletePC,
  killWebrtcCall,
  updateWebrtcConference,
  updateWebrtcConferenceParticipant,
  removeWebrtcParticipant,
  webrtcParticipantLeft,
  toggleWebrtcConferenceMute,
  leaveFromActiveConference,
  removeFromHoldActiveConference
} from '../ducks/webrtc';

import {
  newIncomingMessage,
  newOutgoingMessage,
  changeCallStatus,
  changeMessageStatus,
  newMsgAttachments,
  removeMessageReminder,
  getContactTimeline,
  updateContactMsg,
  deleteContactMsg,
  newScheduledMsg,
  deleteTelegramMsg,
} from '../ducks/clientChats';
import {
  newIncomingChatMessage,
  changeChatCallStatus,
  newOutgoingChatMessage,
  changeChatMsgStatus,
  sendDeliveredStatus,
  updateChatMsg,
  removeChatMsg,
  removeGirlsChatMsg,
  addWebmasterTask,
  removeWebmasterTask,
  addNewChatRoom,
  updateActiveChat,
} from '../ducks/roomChats';
import {
  newOutgoingCall,
  newIncomingCall,
  newActiveCall,
  finishCallOnHold,
  removeTransferedCall,
  newTransferedCall,
  newOutgoingOperatorCall,
  newIncomingOperatorCall,
  finishCall,
  newIncomingQueueCall,
  removeIncomingQueueCall,
} from '../ducks/calls';
import {
  newConference,
  newWarmTransfer,
  acceptWarmTransfer,
  conferenceParticipantJoin,
  conferenceParticipantLeave,
  removeConference,
  conferenceInvitation,
  PARTICIPANT_USER,
  PARTICIPANT_CALLER,
  WARM_TRANSFER
} from '../ducks/conferences';
import {
  newMissedCall,
  removeMissedCall,
  updateVoicemailStatus,
  removeMissedOperatorCallForMe,
  OPERATORS_CALL,
} from '../ducks/missedCalls';
import {
  addPartnerSession,
  addSession,
  removePartnerSession,
  partnerToggleHistorySessionSuccess,
  updateSession,
  updateSessionViewed,
  updateAssistanceSession,
  updateBooking,
  updateBookingRequest,
  deleteBookingRequest,
  updateBookingScheduledMessages,
  deleteBookingScheduledMessages,
  removeBooking,
  updateSessionBuffer,
  createSession,
  updateAdditionalFilters,
  updateActiveFiltersFromSocket,
  updateAdditionalTitleFilters,
  resetDefaultSessionFilters,
} from "../ducks/sessions";
import {
  addNotification,
  removeNotification,
  NOTIFICATION_TYPES,
} from "../ducks/notifications";
import { newServiceMsg } from '../ducks/girlChats';

import {
  openModal,
  closeModal,
  openTransferedCallModal,
  MODAL_TYPES,
  playMedia,
  updateModalProps,
} from "../ducks/activeWindows";

import { timerStart, timerStop } from '../ducks/timers';
import {
  changeOperatorEntity,
  changeOperatorStatus,
} from '../ducks/operators';
import {
  addNewContactToState,
  updateContactInState,
  removeContactFromState,
  updateRecentTabs,
  toggleContactActiveChat,
  updateContactFieldsById,
  addContactsToEntities,
} from '../ducks/contacts';
import {
  updateDivaGirl,
} from '../ducks/divaGirls';
import {
  updateRoom,
  removeRoom,
  addGirlToOffToday,
  removeGirlFromOffToday,
  addGirlToAvailable,
  removeGirlFromAvailable,
} from '../ducks/rooms';
import {
  addEmail,
  addMessageToMailChat,
  deleteMsgFromMailChat,
  deleteConversationToTrash,
  deleteConversationPermanently,
  markMailsNotSpam,
  undeleteMails,
} from '../ducks/mail';
import { updateAdrBookTags } from '../ducks/addressBook';
import { updateActiveContact } from '../ducks/clientChats';
import { updateUser } from '../ducks/user';
import { changeTypingStatus, debouncedChangeTypingStatus } from '../ducks/typingOperators';
import { CONTACT_TYPES, AUDIO_SETTINGS_TYPES, CHAT_TYPES, CONTACTS_MODES_TYPES, ADDITIONAL_SESSION_FILTERS } from '../config/constants';
import API from '../api/api';
import deleteCookie from '../utils/deleteCookie';
import { selectCanIEditSession, selectPropertyOfActiveSession } from '../selectors/selectors';

export let webSocket;

class ChatSocket extends Component {
  state = {
    // ws: new WebSocket(SOCKET_ROOT),
    smsAudio: null,
    callAudio: null,
  }

  componentDidMount() {
    // if (localStorage.connectToken) {
    webSocket = new WebSocket(SOCKET_ROOT)
    this.setupSocket();
    // }
  }

  shouldComponentUpdate() {
    return false;
  }

  componentWillUnmount() {
    // const webSocket = this.state.ws;

    webSocket.close();
  }

  setupSocket = () => {
    // const webSocket = this.state.ws;
    let incomingNotification;
    const tempRinftoneStorage = {
      ringtone: null,
      type: null,
    }

    const playCommonRingtone = (contactType, ringtoneType) => {
      const { agentRingtones, clientRingtones, girlRingtones, operatorRingtones, callBeep } = this.props.settings;

      switch (contactType) {
        case CONTACT_TYPES.CLIENT:
          return clientRingtones[ringtoneType];
        case CONTACT_TYPES.GIRL:
          return girlRingtones[ringtoneType];
        case CONTACT_TYPES.AGENT:
          return agentRingtones[ringtoneType];
        case CONTACT_TYPES.OPERATOR:
          return operatorRingtones[ringtoneType];
        case 'callBeep':
          return callBeep;
        default:
          return clientRingtones[ringtoneType];
      }
    };

    const playRingtone = (customRingtone, contactType, ringtoneType, fn) => {
      const isCall = ringtoneType === 'call';

      const audio = customRingtone
        ? convertToAudio(customRingtone, isCall)
        : playCommonRingtone(contactType, ringtoneType);

      let userVolume;

      if (isCall) {
        userVolume = this.props.settings.volume.call;
      } else {
        if (ringtoneType === 'booking') {
          userVolume = this.props.settings.volume.booking;
        } else {
          userVolume = this.props.settings.volume.sms;
        }
      }

      const volume = (userVolume || userVolume === 0)
        ? userVolume
        : 100;

      audio.volume = volume / 100;

      if (isCall) {
        if (this.state.callAudio && !this.state.callAudio.paused) {
          this.state.callAudio.pause();
          this.state.callAudio.currentTime = 0;
        }

        console.log('audio___', audio);
        this.setState({ callAudio: audio }, () => {
          this.state.callAudio.play();

          fn && fn();
        });
      }
      else {
        if (this.state.smsAudio && !this.state.smsAudio.paused) {
          this.state.smsAudio.pause();
          this.state.smsAudio.currentTime = 0;
        }
        console.log('audio___', audio);
        this.setState({ smsAudio: audio }, () => {

          this.state.smsAudio.play();

          fn && fn();
        });
      }
    };

    const prepareRingtone = (ringtone, contactType, ringtoneType) => {
      const isCall = ringtoneType === 'call';

      const audio = ringtone
        ? convertToAudio(ringtone, isCall)
        : playCommonRingtone(contactType, ringtoneType);

      const userVolume = LS.getItem('notificationsVolume', this.props.user.id);

      const volume = userVolume || userVolume === 0
        ? userVolume
        : 100;

      audio.volume = volume / 100;

      return audio;
    }

    const isContactsSmsAllowed = (contactType) => {
      if (this.props.user && this.props.user.mode) {
        return !!this.props.user.mode.sms?.[CONTACTS_MODES_TYPES[contactType]];
      }
      return false;
    };

    const playBookingOrSmsRingtone = (data) => {
      if (data.isBooking) {
        isContactsSmsAllowed(data.message.caller.type) && playRingtone(data.ringtone, data.message.caller.type, 'booking');
      } else {
        isContactsSmsAllowed(data.message.caller.type) && playRingtone(data.ringtone, data.message.caller.type, 'sms');
      }
    };

    webSocket.onopen = () => {
      console.log('ChatWebSocket connected!)');
    };

    webSocket.onclose = () => {
      console.log('ChatWebSocket disconnected!(');

      // this.setupSocket();

      // delete localStorage.connectToken;
      // delete localStorage.user;
      // window.location = '/client/login';
    };

    webSocket.onerror = (err) => {
      console.log('ChatWebSocket error: ', err);
    };

    webSocket.onmessage = (e) => {
      const { data, type } = JSON.parse(e.data);

      const currentUser = this.props.user;

      console.log('WebSocket event happen!:', 'type:', type, ', data:', data);

      switch (type) {
        case 'new_message': {
          switch (data.type) {

            case 'outgoing':
              this.props.newOutgoingMessage(data.message, currentUser.timezone.offset_minutes);

              this.props.changeTypingStatus({
                id: data.message.caller_id,
                type: data.message.caller.type,
                userId: data.message.user_id,
              }, false);

              this.props.updateContactInState(data.message.caller, data.message.caller.type);
              this.props.toggleContactActiveChat(data.message.caller);
              break;
            case 'incoming':
              const isIamRelated = !data.message.caller.relatedUserId || (data.message.caller.relatedUserId === currentUser.id);

              if (isIamRelated && isEmptyObj(this.props.activeCall)) {
                switch (data.message.caller.audio_status) {
                  case AUDIO_SETTINGS_TYPES.MUTE: break;
                  case AUDIO_SETTINGS_TYPES.IMPORTANT:

                    if (isEmptyObj(this.props.activeCall)) {
                      playBookingOrSmsRingtone(data);
                    }

                    this.props.updateContactInState({ ...data.message.caller, audio_status: AUDIO_SETTINGS_TYPES.NORMAL, withImportantUnread: true }, data.message.caller.type);
                    break;
                  default:
                    if (isEmptyObj(this.props.activeCall)) {
                      playBookingOrSmsRingtone(data);
                    }
                    break;
                };
              }

              if (data.message && data.message.body) {
                isContactsSmsAllowed(data.message.caller.type) && showNotification(data.message, () => {
                  this.props.updateActiveContact(data.message.caller);

                  if (this.props.history.location.pathname !== '/') {
                    this.props.history.push('/');
                  }
                });
              }

              this.props.newIncomingMessage(
                { ...data.message, isIamRelated: true },
                currentUser.timezone.offset_minutes
              );

              this.props.updateContactInState(data.message.caller, data.message.caller.type);
              this.props.toggleContactActiveChat(data.message.caller);
              break;
            case 'scheduled':
              this.props.newScheduledMsg(data.message, currentUser.timezone.offset_minutes);

              if (data.message.session_id) {
                this.props.updateBookingScheduledMessages(data.message.session_id, data.message.booking_id, data.message);
              }
              break;
            case 'attachments':
              this.props.newMsgAttachments(data.message);
              break;
            case 'service':
              this.props.newServiceMsg(data.message, currentUser.timezone.offset_minutes);
              break;
            default:
              break;
          }

          break;
        }

        case 'interaction_update': {
          switch (data.type) {
            case 'message_status':
              this.props.changeMessageStatus(data);
              break;
            case 'voicemail_status':
              this.props.updateVoicemailStatus(data);
              break;
            case 'update':
              this.props.updateContactMsg(data.message);

              //scheduled msg
              if (data.message.session_id && data.message.type === 11) {
                this.props.updateBookingScheduledMessages(data.message.session_id, data.message.booking_id, data.message);
              }
              break;
            case 'delete':
              this.props.deleteContactMsg(data.message);

              //scheduled msg
              if (data.message.session_id && data.message.type === 11) {
                this.props.deleteBookingScheduledMessages(data.message.session_id, data.message.booking_id, data.message.id);
              }
              break;
            default:
              break;
          }

          break;
        }

        case 'outgoing_message_status': {
          this.props.changeMessageStatus(data);
          break;
        }

        case 'new_call': {
          switch (data.callType) {
            case 'incoming': {
              const isMine = isMineInteraction(currentUser.id, data.call.user_id);
              const isIamRelated = !data.call.caller.relatedUserId || data.call.caller.relatedUserId === currentUser.id;

              this.props.newIncomingCall({ ...data.call, isMine, isIamRelated }, currentUser.timezone.offset_minutes);

              if (isMine) {
                showNotification(data.call, () => this.props.updateActiveContact(data.call.caller))
                  .then(res => incomingNotification = res);

                this.props.updateActiveContact(data.call.caller);

                playRingtone(
                  data.ringtone,
                  data.call.caller.type,
                  'call',
                  () => this.props.openModal(MODAL_TYPES.incomingCall, { audio: this.state.callAudio })
                );
              }

              this.props.updateContactInState(data.call.caller, data.call.caller.type);
              this.props.toggleContactActiveChat(data.call.caller);
              break;
            }

            case 'outgoing': {
              const isMine = isMineInteraction(currentUser.id, data.call.user_id);

              this.props.newOutgoingCall({ ...data.call, isMine }, currentUser.timezone.offset_minutes);

              if (data.call.status === "in-progress") { // when the conference turns into an outgoing call
                this.props.timerStart(data.call.id);
              }

              this.props.updateContactInState(data.call.caller, data.call.caller.type);
              this.props.toggleContactActiveChat(data.call.caller);
              break;
            }

            case 'missed': {
              this.props.newMissedCall(data.call);
              break;
            }

            case 'incoming_queue': {
              this.props.newIncomingQueueCall({ ...data.call, status: "in-queue" });

              this.props.updateActiveContact(data.call.caller);

              tempRinftoneStorage.ringtone = data.ringtone;
              tempRinftoneStorage.type = data.call.type;
              break;
            }

            case 'incoming_from_queue': {
              playRingtone(tempRinftoneStorage.ringtone, tempRinftoneStorage.type, 'call');

              this.props.openModal(MODAL_TYPES.incomingQueueCall, { callId: data.callId });

              break;
            }

            case 'transfer': {
              this.props.newTransferedCall(data.call);

              if (data.call.queueData && data.call.queueData.recipient && data.call.queueData.recipient.id === currentUser.id) {
                playRingtone(
                  data.ringtone,
                  data.call.caller.type,
                  'call',
                  () => this.props.openTransferedCallModal(data.call, this.state.callAudio),
                );
              }
              break;
            }

            default:
              break;
          }

          break;
        }

        case 'call_status': {
          const isMine = isMineInteraction(currentUser.id, data.userId);
          const isOutgoingCallModal = this.props.activeModals.find(modal => modal.type === MODAL_TYPES.outgoingCall);
          const isIncomingCallModal = this.props.activeModals.find(modal => modal.type === MODAL_TYPES.incomingCall);

          if (isMine) {
            if (data.type === 1 && ['initiated', 'ringing'].indexOf(data.status) === -1) {
              if (this.state.callAudio && !this.state.callAudio.paused) {
                this.state.callAudio.pause();
                this.state.callAudio.currentTime = 0;
              }

              if (incomingNotification) {
                incomingNotification.close();
                incomingNotification = null;
              }
            }
            if (data.prevStatus === 'ringing' && data.status === 'in-progress') {
              // start main timer for call
              this.props.timerStart(data.callId);

              if (isIncomingCallModal) {
                this.props.closeModal(MODAL_TYPES.incomingCall);
              }
              else if (isOutgoingCallModal) {
                this.props.closeModal(MODAL_TYPES.outgoingCall);
              }
            }
            else if (data.status === "completed") {
              // stop all timers of completed call
              if (this.props.callsOnHold[data.callId]) {
                this.props.finishCallOnHold(data.callId);
              }
              else if (this.props.callsOnTransfer[data.callId]) {
                this.props.removeTransferedCall(data.callId);
                this.props.closeModal(MODAL_TYPES.transferedCall);
              }
              if (this.props.timers[data.callId]) {
                this.props.timerStop(data.callId);
              }
            }
          }
          // not mine calls
          else {
            if (data.prevStatus === 'on-transfer') {
              this.props.removeTransferedCall(data.callId);
              this.props.closeModal(MODAL_TYPES.transferedCall);

              if (this.state.callAudio && !this.state.callAudio.paused) {
                this.state.callAudio.pause();
                this.state.callAudio.currentTime = 0;
              }
            }
            if (data.prevStatus === 'no-answer' && data.status === 'no-answer') {
              this.props.removeMissedCall(data.callerId, 1);
            }
            if (data.prevStatus === 'in-queue') {
              const incomingQueueCallModal = this.props.activeModals.find(modal => modal.type === MODAL_TYPES.incomingQueueCall) || {};

              if (this.state.callAudio && !this.state.callAudio.paused) {
                this.state.callAudio.pause();
                this.state.callAudio.currentTime = 0;
              }

              this.props.removeIncomingQueueCall(data.callId);

              if (incomingQueueCallModal.callId === data.callId) {
                this.props.closeModal(MODAL_TYPES.incomingQueueCall);
              }
            }
          }
          this.props.changeCallStatus({ ...data, isMine });
          break;
        }

        // Operators sockets
        case 'new_user_message': {
          switch (data.type) {
            case 'message': {
              if (data.message.interactionType === 'system_msg') {

                if (isEmptyObj(this.props.activeCall && data.status !== 'mute')) {
                  playRingtone(data.ringtone, CONTACT_TYPES.OPERATOR, 'sms');
                }

                this.props.newIncomingChatMessage(
                  { ...data.message, type: 8 },
                  currentUser.timezone.offset_minutes
                );

                if (
                  data.message.attachments &&
                  data.message.attachments.chatUpdate.action === 'chatCreated' &&
                  data.message.attachments.chatUpdate.userId === currentUser.id
                ) {
                  this.props.updateActiveChat(data.message.chatId, false);
                  break;
                }

                // TODO: refactor. This action update room photo and another actions in system_msg
                this.props.updateRoom(data.message.chat);

                break;
              }

              if (isMineInteraction(currentUser.id, data.message.senderId)) {
                this.props.newOutgoingChatMessage(
                  { ...data.message, type: 3 },
                  currentUser.timezone.offset_minutes
                );

                break;
              }

              if (isEmptyObj(this.props.activeCall)) {
                switch (data.message.caller.audio_status) {
                  case AUDIO_SETTINGS_TYPES.MUTE: break;
                  case AUDIO_SETTINGS_TYPES.IMPORTANT:
                    if (isEmptyObj(this.props.activeCall)) {
                      playRingtone(data.ringtone, CONTACT_TYPES.OPERATOR, 'sms');
                    }

                    this.props.updateRoom({ ...data.message.chat, audio_status: AUDIO_SETTINGS_TYPES.NORMAL, withImportantUnread: true });

                    break;
                  default:
                    if (isEmptyObj(this.props.activeCall)) {
                      playRingtone(data.ringtone, CONTACT_TYPES.OPERATOR, 'sms');
                    }
                    break;
                };
              }

              this.props.newIncomingChatMessage(
                { ...data.message, type: 4 },
                currentUser.timezone.offset_minutes
              );

              this.props.changeTypingStatus({
                id: data.message.chatId,
                type: 3,
                userId: data.message.senderId || data.message.userId,
              }, false);

              if (this.props.activeChatId === data.message.chatId) {
                sendDeliveredStatus(data.message.id);
              }
              break;
            }

            case 'attachments':
              this.props.newMsgAttachments(data.message);
              break;
            default:
              break;
          }

          break;
        }
        case 'new_notification': {

          switch (data.type) {
            case NOTIFICATION_TYPES.conferenceInvitation: {
              this.props.addNotification(data);
              break;
            }

            case NOTIFICATION_TYPES.nightBotReject: {
              this.props.addNotification(data);
              break;
            }

            case NOTIFICATION_TYPES.coldTransfer:
            case NOTIFICATION_TYPES.parkingCall: {
              // const userId = data.notification.call.user.id;
              const userId = data.userId;
              const isCurrentUser = currentUser.id === userId;

              !isCurrentUser && this.props.addNotification({
                ...data,
                duration: 30,
              });
              break;
            }

            case NOTIFICATION_TYPES.messageReminder: {
              const userId = data.notification.reminder.user_id;
              const isCurrentUser = currentUser.id === userId;

              if (isCurrentUser) {
                this.props.addNotification({
                  ...data.notification,
                  color: 'blue',
                });

                this.props.removeMessageReminder(
                  data.notification.reminder.id,
                  data.notification.reminder.message.caller.type,
                  false
                );
                //remove from reminders-timeline as expired and done
              }
              break;
            }

            // case NOTIFICATION_TYPES.warmTransfer: {
            //   const userId = data.notification.conference.creator_id;
            //   const isCurrentUser = currentUser.id !== userId;

            //   !isCurrentUser && this.props.addNotification({
            //     ...data,
            //     duration: 5,
            //   })
            //   break;
            // }
            case NOTIFICATION_TYPES.mention: {
              const isCurrentUser = currentUser.id === data.notification.user_id;

              isCurrentUser &&
                this.props.addNotification({
                  ...data.notification,
                  color: 'blue',
                });
              break;
            }
            case NOTIFICATION_TYPES.bufferedProfileBecomeOff: {
              this.props.addNotification({
                ...data,
                color: 'red',
              });
              break;
            }
            case NOTIFICATION_TYPES.addTelegramChannel:
            case NOTIFICATION_TYPES.changeContactNumber: {
              this.props.addNotification({
                ...data,
                color: 'red',
              });
            }
            case NOTIFICATION_TYPES.sessionView: {
              const {
                prev: leftSession,
                current: viewedSession,
              } = data.data;


              const aSession = this.props.activeSession;

              const isItMySession = (session) => session && session.usersIds.includes(currentUser.id);
              const isLeftMySession = leftSession && isItMySession(leftSession);
              const isViewedMySession = viewedSession && isItMySession(viewedSession);

              if (isLeftMySession) {
                this.props.removeNotification(data.type + '_' + leftSession.userId);

                this.props.updateSessionViewed(leftSession.id, leftSession.viewed);
              }
              if (isViewedMySession) {
                // const isActiveSession = viewedSession.id === aSession;

                this.props.addNotification({
                  ...data,
                  color: 'blue',
                });
                this.props.updateSessionViewed(viewedSession.id, viewedSession.viewed);
              }

              break;
            }
            case NOTIFICATION_TYPES.sessionProposed: {
              const notificationData = data.data;
              this.props.updateAssistanceSession(notificationData.session);

              const userId = notificationData.userId;
              const proposedIds = notificationData.session.proposed.byOperatorId[userId];

              const isAdding = proposedIds && proposedIds.length;

              if (isAdding) {
                this.props.addNotification({
                  ...data,
                });
              }
              else {
                this.props.removeNotification(data.type + '_' + userId);
              }
              break;
            }
            case NOTIFICATION_TYPES.sessionAssistanceRequest: {
              this.props.addNotification({
                ...data,
                color: 'orange',
              });
              break;
            }
            case NOTIFICATION_TYPES.sessionAssistanceDelete: {
              this.props.addNotification({
                ...data,
                color: 'yellow',
              });
              break;
            }
            case NOTIFICATION_TYPES.sessionAssistanceResponse: {
              const notificationData = data.data;

              this.props.addNotification({
                ...data,
                color: notificationData.isAccept
                  ? 'green'
                  : 'yellow',
                isAccept: data.isAccept,
              });
              break;
            }
            case NOTIFICATION_TYPES.sessionTransferRequest: {
              this.props.addNotification({
                ...data,
                color: 'orange',
              });
              break;
            }
            case NOTIFICATION_TYPES.sessionTransferResponse: {
              const notificationData = data.data;

              this.props.addNotification({
                ...data,
                color: notificationData.isAccept
                  ? 'green'
                  : 'yellow',
                isAccept: notificationData.isAccept,
              });
              break;
            }
            case NOTIFICATION_TYPES.missedClientCall: {
              this.props.addNotification({
                ...data,
              });
              break;
            }
            case NOTIFICATION_TYPES.missedSessionClientCall: {
              this.props.addNotification({
                ...data,
              });
              break;
            }
            case NOTIFICATION_TYPES.longSession: {
              this.props.addNotification({
                ...data,
                isCustom: true,
              });
              break;
            }
            case NOTIFICATION_TYPES.clientTextedInSession: {
              this.props.addNotification({
                ...data,
              })
              break;
            }
            case NOTIFICATION_TYPES.profileTextedInSession: {
              this.props.addNotification({
                ...data,
              });
              break;
            }
            // case NOTIFICATION_TYPES.newRelatedMessage: {
            //   this.props.addNotification({
            //     ...data,
            //   });
            //   break;
            // }
            case NOTIFICATION_TYPES.partnerHasntReactTo: {
              this.props.addNotification({
                ...data,
              });
              break;
            }
            // case NOTIFICATION_TYPES.partnerHasBookedProfileInSession: {
            //   this.props.addNotification({
            //     ...data,
            //   });
            //   break;
            // }
            case NOTIFICATION_TYPES.undeliveredMessage: {
              this.props.addNotification({
                ...data,
              });
              break;
            }
            case NOTIFICATION_TYPES.sessionUsingReminder: {

              this.props.addNotification({
                ...data,
              });
              break;
            }
            case NOTIFICATION_TYPES.newMail: {
              this.props.addNotification({
                ...data,
              });
              break;
            }
            default:
              break;
          }

          break;
        }

        case 'notification_delete': {
          this.props.removeNotification(data);
          break;
        }

        case 'user_message_update': {
          switch (data.type) {
            case 'status':
              this.props.changeChatMsgStatus(data.chatId);
              break;
            case 'update':
              this.props.updateChatMsg(data);
              break;
            case 'remove':
              this.props.removeChatMsg(data);
              break;
            case 'hide-message':
              this.props.removeGirlsChatMsg(data.interaction_id);
              break;
            default:
              break;
          }
          break;
        }

        case 'webmaster_task': {
          switch (data.type) {
            case 'add':
              this.props.addWebmasterTask(data.message);
              break;
            case 'remove':
              this.props.removeWebmasterTask(data.message);
              break;
            default:
              break;
          }
          break;
        }

        case 'new_user_call': {
          const isMine = isMineInteraction(currentUser.id, data.senderId);

          if (isMine) {
            this.props.newOutgoingOperatorCall(
              { ...data, type: 2, isMine: true },
              currentUser.timezone.offset_minutes
            );

            playRingtone(data.ringtone, 'callBeep', 'call');
            break;
          }
          this.props.newIncomingOperatorCall(
            { ...data, type: 1, isMine: false },
            currentUser.timezone.offset_minutes
          );

          playRingtone(data.ringtone, CONTACT_TYPES.OPERATOR, 'call');
          break;
        }

        case 'user_call_status': {
          const isMine = isMineInteraction(currentUser.id, data.senderId);
          const isOutgoingCallModal = this.props.activeModals.find(modal => modal.type === MODAL_TYPES.outgoingCall);
          const isIncomingCallModal = this.props.activeModals.find(modal => modal.type === MODAL_TYPES.incomingCall);

          if (['initiated', 'ringing'].indexOf(data.status) === -1) {
            if (this.state.callAudio && !this.state.callAudio.paused) {
              this.state.callAudio.pause();
              this.state.callAudio.currentTime = 0;
            }
          };

          if (data.status === 'connecting') {
            if (isMine) {
              const localPC = this.props.createPeerConnection(data.recipientId, this.props.localStream, true, this.props.activeCall.id);

              sendOfferToOperator(data.recipientId, localPC);
            }
            else {
              this.props.removeMissedOperatorCallForMe(data.senderId);
            }

            if (isIncomingCallModal) {
              this.props.closeModal(MODAL_TYPES.incomingCall);
            }
            else if (isOutgoingCallModal) {
              this.props.closeModal(MODAL_TYPES.outgoingCall);
            }
          }
          else if (data.status === 'in-progress') {
            this.props.timerStart(data.id);
          }
          else if (data.status === 'completed') {
            this.props.timerStop(data.id);

            const operatorId = isMine ? data.recipientId : data.senderId;
            this.props.killWebrtcCall(operatorId, this.props.peerConnections[operatorId], this.props.localStream);
            playRingtone('/media/sounds/callEnd.mp3', null, 'sms');
          }
          else if (data.status === 'no-answer') {
            if (isMine) {
              this.props.killWebrtcCall(data.recipientId, this.props.peerConnections[data.recipientId], this.props.localStream);
              playRingtone('/media/sounds/callEnd.mp3', null, 'sms');
            }
            else {
              this.props.newMissedCall(data, OPERATORS_CALL);
            }
          }
          else if (data.status === 'failed') {
            this.props.openModal(MODAL_TYPES.error, { text: 'Connection error' });

            const operatorId = isMine ? data.recipientId : data.senderId;
            this.props.killWebrtcCall(operatorId, this.props.peerConnections[operatorId], this.props.localStream);
            playRingtone('/media/sounds/callEnd.mp3', null, 'sms');
          }

          this.props.changeChatCallStatus({ ...data, isMine });

          break;
        }

        case 'update_user_data':
        case 'change_user_data': {
          const isCurrentUser = currentUser.id === data.id;

          if (!isCurrentUser && data.status !== this.props.operators[data.id].status) {
            this.props.addNotification({
              color: 'yellow',
              data: { user_id: data.id },
              type: NOTIFICATION_TYPES.changeOperatorStatus,
              id: NOTIFICATION_TYPES.changeOperatorStatus + '_' + data.id, // notification don`t save, so we set id manually
              duration: 5,
              status: data.status,
              isCustom: true,
            });
          }
          else if (isCurrentUser) {
            if (data.status === 'offline') {
              return window.location = '/client/login';
            }

            this.props.updateUser({ ...data });

            if (data.isSystemAway) {
              let messageSound = new Audio('./media/sounds/operators.mp3');
              messageSound.play();

              this.props.openModal(MODAL_TYPES.awaySystem);
            }
            break;
          }
          this.props.changeOperatorEntity(data);
          break;
        }

        case 'logout_inactive_user': {
          deleteCookie('isAuthenticated');
          delete localStorage.isLoggedIn;
          delete localStorage.currentUserId;
          window.location = '/client/login';
          break;
        }

        case 'user_start_typing': {
          if (data.userId !== currentUser.id) {
            if (!JSON.parse(data.typing)) {
              this.props.changeTypingStatus(data, false);
            }
            else {
              this.props.changeTypingStatus(data, true);
              this.props.debouncedChangeTypingStatus(data);
            }
          }

          if (!JSON.parse(data.typing) && typeof data.text === 'string') {
            this.props.updateContactFieldsById( data.id, { draft_message: data.text } )
          }
          break;
        }

        case 'conference': {
          switch (data.type) {
            case 'warm_transfer_start': { // when I am creator of warm transfer
              const conference = prepareConference(data.conference);

              this.props.acceptWarmTransfer(conference);

              this.props.timerStart('conf_' + conference.id, conference.date_created);
              break;
            }

            case 'warm_transfer_invitation': { // second user in warm transfer 
              const conference = prepareConference(data.conference);

              playRingtone(
                data.ringtone,
                CONTACT_TYPES.CLIENT,
                'call',
                () => this.props.openTransferedCallModal(conference, this.state.callAudio),
              );

              this.props.newWarmTransfer(conference);
              this.props.timerStart('conf_' + conference.id, conference.date_created);

              break;
            }

            case 'invitation_canceled': {
              const isTansferedCallModal = !!this.props.activeModals.find(modal => modal.type === MODAL_TYPES.transferedCall);
              const conferenceModal = this.props.activeModals.find(modal => modal.type === MODAL_TYPES.conferenceFullMode) || {};

              // if I cancel invitation to conference
              if (data.participant.type === PARTICIPANT_USER && data.participant.user_id === currentUser.id) {
                this.props.removeConference(data.participant.conference_id);

                if (isTansferedCallModal) {
                  this.props.closeModal(MODAL_TYPES.transferedCall);
                }
                else if (conferenceModal.conferenceId === data.participant.conference_id) {
                  this.props.closeModal(MODAL_TYPES.conferenceFullMode);
                }

                this.props.timerStop('conf_' + data.participant.conference_id);
              }
              // when invited operator cancel invitation to warm transfer => remove conference to avoid blinks before it turns to outgoing call
              else if (data.participant.conference_id === this.props.activeConference.id
                && this.props.activeConference.type === WARM_TRANSFER) {
                this.props.removeConference(data.participant.conference_id);

                if (isTansferedCallModal) {
                  this.props.closeModal(MODAL_TYPES.transferedCall);
                }
                else if (conferenceModal.conferenceId === data.participant.conference_id) {
                  this.props.closeModal(MODAL_TYPES.conferenceFullMode);
                }

                this.props.timerStop('conf_' + data.participant.conference_id);
              }
              // if user cancel invitation to conference => update his status in conferenceFullMode
              else {
                this.props.conferenceParticipantJoin(data.participant);
              }
              break;
            }

            case 'participant_invited':
            case 'participant-join': {
              this.props.conferenceParticipantJoin(data.participant);
              break;
            }
            case 'participant-leave': {
              if (data.participant.type === PARTICIPANT_USER && data.participant.user_id === currentUser.id) {
                const isTansferedCallModal = !!this.props.activeModals.find(modal => modal.type === MODAL_TYPES.transferedCall);
                const conferenceModal = this.props.activeModals.find(modal => modal.type === MODAL_TYPES.conferenceFullMode) || {};

                this.props.removeConference(data.participant.conference_id);

                if (isTansferedCallModal) {
                  this.props.closeModal(MODAL_TYPES.transferedCall);
                }
                else if (conferenceModal.conferenceId === data.participant.conference_id) {
                  this.props.closeModal(MODAL_TYPES.conferenceFullMode);
                }

                this.props.timerStop('conf_' + data.participant.conference_id);
              }
              this.props.conferenceParticipantLeave(data.participant);
              break;
            }

            case 'completed': {
              const isTansferedCallModal = !!this.props.activeModals.find(modal => modal.type === MODAL_TYPES.transferedCall);
              const conferenceModal = this.props.activeModals.find(modal => modal.type === MODAL_TYPES.conferenceFullMode) || {};

              if (this.state.callAudio && !this.state.callAudio.paused) {// if caller drop the phone while warm-transfer
                this.state.callAudio.pause();
                this.state.callAudio.currentTime = 0;
              }

              this.props.removeConference(data.conference.id);

              if (isTansferedCallModal) {
                this.props.closeModal(MODAL_TYPES.transferedCall);
              }
              else if (conferenceModal.conferenceId === data.conference_id) {
                this.props.closeModal(MODAL_TYPES.conferenceFullMode);
              }

              this.props.timerStop('conf_' + data.conference.id);
              break;
            }

            case 'new_conference': {
              const conference = prepareConference(data.conference);
              const isConferenceModal = this.props.activeModals.find(modal => modal.type === MODAL_TYPES.conferenceFullMode);

              this.props.timerStart('conf_' + conference.id, conference.date_created);

              this.props.newConference(conference); // you create new conference

              if (!isConferenceModal) {
                this.props.openModal(MODAL_TYPES.conferenceFullMode, { conferenceId: conference.id });
              }
              break;
            }

            case 'conference_invitation': { // second user in warm transfer or conference
              const conference = prepareConference(data.conference);

              this.props.timerStart('conf_' + conference.id, conference.date_created);

              this.props.conferenceInvitation(conference);
              this.props.openTransferedCallModal(conference, this.state.callAudio);

              // callAudio.play();   //TODO: now this is working different
              break;
            }

            default:
              break;
          }

          break;
        }

        // Synchronization of operators work
        case 'contacts_sync': {
          switch (data.type) {
            case 'create':
              this.props.addNewContactToState(data.contact);
              break;
            case 'related_user_update':
            case 'update': {
              const { initialType, contact } = data;

              const isGirl = (type) => [2, 3, 4].includes(type); // agent, girl ...

              const recentTabs = LS.getItem(
                initialType === CONTACT_TYPES.CLIENT
                  ? 'clientChatsRecentTabs'
                  : 'girlChatsRecentTabs'
                ,
                currentUser.id);

              let isTypeChanged = initialType && contact.type !== initialType;

              if (initialType && isGirl(initialType) && isGirl(contact.type)) {
                isTypeChanged = false;
              }

              if (isTypeChanged && recentTabs) {
                const relevantTabs = recentTabs.all.filter(tab => tab !== contact.id);

                this.props.updateRecentTabs(null, initialType, relevantTabs);
              }

              this.props.updateContactInState(contact, initialType, isTypeChanged);

              const chatType = contact.type === 1
                ? CHAT_TYPES.CLIENT
                : CHAT_TYPES.GIRL;

              if (data.isMerge && (this.props.activeClientChatId === contact.id || this.props.activeGirlChatId === contact.id)) {
                this.props.getContactTimeline(contact.id, chatType, currentUser.timezone.offset_minutes);
              }

              if (contact.type === CONTACT_TYPES.GIRL) {
                if (contact.availability === 'off' || contact.availability === 'off today') {
                  this.props.addContactsToEntities([contact]);
                  this.props.addGirlToOffToday(contact.id);
                }
                else if (this.props.offTodayIds.includes(contact.id)) {
                  this.props.removeGirlFromOffToday(contact.id);
                }

                if (contact.availability === 'available') {
                  this.props.addContactsToEntities([contact]);
                  this.props.addGirlToAvailable(contact.id);
                }
                else if (this.props.availableIds.includes(contact.id)) {
                  this.props.removeGirlFromAvailable(contact.id);
                }
              }

              if (isTypeChanged && contact.type === CONTACT_TYPES.CLIENT) {
                if (this.props.offTodayIds.includes(contact.id)) {
                  this.props.removeGirlFromOffToday(contact.id);
                }
                else if (this.props.availableIds.includes(contact.id)) {
                  this.props.removeGirlFromAvailable(contact.id);
                }
              }

              break;
            }
            case 'remove':
              const recentTabs = LS.getItem(
                data.contact.type === CONTACT_TYPES.CLIENT
                  ? 'clientChatsRecentTabs'
                  : 'girlChatsRecentTabs'
                ,
                currentUser.id);

              if (recentTabs) {
                const relevantTabs = recentTabs.all.filter(tab => tab !== data.contact.id);

                this.props.updateRecentTabs(null, data.contact.type, relevantTabs);
              }

              this.props.removeContactFromState(data.contact);
              break;
            case 'change_active_chat':
              const { caller } = data;

              this.props.updateContactInState(caller, caller.type);
              this.props.toggleContactActiveChat(caller);
              break;

            default:
              break;
          }

          break;
        }

        case 'girls_sync': {
          switch (data.type) {
            case 'update': {
              this.props.updateDivaGirl(data.profile);
              break;
            }
          }
          break;
        }

        case 'ping': {
          webSocket.send(JSON.stringify({ type: 'pong', data: 'pong' }));

          break;
        }

        case 'chat_delete': {
          const recentTabs = LS.getItem('roomChatsRecentTabs', currentUser.id);

          if (recentTabs) {
            const relevantTabs = recentTabs.all.filter(tab => tab !== data.chatId);

            this.props.updateRecentTabs(null, CHAT_TYPES.ROOM, relevantTabs);
          }

          this.props.removeRoom(data.chatId);
          break;
        }

        case 'sales_sessions': {
          switch (data.type) {
            case 'session_start':

              if(this.props.user.id === data.session.userId) {
                this.props.addSession(data.session);
              } else this.props.addPartnerSession(data.session);

              break;
            case 'session_close':
            case 'session_delete': {
              if (this.props.activeModals.find(modal => { // If open buffer modal with deleted session
                return modal.type === 'profiles-buffer' && modal.props.sessionId === data.session.id
              })) {
                this.props.closeModal(MODAL_TYPES.profilesBuffer);
                // use timeout because remove modal use timeout 500ms
                setTimeout(() => {
                  this.props.removePartnerSession(data.session.id);
                }, 600);
              }
              else {
                this.props.removePartnerSession(data.session.id);
              }
                
              break;
            }
             
            case 'session_continue':
              this.props.addPartnerSession(data.session);
              break;
            case 'session_toggle':
              this.props.partnerToggleHistorySessionSuccess(data.session);
              break;
            case 'session_update':
            case 'session_with_future_booking':
              this.props.updateSession(data.session);
              break;
            case 'bufferedIds':
              this.props.updateSessionBuffer(data.session);
              break;
            case 'bookedIds':
              this.props.updateAssistanceSession(data.session);
              break;
            case 'comparedIds':
              this.props.updateAssistanceSession(data.session);

              if (!!data.session.comparedIds.length) {
                this.props.updateAdditionalTitleFilters(
                  ADDITIONAL_SESSION_FILTERS.search,
                  '',
                  data.session.id,
                  this.props.additionalFilters,
                  this.props.canIEditSession,
                  true,
                );
              }
              break;
            case 'additionalFilters':
              this.props.updateAdditionalFilters(data.session.id, data.session.additionalFilters);
              break;
            case 'activeFilters':
              this.props.updateActiveFiltersFromSocket(data.session.id, data.session.activeFilters);
              break;
            default:
              break;
          }

          break;
        }
        case 'sales_session': {
          switch (data.type) {
            case 'update_session_booking':
            case 'create_session_booking': {
              const booking = data.booking;
              const sessionId = data.booking.session_id;

              this.props.updateBooking(sessionId, booking);
              break;
            }
            case 'delete_session_booking': {
              const booking = data.booking;
              const sessionId = data.booking.session_id;
              
              // In case we`ve created a booking from chat, we can`t find it in the state for deletion
              if(this.props.sessions[sessionId]) {
                this.props.removeBooking(sessionId, booking);
              }
              break;
            }
            case 'update_session_booking_request':
            case 'create_session_booking_request': {
              this.props.updateBookingRequest(data.sessionId, data.request);
              break;
            }
            case 'delete_session_booking_request': {
              this.props.deleteBookingRequest(data.sessionId, data.request);
              break;
            }
          }

          break;
        }

        case 'mailing': {
          switch (data.type) {
            case 'success':
            case 'progress':
              this.props.updateModalProps(data.data, 'mailing-component');
              break;
          }
          break;
        }

        case 'webrtc_conference': {
          switch (data.type) {
            case 'update': {
              this.props.updateWebrtcConference(data.conference);
              break;
            }

            case 'participant_left': {
              const isMe = data.participant.user_id === this.props.user.id;

              if (isMe) {
                const thisConference = this.props.conferences[data.participant.conference_id];

                thisConference && this.props.leaveFromActiveConference(thisConference, this.props.peerConnections, this.props.localStream);
                playRingtone('/media/sounds/callEnd.mp3', null, 'sms');
              }
              // operator left my active conference; delete PC with him
              else if (data.participant.conference_id === this.props.activeWebrtcConferenceId) {
                this.props.deletePC(data.participant.user_id, this.props.peerConnections[data.participant.user_id]);
              }

              this.props.webrtcParticipantLeft(data.participant);
              break;
            }

            case 'participant_joined': {
              this.props.updateWebrtcConferenceParticipant(data.participant);
              break;
            }

            case 'update_participant': {
              const isCurrentUser = data.participant.user_id === this.props.user.id;
              const isOpenCurrentConferenceModal = this.props.activeModals.find(modal =>
                modal.type === MODAL_TYPES.webrtcConferenceFullMode
                && modal.props
                && modal.props.conferenceId === data.participant.conference_id
              );
              const currentConference = this.props.conferences[data.participant.conference_id];
              const prevParticipantData = currentConference.participants[data.participant.user_id];

              this.props.updateWebrtcConferenceParticipant(data.participant);

              switch (data.participant.status) {
                case 'active': {
                  if (isCurrentUser && prevParticipantData && prevParticipantData.status === 'connecting') {
                    this.props.localStream && this.props.localStream.getTracks().forEach(track =>
                      track.enabled = !prevParticipantData.is_muted && !prevParticipantData.is_muted_conference
                    );
                    this.props.toggleWebrtcConferenceMute(!!prevParticipantData.is_muted_conference);
                  }
                  if (isCurrentUser && prevParticipantData && prevParticipantData.status === 'busy') {
                    this.props.removeFromHoldActiveConference(currentConference);
                  }
                  break;
                }

                case 'connecting': {
                  if (isCurrentUser && prevParticipantData && prevParticipantData.status === 'busy') {
                    this.props.removeFromHoldActiveConference(currentConference);
                  }
                }

                case 'rejected': {
                  if (!isOpenCurrentConferenceModal) {
                    this.props.removeWebrtcParticipant(data.participant);
                  }
                  break;
                }

                case 'failed': {
                  if (!isOpenCurrentConferenceModal) {
                    this.props.removeWebrtcParticipant(data.participant);
                  }

                  if (isCurrentUser) {
                    this.props.leaveFromActiveConference(currentConference, this.props.peerConnections, this.props.localStream);
                  }
                  else {
                    this.props.deletePC(data.participant.user_id, this.props.peerConnections[data.participant.user_id]);
                  }
                  break;
                }
                default: break;
              }
              break;
            }
          }
          break;
        }

        case 'webrtc': {
          switch (data.type) {
            case 'offer-description': {
              let localPC = this.props.peerConnections[data.operatorId];

              if (!localPC) {
                localPC = this.props.createPeerConnection(data.operatorId, this.props.localStream, null, null, true);
              }

              localPC.setRemoteDescription(new RTCSessionDescription(JSON.parse(data.description)));

              localPC.createAnswer()
                .then((sessionDescription) => {
                  localPC.setLocalDescription(sessionDescription);

                  API.sendLocalDescription(data.operatorId, sessionDescription, 'answer');
                })
                .catch(console.log);
              break;
            }

            case 'answer-description': {
              const localPC = this.props.peerConnections[data.operatorId];

              localPC && localPC.setRemoteDescription(new RTCSessionDescription(JSON.parse(data.description)));
              break;
            }

            case 'ice-candidate': {
              const localPC = this.props.peerConnections[data.operatorId];

              !!localPC && !!data.candidate && localPC.addIceCandidate(new RTCIceCandidate(JSON.parse(data.candidate)));
              break;
            }
          }

          break;
        }

        case 'mail': {
          const mailData = data.data;

          switch (data.type) {
            case 'new_email': {
              if (mailData.mail.conversationId === this.props.activeMailChatId) {
                this.props.addMessageToMailChat(mailData.message);
              }
              else {
                this.props.addEmail(mailData);
              }
              break
            }
            case 'trash': {
              const { id, conversationId } = data;
              if (id) {
                this.props.deleteMsgFromMailChat(id);
              } else if (conversationId) {
                this.props.deleteConversationToTrash(conversationId);
              }
              break;
            }
            case 'permanently': {
              const { id, conversationId, folder } = data;
              if (id) {
                this.props.deleteMsgFromMailChat(id);
              } else if (conversationId) {
                this.props.deleteConversationPermanently({ conversationId, folder });
              }
              break;
            }
            case 'un_spam':
              this.props.markMailsNotSpam(data.conversationId);
              break;
            case 'undelete': {
              const { id: msgId, conversationId } = data;
              this.props.undeleteMails({ msgId, conversationId });
              break;
            }
            default: break;
          }
          break;
        }

        case 'contact-tags': {
          switch (data.type) {
            case 'update': {
              this.props.updateAdrBookTags(data);
              break;
            }
            default: break;
          }
          break;
        }

        case 'message-deleted':
          this.props.deleteTelegramMsg(data);
          break;

        default:
          break;
      }
    };
  }

  render() {
    return null;
  }
}

const mapStateToProps = state => ({
  activeModals: state.activeWindows.activeModals,
  incomingCall: state.calls.incomingCall,
  activeCall: state.calls.activeCall,
  callsOnHold: state.calls.callsOnHold,
  callsOnTransfer: state.calls.callsOnTransfer,
  operators: state.operators.entities,
  user: state.user,
  offTodayIds: state.rooms.offTodayIds,
  availableIds: state.rooms.availableIds,
  // userMode: state.user.mode,
  settings: state.settings,
  timers: state.timers,
  activeSession: state.sessions.activeSession,
  activeChatId: state.roomChats.active,
  activeClientChatId: state.clientChats.active,
  activeGirlChatId: state.girlChats.active,
  activeMailChatId: state.mail.activeMailChatId,

  connection: state.twilio.connection,

  activeConference: state.conferences.activeConference,

  peerConnections: state.webrtc.peerConnections,
  localStream: state.webrtc.localStream,
  activeWebrtcConferenceId: state.webrtc.activeConferenceId,
  conferences: state.webrtc.conferences,

  additionalFilters: (!!state.sessions.entities[state.sessions.activeSession] && selectPropertyOfActiveSession(state, 'additionalFilters')),
  canIEditSession: selectCanIEditSession(state),
  sessions: state.sessions.entities,
});

const mapDispatchToProps = {
  updateActiveChat,
  newIncomingMessage,
  newOutgoingMessage,
  newIncomingCall,
  changeCallStatus,
  changeChatCallStatus,
  changeMessageStatus,
  newOutgoingCall,
  newActiveCall,
  finishCallOnHold,
  removeTransferedCall,
  timerStart,
  timerStop,
  newMissedCall,
  removeMissedCall,
  changeOperatorStatus,
  addNewContactToState,
  updateContactInState,
  removeContactFromState,
  newTransferedCall,
  newIncomingChatMessage,
  newOutgoingOperatorCall,
  newIncomingOperatorCall,
  finishCall,
  newMsgAttachments,
  newOutgoingChatMessage,
  newIncomingQueueCall,
  removeIncomingQueueCall,
  changeChatMsgStatus,
  openTransferedCallModal,
  updateChatMsg,
  removeChatMsg,
  updateActiveContact,
  updateVoicemailStatus,
  addPartnerSession,
  addSession,
  removePartnerSession,
  removeMissedOperatorCallForMe,
  partnerToggleHistorySessionSuccess,
  updateSession,
  updateAssistanceSession,
  updateBooking,
  updateBookingRequest,
  deleteBookingRequest,
  updateBookingScheduledMessages,
  deleteBookingScheduledMessages,
  removeBooking,
  updateSessionViewed,
  updateRoom,
  removeRoom,
  addGirlToOffToday,
  removeGirlFromOffToday,
  addGirlToAvailable,
  removeGirlFromAvailable,
  addNotification,
  removeNotification,
  changeOperatorEntity,
  updateUser,
  debouncedChangeTypingStatus,
  changeTypingStatus,
  newConference,
  newWarmTransfer,
  acceptWarmTransfer,
  conferenceParticipantJoin,
  conferenceParticipantLeave,
  removeConference,
  conferenceInvitation,
  removeGirlsChatMsg,
  addWebmasterTask,
  removeWebmasterTask,
  removeMessageReminder,
  updateSessionBuffer,
  addNewChatRoom,
  getContactTimeline,
  closeModal,
  openModal,
  updateContactMsg,
  updateRecentTabs,
  toggleContactActiveChat,
  updateModalProps,
  createSession,
  updateAdditionalFilters,
  updateActiveFiltersFromSocket,
  deleteContactMsg,
  newScheduledMsg,
  newServiceMsg,
  createPeerConnection,
  deletePC,
  updateWebrtcConference,
  updateWebrtcConferenceParticipant,
  removeWebrtcParticipant,
  toggleWebrtcConferenceMute,
  webrtcParticipantLeft,
  leaveFromActiveConference,
  removeFromHoldActiveConference,
  killWebrtcCall,
  updateAdditionalTitleFilters,
  updateContactFieldsById,
  addEmail,
  addMessageToMailChat,
  deleteMsgFromMailChat,
  deleteConversationToTrash,
  deleteConversationPermanently,
  markMailsNotSpam,
  undeleteMails,
  deleteTelegramMsg,
  resetDefaultSessionFilters,
  updateDivaGirl,
  addContactsToEntities,
  updateAdrBookTags,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChatSocket));