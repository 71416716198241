import React from 'react';

import './ChatCreatorButton.scss';


const ChatCreatorButton = (props) => (
  <button
    onClick={props.onClick}
    className="chat-creator-button">
    {props.children}
  </button>
);

export default ChatCreatorButton;