import React from 'react';

import { CHAT_SOURCES } from '../../../../../config/constants';
import { formatNotificationDate, getDateByTimezoneOffset } from '../../../../../utils';

const RemindMessage = ({ interaction, isMainTimelineOpen, ...props }) => {
  const goToMsgContext = (e) => {
    if (e.target.hasAttribute('data-close')) {
      return props.removeMessageReminder(interaction.id, interaction.interaction.caller.type, false, isMainTimelineOpen);
    }

    props.setActiveChatSource(CHAT_SOURCES.MSGS);
    props.getMessageContext(interaction.interaction.id, interaction.interaction.caller);
  };

  return (
    <div
      onClick={goToMsgContext}
      className="interaction-reminder">
      <p className="interaction-reminder__title">
        You ask to remind about this message
        <span className="interaction-reminder__time">
          {formatNotificationDate(getDateByTimezoneOffset(props.userTimezone, interaction.remind_at), props.userHour12)}
        </span>

        <span className='interaction-reminder__close' data-close />
      </p>
      <div className="interaction-reminder__content">
        {props.children}
      </div>
    </div>
  );
}

export default RemindMessage;