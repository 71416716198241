import React, { useRef, useState, useEffect, useLayoutEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { usePopperTooltip } from 'react-popper-tooltip';

import API from "../../api/api";
import { useToggle, useDidMount, useDidUpdate, useWillUnmount } from "../../hooks";
import { DIVA_GIRLS_IMG_HOST, getDivaGirls } from "../../ducks/divaGirls";
import { classModifier, getDateByTimezoneOffset, isEmptyObj, convertHoursTo12h } from "../../utils";
import {
  selectCanIEditSession,
  selectContactBySessionId,
  selectIsContactMember,
  selectIsPartnersSession,
  selectUserTimezone,
  selectAllRequirements
} from '../../selectors/selectors';
import { updateBooking , updateBufferedId, removeBooking, createSession } from '../../ducks/sessions';
import { CANCEL_BOOKING_REASONS } from '../../config/constants';
import { openModal, MODAL_TYPES } from '../../ducks/activeWindows';

import './BookedEditor.scss';
import DropWrapper from "../DropWrapper/DropWrapper";
import Select from "../Select/Select";
import Toggle from "../Toggle/Toggle";
import LazyLoadImage from "../LazyLoadImage/LazyLoadImage";
import DelayedComponent from "../DelayedComponent/DelayedComponent";
import AsyncButton from '../AsyncButton/AsyncButton';
import Calendar from '../Calendar/Calendar';
import {
  RequirementsCard,
  ScheduledMsgItem,
  RequestItem,
  RequirementsButton,
  ServicesList
} from './components';
import Spinner from '../UI/Spinner/Spinner';
import SvgIcon from '../SvgIcon/SvgIcon';
import ProfilesListForEdit from '../ProfilesListForEdit/ProfilesListForEdit';


const bookingTypes = [
  { name: "select" },
  { name: "incall" },
  { name: "outcall" },
];

const roundTo5Minutes = minutes => {
  const ceilMinutes = Math.ceil(minutes / 5) * 5;
  return ceilMinutes >= 60 ? ceilMinutes - 5 : ceilMinutes;
}

const getDefaultTime = (timezone, hour12 = false) => {
  const date = getDateByTimezoneOffset(timezone);
  let hours = date.getHours();
  let minutes = roundTo5Minutes(date.getMinutes());

  if (hour12) {
    hours = hours > 12 ? hours - 12 : hours;
    if (hours === 0) {
      hours = 12;
    }
  }
  if (hours < 10) {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }

  return hours + ':' + minutes;
};

const getDefaultDayPart = (timezone, hours12 = false) => {
  if (hours12) {
    const date = getDateByTimezoneOffset(timezone);
    let hours = date.getHours();
    let dayPart = hours < 12 ? "AM" : "PM";

    return dayPart;
  }

  return '';
};


const BookedEditor = props => {
  const {
    parentRef,
    isEditingMode,
    booking,
    userTimezone,
    isMember,
    userHour12,
    aSessionClient,
    isAutoPosition,
    toggleConfirmOnClose,
    allRequirements,
    bookingListItemRowRef
  } = props;

  const [editedProfile, setEditedProfile] = useState(null);
  const [changeGirlMode, toggleChangeGirlMode] = useToggle(false);

  const profile = editedProfile || props.profile || {};
  const prices_member = profile?.prices_member ? [...profile?.prices_member] : [];
  const prices = profile?.prices ? profile?.prices.filter((price, i) => {
    if (!price.incall) {
      prices_member[i] = '';
    }
    return !!price.incall
  }) : [];
  const discounts = prices_member.filter(price => !!price);
  const bookingServices = !!booking ? booking.services : [];

  const [isMount, setMount] = useState(false);
  const [hour12, setHour12] = useState(userHour12);
  const [styles, setStyles] = useState({});
  const [isCalendarOpen, toggleCalendarOpen] = useToggle(false);
  const [isSelectedDiscountRate, setIsSelectedDiscountRate] = useState(false);
  const [isFutureBooking, setIsFutureBooking] = useToggle(!!booking && !booking.date);
  const [isBtnSuccess, toggleBtnSuccess] = useState(!!booking && !!booking.is_success);
  const [currentCancelReason, setCurrentCancelReason] = useState(!!booking && booking.reason);
  // "&& true" statement was added because of "React hook call conditionally" issue appears
  const [cancelDescription, setCancelDescription] = useState((!!booking && booking.description && true) ? booking.description : '');

  const [selectedValues, setSelectedValues] = useState({
    time: '00:00',
    type: bookingTypes[0].name,
    duration: prices[0]?.name || '',
    rate: '-',
    discount: '',
    date: getDateByTimezoneOffset(userTimezone),
    isSuccess: false,
    dayPart: 'AM',
    cancel_reason: (!!booking && booking.reason) ? booking.reason : '',
    description: (!!booking && booking.description) ? booking.description : '',
    operatorId: props.userData.id,
    services: bookingServices,
  });

  const [newScheduledMsgsList, setNewScheduledMsgsList] = useState([]);
  const [editableScheduledMsgId, setEditableScheduledMsgId] = useState();

  // for field 'Contact' in scheduled msg
  const [contactsList, setContactsList] = useState(aSessionClient ? [aSessionClient] : []);
  const [isContactPending, setIsContactPending] = useToggle(true);

  // for new scheduled msg
  const [scheduledMsgBlockHeight, setScheduledMsgBlockHeight] = useState(0);
  const [isNewScheduledMsg, setIsNewScheduledMsg] = useToggle(false);
  const [isTransitionUnsetClass, setIsTransitionUnsetClass] = useState(false);
  const [isOverflowUnsetClass, setIsOverflowUnsetClass] = useState(false);

  // for request
  const [selectedRequest, setSelectedRequest] = useState({
    text: '',
    is_success: 1,
    description: ''
  });
  const [isOpenNewRequest, toggleOpenNewRequest] = useToggle(false);
  const [isOpenServices, toggleOpenServices] = useToggle(false);

  // Used in RequirementsCard, RequirementsButton
  const initRequirements = useMemo(() => {
    const source = booking ? booking.requirements || [] : allRequirements;

    return source.map(({ requirement_id, id, title, isChecked, color }) => ({
      id: booking ? requirement_id : id,
      title,
      isChecked: booking ? !!isChecked : false,
      color
    }))
  }, []);
  const [requirements, setRequirements] = useState(initRequirements);
  const [isShowRequirements, setIsShowRequirements] = useState(false);

  const calendarRef = useRef();
  const isInitializing = useRef(true);
  const bookedEditorRef = useRef();
  const topPositionOnMount = useRef(null);

  const isChangedUser = selectedValues.operatorId
    ? (booking?.usersIds[0] || props.userData.id) !== selectedValues.operatorId
    : false;

  const {
    visible,
    setTooltipRef,
    setTriggerRef,
    getTooltipProps
  } = usePopperTooltip({
    trigger: 'click',
    placement: 'auto',
    visible: changeGirlMode,
    mutationObserverOptions: null,
    onVisibleChange: toggleChangeGirlMode,
  });

  useDidMount(() => {
    if (isEmptyObj(profile)) {
      props.getDivaGirls({
        'filter-ids': [props.profileId],
      })
    }

    setMount(true);

    if (profile.contact_uid) {
      setIsContactPending(true)
      API.getGirlsByContactUid([profile.contact_uid])
        .then(({ data }) => {
          if (data && data[0]) {
            setContactsList(prevValues => ([
              ...prevValues,
              data[0],
            ]));
          }
        })
        .catch(console.log)
        .finally(() => setIsContactPending(false));
    }
    else {
      setIsContactPending(false);
    }
  });

  // Calculate div.booked-editor coordinates in viewport
  // To does not go out from the visible area when height changes
  useLayoutEffect(() => {
    if (parentRef && !props.isParsedGirlsListModal) {
      const { width: ownWidth, height: ownHeight } =
        bookedEditorRef.current.getBoundingClientRect();
      const { left: parentLeft, top: parentTop, bottom: parentBottom } =
        parentRef.current.getBoundingClientRect();

      if (!topPositionOnMount.current) {
        topPositionOnMount.current = props.isChat ? parentBottom : parentTop;
      }

      // Top initial
      let top = topPositionOnMount.current;
      // Move higher if does not fit at the bottom
      const bottom = top + ownHeight;
      const bottomOffset = bottom - (document.documentElement.clientHeight - 5);
      top = bottomOffset > 0 ? top - bottomOffset : top;
      // Leave at very top if does not fit on high
      top = top < 5 ? 5 : top;
      // Subtract navbar height in sales page
      top = props.isChat ? top : top - 60;

      // Left - similarly
      let left = parentLeft;
      // Move to the left
      const right = left + ownWidth;
      const rightOffset = right - (window.innerWidth - 30);
      left = rightOffset > 0 ? left - rightOffset : left;
      // Leave at the edge if necessary
      left = left < 15 ? 15 : left;
      // Subtract .sale__container padding
      left = props.isChat ? left : left - 15;

      setStyles({ top, left, position: "fixed" });
    }
  }, [
    isShowRequirements,
    isOpenServices,
    isOpenNewRequest,
    isNewScheduledMsg,
    props.profile,
    booking?.requests,
    selectedRequest,
    booking?.scheduleMessages,
    newScheduledMsgsList,
    scheduledMsgBlockHeight,
    // Other potential dependencies:
    // isOverflowUnsetClass, isTransitionUnsetClass, editableScheduledMsgId,
    // isContactPending, contactsList, booking.id
  ]);

  useEffect(() => {
    const isContactInList = contactsList.find(contact => contact.id === aSessionClient.id) !== -1;
    if (aSessionClient && !isContactInList) {
      setContactsList(prevValues => ([
        aSessionClient,
        ...prevValues,
      ]));
    }
  }, [aSessionClient]);

  // Disable animations
  // useEffect(() => {
  //   if (!props.isAnimatedInit) return;

  //   let timeout;
  //   if (bookedEditorRef.current) {
  //     setStyles(prevStyles => ({
  //       ...prevStyles,
  //       height: bookedEditorRef.current.getBoundingClientRect().height,
  //     }))

  //     // when a booked editor block is open, remove fixed height
  //     timeout = setTimeout(() => {
  //       setStyles(prevStyles => ({
  //         ...prevStyles,
  //         height: 'unset',
  //       }))
  //     }, 500);
  //   }

  //   return () => clearTimeout(timeout);
  // }, [bookedEditorRef.current]);

  // when a new scheduled message block is open, remove css overflow: hidden
  useEffect(() => {
    let timeout;
    if (isNewScheduledMsg) {
      timeout = setTimeout(() => {
        setIsOverflowUnsetClass(true);
      }, 500);
    } else {
      setIsOverflowUnsetClass(false);
    }

    return () => {
      clearTimeout(timeout);
    }
  }, [isNewScheduledMsg]);

  useEffect(() => {
    if (booking && (isInitializing.current || !isEditingMode)) {
      const { type, duration, rate, services } = booking;
      const date = getDateByTimezoneOffset(userTimezone, booking.date);
      // const date = new Date(booking.date);
      let hours = date.getHours() + '';
      let minutes = date.getMinutes() + '';
      let amPm = '';

      if (hour12) {
        amPm = hours < 12 ? "AM" : "PM";
        hours = hours > 12 ? hours - 12 : hours;
        if (hours == 0) {
          hours = 12;
        }
      }

      if (hours.length === 1) {
        hours = '0' + hours;
      }
      if (minutes.length === 1) {
        minutes = '0' + minutes;
      }

      if (booking.discount_rate) {
        setIsSelectedDiscountRate(true);
      } else {
        setIsSelectedDiscountRate(false)
      }
      const discount = discounts.filter(el => el.name === duration)[0];

      setSelectedValues({
        time: hours + ':' + minutes,
        type,
        duration,
        rate,
        discount: discount && discount[type],
        date,
        isSuccess: !!booking.is_success,
        dayPart: hour12 ? amPm : '',
        cancel_reason: !booking.is_success ? booking.reason : '',
        description: !booking.is_success ? booking.description : '',
        operatorId: booking ? booking.usersIds[0] : props.userData.id,
        services,
      });

      toggleBtnSuccess(!!booking.is_success)
    }
    else if (isInitializing.current && !booking) {
      setSelectedValues(prevState => ({
        ...prevState,
        time: getDefaultTime(userTimezone, userHour12),
        dayPart: getDefaultDayPart(userTimezone, userHour12),
      }))
    }

    isInitializing.current = false;
  }, [isEditingMode, userTimezone, hour12]);

  useEffect(() => {
    if (isBtnSuccess) {
      setSelectedValues(prevState => ({
        ...prevState,
        isSuccess: true,
        cancel_reason: '',
        description: '',
      }));

      if (!!booking && !booking.reason) {
        setCurrentCancelReason('');
      }

      if (!!booking && !booking.description) {
        setCancelDescription('')
      }
    }
  }, [isBtnSuccess]);

  useWillUnmount(() => {
    if (toggleConfirmOnClose) {
      toggleConfirmOnClose(false);
    }
  });

  // Updating a new duration and new default services values if we need to change profile
  useDidUpdate(() => {
    setSelectedValues({
      ...selectedValues,
      duration: prices[0]?.name || '',
      services: [],
    });
  }, [editedProfile]);

  useDidUpdate(() => {
    setHour12(userHour12);
  }, [userHour12]);

  // Resetting the duration value if we don't have a profile in the state
  useDidUpdate(() => {
    if(!booking && props.profile) {

      const prices = props.profile?.prices ? props.profile?.prices.filter((price, i) => {
        if (!price.incall) {
          prices_member[i] = '';
        }
        return !!price.incall
      }) : [];

      setSelectedValues({
        ...selectedValues,
        duration: prices[0]?.name
      })
    }
  }, [props.profile]);

  const timeOptions = useMemo(() => {
    const hours = [];
    const minutes = [];
    const dayPart = [
      { name: "AM", value: 'AM', type: "dayPart" },
      { name: "PM", value: 'PM', type: "dayPart" }
    ];

    if (hour12) {
      for (let i = 1; i < 13; i++) {
        hours.push({
          name: i < 10
            ? '0' + i
            : '' + i,
          value: i,
          type: 'hours',
        });
      }
    }
    else {
      for (let i = 0; i < 24; i++) {
        hours.push({
          name: i < 10
            ? '0' + i
            : '' + i,
          value: i,
          type: 'hours',
        });
      }
    }

    for (let j = 0; j < 60; j += 5) {
      minutes.push({
        name: j < 10
          ? '0' + j
          : '' + j,
        value: j,
        type: 'minutes',
      });
    }

    return { hours, minutes, dayPart };
  }, [hour12]);

  const selectDate = (date) => {
    setSelectedValues(prevState => ({ ...prevState, date }));
    toggleCalendarOpen();
  }

  const dateString = new Date(selectedValues.date).toLocaleDateString();

  const selectTypeOrDuration = (value) => {
    if (value.name === 'select') {
      return setSelectedValues(prevState => ({
        ...prevState,
        type: value.name,
        rate: '-',
        discount: '-',
      }));
    };

    const isBookingType = value.name === 'incall' || value.name === 'outcall';

    if (isBookingType) {
      const currentPrice = prices.find(price => price.name === selectedValues.duration);
      const currentDiscount = discounts.find(price => price.name === selectedValues.duration);

      setSelectedValues(prevState => ({
        ...prevState,
        type: value.name,
        rate: currentPrice?.[value.name],
        discount: currentDiscount?.[value.name]
      }));
    }
    else { // isDuration
      const discount = discounts.filter(el => el.name === value.name)[0];
      setSelectedValues(prevState => ({
        ...prevState,
        duration: value.name,
        rate: value[selectedValues.type],
        discount: discount && discount[selectedValues.type],
      }))
    }
  }

  const onChangeGirl = (selectedProfile) => {
    setEditedProfile(selectedProfile);
    
    toggleChangeGirlMode(false);
  }

  const handleSelectOperator = (e) => {
    const id = e.target.value;
    setSelectedValues({
      ...selectedValues,
      operatorId: +id
    })
  }

  const handleServiceSelect = (e, service) => {
    const isChecked = e.target.checked;

    const updatedServices = isChecked 
      ? selectedValues.services.concat(service)
      : selectedValues.services.filter(({ name }) => name !== service.name);
    
    setSelectedValues({
      ...selectedValues,
      services: updatedServices,
    });
  };

  const handleClearAllServices = () => {
    setSelectedValues({
      ...selectedValues,
      services: [],
    });
  };

  const handleSubmit = () => {
    const {
      type,
      duration,
      rate,
      time,
      discount,
      date,
      dayPart,
      services
    } = selectedValues;

    const [hours, minutes] = time.split(':').map(str => +str);
    let hours24 = hours;

    if (dayPart && dayPart === "PM") {
      if (hours24 !== 12) {
        hours24 += 12;
      }
    }

    if (dayPart && dayPart === "AM") {
      if (hours24 === 12) {
        hours24 = 0;
      }
    }

    const newSessionId = 0;

    const config = {
      sessionId: !!booking
        ? booking.session_id
        : props.isChat ? newSessionId : props.activeSession,

      profileId: profile.id,
      // date: new Date(new Date(date.setHours(hours)).setMinutes(minutes)).setSeconds(0),
      date: isFutureBooking
        ? null
        : new Date(date).setHours(hours24, minutes, 0, 0) - (userTimezone - (new Date().getTimezoneOffset() * (-1))) * 60000,
      type,
      duration,
      rate,
      contact_uid: profile && profile.contact_uid,
      discount: isSelectedDiscountRate
        ? discount
        : null,
      requirements: requirements.filter(({ isChecked }) => isChecked).map(({ id }) => id),
      caller_id: props.activeClientChatId || aSessionClient?.id || booking?.callerId,
      operatorId: isChangedUser ? selectedValues.operatorId : null,
      services,
    }

    if (booking) { // Edit Booking
      config['bookingId'] = booking.id;
      config['isSuccess'] = selectedValues.isSuccess;
      config['reason'] = selectedValues.cancel_reason;
      config['description'] = selectedValues.description;

      return API.updateBooking(config)
        .then(res => {
          props.handleCancel();
          props.onSubmit && props.onSubmit(res.data);
          
          setCurrentCancelReason(!!selectedValues.cancel_reason ? selectedValues.cancel_reason : '');
          setCancelDescription(!!selectedValues.description ? selectedValues.description : '');
          isOpenServices && toggleOpenServices();
        })
        .catch(err => {
          console.dir(err);

          if (err.response.data.code === 403) {
            props.openModal(MODAL_TYPES.bookingExistWarning, {
              userHour12,
              userTimezone,
              message: JSON.parse(err.response.data.message),
            });
          }
        })
    }
    else { // Create Booking
      return API.createBooking(config)
        .then(({ data }) => {
          if (!props.isChat) {
            props.updateBufferedId(profile.id, props.activeSession, props.canIEditSession, profile.id_new, profile.contact_uid)
              .then(() => props.showAnimation && props.showAnimation('add-to-buffer'));
          }

          for (let i = newScheduledMsgsList.length - 1; i >= 0; i--) {
            const { date, body, caller } = newScheduledMsgsList[i];
            API.createScheduledMsg({ date, contactId: caller.id, body, bookingId: data.id });
          }

          if (selectedRequest.text) {
            API.addBookingRequest({ ...selectedRequest, booking_id: data.id });
          }
         
          props.handleClose()
        })
        .catch(err => {
          console.dir(err);

          if (err.response.data.code === 403) {
            props.openModal(MODAL_TYPES.bookingExistWarning, {
              userHour12,
              userTimezone,
              message: JSON.parse(err.response.data.message),
            });
          }
        });
    }
  }

  const handleDelete = () => {
    if (window.confirm('Delete Booking?')) {
      setMount(false);

      setTimeout(() => {
        API.deleteBooking(booking.id)
          .then(() => {
            props.handleClose && props.handleClose();
            props.onBookingDeleted && props.onBookingDeleted(booking.id);
          })
          .catch(console.log);
      }, 500);
    }
  }

  const handleCancel = () => {
    if (booking) {
      props.handleCancel();
    }
    else {
      props.handleClose();
    }
    isOpenServices && toggleOpenServices();
  }

  const onTextareaCancelChange = (e) => {
    const value = e.target.value;

    setCancelDescription(value);
  };

  const saveUnsuccessBookingInfo = () => {
    setSelectedValues(prevState => ({
      ...prevState,
      isSuccess: false,
      cancel_reason: currentCancelReason,
      description: cancelDescription,
    }));

    toggleBtnSuccess(false);
  };

  const selectTime = (time) => {
    setSelectedValues(prevState => ({
      ...prevState,
      time: time.type === 'hours'
        ? time.name + ':' + prevState.time.split(':')[1]
        : prevState.time.split(':')[0] + ':' + time.name,
    }));
  };

  const selectDayPart = dayPart => {
    setSelectedValues(prevState => ({
      ...prevState,
      dayPart: dayPart.value
    }));
  };

  const isDiscount = () => {
    return (isMember && !Number.isNaN(Number.parseInt(selectedValues.discount)));
  };

  // when a new scheduled message block is open, removed css transition
  const onNewScheduledMsg = () => {
    if (isNewScheduledMsg) {
      setIsTransitionUnsetClass(false);
    }
    else {
      setTimeout(() => {
        setIsTransitionUnsetClass(true);
      }, 0);
    }
    setIsNewScheduledMsg(!isNewScheduledMsg);
  };

  const getAutoPositionStyles = () => {
    if (!bookedEditorRef?.current || !bookingListItemRowRef?.current) return;

    const bookingItemCoords = bookingListItemRowRef?.current?.getBoundingClientRect();
    const bookedEditorCoords = bookedEditorRef?.current?.getBoundingClientRect();

    const clientHeight = document.documentElement.clientHeight;

    let top = 0;
    let left = 0;
    const marginY = 5;
    const marginX = 15;

    if (bookingItemCoords.x + bookingItemCoords.width - bookedEditorCoords.width > marginX) { 
      left = bookingItemCoords.x + bookingItemCoords.width - bookedEditorCoords.width;
    }
    else {
      left = marginX;
    }

    if (bookingItemCoords.y + bookingItemCoords.height + bookedEditorCoords.height + marginY < clientHeight) {
      top = bookingItemCoords.y + bookingItemCoords.height + marginY;
    }
    else {
      top = bookingItemCoords.y - bookedEditorCoords.height - marginY;
    }

    return { top, left };
  }

  const defaultSelectedScheduledMsg = useMemo(() => ({
    time: getDefaultTime(userTimezone, userHour12),
    dayPart: getDefaultDayPart(userTimezone, userHour12),
    date: getDateByTimezoneOffset(userTimezone),
    caller: contactsList[0],
    body: '',
  }), [userTimezone, userHour12])

  // Flags & styles to indicate when values changed

  const isChangedProfile = props.profile?.id !== profile.id;

  const initIsFutureBooking = booking ? !booking.date : false;
  const isChangedFutureBooking = initIsFutureBooking !== isFutureBooking;
  const isApplyWhenAvailableMod = isChangedFutureBooking && isFutureBooking;

  const initDateTime = getDateByTimezoneOffset(userTimezone, booking?.date);
  if (!booking) {
    initDateTime.setMinutes(roundTo5Minutes(initDateTime.getMinutes()));
  }

  const isChangedDate = isFutureBooking
    ? false
    : initDateTime.toLocaleDateString() !== dateString;
  const isApplyDateTitleMod = isChangedDate || isChangedFutureBooking;

  const initHours = userHour12
    ? convertHoursTo12h(initDateTime.getHours())
    : initDateTime.getHours();
  const currHours = Number(selectedValues.time.split(':')[0]);
  const isChangedHours = isFutureBooking ? false : initHours !== currHours;

  const currMinutes = Number(selectedValues.time.split(':')[1]);
  const isChangedMinutes = isFutureBooking
    ? false
    : initDateTime.getMinutes() !== currMinutes;

  const initDayPart = initDateTime.getHours() < 12 ? 'AM' : 'PM';
  const isChangedDayPart = isFutureBooking || !userHour12
    ? false
    : initDayPart !== selectedValues.dayPart;

  const isChangedTime = isChangedHours || isChangedMinutes || isChangedDayPart;
  const isApplyTimeTitleMod = isChangedTime || isChangedFutureBooking;

  const initType = booking?.type || "select";
  const isChangedType = initType !== selectedValues.type;

  const getFirstDuration = () => props.profile?.prices?.find?.(({incall}) => incall)?.name;
  const initDuration = booking?.duration || getFirstDuration() || '';
  const isChangedDuration = initDuration !== selectedValues.duration;

  const initSuccess = booking ? !!booking.is_success : true;
  const isChangedSuccess = booking ? initSuccess !== selectedValues.isSuccess : false;

  const getIsChangedRequirements = () => {
    return requirements.some(({ id, isChecked }) => {
      const initIsChecked = !!initRequirements.find(init => init.id === id)?.isChecked;
      return initIsChecked !== isChecked;
    })
  }
  const isChangedRequirements = getIsChangedRequirements();

  const isChangedServices = bookingServices && bookingServices.length !== selectedValues.services.length || 
    bookingServices?.some(el => !selectedValues.services?.some(el2 => el.name === el2.name));

  const isChangedAnyData = isChangedServices || isChangedProfile || isChangedUser || isChangedFutureBooking ||
    isChangedDate || isChangedTime || isChangedType || isChangedDuration || isChangedSuccess || isChangedRequirements;

  const classStyles = [
    isEditingMode && "editing",
    props.isChat && 'chat',
    props.isModal && 'modal',
    props.isParsedGirlsListModal && 'parsed-girl-list',
  ];

  const mainStyles = (bookingListItemRowRef && isAutoPosition) ? getAutoPositionStyles() : styles;

  useEffect(() => {
    if (toggleConfirmOnClose) {
      toggleConfirmOnClose(isChangedAnyData);
    }
  }, [isChangedAnyData]);

  // Reset values to init when switching to read only mode
  useDidUpdate(() => {
    if (isEditingMode) {
      return;
    }
    setIsFutureBooking(initIsFutureBooking);
    setRequirements(initRequirements);
  }, [isEditingMode]);

  const isDisabledSaveBtn = isBtnSuccess !== selectedValues.isSuccess || selectedValues.type === 'select' || 
    (!isChangedAnyData && booking);

  if (isEmptyObj(profile)) {
    return (
      <div
        className={classModifier('booked-editor', classStyles)}
        style={mainStyles}
        ref={bookedEditorRef}
      >
        <div className="booked-editor__pending-wrap">
          <Spinner spinnerSize={44} />
        </div>
      </div>
    )
  }

  return (
    // <DelayedComponent
    //   isMount={isMount}
    //   delayUnmountTime={500}
    // >
      <div
        className={classModifier('booked-editor', classStyles)}
        style={mainStyles}
        ref={bookedEditorRef}
      >
        <div className="booked-editor__fields">
          <div className="booked-editor__field booked-editor__avatar-img-wrap">
            <LazyLoadImage
              src={DIVA_GIRLS_IMG_HOST + profile.avatar}
              spinnerSize={30}
            />
          </div>

          <div className="booked-editor__field booked-editor__field--name">
            <div className={classModifier("booked-editor__field-title", [isChangedProfile && 'changed'])}>
              Name
            </div>

            <span className={classModifier("booked-editor__field-text", [isChangedProfile && 'changed'])}>
              {profile.name}
            </span>

            {visible && 
              <div 
                className="z-index-popup"
                ref={setTooltipRef} 
                {...getTooltipProps()}
              >
                <ProfilesListForEdit 
                  isShowCloseBtn
                  id={profile.id} 
                  onChangeGirl={onChangeGirl} 
                  toggleChangeGirlMode={toggleChangeGirlMode}
                />
              </div>
            }

            {isEditingMode && <>
              <button
                className='booking-mini__btn'
                ref={setTriggerRef}
                data-is-btn
                type="button"
              >
                <SvgIcon
                  title="edit"
                  width='16px'
                  height='16px'
                  icon='edit'
                  fill='#0092f2'
                />
              </button>

              <select 
                value={selectedValues.operatorId} 
                onChange={handleSelectOperator} 
                className={classModifier("booked-editor__user-select", [isChangedUser && 'changed'])}
                >
                  <option value={props.userData.id}>{props.userData.username}</option>
                  {props.operatorsList
                    .map(operator => {
                      const { id, username } = operator;
                      return <option key={id} value={id}>{username}</option>
                  })}
              </select>
            </>}

            <RequirementsButton
              onClick={() => setIsShowRequirements(state => !state)}
              requirements={requirements}
              highlighted={isChangedRequirements}
            />
          </div>

          <div className="booked-editor__field-container">

            <div className="booked-editor__field-container--row booked-editor__field-container--date-and-time">

              {booking && !booking.date && !isEditingMode
                ? <div className="booked-editor__field">
                  <div className="booked-editor__field-title">
                    Date
                  </div>

                  <div className="booked-editor__field-value">
                    When she is available
                  </div>
                </div>
                : <>
                  {isEditingMode &&
                    <label className="booked-editor__radio booked-editor__radio--available">
                      <input
                        type="radio"
                        checked={isFutureBooking ? '' : 'checked'}
                        onChange={() => setIsFutureBooking(false)}
                      />
                    </label>
                  }

                  <div className="booked-editor__field">
                    <div className={classModifier("booked-editor__field-title", [isApplyDateTitleMod && "changed"])}>
                      Date
                    </div>

                    {isEditingMode &&
                      <button
                        className="booked-editor__btn booked-editor__btn--primary"
                        onClick={toggleCalendarOpen}
                        disabled={isFutureBooking}
                      >
                        Select
                      </button>
                    }

                    <span className={classModifier('booked-editor__date-string', [isChangedDate && 'changed'])}>
                      {dateString}
                    </span>

                    {isCalendarOpen &&
                      <div ref={calendarRef}>
                        <DropWrapper
                          closeDropWrapper={toggleCalendarOpen}
                          dropWrapperRef={calendarRef}
                        >
                          <Calendar
                            onChange={selectDate}
                            date={selectedValues.date}
                            autoposition
                          />
                        </DropWrapper>
                      </div>
                    }
                  </div>

                  <div className="booked-editor__field booked-editor__field--time">
                    <div className={classModifier("booked-editor__field-title", [isApplyTimeTitleMod && "changed"])}>
                      Time
                    </div>

                    <div className="booked-editor__time-selectors">
                      <Select
                        isScrollToValue
                        classPrefix={isEditingMode ? 'booked-editor' : 'booked-editor-disabled'}
                        options={timeOptions.hours}
                        getOptionValue={option => option.name}
                        defaultValue={selectedValues.time.split(':')[0]}
                        onInputChange={selectTime}
                        isDisabled={!isEditingMode || isFutureBooking}
                        classMods={[isChangedHours && 'changed']}
                        listAutoposition
                      />
                      <div className={classModifier('booked-editor__time-separator', [!isEditingMode && 'disabled'])}>
                        :
                      </div>
                      <Select
                        classPrefix={isEditingMode ? 'booked-editor' : 'booked-editor-disabled'}
                        options={timeOptions.minutes}
                        getOptionValue={option => option.name}
                        defaultValue={selectedValues.time.split(':')[1]}
                        onInputChange={selectTime}
                        isDisabled={!isEditingMode || isFutureBooking}
                        classMods={[isChangedMinutes && 'changed']}
                        listAutoposition
                      />
                      {hour12 &&
                        <>
                          <div className={classModifier('booked-editor__time-separator', [!isEditingMode && 'disabled'])}></div>
                          <Select
                            classPrefix={isEditingMode ? 'booked-editor' : 'booked-editor-disabled'}
                            options={timeOptions.dayPart}
                            getOptionValue={option => option.name}
                            defaultValue={selectedValues.dayPart}
                            onInputChange={selectDayPart}
                            isDisabled={!isEditingMode || isFutureBooking}
                            classMods={[isChangedDayPart && 'changed']}
                            listAutoposition
                          />
                        </>
                      }
                    </div>
                  </div>
                </>
              }
            </div>

            {isEditingMode &&
              <div className="booked-editor__field-container--row">

                <label className={classModifier("booked-editor__radio", ["not-available", isApplyWhenAvailableMod && "changed"])}>
                  <input
                    type="radio"
                    checked={isFutureBooking ? 'checked' : ''}
                    onChange={() => setIsFutureBooking(true)}
                  />
                  <span>When she is available</span>
                </label>
              </div>
            }
          </div>

          <div className="booked-editor__field booked-editor__field--type">
            <div className={classModifier("booked-editor__field-title", [isChangedType && "changed"])}>Type</div>

            <Select
              classPrefix={isEditingMode ? 'booked-editor' : 'booked-editor-disabled'}
              options={bookingTypes}
              getOptionValue={option => option.name}
              defaultValue={selectedValues.type}
              onInputChange={selectTypeOrDuration}
              isDisabled={!isEditingMode}
              classMods={[isChangedType && 'changed']}
              listAutoposition
            />

            {isEditingMode && selectedValues.type === 'select' &&
              <span className="booked-editor__field--type-error">
                Please choose type
              </span>
            }
          </div>

          <div className="booked-editor__field booked-editor__field--duration">
            <div className={classModifier("booked-editor__field-title", [isChangedDuration && "changed"])}>
              Duration
            </div>

            <Select
              classPrefix={isEditingMode ? 'booked-editor' : 'booked-editor-disabled'}
              options={prices}
              getOptionValue={option => option.name}
              defaultValue={selectedValues.duration}
              onInputChange={selectTypeOrDuration}
              isDisabled={!isEditingMode}
              classMods={[isChangedDuration && 'changed']}
              listAutoposition
            />
          </div>

          {!!booking &&
            <div className="booked-editor__field booked-editor__field--booking-success">
              <div className={classModifier("booked-editor__field-title", [isChangedSuccess && "changed"])}>
                Success
              </div>

              <Toggle
                isActive={selectedValues.isSuccess}
                onToggle={() => toggleBtnSuccess(!isBtnSuccess)}
                isDisabled={!isEditingMode || isBtnSuccess !== selectedValues.isSuccess}
              />

              {!!selectedValues.cancel_reason &&
                <span className="booked-editor__field--cancel-reason">
                  {selectedValues.cancel_reason}
                </span>
              }
            </div>
          }

          <div className="booked-editor__field booked-editor__field--rate">
            <div className="booked-editor__field-title">Rate</div>
            {isDiscount()
              ? <div>

                <label className="booked-editor__price booked-editor__price--old">
                  <input
                    disabled={isEditingMode ? '' : 'disabled'}
                    type="radio"
                    checked={isSelectedDiscountRate ? '' : 'checked'}
                    name={'rate' + profile.id}
                    onChange={() => setIsSelectedDiscountRate(false)}
                  /> {selectedValues.rate}£
                </label>
                <label className="booked-editor__price booked-editor__price--discount">
                  <input
                    disabled={isEditingMode ? '' : 'disabled'}
                    type="radio"
                    checked={isSelectedDiscountRate ? 'checked' : ''}
                    name={'rate' + profile.id}
                    onChange={() => setIsSelectedDiscountRate(true)}
                  /> {selectedValues.discount}£
                </label>
              </div>
              : <>{selectedValues.rate}{!Number.isNaN(Number.parseInt(selectedValues.rate)) && '£'}</>
            }
          </div>

          <div className="booked-editor__additional-actions">
            {booking && 
              <button
                className="booked-editor__btn booked-editor__btn--danger"
                onClick={handleDelete}>
                Delete
              </button>
            }

            {!isEditingMode &&
              <button
                className="booked-editor__btn booked-editor__btn--primary"
                onClick={() => props.changeEditMode(profile.id)}>
                Edit
              </button>
            }

            {isEditingMode &&
              <AsyncButton
                className="booked-editor__btn booked-editor__btn--submit"
                onClick={handleSubmit}
                spinnerSize={12}
                disabled={isDisabledSaveBtn}
              >
                Save
              </AsyncButton>
            }

            {isEditingMode && isChangedAnyData &&
            <button
              className="booked-editor__btn booked-editor__btn--danger"
              disabled={isBtnSuccess !== selectedValues.isSuccess}
              onClick={() => {
                handleCancel();
                toggleBtnSuccess(!!booking && !!booking.is_success)
              }}
            >
              Cancel
            </button>
            }

            <div className="booked-editor__additional-btns">
              <button
                className="booked-editor__btn booked-editor__btn--orange"
                onClick={() => toggleOpenServices()}
                disabled={!bookingServices || !profile.services?.length || !isEditingMode}
              >
                Services
              </button>
              <button
                className="booked-editor__btn booked-editor__btn--primary"
                onClick={() => toggleOpenNewRequest()}
                disabled={!!selectedRequest.text || (booking && booking.requests.length)}
              >
                Request
              </button>
              <button
                className="booked-editor__btn booked-editor__btn--orange"
                onClick={onNewScheduledMsg} >
                New Scheduled msg
              </button>
            </div>
          </div>
          {isEditingMode && !isBtnSuccess && isBtnSuccess !== selectedValues.isSuccess &&
            <div className="booked-editor__cancel-container">
              <div className="booked-editor__cancel-reasons">
                <span className="booked-editor__cancel-reasons--title">
                  Choose the reason:
                </span>

                {CANCEL_BOOKING_REASONS.map((reason, idx) => (
                  <label
                    className="booked-editor__cancel-reasons--label booked-editor__radio"
                    key={idx}
                  >
                    <input
                      type="radio"
                      checked={reason === selectedValues.cancel_reason || reason === currentCancelReason}
                      onChange={() => setCurrentCancelReason(reason)}
                    />
                    <span>{reason}</span>
                  </label>
                ))}
              </div>
              <div className="booked-editor__cancel-actions">
                <div className="booked-editor__cancel-actions--descr">
                  <textarea
                    placeholder="description"
                    value={cancelDescription}
                    onChange={onTextareaCancelChange}
                  />
                </div>
                <div className="booked-editor__cancel-actions--btns">
                  <button
                    className="booked-editor__btn booked-editor__btn--submit"
                    onClick={saveUnsuccessBookingInfo}
                    disabled={!currentCancelReason}
                  >
                    Save
                  </button>

                  <button
                    className="booked-editor__btn booked-editor__btn--danger"
                    onClick={() => {
                      toggleBtnSuccess(true);
                      setCurrentCancelReason(booking.reason ? booking.reason : '');
                      setCancelDescription(booking.description ? booking.description : '');
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          }
        </div>

        {isShowRequirements &&
          <RequirementsCard
            requirements={requirements}
            setRequirements={setRequirements}
            initRequirements={initRequirements}
            disableChanges={!isEditingMode}
          />
        }

        {isOpenServices && 
          <ServicesList
            selectedServices={selectedValues.services}
            rate={selectedValues.rate}
            profileServices={profile.services}
            bookingServices={bookingServices}
            onClearAllServices={handleClearAllServices}
            onServiceSelect={handleServiceSelect}
          />
        }

        {isOpenNewRequest && !(booking && booking.requests.length) && !selectedRequest.text &&
          // <div className={classModifier("booked-editor__request", ['new', isOpenNewRequest && 'open'])}>
          <div className="booked-editor__request">
            <div className="booked-editor__fields" >
              <RequestItem
                selectedRequest={selectedRequest}
                setSelectedRequest={setSelectedRequest}
                booking_id={booking && booking.id}
                isNewRequest
                onSubmit={props.onSubmit}
              />
            </div>
          </div>
        }

        {!!selectedRequest.text &&
          <div className="booked-editor__request">
            <div className="booked-editor__fields" >
              <RequestItem
                selectedRequest={selectedRequest}
                setSelectedRequest={setSelectedRequest}
                booking_id={booking && booking.id}
              />
            </div>
          </div>
        }
        {booking && !!booking.requests.length &&
          <div className="booked-editor__request">
            <div className="booked-editor__fields" >
              {booking.requests.map(request =>
                <RequestItem
                  key='request'
                  selectedRequest={request}
                  setSelectedRequest={setSelectedRequest}
                  booking_id={booking && booking.id}
                  onSubmit={props.onSubmit}
                />
              )}
            </div>
          </div>
        }

        <div
          className={classModifier("booked-editor__new-scheduled-msg",
            [isNewScheduledMsg && 'open',
            isOverflowUnsetClass && 'overflow-unset',
            isTransitionUnsetClass && 'transition-unset']
          )}
          style={{
            height: isNewScheduledMsg ? scheduledMsgBlockHeight + 50 + 'px' : 0,
          }}>

          <div className="booked-editor__fields">
            <ScheduledMsgItem
              isNewScheduledMsg
              setScheduledMsgBlockHeight={setScheduledMsgBlockHeight}
              selectedScheduledMsg={defaultSelectedScheduledMsg}
              isContactPending={isContactPending}
              bookingId={booking && booking.id}
              setNewScheduledMsgsList={setNewScheduledMsgsList}
              contactsList={contactsList}
              timeOptions={timeOptions}
              hour12={userHour12}
              userTimezone={userTimezone}
              isEditableScheduledMsg
            />
          </div>

        </div>
        {newScheduledMsgsList.map(scheduledMsg => (
          <div className="booked-editor__fields scheduled-msg" key={scheduledMsg.id}>
            <ScheduledMsgItem
              selectedScheduledMsg={scheduledMsg}
              isContactPending={isContactPending}
              contactsList={contactsList}
              setNewScheduledMsgsList={setNewScheduledMsgsList}
              timeOptions={timeOptions}
              hour12={userHour12}
              userTimezone={userTimezone}
              setEditableScheduledMsgId={setEditableScheduledMsgId}
              isEditableScheduledMsg={editableScheduledMsgId === scheduledMsg.id}
            />
          </div>
        ))}
        {!!booking && booking.scheduleMessages.map(scheduledMsg => (
          <div className="booked-editor__fields scheduled-msg" key={scheduledMsg.id}>
            <ScheduledMsgItem
              selectedScheduledMsg={scheduledMsg}
              isContactPending={isContactPending}
              bookingId={booking.id}
              contactsList={contactsList}
              timeOptions={timeOptions}
              hour12={userHour12}
              userTimezone={userTimezone}
              setEditableScheduledMsgId={setEditableScheduledMsgId}
              isEditableScheduledMsg={editableScheduledMsgId === scheduledMsg.id}
            />
          </div>
        ))}
      </div>
    // </DelayedComponent>
  );
};

const mapStateToProps = (state, ownProps) => ({
  profile: state.divaGirls.entities[ownProps.profileId],
  activeSession: state.sessions.activeSession,
  booking: ownProps.isFromHistory
    ? ownProps.booking
    : state.sessions.entities[state.sessions.activeSession].bookings[ownProps.profileId],
  userTimezone: selectUserTimezone(state),
  isMember: ownProps.isFromHistory
    ? ownProps.isMember
    : selectIsContactMember(state),
  isPartners: selectIsPartnersSession(state),
  userHour12: state.user.hour12,
  userData: state.user,
  aSessionClient: selectContactBySessionId(state, state.sessions.activeSession),
  canIEditSession: selectCanIEditSession(state),
  operatorsList: Object.values(state.operators.entities),
  activeClientChatId: ownProps.isChat && state.clientChats.active,
  sessions: Object.values(state.sessions.entities),
  allRequirements: selectAllRequirements(state)
});

const mapDispatchToProps = {
  openModal,
  createSession,
  getDivaGirls,
  updateBooking,
  updateBufferedId,
  removeBooking,
};

export default connect(mapStateToProps, mapDispatchToProps)(BookedEditor);