import React from 'react';


const ScrollToBottom = ({ unreadCount, ...props }) => (
  <button {...props}>
    &#8681;
    {!!unreadCount &&
      <span className="timeline__unread-count">
        {unreadCount}
      </span>
    }
  </button>
);

export default ScrollToBottom;