import React, { useMemo, useCallback, useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { CONTACTS_LIMIT } from '../../../../ducks/contacts';
import {
  ALL_FILTER,
  ONLINE_FILTER,
  ALL_OPERATORS_FILTER,
  ALL_ROOMS_FILTER,
  filterRooms,
  updateRoomsList,
  getAllChatRooms,
  getUnreadChats,
  SHOW_UNREAD_ROOMS,
  DEFAULT_FILTER,
  updateSearchRooms,
  stopRoomsSearch,
  searchRooms,
} from '../../../../ducks/rooms';
import { selectRoomsList, selectOnlineOperatorsIds } from '../../../../selectors/selectors';
import { LS } from '../../../../utils';
import { usePrevious, useDidMount, useDidUpdate, useCancelToken } from '../../../../hooks';

import ChatList from '../ChatList/ChatList';
import RoomItem from '../ChatList/RoomItem';
import ChatListSearchFilterZone from '../ChatListSearchFilterZone/ChatListSearchFilterZone';
import RoomTabsMenu from './RoomTabsMenu';

const RoomTabs = ({ unreadCount, ...props }) => {
  const {
    onlineOperatorsCount,
    activeFilter,
    searchRoomsQuery,
  } = props;

  const [animationClass, setAnimationClass] = useState('');

  const prevUnreadCount = usePrevious(unreadCount)

  // To cancel axios http request
  const { newCancelToken, cancelPrevRequest } = useCancelToken();

  useDidMount(() => {
    if (searchRoomsQuery) {
      props.searchRooms(searchRoomsQuery, newCancelToken());
    }

    if (activeFilter === DEFAULT_FILTER) {
      props.getAllChatRooms(DEFAULT_FILTER);
    }
  }, []);

  useDidUpdate(() => {
    LS.setItem('roomsSearch', searchRoomsQuery);
  }, [searchRoomsQuery])

  useEffect(() => {
    if (prevUnreadCount !== unreadCount && unreadCount) {
      setAnimationClass('');
    }

    if (unreadCount === 0 && prevUnreadCount) {
      setAnimationClass('');
    }
  }, [unreadCount])


  const updateRoomsList = useCallback(() => {
    if (searchRoomsQuery) {
      return props.updateSearchRooms;
    }
    if (activeFilter === ALL_ROOMS_FILTER) {
      return props.getAllChatRooms;
    }
    if (activeFilter === DEFAULT_FILTER) {
      return updateRoomsListIfDefaultFilter;
    }

    return props.updateRoomsList;
  }, [activeFilter, searchRoomsQuery, onlineOperatorsCount]);

  const updateRoomsListIfDefaultFilter = useCallback((type, offset) =>
    props.getAllChatRooms(DEFAULT_FILTER, offset - onlineOperatorsCount),
    [onlineOperatorsCount]
  );

  const filters = useMemo(() => [
    { name: DEFAULT_FILTER, action: () => props.getAllChatRooms(DEFAULT_FILTER) },
    { name: ALL_FILTER, action: () => props.filterRooms(ALL_FILTER) },
    { name: ONLINE_FILTER, action: () => props.filterRooms(ONLINE_FILTER) },
    { name: ALL_OPERATORS_FILTER, action: () => props.filterRooms(ALL_OPERATORS_FILTER) },
    { name: SHOW_UNREAD_ROOMS, action: () => props.getUnreadChats(SHOW_UNREAD_ROOMS) },
    { name: ALL_ROOMS_FILTER, action: () => props.getAllChatRooms(ALL_ROOMS_FILTER) },
  ], []);

  const stopSearch = useCallback(() => {
    cancelPrevRequest();
    props.stopRoomsSearch();
  }, []);

  const startSearch = useCallback((query) => {
    cancelPrevRequest();
    return props.searchRooms(query, newCancelToken());
  }, []);

  return (
    <Tabs>
      <div className="react-tabs__tab-list-container">
        <RoomTabsMenu />

        <TabList>
          <Tab
            data-animationclass={animationClass}
            className="react-tabs__tab react-tabs__tab--long"
          >
            Operators chats ({props.roomsTotalCount})

            {unreadCount
              ? <span className="react-tabs__unread">{unreadCount > 99 ? '99+' : unreadCount}</span>
              : null
            }
          </Tab>
        </TabList>
      </div>

      <ChatListSearchFilterZone
        initialQuery={searchRoomsQuery}
        activeTab={null}
        startSearch={startSearch}
        stopSearch={stopSearch}
        activeFilter={activeFilter}
        defaultFilter={DEFAULT_FILTER}
        filters={filters}
        showClearSearchBtn={true}
      />

      <TabPanel>
        <ChatList
          list={props.rooms}
          pending={props.roomsPending}
          search={searchRoomsQuery}
          filter={activeFilter}
          limit={CONTACTS_LIMIT}
          updateList={updateRoomsList()}

          chatListItem={RoomItem}
        />
      </TabPanel>

    </Tabs>
  );
};

const mapStateToProps = state => ({
  rooms: selectRoomsList(state),
  roomsPending: state.rooms.pending,
  activeFilter: state.rooms.activeFilter,
  searchRoomsQuery: state.rooms.search,
  roomsTotalCount: state.rooms.totalCount,
  unreadCount: state.rooms.unreadCount,
  onlineOperatorsCount: selectOnlineOperatorsIds(state).length,
});

const mapDispatchToProps = {
  filterRooms,
  updateRoomsList,
  stopRoomsSearch,
  searchRooms,
  updateSearchRooms,
  getAllChatRooms,
  getUnreadChats,
};

export default connect(mapStateToProps, mapDispatchToProps)(RoomTabs);