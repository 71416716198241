import React, { memo } from "react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { getContactAvatar } from "../../utils";
import { useToggle } from "../../hooks";

import LazyLoadImage from "../LazyLoadImage/LazyLoadImage";


const SearchContactItem = props => {
  const {
    selectedContact,
    item: contact = {},
    classPrefix,
    closeModal,
    sessionMode,
    onClickAction,
  } = props;

  const [isHover, setIsHover] = useToggle(false);

  if (props.item === 1) return null; // If it is Saved Messages

  const getClassName = id => selectedContact && selectedContact.id === id
    ? `${classPrefix}__item session-operators__item--active`
    : `${classPrefix}__item`;

  const selectContact = () => {
    if (sessionMode && selectedContact) {
      if (selectedContact.id === contact.id) {
        return onClickAction(null);
      }
    }

    onClickAction(contact);
    closeModal && closeModal();
  };

  return (
    <li
      className={getClassName(contact.id)}
      onClick={!contact.withoutNumber ? selectContact : undefined}
      data-disabled={contact.withoutNumber}
      // onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover()}
      onMouseOver={() => { if (!isHover) setIsHover() }}
    >
      <div className={'contact-item__img-wrap'}>
        <LazyLoadImage src={getContactAvatar(contact)} alt="ava" className={classPrefix + "__img"} />
      </div>

      <div className={classPrefix + "__name"} >
        <span>{contact.fn}</span>
        <span>{contact.tels && contact.tels[0] && contact.tels[0].tel}</span>
      </div>

      {isHover && !contact.withoutNumber &&
        <FontAwesomeIcon
          className="plus-icon"
          icon={faPlus}
          color="#fff"
          style={{ backgroundColor: "#0092F2", borderRadius: '50%', padding: 3, height: 20, width: 20 }}
        />
      }
    </li>
  );
};

export default memo(SearchContactItem);