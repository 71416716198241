import React, { useMemo, useRef } from 'react';
import { connect } from 'react-redux';

import { updateNotificationsVolume } from '../../../../../ducks/settings';
import { debounce } from '../../../../../utils';

import './NotificationsVolumeEditor.scss';
import Slider from '../../../../../components/AudioPlayer/Slider';


const NotificationsVolumeEditor = props => {
  const {
    userId,
    updateNotificationsVolume,
    volume,
    type,
  } = props;

  const userVolumeRef = useRef(volume);

  const onUpdate = (volume) => {
    const updatedVolume = Math.floor(volume);

    if (userVolumeRef.current !== updatedVolume) {
      updateNotificationsVolume(updatedVolume, type, userId);
      userVolumeRef.current = updatedVolume;
    }
  };

  const title = useMemo(() => {
    switch (type) {
      case 'call':
        return 'Call notifications volume';
      case 'sms' :
        return 'Sms notifications volume';
      case 'booking':
        return 'Booking notifications volume';
    }
  }, [type]);

  return (
    <div>
      <h3 className='notifications-volume__title'>{title}</h3>

      <Slider
        onUpdate={debounce(onUpdate, 300)}
        countOfSteps={100}
        currentStep={userVolumeRef.current}
      />
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  userId: state.user.id,
  volume: state.settings.volume[ownProps.type],
});

const mapDispatchToProps = {
  updateNotificationsVolume,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsVolumeEditor);