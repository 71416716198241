import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import './FullScreenCallItem.scss';
import {
  putCallOnHold,
  createTransferedCall,
} from '../../../ducks/calls';
import { updateActiveContact } from '../../../ducks/clientChats';
import { updateActiveChat } from '../../../ducks/roomChats';

import CallItem from '../CallItem';
import SvgIcon from '../../SvgIcon/SvgIcon';
import { killConnection } from '../../../ducks/twilio';


const FullScreenCallItem = ({ activeCall, ...props }) => {
  const activeCallRef = useRef(activeCall);

  const hangUp = (e) => {
    e.preventDefault();

    props.closeModal();

    console.log('kill-----');
    killConnection(props.connection);
  };

  const onHold = () => {
    props.closeModal();

    props.putCallOnHold(activeCallRef.current);
  };

  const onTransfer = () => {
    props.closeModal();

    props.createTransferedCall(activeCallRef.current);
  };

  const getConnectedContact = () => {
    if (activeCallRef.current.caller) {
      return activeCallRef.current.caller;
    }
    else if (activeCallRef.current.senderId === props.profileId) {
      return activeCallRef.current.recipient;
    }
    else {
      return activeCallRef.current.sender;
    }
  };

  return (
    <div className="full-screen">

      <span
        className="full-screen__end"
        onClick={() => props.closeModal()}>
        Close(ESC)
      </span>

      <CallItem
        actions={activeCallRef.current.caller ? true : false}
        activeFullScreen
        caller={getConnectedContact()}
        call={activeCallRef.current} />

      <nav className="full-screen__buttons">

        <button
          onClick={onHold}
          className="full-screen__button">
          <svg
            version="1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="1 -28 512 511"
            fill='#F2CC0C'
            width="25"
            height="25">
            <path d="M193 0h-75c-17 0-31 14-31 31v458c0 17 14 30 31 30h75c17 0 31-13 31-30V31c0-17-14-31-31-31zM402 0h-76c-17 0-31 14-31 31v458c0 17 14 30 31 30h76c16 0 30-13 30-30V31c0-17-14-31-30-31z" />
          </svg>

          <span className="full-screen__button full-screen__button-name">Put on hold</span>
        </button>

        <button
          onClick={onTransfer}
          className="full-screen__button">
          <svg
            version="1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="1 -28 512 511"
            width="25"
            height="25"
            fill='#0092F2'>
            <path d="M235 270a733 733 0 0 1 35-71l14-17c6-6 13-10 20-13s16-5 25-5h73v55c0 3 1 5 3 7s4 2 6 2c3 0 5 0 7-2l91-92c2-1 3-3 3-6s-1-5-3-7l-91-91c-2-2-5-3-7-3-3 0-5 1-6 3-2 2-3 4-3 7v54h-73a137 137 0 0 0-67 17c-9 5-17 11-26 20a228 228 0 0 0-55 82 2620 2620 0 0 0-36 80l-13 22-14 18c-6 6-13 10-20 13s-16 4-25 4H9c-3 0-5 1-6 3-2 1-3 4-3 6v55c0 3 1 5 3 7l6 2h64a137 137 0 0 0 67-16c9-5 17-12 26-20a229 229 0 0 0 55-82l14-32z" />
            <path d="M9 164h64a77 77 0 0 1 41 14 189 189 0 0 1 37 55c15-34 28-60 39-77-30-43-69-65-117-65H9c-3 0-5 1-6 3-2 2-3 4-3 7v54c0 3 1 5 3 7 1 2 3 2 6 2zM418 286c-2-2-5-3-7-3-3 0-5 1-6 3l-3 6v55h-73a67 67 0 0 1-41-14c-5-4-10-9-15-16a187 187 0 0 1-22-39c-15 34-28 60-39 78a165 165 0 0 0 49 47l16 8 18 5a386 386 0 0 0 60 5 2640 2640 0 0 1 47-1v55c0 3 1 5 3 7l6 2 7-2 91-92c2-1 3-4 3-6 0-3-1-5-3-7l-91-91z" />
          </svg>

          <span className="full-screen__button full-screen__button-name">Transfer Call</span>
        </button>

        <button
          onClick={hangUp}
          className="full-screen__button">
          <SvgIcon icon="phone" height={25} width={25} fill='#F8737F' title="hang up" />

          <span className="full-screen__button full-screen__button-name">End Call</span>
        </button>
      </nav>

    </div >
  );
};

const mapStateToProps = (state) => ({
  activeCall: state.calls.activeCall,
  profileId: state.user.id,
  connection: state.twilio.connection,
});

const mapDispatchToProps = {
  putCallOnHold,
  createTransferedCall,
  updateActiveChat,
  updateActiveContact,
};

FullScreenCallItem.propTypes = {
  activeCall: PropTypes.object,
  profileId: PropTypes.number,
  connection: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(FullScreenCallItem);