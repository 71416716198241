import React from 'react';


const Tag = ({ tag, removeTag, contact, ...props }) => {
  const textColor = '#2d91f2';

  const deleteTag = (e) => {
    e.preventDefault();
    if (window.confirm('Remove tag: ' + tag.title + '?')) {
      return removeTag(contact, tag);
    }
  };

  const handleOnChange = (e) => {
    const isChecked = e.target.checked;

    props.setSelectedValues((prevState) => ({
      ...prevState,
      callerTags: isChecked
        ? [...prevState.callerTags, tag]
        : prevState.callerTags.filter(t => t.id !== tag.id)
    }))
  };

  return (
    <li className="tag" style={{ backgroundColor: tag.color, color: textColor }}>
      <label>
        <span className="tag__title">{tag.title}</span>
        {removeTag &&
          <>
            <button
              className="tags__btn tags__btn--del"
              type="button"
              onClick={deleteTag}>
              &#10005;
            </button>

          </>
        }
        {props.selectMode &&
          <span>
            <input
              type="checkbox"
              defaultChecked={props.isChecked}
              onChange={handleOnChange}
              name="tag-item"
              className="fake-contact-form__merge-indicator-fake" />

            <span className="fake-contact-form__merge-indicator--checkbox"></span>
          </span>
        }
      </label>
    </li>
  )
}

export default React.memo(Tag);