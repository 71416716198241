import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

import { CHAT_TYPES } from '../../../../../config/constants';
import { getDateByTimezoneOffset, getShortDate } from '../../../../../utils';

import './DateIndicator.scss';

const DateIndicator = ({ groupDate, userTimezone, ...props }) => {
  const showTimelineDate = () => {
    const d = getDateByTimezoneOffset(userTimezone);

    const interactionDate = getShortDate(groupDate);
    const todayByTimezone = getShortDate(getDateByTimezoneOffset(userTimezone));
    const yesterdayByTimezone = getShortDate(new Date(d.setDate(d.getDate() - 1)));

    const currentYear = d.getFullYear();
    const interactionYear = new Date(interactionDate).getFullYear();

    if (interactionDate === todayByTimezone) {
      return "Today";
    }
    else if (interactionDate === yesterdayByTimezone) {
      return "Yesterday";
    }
    else if (interactionYear !== currentYear) {
      return groupDate.toLocaleDateString('en-US', { year: 'numeric', day: 'numeric', month: 'long' });
    }
    else {
      return groupDate.toLocaleDateString('en-US', { weekday: 'long', day: 'numeric', month: 'long' });
    }
  }

  const setContextDateRef = () => {
    if (props.contextDate) {
      const contextDateByTimezone = getShortDate(getDateByTimezoneOffset(userTimezone, props.contextDate));

      const formattedGroupDate = getShortDate(groupDate);


      console.log('BBB', contextDateByTimezone);
      console.log('BBB1', formattedGroupDate);

      return contextDateByTimezone === formattedGroupDate
        ? props.contextDateRef
        : null;
    }

    return null;
  }

  return (
    <div className="date-indicator" ref={setContextDateRef()}>
      <button
        className="date-indicator__btn"
        onClick={() => props.showTimePickerForDateContext
          ? props.showTimePickerForDateContext()
          : null
        }
      >
        <span className="date-indicator__date">
          {showTimelineDate()}
        </span>

        {props.chatType !== CHAT_TYPES.ROOM &&
          <FontAwesomeIcon
            title="calendar"
            icon={faCalendarAlt}
            className="date-indicator__icon"
          />
        }
      </button>
    </div>
  )
}

export default DateIndicator;