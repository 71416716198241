import React from 'react';

import LazyLoadImage from '../LazyLoadImage/LazyLoadImage';
import './ModalImage.scss';


const ModalImage = ({ src, isRound }) => (
  <LazyLoadImage
    alt={'profile'}
    src={src}
    className={`modal-img ${isRound ? "modal-img--round" : ""}`}
  />
)


export default ModalImage;
