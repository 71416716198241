import React from 'react';
import ReactDOM from 'react-dom';

export const Portal = ({ isPortal, ...props }) => (
	isPortal
		? ReactDOM.createPortal(
			<>
				{props.children}
			</>,
			document.getElementById('dialogs-root')
		)
		: <>{props.children}</>
);