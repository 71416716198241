import { faQuoteRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { isMineInteraction, formatNotificationDate, getContactAvatar, getDateByTimezoneOffset } from '../../../../utils';

import MsgText from '../ChatTimeline/interactions/MsgText';


const AttachmentMessage = ({ interaction, profileId, ...props }) => {
  const sender = isMineInteraction(profileId, interaction.senderId)
    ? 'You'
    : interaction.sender.username;

  return (
    <div className={`${props.className ? props.className : ""} attachment-msg`}>
      <header className="attachment-msg__header">
        <FontAwesomeIcon icon={faQuoteRight} size="xs" color="#c1c1c1" />
        <div className="attachment-msg__ava">
          <img src={getContactAvatar(interaction.sender)} alt="ava" />
        </div>
        <div>
          <p className="attachment-msg__name">{sender}</p>
          <time className="attachment-msg__time">
            {formatNotificationDate(getDateByTimezoneOffset(props.userTimezone, interaction.dateCreated), props.userHour12)}
          </time>
        </div>
      </header>

      <div className="attachment-msg__content">
        <MsgText interaction={interaction} />
      </div>
    </div>
  );
}

export default AttachmentMessage;