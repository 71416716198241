const mobileAgents = /BlackBerry|webOS|iPhone|IEMobile|Android|Windows Phone|iPad|iPod|Opera Mini|Mobile/i;

const detectMobileDevices = () => {
  // If it possible, determine by presence of a touchscreen

  // if ("maxTouchPoints" in navigator) {
  //   return navigator.maxTouchPoints > 0;
  // }

  // if ("msMaxTouchPoints" in navigator) {
  //   return navigator.msMaxTouchPoints > 0;
  // }

  // Otherwise determine by accuracy of primary pointing device

  // const mql = window.matchMedia && matchMedia("(pointer: coarse)");

  // if (mql?.media === "(pointer: coarse)") {
  //   return mql.matches;
  // }

  // As fallback, check the deprecated property "orientation"

  // if ("orientation" in window) {
  //   return true;
  // }

  // Finally go to user agent sniffing

  const isMobileDevice = mobileAgents.test(navigator.userAgent);

  if (isMobileDevice) {
    console.log('user-agent:', navigator.userAgent);
  }

  return isMobileDevice;
}

export default detectMobileDevices;
