import React from 'react';

import SvgIcon from '../../SvgIcon/SvgIcon';
import './Title.scss';


const Title = ({ classPrefix, isShowIcon = true, ...props }) => {
  const classNamePrefix = classPrefix || 'title';

  return (
    <h2 className={classNamePrefix}>

      {isShowIcon &&
        <div className={classNamePrefix + "__icon-wrap"}>
          <SvgIcon
            type={props.iconType || "svg"}
            icon={props.icon}
            width="15px"
            color="#404040"
          />
        </div>
      }
      <span className={classNamePrefix + "__text"}>
        {props.text}
      </span>

      {!!props.count &&
        <span className={classNamePrefix + "__count"}>
          {"(" + props.count + ")"}
        </span>
      }
    </h2>
  )
};

export default React.memo(Title);