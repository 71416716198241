import React, { useState, useLayoutEffect, useRef, useEffect, memo } from 'react';
import { Scrollbars } from "react-custom-scrollbars";

import InteractionsList from './InteractionsList';
import './ChatTimeline.scss';

import { INTERACTION_TYPES } from '../../../../config/constants';
import { throttle, sumOfNestedArrayItems, debounce } from '../../../../utils';
import { useDidMount, usePrevious } from '../../../../hooks';

import PinnedMsg from './interactions/PinnedMsg';
import ScrollToBottom from './ScrollToBottom';
import Spinner from '../../../../components/UI/Spinner/Spinner';

const ChatTimeline = (props) => {
  const {
    timelinePending,
    updatePending,
    timeline,
    activeRecipient,
    newInteractionType,

    search,
    contextMsgId,
    isMainTimelineOpen,

    contextDate,
    activeChatSource,
  } = props;

  const { OUTGOING_CALL, OUTGOING_MSG, MSG_ATTACHMENT } = INTERACTION_TYPES;

  const [scrollBtn, setScrollBtn] = useState(false);

  const scrollPosition = useRef(0);

  const timelineRef = useRef();
  const unreadRef = useRef();
  const contextMsgRef = useRef();
  const contextDateRef = useRef();
  const lastLoadScrollDirection = useRef();

  const prevTimelineLength = usePrevious(sumOfNestedArrayItems(timeline));
  const prevActiveRecipientId = usePrevious(activeRecipient.id);

  const prevSearch = usePrevious(search);
  const prevContextMsgId = usePrevious(contextMsgId);
  const prevContextDate = usePrevious(contextDate);
  const prevActiveChatSource = usePrevious(activeChatSource);
  // const prevIsShowReminders = usePrevious(isShowReminders);
  // const prevIsTimelineMedia = usePrevious(timelineMedia);

  useDidMount(() => {
    console.log(0);
    // if we come from another page and timeline already loaded
    if (timeline.length) {
      scrollToBottom();
      // timelineRef.current.scrollToBottom();
    }
  });

  useLayoutEffect(() => {
    // if first timeline page load
    if (!prevTimelineLength && sumOfNestedArrayItems(timeline)) {
      console.log(1);
      return scrollToBottom();
      // return timelineRef.current.scrollToBottom();
    }

    // if search toggle or new search query => scroll to bottom
    if ((!prevSearch && search) || (prevSearch && !search) || (prevSearch && search && (prevSearch !== search))) {
      console.log(8, search, prevSearch);
      return timelineRef.current.scrollToBottom();
    }

    // if chat source changed => scroll to bottom
    if (prevActiveChatSource && activeChatSource && (prevActiveChatSource !== activeChatSource)) {
      console.log(7, activeChatSource, prevActiveChatSource);
      return timelineRef.current.scrollToBottom();
    }


    // if message context toggle 
    if ((!prevContextMsgId && contextMsgId) || (prevContextMsgId && !contextMsgId)) {
      // if contextMessageId => scroll to contextMsg
      if (contextMsgRef.current) {
        console.log(11);
        // return contextMsgRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
        return timelineRef.current.scrollTop(contextMsgRef.current.offsetTop - 30);
      }
      // if we close message context => scroll to bottom
      console.log(10)
      return timelineRef.current.scrollToBottom();
    }
    // if message context date toggle or change
    if ((!prevContextDate && contextDate) ||
      (prevContextDate && !contextDate) ||
      (prevContextDate && contextDate && (prevContextDate !== contextDate))
    ) {
      // if contextMessageId => scroll to contextMsg
      if (contextDateRef.current) {
        console.log(13);
        // return contextDateRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
        return timelineRef.current.scrollTop(contextDateRef.current.offsetTop - 30);
      }
      // if we close message context => scroll to bottom
      console.log(14)
      return timelineRef.current.scrollToBottom();
    }

    // 
    if (activeRecipient.id === prevActiveRecipientId) {
      // timeline update
      if (prevTimelineLength && sumOfNestedArrayItems(timeline) !== prevTimelineLength) {
        if (newInteractionType) {
          // timeline update by new outgoing interaction => scroll to bottom
          console.log(6);
          if ([OUTGOING_CALL, OUTGOING_MSG].includes(newInteractionType)) {
            return timelineRef.current.scrollToBottom();
          }
          // timeline update by new incoming interaction => scroll to bottom
          else {
            const isBottom = timelineRef.current.getScrollHeight() - timelineRef.current.getScrollTop() - timelineRef.current.getClientHeight();
            console.log(66, isBottom);

            if (isBottom <= 400) {
              return timelineRef.current.scrollToBottom();
            }
          }
        }
        // timeline update by getting new page
        else {
          console.log(2);
          // timeline update by getting new page with scrollTop => save scroll position
          if (lastLoadScrollDirection.current === 'up') {
            return timelineRef.current.scrollTop(timelineRef.current.getScrollHeight() - scrollPosition.current);
          }
        }
      }
      // if interaction attachment
      else if (prevTimelineLength && sumOfNestedArrayItems(timeline) === prevTimelineLength) {
        if (newInteractionType === MSG_ATTACHMENT) {
          const isBottom = timelineRef.current.getScrollHeight() - timelineRef.current.getScrollTop() - timelineRef.current.getClientHeight();
          console.log(15);

          if (isBottom <= 300) {
            return timelineRef.current.scrollToBottom();
          }
        }
      }
      return;
    }
    // any other situation
    console.log(20)
    return scrollToBottom();
  }, [timeline, activeRecipient.id, newInteractionType, activeChatSource, search, contextMsgId, contextDate]);

  const handleScroll = ({ target: { scrollTop, scrollHeight, clientHeight } }) => {
    const {
      updateContactTimeline,
      timelinePageCount,
      timelineLowerLoadedPage,
      timelineHigherLoadedPage,
    } = props;
    const scrollBottom = scrollHeight - scrollTop - clientHeight;
    // console.log('scrollTop :' + scrollTop + ', clientHeight / 2: ' + clientHeight / 2);

    if (timelinePending || updatePending) {
      return;
    }

    //toggle scroll button
    if (!scrollBtn && scrollBottom >= 350) {
      setScrollBtn(true);
    }
    else if (scrollBtn && scrollBottom < 350) {
      setScrollBtn(false);
    }
    if ((scrollTop <= clientHeight) && !!timelineHigherLoadedPage && timelineHigherLoadedPage !== 1) { // this code for infinite scroll
      // if (scrollTop === 0 && timelineHigherLoadedPage !== 1) {
      // if we already have msgs more than our limit
      //TODO: some calculation for shift
      scrollPosition.current = scrollHeight - scrollTop;
      lastLoadScrollDirection.current = 'up';
      console.log('loadTop', scrollToBottom);
      return updateContactTimeline(activeRecipient, timelineHigherLoadedPage - 1, 'up');
    }
    else if (scrollBottom === 0 && timelineLowerLoadedPage && timelineLowerLoadedPage !== timelinePageCount) {
      lastLoadScrollDirection.current = 'down';
      console.log('loadBottom');
      return updateContactTimeline(activeRecipient, timelineLowerLoadedPage + 1, 'down');
    }
  };

  const scrollToBottom = (scrollToNew) => {
    if (contextMsgRef.current) {
      return timelineRef.current.scrollTop(contextMsgRef.current.offsetTop - 30);
    }
    else if (unreadRef.current && scrollToNew) {
      return timelineRef.current.scrollTop(unreadRef.current.offsetTop - 30);
    }

    if (scrollBtn) {
      setScrollBtn(false);
    }

    timelineRef.current.scrollToBottom();
  };

  const smoothScroll = () => {
    const scrollStep = setInterval(() => {
      const isBottom = timelineRef.current.getScrollHeight() - timelineRef.current.getScrollTop() - timelineRef.current.getClientHeight();

      if (isBottom !== 0) {
        timelineRef.current.scrollTop(timelineRef.current.getScrollTop() + 350);
      }
      else {
        clearInterval(scrollStep);
      }
    }, 15);
  }
  console.log('render timeline');

  return (
    <div
      className={props.publicChat ? "timeline timeline--public" : "timeline"}>

      {isMainTimelineOpen && props.pinnedMsg &&
        <PinnedMsg
          playMedia={props.playMedia}
          profileId={props.profileId}
          unpinMsg={props.unpinMsg}
          chatId={activeRecipient.id}
          getMessageContext={props.getMessageContext}
          type={props.type}
          interaction={props.pinnedMsg} />
      }
      <Scrollbars
        onScroll={throttle(handleScroll, 300)}
        autoHide
        ref={timelineRef}
        // renderView={props => <div {...props} style={{ ...props.style, overflowX: 'hidden' }} />}
        renderTrackHorizontal={props => <div {...props} style={{ display: 'none' }} className="track-horizontal" />}
      >

        <InteractionsList
          pinMsg={props.pinMsg}
          activeRecipient={activeRecipient}
          type={props.type}
          search={search}
          timelinePending={props.timelinePending}
          editMsg={props.editMsg}
          playMedia={props.playMedia}
          timeline={props.timeline}
          profileId={props.profileId}
          userTimezone={props.userTimezone}
          userHour12={props.userHour12}
          unreadRef={unreadRef}
          showTimePickerForDateContext={props.showTimePickerForDateContext}
          getMessageContext={props.getMessageContext}
          addNewArrayGirlsToState={props.addNewArrayGirlsToState}
          contextMsgId={props.contextMsgId}
          contextMsgRef={contextMsgRef}
          contextDate={props.contextDate}
          contextDateRef={contextDateRef}
          shareMsg={props.shareMsg}
          replyMsg={props.replyMsg}
          publicChat={props.publicChat}
          setActiveChatSource={props.setActiveChatSource}
          isGlobalSearch={props.isGlobalSearch}
          startGlobalMsgSearch={props.startGlobalMsgSearch}
          removeMessageReminder={props.removeMessageReminder}
          updateActiveContact={props.updateActiveContact}
          openModal={props.openModal}
          isMainTimelineOpen={isMainTimelineOpen}
        />
      </Scrollbars>

      {scrollBtn && isMainTimelineOpen &&
        <ScrollToBottom
          className="timeline__btn"
          onClick={smoothScroll}
          unreadCount={activeRecipient.unreadCount}
        />
      }

      {(props.contextMsgId || props.contextDate) &&
        <button
          className="timeline__btn--search"
          title="Back to search results"
          onClick={() => props.contextMsgId
            ? props.getMessageContext(false, activeRecipient, search)
            : props.cleanContactDateMsgContext(props.type)
          }>
          ➥
        </button>
      }

      {props.updatePending &&
        <Spinner spinnerSize={25} className="timeline__update-spinner"/>
      }
    </div>
  );
}

export default memo(ChatTimeline);
