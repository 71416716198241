import React from 'react';
import { connect } from 'react-redux';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { updateActiveContact } from '../../../../ducks/clientChats';
import { selectUserTimezone } from '../../../../selectors/selectors';
import { formatDateToTime, getDateByTimezoneOffset } from '../../../../utils';

const NightBotSessionElement = (props) => {

  const {
    sessionData,
    onDeleteSession,
  } = props;

  const { id, profileName, callerName, profile_id, caller_id, agreedTime, agreedDuration, last_interaction } = sessionData;

  const handleChangeChats = () => {
    props.onChangeChat(profile_id, caller_id);
  };

  const handleDeleteSession = (e) => {
    e.stopPropagation();

    onDeleteSession(id);
  }

  const getBookingTime = () => {
    const date = getDateByTimezoneOffset(props.userTimezone, last_interaction);;

    const prettyDate = date.toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' });
    const time = formatDateToTime(date, props.userHour12, false);

    return prettyDate + ' at ' + time;
  }

  return (
    <li 
      className="session-list__element"
      onClick={handleChangeChats}
      >
      <div className="session-list__content-wrapper">
        <span className="session-list__caller-name">{callerName}</span>
        <span className="session-list__profile-name">{profileName}</span>
      </div>

      <div className="booking-mini__column">
        {getBookingTime()}
        {agreedTime && <span> / {agreedTime} </span>}
        {agreedDuration && <span> / {agreedDuration} </span>}
      </div>

      <button className="session-list__btn" onClick={handleDeleteSession}>
        <FontAwesomeIcon
          icon={faTrash}
          title="delete"
          color="#0092F2"
        />
      </button>
    </li>
  )
}

const mapStateToProps = (state) => ({
  userTimezone: selectUserTimezone(state),
  userHour12: state.user.hour12,
});

const mapDispatchToProps = {
  updateActiveContact,
}

export default connect(mapStateToProps, mapDispatchToProps)(NightBotSessionElement);