import React, { useRef, useState } from 'react';

import './ChatCreatorForm.scss';

import SvgIcon from '../../../../components/SvgIcon/SvgIcon';
import { useDidMount, useToggle } from '../../../../hooks';
import API from '../../../../api/api';
import { CHAT_TYPES } from '../../../../config/constants';


const ChatCreatorForm = (props) => {
  const [tel, setTel] = useState('');

  const [err, toggleErr] = useToggle(false);

  const inputRef = useRef(null);

  useDidMount(() => {
    inputRef.current && inputRef.current.focus();
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    API.createNewChat(tel)
      .then(res => {
        props.updateActiveContact(res.data);
        props.removeContactTab('new_chat', CHAT_TYPES.CLIENT);
      })
      .catch(() => toggleErr());
  };

  const handleOnChange = (e) => {
    setTel(e.target.value);
    toggleErr(false);
  };

  return (
    <form
      className="chat-creator-form" onSubmit={handleSubmit}>
      <SvgIcon className="chat-creator-form__btn--keyboard" icon="phoneKeyboard" width="20px" height="20px" fill="#0092F2" />

      <input
        style={err ? { color: 'red' } : null}
        ref={inputRef}
        onChange={handleOnChange}
        placeholder="Enter phone number"
        className="chat-creator-form__input"
        type="tel"
        // pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
        required />

      <button className="chat-creator-form__btn--submit">
        <SvgIcon
          icon="plus"
          width="20px"
          height="20px"
          title="create"
          fill="#0092F2" />
      </button>
    </form>
  )
};

export default ChatCreatorForm;