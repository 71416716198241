import React, { useState, useEffect } from 'react';

import { classModifier } from '../../utils';

import SearchInput from '../SearchInput/SearchInput';
import List from '../List/List';
import { usePrevious } from '../../hooks';


const SearchableList = (props) => {
  const {
    classPrefix = 'search-list',
    list,
    HeaderComponent,
    isHideInput
  } = props;

  const [query, setQuery] = useState(props.initialQuery || '');

  const currentQuery = (props.query !== undefined) ? props.query : query;
  const currentSetQuery = (props.setQuery !== undefined) ? props.setQuery : setQuery;

  const [loadedQuery, setLoadedQuery] = useState('');

  const prevList = usePrevious(list);
  const prevQuery = usePrevious(currentQuery);

  // change mode only when list loaded by query
  useEffect(() => {
    if (currentQuery.length > 1 && prevList !== list) {
      console.log('SET LOADED', currentQuery);
      setLoadedQuery(currentQuery);
    }
    else if (prevQuery && !currentQuery) {
      console.log('SET LOADED 2', currentQuery);
      setLoadedQuery('');
    }
  }, [list, currentQuery]);

  return (
    <div className={classPrefix}>
      <div className={classModifier(classPrefix + "__input-wrap", isHideInput && 'hidden')}>
        <SearchInput
          classPrefix={classPrefix}
          query={currentQuery}
          setQuery={currentSetQuery}
          isDisabled={props.isDisabled}
          startSearch={props.startSearch}
          stopSearch={props.stopSearch}
          showClearSearchBtn
        />
      </div>

      {HeaderComponent}

      <div className={classPrefix + "__list-wrap"}>
        <List
          list={list}
          hasMore={props.hasMore}
          limit={props.listLimit}
          classPrefix={classPrefix}
          mode={loadedQuery + '_' + props.listMode}
          ignoredListItemsCount={props.ignoredListItemsCount}
          loadMore={props.loadMore}
          listItem={props.listItem}
          listItemProps={props.listItemProps}
          listLoadPending={props.listLoadPending}
        />
      </div>
    </div>
  );
};

export default SearchableList;