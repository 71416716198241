import { CHANGE_CALL_STATUS } from "./clientChats";
import { GET_ALL_CALLS } from "./calls";
import API from "../api/api";

export const NEW_MISSED_CALL = "NEW_MISSED_CALL";
export const REMOVE_MISSED_CALL = "REMOVE_MISSED_CALL";

export const GET_ALL_VOICEMAILS_PENDING = "GET_ALL_VOICEMAILS_PENDING";
export const GET_ALL_VOICEMAILS = "GET_ALL_VOICEMAILS";
export const UPDATE_VOICEMAIL_STATUS = "UPDATE_VOICEMAIL_STATUS";
export const DELETE_ALL_MISSED = "DELETE_ALL_MISSED";
export const SET_ALL_VOICEMAILS_COUNT = "SET_ALL_VOICEMAILS_COUNT";

export const CONTACTS_CALL = 1;
export const OPERATORS_CALL = 3;

export const newMissedCall = (call, callType = CONTACTS_CALL) => (dispatch) => {
  dispatch({
    type: NEW_MISSED_CALL,
    payload: {
      entity: call,
      id: call.caller_id ? call.caller_id + "_" + callType : call.sender.id + "_" + callType
    }
  });
};

export const removeMissedCall = (callId, callType = 1) => (dispatch) => {
  dispatch({
    type: REMOVE_MISSED_CALL,
    payload: callId + "_" + callType
  });
};

export const removeMissedContactCallForMe = (callId) => (dispatch) => {
  API.removeMissedContactCallForMe(callId)
    .then(() => {
      dispatch(removeMissedCall(callId, CONTACTS_CALL));
    })
    .catch((err) => console.log(err));
};

export const removeMissedOperatorCallForMe = (callId) => (dispatch) => {
  API.removeMissedOperatorCallForMe(callId)
    .then(() => {
      dispatch(removeMissedCall(callId, OPERATORS_CALL));
    })
    .catch((err) => console.log(err));
};

export const removeMissedCallForAll = (callId, callerType) => (dispatch) => {
  API.removeMissedCallForAll(callId, callerType)
    .then(() => {
      dispatch(removeMissedCall(callId, callerType === 9 ? OPERATORS_CALL : CONTACTS_CALL));
    })
    .catch((err) => console.log(err));
};

export const updateVoicemailStatus = (call) => (dispatch) => {
  dispatch({
    type: UPDATE_VOICEMAIL_STATUS,
    payload: call
  });
};

export const setAllVoicemailsCount = (count) => (dispatch) => {
  dispatch({
    type: SET_ALL_VOICEMAILS_COUNT,
    payload: count
  });
}

export const getAllVoicemails = () => (dispatch) => {
  dispatch({ type: GET_ALL_VOICEMAILS_PENDING });

  API.getAllVoicemails()
    .then((res) => {
      dispatch({
        type: GET_ALL_VOICEMAILS,
        payload: res.data
      });
    })
    .catch((err) => console.log(err));
};

export const deleteAllMissedForMe = () => (dispatch) => {
  dispatch({ type: DELETE_ALL_MISSED });
};

const initialState = {
  voicemailsPending: false,
  voicemails: [
    // {
    //   attachments: null,
    //   body: null,
    //   callBegin: null,
    //   caller: { "adr": null, "blocked": null, "callerTags": [], "date_created": "2019-08-15 06:25:07", "email": null, "emails": [], "fn": "+380956987831", "id": 8, "isNew": false, "last_interaction": "2019-08-15 06:25:16", "map": null, "marked": false, "note": null, "old_tel": null, "photo": null, "pinned": false, "relatedUserId": null, "hardLocked": null, "tel": "+380956987831", "tels": [{ "id": 46171, "name": "tel1", "tel": "+380956987831", "status": 10, "default": 1 }], "type": 1, "unreadCount": 0, "url": null },
    //   caller_id: 42649,
    //   channel: "+12072306913",
    //   dateCreated: "2020-06-23 10:08:06",
    //   description: null,
    //   direction: null,
    //   duration: 0,
    //   fn: "+12072306913",
    //   hasUrls: 0,
    //   id: 16005,
    //   isRead: 1,
    //   is_hidden: 0,
    //   media_url: null,
    //   status: "no-answer",
    //   type: 1,
    //   user: { "id": 2, "username": "vlad", "photo": "https://s3.eu-west-2.amazonaws.com/cdn.commdiva.abcwdl.com/production/sources/connect-user-2.png?e43171c600355c0698dcc33b10753e0d", "unreadCount": 0, "status": "online", "type": 3 },
    //   voicemail: { id: 228, url: "https://s3.eu-west-2.amazonaws.com/cdn.commdiva.mr…call-16005-RE06e91cf572177ee8f512add242b332c9.mp3", listened: 1 },
    //   webMasterTask: null,
    // }
  ],
  voicemailsCount: 0,
  entities: {
    // '2_3': [{
    //   sender: {
    //     active: 1,
    //     email: "adieush@gmail.com",
    //     id: 2,
    //     isHour12: false,
    //     photo: { min: "https://s3.eu-west-2.amazonaws.com/cdn.commdiva.mr…fd9f2a7baf3.jpeg?b18f2282e8451d40f3d2a1b3d60077fa", src: "https://s3.eu-west-2.amazonaws.com/cdn.commdiva.mr…d9f2a7baf3.jpeg?b18f2282e8451d40f3d2a1b3d60077fa1" },
    //     role: "admin",
    //     status: "online",
    //     timezone: 120,
    //     type: 9,
    //     unreadCount: 0,
    //     username: "admin",
    //   },
    //   dateCreated: "2020-11-30 16:11:26",
    //   "id": 2, "body": null, "caller_id": 2,
    //   "caller": { "adr": null, "blocked": null, "callerTags": [], "date_created": "2019-08-15 06:25:07", "email": null, "emails": [], "fn": "admin name", "id": 2, "isNew": false, "last_interaction": "2019-08-15 06:25:16", "map": null, "marked": false, "note": null, "old_tel": null, "photo": null, "pinned": false, "relatedUserId": null, "hardLocked": null, "tel": "+380956987831", "tels": [{ "id": 46171, "name": "tel1", "tel": "+380956987831", "status": 10, "default": 1 }], "type": 9, "unreadCount": 0, "url": null },
    //   "user": { "id": 3, "username": "myname", "photo": "https://s3.eu-west-2.amazonaws.com/cdn.commdiva.abcwdl.com/production/sources/connect-user-2.png?e43171c600355c0698dcc33b10753e0d", "unreadCount": 0, "status": "online", "type": 9 },
    //   "type": 1, "hasUrls": 0, "duration": 0, "description": null, "dateCreated": "2019-08-15 06:25:16", "isRead": 1, "user_id": 2, "media_url": null, "status": "no-answer", "direction": null, "callBegin": null, "attachments": null, "voicemail": null, "onTransfer": 0, "queueData": null
    // },]
  },
  ids: [
    // '2_3',
  ]
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CALLS: {
      if (!action.payload.missed) {
        return state;
      }
      const formatMissed = groupMissedCallsByCaller(action.payload.missed);

      return { ...state, ...formatMissed };
    }

    case NEW_MISSED_CALL: {
      const isInIds = !!state.entities[action.payload.id];
      const isWithVoicemail = !!action.payload.entity.voicemail;

      const updatedIds = isInIds
        ? [action.payload.id, ...state.ids.filter((id) => id !== action.payload.id)]
        : [action.payload.id, ...state.ids];

      return {
        ...state,
        ids: updatedIds,
        entities: addNewMissedEntity(action.payload, state.entities, isInIds),
        voicemails: isWithVoicemail
          ? [action.payload.entity, ...state.voicemails]
          : state.voicemails,
        voicemailsCount: isWithVoicemail ? +state.voicemailsCount + 1 : state.voicemailsCount
      };
    }

    case REMOVE_MISSED_CALL: {
      const isInMissed = !!state.entities[action.payload];

      if (!isInMissed) {
        return state;
      }

      const updatedMissedEntities = { ...state.entities };

      delete updatedMissedEntities[action.payload];

      const updatedMissedIds = [...state.ids.filter((id) => id !== action.payload)];

      return {
        ...state,
        entities: updatedMissedEntities,
        ids: updatedMissedIds
      };
    }

    case UPDATE_VOICEMAIL_STATUS: {
      return {
        ...state,
        voicemails: changeVoicemailStatus(state.voicemails, action.payload),
        entities: changeVoicemailStatusInCall(state.entities, action.payload),
        voicemailsCount: state.voicemailsCount > 0 ? state.voicemailsCount - 1 : state.voicemailsCount
      };
    }

    case GET_ALL_VOICEMAILS_PENDING: {
      return {
        ...state,
        voicemailsPending: true
      };
    }

    case GET_ALL_VOICEMAILS: {
      return {
        ...state,
        voicemails: action.payload,
        voicemailsPending: false
      };
    }

    case CHANGE_CALL_STATUS: {
      const isInMissedIds = state.ids.some(
        (id) => id === action.payload.callerId + "_" + CONTACTS_CALL
      );

      if (!isInMissedIds) {
        return state;
      }

      const updatedMissedEntities = { ...state.entities };
      let updatedMissedIds;

      if (
        (action.payload.prevStatus === "ringing" && action.payload.status === "in-progress") ||
        (action.payload.prevStatus === "in-queue" && action.payload.status === "in-progress") ||
        (action.payload.prevStatus === "initiated" && action.payload.status === "in-progress")
      ) {
        updatedMissedIds = [
          ...state.ids.filter((id) => id !== action.payload.callerId + "_" + CONTACTS_CALL)
        ];

        delete updatedMissedEntities[action.payload.callerId + "_" + CONTACTS_CALL];
      } else {
        updatedMissedIds = [...state.ids];
      }

      return {
        ...state,
        entities: updatedMissedEntities,
        ids: updatedMissedIds
      };
    }

    case DELETE_ALL_MISSED: {
      return {
        ...state,
        entities: {},
        ids: []
      };
    }

    case SET_ALL_VOICEMAILS_COUNT: {
      return {
        ...state,
        voicemailsCount: action.payload
      };
    }

    default:
      return state;
  }
};

//Redux helper start

export const addNewMissedEntity = (payload, entities, isInIds) => {
  const animatedEntity = { ...payload.entity, isNew: true };

  if (isInIds) {
    return {
      ...entities,
      [payload.id]: [animatedEntity, ...entities[payload.id]]
    };
  }

  return { ...entities, [payload.id]: [animatedEntity] };
};

export const groupMissedCallsByCaller = (missedCalls) => {
  const missed = {
    entities: {},
    ids: []
  };

  missedCalls.map((call) => {
    if (call.senderId) {
      //when operator called
      if (missed.entities[call.senderId + "_" + OPERATORS_CALL]) {
        missed.entities[call.senderId + "_" + OPERATORS_CALL].push(call);
      } else {
        missed.ids.push(call.senderId + "_" + OPERATORS_CALL);
        missed.entities[call.senderId + "_" + OPERATORS_CALL] = [call];
      }
    } else {
      //when contact called
      if (missed.entities[call.caller_id + "_" + CONTACTS_CALL]) {
        missed.entities[call.caller_id + "_" + CONTACTS_CALL].push(call);
      } else {
        missed.ids.push(call.caller_id + "_" + CONTACTS_CALL);
        missed.entities[call.caller_id + "_" + CONTACTS_CALL] = [call];
      }
    }
  });

  return missed;
};

const changeVoicemailStatus = (voicemails, payload) => {
  const isInMissedVoicemails = voicemails.some((entity) => entity.id === payload.callId);

  if (!isInMissedVoicemails) {
    return voicemails;
  }

  const updatedMissedVoicemails = voicemails.map((call) => {
    if (call.id === payload.callId) {
      return { ...call, voicemail: payload.voicemail };
    }
    return call;
  });

  return updatedMissedVoicemails;
};

const changeVoicemailStatusInCall = (entities, payload) => {
  const isInMissedEntities = !!entities[payload.callerId + "_" + CONTACTS_CALL];

  if (!isInMissedEntities) {
    return entities;
  }

  const updatedMissedById = entities[payload.callerId + "_" + CONTACTS_CALL].map((call) => {
    if (payload.callId === call.id) {
      return { ...call, voicemail: payload.voicemail };
    }
    return call;
  });

  return {
    ...entities,
    [payload.callerId + "_" + CONTACTS_CALL]: updatedMissedById
  };
};
//Redux helper stop
