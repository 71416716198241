import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

import { classModifier } from "../../../utils";

export default function RequirementCheckbox({
  checked,
  onChange,
  label,
  stickerColor,
  highlighted,
  disabled,
}) {
  const stickerStyle = stickerColor ? { backgroundColor: stickerColor } : null;

  return (
    <label
      className={classModifier("requirement-checkbox", [
        stickerColor && "sticked",
        highlighted && "highlighted",
        disabled && "disabled",
      ])}
    >
      {!!stickerColor && (
        <span className="requirement-checkbox__sticker" style={stickerStyle}></span>
      )}

      <input
        className="visually-hidden"
        type="checkbox"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />

      <span
        className={classModifier("requirement-checkbox__icon", [checked && "checked"])}
      >
        {!!checked && 
          <FontAwesomeIcon icon={faCheck} />
        }
      </span>

      <span className="requirement-checkbox__label">{label}</span>
    </label>
  );
}
