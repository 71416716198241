import React, { useMemo } from 'react';
import { classModifier } from '../../../utils';

const ServicesList = props => {
  const { 
    rate,
    onServiceSelect, 
    profileServices,
    bookingServices, 
    selectedServices, 
    onClearAllServices, 
  } = props;

  const extraServicesRate = useMemo(() => selectedServices.reduce((acc, el) => acc + el.price, 0), [selectedServices]);
  const totalRate = Number.isNaN(Number.parseInt(rate)) ? '-' : `${+rate + extraServicesRate}£`;

  return (
    <div className="booked-editor-services">
      <div className="booked-editor-services__header">
        <div className="booked-editor-services__rates">
          <span className="booked-editor-services__rate">Extra services rate: {extraServicesRate}£</span>
          <span className="booked-editor-services__rate">Total rate: {totalRate}</span>
        </div>
        <button 
          className="booked-editor__btn booked-editor__btn--primary" 
          onClick={onClearAllServices}
          disabled={!selectedServices.length}
        >
          Clear
        </button>
      </div>
      <ul className="booked-editor-services__list">
        {profileServices.map(service => {
          const { name, price } = service;
          const isChecked = selectedServices.some(el => el.name === name);
          const isAlreadyInBooking = bookingServices.some(el => el.name === name);
          const isChanged = isChecked !== isAlreadyInBooking;
          return (
            <li key={name} className={classModifier("booked-editor-services__element", [isChanged && 'changed'])}>
              <label>
                <div>
                  <input
                    type="checkbox"
                    checked={isChecked} 
                    onChange={(e) => onServiceSelect(e, service)}
                  />
                  <span>{name}</span>
                </div>
                <span>{price ? `${price}£` : '-'}</span>
              </label>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default ServicesList;