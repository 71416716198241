import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import './MissedCallItem.scss';
import { updateActiveContact } from '../../ducks/clientChats';
import API from '../../api/api';
import { callTo } from '../../ducks/twilio';
import { onHoldActiveConference, webrtcCallTo } from '../../ducks/webrtc';
import { playMedia } from '../../ducks/activeWindows';
import {
  removeMissedContactCallForMe,
  removeMissedOperatorCallForMe,
  removeMissedCallForAll,
} from '../../ducks/missedCalls';
import {
  selectMissedCallsByCallerId,
  selectCallerFromMissedCall,
  selectRoomIdByOperatorId,
  selectIncomingCallByCallerId,
  selectActiveWebrtcConference
} from '../../selectors/selectors';
import { getContactAvatar, isEmptyObj } from '../../utils';
import { useToggle } from '../../hooks';
import { updateActiveChat } from '../../ducks/roomChats';

import MissedSubItem from './MissedSubItem';
import Menu from '../Menu/Menu';
import LazyLoadImage from '../LazyLoadImage/LazyLoadImage';
import SvgIcon from '../SvgIcon/SvgIcon';
import TimeAgo from '../TimeAgo';


const MissedCallItem = ({ missedCalls, caller, ...props }) => {
  const [isShowMissedCalls, toggleMissedCalls] = useToggle(false);
  const [menu, setMenu] = useState([]);

  const isOperator = !!caller.username;

  useEffect(() => {
    if (!isEmptyObj(caller) && props.chatId) {
      setMenu([{
        name: 'Send Message',
        action: () => [1, 2, 3].includes(caller.type)
          ? props.updateActiveContact(caller)
          : props.updateActiveChat(props.chatId),
      },
      {
        name: 'Remove missed call for me',
        action: caller.type === 9
          ? () => props.removeMissedOperatorCallForMe(caller.id)
          : () => props.removeMissedContactCallForMe(caller.id),
      }
      ]);
    }
  }, [caller, props.chatId]);

  const getContactName = () => {
    if (!caller) {
      return "Unknown number";
    }

    return caller.short_name || caller.fn || caller.username;
  };

  const isVoicemails = () => {
    if (missedCalls) {
      return missedCalls.some(call => !!call.voicemail);
    }
  };

  const onVoicemailPlay = (audio, voicemail) => {
    props.playMedia(audio);

    if (voicemail.listened) {
      return;
    }

    API.markVoicemailAsListened(voicemail.id);
  };

  const pickUp = (e) => {
    e.preventDefault();

    if (props.activeConference) {
      props.onHoldActiveConference(props.activeConference, props.peerConnections, props.localStream);
    };

    isOperator
      ? props.webrtcCallTo(caller)
      : props.callTo(caller);

    props.removeMissedCallForAll(caller.id, caller.type);
  };

  return (
    <li className="missed-call-item__wrap">
      <div className="missed-call-item">
        <main className="missed-call-item__body">
          <div className="missed-call-item__img-wrap">
            <LazyLoadImage src={getContactAvatar(caller)} alt="ava" className="call-item__img" />
          </div>

          <div className="missed-call-item__info">
            <p className="missed-call-item__number">
              {getContactName()}

              {caller.type === 9 ? <b className="missed-call-item__operator">Operator</b> : null}
            </p>

            <p className="missed-call-item__duration">
              <TimeAgo minPeriod={60} date={missedCalls[0].dateCreated} />
            </p>

          </div>

          <span className="missed-call-item__count">

            <span className="missed-call-item__voicemail-flag">
              {isVoicemails() &&
                <svg
                  height="16px"
                  width="16px"
                  viewBox="1 -28 512 511"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#000">
                  <path d="M457 1H55C25 1 0 25 0 56v305c0 30 25 55 55 55h155l35 36a15 15 0 0 0 22 0l35-36h155c30 0 55-25 55-55V56c0-31-25-55-55-55zm25 360c0 14-11 25-25 25H296c-4 0-8 2-10 4l-30 30-30-30c-2-2-6-4-10-4H55c-14 0-25-11-25-25V56c0-14 11-26 25-26h402c14 0 25 12 25 26zm0 0" />
                  <path d="M359 136a72 72 0 0 0-58 115h-90a72 72 0 1 0-59 30h207a72 72 0 0 0 0-145zm-249 72a42 42 0 1 1 85 0 42 42 0 0 1-85 0zm249 43a42 42 0 1 1 0-85 42 42 0 0 1 0 85zm0 0" />
                </svg>
              }
            </span>

            {missedCalls.length > 1 ? `(${missedCalls.length})` : null}
          </span>

          <div className="missed-call-item__controls">
            {<Menu
              menuItems={menu}
              className="missed-call-item__btn--burger"
              menuPosition="fixed-center" />
            }


            {missedCalls.length === 1 && !missedCalls[0].voicemail
              ? null
              : <span
                style={isShowMissedCalls
                  ? { transform: 'rotateX(180deg)', transition: '0.2s cubic-bezier(1,.5,1,.33) all' }
                  : { transform: 'rotateX(0deg)', transition: '0.2s cubic-bezier(1,.5,1,.33) all' }}
                className="missed-call-item__btn--toggler"
                onClick={() => toggleMissedCalls()}>

                <svg width='12' height='12' fill='#BFBFBF'
                  className='chat__show-more-contacts-icon'
                  viewBox="0 0 371 371" >
                  <path d="M293 25L269 0 78 185l191 186 24-25-165-161z" />
                </svg>
              </span>
            }
          </div>
        </main>

        <div className="missed-call-item__dropdown">
          {isShowMissedCalls &&
            <ul className="missed-sub-list">
              {missedCalls.map(callItem => {
                return <MissedSubItem
                  callItem={callItem}
                  onVoicemailPlay={onVoicemailPlay}
                  key={callItem.id}
                  playMedia={props.playMedia} />;
              })}
            </ul>
          }
        </div>

      </div>

      <button
        className="missed-call-item__btn--main call-item__btn"
        disabled={isEmptyObj(caller)
          || props.isActiveCall
          || caller.status === 'offline'
          || (isOperator && !isEmptyObj(props.incomingCallByCaller))
        }
        onClick={pickUp}>
        <SvgIcon icon="phone" width="25px" height="25px" fill="#01DF85" title="call back" />
      </button>
    </li>
  );
};

const mapStateToProps = (state, ownProps) => ({
  caller: selectCallerFromMissedCall(state, ownProps.id) || {},
  missedCalls: selectMissedCallsByCallerId(state, ownProps.id),
  chatId: selectRoomIdByOperatorId(state, ownProps.id),
  isActiveCall: !isEmptyObj(state.calls.activeCall),
  incomingCallByCaller: selectIncomingCallByCallerId(state, ownProps.id),
  localStream: state.webrtc.localStream,
  peerConnections: state.webrtc.peerConnections,
  activeConference: selectActiveWebrtcConference(state),
});

const mapDispatchToProps = {
  removeMissedContactCallForMe,
  removeMissedOperatorCallForMe,
  removeMissedCallForAll,
  updateActiveContact,
  updateActiveChat,
  callTo,
  playMedia,
  onHoldActiveConference,
  webrtcCallTo,
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(MissedCallItem));