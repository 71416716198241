import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import Scrollbars from 'react-custom-scrollbars';

import API from '../../../api/api';
import { webSocket } from '../../../components/ChatSocket';
import { useDidUpdate } from '../../../hooks';
import { getDateByTimezoneOffset, classModifier } from '../../../utils';

import BookingsListItem from './BookingsListItem';
import Spinner from "../../../components/UI/Spinner/Spinner";

const HOURS_COUNT = 28;

const ONE_HOUR = 3600000;
const TWENTY_FOUR_HOURS = ONE_HOUR * 24;

const createBookingRowsByTime = (hour12 = false) => {
  const bookingRows = [];
  let amPm = '';

  for (let i = 0; i < HOURS_COUNT; i++) {
    const hour = i % 24;

    if (hour12) {
      amPm = hour < 12 ? ' AM' : ' PM';
      hour = hour > 12 ? hour - 12 : hour
    }

    const hours = String(hour).length === 1
      ? '0' + hour
      : hour;

    bookingRows.push({
      time: hours + ':00' + amPm,
      bookings: []
    });
  }

  return bookingRows;
}

const BookingsList = (props) => {
  const {
    dateFilter,
    userTimezone,
    toggleConfirmOnClose
  } = props;

  const [bookings, setBookings] = useState([]);
  const [bookingRows, setBookingRows] = useState([]);
  const [isPending, setPending] = useState(true);

  const listRef = useRef(null);
  const currentTimeRowRef = useRef(null);

  useEffect(() => {
    if (!dateFilter) return;

    setPending(true);

    API.getBookingsByDate(dateFilter)
      .then(res => setBookings(res.data))
      .catch(console.log)
      .finally(() => setPending(false));
  }, [dateFilter]);

  useDidUpdate(() => {
    setBookingRows(getBookingsRows(bookings));
  }, [bookings])

  useLayoutEffect(() => {
    if (!isPending && currentTimeRowRef.current) {
      listRef.current.scrollTop(currentTimeRowRef.current.offsetTop - 10);
    }
  }, [isPending]);

  useEffect(() => {
    webSocket.addEventListener('message', handleSocket);
    return () => webSocket.removeEventListener('message', handleSocket);
  }, [dateFilter])

  const handleSocket = (e) => {
    const { data, type } = JSON.parse(e.data);

    const getIsBookingInFilterRange = (booking) => {
      const dateFilterByTimezone = getDateByTimezoneOffset(userTimezone, dateFilter).getTime();
      const bookingDateByTimezone = getDateByTimezoneOffset(userTimezone, booking.date).getTime();

      const listHoursTotal = HOURS_COUNT * ONE_HOUR - 1;
      const listHoursStart = dateFilterByTimezone;
      const listHoursEnd = listHoursStart + listHoursTotal;

      return listHoursStart <= bookingDateByTimezone && bookingDateByTimezone < listHoursEnd;
    }

    if (type === "sales_session") {
      if (data.type === "create_session_booking" && getIsBookingInFilterRange(data.booking)) {
        setBookings(prevState => [...prevState, data.booking]);
      }
  
      if (data.type === "delete_session_booking" && getIsBookingInFilterRange(data.booking)) {
        setBookings(prevState => prevState.filter(booking => booking.id !== data.booking.id));
      }
    }
  }

  const getBookingsRows = (bookings) => {
    const newBookingRows = createBookingRowsByTime(props.userHour12);

    bookings.forEach(booking => {
      const bookingDateByTimezone = getDateByTimezoneOffset(userTimezone, booking.date);
      const dateFilterByTimezone = getDateByTimezoneOffset(userTimezone, dateFilter);

      const datesFloat = bookingDateByTimezone - dateFilterByTimezone;
      const daysDifference = Math.floor(datesFloat / TWENTY_FOUR_HOURS);

      const hours = bookingDateByTimezone.getHours() + (24 * daysDifference);

      newBookingRows[hours].bookings.push(booking);
    });

    return newBookingRows;
  }

  const setNewBookingToRow = (updatedBookingRows, newHour, currentRow, currentBookingIndex, newBooking) => {
    const getMinutesFromDate = (date) => new Date(date).getMinutes();
    const nextRow = updatedBookingRows[newHour].bookings;

    currentRow.bookings.splice(currentBookingIndex, 1);

    let indexOfAddBooking = nextRow.length
      ? nextRow.findIndex(booking => getMinutesFromDate(booking.date) > getMinutesFromDate(newBooking.date))
      : 0;

    if (indexOfAddBooking === -1) {
      indexOfAddBooking = nextRow.length;
    }

    nextRow.splice(indexOfAddBooking, 0, newBooking);
  };

  const updateBookings = (newBooking, hour) => {
    const getDate = (date) => getDateByTimezoneOffset(userTimezone, date);

    setBookingRows(prevBookingRows => {
      const updatedBookingRows = [...prevBookingRows];
      const currentRow = updatedBookingRows[hour];

      const currentBookingIndex = currentRow.bookings.findIndex(booking => booking.id === newBooking.id);
      const currentBooking = currentRow.bookings[currentBookingIndex];

      const isUpdateOfTime = currentBooking.date.split(' ')[1] !== newBooking.date.split(' ')[1];
      const isUpdateOfDate = getDate(currentBooking.date).toLocaleDateString() !== getDate(newBooking.date).toLocaleDateString();

      let newHour = getDate(newBooking.date).getHours();

      if (isUpdateOfDate) {
        const currentBookingDay = getDateByTimezoneOffset(userTimezone, currentBooking.date).setHours(0, 0, 0, 0);
        const newBookingDay = getDateByTimezoneOffset(userTimezone, newBooking.date).setHours(0, 0, 0, 0);
        const timeDiff = currentBookingDay - newBookingDay;

        if (Math.abs(timeDiff) === TWENTY_FOUR_HOURS) {
          if (timeDiff < 0) {
            console.log("Re-booking forward");

            if (newHour < 4) {
              newHour += 24;

              setNewBookingToRow(updatedBookingRows, newHour, currentRow, currentBookingIndex, newBooking);
            }
            else {
              currentRow.bookings.splice(currentBookingIndex, 1);
            }
          }
          else {
            console.log("Re-booking back");

            if (hour > 23) {
              setNewBookingToRow(updatedBookingRows, newHour, currentRow, currentBookingIndex, newBooking);
            }
            else {
              currentRow.bookings.splice(currentBookingIndex, 1);
            }
          }
        }
        else {
          currentRow.bookings.splice(currentBookingIndex, 1);
        }
      }
      else if (isUpdateOfTime) {

        if (hour > 23) {
          if (newHour < 4) {
            newHour += 24;
            setNewBookingToRow(updatedBookingRows, newHour, currentRow, currentBookingIndex, newBooking);
          }
          else {
            currentRow.bookings.splice(currentBookingIndex, 1);
          }
        }
        else {
          setNewBookingToRow(updatedBookingRows, newHour, currentRow, currentBookingIndex, newBooking);
        }
      }
      else {
        currentRow.bookings[currentBookingIndex] = newBooking;
      }

      return updatedBookingRows;
    });
  }

  const getIsCurrentTimeRow = (hours) => {
    const daysDifference = Math.floor(hours / 24);
    const currentDay = new Date(dateFilter + (TWENTY_FOUR_HOURS * daysDifference));

    const isToday = getDateByTimezoneOffset(userTimezone, currentDay).toLocaleDateString() === getDateByTimezoneOffset(userTimezone).toLocaleDateString();

    if (!isToday) {
      return false;
    }

    return getDateByTimezoneOffset(userTimezone).getHours() === hours % 24;
  }

  return (
    <div className='bookings__list-wrap'>
      <Scrollbars ref={listRef}>
        <ul className='bookings__list'>
          {isPending
            ? (
              <div className="bookings__spinner-wrap">
                <Spinner spinnerSize={30} />
              </div>
            )
            : bookingRows.map((bookingRow, index) =>
              <li
                className={classModifier('bookings__item', [index > 23 && 'next-day'])}
                key={index}
                ref={getIsCurrentTimeRow(index) ? currentTimeRowRef : null}
              >
                {!!getIsCurrentTimeRow(index) &&
                  <div className='bookings__current-time-indicator'></div>
                }

                <div className='bookings__time'>
                  {props.isChat
                    ? bookingRow.time.replace(':00', '')
                    : bookingRow.time
                  }
                </div>

                <ul className='bookings__bookings-list'>
                  {bookingRow.bookings.map(booking =>
                    <BookingsListItem
                      isChat={props.isChat}
                      key={booking.id}
                      booking={booking}
                      onClose={props.onClose}
                      updateBookings={updateBookings}
                      hour={index}
                      toggleConfirmOnClose={toggleConfirmOnClose}
                      askConfirmOnClose
                    />
                  )}
                </ul>
              </li>
            )
          }
        </ul>
      </Scrollbars>
    </div>
  );
};

const mapStateToProps = state => ({
  userHour12: state.user.hour12,
})

export default connect(mapStateToProps, null)(BookingsList);
