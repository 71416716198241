import React, { useEffect, useRef } from "react";

import './ResizableTextarea.scss';

const ResizableTextarea = ({ setHeight, ...props }) => {
  const textareaRef = useRef(null);

  const inputHeightAutosize = () => {
    textareaRef.current.style.height = '';
    textareaRef.current.style.height = textareaRef.current.scrollHeight + 2 + 'px';

    setHeight && setHeight(textareaRef.current.scrollHeight + 2);
  };

  useEffect(() => {
    inputHeightAutosize();
  }, [props.value])

  return (
    <textarea
      {...props}
      className={props.className ? `resizable-textarea ${props.className}` : 'resizable-textarea'}
      ref={textareaRef}
      rows='1'
    />
  )
}

export default ResizableTextarea;