import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';

import { killConnection, callTo, callToWithParams } from '../../ducks/twilio';
import { acceptTransferedCall, acceptIncomingQueueCall, unholdCall } from '../../ducks/calls';
import { classModifier, isEmptyObj } from '../../utils';
import { onHoldActiveConference } from '../../ducks/webrtc';
import { selectActiveWebrtcConference } from '../../selectors/selectors';

import SvgIcon from '../SvgIcon/SvgIcon';
import DropWrapper from '../DropWrapper/DropWrapper';


const CallButton = ({ onClick, title, fill, disabled = false, className = '', width = 16, isConfirm, closeConfirm, ...props }) => (
  <button
    className={`${className} call-btn`}
    title={title}
    disabled={disabled}
    onClick={() => {
      props.closeActiveConference && props.closeActiveConference();
      onClick();
      closeConfirm && closeConfirm();
    }}
  >
    {isConfirm
      ? 'Confirm call'
      : <SvgIcon icon="phone" fill={fill} width={width} height={width} />
    }
  </button>
);

const CallButtonWrapper = (props) => {
  const { activeRecipient, connectedContacts } = props;
  const [dropdownStatus, setDropdownStatus] = useState(false);

  const wrapperRef = useRef();

  const isActiveCall = props.connection && props.connectedCaller &&
    props.connectedCaller.id === activeRecipient.id &&
    props.connectedCaller.type === activeRecipient.type;

  const closeActiveConference = () => {
    if (props.activeConference) {
      props.onHoldActiveConference(props.activeConference, props.peerConnections, props.localStream);
    };
  };

  const unholdCall = (call) => {
    props.device.connect(callToWithParams('unhold', call.id))

    props.unholdCall(call);
  }

  const acceptIncomingQueueCall = (call) => {
    props.device.connect(callToWithParams('incoming_queue', call.id))

    props.acceptIncomingQueueCall(call);
  }

  const getCallBtnProps = () => {
    if (!activeRecipient) {
      return {
        disabled: true
      }
    }
    if (!connectedContacts[activeRecipient.id] && activeRecipient.tels) {         // if caller not in call and we can call to him
      return {
        disabled: props.callInProcess || !activeRecipient.tels[0],
        fill: "#0092F2",
        onClick: () => props.callTo(activeRecipient)
      }
    }
    if (activeRecipient.status) {                                                 // if operator and we can call to him if we haven't call in progress already
      return {
        disabled: activeRecipient.status !== "online" || props.callInProcess,
        fill: "#0092F2",
        onClick: () => props.callTo(activeRecipient)
      }
    }
    if (isActiveCall) {                      // this contact in activeCall => we can hang up this call
      return {
        fill: "#F8737F",
        onClick: () => killConnection(props.connection)
      }
    }
    if (!isEmptyObj(props.callsOnHold)) {                                         // if caller onHold => pickUp
      const call = Object.values(props.callsOnHold)
        .find(call => call.caller_id === activeRecipient.id);

      if (call) {
        return {
          disabled: props.callInProcess,
          fill: "#01DF85",
          onClick: () => unholdCall(call),
        }
      }
    }
    if (!isEmptyObj(props.callsOnTransfer)) {                                     // if caller onTransfer => pickUp
      const call = Object.values(props.callsOnTransfer)
        .find(call => call.caller_id === activeRecipient.id);

      if (call) {
        return {
          disabled: props.callInProcess,
          fill: "#01DF85",
          onClick: () => props.acceptTransferedCall(call),
        }
      }
    }
    if (!isEmptyObj(props.incomingCalls)) {                                     // if caller in incomingQueue => pickUp
      const call = Object.values(props.incomingCalls)
        .find(call => call.status === "in-queue" && call.caller_id === activeRecipient.id);

      if (call) {
        return {
          disabled: props.callInProcess,
          fill: "#01DF85",
          onClick: () => acceptIncomingQueueCall(call),
        }
      }
    }

    return {                                                                  // default call btn if caller busy => disabled
      title: "Busy",
      disabled: true,
      onClick: () => props.callTo(activeRecipient)
    }
  }

  const toggleStatus = () => {
    setDropdownStatus(prev => !prev);
  }

  if (props.isConfirm && !isActiveCall) {
    return <div style={{ position: 'relative' }}>
      <CallButton {...getCallBtnProps()} onClick={toggleStatus} width={props.width} className={props.className} />

      {dropdownStatus &&
        <div ref={wrapperRef}>
          <DropWrapper
            closeDropWrapper={toggleStatus}
            dropWrapperRef={wrapperRef}
            className={classModifier("call-btn__dropdown", props.dropdownPosition)}
          >
            <CallButton
              {...getCallBtnProps()}
              closeConfirm={toggleStatus}
              isConfirm
              width={props.width}
              className={props.className}
              closeActiveConference={closeActiveConference}
            />
          </DropWrapper>
        </div>
      }
    </div>
  }

  return <CallButton {...getCallBtnProps()} width={props.width} closeActiveConference={closeActiveConference} className={props.className} />
}

const mapStateToProps = state => ({
  connection: state.twilio.connection,
  device: state.twilio.device,
  connectedContacts: state.calls.connectedContacts,
  callsOnHold: state.calls.callsOnHold,
  callsOnTransfer: state.calls.callsOnTransfer,
  connectedCaller: state.calls.activeCall.caller,
  callInProcess: state.calls.activeCall.status,
  incomingCalls: state.calls.incomingCalls,
  localStream: state.webrtc.localStream,
  peerConnections: state.webrtc.peerConnections,
  activeConference: selectActiveWebrtcConference(state),
});

const mapDispatchToProps = {
  callTo,
  acceptTransferedCall,
  acceptIncomingQueueCall,
  onHoldActiveConference,
  unholdCall
};

export default connect(mapStateToProps, mapDispatchToProps)(CallButtonWrapper);