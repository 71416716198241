import React from 'react';
// import { Scrollbars } from "react-custom-scrollbars";

import './Modal.css';
import { useDidMount, useWillUnmount } from '../../hooks';

const Modal = (props) => {
  useDidMount(() => document.addEventListener("keydown", handleKeyDown, false));

  useWillUnmount(() => document.removeEventListener("keydown", handleKeyDown, false));

  const handleKeyDown = (e) => {
    if (!props.closeModal) return;

    if (e.code === "Escape") {
      props.closeModal();
    }
  }

  const handleCloseModal = (e) => {
    if (!props.closeModal) return;
    if (!props.isCloseOutside) return;

    if (e.target.className !== "modal__background") {
      return;
    }
    props.closeModal();
  }

  const activeClass = props.isOpen
    ? 'modal__container open'
    : 'modal__container open out';

  return (
    <div
      className={activeClass}
      style={props.zIndex ? { zIndex: props.zIndex } : null}
      onMouseDown={handleCloseModal}
    >
      <div className="modal__background" >
        <article className="modal__content">
          {/* <Scrollbars > */}
          {props.children}
          {/* </Scrollbars> */}
        </article>
      </div>
    </div>
  )
}

export default Modal;
