import React from 'react';
import { connect } from 'react-redux';

import { callToConference } from '../../../../ducks/twilio';

import Menu from '../../../../components/Menu/Menu';


const RoomTabsMenu = (props) => {
  const menu = [
    {
      name: 'Start new conference',
      onMouseOver: console.log,
      action: () => props.callToConference()
    },
  ];

  return (
    <div className='room-tabs-menu'>
      <Menu
        menuItems={menu}
        width="14"
        icon="settings"
        menuPosition="fixed-right"
      />
    </div>
  )
}

const mapDispatchToProps = {
  callToConference,
}

export default connect(null, mapDispatchToProps)(RoomTabsMenu);