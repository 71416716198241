import React, { useLayoutEffect, useRef, useState } from 'react';

import { useDidMount, useWillUnmount } from '../../hooks';

import SelectMenuItem from './SelectMenuItem';


const SelectMenu = ({ autoposition, parentRef, ...props }) => {
  const [listStyle, setListStyle] = useState(null);

  const listRef = useRef(null);

  useLayoutEffect(() => {
    if (!autoposition) return;

    const { height: ownHeight } = listRef.current.getBoundingClientRect();
    const { top: parentTop, bottom: parentBottom, height: parentHeight } =
        parentRef.current.getBoundingClientRect();

    // Top initial (position relatively viewport)
    let top = parentBottom - 1;
    // Move higher if does not fit at the bottom
    const bottom = top + ownHeight;
    const bottomOffset = bottom - (document.documentElement.clientHeight - 5);
    top = bottomOffset > 0 ? top - Math.max(bottomOffset, parentHeight) : top;
    // Leave at very top if does not fit on high
    top = top < 5 ? 5 : top;

    // Set top relatively parent component (position absolute)
    setListStyle({ top: top - parentTop });
  }, []);

  useDidMount(() => {
    document.addEventListener('click', props.closeMenu);
    document.addEventListener('keydown', preventArrowKeys);
  });

  useWillUnmount(() => {
    document.removeEventListener('click', props.closeMenu);
    document.removeEventListener('keydown', preventArrowKeys);
  });

  const preventArrowKeys = (e) => {
    if (e.key === "ArrowDown" || e.key === "ArrowUp") {
      e.preventDefault();
    }
  }

  const updateFocused = (index, value) => {
    if (value === props.focusedValue) return;

    props.setFocused({ index, value })
  }

  const updateAndClose = (e, option) => {
    props.updateInputValue(e, option);
    props.closeMenu(e);
  }

  return (
    <ul
      className={props.classPrefix + "__list"}
      ref={listRef}
      style={listStyle}
    >
      {props.options.map((option, index) => {
        const optionValue = props.getOptionValue(option);

        return (
          <SelectMenuItem 
            option={option}
            listRef={listRef}
            itemIndex={index}
            value={optionValue}
            key={option.id || index}
            updateFocused={updateFocused}
            classPrefix={props.classPrefix}
            updateAndClose={updateAndClose}
            isActionButton={!!option.action}
            selectOption={props.SelectOption}
            isScrollToValue={props.isScrollToValue}
            isActive={props.defaultValue === optionValue}
            isFocused={props.focusedValue === optionValue}
          />
        )
      })}
    </ul>
  )
}

export default SelectMenu;