import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';

import './SessionsAccordion.scss';
import Sessions from '../Sessions/Sessions';
import Spinner from '../../components/UI/Spinner/Spinner';


const SessionsAccordion = (props) => {
  const [activeList, setActiveList] = useState(null);

  const toggleAccordion = useCallback((id, isInit) => {
    setActiveList(prevId => {
      if (isInit) {
        return prevId || id;
      }

      return prevId === id
        ? null
        : id;
    });
  }, []);

  if (props.sessionsPending) {
    return (
      <div className="sessions-accordion__spinner">
        <Spinner spinnerSize={50} />
      </div>
    )
  }

  return (
    <div className="sessions-accordion">
      <Sessions
        accordion={{
          isOpen: activeList === 1,
          toggle: toggleAccordion,
          id: 1,
        }}
        type={{ isMainPage: true, isMine: true, property: 'ids' }}
      />

      <Sessions
        accordion={{
          isOpen: activeList === 3,
          toggle: toggleAccordion,
          id: 3,
        }}
        type={{ isAssistance: true, property: 'assistanceIds' }}
      />

      <Sessions
        accordion={{
          isOpen: activeList === 2,
          toggle: toggleAccordion,
          id: 2,
        }}
        type={{ isPartners: true, property: 'partnersIds' }}
      />

    </div>
  )
}

const mapStateToProps = (state) => ({
  sessionsPending: state.sessions.addSessionPending,
});

export default connect(mapStateToProps)(SessionsAccordion);