import React from 'react';

import './SubMenu.scss';


const SubMenu = ({ classPrefix, ...props }) => {
  const renderItems = (item, index) => {
    if (item.separator) {
      return <li key={index} className={classPrefix + "__separator"}></li>;
    }
    else if (item.header) {
      return (
        <li key={index} className={classPrefix + "__header"}>
          {item.title}<br />
          <strong>{item.target}</strong>
        </li>
      );
    }
    return (
      <li
        key={index}
        onClick={(e) => handleItemClick(e, item)}
        onMouseOver={item.onMouseOver || null}
        className={classPrefix + "__item"}>
        <div className={classPrefix + "__item-content"}>{item.title}</div>
      </li>
    );
  }

  const handleItemClick = (e, item) => {
    e.preventDefault();

    item.action();

    if (item.notCloseOnClick) {
      return;
    }

    props.closeSubMenu();
  }


  return (
    <ul className={classPrefix + "__menu"}>
      {props.items.map((item, index) => renderItems(item, index))}
    </ul>
  );
}

export default SubMenu;