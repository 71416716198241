import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';

import { openModal, MODAL_TYPES } from '../../ducks/activeWindows';

import './ProfileRates.scss';


const ProfileRates = (props) => {
  const {
    isEditor,
    profileId,
    styles = {},
  } = props;

  // Get filtered prices
  const defaultPrices = useMemo(() => props.defaultPrices.filter(r => r.name !== 'Evening'), [props.defaultPrices]);
  const memberPrices = useMemo(() => props.memberPrices.filter(r => r.name !== 'Evening'), [props.memberPrices]);
  const secretPrices = useMemo(() => props.secretPrices.filter(r => r.name !== 'Evening'), [props.secretPrices]);

  const dispatch = useDispatch();

  const getPricesForTable = (defaultPrices, memberPrices = null, secretPrices = null) => {
    const prices = {};
    if(defaultPrices) {
      defaultPrices.forEach((item) => {
        prices[item.name] = {
          defaultIncall: item.incall,
          defaultOutcall: item.outcall,
        }
      });
    }

    if(memberPrices) {
      memberPrices.forEach((item) => {
        prices[item.name] = {
          ...prices[item.name],
          memberIncall: item.incall,
          memberOutcall: item.outcall,
        }
      });
    }

    if(secretPrices) {
      secretPrices.forEach((item) => {
        prices[item.name] = {
          ...prices[item.name],
          secretIncall: item.incall,
          secretOutcall: item.outcall,
        }
      });
    }

    return prices;
  }

  const prices = getPricesForTable(defaultPrices, memberPrices, secretPrices);

  const pricingPeriods = Object.keys(prices);

  const getCellValue = (value) => {
    return typeof(value) === 'number' ? `£${value}` : '–';
  }

  return (
    <div className="profile-rates" data-profile-rates style={styles}>
      <div className="profile-rates__title" data-profile-rates>Rates</div>

      {isEditor && !!defaultPrices.length &&
        <button
          className="profile-rates__edit-btn"
          data-profile-rates
          onClick={() => dispatch(openModal(MODAL_TYPES.profilePricesEditing, {
            profileId,
            defaultPrices,
            memberPrices,
            secretPrices,
          }))}
        >
          <FontAwesomeIcon icon={faPen} />
        </button>
      }

      <div className="profile-rates__header" data-profile-rates>
        <div className="profile-rates__header-item" data-profile-rates>Duration</div>
        <div className="profile-rates__header-item profile-rates__header-item--in-out" data-profile-rates>In / Out</div>
        <div className="profile-rates__header-item profile-rates__header-item--member" data-profile-rates>M</div>
        <div className="profile-rates__header-item profile-rates__header-item--secret" data-profile-rates>S</div>
      </div>

      <ul className="profile-rates__items" data-profile-rates>
        {pricingPeriods.map((period) => (
            <li className="profile-rates__item" key={period}>
              <div className="profile-rates__cell profile-rates__cell--name" data-profile-rates>{period || '–'}</div>
              <div className="profile-rates__cell profile-rates__cell--incall-outcall" data-profile-rates>
                <div className="profile-rates__cell-value">{getCellValue(prices[period].defaultIncall)}</div>
                <div className="profile-rates__cell-value">{getCellValue(prices[period].defaultOutcall)}</div>
              </div>
              <div className="profile-rates__cell profile-rates__cell--member" data-profile-rates>
                <div className="profile-rates__cell-value">{getCellValue(prices[period].memberIncall)}</div>
                <div className="profile-rates__cell-value">{getCellValue(prices[period].memberOutcall)}</div>
              </div>
              <div className="profile-rates__cell profile-rates__cell--secret" data-profile-rates>
                <div className="profile-rates__cell-value">{getCellValue(prices[period].secretIncall)}</div>
                <div className="profile-rates__cell-value">{getCellValue(prices[period].secretOutcall)}</div>
              </div>
            </li>
        ))}
      </ul>
    </div>
  )
}

export default ProfileRates;