// Types of contacts in our db
export const CONTACT = 'contact';
export const OPERATOR = 'operator';
export const ROOM = 'room';

export const CONTACT_TABS = Object.freeze({
  CLIENTS: 'clients',
  GIRLS: 'girls',
  OPERATOR: 'operator'
});

export const CONTACT_TYPES = Object.freeze({
  CLIENT: 1,
  GIRL: 2,
  AGENT: 3,
  OPERATOR: 9,
});

export const INTERACTION_TYPES = Object.freeze({
  INCOMING_MSG: 1,
  OUTGOING_MSG: 2,
  OUTGOING_CALL: 3,
  INCOMING_CALL: 4,
  SYSTEM_MSG: 8,
  INCOMING_MSG_TELEGRAM: 9,
  OUTGOING_MSG_TELEGRAM: 10,
  INCOMING_EMAIL: 12,
  MSG_ATTACHMENT: 'attachment'
});

export const CHAT_TYPES = Object.freeze({
  CLIENT: 1,
  GIRL: 2,
  ROOM: 3,
});

export const AUDIO_SETTINGS_TYPES = Object.freeze({
  NORMAL: 0,
  MUTE: 1,
  IMPORTANT: 2,
});

export const MSG_PEACES = Object.freeze({
  GIRL_NAME: 'girlName',
  MENTION: 'mention',
  LINK: 'link',
  TEXT: 'text'
});

export const CHAT_SOURCES = Object.freeze({
  MSGS: 'msgs',
  MEDIA: 'media',
  REMINDERS: 'reminders',
  SCHEDULED_MSGS: 'scheduled-msgs',
  SYSTEM_MSGS: 'system_msgs',
});

export const TITLE = 'ComDiva App';

export const CARD_TABS = Object.freeze({
  INFO: 0,
  EDIT: 1,
  LOGS: 2,
  SESSIONS: 3,
});

export const MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export const CONTACTS_MODES_TYPES = Object.freeze({
  1: 'client',
  2: 'girl',
  3: 'agent',
  4: 'service'
});

export const ADDITIONAL_SESSION_FILTERS = Object.freeze({
  holidays: "on-holidays",
  banned: "include-banned",
  includeoff: "includeoff",
  sortby: "sortby",
  age: "filter-age_filter",
  breast: "filter-breast_filter",
  ethnicity: "filter-ethnicity",
  hair: "filter-hair",
  height: "filter-height_filter",
  location: "filter-location",
  search: "search",
  language: "filter-language",
  price: "filter-price_filter",
  dress: "filter-dress_filter",
  nationality: "filter-nationality",
  city: "filter-city",
  services: "filter-services",
});

export const MAX_CONFERENCE_PARTICIPANTS = 8;

export const CANCEL_BOOKING_REASONS = ["because of the client", "because of a girl", "because of the operator"];
