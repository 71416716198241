import React, { Fragment, useCallback, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import {
  getAllContactsList,
  updateContactsList,
  toggleContactPin,
  toggleContactMark,
  CONTACTS_LIMIT,
  toggleContactBlock,
  removeContactFilter,
  getMarkedContacts,
  getContactsWithUnread,
  getRelatedContacts,
  getAvailableGirls,
  getOnlyHotels,
  getOnlyAgents,
  getNotTextedForLong,
  getActiveChats,
  ALL_FILTER,
  UNREAD_FILTER,
  MARKED_FILTER,
  SERVICES_FILTER,
  AGENTS_FILTER,
  RELATED_FILTER,
  AVAILABLE_GIRLS_FILTER,
  NOT_TEXTED_FOR_LONG,
  ACTIVE_CHATS,
  toggleSearchSource,
} from '../../../../ducks/contacts';
import { CONTACT_TABS } from '../../../../config/constants';

import API from '../../../../api/api';
import { usePrevious, useDidUpdate, useDidMount, useCancelToken } from '../../../../hooks';
import { selectGirlsList } from '../../../../selectors/selectors';
import { openModal, MODAL_TYPES } from '../../../../ducks/activeWindows';
import { updateSearchContactsByType, stopContactsSearchByType, searchContactsByType, cleanContactsUnreadCount } from '../../../../ducks/contacts';
import LS from '../../../../utils/localStorageAPI';

import './GirlTabs.scss';
import ChatList from '../ChatList/ChatList';
import ContactItem from '../ChatList/ContactItem';
import Contextmenu from '../../../../components/ContextMenu/ContextMenu';
import ChatListSearchFilterZone from '../ChatListSearchFilterZone/ChatListSearchFilterZone';
import SearchContacts from '../../../../components/SearchContacts/SearchContacts';

const GirlTabs = ({ openModal, girlsUnreadCount, girlsSearchSource, ...props }) => {
  const [animationClass, setAnimationClass] = useState({
    girls: '',
  });
  const [searchContacts, setSearchContacts] = useState({
    query: '',
    list: [],
    isOpen: false,
  });
  const [donorContact, setDonorContact] = useState(null);

  const prevUnreadGirls = usePrevious(girlsUnreadCount);
  const prevGirlsSearchSource = usePrevious(girlsSearchSource);

  // To cancel axios http request
  const { newCancelToken, cancelPrevRequest } = useCancelToken();

  useDidMount(() => {
    if (props.searchGirls) {
      props.searchContactsByType(
        props.searchGirls,
        CONTACT_TABS.GIRLS,
        girlsSearchSource,
        newCancelToken(),
      );
    }

    if (props.girlsActiveFilter === ACTIVE_CHATS) {
      props.getActiveChats('girls')
    };
  });

  useDidUpdate(() => {
    if ((prevUnreadGirls !== girlsUnreadCount) && girlsUnreadCount) {
      setAnimationClass(prevState => ({ ...prevState, girls: '' }));
    }
    else {
      if (!girlsUnreadCount && prevUnreadGirls) {
        setAnimationClass({ ...animationClass, girls: '' });
      }
    }
  }, [girlsUnreadCount]);

  useDidUpdate(() => {
    startSearch(props.searchGirls);
  }, [girlsSearchSource]);

  useDidUpdate(() => {
    LS.setItem('girlsSearch', props.searchGirls);
  }, [props.searchGirls]);

  const updateGirlList = () => {
    if (props.searchGirls) {
      return props.updateSearchContactsByType;
    }
    else if (props.girlsActiveFilter === MARKED_FILTER) {
      return props.getMarkedContacts;
    }
    else if (props.girlsActiveFilter === UNREAD_FILTER) {
      return props.getContactsWithUnread;
    }
    else if (props.girlsActiveFilter === AVAILABLE_GIRLS_FILTER) {
      return props.getAvailableGirls;
    }
    else if (props.girlsActiveFilter === RELATED_FILTER) {
      return props.getRelatedContacts;
    }
    else if (props.girlsActiveFilter === SERVICES_FILTER) {
      return props.getOnlyHotels;
    }
    else if (props.girlsActiveFilter === AGENTS_FILTER) {
      return props.getOnlyAgents;
    }
    else if (props.girlsActiveFilter === NOT_TEXTED_FOR_LONG) {
      return props.getNotTextedForLong;
    }
    else if (props.girlsActiveFilter === ACTIVE_CHATS) {
      return props.getActiveChats;
    }
    return props.updateContactsList;
  };

  const blockContact = (contact) => {
    if (contact.blocked) {
      return props.toggleContactBlock(contact);
    }

    return openModal(MODAL_TYPES.blockForm, { contact: contact.id });
  };

  const generateContextItems = (contact) => {
    const items = props.girlsActiveFilter !== ACTIVE_CHATS
      ?[
        {
          text: `${contact.pinned ? 'Unp' : 'P'}in contact`,
          action: () => props.toggleContactPin(contact),
        },
        {
          text: `${contact.marked ? 'Unm' : 'M'}ark contact`,
          action: () => props.toggleContactMark(contact),
        },
        {
          text: `${contact.blocked ? 'Un' : 'B'}lock contact`,
          action: () => blockContact(contact),
        },
        {
          text: 'Merge contact to ...',
          action: () => {
            setSearchContacts({ ...searchContacts, isOpen: true })
            setDonorContact(contact);
          },
        },
        {
          text: `${contact.is_active_chat ? 'Remove from' : 'Add to'} active chats`,
          action: () => API.toggleContactActiveChat(contact),
        },
      ]
      :[
        {
          text: "Remove from active chats",
          action: () => API.toggleContactActiveChat(contact),
        },
      ];

    items.push();

    return items;
  };

  const formatTotalCount = count => count > 99999 ? "99999+" : count;

  const getFiltersByType = useCallback(() => {
    const filters = [
      { name: ALL_FILTER, action: () => props.removeContactFilter('girls') },
      { name: MARKED_FILTER, action: () => props.getMarkedContacts('girls') },
      { name: UNREAD_FILTER, action: () => props.getContactsWithUnread('girls') },
      { name: RELATED_FILTER, action: () => props.getRelatedContacts('girls') },
      { name: SERVICES_FILTER, action: () => props.getOnlyHotels('girls') },
      { name: AGENTS_FILTER, action: () => props.getOnlyAgents('girls') },
      { name: AVAILABLE_GIRLS_FILTER, action: () => props.getAvailableGirls('girls') },
      { name: NOT_TEXTED_FOR_LONG, action: () => props.getNotTextedForLong('girls') },
      { name: ACTIVE_CHATS, action: () => props.getActiveChats('girls') },
    ];

    return filters;
  }, []);

  console.log("RENDER GIRL TABS", animationClass);

  const stopSearch = useCallback(() => {
    cancelPrevRequest();
    props.stopContactsSearchByType('girls');
  }, []);

  const startSearch = useCallback((query) => {
    if (query !== props.searchGirls || prevGirlsSearchSource !== girlsSearchSource) {
      cancelPrevRequest();

      return props.searchContactsByType(query, 'girls', girlsSearchSource, newCancelToken());
    }
  }, [girlsSearchSource]);

  return (
    <Fragment>
      <Tabs>
        <div className="react-tabs__tab-list-container">
          <TabList>
            <Tab
              data-animationclass={animationClass.girls}
              className="react-tabs__tab react-tabs__tab--long"
            >
              <>
                Girls ({formatTotalCount(props.girlsTotalCount)})
                {girlsUnreadCount
                  ? <span
                    className="react-tabs__unread"
                    onDoubleClick={() => props.cleanContactsUnreadCount(CONTACT_TABS.GIRLS)}
                  >
                    {girlsUnreadCount > 99 ? '99+' : girlsUnreadCount}
                  </span>
                  : null
                }
              </>
            </Tab>
          </TabList>
        </div>

        <ChatListSearchFilterZone
          // initialQuery={getInitialQuery()}
          initialQuery={props.searchGirls}
          // savedLsQuery={savedLsQuery}
          activeTab={'girls'}
          startSearch={startSearch}
          stopSearch={stopSearch}
          filters={getFiltersByType()}
          activeFilter={props.activeFilter}
          defaultFilter={ALL_FILTER}
          showSearchSourceToggle
          showClearSearchBtn={true}
          searchSource={girlsSearchSource}
          toggleSearchSource={props.toggleSearchSource}
        />

        <TabPanel>
          <ChatList
            list={props.girls}
            pending={props.girlsPending}
            search={props.searchGirls}
            filter={props.girlsActiveFilter}
            updateList={updateGirlList()}
            limit={CONTACTS_LIMIT}
            type={2}

            chatListItem={ContactItem}
            contextMenu={Contextmenu}
            generateContextItems={generateContextItems}

            searchSource={girlsSearchSource}
          />
        </TabPanel>
      </Tabs>

      {searchContacts.isOpen &&
        <SearchContacts
          onClickAction={(acceptorContact) => openModal(MODAL_TYPES.mergeContacts, { donorContact, acceptorContact })}
          setSearchContacts={setSearchContacts}
          classPrefix="search-contacts"
          ignoredIds={[donorContact.id]}
          isPopup
          closeModal={() => setSearchContacts({ ...searchContacts, isOpen: false })}
          searchContacts={searchContacts}
          isGirlsTab={true}
        />
      }
    </Fragment >
  );
};


const mapStateToProps = state => ({
  girls: selectGirlsList(state, 2),
  girlsPending: state.contacts.girls.pending,
  girlsActiveFilter: state.contacts.girls.activeFilter,
  searchGirls: state.contacts.girls.search,
  girlsTotalCount: state.contacts.girls.totalCount,
  girlsUnreadCount: state.contacts.girls.unreadCount,
  activeFilter: state.contacts.girls.activeFilter,
  girlsSearchSource: state.contacts.girls.searchSource,
});

const mapDispatchToProps = {
  getAllContactsList,
  updateContactsList,
  updateSearchContactsByType,
  toggleContactPin,
  toggleContactMark,
  getContactsWithUnread,
  getMarkedContacts,
  getAvailableGirls,
  getRelatedContacts,
  toggleContactBlock,
  stopContactsSearchByType,
  searchContactsByType,
  removeContactFilter,
  getOnlyHotels,
  getOnlyAgents,
  getNotTextedForLong,
  getActiveChats,
  cleanContactsUnreadCount,
  openModal,
  toggleSearchSource,
};

export default connect(mapStateToProps, mapDispatchToProps)(GirlTabs);