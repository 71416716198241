import React, { useCallback, useRef, useEffect, useState } from 'react';

import API from '../../../../api/api';
import { useCancelToken, useDidMount, useToggle } from '../../../../hooks';
import { CONTACTS_LIMIT } from '../../../../ducks/contacts';

import './ParsedGirlList.scss';
import DropWrapper from '../../../../components/DropWrapper/DropWrapper';
import SearchableList from '../../../../components/SearchableList/SearchableList';
import ParsedGirlItem from './ParsedGirlItem/ParsedGirlItem';


const ParsedGirlList = ({ profileId, activeRecipient, parsedGirlsList, setParsedGirlsList, msgRef, ...props }) => {
  const [coords, setCoords] = useState({
    x: -9999,
    y: -9999,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmOnClose, toggleConfirmOnClose] = useToggle(false);

  const parsedGirlsListWrapperRef = useRef();

  // To cancel axios http request
  const { newCancelToken, cancelPrevRequest, isCancel } = useCancelToken();

  useEffect(() => {
    const msgCoords = msgRef.current.getBoundingClientRect();
    const parsedGirlsListWrapperHeight = parsedGirlsListWrapperRef.current.offsetHeight;

    setCoords({
      x: msgCoords.left + msgCoords.width + 20,
      y: window.innerHeight - msgCoords.top < parsedGirlsListWrapperHeight
        ? window.innerHeight - parsedGirlsListWrapperHeight - 5
        : msgCoords.top - 5
    })
  }, []);

  useDidMount(() => {
    if(!parsedGirlsList.list.length) {
      startSearchParsedGirls(parsedGirlsList.query);
    }
  })

  const startSearchParsedGirls = (query) => {
    cancelPrevRequest();

    setIsLoading(true);

    API.searchContacts(2, query, 0, CONTACTS_LIMIT, newCancelToken())
      .then(res => {
        props.addNewArrayGirlsToState(res.data);
        setParsedGirlsList({ ...parsedGirlsList, list: res.data, query });
        setIsLoading(false);
      })
      .catch(err => {
        if (!isCancel(err)) {
          setIsLoading(false);
        }
      });
  };

  const loadMoreParsedGirls = (offset) => {
    API.searchContacts(2, parsedGirlsList.query, offset)
      .then(res => {
        props.addNewArrayGirlsToState(res.data);

        setParsedGirlsList({ ...parsedGirlsList, list: [...parsedGirlsList.list, ...res.data] })
      });
  };

  const loadMore = useCallback((offset) => loadMoreParsedGirls(offset), [parsedGirlsList]);

  const styles = {
    left: coords.x,
    top: coords.y
  }

  const bookingsListStyles = {
    inset: 0,
    margin: 'auto',
  }

  return (
    <DropWrapper
      dropWrapperRef={parsedGirlsListWrapperRef}
      className="parsed-girls-list__drop-wrap"
      style={props.isParsedGirlsListModal ? bookingsListStyles : styles}
      onClose={() => isConfirmOnClose}
      closeDropWrapper={() => setParsedGirlsList({ ...parsedGirlsList, isOpen: false })}
    >
      <div ref={parsedGirlsListWrapperRef} className="parsed-girls-list__wrap">
        <span className="parsed-girls-list__input-title">Search</span>
        <SearchableList
          classPrefix="parsed-girls-list"
          list={parsedGirlsList.list}
          initialQuery={parsedGirlsList.query}
          listLimit={20}
          scrollInitialPosition='top'
          loadMore={loadMore}
          listItem={ParsedGirlItem}
          listItemProps={{
            updateActiveContact: props.updateActiveContact,
            closeModal: () => setParsedGirlsList({ ...parsedGirlsList, isOpen: false }),
            activeRecipientId: activeRecipient.id,
            profileId: profileId,
            toggleConfirmOnClose,
            isParsedGirlsListModal: props.isParsedGirlsListModal
          }}
          startSearch={startSearchParsedGirls}
          stopSearch={startSearchParsedGirls}
          listLoadPending={isLoading}
        />
        <span onClick={() => setParsedGirlsList({ ...parsedGirlsList, isOpen: false })} className="parsed-girls-list__close" />
      </div>
    </DropWrapper>
  )
};

export default ParsedGirlList;