import React, { useState } from 'react';
import API from '../../../api/api';
import { useHideTemporaryTooltip, useToggle } from '../../../hooks';
import ResizableTextarea from '../../ResizableTextarea/ResizableTextarea';
import Toggle from '../../Toggle/Toggle';

const RequestItem = ({ booking_id, isNewRequest, ...props }) => {
	const [customError, setCustomError] = useHideTemporaryTooltip('', 1500);
	const [selectedRequest, setSelectedRequest] = useState(props.selectedRequest);
	const [isEditable, toggleEditable] = useToggle(false);

	const handleRequestChange = (e) => {
		const value = e.target.value;

		setSelectedRequest(prev => ({
			...prev,
			text: value,
		}));
	};

	const handleDescriptionChange = (e) => {
		const value = e.target.value;

		setSelectedRequest(prev => ({
			...prev,
			description: value,
		}));
	};

	const toggleSuccess = () => {
		setSelectedRequest(prev => ({
			...prev,
			is_success: !prev.is_success,
		}));
	};

	const handleCancel = () => {
		setSelectedRequest(props.selectedRequest);

		toggleEditable();
	};

	const handleCreate = () => {
		if (!selectedRequest.text.trim()) {
			return setCustomError('Request is required!');
		}

		if (booking_id) {
			API.addBookingRequest({ ...selectedRequest, booking_id })
				.then(({ data }) => {
					props.onSubmit && props.onSubmit(data);
				})
		}
		else {
			props.setSelectedRequest(selectedRequest)
		}
	}

	const handleUpdate = () => {
		if (!selectedRequest.text.trim()) {
			return setCustomError('Request is required!');
		}

		if (booking_id) {
			API.updateBookingRequest(selectedRequest)
				.then(({ data }) => {
					toggleEditable();
					props.onSubmit && props.onSubmit(data);
				});
		}
		else {
			props.setSelectedRequest(selectedRequest);
			toggleEditable();
		}
	};

	const handleDelete = () => {
		if (booking_id) {
			API.deleteBookingRequest(props.selectedRequest.id)
				.then(({ data }) => {
					props.onSubmit && props.onSubmit(data);
				})
		}
		else {
			props.setSelectedRequest({
				text: '',
				is_success: 1,
				description: ''
			});
			setSelectedRequest({
				text: '',
				is_success: 1,
				description: ''
			});
		}
	}

	const renderBtns = () => {
		if (isNewRequest) {
			return (
				<button
					className="booked-editor__btn booked-editor__btn--primary"
					onClick={handleCreate}
				>
					Create
				</button>
			)
		}
		else if (isEditable) {
			return (
				<>
					<button
						className="booked-editor__btn booked-editor__btn--primary"
						onClick={handleUpdate}
					>
						Update
        		</button>

					<button
						className="booked-editor__btn booked-editor__btn--danger"
						onClick={handleCancel}
					>
						Cancel
        		</button>
				</>
			)
		}
		else {
			return (
				<>
					<button
						className="booked-editor__btn booked-editor__btn--primary"
						onClick={toggleEditable}
					>
						Edit
        		</button>
					<button
						className="booked-editor__btn booked-editor__btn--danger"
						onClick={handleDelete}
					>
						Delete
        		</button>
				</>
			)
		}
	}

	return (
		<>
			<div className="booked-editor__field booked-editor__field--message">
				<div className="booked-editor__field-title">Request</div>
				<ResizableTextarea
					disabled={!isNewRequest && !isEditable}
					value={selectedRequest.text}
					onChange={handleRequestChange}
				/>
			</div>
			<div className="booked-editor__field booked-editor__field--success">
				<div className="booked-editor__field-title">Success</div>
				<Toggle
					isActive={selectedRequest.is_success}
					onToggle={toggleSuccess}
					isDisabled={!isNewRequest && !isEditable}
				/>
			</div>
			<div className="booked-editor__field booked-editor__field--message">
				<div className="booked-editor__field-title">Description</div>
				<ResizableTextarea
					disabled={!isNewRequest && !isEditable}
					value={selectedRequest.description || ''}
					onChange={handleDescriptionChange}
				/>
			</div>
			<div className="booked-editor__additional-actions">
				{renderBtns()}
			</div>

			{!!customError &&
				<span className="booked-editor__tooltip">
					{customError}
				</span>
			}
		</>
	)
}

export default RequestItem;