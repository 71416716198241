import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import Creatable from 'react-select/creatable';

import API from '../../../../../api/api';
import { useDidMount } from '../../../../../hooks';
import { classModifier } from '../../../../../utils';
import { updateContact } from '../../../../../ducks/contacts';

import './EditContactTelsForm.scss';
import SvgIcon from '../../../../../components/SvgIcon/SvgIcon';
import Spinner from '../../../../../components/UI/Spinner/Spinner';


const EditContactTelsForm = props => {
  const {
    activeRecipient,
  } = props;
  const [serverError, setServerError] = useState({});
  const [initialValues, setInitialValues] = useState({});
  const [phoneLabels, setPhoneLabels] = useState([]);
  const [isMount, setMount] = useState(true);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: '1px solid #0092f2',
      fontSize: '13px'
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 10,
      backgroundColor: '#f6f6f6',
      fontSize: '13px',
    }),
    dropdownIndicator: () => ({
      padding: '2px',
      color: '#808080'
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: '12px',
      margin: 0,
    })
  };

  useDidMount(() => {
    API.getPhoneLabels()
      .then(({ data }) => {
        let options = [];
        let defaultLabel = '';

        if (data.length) {
          data.map((label => {
            if (!!label) {
              options.push({ value: label, label })
            }
          }));

          defaultLabel = data[0];
        }
        else {
          options.push({ value: 'default', label: 'default' });

          defaultLabel = 'default';
        }

        setPhoneLabels(options);

        if (!activeRecipient.tels || !activeRecipient.tels.length) {
          setInitialValues({
            tels: [{ default: 1, labelName: defaultLabel }],
          });
        }
        else {
          setInitialValues({
            tels: activeRecipient.tels.map(tel => ({ ...tel }))
          });
        }

        setMount(false);
      })
      .catch(err => console.log(err));
  });

  const addNewField = useCallback((fields) => {
    if (fields.length >= 10) {
      return;
    }

    fields.push({
      default: 0,
      labelName: phoneLabels[0].value
    });
  }, [phoneLabels]);

  const updateContact = (values) => {
    const updatedContact = {
      fn: activeRecipient.fn,
      id: activeRecipient.id,
      type: activeRecipient.type,
      tels: values.tels,
    }

    props.updateContact(updatedContact, activeRecipient.type)
      .then(() => {
        props.onClose();
      })
      .catch(err => {
        setServerError(JSON.parse(err.response.data.message));
      })
  };

  const telLabelsChange = (props, fields, index) => {
    fields.update(index, {
      ...fields.value[index],
      labelName: props.value,
    });
  };

  const changeDefaultNumber = (fields, index) => {
    const currentDefault = fields.value.findIndex(tel => tel.default);

    if (currentDefault === index) {
      return;
    }

    if (currentDefault !== -1) {
      fields.update(currentDefault, {
        ...fields.value[currentDefault],
        default: 0,
      });
    }
    fields.update(index, {
      ...fields.value[index],
      default: 1,
    });
  };

  const isErrorExist = (fieldArrayMeta, inputMeta, index) => {
    const { error } = fieldArrayMeta;
    const isValidationError = error && error.length && error[index] && error[index].error;

    if (isValidationError && inputMeta.touched && !inputMeta.active) {
      return 'client';
    }
    else if (!isValidationError && serverError && serverError.tels && serverError.tels[index]) {
      return 'server';
    }

    return null;
  };

  const hideServerErrorOnChange = (e, input, index) => {
    input.onChange && input.onChange(e);

    serverError.tels && setServerError((prev) => {
      const updatedServerError = JSON.parse(JSON.stringify(prev));

      delete updatedServerError.tels[index];

      return updatedServerError;
    });
  };

  if (isMount) {
    return null;
  }

  return (
    <Form
      onSubmit={updateContact}
      validate={validate}
      initialValues={initialValues}
      mutators={{
        ...arrayMutators
      }}
      render={({ handleSubmit, pristine, form, submitting, values }) => {
        return (
          <form
            onSubmit={handleSubmit}
            className="contact-tels-form"
          >
            <div className="contact-tels-form__title">Add a phone number for a contact line</div>

            <FieldArray name="tels" >
              {({ fields, meta: fieldArrayMeta }) => (
                <>
                  {fields.map((tel, index) => {
                    const field = fields.value[index];

                    return (
                      <div className="contact-tels-form__field" key={index}>
                        <div
                          className={classModifier("contact-tels-form__radio", [
                            field.default && 'checked',
                          ])}
                          onClick={() => changeDefaultNumber(fields, index)}
                        />

                        <Field
                          name={`${tel}.tel`}
                          type="text"
                        >
                          {({ input, meta }) => (<>

                            {phoneLabels.length ?
                              <Creatable
                                styles={customStyles}
                                className={classModifier("contact-tels-form__select-tel-label", [
                                  !fields.value[index].default && 'removable'
                                ])}
                                value={phoneLabels.find(option => fields.value[index].labelName === option.label)}
                                options={phoneLabels}
                                placeholder='Phone type'
                                onChange={(e) => telLabelsChange(e, fields, index)}
                              />
                              : <div className={classModifier("contact-tels-form__select-tel-label", ['pending'])}>
                                <Spinner spinnerSize="30px" />
                              </div>
                            }

                            <input
                              {...input}
                              className={classModifier("contact-tels-form__input", [
                                !field.default && 'removable',
                                isErrorExist(fieldArrayMeta, meta, index) && 'error',
                              ])}
                              placeholder={`Phone${index + 1}`}
                              autoComplete='off'
                              onChange={e => hideServerErrorOnChange(e, input, index)}
                            />

                            {isErrorExist(fieldArrayMeta, meta, index) &&
                              <div className="form-error-text__wrap">
                                <div className="form-error-text">
                                  {isErrorExist(fieldArrayMeta, meta, index) === 'server'
                                    ? serverError.tels[index]
                                    : fieldArrayMeta.error[index].error
                                  }
                                </div>
                              </div>
                            }
                          </>)}
                        </Field>

                        {!field.default &&
                          <button
                            type="button"
                            title="Remove Number"
                            className="contact-tels-form__remove-field"
                            onClick={() => {
                              fields.remove(index);
                            }}
                          />
                        }
                      </div>
                    )
                  })}

                  <button
                    type="button"
                    onClick={() => addNewField(fields)}
                    className="contact-tels-form__add-field"
                  >
                    <SvgIcon icon="plus" width="18" height="18" fill="#0092F2" title="add tel" />

                    Add number
                  </button>
                </>
              )}
            </FieldArray>

            <div className="contact-tels-form__actions">
              <button
                className="contact-tels-form__cancel"
                type='button'
                onClick={() => props.onClose()}
              >
                Cancel
              </button>

              <button
                className="contact-tels-form__submit"
                type='submit'
                disabled={submitting || pristine}
              >
                Set the Line
              </button>
            </div>
          </form>
        )
      }}
    />
  )
}

const validate = ({ tels }) => {
  const errors = {
    tels: [],
  };

  if (tels) {
    tels.forEach((currentTel, index) => {
      const { tel } = currentTel;

      if (!tel) {
        return errors.tels[index] = { error: 'Enter the phone number' };
      }
      const numbers = /^\d+$/;
      const isCorrent = tel[0] === '+'
        ? numbers.test(tel.slice(1))
        : numbers.test(tel);

      if (!isCorrent) {
        return errors.tels[index] = { error: 'Incorrect phone number' };
      }
      const countOfCoincidence = tels.filter(t => t.tel === tel).length;

      if (countOfCoincidence > 1) {
        return errors.tels[index] = { error: 'Phone number has already exist' };
      }
    })
  }

  return errors;
};

const mapDispatchToProps = {
  updateContact,
}

export default connect(null, mapDispatchToProps)(EditContactTelsForm);