import React from 'react';

import './Spinner.scss';

// const Spinner = ({ spinnerSize = "100%" }) => (
//   <div
//     src="/media/spinner.gif"
//     style={{ width: spinnerSize, height: spinnerSize }}
//     className="spinner"
//     alt="Loading..." />
// );
const Spinner = ({ className, spinnerSize = "100%" }) => (
  <img
    src="/media/spinner.gif"
    style={{ width: spinnerSize, height: spinnerSize }}
    className={className}
    alt="Loading..." />
);

export default Spinner;