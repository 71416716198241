import React from 'react';
import { connect } from 'react-redux';

import './OffTodayList.scss';

import API from '../../../../api/api';
import { useDidMount } from '../../../../hooks';
import { addContactsToEntities } from '../../../../ducks/contacts';
import { setRoomPending, addOffTodayGirls } from '../../../../ducks/rooms';
import { CONTACT_TYPES } from '../../../../config/constants';

import Spinner from '../../../../components/UI/Spinner/Spinner';
import ContactItem from '../../../Sidebar/components/ChatList/ContactItem';
import Scrollbars from 'react-custom-scrollbars';

const OffTodayList = ({ offTodayIds, pending, ...props }) => {
  useDidMount(() => {
    props.setRoomPending();

    API.getGirlsWithStatus('off')
      .then(({ data }) => {
        props.addContactsToEntities(data.map(offTodayItem => offTodayItem.caller));
        props.addOffTodayGirls(data);
      })
      .catch(console.log);
  });

  if (pending) {
    return (
      <div className="off-today__pending">
        <Spinner spinnerSize="30px"/>
      </div>
    )
  }

  if (!offTodayIds.length) {
    return (
      <span className="off-today__no-items">
        no items
      </span>
    )
  }

  return (
    <div className="off-today__wrap">
      <ul className="off-today__list">
        <Scrollbars autoHide>
          {offTodayIds.map(id => (
            <li
              className="off-today__list--item"
              key={id}
            >
              <ContactItem
                id={id}
                type={CONTACT_TYPES.GIRL}
                isSimpleView // don`t show pinned and marked, also we can hide another elements and functionality
                isFromListOfGirlsWithStatus
              />
            </li>
          ))}
        </Scrollbars>
      </ul>
    </div>
  );
};

const mapStateToProps = state => ({
  offTodayIds: state.rooms.offTodayIds,
  pending: state.rooms.pending,
});

const mapDispatchToProps = {
  setRoomPending,
  addOffTodayGirls,
  addContactsToEntities,
};

export default connect(mapStateToProps, mapDispatchToProps)(OffTodayList);