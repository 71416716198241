import React, { useState, Fragment, useEffect } from 'react';
import { connect } from 'react-redux';

import { getContactsById } from '../../ducks/contacts';
import { killConnection, callTo, acceptConnection } from '../../ducks/twilio';
import {
  hangupCallOnHold,
  unholdCall,
  acceptTransferedCall,
  acceptIncomingQueueCall,
  putCallOnHold,
  createTransferedCall,
  createWarmTransferCall,
} from '../../ducks/calls';
import { webrtcAcceptIncomingCall, webrtcToggleMuteActiveCall, onHoldActiveConference } from '../../ducks/webrtc';
import { removeMissedCallForAll, removeMissedCall } from '../../ducks/missedCalls';
import { updateActiveContact } from '../../ducks/clientChats';
import { updateActiveChat } from '../../ducks/roomChats';
import { playMedia, openModal, MODAL_TYPES, closeModal } from '../../ducks/activeWindows';
import { selectActiveWebrtcConference, selectContactFromEntities, selectRoomIdByOperatorId } from '../../selectors/selectors';
import API from '../../api/api';
import { getContactAvatar, isEmptyObj, classModifier } from '../../utils';

import './CallItem.scss';
import SvgIcon from '../SvgIcon/SvgIcon';
import CallTimer from './CallTimer';
import LazyLoadImage from '../LazyLoadImage/LazyLoadImage';
import CallTransferForm from './CallTransferForm';
import Menu from '../Menu/Menu';
import AudioPlayer from '../AudioPlayer/AudioPlayer';
import { useDidMount } from '../../hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophoneSlash, faMicrophone } from '@fortawesome/free-solid-svg-icons';
import TimeAgo from '../TimeAgo';
import Spinner from '../UI/Spinner/Spinner';


const CallItem = (props) => {
  const {
    call,
    connectedCaller,
    mainTimer,
    queueTimer,
    onTransfer,
    inQueue,
    onHold,
    missed,
    active,
    activeFullScreen,
    chatId,
    activeCall,
    noMicro = false,
  } = props;

  const [transferCall, setTransferCall] = useState({
    type: null,
    isPersonal: false,
    showForm: false
  });

  const [menu, setMenu] = useState([]);

  const isOperator = connectedCaller.type === 9;

  useDidMount(() => {
    if ((!connectedCaller || !connectedCaller.id) && call.caller) {
      props.getContactsById(call.caller.id, call.caller.type);
    }
  });

  useEffect(() => {
    if (active && isOperator) {
      const confirm = function (e) {
        console.log("beforeunload with active call");
        e.returnValue = "You have active call";
      };

      window.addEventListener("beforeunload", confirm);

      return () => {
        window.removeEventListener("beforeunload", confirm);
      };
    };
  }, [connectedCaller, active]);

  useEffect(() => {
    if (!isEmptyObj(connectedCaller) && !isEmptyObj(props.connection)) {
      setMenu(generateMenu());
    }
  }, [connectedCaller, props.connection, activeCall.isMute]);

  const hangUp = (e) => {
    e.preventDefault();

    if (isOperator) {
      if (call.status === 'ringing') {
        API.dismissWebrtcCall(call.id)
          .catch(console.log);
      }
      else {
        API.hangUpWebrtcCall(call.id)
          .catch(console.log);
      }
    }
    else {
      if (onHold) {
        return hangupCallOnHold(call);
      }
      console.log('kill-----');
      killConnection(props.connection);
    }
  };

  const pickUp = (e) => {
    e.preventDefault();

    if (props.activeConference) {
      props.onHoldActiveConference(props.activeConference, props.peerConnections, props.localStream);
    };

    if (isOperator) {
      props.webrtcAcceptIncomingCall(call.id, connectedCaller.id);
    }
    else if (onHold) {
      props.device.connect({
        interactionId: call.id,
        action: 'unhold',
      });

      props.unholdCall(call);
    }
    else if (inQueue && call.callBegin) {
      props.device.connect({
        interactionId: call.id,
        action: 'incoming_queue'
      });

      props.acceptIncomingQueueCall(call);
    }
    else if (onTransfer) {
      props.acceptTransferedCall(call);
    }
    else if (missed) {
      props.callTo(connectedCaller);
      props.removeMissedCallForAll(call.id, connectedCaller.type);
    }
    else {
      acceptConnection(props.connection);
    }
  };

  const toggleMuteCall = () => {
    if (isOperator && active) {
      props.webrtcToggleMuteActiveCall(!activeCall.isMute, props.localStream);
    }
    else {
      props.device.activeConnection().isMuted()
        ? props.device.activeConnection().mute(false)
        : props.device.activeConnection().mute(true);
    }
  }


  const generateMenu = () => {
    const menu = [];

    const menuTemplate = {
      hangUp: {
        name: 'Drop The Call',
        action: hangUp,
      },
      sendMsg: {
        name: 'Send Message',
        action: () => {
          if (missed) {
            // props.showVoicemails(false);
          }
          isOperator
            ? props.updateActiveChat(chatId)
            : props.updateActiveContact(connectedCaller);
        }
      },
      redirect: {
        name: 'Redirect With Message',
        action: () => toggleTransferForm('cold', true)
      },
      parkingCall: {
        name: 'Call Parking',
        action: () => props.createTransferedCall(props.call) //TODO: remove dispatch
      },
      putOnHold: {
        name: 'Put On Hold',
        action: () => props.putCallOnHold(call)
      },
      warmTransfer: {
        name: 'Warm transfer',
        action: () => toggleTransferForm('warm')
      },
    };

    if (call.caller) {
      menu.push(menuTemplate.sendMsg);
    }

    if (active && call.caller) { // transfer not allowable for calls between dispatchers
      menu.push(
        // menuTemplate.putOnHold,
        menuTemplate.parkingCall,
        // menuTemplate.redirect,
        menuTemplate.warmTransfer,
      );
    }
    else if (onHold || inQueue) {
      menu.push(menuTemplate.hangUp);
    }
    if (activeFullScreen) {
      menu.splice(0);

      menu.push(
        menuTemplate.redirect,
        // menuTemplate.toggleMuteCall,
        menuTemplate.warmTransfer,
      );
    }
    return menu;
  };

  const toggleFullScreenCall = () => {
    const {
      id: callerId,
      type: chatType,
    } = connectedCaller;

    props.openModal(MODAL_TYPES.fullScreenCall, { callerId, chatType });
  };

  const toggleTransferForm = (type = null, isPersonal = false) => {
    setTransferCall(prevTransferCall => ({
      showForm: !prevTransferCall.showForm,
      type,
      isPersonal
    }));
  };

  const onVoicemailPlay = (audio, voicemail) => {
    props.playMedia(audio);

    if (voicemail.listened) {
      return;
    }

    API.markVoicemailAsListened(voicemail.id);
  };


  const renderActions = () => (
    <div className="call-item__btns">
      {!isOperator &&
        <>
          <button
            className="call-item__btn"
            onClick={() => props.putCallOnHold(call)}>
            <svg
              version="1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="1 -28 512 511"
              fill='#F2CC0C'
              width="25px"
              height="25px">
              <path d="M193 0h-75c-17 0-31 14-31 31v458c0 17 14 30 31 30h75c17 0 31-13 31-30V31c0-17-14-31-31-31zM402 0h-76c-17 0-31 14-31 31v458c0 17 14 30 31 30h76c16 0 30-13 30-30V31c0-17-14-31-30-31z" />
            </svg>
          </button>

          <button
            className="call-item__btn"
            onClick={() => toggleTransferForm('cold')}>

            <SvgIcon icon="arrows" fill="#0092F2" width="25px" title="transfer" />
          </button>
        </>
      }

      <button
        onClick={hangUp}
        className="call-item__btn">
        <SvgIcon icon="phone" width="25px" height="25px" fill="#F8737F" title="hang up" />
      </button>

      {transferCall.showForm &&
        <CallTransferForm
          activeFullScreen
          transferType={transferCall.type}
          call={call}
          onClose={toggleTransferForm}
          createTransferedCall={props.createTransferedCall}
          createWarmTransferCall={props.createWarmTransferCall}
          closeModal={props.closeModal}
        />
      }
    </div>
  );

  // const renderSubBtn = () => {
  //   if (missed) {
  //     return
  //     //  (
  //     // <button
  //     //   onClick={() => props.removeMissedCall(call.id)}
  //     //   className="call-item__btn call-item__btn--remove">
  //     // </button>
  //     // )
  //   }
  //   else if (onTransfer) {
  //     return renderTransferDescription();
  //   }
  //   return (
  //     <button
  //       onClick={hangUp}
  //       disabled={onTransfer || inQueue}
  //       className="call-item__btn call-item__btn--finish">
  //       <SvgIcon icon="phone" width="20px" height="20px" fill="#F8737F" />
  //     </button>
  //   )
  // }

  // const renderTransferDescription = () => (
  //   <Fragment>
  //     <button className="call-item__btn call-item__btn--desc">
  //       <SvgIcon icon="info" width="16" height="16" fill="#000" />
  //     </button>

  //     <div className="call-item__desc">
  //       <Scrollbars
  //         hideTracksWhenNotNeeded
  //         autoHeight
  //         autoHeightMin={20}
  //         autoHeightMax={42} >

  //         <div><b>from:</b> {call.queueData.sender.username}</div>

  //         {call.queueData.description &&
  //           <Fragment><b>desc:</b> {call.queueData.description}</Fragment>
  //         }

  //       </Scrollbars>
  //     </div>
  //   </Fragment>
  // );


  const renderMainBtn = () => {
    if (activeFullScreen || active) { return; }

    if (active) {
      return (
        <button
          onClick={hangUp}
          // disabled={onTransfer || inQueue}
          className="call-item__btn call-item__btn--finish">
          <SvgIcon icon="phone" width="20px" height="20px" fill="#F8737F" title="hang up" />
        </button>
      );

    }
    else {
      return (
        <>
          {call.voicemail &&
            <button
              className="call-item__btn call-item__btn--voice"
              disabled={activeCall.status === 'in-progress' && (onHold || onTransfer || inQueue || missed)}
              onClick={pickUp}>
              <svg
                height="16px"
                width="16px"
                viewBox="1 -28 512 511"
                xmlns="http://www.w3.org/2000/svg"
                fill={call.voicemail.listened ? "#000" : "#0092F2"}>
                <path d="M457 1H55C25 1 0 25 0 56v305c0 30 25 55 55 55h155l35 36a15 15 0 0 0 22 0l35-36h155c30 0 55-25 55-55V56c0-31-25-55-55-55zm25 360c0 14-11 25-25 25H296c-4 0-8 2-10 4l-30 30-30-30c-2-2-6-4-10-4H55c-14 0-25-11-25-25V56c0-14 11-26 25-26h402c14 0 25 12 25 26zm0 0" />
                <path d="M359 136a72 72 0 0 0-58 115h-90a72 72 0 1 0-59 30h207a72 72 0 0 0 0-145zm-249 72a42 42 0 1 1 85 0 42 42 0 0 1-85 0zm249 43a42 42 0 1 1 0-85 42 42 0 0 1 0 85zm0 0" />
              </svg>
            </button>
          }

          <button
            className="call-item__btn call-item__btn--call"
            disabled={(activeCall.status === 'in-progress'
              && (onHold || onTransfer || inQueue || missed))
              || noMicro
              || (isOperator && !isEmptyObj(activeCall))}
            onClick={pickUp}>
            <SvgIcon icon="phone" width="25px" height="25px" fill="#01DF85" title="pick up" />
          </button>
        </>
      );
    }

  };

  const renderCallTime = () => (
    props.missed
      ? (
        <p className="call-item__duration">
          <TimeAgo minPeriod={60} date={call.dateCreated} />
        </p>
      )
      : (
        <div className="call-item__timers">
          {mainTimer &&
            <CallTimer
              timer={mainTimer}
            />
          }
          {queueTimer &&
            <CallTimer
              className="call-item__timer--hold"
              timer={queueTimer}
            />
          }
        </div>
      )
  );

  const getContactName = () => {
    if (missed) {
      return connectedCaller.short_name || connectedCaller.fn || connectedCaller.username;
    }
    if (connectedCaller && (connectedCaller.short_name || connectedCaller.fn || connectedCaller.username)) {
      return connectedCaller.short_name || connectedCaller.fn || connectedCaller.username;
    }
    return "Unknown number";
  };

  const alarmClass = () => {
    if (onHold) {
      return queueTimer && queueTimer.counter >= 10000
        ? "alarm"
        : "";
    }
    else if (onTransfer) {
      return "transfer";
    }
    else {
      return false;
    }
  };

  const renderBtnMute = () => {
    if (!active) return null;

    return (
      call.isMute
        ? (
          <button
            onClick={() => toggleMuteCall()}
            className='call-item__btn-microphone'
          >
            <FontAwesomeIcon
              icon={faMicrophoneSlash}
              title="mute"
              color="#0092F2"
            />
          </button>
        )
        : (
          <button
            onClick={() => toggleMuteCall()}
            className='call-item__btn-microphone'
          >
            <FontAwesomeIcon
              title="unmute"
              icon={faMicrophone}
              color="#0092F2"
            />
          </button>
        )
    )
  };

  return (
    <Fragment>
      <div className={
        classModifier("call-item", [
          alarmClass() && alarmClass(),
          active && "active",
          inQueue && "incoming"
        ])}>
        <div className="call-item__main">
          <div
            onClick={isOperator ? null : () => toggleFullScreenCall()}
            className="call-item__img-wrap"
          >
            <LazyLoadImage src={getContactAvatar(connectedCaller)} alt="ava" className="call-item__img" />
          </div>

          <div className="call-item__info">
            <p className="call-item__number" onClick={isOperator ? null : () => toggleFullScreenCall()} >
              {getContactName()}

              {isOperator &&
                <b className="call-item__operator">Operator</b>
              }
            </p>


            {(active && activeCall.status === 'connecting')
              ? <div className="call-item__spinner-wrap">
                connecting
                    <Spinner spinnerSize='14px' />
              </div>
              : renderCallTime()
            }
          </div>

          {renderBtnMute()}

          {!isOperator &&
            <Menu
              menuItems={menu}
              menuPosition="fixed-center"
            />
          }

        </div>

        {renderMainBtn()}

      </div>

      {call.voicemail &&
        <AudioPlayer
          onPlay={(audio) => onVoicemailPlay(audio, call.voicemail)}
          hideVolume
          src={call.voicemail.url} />
      }


      {active && renderActions()}

    </Fragment >
  );
};


const mapStateToProps = (state, ownProps) => ({
  connectedCaller: selectContactFromEntities(state, ownProps.caller.id, ownProps.caller.type) || {},
  activeCall: state.calls.activeCall,
  device: state.twilio.device,
  connection: state.twilio.connection,
  mainTimer: state.timers[ownProps.call.id],
  queueTimer: state.timers['queue_' + ownProps.call.id],
  chatId: selectRoomIdByOperatorId(state, ownProps.caller.id),
  localStream: state.webrtc.localStream,
  peerConnections: state.webrtc.peerConnections,
  activeConference: selectActiveWebrtcConference(state),
});

const mapDispatchToProps = {
  unholdCall,
  acceptTransferedCall,
  acceptIncomingQueueCall,
  putCallOnHold,
  createTransferedCall,
  updateActiveContact,
  removeMissedCall,
  callTo,
  onHoldActiveConference,
  removeMissedCallForAll,
  getContactsById,
  updateActiveChat,
  playMedia,
  createWarmTransferCall,
  openModal,
  closeModal,
  webrtcAcceptIncomingCall,
  webrtcToggleMuteActiveCall,
};

export default connect(mapStateToProps, mapDispatchToProps)(CallItem);