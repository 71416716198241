import React, { useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { connect } from 'react-redux';

import ClientSessionChat from '../../../Chat/ClientSessionChat';
import NightBotSessionElement from '../NightBotSessionElement/NightBotSessionElement';
import GirlSessionChat from '../../../Chat/GirlSessionChat';
import Spinner from '../../../../components/UI/Spinner/Spinner';

import './NightBotReportModal.scss';
import API from '../../../../api/api';
import { useDidMount } from '../../../../hooks';
import { initSessionChats } from '../../../../ducks/saleSessionChats';
import { getContactsById } from '../../../../ducks/contacts';
import { closeModal, MODAL_TYPES } from '../../../../ducks/activeWindows';
import { CHAT_TYPES } from '../../../../config/constants';

const NightBotReportModal = (props) => {

  const {
    sessionId,
  } = props;

  const [sessions, setSessions] = useState({
    data: null,
    chatStatus: 'hide'
  });

  const [recipientIds, setRecipientIds] = useState({
    callerId: null, 
    profileId: null
  })

  useDidMount(() => {
    API.getNightBotReport()
      .then(res => setSessions({
        ...sessions,
        data: res.data.sessions
      }))
      .catch(console.log);
  });

  const initChats = (callerId, profileId) => {
    props.initSessionChats(callerId, [profileId], profileId);
  };

  const handleDeleteSession = (id) => {
    if(window.confirm(`Remove session?`)) {
      API.deleteNightBotReportSession(id)
        .then(res => {
          if(res.data) {
            const data = sessions.data.filter(session => session.id !== id);

            setSessions({
              ...sessions,
              data,
              chatStatus: 'hide'
            })

            props.onChangeSessionCount(data.length);

            if (!data.length) {
              props.closeModal(MODAL_TYPES.nightBotReport);
            }
          }
        })
        .catch(console.log)
    }
  };

  const handleSelectSession = (profileId, callerId) => {
    if (!props.contactList[profileId] || !props.contactList[callerId]) {
      props.getContactsById([profileId, callerId], CHAT_TYPES.GIRL)
        .finally(() => {
          initChats(callerId, profileId);
        });
      } 
    else {
      initChats(callerId, profileId);
    }

    if(!props.isCallerChatActive || !props.isGirlChatActive) {
      setRecipientIds({
        profileId, callerId
      });
    }

    if (sessions.chatStatus === 'hide') { 
      setSessions({ ...sessions, chatStatus: 'show' }) 
    }
  }

  const renderModalContent = () => {
    return (
      <section className="night-bot-modal__chats">
        <ClientSessionChat 
          chatStatus={sessions.chatStatus}
          sessionId={sessionId}
          id={recipientIds.callerId}
          />

        {sessions.data ? 
          <div className="night-bot-modal__scrollbar-wrapper">
            <Scrollbars
              renderThumbVertical={() => <div className="sale-sidebar__scrollbar-thumb" />}
              renderTrackHorizontal={() => <div style={{ display: 'none' }}></div>}
              >

              <ul className="session-list">
                {sessions.data.map(session => {
                  return (
                    <NightBotSessionElement 
                      key={session.id}
                      sessionData={session}
                      onDeleteSession={handleDeleteSession}
                      onChangeChat={handleSelectSession}
                      />
                    )
                })}
              </ul> 

            </Scrollbars>
          </div>
        : <Spinner spinnerSize={50} />}

        <GirlSessionChat 
          chatStatus={sessions.chatStatus}
          sessionId={sessionId}
          id={recipientIds.profileId}
          />
      </section>
    )
  }

  return (
    <div className='night-bot-modal'>
      <button
          className="night-bot-modal__btn--close"
          onClick={() => props.closeModal(MODAL_TYPES.nightBotReport)}
        >
          (Esc)
      </button>
      {renderModalContent()}
    </div>
  )
}

const mapStateToProps = (state) => ({
  contactList: state.contacts.entities,
  isCallerChatActive: state.saleSessionChats.clients.active,
  isGirlChatActive: state.saleSessionChats.girls.active,
});

const mapDispatchToProps = {
  closeModal,
  getContactsById,
  initSessionChats,
}

export default connect(mapStateToProps, mapDispatchToProps)(NightBotReportModal);