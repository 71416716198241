import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { getContactAvatar } from '../../../../utils';
import useToggle from '../../../../hooks/useToggle';
import API from '../../../../api/api';

import './ChatRoomHeader.scss';
import LazyLoadImage from '../../../../components/LazyLoadImage/LazyLoadImage';
import RoomDetails from './RoomDetails';
import SvgIcon from '../../../../components/SvgIcon/SvgIcon';
import { ROOM } from '../../../../config/constants';
import RoomParticipantTag from './RoomParticipantTag';


const RoomInfoCard = ({ activeRecipient, profile, operators, girlsTodayMsgCount, user, ...props }) => {
  const [isShowRoomInfo, toggleRoomInfo] = useToggle(false);

  const [room, setRoom] = useState({
    name: activeRecipient.chatTitle,
    photo: activeRecipient.photo ? activeRecipient.photo.min : null,
    photoFile: null,
  });

  const isIamAdmin = activeRecipient.adminsIds.some(id => id === user.id);

  useEffect(() => {
    toggleRoomInfo(false);

    setRoom({
      ...room,
      name: activeRecipient.chatTitle,
      photo: activeRecipient.photo ? activeRecipient.photo.min : null,
    });
  }, [activeRecipient.id, activeRecipient.photo]);

  const submitChanges = () => {
    toggleRoomInfo();

    if (!isShowRoomInfo) {
      return;
    }

    if (room.name !== activeRecipient.chatTitle || room.photoFile) {
      API.updateRoom(activeRecipient.id, room.name, room.photoFile);

      setRoom({
        ...room,
        photoFile: null,
      });
    }
  };

  const changeRoomPhoto = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    console.log(file);

    reader.onloadend = () => {
      if (file.type.startsWith('image')) {
        setRoom({
          ...room,
          photo: reader.result,
          photoFile: file,
        });
      }
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const getChatTitle = () => {
    switch (activeRecipient.id) {
      case 'webmaster':
        return 'Webmaster Chat'
      case 'girls':
        return `${girlsTodayMsgCount} messages today`
      default:
        return activeRecipient.chatTitle
    }
  }

  console.log(getContactAvatar(activeRecipient, ROOM));

  return (
    <div
      style={isShowRoomInfo ? { height: 80 } : null}
      className="room-info-card">

      {activeRecipient.chatType === 'room' &&
        <div className="room-info-card__btn--details" onClick={submitChanges} >
          {isShowRoomInfo
            ? <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 426.667 426.667">
              <path d="M213.333 0C95.518 0 0 95.514 0 213.333s95.518 213.333 213.333 213.333c117.828 0 213.333-95.514 213.333-213.333S331.157 0 213.333 0zm-39.134 322.918l-93.935-93.931 31.309-31.309 62.626 62.622 140.894-140.898 31.309 31.309-172.203 172.207z" fill="#6ac259" />
            </svg>
            : <SvgIcon icon="info" width="16" height="16" title="chat info" />
          }
        </div>
      }

      <header className="room-info-card__meta" >
        <label htmlFor="room-info-card__photo">
          <figure className={`room-info-card__ava-wrap ${isShowRoomInfo && `room-info-card__ava-wrap--changeble`}`} >
            <LazyLoadImage
              src={room.photo || getContactAvatar(activeRecipient, ROOM)}
              className="round-img"
              alt="contact_avatar" />
          </figure>
        </label>

        <div className="room-info-card__information">
          {isShowRoomInfo
            ? <input
              className="room-info-card__name--input"
              type="text"
              value={room.name}
              onChange={(e) => setRoom({ ...room, name: e.target.value })} />
            : <span className="room-info-card__name">
              {getChatTitle()}
            </span>
          }

          {activeRecipient.chatType === 'room' && <div className="room-info-card__count">
            ({activeRecipient.usersIds.length + 1 + ' members'})
          </div>
          }
        </div>

        {activeRecipient.chatType === 'room' && !isShowRoomInfo &&
          <ul className="room-info-card__participants">
            <li>You</li>
            {activeRecipient.usersIds.map(userId => <RoomParticipantTag key={userId} userId={userId} />)}
          </ul>
        }

        {isShowRoomInfo &&
          <input
            type="file"
            id="room-info-card__photo"
            className="room-info-card__photo"
            onChange={changeRoomPhoto} />
        }
      </header>

      {isShowRoomInfo &&
        <RoomDetails
          isIamAdmin={isIamAdmin}
          activeRecipient={activeRecipient}
          user={user}
          updateActiveChat={props.updateActiveChat}
        />
      }
    </div >
  );
};

const mapStateToProps = (state) => ({
  profile: state.user,
  operators: state.operators.entities,
  girlsTodayMsgCount: state.roomChats.girlsTodayMsgCount,
  user: state.user,
});

export default connect(mapStateToProps)(RoomInfoCard);