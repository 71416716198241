import API from "../api/api";
import { normalize } from "../utils/normalizeContacts";

export const ADD_NOTIFICATIONS = 'ADD_NOTIFICATIONS';
export const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

export const REMOVE_ALL_SESSION_NOTIFICATIONS = 'REMOVE_ALL_SESSION_NOTIFICATIONS';

export const SET_NOTIFICATIONS_UNREAD_COUNT = 'SET_NOTIFICATIONS_UNREAD_COUNT';
export const READ_NOTIFICATION = 'READ_NOTIFICATION';
export const READ_ALL_NOTIFICATIONS = 'READ_ALL_NOTIFICATIONS';


export const NOTIFICATION_TYPES = {
  sessionView: 'session_view',
  sessionProposed: 'session_proposed',
  sessionAssistanceRequest: 'session_assistance_request',
  sessionAssistanceDelete: 'session_assistance_delete',
  sessionAssistanceResponse: 'session_assistance_response',
  sessionTransferRequest: 'session_transfer_request',
  sessionTransferResponse: 'session_transfer_response',
  changeOperatorStatus: 'change_operator_status',
  messageReminder: 'message-reminder',
  mention: 'mentions',
  coldTransfer: 'cold_transfer',
  parkingCall: 'parking_call',
  warmTransfer: 'warm_transfer',
  bufferedProfileBecomeOff: 'buffered_profile_become_off',
  changeContactNumber: 'change_contact_number', // contact has wrote from another number
  addTelegramChannel: 'add_telegram_channel',
  missedClientCall: 'missed_client_call',
  missedSessionClientCall: 'missed_session_client_call',
  longSession: 'long_session',
  clientTextedInSession: 'client_texted_in_session',
  profileTextedInSession: 'profile_texted_in_session',
  newRelatedMessage: 'new_related_message',
  nightBotReject: 'night_bot_reject',
  partnerHasntReactTo: 'partner_hasnt_reacted_to',
  partnerHasBookedProfileInSession: 'partner_has_booked_profile_in_session',
  undeliveredMessage: 'undelivered_message',
  sessionUsingReminder: 'session_using_reminder',
  newMessageReminder: 'new-message-reminder',
  conferenceInvitation: 'conference_invitation',
  newMail: 'new_mail',
}
const NOTIFICATIONS_DURATION = {
  red: null,
  blue: null,
  orange: 300, // 5 minutes
  yellow: 120, // 2 minutes
  green: 30, // 30 seconds
}


export const addNotifications = offset => dispatch => {
  return API.getUserNotifications(offset)
    .then(({ data }) => {
      dispatch({
        type: offset
          ? UPDATE_NOTIFICATIONS
          : ADD_NOTIFICATIONS,
        payload: normalize(data)
      });
    })
    .catch(console.log);
};

export const addNotification = (notification) => dispatch => {
  const {
    color = 'yellow',
    duration,
    date_created,
  } = notification;

  dispatch({
    type: ADD_NOTIFICATION,
    payload: {
      ...notification,
      color,
      duration: duration || NOTIFICATIONS_DURATION[color],
      date_created: date_created ? date_created : new Date().getTime(),
    }
  })
};

export const removeAllSessionNotifications = (sessionId) => dispatch => {
  dispatch({
    type: REMOVE_ALL_SESSION_NOTIFICATIONS,
    payload: sessionId,
  })
};

export const removeNotification = (id) => dispatch => {
  dispatch({
    type: REMOVE_NOTIFICATION,
    payload: id
  });
};

export const setNotificationsUnreadCount = (count) => dispatch => {
  dispatch({
    type: SET_NOTIFICATIONS_UNREAD_COUNT,
    payload: count,
  });
}

export const readNotification = (notificationId) => dispatch => {
  return API.readUserNotification(notificationId)
    .then(() => {
      dispatch({
        type: READ_NOTIFICATION,
        payload: notificationId,
      })
    })
    .catch(console.log);
}

export const readAllNotifications = () => dispatch => {
  return API.readAllUserNotifications()
    .then(() => {
      dispatch({
        type: READ_ALL_NOTIFICATIONS,
      })
    })
    .catch(console.log);
}

const initialState = {
  entities: {},
  savedIds: [], // notification center
  newIds: [], // notification section
  unreadCount: 0,
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ADD_NOTIFICATIONS: {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...payload.entities,
        },
        savedIds: payload.result,
      }
    }
    case UPDATE_NOTIFICATIONS: {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...payload.entities,
        },
        savedIds: [
          ...state.savedIds,
          ...payload.result
        ],
      }
    }
    case ADD_NOTIFICATION: {
      let updatedUnreadCount = payload.isCustom
        ? state.unreadCount
        : state.unreadCount + 1;

      const isIdString = typeof payload.id === 'string'; 
      // id typeof === string only in those notifications, that we don`t save, that`s why saved only ids typeof === number

      const isNewIdAlreadyExist = state.newIds.includes(payload.id);

      return {
        ...state,
        unreadCount: updatedUnreadCount,
        entities: {
          ...state.entities,
          [payload.id]: payload
        },
        savedIds: isIdString
          ? state.savedIds
          : [
            payload.id,
            ...state.savedIds
          ],
        newIds: isNewIdAlreadyExist
          ? state.newIds
          : [
            payload.id,
            ...state.newIds,
        ],
      }
    }
    case REMOVE_NOTIFICATION: {
      if (!Array.isArray(payload) && !state.entities[payload]) return state;

      const updatedEntities = { ...state.entities };
      let updatedUnreadCount = state.unreadCount;

      if (Array.isArray(payload)) {
        payload.forEach((id) => {
          if (updatedEntities[id]) {
            updatedUnreadCount--;
            delete updatedEntities[id];
          }
        })
      } else {
        delete updatedEntities[payload];
      }

      return {
        ...state,
        entities: updatedEntities,
        unreadCount: updatedUnreadCount,
        newIds: Array.isArray(payload)
          ? state.newIds.filter(id => !payload.includes(id))
          : state.newIds.filter(id => id !== payload),
      }
    }
    case REMOVE_ALL_SESSION_NOTIFICATIONS: {
      const updatedNewIds = [...state.newIds];

      state.newIds.forEach(notificationId => {
        const sessionNotificationTypes = [
          NOTIFICATION_TYPES.sessionView,
          NOTIFICATION_TYPES.sessionProposed,
          NOTIFICATION_TYPES.sessionAssistanceRequest,
          NOTIFICATION_TYPES.sessionAssistanceDelete,
          NOTIFICATION_TYPES.sessionAssistanceResponse,
          NOTIFICATION_TYPES.sessionTransferRequest,
          NOTIFICATION_TYPES.sessionTransferResponse,
          NOTIFICATION_TYPES.missedSessionClientCall,
          NOTIFICATION_TYPES.longSession,
          NOTIFICATION_TYPES.clientTextedInSession,
          NOTIFICATION_TYPES.profileTextedInSession,
          NOTIFICATION_TYPES.partnerHasBookedProfileInSession,
          NOTIFICATION_TYPES.partnerHasntReactTo
        ];

        const isSessionNotification = sessionNotificationTypes.find(type => type === state.entities[notificationId].type);

        if (!isSessionNotification) {
          return;
        }
        const sessionId = state.entities[notificationId].data.session_id;

        if (sessionId === payload) {
          const index = updatedNewIds.indexOf(notificationId);
          updatedNewIds.splice(index, 1);
        }
      });

      return {
        ...state,
        newIds: updatedNewIds,
      }
    }
    case SET_NOTIFICATIONS_UNREAD_COUNT: {
      return {
        ...state,
        unreadCount: payload,
      }
    }
    case READ_NOTIFICATION: {
      const updatedEntities = { ...state.entities };
      const updatedNewIds = [...state.newIds.filter(id => id !== payload)];

      if (state.savedIds.includes(payload)) {
        updatedEntities[payload] = {
          ...updatedEntities[payload],
          viewed: 1
        };
      }

      return {
        ...state,
        unreadCount: state.unreadCount - 1,
        entities: updatedEntities,
        newIds: updatedNewIds,
      }
    }
    case READ_ALL_NOTIFICATIONS: {
      const updatedEntities = { ...state.entities };

      state.savedIds.forEach(id => {
        updatedEntities[id] = {
          ...updatedEntities[id],
          viewed: 1
        }
      });

      return {
        ...state,
        unreadCount: 0,
        entities: updatedEntities,
        newIds: []
      }
    }
    default: {
      return state;
    }
  }
};