import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import LS from '../../utils/localStorageAPI';
import {
  sendMessageToChat,
  updateChatTimeline,
  getChatTimeline,
  updateActiveChat,
  markChatAsRead,
  updateSharedContact,
  getChatMessageContext,
  getChatTimelineMedia,
  cleanChatTimelineMedia,
  updateChatTimelineMedia,
  pinMsg,
  unpinMsg,
  fixRoomTab,
  updateChatMessageSearch,
  stopChatMessageSearch,
  searchMessageInChat,
  updateChatMessageContext,
  createNewRoom,
  onRemoveChatTab
} from '../../ducks/roomChats';

import { playMedia, openModal } from '../../ducks/activeWindows';
import { removeContactTab, updateActiveContact } from '../../ducks/clientChats';
import { selectUserTimezone } from '../../selectors/selectors';

import AdaptiveTabs from "./components/ChatTabs/AdaptiveTabs";
import Title from '../../components/UI/Title/Title';
import ChatTabs from './components/ChatTabs/ChatTabs';
import ChatTimeline from './components/ChatTimeline/ChatTimeline1';
import ChatMessageInput from './components/ChatMessageInput/ChatMessageInput';
import AttachmentMessage from './components/ChatAttachments/AttachmentMessage';
import AttachmentContact from './components/ChatAttachments/AttachmentContact';
import AttachmentVoiceMessage from './components/ChatAttachments/AttachmentVoiceMessage';
import AttachmentEditedMessage from './components/ChatAttachments/AttachmentEditedMessage';
import AttachmentImages from "./components/ChatAttachments/AttachmentImages";
import ChatAttachments from './components/ChatAttachments/ChatAttachments';
import ChatRoomHeader from './components/ChatContactInfo/ChatRoomHeader';
import ChatCreatorButton from './components/ChatCreatorForm/ChatCreatorButton/ChatCreatorButton';
import CreateRoomDialog from '../Sidebar/components/RoomTabs/CreateRoomDialog/CreateRoomDialog';
import DropMediaContainer from '../../components/DropMediaContainer/DropMediaContainer';
import ChatSources from './components/ChatSources/ChatSources';


import API from '../../api/api';
import { updateRoom } from '../../ducks/rooms';
import { CHAT_SOURCES, CHAT_TYPES } from '../../config/constants';
import Bookings from '../Bookings/Bookings';
import OffTodayList from './components/OffTodayList/OffTodayList';
import AvailableGirlsList from './components/AvailableGirlsList/AvailableGirlsList';


class PublicChat extends Component {
  state = {
    sharedMsg: null,
    editedMsg: null,
    voiceMsg: null,
    isShowChatCreator: false,
    isHideNewChat: false,
    activeChatSource: CHAT_SOURCES.MSGS,
    images: null,
  };

  componentDidMount() {
    if (this.props.active && !this.props.timeline.length) {
      this.props.getChatTimeline(this.props.active, this.props.userId, this.props.userTimezone);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { activeChatSource } = this.state;

    if (prevProps.active !== this.props.active) {
      if (this.props.active !== null) {
        this.props.getChatTimeline(this.props.active, this.props.userId, this.props.userTimezone);
      }
      if (this.state.voiceMsg) {
        this.updateVoiceMsg();
      }
      if (this.state.editedMsg) {
        this.cleanEditedMsg();
      }
      if (this.state.images) {
        this.cleanImages();
      }

      LS.setItem('aChat', this.props.active, this.props.userId);
    }

    if (prevProps.tabs !== this.props.tabs && prevProps.tabs.length !== 0) {
      LS.setItem('chTabs', this.props.tabs, this.props.userId);
    }
    if (prevProps.unfixedTab !== this.props.unfixedTab) {
      LS.setItem('unfixedChatTab', this.props.unfixedTab, this.props.userId);
    }

    if (activeChatSource !== prevState.activeChatSource) {
      if (activeChatSource === CHAT_SOURCES.MEDIA) {
        this.props.getChatTimelineMedia(this.props.activeRecipient, this.props.userId, this.props.userTimezone);
      }

      else if (prevState.activeChatSource === CHAT_SOURCES.MEDIA) {
        this.props.cleanChatTimelineMedia();
      }
    }
  }

  markChatAsRead = () => {
    //REVIEW: without setTimeout active recipient doesnt change
    if (this.props.activeRecipient && this.props.activeRecipient.unreadCount) {
      console.log('MARKASREAD', this.props.activeRecipient);

      this.props.markChatAsRead(this.props.activeRecipient.id);
    }
  }

  shareMsg = (msg) => {
    this.setState({ sharedMsg: msg });
  }

  editMsg = (msg) => {
    this.setState({ editedMsg: msg });
  }

  updateVoiceMsg = (blob, duration) => {
    if (blob && duration) {                 // add voiceMsg
      this.setState({
        voiceMsg: {
          blob,
          url: URL.createObjectURL(blob),
          duration
        }
      });
    }
    else if (this.state.voiceMsg) {         // clean voiceMsg
      this.setState({ voiceMsg: null });
    }
  }

  updateImages = (images) => {
    const imagesWithUrl = images.map(file => Object.assign(file, {
      url: URL.createObjectURL(file)
    }));

    this.setState({ images: imagesWithUrl });
  }
  cleanImages = () => {
    this.state.images.forEach(file => URL.revokeObjectURL(file.url));
    this.setState({ images: null });
  }


  cleanSharedMsg = () => this.setState({ sharedMsg: null });

  cleanEditedMsg = () => this.setState({ editedMsg: null });

  setActiveChatSource = (chatSource) => {
    if (chatSource !== this.state.activeChatSource) {
      this.setState({ activeChatSource: chatSource });
    }
  }

  timeline = () => {
    if (this.props.contextMsgId) {
      return this.props.auxiliaryTimeline;
    }
    else if (this.props.search) {
      return this.props.auxiliaryTimeline;
    }
    else if (this.state.activeChatSource !== CHAT_SOURCES.MSGS) {
      return this.props.auxiliaryTimeline;
    }
    return this.props.timeline;
  }

  updateTimeline = () => {
    if (this.props.contextMsgId) {
      return this.updateMsgContext;
    }
    else if (this.props.search) {
      return this.updateChatMessageSearch;
    }
    else if (this.state.activeChatSource === CHAT_SOURCES.MEDIA) {
      return this.updateChatTimelineMedia;
    }
    return this.updateChatTimeline;
  }

  updateChatTimeline = (chat, page, loadDirection) =>
    this.props.updateChatTimeline(chat, page, loadDirection, this.props.userId, this.props.userTimezone);

  startSearch = (query) =>
    this.props.searchMessageInChat(query, this.props.activeRecipient.id, this.props.userId, this.props.userTimezone);

  updateChatMessageSearch = (activeRecipient, page, loadDirection) =>
    this.props.updateChatMessageSearch(activeRecipient, page, loadDirection, this.props.search, this.props.userId, this.props.userTimezone);

  getChatMsgContext = (msgId, contact) =>
    this.props.getChatMessageContext(msgId, this.props.active, contact, this.props.userId, this.props.search, this.props.userTimezone);

  updateMsgContext = (activeRecipient, page, loadDirection) =>
    this.props.updateChatMessageContext(this.props.contextMsgId, page, loadDirection, this.props.userId, activeRecipient.id, this.props.userTimezone);

  updateChatTimelineMedia = (activeRecipient, page, loadDirection) =>
    this.props.updateChatTimelineMedia(activeRecipient, page, loadDirection, this.props.userId, this.props.userTimezone);

  handleDropMediaSubmit = (images) => {
    // return API.sendMessageToChat({ files: images }, this.props.activeRecipient.id);
    this.updateImages(images);
  }

  changeRecipientAudioStatus = (action, recipientId) =>
    API.setRoomAudioStatus(action, recipientId)
      .then(({ data }) => {
        this.props.updateRoom(data.chat);
      })
      .catch(console.error);

  render() {
    const {
      activeRecipient,
      tabs,
      className,
      search,
      userId,
      userTimezone,
      userHour12,
    } = this.props;

    const isMainTimelineOpen = this.props.search ||
      this.state.activeChatSource !== CHAT_SOURCES.MSGS ||
      this.props.contextMsgId
      ? false
      : true;

    const timelineLowerLoadedPage = !isMainTimelineOpen
      ? this.props.auxiliaryLowerLoadedPage
      : this.props.timelineLowerLoadedPage;

    const timelinePageCount = !isMainTimelineOpen
      ? this.props.auxiliaryPageCount
      : this.props.timelinePageCount;

    const timelineHigherLoadedPage = !isMainTimelineOpen
      ? this.props.auxiliaryHigherLoadedPage
      : this.props.timelineCurrentPage;

    const timelineCurrentPage = !isMainTimelineOpen
      ? this.props.auxiliaryCurrentPage
      : this.props.timelineCurrentPage;

    // TODO: remove publicChat
    return (
      <div
        onClick={() => setTimeout(() => this.markChatAsRead(), 0)}
        className={className ? `chat ${className}` : "chat"} >

        {tabs.length > 0 &&
          <Fragment>
            <div className="chat__title-wrap">
              <Title
                classPrefix="chat-title"
                icon="messages"
                text={this.props.title}
              />

              {this.props.active !== 'new_chat' &&
                <ChatCreatorButton onClick={this.props.createNewRoom}>
                  <span className="chat-creator-button__text">
                    New Group Chat
                  </span>
                </ChatCreatorButton>
              }
            </div>

            <AdaptiveTabs
              tabs={tabs}
              type={CHAT_TYPES.ROOM} >

              <ChatTabs
                withUnfixedTab
                unfixedTab={this.props.unfixedTab}
                fixTab={this.props.fixRoomTab}
                activeRecipient={activeRecipient}
                removeContactTab={this.props.removeContactTab}
                updateActiveContact={this.props.updateActiveChat}
                isShowUndoTabs={true} />

            </AdaptiveTabs>

            <div className="chat__wrapper chat__wrapper--public">
              {this.props.active === 'new_chat' &&
                <CreateRoomDialog
                  active={this.props.active}
                  onRemoveChatTab={this.props.onRemoveChatTab}
                  userId={this.props.userId}
                />
              }
              {this.props.active === 'bookings' &&
                <Bookings isChat />
              }
              {this.props.active === 'off_today' &&
                <OffTodayList/>
              }
              {this.props.active === 'available' &&
                <AvailableGirlsList/>
              }

              <ChatRoomHeader
                activeRecipient={activeRecipient}
                updateActiveChat={this.props.updateActiveChat}
              />

              <ChatSources
                type={CHAT_TYPES.ROOM}
                activeRecipient={activeRecipient}
                stopMessageSearch={this.props.stopChatMessageSearch}
                startMessageSearch={this.startSearch}
                setActiveChatSource={this.setActiveChatSource}
                activeChatSource={this.state.activeChatSource}
                search={search}
              />

              <DropMediaContainer
                activeRecipient={activeRecipient}
                openModal={this.props.openModal}
                type={CHAT_TYPES.ROOM}
                // classNamePrefix="chat-dropzone"
                onSubmit={this.handleDropMediaSubmit}
              >
                {(getInputProps) => (
                  <>
                    <ChatTimeline
                      activeRecipient={activeRecipient}
                      type={CHAT_TYPES.ROOM}

                      timelinePending={this.props.timelinePending}
                      updatePending={this.props.updatePending}
                      timeline={this.timeline()}
                      timelineCurrentPage={timelineCurrentPage}
                      timelinePageCount={timelinePageCount}
                      timelineHigherLoadedPage={timelineHigherLoadedPage}
                      timelineLowerLoadedPage={timelineLowerLoadedPage}
                      newInteractionType={this.props.newInteractionType}
                      isMainTimelineOpen={isMainTimelineOpen}
                      updateContactTimeline={this.updateTimeline()}

                      setActiveChatSource={this.setActiveChatSource}
                      activeChatSource={this.state.activeChatSource}

                      profileId={userId}
                      userTimezone={userTimezone}
                      userHour12={userHour12}

                      // showModalImage={this.props.showModalImage} // not used
                      search={search}
                      contextMsgId={this.props.contextMsgId}
                      getMessageContext={this.getChatMsgContext}
                      updateActiveContact={this.props.updateActiveContact}
                      publicChat
                      playMedia={this.props.playMedia}

                      pinnedMsg={this.props.pinnedMsg}
                      pinMsg={this.props.pinMsg}
                      unpinMsg={this.props.unpinMsg}

                      shareMsg={this.shareMsg}
                      editMsg={this.editMsg}
                    />


                    <ChatAttachments
                      isShow={!!this.state.sharedMsg && !this.state.editedMsg}
                      onClose={this.cleanSharedMsg} >
                      <AttachmentMessage
                        showTitle
                        interaction={this.state.sharedMsg}
                        profileId={userId}
                        userTimezone={userTimezone}
                        userHour12={userHour12}
                      />
                    </ChatAttachments>

                    <ChatAttachments
                      isShow={!!this.props.sharedContactId && !this.state.editedMsg}
                      onClose={() => this.props.updateSharedContact(null)} >
                      <AttachmentContact
                        showTitle
                        contactId={this.props.sharedContactId} />
                    </ChatAttachments>

                    <ChatAttachments
                      isShow={this.state.voiceMsg && !this.state.voiceMsg.sendAtImmediately && !this.state.editedMsg}
                      onClose={this.updateVoiceMsg} >
                      <AttachmentVoiceMessage
                        showTitle
                        voiceMsg={this.state.voiceMsg} />
                    </ChatAttachments>

                    <ChatAttachments
                      isShow={this.state.editedMsg}
                      onClose={this.cleanEditedMsg} >
                      <AttachmentEditedMessage
                        showTitle
                        interaction={this.state.editedMsg} />
                    </ChatAttachments>

                    <ChatAttachments
                      isShow={!!this.state.images?.length}
                      onClose={this.cleanImages} >
                      <AttachmentImages
                        images={this.state.images} />
                    </ChatAttachments>

                    {this.props.active && !['girls', 'webmaster', 'new_chat', 'bookings', 'off_today', 'available'].includes(this.props.active) &&
                      <ChatMessageInput
                        publicChat
                        typingStatus={this.props.typingStatus}
                        activeRecipient={activeRecipient}
                        shortcuts={this.props.shortcuts}
                        sendMessage={this.props.sendMessage}
                        userId={userId}
                        isAnyAttachments={!!this.state.sharedMsg || !!this.state.voiceMsg || !!this.state.editedMsg || !!this.props.sharedContactId}
                        sharedMsg={this.state.sharedMsg}
                        cleanSharedMsg={this.cleanSharedMsg}
                        editedMsg={this.state.editedMsg}
                        cleanEditedMsg={this.cleanEditedMsg}
                        sharedContactId={this.props.sharedContactId}
                        updateSharedContact={this.props.updateSharedContact}
                        voiceMsg={this.state.voiceMsg}
                        images={this.state.images}
                        cleanImages={this.cleanImages}
                        updateVoiceMsg={this.updateVoiceMsg}
                        fileInputProps={getInputProps}
                        fixTab={this.props.fixRoomTab}
                        unfixedTab={this.props.unfixedTab}
                        changeRecipientAudioStatus={this.changeRecipientAudioStatus}
                        type={CHAT_TYPES.ROOM}
                        openModal={this.props.openModal}
                      />
                    }
                  </>
                )}
              </ DropMediaContainer >
            </div>

            {/* 
              <ChatFooter
                publicChat
                activeRecipient={activeRecipient}
                shortcuts={this.props.shortcuts}
                sendMessage={this.props.sendMessage}
                profileId={profile.id}
                isAnyAttachments={!!this.state.sharedMsg || !!this.state.voiceMsg || !!this.state.editedMsg || !!this.props.sharedContactId}
                sharedMsg={this.state.sharedMsg}
                cleanSharedMsg={this.cleanSharedMsg}
                editedMsg={this.state.editedMsg}
                cleanEditedMsg={this.cleanEditedMsg}
                sharedContactId={this.props.sharedContactId}
                updateSharedContact={this.props.updateSharedContact}
                voiceMsg={this.state.voiceMsg}
                updateVoiceMsg={this.updateVoiceMsg}
              /> */}

          </Fragment>
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  active: state.roomChats.active,
  activeRecipient: state.rooms.entities[state.roomChats.active],
  tabs: state.roomChats.tabs,
  unfixedTab: state.roomChats.unfixedTab,

  timeline: state.roomChats.timeline,
  timelinePending: state.roomChats.timelinePending,
  updatePending: state.roomChats.updatePending,
  timelineCurrentPage: state.roomChats.timelineCurrentPage,
  timelinePageCount: state.roomChats.timelinePageCount,
  timelineLowerLoadedPage: state.roomChats.timelineLowerLoadedPage,
  newInteractionType: state.roomChats.newInteractionType,

  auxiliaryTimeline: state.roomChats.auxiliaryTimeline,
  auxiliaryLowerLoadedPage: state.roomChats.auxiliaryLowerLoadedPage,
  auxiliaryPageCount: state.roomChats.auxiliaryPageCount,
  auxiliaryHigherLoadedPage: state.roomChats.auxiliaryHigherLoadedPage,
  auxiliaryCurrentPage: state.roomChats.auxiliaryCurrentPage,

  sharedContactId: state.roomChats.sharedContactId,
  pinnedMsg: state.roomChats.pinnedMsg,

  search: state.roomChats.search,
  // searchData: state.roomChats.searchedMessages,
  // timelineMedia: state.roomChats.timelineMedia,
  // messageContext: state.roomChats.timelineMsgContext,

  contextMsgId: state.roomChats.contextMsgId,

  userId: state.user.id,
  userTimezone: selectUserTimezone(state),
  shortcuts: state.msgTemplates.shortcuts,
  userHour12: state.user.hour12,

  typingStatus: state.typingOperators.typingOperators[state.roomChats.active + '_3'],
});
const mapDispatchToProps = {
  updateRoom,
  sendMessage: sendMessageToChat,
  updateChatTimeline,
  getChatTimeline,
  updateChatMessageSearch,
  updateActiveChat,
  removeContactTab,
  stopChatMessageSearch,
  searchMessageInChat,
  markChatAsRead,
  updateSharedContact,
  getChatMessageContext,
  updateChatMessageContext,
  getChatTimelineMedia,
  updateChatTimelineMedia,
  cleanChatTimelineMedia,
  updateActiveContact,
  pinMsg,
  unpinMsg,
  fixRoomTab,
  playMedia,
  createNewRoom,
  onRemoveChatTab,
  openModal
};

export default connect(mapStateToProps, mapDispatchToProps)(PublicChat);