import { createStore, applyMiddleware, compose } from 'redux';

import thunk from 'redux-thunk';
import logger from 'redux-logger';

import rootReducer from '../reducers';

const enhancers = [
  applyMiddleware(thunk, logger)
];

// Redux Devtools
if (process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION__) {
  enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
}

const store = createStore(
  rootReducer,
  compose(...enhancers)
);

// if (process.env.NODE_ENV !== 'production') {
//   if (module.hot) {
//     module.hot.accept('../reducers', () => {
//       store.replaceReducer(rootReducer);
//     });
//   }
// }

export default store;