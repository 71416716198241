import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import API from "../../../../api/api";
import {
  createSession,
  updateActiveSession
} from "../../../../ducks/sessions";
import { selectSessionByContactId, selectIsPartnersSession } from '../../../../selectors/selectors';


const StartSessionBtn = (props) => {
  const updateActiveSession = () => {
    if (!props.sessionInfo.isMineSession && props.sessionInfo.sessionId) return;

    if (props.sessionInfo.sessionId) {
      props.updateActiveSession(props.sessionInfo.sessionId);
    }
    else {
      props.createSession(props.activeRecipientId)
        .then(() => {
          if (props.isPartners) {
            API.removeViewedInSession(props.activeSession);
          }
        })
    }
  }

  const getBtnText = () => {
    if (!props.sessionInfo.isMineSession && props.sessionInfo.sessionId) {
      return <>Session already created by <b>{props.sessionInfo.operatorName}</b></>
    }
    else {
      return props.sessionInfo.sessionId
        ? "Go to existed session"
        : "Start new session"
    }
  }

  return (
    <div className="chat-sources__sales-popup sales-popup">
      <Link to={
        !props.sessionInfo.isMineSession && props.sessionInfo.sessionId
          ? "/"
          : "/client/sales"
      }>
        <button
          disabled={!props.sessionInfo.isMineSession && props.sessionInfo.sessionId}
          onClick={updateActiveSession}
          className="sales-popup__btn">

          {getBtnText()}

        </button>
      </Link>

      <button
        onClick={() => props.toggleSalesBtn()}
        className="sales-popup__btn sales-popup__btn--cancel">
        Cancel
        </button>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => ({
  sessionInfo: selectSessionByContactId(state, ownProps.activeRecipientId),
  activeSession: state.sessions.activeSession,
  isPartners: selectIsPartnersSession(state),
});

const mapDispatchToProps = {
  createSession,
  updateActiveSession
};

export default connect(mapStateToProps, mapDispatchToProps)(StartSessionBtn);