import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { usePopperTooltip } from 'react-popper-tooltip';

import Api from '../../../../api/api';
import { getDefaultField } from '../../../../utils';
import { openModal, MODAL_TYPES } from '../../../../ducks/activeWindows';
import { CARD_TABS } from '../../../../config/constants';

import 'react-popper-tooltip/dist/styles.css';
import Select from '../../../../components/Select/Select';
import SvgIcon from '../../../../components/SvgIcon/SvgIcon';

const ContactEmails = (props) => {
  const { emails = [] } = props.activeRecipient;

  const dispatch = useDispatch();

  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible
  } = usePopperTooltip({
    trigger: 'click',
    placement: 'bottom-start',
    offset: [0, 0],
  });

  // if (!getDefaultField(emails, 'email')) return null;

  const onChange = (option) => {
    if (getDefaultField(emails, 'email') === option.value) return;

    Api.changeDefaultContactEmail(option.id)
      .catch(err => console.error(err.response.data.message));

    console.log('onChange');
  }

  const defaultEmail = getDefaultField(emails, 'email');

  const openEditContactForm = () => {
      return dispatch(openModal(MODAL_TYPES.contactCard, {
          contact: props.activeRecipient.id,
          defaultTab: CARD_TABS.EDIT,
          isAddEmailFieldOnMount: true,
      }));
  };

  const options = useMemo(() => emails.length === 1
      ? [ { value: '' }, { action: openEditContactForm, value: 'Add New Email' }, { value: '' } ]
      : [ ...emails.map(email => ({ id: email.id, value: email.email, })), { action: openEditContactForm, value: 'Add New Email' }]
      , [emails]);

  return (
    <div className="chat-header__row">

      <button
        disabled={!defaultEmail}
        ref={setTriggerRef}
        className="call-btn__btn">
        <SvgIcon width={16} height={16} icon="envelope" fill="#0092F2" />
      </button>

      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({ className: 'tooltip-container events-auto' })}
        >
          <div className="chat-header__email-list">
            {emails.map((email, index) => {
              return (
                <NavLink
                  className="chat-header__email-item"
                  title={email.email}
                  to={`/client/mail/compose?email=${email.email}`}
                  key={email.id || index}
                >
                  mail to:
                  <span className="chat-header__email-item-email">{email.email}</span>
                </NavLink>
              )
            })}
          </div>
          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
        </div>
      )}

      <div
          onMouseDown={() => !defaultEmail ? openEditContactForm() : null}
          style={defaultEmail ? null : { cursor: 'pointer' }}
      >
        <Select
          classPrefix="email"
          options={options}
          getOptionValue={option => option.value}
          isDisabled={!defaultEmail}
          defaultValue={defaultEmail || "Add Email"}
          onInputChange={onChange}
        />
      </div>
    </div>
  )
}

export default ContactEmails;