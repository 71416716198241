import React from 'react';

import { getDateByTimezoneOffset } from '../../../../utils';


const ContactBlockInfo = ({ activeRecipient, userTimezone }) => {
  const formatBlockedDate = date => (
    getDateByTimezoneOffset(userTimezone, date)
      .toLocaleString(
        'en-US',
        {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric'
        }
      )
  );

  return (
    <div className="chat-header__block-info" >
      This user was blocked by < b > {activeRecipient.blocked.user.username}</b > <br />
      at {formatBlockedDate(activeRecipient.blocked.date_created)} <br />
      <b>because of:</b> {activeRecipient.blocked.reason}
    </div >
  );
}

export default ContactBlockInfo;