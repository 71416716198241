import React, { useCallback, useRef, useState } from "react";
import { connect } from "react-redux";

import {
  useDidMount,
  useWillUnmount
} from "../../../hooks";
import {
  getSessionsHistory,
  updateSessionsHistoryAPI,
  clearSessionsHistory
} from "../../../ducks/sessions";

import List from "../../../components/List/List";
import SessionFromHistory from "../../SessionsHistory/SessionFromHistory";


const OperatorSessionsHistory = props => {
  console.log('MY SESSIONS HISTORY RENDER');

  const [isMount, setMount] = useState(true);

  useDidMount(() => {
    props.getSessionsHistory('user')
      .finally(() => setMount(false));

    document.addEventListener('mousedown', closeOperatorSessHistory);
  });

  useWillUnmount(() => {
    props.clearSessionsHistory();

    document.removeEventListener('mousedown', closeOperatorSessHistory);
  });

  const wrapperRef = useRef();

  const closeOperatorSessHistory = e => {
    const isNotOpenBtn = e.target !== props.openHistoryLinkRef;

    if (!wrapperRef.current.contains(e.target) && isNotOpenBtn) {
      props.closeHistory();
    };
  }

  const Component = useCallback((itProps) =>
    <SessionFromHistory
      closeHistory={props.closeHistory}
      isOperator
      {...itProps}
    />
    , []);

  const loadMore = useCallback((offset) => props.updateSessionsHistoryAPI('user', '', offset), []);

  return (
    <div ref={wrapperRef}>
      <List
        list={props.ids}
        limit={20}
        scrollInitialPosition='top'
        classPrefix={props.isFixed ? 'my-sessions-history-fixed' : 'my-sessions-history'}
        listItem={Component}
        loadMore={loadMore}
        listLoadPending={isMount}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  ids: state.sessions.historyIds
});

const mapDispatchToProps = {
  getSessionsHistory,
  updateSessionsHistoryAPI,
  clearSessionsHistory
};

export default connect(mapStateToProps, mapDispatchToProps)(OperatorSessionsHistory);