import React, { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';

import { useToggle } from "../../../hooks";
import { classModifier } from "../../../utils";

import DropWrapper from "../../../components/DropWrapper/DropWrapper";
import Calendar from "../../../components/Calendar/Calendar";


const BookingCalendar = props => {
  const {
    isActive,
    date,
    userTimezone,
  } = props;

  const [isCalendarOpen, toggleCalendarOpen] = useToggle(false);

  const calendarRef = useRef();

  const localTimezoneOffset = -new Date().getTimezoneOffset();
  const userOffset = localTimezoneOffset - userTimezone;
  const minute = 60000;
  const dateByTimezone = date - (userOffset * minute);

  const selectDate = (date) => {
    props.onChange(date);
    toggleCalendarOpen();
  }

  const renderDate = () => new Date(dateByTimezone).toLocaleString('en-US', {
    year: 'numeric',
    day: 'numeric',
    month: 'short',
  });

  return (
    <div className="bookings__calendar-wrap">
      {isActive &&
        <span>{renderDate()}</span>
      }

      <button
        className={classModifier('bookings__open-calendar', [
          isActive && 'active',
        ])}
        onClick={toggleCalendarOpen}
      >
        <FontAwesomeIcon icon={faCalendar} title="calendar" />
      </button>

      {isCalendarOpen &&
        <DropWrapper
          closeDropWrapper={toggleCalendarOpen}
          dropWrapperRef={calendarRef}
        >
          <div ref={calendarRef}>
            <Calendar
              onChange={selectDate}
              date={dateByTimezone}
            />
          </div>
        </DropWrapper>
      }
    </div>
  );
};

export default BookingCalendar;