import React, { useState, useRef, useEffect, memo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faComments,
  faCommentSlash,
  faGlobeEurope,
  faHistory,
  faImages,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";

import { classModifier } from '../../../../utils';
import { useToggle, useDidUpdate } from "../../../../hooks";
import { CHAT_SOURCES, CHAT_TYPES } from "../../../../config/constants";

import "./ChatSources.scss";
import SearchInput from "../../../../components/SearchInput/SearchInput";

const ChatSources = (props) => {
  const {
    activeRecipient,
    isGlobalSearch,
    search,
    isTimelineData,
    showSearchQuery,
    contextDate,
    activeChatSource,
    setActiveChatSource,
    searchSource = 'contacts',
  } = props;

  const [query, setQuery] = useState("");
  const [ignoreRequest, setIgnoreRequest] = useToggle(false);
  const [showSearch, toggleShowSearch] = useToggle(false);

  const searchInputRef = useRef();

  // if global search from another type of chat => open searchBar with query
  useEffect(() => {
    if (showSearchQuery && searchSource === 'msgs') {
      if (search && search.length > 1) {
        setQuery(search);
        toggleShowSearch(true);
      }
    }
    else if (showSearchQuery) {
      if (search && search.length > 1) {
        setQuery(search);
        toggleShowSearch(true);
        setIgnoreRequest(true);
      }
    } else if (!showSearchQuery) {
      setIgnoreRequest(false);
    }
    // if we start to search when timelineMedia is open
    if (search && search.length > 1) {
      setActiveChatSource(CHAT_SOURCES.MSGS);
      console.log("gagagagaga");
    }
  }, [showSearchQuery, search]);

  useEffect(() => {
    if (props.setIsSearchOpen) {
      props.setIsSearchOpen(showSearch);
    }
  }, [showSearch]);

  useDidUpdate(() => {
    setActiveChatSource(CHAT_SOURCES.MSGS);

    if (query && !isGlobalSearch) {
      setQuery("");
    }
    if (!isGlobalSearch) {
      toggleShowSearch(false);
    }
  }, [activeRecipient.id]);

  useEffect(() => {
    if (contextDate) {
      if (query) {
        console.log("gegege");
        setQuery("");
        toggleShowSearch(false);
      }
      setActiveChatSource(CHAT_SOURCES.MSGS);
    }
  }, [contextDate]);

  const handleSearch = (e) => {
    e.preventDefault();

    if (!showSearch) {
      toggleShowSearch();

      return searchInputRef.current.focus();
    }
    
    if(query) {
      setQuery("");
    }

    return toggleShowSearch();
  };

  console.log("CHAT SOURCE RENDER");

  if (activeRecipient.id && ["girls", "webmaster"].includes(activeRecipient.id)) {
    return null;
  }

  const getIsSelectedSource = (source) => activeChatSource === source;

  const getSelectedClass = (source) => getIsSelectedSource(source)
    ? "chat-sources__source--selected"
    : "";

  return (
    <div className='chat-sources'>
      <button
        className={`chat-sources__source ${getSelectedClass(CHAT_SOURCES.MSGS)}`}
        onClick={() => setActiveChatSource(CHAT_SOURCES.MSGS)}
      >
        <FontAwesomeIcon
          icon={faComments}
          color={getIsSelectedSource(CHAT_SOURCES.MSGS) ? "#0092F2" : "#808080"}
          title='chat'
        />
      </button>

      <button
        className={`chat-sources__source ${getSelectedClass(CHAT_SOURCES.MEDIA)}`}
        onClick={() => setActiveChatSource(CHAT_SOURCES.MEDIA)}
      >
        <FontAwesomeIcon
          icon={faImages}
          color={getIsSelectedSource(CHAT_SOURCES.MEDIA) ? "#0092F2" : "#808080"}
          title='media'
        />
      </button>

      {props.type !== CHAT_TYPES.ROOM && (
        <>
          <button
            className={`chat-sources__source ${getSelectedClass(CHAT_SOURCES.REMINDERS)}`}
            onClick={() => setActiveChatSource(CHAT_SOURCES.REMINDERS)}
          >
            <FontAwesomeIcon
              icon={faBell}
              color={getIsSelectedSource(CHAT_SOURCES.REMINDERS) ? "#0092F2" : "#808080"}
              title='reminders'
            />
          </button>
          <button
            className={`chat-sources__source ${getSelectedClass(CHAT_SOURCES.SCHEDULED_MSGS)}`}
            onClick={() => setActiveChatSource(CHAT_SOURCES.SCHEDULED_MSGS)}
          >
            {!!props.scheduledMsgsCount &&
              <span className="chat-sources__count">{props.scheduledMsgsCount}</span>
            }

            <FontAwesomeIcon
              icon={faHistory}
              color={getIsSelectedSource(CHAT_SOURCES.SCHEDULED_MSGS) ? "#0092F2" : "#808080"}
              title='scheduled-msgs'
            />
          </button>

          {props.activeRecipient.type === 2 &&
            <button
              className={`chat-sources__source ${getSelectedClass(CHAT_SOURCES.SYSTEM_MSGS)}`}
              onClick={() => setActiveChatSource(CHAT_SOURCES.SYSTEM_MSGS)}
            >
              {!!props.serviceMsgCount &&
                <span className="chat-sources__count">{props.serviceMsgCount}</span>
              }

              <FontAwesomeIcon
                icon={faCommentSlash}
                color={getIsSelectedSource(CHAT_SOURCES.SYSTEM_MSGS) ? "#0092F2" : "#808080"}
                title='service messages'
              />
            </button>
          }
        </>
      )}

      <form
        action=''
        className={classModifier('chat-sources__search-form', showSearch && "open")}
      >
        <SearchInput
          classPrefix='chat-sources'
          startSearch={ignoreRequest ? () => console.log("ignore") : props.startMessageSearch}
          stopSearch={props.stopMessageSearch}
          inputRef={searchInputRef}
          query={query}
          setQuery={setQuery}
        />

        {query.length >= 2 && (
          <button
            className='chat-sources__btn chat-sources__btn--remove'
            onClick={() => setQuery("")}
            type='button'
          ></button>
        )}

        <button
          className='chat-sources__btn'
          type='submit'
          onClick={handleSearch}
        >
          <FontAwesomeIcon
            icon={props.isGlobalSearch && showSearch ? faGlobeEurope : faSearch}
            color={showSearch ? "#0092F2" : "#808080"}
            title='search'
          />
        </button>

        {isTimelineData && !isGlobalSearch && 
          <button 
            className={classModifier('chat-sources__search-all-btn', showSearch && 'open')}
            type="button"
            onClick={() => props.startGlobalMsgSearch(search, activeRecipient)}
          >
            Search All Chats
          </button>
        }
      </form>
    </div>
  );
};

export default memo(ChatSources);
