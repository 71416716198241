import React, { useState, useRef, useCallback, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHistory, faChevronDown } from '@fortawesome/free-solid-svg-icons'

import { useToggle, useDidMount } from '../../../../hooks';
import API from '../../../../api/api';

import './CallsLog.scss';
import DropWrapper from '../../../../components/DropWrapper/DropWrapper';
import List from '../../../../components/List/List';
import CallsLogItem from './CallsLogItem';


const CallsLogList = (props) => {
  console.log('CALLSLOG1 RENDER');
  const [log, setLog] = useState([]);
  const [pending, setPending] = useState(true);

  const callsLogRef = useRef();

  useDidMount(() => {
    getContactLog();
  });

  const getContactLog = (offset) => {
    API.fetchCallsLog(offset)
      .then(res => {
        setPending(false);

        if (res.data.length) {
          setLog(prevLog => {
            return [...prevLog, ...res.data];
          });
        }
      })
      .catch(err => {
        console.log(err);
        setPending(false);
      });
  };

  const Component = useCallback((props) => <CallsLogItem {...props} />, []);
  const loadMore = useCallback((offset) => getContactLog(offset), []);

  return (
    <div ref={callsLogRef}>
      <DropWrapper
        className='calls-log__wrapper'
        dropWrapperRef={callsLogRef}
        closeDropWrapper={props.toggleList}>
        <List
          list={log}
          limit={20}
          classPrefix='calls-log'
          listLoadPending={pending}
          scrollInitialPosition='top'
          listItem={Component}
          loadMore={loadMore}
        />
      </DropWrapper>
    </div>
  )
}


const CallsLog = () => {
  const [isCallsListShown, toggleCallsList] = useToggle(false);

  return (
    <div className="calls-log">
      <button
        onMouseDown={() => toggleCallsList()}
        className="calls-log__btn"
      >
        <FontAwesomeIcon
          icon={faHistory}
          color='#808080'
          title="calls log"
        />

        <FontAwesomeIcon
          className="calls-log__drop"
          icon={faChevronDown}
          color='#808080'
          title="calls log"
          size="xs"
        />
      </button>


      {isCallsListShown &&
        <CallsLogList
          toggleList={toggleCallsList}
        />
      }
    </div>
  );
};

export default CallsLog;