import React, { useEffect, useState, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faBell as farBell } from '@fortawesome/free-regular-svg-icons';

import { useToggle } from '../../../../hooks';
import {
  readAllNotifications,
  readNotification,
  addNotifications
} from '../../../../ducks/notifications';

import List from "../../../../components/List/List";
import DropWrapper from '../../../../components/DropWrapper/DropWrapper';
import NotificationComponent from '../../../NotificationComponent/NotificationComponent';



const Notifications = (props) => {
  const {
    unreadCount,
    notifications,
    savedNotificationsIds,
    readNotification,
  } = props;

  const [userNotifications, setUserNotifications] = useState([]);
  const [isOpen, toggleOpen] = useToggle(false);
  const [isPending, setPending] = useState(true);

  const wrapperRef = useRef();

  useEffect(() => {
    if (isOpen) {
      props.addNotifications()
        .then(() => {
          setPending(false);
        });
    }
    else {
      setUserNotifications([]);
      setPending(true)
    }  
  }, [isOpen]);

  useEffect(() => {
    setUserNotifications(savedNotificationsIds.map(id => notifications[id]))
  }, [notifications]);

  const listItem = useCallback((props) => {
    return <NotificationComponent
      className="user-notifications__item"
      {...props}
    />
  }, []);

  return (
    <div ref={wrapperRef}>
      <DropWrapper
        closeDropWrapper={toggleOpen}
        dropWrapperRef={wrapperRef}
      >
        <div className="user-notifications__wrap">
          <div className="user-notifications__icons" onClick={() => toggleOpen()}>
            <FontAwesomeIcon
              icon={isOpen ? farBell : faBell}
              title="notifications"
              className='user-notifications__bell-icon'
            />

            <FontAwesomeIcon
              color={!isOpen ? '#777' : '#0092f2'}
              icon={faChevronDown}
              title="notifications"
              size='xs'
            />

            {!!unreadCount &&
              <div className="user-notifications__unread">{unreadCount > 99
                ? '99+'
                : unreadCount
              }</div>
            }
          </div>

          {isOpen &&
            <div className="user-notifications">
              <div className="user-notifications__title">
                <button
                  className="user-notifications__read-all"
                  onClick={props.readAllNotifications}
                  disabled={!unreadCount}
                >
                  Read all
                </button>
              </div>

              <List
                list={userNotifications}
                limit={20}
                classPrefix={'user-notifications'}
                scrollInitialPosition={'top'}
                listItem={listItem}
                loadMore={props.addNotifications}
                listItemProps={(notification) => ({
                  notification,
                  readNotification,
                  toggleOpen,
                })}
                listLoadPending={isPending}
              />
            </div>
          }
        </div>
      </DropWrapper>
    </div>
  );
};

const mapStateToProps = (state) => ({
  unreadCount: state.notifications.unreadCount,
  notifications: state.notifications.entities,
  savedNotificationsIds: state.notifications.savedIds,
});

const mapDispatchToProps = {
  readAllNotifications,
  readNotification,
  addNotifications,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);