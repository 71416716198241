export const normalize = (data, key = 'id') => {
  let arrData = data;

  if (data instanceof Object && data.constructor === Object) {
    arrData = [data];
  }

  const entities = {};

  const result = arrData.map(item => {
    entities[item[key]] = item;

    return item[key];
  })

  return { entities, result };
}


export const normalizeContact = (contact) => {
  const contactEntity = normalize(contact);

  if (contact.pinned) {                         // pinned contact
    return {
      pinIds: contactEntity.result,
      entities: contactEntity.entities
    }
  }
  else {                                       // unpinned contact
    return {
      ids: contactEntity.result,
      entities: contactEntity.entities
    }
  }
}


export default (contacts, withPinned = true) => {
  if (contacts instanceof Object && contacts.constructor === Object) {   // if its one contact
    return normalizeContact(contacts);
  }

  if (withPinned && contacts[0] && contacts[0].pinned) {                 // with pinned contacts
    const pinned = [];
    const unpinned = [];

    contacts.forEach(contact =>
      contact.pinned
        ? pinned.push(contact)
        : unpinned.push(contact)
    );
    const pinnedEntities = normalize(pinned);
    const unpinnedEntities = normalize(unpinned);

    return {
      pinIds: pinnedEntities.result,
      ids: unpinnedEntities.result,
      entities: {
        ...pinnedEntities.entities,
        ...unpinnedEntities.entities
      }
    }
  }
  else {                                                                // without pinned contacts
    const contactsEntities = normalize(contacts)

    return {
      ids: contactsEntities.result,
      entities: contactsEntities.entities
    }
  }
}