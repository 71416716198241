import { decodeHTML } from ".";
import { MSG_PEACES } from "../config/constants";

// This is main function to parse msg text
// This is old parseRegex just for example -  /\[IMG\](.+?)\[\/IMG\]|\[LINK\](.+?)\[\/LINK\]/g;
//
// Result of this function will be or parsed msg string
// or array with parsed msg parts. Example:
//
// resultArr = [
//   {type: MSG_PEACES.TEXT, value: "some text " },
//   {type: MSG_PEACES.MENTION, value: "@operatorXName" },
//   {type: MSG_PEACES.TEXT, value: " some another text" },
// ]

export default (msg, returnAsString) => {
  const parseRegex = /<(.+?)>/g;

  if (!parseRegex.test(msg)) {
    return decodeHTML(msg);
  }

  parseRegex.lastIndex = 0;

  const resultArr = [];
  let resultStr = '';

  let lastIndex = 0;
  let match = parseRegex.exec(msg);

  while (match) {
    // text before match
    returnAsString
      ? resultStr += msg.slice(lastIndex, match.index)
      : resultArr.push(
        {
          type: MSG_PEACES.TEXT,
          value: decodeHTML(msg.slice(lastIndex, match.index))
        }
      );

    lastIndex = parseRegex.lastIndex;
    // match text part
    // if mention
    if (match[1].startsWith('@')) {
      returnAsString
        ? resultStr += match[1]
        : resultArr.push(
          {
            type: MSG_PEACES.MENTION,
            value: match[1]
          }
        );
    }
    // if girl name
    else if (match[1].startsWith('[girl]')) {
      const girlName = match[1].slice(6);

      returnAsString
        ? resultStr += girlName
        : resultArr.push(
          {
            type: MSG_PEACES.GIRL_NAME,
            value: girlName
          }
        );
    }
    // if link
    else {
      returnAsString
        ? resultStr += match[1]
        : resultArr.push(
          {
            type: MSG_PEACES.LINK,
            value: match[1]
          }
        );
    }
    // next time it will search from last match
    match = parseRegex.exec(msg);
  }
  // text after last match
  if (lastIndex !== msg.length) {
    returnAsString
      ? resultStr += msg.slice(lastIndex)
      : resultArr.push(
        {
          type: MSG_PEACES.TEXT,
          value: decodeHTML(msg.slice(lastIndex))
        }
      );
  }

  return returnAsString
    ? decodeHTML(resultStr)
    : resultArr;
}