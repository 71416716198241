import React, { useRef, useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPause, faPlay, faVolumeUp, faVolumeMute, faExpand } from '@fortawesome/free-solid-svg-icons';

import { classModifier } from '../../utils';

import './VideoPlayer.scss';
import Slider from '../AudioPlayer/Slider';


const openInFullscreen = (elem) => {
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  }
  else if (elem.mozRequestFullScreen) { /* Firefox */
    elem.mozRequestFullScreen();
  }
  else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
    elem.webkitRequestFullscreen();
  }
  else if (elem.msRequestFullscreen) { /* IE/Edge */
    elem.msRequestFullscreen();
  }
}


const VideoPlayer = props => {
  const {
    src,
    isAutoFocus = false,
    videoId,
  } = props;

  const [isPaused, setPaused] = useState(true);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [volume, setVolume] = useState(100);

  const [thumbWasMoved, setThumWasbMoved] = useState(); // render slider after change values
  const [volumeThumbWasMoved, setVolumeThumWasbMoved] = useState();  // render slider after change values

  const videoWrapperRef = useRef(null);
  const videoRef = useRef(null);
  const btnPlayRef = useRef(null);
  const isFocus = useRef(isAutoFocus);

  useEffect(() => {
    const togglePlayerPlayOnClick = (e) => {
      isFocus.current = videoWrapperRef.current.contains(e.target);

      const dataset = (e.target && e.target.dataset) || {};

      if (dataset.videoPlayer === videoId) {
        toggleVideoPlay();
      }
    };

    const togglePlayerPlayOnKeyDown = (e) => {
      if (!isFocus.current) return;

      if (e.keyCode === 32 && e.target !== btnPlayRef.current) { // pause/play on space bar
        toggleVideoPlay();
      }
      else if ([39, 37].includes(e.keyCode)) { // +/- video volume on ↑/↓
        const duration = videoRef.current.duration;
        let newTime = e.keyCode === 39
          ? videoRef.current.currentTime + 10
          : videoRef.current.currentTime - 10;

        if (newTime > duration) {
          newTime = duration;
        }
        else if (newTime < 0) {
          newTime = 0;
        }

        videoRef.current.currentTime = newTime;
        setCurrentTime(newTime);
        moveThumb();
      }
      else if ([38, 40].includes(e.keyCode)) { // +/- video time by →/←
        e.preventDefault();

        let newVolume = e.keyCode === 38
          ? videoRef.current.volume + 0.1
          : videoRef.current.volume - 0.1;

        if (newVolume > 1) {
          newVolume = 1;
        }
        else if (newVolume < 0) {
          newVolume = 0;
        }

        setVolume(newVolume * 100);
        moveVolumeThumb()
      }
    };

    document.addEventListener('click', togglePlayerPlayOnClick);
    document.addEventListener('keydown', togglePlayerPlayOnKeyDown);

    return () => {
      document.removeEventListener('click', togglePlayerPlayOnClick);
      document.removeEventListener('keydown', togglePlayerPlayOnKeyDown);
    }
  }, []);

  useEffect(() => {
    videoRef.current.volume = volume / 100;
  }, [volume]);

  useEffect(() => { // paused after opening another video
    if (videoRef.current && videoRef.current.paused !== isPaused) {
      setPaused(videoRef.current.paused);
    }
  }, [videoRef.current && videoRef.current.paused, isPaused]);

  const moveThumb = useCallback(() => {
    setThumWasbMoved(prevState => !prevState);
  }, []);

  const moveVolumeThumb = useCallback(() => {
    setVolumeThumWasbMoved(prevVolume => !prevVolume);
  }, []);

  const toggleFullscreen = useCallback(() => {
    const video = videoWrapperRef.current;

    if (document.fullscreen) {
      document.exitFullscreen()
    }
    else {
      openInFullscreen(video);
    }
  }, []);

  const onTimeChangeManually = useCallback((time) => {
    const newTime = Math.floor(time);

    if (newTime === videoRef.current.currentTime) {
      return;
    }

    videoRef.current.currentTime = newTime;
    setCurrentTime(newTime);
  }, []);

  const onInitVideo = () => {
    props.onInitVideo && props.onInitVideo();
    setDuration(Math.floor(videoRef.current.duration));
  };

  const onTimeChange = () => {
    const currentVideoTime = Math.floor(videoRef.current.currentTime);

    if (currentVideoTime !== currentTime) {
      setCurrentTime(currentVideoTime);
      moveThumb();
    }
  };

  const onPlay = (e) => {
    if (props.onPlay && typeof props.onPlay === 'function') {
      props.onPlay(e.target);
    }
  };

  const toggleVideoPlay = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setPaused(false);
    }
    else {
      videoRef.current.pause();
      setPaused(true);
    }
  }

  const onEndVideo = () => {
    setCurrentTime(0);
    moveThumb();
    setPaused(true);
  };

  const toggleMute = () => {
    setVolume(volume ? 0 : 100);
    moveVolumeThumb();
  };

  const getTime = (sec) => {
    let minutes = Math.floor(sec / 60);
    let seconds = Math.floor(sec % 60);

    if (minutes < 10) {
      minutes = '0' + minutes;
    }
    if (seconds < 10) {
      seconds = '0' + seconds;
    }

    return `${minutes}:${seconds}`;
  }

  return (
    <div
      className={classModifier('video', [
        isPaused && 'paused'
      ])}
      ref={videoWrapperRef}
    >
      <video
        width="100%"
        ref={videoRef}
        onLoadedMetadata={onInitVideo}
        onTimeUpdate={onTimeChange}
        onEnded={onEndVideo}
        onPlay={onPlay}
        controls={false}
        data-video-player={videoId}
        onDoubleClick={toggleFullscreen}
      >
        <source src={src} type='video/mp4' />

        Your browser does not support video tag
      </video>

      <div className="video__bottom">
        {duration !== 0 &&
          <Slider
            onUpdate={onTimeChangeManually}
            countOfSteps={duration}
            currentStep={currentTime}
            thumbWasMoved={thumbWasMoved}
          />
        }

        <div className="video__controls">
          <button
            onClick={toggleVideoPlay}
            ref={btnPlayRef}
            className="video__play"
          >
            <FontAwesomeIcon icon={isPaused ? faPlay : faPause} />
          </button>

          <div className="video__volume">
            <button
              className='video__volume-btn'
              onClick={toggleMute}
            >
              <FontAwesomeIcon icon={volume > 0 ? faVolumeUp : faVolumeMute} />
            </button>

            <Slider
              onUpdate={setVolume}
              countOfSteps={100}
              currentStep={volume}
              thumbWasMoved={volumeThumbWasMoved}
            />
          </div>

          <time className='video__time'>
            {getTime(currentTime)} / {getTime(duration)}
          </time>

          <button
            className="video__toggle-fullscreen"
            onClick={toggleFullscreen}
          >
            <FontAwesomeIcon icon={faExpand} />
          </button>
        </div>
      </div>
    </div>
  )
}

export default VideoPlayer;