import React from 'react';
import classNames from 'classnames';

const SelectOption = ({ value, classPrefix, option, ...props }) => {
  const focusedClass = props.isFocused
    ? classPrefix + "__option--focused"
    : "";
  const activeClass = props.isActive
    ? classPrefix + "__option--active"
    : "";
  const actionButtonClass = props.isActionButton
      ? classPrefix + "__option--action-btn"
      : "";

  return (
    <div
      className={classNames(`${classPrefix}__option`, activeClass, focusedClass, actionButtonClass)}
    >
      {option && option.label
        ? option.label
        : value
      }
    </div>
  )
}

export default React.memo(SelectOption);