import React, { useState, useEffect, useRef, memo } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkSquareAlt, faMapPin, faStar } from "@fortawesome/free-solid-svg-icons";
import { faBootstrap } from "@fortawesome/free-brands-svg-icons";

import "./ChatHeader.scss";
import { getContactAvatar, classModifier } from "../../../../utils";
import compressChildren from '../../../../utils/compressChildren';
import { useToggle } from "../../../../hooks";
import API from "../../../../api/api";
import { CHAT_TYPES, CONTACT_TYPES } from "../../../../config/constants";
import { openModal, MODAL_TYPES } from "../../../../ducks/activeWindows";
import { toggleContactPin, toggleContactMark, addNewArrayGirlsToState } from '../../../../ducks/contacts';
import { selectUserTimezone } from "../../../../selectors/selectors";
import { addDivaGirlsToEntities } from "../../../../ducks/divaGirls";

import LazyLoadImage from "../../../../components/LazyLoadImage/LazyLoadImage";
import ContactTels from "./ContactTels";
import ContactEmails from "./ContactEmails";
import ContactDetails from "./ContactDetails";
import ContactBlockInfo from "./ContactBlockInfo";
import ContactAgent from "./ContactAgent";
import ChatCreatorForm from "../ChatCreatorForm/ChatCreatorForm";
import AsyncButton from "../../../../components/AsyncButton/AsyncButton";
import TriStateSwitch from "../../../../components/TriStateSwitch/TriStateSwitch";
// import CopyToClipboardButton from "../../../../components/CopyToClipboardButton/CopyToClipboardButton";
import SvgIcon from "../../../../components/SvgIcon/SvgIcon";
import DropWrapper from '../../../../components/DropWrapper/DropWrapper';
import StartSessionBtn from "../ChatSources/StartSessionBtn";
import GirlActualBookingsBtn from "../../../../components/GirlActualBookingsBtn/GirlActualBookingsBtn";
import BookingIcon from "../../../../components/BookingIcon";
import CopyToClipboardButton from "../../../../components/CopyToClipboardButton/CopyToClipboardButton";
import BookButton from "../../../../components/BookButton/BookButton";
import ParsedGirlList from "../ParsedGirlList/ParsedGirlList";

const TAKE = "T";
const RELEASE = "R";

const ChatHeader = ({ activeRecipient, divaProfile, ...props }) => {
  const [compressedTags, setCompressedTags] = useState(activeRecipient.callerTags || []);
  const [isActualBookings, setActualBookings] = useState(false);
  const [parsedGirlsList, setParsedGirlsList] = useState({
    query: '',
    list: [],
    isOpen: false,
  });

  const [blockInfo, toggleContactInfo] = useToggle(false);
  const [isSessionMenuOpen, toggleSessionMenu] = useToggle(false);

  const contactInfoRef = useRef();
  const headerTagsRef = useRef();
  const sessionMenuRef = useRef(null);
  const msgRef = useRef();
  
  const girlDivaId = divaProfile?.id;

  useEffect(() => {
    contactInfoRef.current && contactInfoRef.current.scrollToTop();

    if (activeRecipient.type === CONTACT_TYPES.GIRL && !divaProfile) {
      API.getDivaGirlsByContactsIds(activeRecipient.id)
        .then(res => props.addDivaGirlsToEntities([res.data]))
        .catch(console.error);
    }
  }, [activeRecipient.id, activeRecipient.type]);

  useEffect(() => {
    setCompressedTags(activeRecipient.callerTags || []);
  }, [activeRecipient.callerTags]);

  useEffect(() => {
    if (girlDivaId) {
      API.getIsGirlHasBookingsForToday([girlDivaId], props.userTimezone)
        .then(res => setActualBookings(res.data[girlDivaId]))
        .catch(console.log);
    }
    else {
      setActualBookings(false);
    }
  }, [girlDivaId]);

  useEffect(() => {
    const isCallerTags = activeRecipient.callerTags && activeRecipient.callerTags.length;

    if (isCallerTags) {
      handleWindowSize();
      window.addEventListener('resize', handleWindowSize);

      return () => window.removeEventListener('resize', handleWindowSize);
    }
  }, [compressedTags.length]);

  // console.log('chat header render', activeRecipient);

  const isBlocked = !!activeRecipient.blocked;

  const handleWindowSize = () => {
    setCompressedTags(compressChildren(headerTagsRef, compressedTags));
  };

  const renderRelatedBtn = () => {
    const buttonType =
      activeRecipient.relatedUserId && activeRecipient.relatedUserId === props.profileId
        ? RELEASE
        : TAKE;

    return (
      <AsyncButton
        className='chat-header__btn--related'
        onClick={() => changeRelatedStatus(buttonType)}
        spinnerSize={10}
        title='Take / Release'
      >
        {buttonType}
      </AsyncButton>
    );
  };

  const changeRelatedStatus = (actionType) => {
    const apiAction = actionType === TAKE ? "lock" : "unlock";

    return API.lockContact(apiAction, activeRecipient.id);
  };

  const bookingBtnClick = () => {
    setParsedGirlsList({
      ...parsedGirlsList,
      isOpen: true, 
    });
  }

  const renderAvailableBtn = () => {
    return (
      <div className='chat-header__btn--available'>
        <TriStateSwitch
          value={activeRecipient.availability ? activeRecipient.availability : "waiting"}
          onChange={(value) => API.changeGirlsStatus(value, activeRecipient.id)}
        />
      </div>
    );
  };

  const showContactName = () => {
    if (activeRecipient.fn.startsWith('EX ')) {
      return <><span className="chat-header__ex">EX </span>{activeRecipient.fn.slice(3)}</>
    }

    return activeRecipient.fn;
  }

  const showProfileReviews = () => {
    if (!activeRecipient.reviewsCount || !activeRecipient.diva_default_id) return;

    props.openModal(MODAL_TYPES.profileReviews, {id: activeRecipient.diva_default_id});
  }

  return (
    <div
      className={classModifier("chat-header", [
        !activeRecipient.id && "hidden",
        activeRecipient.agentId && "with-agent",
        activeRecipient.availability && activeRecipient.availability
      ])}
    >
      {activeRecipient.id === "new_chat" ? (
        <ChatCreatorForm
          updateActiveContact={props.updateActiveContact}
          removeContactTab={props.removeContactTab}
        />
      ) : (
          <>
            <div className='chat-header__header'>
              <div
                className={`chat-header__ava-wrap ${isBlocked ? "blocked" : ""}`}
                onMouseEnter={isBlocked ? () => toggleContactInfo() : null}
                onMouseLeave={isBlocked ? () => toggleContactInfo() : null}
                onClick={
                  props.fullScreenMode
                    ? null
                    : () => props.openModal(MODAL_TYPES.contactCard, { contact: activeRecipient.id })
                }
              >
                <LazyLoadImage
                  src={getContactAvatar(activeRecipient)}
                  className='round-img'
                  alt='contact_avatar'
                />

                {blockInfo && (
                  <ContactBlockInfo
                    activeRecipient={activeRecipient}
                    userTimezone={props.userTimezone}
                  />
                )}
              </div>

              <div className='chat-header__name-box'>
                <p className='chat-header__name'>
                  <CopyToClipboardButton
                    className='item__copy'
                    value={activeRecipient.fn}
                    disabled={!activeRecipient.fn}
                    title='Copy name'
                  />
                  {showContactName()}
                </p>
                <p className='chat-header__deteils'>
                  {activeRecipient.type === CONTACT_TYPES.GIRL &&
                    <>
                      {activeRecipient.exclusive &&
                        <FontAwesomeIcon
                          className="chat-header__exclusive"
                          icon={faStar}
                          title="exclusive"
                        />
                      }
                        <span className="chat-header__age">
                          {activeRecipient.age && `${activeRecipient.age} y.o.`}&nbsp;
                        </span>
                      {!!activeRecipient.rating &&
                        <span className="chat-header__rating">
                          {activeRecipient.rating}&nbsp;
                        </span>
                      }
                      {!!activeRecipient.reviewsCount && 
                        <button 
                          className="chat-header__show-review-list"
                          onClick={showProfileReviews}
                        >
                          ({activeRecipient.reviewsCount})
                        </button>
                      } 
                    </>
                  }
                </p>
              </div>

              {/* <CopyToClipboardButton
              className='name__copy'
              value={activeRecipient.fn}
              svgWidth='13'
              svgHeight='13'
            /> */}

              <div className='chat-header__action-buttons'>
                {isActualBookings &&
                  <GirlActualBookingsBtn
                    className='chat-header__actual-bookings'
                    profileId={girlDivaId}
                  />
                }

                {activeRecipient.type === CONTACT_TYPES.GIRL && renderAvailableBtn()}
              
                <button
                  className={classModifier('chat-header__mark-button', [
                    activeRecipient.marked && 'marked'
                  ])}
                  onClick={() => props.toggleContactMark(activeRecipient)}
                  title={`${activeRecipient.marked ? 'Unmark' : 'Mark'}`}
                >
                  <span></span>
                </button>

                <button
                  className={classModifier('chat-header__pin-icon', [
                    activeRecipient.pinned && 'pinned'
                  ])}
                  onClick={() => props.toggleContactPin(activeRecipient)}
                  title={`${activeRecipient.pinned ? 'Unpin' : 'Pin'}`}
                >
                  <FontAwesomeIcon icon={faMapPin} />
                </button>

                {props.type === CHAT_TYPES.CLIENT &&
                  <button
                    className='chat-header__session'
                    onMouseDown={() => toggleSessionMenu()}
                    title='start session'
                    disabled={!props.showSalesButton}
                  >
                    <SvgIcon
                      width='16px'
                      height='16px'
                      fill='#808080'
                      icon='puzzle'
                    />
                  </button>
                }

                {props.type === CHAT_TYPES.CLIENT && renderRelatedBtn()}
              </div>

              <ContactDetails
                divaProfile={divaProfile}
                activeRecipient={activeRecipient} 
              />
            </div>

            <div className="chat-header__tags" ref={headerTagsRef}>
              {!!compressedTags.length &&
                compressedTags.map(tag => (
                  <span
                    key={tag.id}
                    className="chat-header__tags--tag"
                    title={tag.title}
                  >
                    {tag.title}
                  </span>
                ))
              }
            </div>

            <div className='chat-header__footer' ref={msgRef}>
              <ContactTels
                callFromChat={props.callFromChat}
                activeRecipient={activeRecipient}
                isCopyEnable={true}
              />

              <ContactEmails
                activeRecipient={activeRecipient}
              />

              {activeRecipient.agentId && <ContactAgent agentId={activeRecipient.agentId} />}

              {activeRecipient.type === 1 && activeRecipient.uncompletedBooking && (
                <BookingIcon
                  classPrefix="contact-bookings__open"
                  booking={activeRecipient.closestBooking}
                  userTimezone={props.userTimezone}
                  onClick={() => props.openModal(MODAL_TYPES.bookingsList, { contactId: activeRecipient.id })}
                />
              )}

              {activeRecipient.type === 1 && !activeRecipient.closestBooking && 
                <FontAwesomeIcon
                  className="contact-bookings__open booking-icon"
                  icon={faBootstrap}
                  color="#808080"
                  onClick={bookingBtnClick}
                />
              }

              {parsedGirlsList.isOpen && 
                <ParsedGirlList 
                  msgRef={msgRef}
                  activeRecipient={activeRecipient}
                  addNewArrayGirlsToState={props.addNewArrayGirlsToState}
                  parsedGirlsList={parsedGirlsList}
                  updateActiveContact={props.updateActiveContact}
                  setParsedGirlsList={setParsedGirlsList}
                />
              }

              {activeRecipient.type === 2 && !!activeRecipient.urls.length && (
                <a
                  href={activeRecipient.urls[0].url}
                  target="_blank"
                  className="chat-header__external-link"
                >
                  <FontAwesomeIcon icon={faExternalLinkSquareAlt} />
                </a>
              )}

              {activeRecipient.type === 2 && props.canBookFromBuffer && divaProfile &&
              // we should check if profile exist, because in some moments when we add to buffer girl, we cat not diva_id
              // but id_new, this is problem (of bug) FIXME
                <span className="chat-header__booked-dropdown" >
                  <BookButton
                    profileId={activeRecipient.diva_id}
                    isFromSessionChat
                    className={'booked-dropdown'}
                  />
                </span>
              }
            </div>

            {isSessionMenuOpen &&
              <div ref={sessionMenuRef}>
                <DropWrapper
                  dropWrapperRef={sessionMenuRef}
                  closeDropWrapper={toggleSessionMenu}
                >
                  <StartSessionBtn
                    activeRecipientId={activeRecipient.id}
                    toggleSalesBtn={toggleSessionMenu}
                  />
                </DropWrapper>
              </div>
            }
          </>
        )}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  divaProfile: state.divaGirls.entities[ownProps.activeRecipient.diva_default_id],
  userTimezone: selectUserTimezone(state),
});

const mapDispatchToProps = {
  openModal,
  toggleContactPin,
  toggleContactMark,
  addDivaGirlsToEntities,
  addNewArrayGirlsToState,
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(ChatHeader));
