import React, { useState, useEffect } from 'react';

import './ChatListSearchFilterZone.scss';
import { ALL_FILTER } from '../../../../ducks/contacts';
import { usePrevious, useDidUpdate } from '../../../../hooks';

import ChatListFilter from './ChatListFilter';
import SearchInput from '../../../../components/SearchInput/SearchInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments, faSearch, faUsers } from '@fortawesome/free-solid-svg-icons';
import { classModifier } from '../../../../utils';


const ChatListSearchFilterZone = ({
  initialQuery,
  activeTab,
  activeFilter,
  searchSource,
  ...props
}) => {
  const [query, setQuery] = useState(initialQuery || '');
  const prevActiveTab = usePrevious(activeTab);

  useEffect(() => {
    if ((query && activeFilter !== ALL_FILTER) || (activeTab !== prevActiveTab)) {
      setQuery(initialQuery);
    }
  }, [activeTab, activeFilter]);

  return (
    <div className="filter-zone">
      <SearchInput
        classPrefix="filter-zone"
        startSearch={props.startSearch}
        stopSearch={props.stopSearch}
        query={query}
        setQuery={setQuery}
        showClearSearchBtn={props.showClearSearchBtn}
      />

      {props.showSearchSourceToggle &&
        <button
          className="filter-zone__toggle-btn"
          onClick={() => props.toggleSearchSource(activeTab)}
        >
          <div className="filter-zone__toggle-btn-icon-wrap">
            <FontAwesomeIcon
              icon={searchSource === 'contacts' ? faComments : faUsers}
              className="filter-zone__toggle-btn-icon"
            />
          </div>
          <div className="filter-zone__toggle-btn-icon-wrap filter-zone__toggle-btn-icon-wrap--active">
            <FontAwesomeIcon
              icon={searchSource === 'contacts' ? faUsers : faComments}
              className="filter-zone__toggle-btn-icon"
            />
          </div>
        </button>
      }

      <ChatListFilter
        isQuery={!!query.length}
        setQuery={setQuery}
        filters={props.filters}
        defaultFilter={activeFilter}
      />
    </div>
  );
}

export default React.memo(ChatListSearchFilterZone);
