import API from "../api/api";

import {
  UPDATE_CLIENT,
  REMOVE_CLIENT,
  GET_CLIENTS_FROM_TABS,
  UPDATE_GIRL,
  TOGGLE_CONTACTS_SEARCH_SOURCE,
  updateRecentTabs,
} from "./contacts";
import { NEW_OUTGOING_CALL, NEW_INCOMING_CALL, NEW_INCOMING_QUEUE_CALL } from "./calls";
import {
  SET_GIRL_UPDATE_PENDING,
  GET_GIRL_TIMELINE,
  GET_GIRL_TIMELINE_PENDING,
  GET_GIRL_MSG_REMINDERS,
  CLEAN_GIRL_MSG_REMINDERS,
  UPDATE_GIRL_TIMELINE,
  UPDATE_ACTIVE_GIRL,
  REMOVE_GIRL_TAB,
  REMOVE_GIRL_TABS,
  UNREAD_GIRL_MESSAGES,
  GET_GIRL_CONVERSATION_MEDIA,
  CLEAN_GIRL_CONVERSATION_MEDIA,
  GET_GIRL_TIMELINE_ERROR,
  GET_GIRL_MSG_CONTEXT,
  UPDATE_SEARCHED_GIRL_MESSAGES,
  GLOBAL_GIRL_MESSAGE_SEARCH,
  UPDATE_GLOBAL_GIRL_MESSAGE_SEARCH,
  STOP_SEARCH_GIRL_MESSAGES,
  GET_SEARCHED_GIRL_MESSAGES,
  UPDATE_GIRL_MSG_REMINDERS,
  REMOVE_GIRL_REMIND_MSG,
  REMOVE_GIRL_REMINDER_FROM_MAIN_TIMELINE,
  UPDATE_GIRL_MSG,
  DELETE_GIRL_MSG,
} from "./girlChats";
import { onRemoveChatTab, formatInteractionType } from "./roomChats";
import { getDateByTimezoneOffset, getShortDate } from "../utils";

import { NEW_MISSED_CALL, UPDATE_VOICEMAIL_STATUS } from "./missedCalls";
import { addNotification } from "./notifications";
import { CHAT_SOURCES, CHAT_TYPES, CONTACT_TYPES, INTERACTION_TYPES } from "../config/constants";
import { openModal, MODAL_TYPES } from "./activeWindows";
import { selectRecentTabs } from "../selectors/selectors";


export const SET_CLIENT_UPDATE_PENDING = 'SET_CLIENT_UPDATE_PENDING';

export const GET_SEARCHED_CLIENT_MESSAGES = 'GET_SEARCHED_CLIENT_MESSAGES';
export const UPDATE_SEARCHED_CLIENT_MESSAGES = 'UPDATE_SEARCHED_CLIENT_MESSAGES';
export const GLOBAL_CLIENT_MESSAGE_SEARCH = 'GLOBAL_CLIENT_MESSAGE_SEARCH';
export const UPDATE_GLOBAL_CLIENT_MESSAGE_SEARCH = 'UPDATE_GLOBAL_CLIENT_MESSAGE_SEARCH';
export const STOP_SEARCH_CLIENT_MESSAGES = 'STOP_SEARCH_CLIENT_MESSAGES';
export const REMOVE_CLIENT_MSG_REMIND = 'REMOVE_CLIENT_MSG_REMIND';
export const REMOVE_CLIENT_REMINDER_FROM_MAIN_TIMELINE = "REMOVE_CLIENT_REMINDER_FROM_MAIN_TIMELINE";

export const UPDATE_ACTIVE_CLIENT = "UPDATE_ACTIVE_CLIENT";

export const GET_CLIENT_TIMELINE_PENDING = "GET_CLIENT_TIMELINE_PENDING";
export const GET_CLIENT_TIMELINE = "GET_CLIENT_TIMELINE";
export const GET_CLIENT_TIMELINE_ERROR = "GET_CLIENT_TIMELINE_ERROR";

export const UPDATE_CLIENT_TIMELINE = "UPDATE_CLIENT_TIMELINE";

export const CHANGE_MESSAGE_STATUS = "CHANGE_MESSAGE_STATUS";
export const CHANGE_CALL_STATUS = "CHANGE_CALL_STATUS";
export const REMOVE_CLIENT_TAB = 'REMOVE_CLIENT_TAB';
export const REMOVE_CLIENT_TABS = 'REMOVE_CLIENT_TABS';
export const SET_CLIENT_TABS = 'SET_CLIENT_TABS';

export const NEW_OUTGOING_MESSAGE = "NEW_OUTGOING_MESSAGE";
export const NEW_INCOMING_MESSAGE = "NEW_INCOMING_MESSAGE";

export const UNREAD_CLIENT_MESSAGES = "UNREAD_CLIENT_MESSAGES";
export const NEW_MESSAGE_ATTACHMENTS = "NEW_MESSAGE_ATTACHMENTS";

export const GET_CLIENT_CONVERSATION_MEDIA = "GET_CLIENT_CONVERSATION_MEDIA";
export const UPDATE_CLIENT_CONVERSATION_MEDIA = "UPDATE_CLIENT_CONVERSATION_MEDIA";
export const UPDATE_GIRL_CONVERSATION_MEDIA = "UPDATE_GIRL_CONVERSATION_MEDIA";
export const CLEAN_CLIENT_CONVERSATION_MEDIA = "CLEAN_CLIENT_CONVERSATION_MEDIA";

export const GET_CLIENT_MSG_REMINDERS = "GET_CLIENT_MSG_REMINDERS";
export const CLEAN_CLIENT_MSG_REMINDERS = 'CLEAN_CLIENT_MSG_REMINDERS';
export const UPDATE_CLIENT_MSG_REMINDERS = 'UPDATE_CLIENT_MSG_REMINDERS';

export const GET_CLIENT_MSG_CONTEXT = "GET_CLIENT_MSG_CONTEXT";
export const GET_GLOBAL_CLIENT_MSG_CONTEXT = "GET_GLOBAL_CLIENT_MSG_CONTEXT";

export const UPDATE_CLIENT_MSG_CONTEXT = "UPDATE_CLIENT_MSG_CONTEXT";
export const UPDATE_GIRL_MSG_CONTEXT = "UPDATE_GIRL_MSG_CONTEXT";

export const GET_CLIENT_DATE_MSG_CONTEXT = "GET_CLIENT_DATE_MSG_CONTEXT";
export const UPDATE_CLIENT_DATE_MSG_CONTEXT = "UPDATE_CLIENT_DATE_MSG_CONTEXT";
export const CLEAN_CLIENT_DATE_MSG_CONTEXT = "CLEAN_CLIENT_DATE_MSG_CONTEXT";

export const GET_GIRL_DATE_MSG_CONTEXT = "GET_GIRL_DATE_MSG_CONTEXT";
export const UPDATE_GIRL_DATE_MSG_CONTEXT = "UPDATE_GIRL_DATE_MSG_CONTEXT";
export const CLEAN_GIRL_DATE_MSG_CONTEXT = "CLEAN_GIRL_DATE_MSG_CONTEXT";

export const CLEAN_CLIENT_MSG_CONTEXT = "CLEAN_CLIENT_MSG_CONTEXT";
export const CLEAN_GIRL_MSG_CONTEXT = "CLEAN_GIRL_MSG_CONTEXT";

export const NEW_SCHEDULED_MESSAGE = "NEW_SCHEDULED_MESSAGE";

export const GET_CLIENT_SCHEDULED_MSGS_COUNT = "GET_CLIENT_SCHEDULED_MSGS_COUNT";
export const GET_CLIENT_SCHEDULED_MSGS = "GET_CLIENT_SCHEDULED_MSGS";
export const UPDATE_CLIENT_SCHEDULED_MSGS = 'UPDATE_CLIENT_SCHEDULED_MSGS';
export const CLEAN_CLIENT_SCHEDULED_MSGS = "CLEAN_CLIENT_SCHEDULED_MSGS";

export const GET_GIRL_SCHEDULED_MSGS_COUNT = "GET_GIRL_SCHEDULED_MSGS_COUNT";
export const GET_GIRL_SCHEDULED_MSGS = "GET_GIRL_SCHEDULED_MSGS";
export const UPDATE_GIRL_SCHEDULED_MSGS = 'UPDATE_GIRL_SCHEDULED_MSGS';
export const CLEAN_GIRL_SCHEDULED_MSGS = "CLEAN_GIRL_SCHEDULED_MSGS";

export const CREATE_NEW_CHAT = "CREATE_NEW_CHAT";
export const DELETE_NEW_CHAT = "DELETE_NEW_CHAT";

export const PIN_CLIENT_MSG = 'PIN_CLIENT_MSG';
export const PIN_GIRL_MSG = 'PIN_GIRL_MSG';
export const FIX_CLIENT_TAB = 'FIX_CLIENT_TAB';
export const INIT_UNFIXED_CLIENT_TAB = 'INIT_UNFIXED_CLIENT_TAB';

export const UPDATE_CLIENT_MSG = 'UPDATE_CLIENT_MSG';
export const DELETE_CLIENT_MSG = 'DELETE_CLIENT_MSG';

export const SET_CLIENTS_RECENT_TABS = 'SET_CLIENTS_RECENT_TABS';

export const START_SEARCH_CLIENTS_MSGS = 'START_SEARCH_CLIENTS_MSGS';
export const START_SEARCH_GIRLS_MSGS = 'START_SEARCH_GIRLS_MSGS';

export const DELETE_TELEGRAM_MSG = 'DELETE_TELEGRAM_MSG';

export const LIMIT = 15;

export const getContactTimeline = (contactId, chatType, userTimezone, withCancel = true, query = '') => dispatch => {
  if (contactId === "new_chat") {
    return dispatch({
      type: GET_CLIENT_TIMELINE,
      payload: {
        contactId
      }
    });
  }

  dispatch({
    type:
      chatType === CHAT_TYPES.CLIENT
        ? GET_CLIENT_TIMELINE_PENDING
        : GET_GIRL_TIMELINE_PENDING
  });

  API.getInteractions(contactId, null, withCancel)
    .then(res => {
      dispatch({
        type: chatType === CHAT_TYPES.CLIENT ? GET_CLIENT_TIMELINE : GET_GIRL_TIMELINE,
        payload: {
          ...res.data,
          messages: groupInteractionsByTimezone(res.data.messages, userTimezone),
          contactId
        }
      });
    })
    .then(() => {
      if (query) {
        dispatch({
          type: chatType === CHAT_TYPES.CLIENT
            ? START_SEARCH_CLIENTS_MSGS
            : START_SEARCH_GIRLS_MSGS,
          payload: query
        })
      }
    })
    .catch(err => {
      console.log(err);
      dispatch({
        type:
          chatType === CHAT_TYPES.CLIENT
            ? GET_CLIENT_TIMELINE_ERROR
            : GET_GIRL_TIMELINE_ERROR,
        payload: contactId
      });
    });
};

export const updateContactTimeline = (contact, page, loadDirection, userTimezone) => dispatch => {
  API.getInteractions(contact.id, page, true)
    .then(res => {
      // if we have 10 messages only and offset = 10 we will get []
      if (!res.data.messages.length) return;

      dispatch({
        type: contact.type === 1 ? UPDATE_CLIENT_TIMELINE : UPDATE_GIRL_TIMELINE,
        payload: {
          ...res.data,
          messages: groupInteractionsByTimezone(res.data.messages, userTimezone),
          loadDirection,
          userTimezone
        }
      });
    })
    .catch(err => console.log(err));
};

export const pinClientMsg = (msg, callerId) => dispatch => {
  dispatch({
    type: msg.caller.type === 1
      ? PIN_CLIENT_MSG
      : PIN_GIRL_MSG,
    payload: null
  });

  return API.pinClientMsg(msg.id, callerId)
    .then(() => {
      dispatch({
        type: msg.caller.type === 1
          ? PIN_CLIENT_MSG
          : PIN_GIRL_MSG,
        payload: msg
      });
    })
    .catch(console.error);
};

export const unpinClientMsg = (chatId, chatType) => dispatch => {
  return API.unpinClientMsg(chatId)
    .then(() => {
      dispatch({
        type: chatType === CHAT_TYPES.CLIENT
          ? PIN_CLIENT_MSG
          : PIN_GIRL_MSG,
        payload: null,
      });
    })
    .catch(console.error);
};

export const updateActiveContact = (contact, fromMoreTabs = false) => (dispatch, getState) => {
  const chatType = contact.type === CONTACT_TYPES.CLIENT ? CONTACT_TYPES.CLIENT : CONTACT_TYPES.GIRL;
  const recentTabsFromState = selectRecentTabs(getState(), chatType);

  // If the tab is fixed, unfix it when activating a contact with the same ID
  if (recentTabsFromState.visible.includes(contact.id)) {
    const updatedRecentTabs = {
      all: recentTabsFromState.all.filter(tabId => tabId !== contact.id),
      visible: recentTabsFromState.visible.filter(tabId => tabId !== contact.id),
    }

    dispatch(updateRecentTabs(updatedRecentTabs, chatType));
  }

  // Contact in tabs but not active
  dispatch({
    type: contact.type === 1 ? UPDATE_ACTIVE_CLIENT : UPDATE_ACTIVE_GIRL,
    payload: {
      contact,
      fromMoreTabs
    }
  });
};

export const removeContactTab = (removedId, chatType) => dispatch => {
  chatType === CHAT_TYPES.ROOM
    ? dispatch(onRemoveChatTab(removedId))
    : dispatch(onRemoveContactTab(removedId, chatType)) // else only delete from tabs
};

export const onRemoveContactTab = (removedId, chatType) => dispatch => {
  dispatch({
    type: chatType === CHAT_TYPES.CLIENT ? REMOVE_CLIENT_TAB : REMOVE_GIRL_TAB,
    payload: removedId
  });
};

export const removeContactTabsFromContextMenu = (
  activeTab,
  type,
  query
) => dispatch => {
  dispatch({
    type: type === 1 ? REMOVE_CLIENT_TABS : REMOVE_GIRL_TABS,
    payload: { activeTab, query }
  });
};

export const setClientTabs = (newTabs) => dispatch => {
  dispatch({
    type: SET_CLIENT_TABS,
    payload: newTabs,
  });
}

export const sendMessage = (message, recipient) => dispatch => {
  return API.sendMessage(message, recipient)
    .then(() => true)
    .catch(err => {
      console.log(err);

      if (err.response && err.response.data && err.response.data.status === 403) {
        dispatch(openModal(MODAL_TYPES.error, { text: err.response.data.message }));
      }
    });
};

export const newIncomingMessage = (message, userTimezone) => dispatch => {
  dispatch({
    type: NEW_INCOMING_MESSAGE,
    payload: {
      interaction: message,
      userTimezone
    }
  });

  // showNotification(message, () => dispatch(updateActiveContact(message.caller)));
};

export const newOutgoingMessage = (message, userTimezone) => dispatch => {
  dispatch({
    type: NEW_OUTGOING_MESSAGE,
    payload: {
      interaction: message,
      userTimezone
    }
  });
};

export const changeCallStatus = call => dispatch => {
  dispatch({
    type: CHANGE_CALL_STATUS,
    payload: call
  });
};

export const changeMessageStatus = call => dispatch => {
  dispatch({
    type: CHANGE_MESSAGE_STATUS,
    payload: call
  });
};

export const markMessageAsUnread = (msg) => dispatch => {
  if (msg.type !== 4) return;

  return API.markMessageAsUnread(msg.id)
    .then(res => {
      dispatch({
        type:
          msg.caller.type === 1 ? UNREAD_CLIENT_MESSAGES : UNREAD_GIRL_MESSAGES,
        payload: {
          interaction: { ...msg, count: res.data, isIamRelated: true },
        }
      });
    })
    .catch(err => console.log(err));
};

export const markContactChatAsRead = contact => dispatch => {
  return API.markContactChatAsRead(contact.id)
    .then(res => {
      dispatch(updateActiveContact(contact));
    })
    .catch(err => console.log(err));
};

export const setMessageReminder = (msg, inTime) => dispatch => {
  if (msg.type !== 4 && msg.type !== 3 && msg.type !== 9 && msg.type !== 10) return;
  console.log(msg);

  return API.setMessageReminder(msg.caller_id, msg.id, inTime)
    // TODO: it was hidden, because it breaks notifications-logic
    // .then(() => {
    //   dispatch(addNotification({
    //     type: 'set-message-reminder',
    //     color: 'yellow',
    //     duration: 5,
    //     userId: msg.caller_id,
    //     position: 'topright',
    //     message: msg,
    //     data: msg.dateCreated,
    //     isCustom: true,
    //   }))
    // })
    .catch(console.error);
};

export const removeMessageReminder = (remindId, type, isNotificationEnd = false, isMainTimeline = false) => dispatch => {
  if (!isMainTimeline) {
    if (isNotificationEnd) {
      dispatch({
        type: type === 1
          ? REMOVE_CLIENT_MSG_REMIND
          : REMOVE_GIRL_REMIND_MSG,
        payload: remindId
      });
    }
    else {
      return API.removeMessageReminder(remindId)
        .then(() => {
          dispatch({
            type: type === 1
              ? REMOVE_CLIENT_MSG_REMIND
              : REMOVE_GIRL_REMIND_MSG,
            payload: remindId
          });
        })
        .catch(console.error);
    }
  }
  else {
    dispatch({
      type: type === 1
        ? REMOVE_CLIENT_REMINDER_FROM_MAIN_TIMELINE
        : REMOVE_GIRL_REMINDER_FROM_MAIN_TIMELINE,
      payload: remindId
    })
  }
};

export const newMsgAttachments = attachments => dispatch => {
  dispatch({
    type: NEW_MESSAGE_ATTACHMENTS,
    payload: attachments
  });
};

export const getTimelineMedia = (contact, userTimezone) => dispatch => {
  dispatch({
    type:
      contact.type === 1
        ? GET_CLIENT_TIMELINE_PENDING
        : GET_GIRL_TIMELINE_PENDING
  });

  return API.getConversationMedia(contact.id)
    .then(res => {
      dispatch({
        type:
          contact.type === 1
            ? GET_CLIENT_CONVERSATION_MEDIA
            : GET_GIRL_CONVERSATION_MEDIA,
        payload: {
          ...res.data,
          messages: groupInteractionsByTimezone(res.data.messages, userTimezone),
          chatSource: CHAT_SOURCES.MEDIA,
        }
      });
    })
    .catch(console.error);
};

export const updateContactTimelineMedia = (contact, page, loadDirection, userTimezone) => dispatch => {
  return API.getConversationMedia(contact.id, page)
    .then(res =>
      dispatch({
        type:
          contact.type === 1
            ? UPDATE_CLIENT_CONVERSATION_MEDIA
            : UPDATE_GIRL_CONVERSATION_MEDIA,
        payload: {
          ...res.data,
          messages: groupInteractionsByTimezone(res.data.messages, userTimezone),
          userTimezone,
          loadDirection
        }
      })
    )
    .catch(console.error);
};

export const cleanTimelineMedia = contactType => dispatch => {
  dispatch({
    type:
      contactType === 1
        ? CLEAN_CLIENT_CONVERSATION_MEDIA
        : CLEAN_GIRL_CONVERSATION_MEDIA
  });
};

export const getContactReminders = (contact, userTimezone) => dispatch => {
  dispatch({
    type:
      contact.type === 1
        ? GET_CLIENT_TIMELINE_PENDING
        : GET_GIRL_TIMELINE_PENDING
  });

  return API.getContactReminders(contact.id)
    .then(res => {
      dispatch({
        type:
          contact.type === 1
            ? GET_CLIENT_MSG_REMINDERS
            : GET_GIRL_MSG_REMINDERS,
        payload: {
          ...res.data,
          messages: groupInteractionsByTimezone(res.data.messages, userTimezone),
          chatSource: CHAT_SOURCES.REMINDERS,
        }
      });
    })
    .catch(console.error);
};

export const cleanContactReminders = contactType => dispatch => {
  dispatch({
    type:
      contactType === 1
        ? CLEAN_CLIENT_MSG_REMINDERS
        : CLEAN_GIRL_MSG_REMINDERS
  });
};


export const updateContactReminders = (contact, page, loadDirection, userTimezone) => dispatch => {
  return API.getContactReminders(contact.id, page)
    .then(res =>
      dispatch({
        type:
          contact.type === 1
            ? UPDATE_CLIENT_MSG_REMINDERS
            : UPDATE_GIRL_MSG_REMINDERS,
        payload: {
          ...res.data,
          messages: groupInteractionsByTimezone(res.data.messages, userTimezone),
          userTimezone,
          loadDirection
        }
      })
    )
    .catch(console.error);
};



export const getContactMessageContext = (contextMsgId, type, contact = null, query = "", userTimezone) => async dispatch => {
  let contactType = contact ? contact.type : type;

  // TODO: pass instead int array and check like type.includes(contact.type)
  if (contactType === 3 || contactType === 4 || contactType === 5) {
    contactType = 2;
  }

  // cleanContactMsgContext
  if (!contextMsgId) {
    if (query) {
      return dispatch(searchContactMessage(query, contact));
    }
    return dispatch({
      type: contactType === 1 ? CLEAN_CLIENT_MSG_CONTEXT : CLEAN_GIRL_MSG_CONTEXT
    });
  }

  // if contact passed it is globalMsgSearch so we need update contact => get contact timeline => make search if query passed => and get contact msg context
  if (contact) {
    dispatch({
      type: contactType === 1 ? UPDATE_ACTIVE_CLIENT : UPDATE_ACTIVE_GIRL,
      payload: {
        contact,
        isGlobalMsgSearch: true,
        showSearchQuery: contactType !== type
      }
    });

    // search contact message if query passed
    // if (query) {
    //   await dispatch(searchContactMessage(query, contact));
    // }

  } else {
    dispatch({
      type:
        contactType === 1
          ? GET_CLIENT_TIMELINE_PENDING
          : GET_GIRL_TIMELINE_PENDING
    });
  }

  API.getMessageContext(contextMsgId)
    .then(res => {
      dispatch({
        type: contactType === 1 ? GET_CLIENT_MSG_CONTEXT : GET_GIRL_MSG_CONTEXT,
        payload: {
          contextMsgId,
          ...res.data,
          messages: groupInteractionsByTimezone(res.data.messages, userTimezone)
        }
      });
    })
    .catch(console.error);
};

export const updateContactMessageContext = (contextMsgId, page, loadDirection, contact, userTimezone) => dispatch => {
  API.getMessageContext(contextMsgId, page)
    .then(res => {
      dispatch({
        type: contact.type === 1 ? UPDATE_CLIENT_MSG_CONTEXT : UPDATE_GIRL_MSG_CONTEXT,
        payload: {
          ...res.data,
          messages: groupInteractionsByTimezone(res.data.messages, userTimezone),
          userTimezone,
          loadDirection
        }
      });
    })
    .catch(console.error);
}

export const createContactChat = () => dispatch => dispatch({ type: CREATE_NEW_CHAT });

export const fixClientTab = (tabId) => dispatch => dispatch({ type: FIX_CLIENT_TAB, payload: tabId });

export const initUnfixedClientTab = (tabId) => dispatch => dispatch({ type: INIT_UNFIXED_CLIENT_TAB, payload: tabId });

export const searchContactMessage = (query, contact, userTimezone) => dispatch => {
  dispatch({
    type: contact.type === 1
      ? GET_CLIENT_TIMELINE_PENDING
      : GET_GIRL_TIMELINE_PENDING
  })

  return API.searchContactMessage(contact.id, query)
    .then(res => {
      dispatch({
        type: contact.type === 1
          ? GET_SEARCHED_CLIENT_MESSAGES
          : GET_SEARCHED_GIRL_MESSAGES,
        payload: {
          ...res.data,
          messages: groupInteractionsByTimezone(res.data.messages, userTimezone),
          query
        }
      });
    })
    .catch(err => console.log(err));
};

export const updateContactMessageSearch = (contact, page, loadDirection, query, userTimezone) => dispatch => {
  dispatch({
    type: contact.type === 1
      ? SET_CLIENT_UPDATE_PENDING
      : SET_GIRL_UPDATE_PENDING,
    payload: true,
  });

  API.searchContactMessage(contact.id, query, page)
    .then(res => {
      dispatch({
        type: contact.type === 1
          ? UPDATE_SEARCHED_CLIENT_MESSAGES
          : UPDATE_SEARCHED_GIRL_MESSAGES,
        payload: {
          ...res.data,
          messages: groupInteractionsByTimezone(res.data.messages, userTimezone),
          userTimezone,
          loadDirection
        }
      });
    })
    .catch(err => console.log(err));
};

export const searchGlobalContactMessage = (query, contact, userTimezone) => dispatch => {
  dispatch({
    type: contact.type === 1
      ? GET_CLIENT_TIMELINE_PENDING
      : GET_GIRL_TIMELINE_PENDING
  })

  API.searchContactMessage('', query)
    .then(res => {
      dispatch({
        type: contact.type === 1
          ? GLOBAL_CLIENT_MESSAGE_SEARCH
          : GLOBAL_GIRL_MESSAGE_SEARCH,
        payload: {
          ...res.data,
          messages: groupInteractionsByTimezone(res.data.messages, userTimezone),
          query
        }
      });
    })
    .catch(err => console.log(err));
};

export const updateGlobalContactMessageSearch = (contact, page, query, loadDirection, userTimezone) => (dispatch, getState) => {
  dispatch({
    type: contact.type === 1
      ? SET_CLIENT_UPDATE_PENDING
      : SET_GIRL_UPDATE_PENDING,
    payload: true,
  });

  API.searchContactMessage('', query, page)
    .then(res => {
      dispatch({
        type: contact.type === 1
          ? UPDATE_GLOBAL_CLIENT_MESSAGE_SEARCH
          : UPDATE_GLOBAL_GIRL_MESSAGE_SEARCH,
        payload: {
          ...res.data,
          messages: groupInteractionsByTimezone(res.data.messages, userTimezone),
          userTimezone,
          loadDirection
        }
      });
    })
    .catch(err => console.log(err));
};

export const stopContactMessageSearch = (contact, status) => dispatch => {
  dispatch({
    type: contact.type === 1
      ? STOP_SEARCH_CLIENT_MESSAGES
      : STOP_SEARCH_GIRL_MESSAGES,
    payload: status
  });
};

export const getContactDateMsgContext = (contextDate, contactId, chatType, userTimezone) => dispatch => {
  dispatch({
    type: chatType === 1
      ? GET_CLIENT_TIMELINE_PENDING
      : GET_GIRL_TIMELINE_PENDING
  })

  return API.getContactDateMsgContext(contextDate, contactId)
    .then(res => {
      dispatch({
        type: chatType === 1 ? GET_CLIENT_DATE_MSG_CONTEXT : GET_GIRL_DATE_MSG_CONTEXT,
        // payload: res.data
        payload: {
          ...res.data,
          messages: groupInteractionsByTimezone(res.data.messages, userTimezone)
        }
      });
    })
}

export const updateContactDateMsgContext = (contact, page, loadDirection, contextDate, userTimezone) => dispatch => {
  const contextUTCDateByLocalTimezoneOffset = new Date(contextDate) - new Date().getTimezoneOffset() * 60000;

  return API.getContactDateMsgContext(contextUTCDateByLocalTimezoneOffset, contact.id, page)
    .then(res => {
      // if we have 10 messages only and offset = 10 we will get []
      if (!res.data.messages.length) return;

      dispatch({
        type: contact.type === 1
          ? UPDATE_CLIENT_DATE_MSG_CONTEXT
          : UPDATE_GIRL_DATE_MSG_CONTEXT,
        payload: {
          ...res.data,
          messages: groupInteractionsByTimezone(res.data.messages, userTimezone),
          userTimezone,
          loadDirection
        }
      });
    });
}

export const cleanContactDateMsgContext = chatType => dispatch => {
  dispatch({
    type:
      chatType === 1
        ? CLEAN_CLIENT_DATE_MSG_CONTEXT
        : CLEAN_GIRL_DATE_MSG_CONTEXT
  });
};

export const getScheduledMsgs = (contact, userTimezone) => dispatch => {
  dispatch({
    type:
      contact.type === 1
        ? GET_CLIENT_TIMELINE_PENDING
        : GET_GIRL_TIMELINE_PENDING
  });

  return API.getContactScheduledMsgs(contact.id)
    .then(res => {
      dispatch({
        type:
          contact.type === 1
            ? GET_CLIENT_SCHEDULED_MSGS
            : GET_GIRL_SCHEDULED_MSGS,
        payload: {
          ...res.data,
          messages: groupInteractionsByTimezone(res.data.messages, userTimezone),
          chatSource: CHAT_SOURCES.SCHEDULED_MSGS,
        }
      });
    })
    .catch(console.error);
}

export const updateScheduledMsgs = (contact, page, loadDirection, userTimezone) => dispatch => {
  return API.getContactScheduledMsgs(contact.id, page)
    .then(res => {
      dispatch({
        type:
          contact.type === 1
            ? UPDATE_CLIENT_SCHEDULED_MSGS
            : UPDATE_GIRL_SCHEDULED_MSGS,
        payload: {
          ...res.data,
          messages: groupInteractionsByTimezone(res.data.messages, userTimezone),
          userTimezone,
          loadDirection
        }
      });
    })
    .catch(console.error);
}

export const cleanScheduledMsgs = chatType => dispatch => {
  dispatch({
    type:
      chatType === 1
        ? CLEAN_CLIENT_SCHEDULED_MSGS
        : CLEAN_GIRL_SCHEDULED_MSGS
  });
};

export const newScheduledMsg = (msg, userTimezone) => dispatch => {
  dispatch({
    type: NEW_SCHEDULED_MESSAGE,
    payload: {
      interaction: msg,
      userTimezone
    }
  });
};

export const updateContactMsg = (msg) => dispatch => {
  dispatch({
    type: msg.caller.type === CONTACT_TYPES.CLIENT
      ? UPDATE_CLIENT_MSG
      : UPDATE_GIRL_MSG,
    payload: msg,
  });
};

export const deleteContactMsg = (msg) => dispatch => {
  dispatch({
    type: msg.caller.type === CONTACT_TYPES.CLIENT
      ? DELETE_CLIENT_MSG
      : DELETE_GIRL_MSG,
    payload: msg,
  });
};

export const getScheduledMsgsCount = (contactId, contactType) => dispatch => {
  return API.getScheduledMsgsCount(contactId)
    .then(res => {
      dispatch({
        type: contactType === CHAT_TYPES.CLIENT
          ? GET_CLIENT_SCHEDULED_MSGS_COUNT
          : GET_GIRL_SCHEDULED_MSGS_COUNT,
        payload: res.data,
      })
    });
};

export const startSearchContactsMsgsInChat = (chatType, query) => dispatch => {
  dispatch({
    type: chatType === CHAT_TYPES.CLIENT
      ? START_SEARCH_CLIENTS_MSGS
      : START_SEARCH_GIRLS_MSGS,
    payload: query
  });
};

export const deleteTelegramMsgRequest = id => dispatch => {
  return API.deleteTelegramMsg(id)
    .catch(err => {
      console.log(err);

      if (err.response?.data?.status === 403) {
        dispatch(openModal(MODAL_TYPES.error, {text: err.response.data.message}));
      }
    });
};

export const deleteTelegramMsg = payload => ({
  type: DELETE_TELEGRAM_MSG,
  payload,
});

const initialState = {
  active: null,

  tabs: [],
  unfixedTab: null,
  recentTabs: {
    all: [], // all recent tabs we worked with
    visible: [], // tabs that can be returned by undo button.
  },

  pinnedMsg: null,

  timelinePending: false,
  updatePending: false,

  timeline: [],
  timelinePageCount: null,
  timelineLowerLoadedPage: null,
  timelineCurrentPage: null,
  newInteractionType: null,


  search: '',
  isGlobalMsgSearch: false,
  showSearchQuery: false,

  contextMsgId: null,
  contextDate: null,

  chatSource: CHAT_SOURCES.MSGS,

  scheduledMsgsCount: 0,

  auxiliaryTimeline: [],
  auxiliaryPageCount: null,
  auxiliaryLowerLoadedPage: null,
  auxiliaryHigherLoadedPage: null,
  auxiliaryCurrentPage: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CLIENT_UPDATE_PENDING:
      return {
        ...state,
        updatePending: action.payload,
      };

    case GET_CLIENTS_FROM_TABS: {
      const getUpdatedActive = () => {
        if (action.payload.ids.includes(action.payload.active)) {
          // if we already have our active contact
          return action.payload.active;
        } else if (
          !!action.payload.ids.length &&
          !action.payload.ids.includes(action.payload.active)
        ) {
          // if our active has been changed => chose new active from tabs
          return action.payload.ids[0];
        } else return null;
      };

      const getUpdatedUnfixedTab = () => {
        if (state.unfixedTab && action.payload.ids.includes(state.unfixedTab)) {
          return state.unfixedTab
        }
        // if we have unfixedTab that was removed from server
        else {
          return null
        }
      }

      return {
        ...state,
        tabs: action.payload.ids,
        active: getUpdatedActive(),
        unfixedTab: getUpdatedUnfixedTab()
      };
    }
    case INIT_UNFIXED_CLIENT_TAB: {
      return {
        ...state,
        unfixedTab: action.payload
      }
    }

    case FIX_CLIENT_TAB: {
      if (action.payload !== state.unfixedTab) return state;

      return {
        ...state,
        unfixedTab: null,
      }
    }
    case UPDATE_ACTIVE_CLIENT: {
      // if we mark msgs as read
      const clientId = action.payload.contact.id;
      if (clientId === 'new_chat') {
        return {
          ...state,
          active: "new_chat",
          timeline: [],
          timelinePageCount: 1,
          timelineLowerLoadedPage: 1,
          timelineCurrentPage: 1,
        }
      }
      if (clientId === state.active && !action.payload.showSearchQuery && !action.payload.fromMoreTabs) {
        return state;
      }
      if (clientId === state.active && action.payload.showSearchQuery) {
        return {
          ...state,
          isGlobalMsgSearch: action.payload.isGlobalMsgSearch ? true : false,
          showSearchQuery: action.payload.showSearchQuery ? true : false,
          search: action.payload.isGlobalMsgSearch ? state.search : '',
        };
      }

      const isInTabs = state.tabs.indexOf(clientId) !== -1;

      let updatedTabs = [...state.tabs];

      let updatedUnfixedTab = state.unfixedTab;

      if (action.payload.fromMoreTabs) {
        updatedTabs = [clientId, ...state.tabs.filter(id => id !== clientId)];

        if (clientId === state.active) {
          return {
            ...state,
            tabs: updatedTabs,
          }
        }
      }
      else if (!isInTabs) {
        if (state.unfixedTab) { //if tab not fixed replace her by action.payload.chatId
          if (updatedTabs.length === 20) { // limit - 20 msgs;
            updatedTabs[19] = clientId;
          }
          else {
            updatedTabs = updatedTabs.map(id => {
              if (id === state.unfixedTab) {
                return clientId;
              }
              return id;
            });
          }
        }
        else {
          if (updatedTabs.length === 20) { // limit - 20 msgs;
            updatedTabs[19] = clientId;
          }
          else {
            updatedTabs.push(clientId);
          }
        }

        updatedUnfixedTab = clientId;
      }


      return {
        ...state,
        tabs: updatedTabs,
        unfixedTab: updatedUnfixedTab,
        active: clientId,

        pinnedMsg: null,

        timeline: [],
        timelinePageCount: null,
        timelineLowerLoadedPage: null,
        timelineCurrentPage: null,

        auxiliaryTimeline: state.auxiliaryTimeline.length ? [] : state.auxiliaryTimeline,
        auxiliaryCurrentPage: null,
        auxiliaryLowerLoadedPage: null,
        auxiliaryHigherLoadedPage: null,
        auxiliaryPageCount: null,

        contextMsgId: null,
        contextDate: null,
        chatSource: CHAT_SOURCES.MSGS,
        scheduledMsgsCount: 0,

        isGlobalMsgSearch: action.payload.isGlobalMsgSearch ? true : false,
        showSearchQuery: action.payload.showSearchQuery ? true : false,
        search: action.payload.isGlobalMsgSearch ? state.search : '',
      };
    }
    case START_SEARCH_CLIENTS_MSGS: {
      return {
        ...state,
        timelinePending: true,
        showSearchQuery: true,
        search: action.payload
      }
    }
    case GET_CLIENT_TIMELINE_PENDING: {
      return {
        ...state,
        timelinePending: true,
        // auxiliaryTimeline: state.auxiliaryTimeline.length ? [] : state.auxiliaryTimeline,
        // auxiliaryCurrentPage: null,
        // auxiliaryPageCount: null,
        // auxiliaryLowerLoadedPage: null
      };
    }
    case GET_CLIENT_TIMELINE: {
      if (action.payload.contactId === "new_chat") {
        return state;
      }
      // additional check
      if (action.payload.contactId !== state.active) {
        return state;
      }
      if (!action.payload.messages.length) {
        return {
          ...state,
          timelinePending: false,
          timelinePageCount: action.payload.pageCount,
          timelineLowerLoadedPage: action.payload.currentPage,
          timelineCurrentPage: action.payload.currentPage,
        };
      }


      return {
        ...state,
        timelinePending: false,
        timeline: action.payload.messages,
        timelinePageCount: action.payload.pageCount,
        timelineLowerLoadedPage: action.payload.currentPage,
        timelineCurrentPage: action.payload.currentPage,
        newInteractionType: null,
        pinnedMsg: action.payload.pinnedMessage,

        search: '',
        isGlobalMsgSearch: false,
        showSearchQuery: false,

        // contextMsgId: null,
        contextDate: null,
        chatSource: CHAT_SOURCES.MSGS,
      };
    }
    case UPDATE_CLIENT_TIMELINE: {
      return {
        ...state,
        timeline: onUpdateTimeline(state.timeline, action.payload.messages, action.payload.loadDirection, action.payload.userTimezone),
        timelineCurrentPage: action.payload.currentPage,
        // do not update page count if we scroll to top because new msgs can update it dynamically
        timelinePageCount: action.payload.loadDirection === 'up'
          ? state.timelinePageCount
          : action.payload.pageCount,
        newInteractionType: null
      }
    }
    case REMOVE_CLIENT_REMINDER_FROM_MAIN_TIMELINE: {
      const updatedTimeline = [...state.timeline];
      const updatedLastGroup = updatedTimeline[updatedTimeline.length - 1]
        .filter(interaction => interaction.type !== 7 && interaction.id !== action.payload);

      updatedTimeline[updatedTimeline.length - 1] = updatedLastGroup;

      return {
        ...state,
        timeline: updatedTimeline,
      }
    }
    case REMOVE_CLIENT_TAB: {
      const updatedActive = onRemoveTabUpdateActiveContact(
        state.tabs,
        state.active,
        action.payload
      )

      if (updatedActive === 'new_chat') {
        return {
          ...state,
          timeline: [],
          tabs: onRemoveTab(state.tabs, action.payload),
          unfixedTab: action.payload === state.unfixedTab ? null : state.unfixedTab,
          active: updatedActive,
          timelinePageCount: 1,
          timelineLowerLoadedPage: 1,
          timelineCurrentPage: 1,
        }
      }

      return {
        ...state,
        timeline: action.payload === state.active ? [] : state.timeline,
        tabs: onRemoveTab(state.tabs, action.payload),
        unfixedTab: action.payload === state.unfixedTab ? null : state.unfixedTab,
        active: updatedActive,
      };
    }
    case REMOVE_CLIENT_TABS: {
      let updatedTimeline = [];
      let updatedActive = null;

      if (
        state.active === action.payload.activeTab &&
        action.payload.query !== "all"
      ) {
        updatedTimeline = state.timeline;
        updatedActive = state.active;
      } else if (
        state.active !== action.payload.activeTab &&
        action.payload.query !== "all"
      ) {
        updatedActive = action.payload.activeTab;
      }

      return {
        ...state,
        active: updatedActive,
        timeline: updatedTimeline,
        tabs: onRemoveTabs(state.tabs, action.payload)
      };
    }
    case SET_CLIENT_TABS: {
      if (!state.unfixedTab) {
        return {
          ...state,
          tabs: action.payload,
        }
      }

      const prevIndex = state.tabs.indexOf(state.unfixedTab);
      const newIndex = action.payload.indexOf(state.unfixedTab);

      console.log(prevIndex, newIndex);

      if (newIndex === -1 || newIndex !== prevIndex) {
        return {
          ...state,
          tabs: action.payload,
          unfixedTab: null,
        }
      }
    }
    case NEW_OUTGOING_CALL:
    case NEW_INCOMING_QUEUE_CALL:
    case NEW_INCOMING_CALL:
    case NEW_INCOMING_MESSAGE:
    case NEW_OUTGOING_MESSAGE: {
      const contactId = action.payload.interaction.caller_id;
      const newInteraction = { ...action.payload.interaction, isNew: true };

      if (state.active === contactId) {
        return {
          ...state,
          timeline: onInteraction(state.timeline, newInteraction, action.payload.userTimezone),
          newInteractionType: newInteraction.type
        };
      }
      if (action.type === NEW_INCOMING_MESSAGE && state.tabs.includes(contactId)) {
        const updatedTabs = state.tabs.filter(id => id !== contactId);

        updatedTabs.unshift(contactId);

        return {
          ...state,
          tabs: updatedTabs,
        }
      }
      return state;
    }
    case NEW_SCHEDULED_MESSAGE: {
      const contactId = action.payload.interaction.caller_id;

      if (state.active === contactId && state.chatSource === CHAT_SOURCES.SCHEDULED_MSGS) {
        return {
          ...state,
          auxiliaryTimeline: onInteraction(state.auxiliaryTimeline, action.payload.interaction, action.payload.userTimezone),
          newInteractionType: action.payload.interaction.type,
          scheduledMsgsCount: state.scheduledMsgsCount + 1
        };
      }
      return state;
    }
    case UPDATE_CLIENT: {
      if (action.payload.isTypeChanged && action.payload.wasContactInTabs) {
        const updatedContactId = action.payload.ids
          ? action.payload.ids[0]
          : action.payload.pinIds[0];

        const updatedTabs = onRemoveTab(state.tabs, updatedContactId);
        let updatedActive = state.active;
        let updatedTimeline = state.timeline;

        if (action.payload.isActive) {
          updatedActive = onRemoveTabUpdateActiveContact(
            state.tabs,
            state.active,
            updatedContactId
          );
          updatedTimeline = [];
        }

        const updatedUnfixedTab = state.unfixedTab === updatedContactId
          ? null
          : state.unfixedTab;

        return {
          ...state,
          active: updatedActive,
          tabs: updatedTabs,
          timeline: updatedTimeline,
          unfixedTab: updatedUnfixedTab
        };
      }
      return state;
    }
    case UPDATE_GIRL: {
      if (action.payload.isTypeChanged && action.payload.wasContactInTabs) {
        const updatedContactId = action.payload.ids
          ? action.payload.ids[0]
          : action.payload.pinIds[0];

        const updatedTabs = [...state.tabs, updatedContactId];

        let updatedActive = state.active ? state.active : updatedContactId;

        let updatedTimeline = state.active ? state.timeline : [];

        if (action.payload.isActive) {
          updatedTimeline = [];
          updatedActive = updatedContactId;
        }

        return {
          ...state,
          active: updatedActive,
          tabs: updatedTabs,
          timeline: updatedTimeline
        };
      }
      return state;
    }
    case REMOVE_CLIENT: {
      const isContactInTabs =
        state.tabs.indexOf(action.payload) === -1 ? false : true;

      if (!isContactInTabs) {
        // if contact not in tabs return
        return state;
      }

      const updatedTabs = onRemoveTab(state.tabs, action.payload);
      let updatedActive = state.active;
      let updatedTimeline = state.timeline;

      if (state.active === action.payload) {
        updatedActive = onRemoveTabUpdateActiveContact(
          state.tabs,
          state.active,
          action.payload
        );
        updatedTimeline = [];
      }

      const updatedUnfixedTab = state.unfixedTab === action.payload
        ? null
        : state.unfixedTab;

      return {
        ...state,
        active: updatedActive,
        tabs: updatedTabs,
        timeline: updatedTimeline,
        unfixedTab: updatedUnfixedTab
      };
    }
    case CHANGE_MESSAGE_STATUS:
    case CHANGE_CALL_STATUS: {
      if (
        state.active &&
        (state.active === action.payload.caller_id || // if smsStatus
          state.active === action.payload.callerId)
      ) {
        // if callStatus
        return {
          ...state,
          timeline: onStatusChange(state.timeline, action.payload)
        };
      }
      return state;
    }
    case NEW_MESSAGE_ATTACHMENTS: {
      if (state.active && state.active === action.payload.caller_id) {
        return {
          ...state,
          timeline: onAttachmentsAdding(state.timeline, action.payload),
          newInteractionType: INTERACTION_TYPES.MSG_ATTACHMENT
        };
      }
      return state;
    }
    case UNREAD_CLIENT_MESSAGES: {
      return {
        ...state,
        timeline: updateUnreadMsgsInTimeline(state.timeline, action.payload.interaction)
      };
    }

    case GET_CLIENT_MSG_CONTEXT: {
      return {
        ...state,
        timelinePending: false,

        isGlobalMsgSearch: false,
        showSearchQuery: false,
        contextDate: null,
        chatSource: CHAT_SOURCES.MSGS,

        contextMsgId: action.payload.contextMsgId,
        auxiliaryCurrentPage: action.payload.currentPage,
        auxiliaryLowerLoadedPage: action.payload.currentPage,
        auxiliaryHigherLoadedPage: action.payload.currentPage,
        auxiliaryPageCount: action.payload.pageCount,
        auxiliaryTimeline: action.payload.messages
      };
    }

    case UPDATE_CLIENT_MSG_CONTEXT: {
      //exit from context
      // if (action.payload.currentPage === action.payload.pageCount && action.payload.loadDirection === 'down') {
      //   return {
      //     ...state,
      //     timeline: onUpdateTimeline(state.auxiliaryTimeline, action.payload.messages, action.payload.loadDirection, action.payload.userTimezone),
      //     timelinePageCount: action.payload.pageCount,
      //     timelineLowerLoadedPage: action.payload.currentPage,
      //     timelineCurrentPage: state.auxiliaryHigherLoadedPage,

      //     auxiliaryCurrentPage: null,
      //     auxiliaryLowerLoadedPage: null,
      //     auxiliaryHigherLoadedPage: null,
      //     auxiliaryPageCount: null,
      //     auxiliaryTimeline: state.auxiliaryTimeline.length ? [] : state.auxiliaryTimeline,

      //     contextMsgId: null,
      //     search: '',
      //   }
      // }

      return {
        ...state,
        auxiliaryTimeline: onUpdateTimeline(state.auxiliaryTimeline, action.payload.messages, action.payload.loadDirection, action.payload.userTimezone),
        auxiliaryCurrentPage: action.payload.currentPage,

        auxiliaryHigherLoadedPage: state.auxiliaryHigherLoadedPage > action.payload.currentPage
          ? action.payload.currentPage
          : state.auxiliaryHigherLoadedPage,
        auxiliaryLowerLoadedPage: state.auxiliaryLowerLoadedPage > action.payload.currentPage
          ? state.auxiliaryLowerLoadedPage
          : action.payload.currentPage,
        auxiliaryPageCount: action.payload.loadDirection === 'up'
          ? state.auxiliaryPageCount
          : action.payload.pageCount,
      }
    }

    case CLEAN_CLIENT_MSG_CONTEXT: {
      return {
        ...state,
        contextMsgId: null,
        auxiliaryCurrentPage: null,
        auxiliaryLowerLoadedPage: null,
        auxiliaryHigherLoadedPage: null,
        auxiliaryPageCount: null,
        auxiliaryTimeline: state.auxiliaryTimeline.length ? [] : state.auxiliaryTimeline,

      }
    }

    case GET_CLIENT_DATE_MSG_CONTEXT: {
      return {
        ...state,
        timelinePending: false,

        search: '',
        contextMsgId: null,
        chatSource: CHAT_SOURCES.MSGS,

        contextDate: action.payload.contextDate,
        auxiliaryCurrentPage: action.payload.currentPage,
        auxiliaryLowerLoadedPage: action.payload.currentPage,
        auxiliaryHigherLoadedPage: action.payload.currentPage,
        auxiliaryPageCount: action.payload.pageCount,
        auxiliaryTimeline: action.payload.messages
      }
    }

    case UPDATE_CLIENT_DATE_MSG_CONTEXT: {
      // if (action.payload.currentPage === action.payload.pageCount && action.payload.loadDirection === 'down') {
      //   return {
      //     ...state,
      //     timeline: onUpdateTimeline(state.auxiliaryTimeline, action.payload.messages, action.payload.loadDirection),
      //     timelinePageCount: action.payload.pageCount,
      //     timelineLowerLoadedPage: action.payload.currentPage,
      //     timelineCurrentPage: state.auxiliaryHigherLoadedPage,

      //     auxiliaryCurrentPage: null,
      //     auxiliaryLowerLoadedPage: null,
      //     auxiliaryHigherLoadedPage: null,
      //     auxiliaryPageCount: null,
      //     auxiliaryTimeline: state.auxiliaryTimeline.length ? [] : state.auxiliaryTimeline,

      //     contextDate: null,
      //     search: '',
      //   }
      // }
      return {
        ...state,

        auxiliaryTimeline: onUpdateTimeline(state.auxiliaryTimeline, action.payload.messages, action.payload.loadDirection, action.payload.userTimezone),
        auxiliaryCurrentPage: action.payload.currentPage,

        auxiliaryHigherLoadedPage: state.auxiliaryHigherLoadedPage > action.payload.currentPage
          ? action.payload.currentPage
          : state.auxiliaryHigherLoadedPage,
        auxiliaryLowerLoadedPage: state.auxiliaryLowerLoadedPage > action.payload.currentPage
          ? state.auxiliaryLowerLoadedPage
          : action.payload.currentPage,
        auxiliaryPageCount: action.payload.loadDirection === 'up'
          ? state.auxiliaryPageCount
          : action.payload.pageCount,
      }
    }

    case CLEAN_CLIENT_DATE_MSG_CONTEXT: {
      return {
        ...state,
        contextDate: null,
        auxiliaryCurrentPage: null,
        auxiliaryLowerLoadedPage: null,
        auxiliaryHigherLoadedPage: null,
        auxiliaryPageCount: null,
        auxiliaryTimeline: state.auxiliaryTimeline.length ? [] : state.auxiliaryTimeline,
      }
    }
    case GET_SEARCHED_CLIENT_MESSAGES: {
      return {
        ...state,
        search: action.payload.query,
        timelinePending: false,

        auxiliaryCurrentPage: action.payload.currentPage,
        auxiliaryLowerLoadedPage: action.payload.currentPage,
        auxiliaryHigherLoadedPage: action.payload.currentPage,
        auxiliaryPageCount: action.payload.pageCount,
        auxiliaryTimeline: action.payload.messages,

        contextMsgId: null,
        contextDate: null,
        chatSource: CHAT_SOURCES.MSGS,
        isGlobalMsgSearch: state.isGlobalMsgSearch
          ? false
          : state.isGlobalMsgSearch
      };
    }

    case UPDATE_GLOBAL_CLIENT_MESSAGE_SEARCH:
    case UPDATE_SEARCHED_CLIENT_MESSAGES: {
      return {
        ...state,
        // searchedMessages: onUpdateTimeline(state.searchedMessages, action.payload.messages, action.payload.loadDirection),
        auxiliaryCurrentPage: action.payload.currentPage,
        auxiliaryHigherLoadedPage: state.auxiliaryHigherLoadedPage > action.payload.currentPage
          ? action.payload.currentPage
          : state.auxiliaryHigherLoadedPage,
        auxiliaryLowerLoadedPage: state.auxiliaryLowerLoadedPage > action.payload.currentPage
          ? state.auxiliaryLowerLoadedPage
          : action.payload.currentPage,
        auxiliaryTimeline: onUpdateTimeline(state.auxiliaryTimeline, action.payload.messages, action.payload.loadDirection, action.payload.userTimezone),
        auxiliaryPageCount: action.payload.loadDirection === 'up'
          ? state.auxiliaryPageCount
          : action.payload.pageCount,
        updatePending: false,
      }
    }

    case STOP_SEARCH_CLIENT_MESSAGES: {
      return {
        ...state,
        search: '',

        auxiliaryTimeline: state.auxiliaryTimeline.length ? [] : state.auxiliaryTimeline,
        auxiliaryPageCount: null,
        auxiliaryLowerLoadedPage: null,
        auxiliaryHigherLoadedPage: null,
        auxiliaryCurrentPage: null,

        contextMsgId: null,
        isGlobalMsgSearch: false
      }
    }

    case GLOBAL_CLIENT_MESSAGE_SEARCH: {
      return {
        ...state,
        isGlobalMsgSearch: true,
        search: action.payload.query,
        chatSource: CHAT_SOURCES.MSGS,

        auxiliaryCurrentPage: action.payload.currentPage,
        auxiliaryLowerLoadedPage: action.payload.currentPage,
        auxiliaryHigherLoadedPage: action.payload.currentPage,
        auxiliaryPageCount: action.payload.pageCount,
        auxiliaryTimeline: action.payload.messages,
        timelinePending: false,
      };
    }

    case PIN_CLIENT_MSG: {
      return {
        ...state,
        pinnedMsg: action.payload
      }
    }

    case NEW_MISSED_CALL: {
      const isActive = state.active === +action.payload.id.split('_')[0];

      if (!isActive) return state;

      const newTimeline = isActive && state.timeline.map(group => {
        return group.map(msg => {
          if (msg.id === action.payload.entity.id) {
            return action.payload.entity;
          }
          return msg;
        });
      });

      return {
        ...state,
        timeline: newTimeline
      }
    }

    case UPDATE_VOICEMAIL_STATUS: {
      const isActive = state.active === action.payload.callerId

      const newTimeline = isActive && state.timeline.map(group => {
        return group.map(msg => {
          if (msg.id === action.payload.callId) {
            return { ...msg, voicemail: action.payload.voicemail };
          }
          return msg;
        });
      });

      return {
        ...state,
        timeline: isActive
          ? newTimeline
          : state.timeline
      }
    }

    case GET_CLIENT_CONVERSATION_MEDIA:
    case GET_CLIENT_SCHEDULED_MSGS:
    case GET_CLIENT_MSG_REMINDERS: {
      return {
        ...state,
        timelinePending: false,
        contextDate: null,
        chatSource: action.payload.chatSource,

        auxiliaryTimeline: action.payload.messages,
        auxiliaryPageCount: action.payload.pageCount,
        auxiliaryLowerLoadedPage: action.payload.currentPage,
        auxiliaryHigherLoadedPage: action.payload.currentPage,
        auxiliaryCurrentPage: action.payload.currentPage,
      };
    }

    case UPDATE_CLIENT_CONVERSATION_MEDIA:
    case UPDATE_CLIENT_SCHEDULED_MSGS:
    case UPDATE_CLIENT_MSG_REMINDERS: {
      return {
        ...state,

        auxiliaryTimeline: onUpdateTimeline(state.auxiliaryTimeline, action.payload.messages, action.payload.loadDirection, action.payload.userTimezone),
        auxiliaryPageCount: action.payload.loadDirection === 'up'
          ? state.auxiliaryPageCount
          : action.payload.pageCount,
        auxiliaryHigherLoadedPage: state.auxiliaryHigherLoadedPage > action.payload.currentPage
          ? action.payload.currentPage
          : state.auxiliaryHigherLoadedPage,
        auxiliaryLowerLoadedPage: state.auxiliaryLowerLoadedPage > action.payload.currentPage
          ? state.auxiliaryLowerLoadedPage
          : action.payload.currentPage,
        auxiliaryCurrentPage: action.payload.currentPage,
      };
    }

    case CLEAN_CLIENT_CONVERSATION_MEDIA:
    case CLEAN_CLIENT_SCHEDULED_MSGS:
    case CLEAN_CLIENT_MSG_REMINDERS: {
      if (state.search) {
        return state;
      }

      return {
        ...state,

        chatSource: CHAT_SOURCES.MSGS,

        auxiliaryCurrentPage: null,
        auxiliaryLowerLoadedPage: null,
        auxiliaryHigherLoadedPage: null,
        auxiliaryPageCount: null,
        auxiliaryTimeline: state.auxiliaryTimeline.length ? [] : state.auxiliaryTimeline,
      }
    }

    case CREATE_NEW_CHAT: {
      if (state.tabs.includes("new_chat")) {
        return {
          ...state,
          active: "new_chat",
          timeline: [],
          timelinePageCount: 1,
          timelineLowerLoadedPage: 1,
          timelineCurrentPage: 1,
        }
      }
      return {
        ...state,
        active: "new_chat",
        tabs: ["new_chat", ...state.tabs],
        timeline: [],


        timelinePageCount: 1,
        timelineLowerLoadedPage: 1,
        timelineCurrentPage: 1,
      };
    }

    case REMOVE_CLIENT_MSG_REMIND: {
      const updatedReminders = [];

      state.auxiliaryTimeline.forEach(remindersByDate => {
        const updatedRemindersByDate = remindersByDate.filter(remind => remind.id !== action.payload);

        if (updatedRemindersByDate.length) {
          updatedReminders.push(updatedRemindersByDate);
        }
      })

      return {
        ...state,
        auxiliaryTimeline: updatedReminders
      }
    }

    case UPDATE_CLIENT_MSG: {
      let updatedTimeline = 'timeline';

      if (action.payload.type === 11 && state.chatSource === CHAT_SOURCES.SCHEDULED_MSGS) {
        updatedTimeline = 'auxiliaryTimeline'
      }

      return {
        ...state,
        [updatedTimeline]: state[updatedTimeline].map(group => {
          return group.map(msg => {
            if (msg.id === action.payload.id) {
              return {
                ...msg,
                body: action.payload.body,
                edited: action.payload.edited || true,
                date: action.payload.date ? action.payload.date : null,
                hasUrls: action.payload.hasUrls || msg.hasUrls,
                attachments: action.payload.attachments,
              };
            }
            return msg;
          });
        }),
      };
    }

    case DELETE_CLIENT_MSG: {
      let updatedTimelineName = 'timeline';

      if (action.payload.type === 11 && state.chatSource === CHAT_SOURCES.SCHEDULED_MSGS) {
        updatedTimelineName = 'auxiliaryTimeline'
      }

      const updatedTimeline = [];

      state[updatedTimelineName].forEach(group => {
        const updatedGroup = group.filter(msg => msg.id !== action.payload.id);

        if (updatedGroup.length) {
          updatedTimeline.push(updatedGroup);
        }
      })

      return {
        ...state,
        [updatedTimelineName]: updatedTimeline,
        scheduledMsgsCount: action.payload.type === 11 && state.scheduledMsgsCount >= 1
          ? state.scheduledMsgsCount - 1
          : state.scheduledMsgsCount
      };
    }

    case SET_CLIENTS_RECENT_TABS: {
      if (!action.payload) return state;

      return {
        ...state,
        recentTabs: action.payload,
      }
    }

    case GET_CLIENT_SCHEDULED_MSGS_COUNT: {
      if (action.payload === state.scheduledMsgsCount) return state;

      return {
        ...state,
        scheduledMsgsCount: action.payload,
      }
    }

    case DELETE_TELEGRAM_MSG: {
      const { timeline, auxiliaryTimeline } = state;
      const { id } = action.payload;

      return {
        ...state,
        timeline: removeFromTimeline(timeline, id),
        auxiliaryTimeline: removeFromTimeline(auxiliaryTimeline, id),
      };
    }

    default:
      return state;
  }
};

// Redux helpers start

// Group interactions in array of arrays by userTimezone
// but in redux we save in UTC time which comes from server
export const groupInteractionsByTimezone = (interactions, userTimezone, userId = null) => {
  const groupsByTimezone = {};

  interactions.forEach((interaction) => {
    // if rooms interactions
    if (userId) {
      interaction = {
        ...interaction,
        type: formatInteractionType(interaction, userId)
      }
    }

    const dateByTimezone = getDateByTimezoneOffset(userTimezone, interaction.dateCreated);
    const groupDate = getShortDate(dateByTimezone);

    const groupByTimezone = groupsByTimezone[groupDate]
      ? groupsByTimezone[groupDate]
      : [];

    groupsByTimezone[groupDate] = [...groupByTimezone, interaction];
  });

  return Object.values(groupsByTimezone);
}

export const onUpdateTimeline = (timeline, newInteractions, loadDirection = 'up', userTimezone) => {
  if (!timeline || !timeline.length) {
    return newInteractions;
  } else if (!newInteractions.length) {
    return timeline;
  }

  if (loadDirection === 'up') {
    const firstInteractionGroup = timeline[0];
    const firstInteractionDateByTimezone = getShortDate(getDateByTimezoneOffset(userTimezone, firstInteractionGroup[0].dateCreated));


    const lastNewInteractionGroup = newInteractions[newInteractions.length - 1];
    const lastNewInteractionDateByTimezone = getShortDate(getDateByTimezoneOffset(userTimezone, lastNewInteractionGroup[0].dateCreated));

    if (firstInteractionDateByTimezone === lastNewInteractionDateByTimezone) {
      console.log('AAAAA', firstInteractionDateByTimezone);
      console.log('AAAAA1', lastNewInteractionDateByTimezone);
      console.log('AAAAA2', userTimezone);
      console.log('AAAAA3', userTimezone);
      const updatedFirstGroup = [...lastNewInteractionGroup, ...firstInteractionGroup];
      const updatedTimeline = timeline.slice(1);

      return [...newInteractions.slice(0, -1), updatedFirstGroup, ...updatedTimeline];
    }
    else {
      return [...newInteractions, ...timeline];
    }
  }
  else {
    const lastInteractionGroup = timeline[timeline.length - 1];
    const lastInteractionDateByTimezone = getShortDate(getDateByTimezoneOffset(userTimezone, lastInteractionGroup[0].dateCreated));

    const firstNewInteractionGroup = newInteractions[0];
    const firstNewInteractionDateByTimezone = getShortDate(getDateByTimezoneOffset(userTimezone, firstNewInteractionGroup[0].dateCreated));

    if (lastInteractionDateByTimezone === firstNewInteractionDateByTimezone) {
      let updatedLastGroup = [...lastInteractionGroup, ...firstNewInteractionGroup];
      let updatedTimeline = timeline.slice(0, -1);

      return [...updatedTimeline, updatedLastGroup, ...newInteractions.slice(1)];
    }
    else {
      return [...timeline, ...newInteractions];
    }
  }
};


export const onInteraction = (timeline, newInteraction, userTimezone) => {
  if (timeline.length) {
    const lastInteractionGroup = [...timeline[timeline.length - 1]];
    const lastInteractionDateByTimezone = getShortDate(getDateByTimezoneOffset(userTimezone, lastInteractionGroup[0].dateCreated));
    const newInteractionDateByTimezone = getShortDate(getDateByTimezoneOffset(userTimezone, newInteraction.dateCreated));

    if (lastInteractionDateByTimezone === newInteractionDateByTimezone) {
      lastInteractionGroup.push(newInteraction);

      return [...timeline.slice(0, -1), lastInteractionGroup];
    } else {
      return [...timeline, [newInteraction]];
    }
  } else {
    return [[newInteraction]];
  }
};

export const onStatusChange = (activeTimeline, payload) => {
  const lastInteractionGroup = activeTimeline[activeTimeline.length - 1];

  const interactionId = payload.callId ? payload.callId : payload.id;

  const newTimeline = activeTimeline.length
    ? lastInteractionGroup.map(interaction => {
      if (interactionId === interaction.id) {
        return {
          ...interaction,
          status: payload.status,
          duration: payload.duration || null
        }
      }
      else {
        return interaction
      }
    })
    : [];

  return [...activeTimeline.slice(0, -1), newTimeline];
};

export const updateUnreadMsgsInTimeline = (timeline, firstUnreadMsg) => {
  let unread = false;

  return timeline.map(group =>
    group.map(msg => {
      if (msg.id === firstUnreadMsg.id) {
        unread = true;
      }
      return unread ? { ...msg, isRead: 0 } : msg;
    })
  );
};

export const onAttachmentsAdding = (timeline, payload) => {
  return timeline.map(group =>
    group.map(interaction => {
      return payload.id === interaction.id
        ? {
          ...interaction,
          attachments: payload.attachments
        }
        : interaction;
    })
  );
};


export const onRemoveTab = (tabs, removedTab) =>
  tabs.filter(tab => tab !== removedTab);

export const onRemoveTabUpdateActiveContact = (tabs, active, removedTab) => {
  let newActiveContact;

  if (active === removedTab) {
    // we need to change activeContact
    if (tabs.length === 1) {
      newActiveContact = null;
    } else if (tabs.length >= 2) {
      let idx = tabs.indexOf(removedTab);

      if (idx === 0) {
        newActiveContact = tabs[idx + 1];
      } else {
        newActiveContact = tabs[idx - 1];
      }
    }
  } else {
    newActiveContact = active; // activeContact stays
  }
  return newActiveContact;
};

export const onRemoveTabs = (tabs, { activeTab, query }) => {
  let updatedTabs;

  switch (query) {
    case "all": {
      updatedTabs = [];
      break;
    }
    case "other": {
      updatedTabs = [activeTab];
      break;
    }
    case "right": {
      const idx = tabs.indexOf(activeTab);

      updatedTabs =
        idx + 1 === tabs.length ? tabs : [...tabs].splice(0, idx + 1);
      break;
    }
    case "left": {
      const idx = tabs.indexOf(activeTab);

      updatedTabs = idx === 0 ? tabs : [...tabs].splice(idx);
      break;
    }
    default:
      break;
  }
  return updatedTabs;
};

export const removeFromTimeline = (timeline, delId) => {
  return timeline
      .map(group => group.map(item => {
        return item.id === delId
        ? {
          ...item,
          is_deleted: !item.is_deleted
        }
        : item
      }))
}

// Redux helpers stop
