export default function convertHoursTo12h(hours24h) {
  if (!Number.isInteger(hours24h)) {
    return;
  }
  if (hours24h === 0 || hours24h === 12) {
    return 12;
  }
  if (hours24h >= 13) {
    return hours24h - 12;
  }
  return hours24h;
}
