import React, { useEffect } from 'react';

import {
  convertNumberToSeconds,
  getContactAvatar,
  formatDateToTime,
  getDefaultField,
  getDateByTimezoneOffset
} from '../../../../../utils';

import AttachmentVoiceMessage from '../../ChatAttachments/AttachmentVoiceMessage';
import { useToggle } from '../../../../../hooks';


const IncomingCall = ({ interaction, activeRecipient, profileId, ...props }) => {
  const status = interaction.status
    ? interaction.status
    : null;

  const duration = +interaction.duration > 0
    ? convertNumberToSeconds(+interaction.duration)
    : null;

  const isGotFromNotDefaultNumber = interaction.channel && (getDefaultField(activeRecipient.tels, 'tel') !== interaction.channel);

  const isContextMsg = interaction.id === +props.contextMsgId;

  const [isAnimate, setIsAnimate] = useToggle(false);

  useEffect(() => {
    if (isContextMsg) {
      setIsAnimate();

      setTimeout(() => setIsAnimate(false), 2000);
    }
  }, [isContextMsg]);

  const setContextRef = ref => {
    if (isContextMsg) {
      return props.contextMsgRef.current = ref;
    }
  }
  return (
    <li
      className="interaction"
      ref={setContextRef}
      data-isanimate={isAnimate}
    >
      <header className="interaction__header">
        <div className="interaction__ava">
          <img src={getContactAvatar(activeRecipient)} alt="ava" />
        </div>
        <span className="interaction__name">
          {activeRecipient.short_name || activeRecipient.fn || activeRecipient.chatTitle}
        </span>
        <span className="interaction__status">{status} {duration ? `- (${duration})` : ''}</span>
        <span className="interaction__time interaction__time--msg">
          {formatDateToTime(getDateByTimezoneOffset(props.userTimezone, interaction.dateCreated), props.userHour12)}
        </span>

        <span className="interaction__channel">
          {isGotFromNotDefaultNumber && `(from: ${interaction.channel})`}
        </span>

      </header>

      <div className="interaction__body-wrap">
        <div className="interaction__text-wrap">
          <p className="interaction__text">
            {status === 'no-answer' ? 'Missed' : 'Incoming'} Call {' '}
          </p>

          {interaction.user &&
            <div className="interaction__operator-info">
              with

            <span className="interaction__operator-info-img">
                <img src={getContactAvatar(interaction.user)} alt="operator avatar" />
              </span>

              <span className="interaction__operator-name">
                {interaction.user && interaction.user.username}
              </span>

              <span className="interaction__voicemail">

                {interaction.voicemail &&
                  <svg
                    height="12px"
                    width="12px"
                    viewBox="1 -28 512 511"
                    xmlns="http://www.w3.org/2000/svg"
                    fill={interaction.voicemail.listened ? "#000" : "#0092F2"}>
                    <path d="M457 1H55C25 1 0 25 0 56v305c0 30 25 55 55 55h155l35 36a15 15 0 0 0 22 0l35-36h155c30 0 55-25 55-55V56c0-31-25-55-55-55zm25 360c0 14-11 25-25 25H296c-4 0-8 2-10 4l-30 30-30-30c-2-2-6-4-10-4H55c-14 0-25-11-25-25V56c0-14 11-26 25-26h402c14 0 25 12 25 26zm0 0" />
                    <path d="M359 136a72 72 0 0 0-58 115h-90a72 72 0 1 0-59 30h207a72 72 0 0 0 0-145zm-249 72a42 42 0 1 1 85 0 42 42 0 0 1-85 0zm249 43a42 42 0 1 1 0-85 42 42 0 0 1 0 85zm0 0" />
                  </svg>
                }
              </span>
            </div>
          }
        </div>

        {interaction.voicemail &&
          <AttachmentVoiceMessage
            className="interaction__operator-voicemail"
            voiceMsg={interaction.voicemail} />
        }
      </div>
    </li>
  );
};

export default IncomingCall;