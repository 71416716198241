import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Scrollbars from 'react-custom-scrollbars';

import './NotificationsSection.scss';

import {
  readNotification,
  removeNotification,
  NOTIFICATION_TYPES,
} from '../../ducks/notifications';

import NotificationComponent from '../NotificationComponent/NotificationComponent';


const READABLE_NOTIFICATIONS = [
  NOTIFICATION_TYPES.messageReminder,
  NOTIFICATION_TYPES.coldTransfer,
  NOTIFICATION_TYPES.parkingCall,
  NOTIFICATION_TYPES.warmTransfer,
  NOTIFICATION_TYPES.changeContactNumber,
  NOTIFICATION_TYPES.addTelegramChannel,
  NOTIFICATION_TYPES.missedClientCall,
  NOTIFICATION_TYPES.missedSessionClientCall,
  NOTIFICATION_TYPES.longSession,
  NOTIFICATION_TYPES.clientTextedInSession,
  NOTIFICATION_TYPES.profileTextedInSession,
  NOTIFICATION_TYPES.newRelatedMessage,
  NOTIFICATION_TYPES.partnerHasntReactTo,
  NOTIFICATION_TYPES.partnerHasBookedProfileInSession,
  NOTIFICATION_TYPES.sessionUsingReminder,
  NOTIFICATION_TYPES.undeliveredMessage,
  NOTIFICATION_TYPES.sessionProposed,
  NOTIFICATION_TYPES.sessionAssistanceRequest,
  NOTIFICATION_TYPES.sessionAssistanceDelete,
  NOTIFICATION_TYPES.sessionAssistanceResponse,
  NOTIFICATION_TYPES.sessionTransferRequest,
  NOTIFICATION_TYPES.sessionTransferResponse,
  NOTIFICATION_TYPES.sessionView,
  NOTIFICATION_TYPES.bufferedProfileBecomeOff,
  "mention",
];


const NotificationsSection = props => {
  const {
    notifications,
    newNotificationIds,
  } = props;

  const [newNotifications, setNewNotifications] = useState([]);

  useEffect(() => {
    setNewNotifications(newNotificationIds.map(id => notifications[id]));
  }, [newNotificationIds, notifications]);

  return (
    <div className="notifications-section">
      <Scrollbars>
        <div className="notifications-section__wrap">
          {!!newNotifications.length && newNotifications.map(notification => (
              <NotificationComponent
                key={notification.id}
                notification={notification}
                readNotification={READABLE_NOTIFICATIONS.includes(notification.type)
                  ? props.readNotification
                  : props.removeNotification
                }
                isNewNotification
                isLarge={notification.type === NOTIFICATION_TYPES.partnerHasntReactTo}
              />
          ))}
        </div>
      </Scrollbars>
    </div>
  );
};

const mapStateToProps = state => ({
  notifications: state.notifications.entities,
  newNotificationIds: state.notifications.newIds,
});

const mapDispatchToProps = {
  readNotification,
  removeNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsSection);