import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const Empty = () => {
  console.log(333333333333);
  window.location = '/client/login';

  return null
}


const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    rest.isAuthenticated
      ? <Component {...rest} {...props} />
      : <Empty /> // we need it here because of vpn
  )} />
)


export default PrivateRoute;