import React from 'react';
import { connect } from 'react-redux';

import { selectIsWebrtcAudioElementsMuted } from '../selectors/selectors';

const WebrtcAudioElements = ({ remoteStreams, isConferenceMute }) => (
  Object.entries(remoteStreams).map(([id, remoteStream]) =>
    <audio
      autoPlay
      playsInline
      key={id}
      muted={isConferenceMute}
      ref={(audio) => {
        if (audio)
          audio.srcObject = remoteStream
      }}
    />
  )
);

const mapStateToProps = (state) => ({
  remoteStreams: state.webrtc.remoteStreams,
  isConferenceMute: selectIsWebrtcAudioElementsMuted(state),
});

export default connect(mapStateToProps)(WebrtcAudioElements);