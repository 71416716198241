import React from 'react';
import { connect } from 'react-redux';

import './BusyStatus.scss';

import { selectCallInfoById } from '../../../../../selectors/selectors';
import { getContactsById } from '../../../../../ducks/contacts';
import { useDidMount } from '../../../../../hooks';

const BusyStatus = ({ calls, ...props }) => {
  useDidMount(() => {
    if (!calls.isInEntities && calls.callerId && !props.isContact) {
      props.getContactsById(calls.callerId, 1);
    }
  });

  const renderInfo = () => {

    if (props.isContact) {
      const currentOperator = calls.operator;
      const currentContact = calls.contact;

      return (
        <div className="busy-status">
          {currentOperator
            ? (
              <div className="busy-status__status">
                <span className="busy-status__trimer">{currentContact.fn}</span>
                busy with
              <span className="busy-status__trimer--inline">
                  {currentOperator === 'Me' ? 'Me' : currentOperator.username}
                </span>
              </div>)
            : (<div className="busy-status__status">
              <span className="busy-status__trimer">{currentContact.fn}</span>
              waiting for free operator
            </div>)
          }</div>
      );
    }
    else {
      const currentContact = calls.contactByOperatorId;

      if (calls.operatorByRoom.status !== "busy") {
        return null;
      }


      if (!calls.isInEntities && !calls.callerId) {
        return (
          <div className="busy-status">
            <div className="busy-status__status">now is unavailable</div>
          </div>
        );
      }
      return (
        <div className="busy-status">
          <div className="busy-status__status">
            busy with:
            {calls.isInEntities
              ? <span className="busy-status__trimer--inline">{currentContact.fn}</span>
              : 'loading...'}
          </div>
        </div>
      );
    }
  };

  return renderInfo();
};

const mapStateToProps = (state, ownProps) => ({
  calls: selectCallInfoById(state, ownProps.id, ownProps.operatorId),
});

const mapDispatchToProps = {
  getContactsById,
};

export default connect(mapStateToProps, mapDispatchToProps)(BusyStatus);
