import React from "react";

import { RequirementCheckbox } from ".";

export default function RequirementsCard({
  requirements,
  setRequirements,
  initRequirements,
  disableChanges,
}) {
  const toggleIsChecked = id => {
    setRequirements(state =>
      state.map(req => req.id === id ? { ...req, isChecked: !req.isChecked } : req
  ))};

  const getIsChanged = (id, isChecked) => {
    const initIsChecked = !!initRequirements.find(init => init.id === id)?.isChecked;
    return initIsChecked !== isChecked;
  }

  return (
    <div className="requirements-card">
      <p className="requirements-card__title">Requirements</p>

      {requirements.length ? (
        <ul className="requirement-list">
          {requirements.map(({ id, title, isChecked, color }) => 
            <li className="requirement-list__item" key={id}>
              <RequirementCheckbox
                label={title}
                checked={isChecked}
                stickerColor={color}
                onChange={() => toggleIsChecked(id)}
                highlighted={getIsChanged(id, isChecked)}
                disabled={disableChanges}
              />
            </li>
          )}
        </ul>
      ) : (
        <p className="requirements-card__placeholder">There are no items available</p>
      )}
    </div>
  );
}
