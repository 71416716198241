import React from "react";


const TextInput = (props) => {
  const {
    input,
    autoFocus,
    className,
    placeholder,
    getErrors,
    tabIndex,
    meta: { error, touched, active }
  } = props;

  const errorInputClass = (error && touched && !active)
    ? props.errorInputClass
    : '';

  return (
    <>
      <input
        {...input}
        type="text"
        ref={props.inputRef}
        tabIndex={tabIndex}
        placeholder={placeholder}
        className={`${className} ${errorInputClass}`}
        name={input.name}
        value={input.value}
        autoComplete="off"
        autoFocus={autoFocus}
      />

      {/* Client Error */}
      {error && touched && !active && <div className={props.errorClass}>{error}</div>}
      {/* Server Error */}
      {getErrors
        && getErrors[input.name]
        && !error
        && <div className={props.errorClass}>{getErrors[input.name]}</div>
      }
    </>
  )
};

export default TextInput;