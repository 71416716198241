export const SET_MERGE_CONTACTS_COUNT = 'SET_MERGE_CONTACTS_COUNT';
export const SET_NIGHT_BOT_UNVIEWED_SESSIONS_COUNT = 'SET_NIGHT_BOT_UNVIEWED_SESSIONS_COUNT';

export const setMergeContactsCount = (count) => (dispatch) => {
  dispatch({
    type: SET_MERGE_CONTACTS_COUNT,
    payload: count,
  })
}

export const setNightBotUnviewedSessionsCount = (count) => (dispatch) => {
  dispatch({
    type: SET_NIGHT_BOT_UNVIEWED_SESSIONS_COUNT,
    payload: count,
  })
}

const initialState = {
  mergeContacts: 0,
  nightBotUnviewedSessions: 0
}

export default (state = initialState, { type, payload }) => {
  switch (type) {

    case SET_MERGE_CONTACTS_COUNT:
      return { 
        ...state, 
        mergeContacts: payload
      }

    case SET_NIGHT_BOT_UNVIEWED_SESSIONS_COUNT:
      return { 
        ...state, 
        nightBotUnviewedSessions: payload
      }

  default:
    return state
  }
}
