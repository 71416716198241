import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { getContactAvatar } from '../../../../../utils';
import { usePrevious } from '../../../../../hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPlus } from '@fortawesome/free-solid-svg-icons';
import { selectOperatorOrUserById } from '../../../../../selectors/selectors';

import LazyLoadImage from '../../../../../components/LazyLoadImage/LazyLoadImage';


const OperatorEntity = ({ isDialogMode, isSessionMode, isChecked, operator, item, userId, ...props }) => {
  const [isHover, setIsHover] = useState(false);

  const prevIsChecked = usePrevious(isChecked);

  useEffect(() => {
    if (prevIsChecked === true && !isChecked) {
      return setIsHover(true)
    }

    if (isChecked) {
      return setIsHover(false)
    }
  }, [isChecked])

  const updateOperatorIds = () => {
    if (isDialogMode && operator.id === userId) {
      return null;
    }

    props.setOperatorIds(prevState => {
      if (prevState.includes(item)) {
        return prevState.filter(operatorId => operatorId !== item);
      }
      else if (isSessionMode || props.agentId) {
        return [item];
      }
      else {
        return [
          ...prevState,
          item,
        ];
      }
    });

    props.setNames && props.setNames(prevNames => {
      if (prevNames.some(name => name.username === operator.username)) {
        return prevNames.filter(name => name.username !== operator.username);
      }
      else {
        return [
          ...prevNames,
          operator.id === userId
            ? { username: 'You', status: operator.status, id: userId }
            : { username: operator.username, status: operator.status, id: operator.id }
        ]
      }
    });
  };

  const renderStylesForItem = () => {
    if (isChecked && isSessionMode) {
      return {
        backgroundColor: '#8fb6e3',
        outline: 'none',
      };
    }
    else if (isDialogMode && operator.id === userId) {
      return {
        opacity: '0.6',
        cursor: 'default',
        backgroundColor: '#eee'
      }
    }
    if (isChecked) {
      return {
        backgroundColor: '#eee'
      };
    }
  };

  const showChatStatus = () => {
    let statusColor;

    switch (operator.status) {
      case "busy":
        statusColor = "#F8737F";
        break;
      case "online":
        statusColor = "#01DF85";
        break;
      case "away":
        statusColor = "orange";
        break;
      default:
        statusColor = "grey";
        break;
    }

    return <span
      style={{ border: "2px solid #fff", backgroundColor: statusColor }}
      className="contact-item__status" />;
  };

  return (
    <div
      style={renderStylesForItem()}
      className="contact-item"
      data-checked={isChecked}
      onClick={updateOperatorIds}
      onMouseEnter={() => !isChecked && setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      tabIndex={1}
    >
      <div className="contact-item__img-wrap" >
        {isDialogMode && showChatStatus()}
        <LazyLoadImage
          src={getContactAvatar(operator)}
          alt="ava"
          className="contact-item__img" />
      </div>

      <div className="contact-item__info">
        <h4 className="contact-item__name">{operator.id === userId ? 'You' : operator.username}</h4>
      </div>

      {isDialogMode
        ? <div className="contact-item__checkbox">
          {isHover
            ? <FontAwesomeIcon icon={faPlus} title="add operator" color="#fff" style={{ backgroundColor: "#0092F2", borderRadius: '50%', padding: 3, height: 20, width: 20 }} />
            : isChecked && <FontAwesomeIcon title="selected" icon={faCheck} color="#00A562" />
          }
        </div>
        : null
      }
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  operator: selectOperatorOrUserById(state, ownProps.item),
  operatorIds: null,
  isChecked: ownProps.operatorIds.includes(ownProps.item),
  userId: state.user.id,
});

export default connect(mapStateToProps)(React.memo(OperatorEntity));
