import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import AsyncSelect from 'react-select/async';

import { useToggle } from '../../../../hooks';
import { getDefaultField, debounce } from '../../../../utils';
import { callTo } from '../../../../ducks/twilio';
import { onHoldActiveConference } from '../../../../ducks/webrtc';
import { selectActiveWebrtcConference } from '../../../../selectors/selectors';

import './Dialpad.scss';
import SvgIcon from '../../../../components/SvgIcon/SvgIcon';
import DropWrapper from '../../../../components/DropWrapper/DropWrapper';
import API from '../../../../api/api';
import DialpadSearchItem from './DialpadSearchItem';

const MAX_LENGTH = 20;

const Dialpad = (props) => {
  const [number, setNumber] = useState('');
  const [contact, setContact] = useState(null);
  const [searchedContacts, setSearchedContacts] = useState(null);

  const [isDialpadOpen, toggleDialpad] = useToggle(false);

  const selectRef = useRef();
  const dialpadRef = useRef();

  useEffect(() => {
    if (isDialpadOpen) {

      setTimeout(() => {
        selectRef.current && selectRef.current.focus();
      }, 0);
    }
    else if (!isDialpadOpen) {
      setNumber('');
      setContact(null);
      setSearchedContacts(null);
    }
  }, [isDialpadOpen]);

  const callToContact = () => {
    console.log('Call to Number', number);

    if (!number.trim().length) return;

    if (props.activeConference) {
      props.onHoldActiveConference(props.activeConference, props.peerConnections, props.localStream);
    };

    playDialSound('call');

    if (contact) {
      props.callTo(contact)
    }
    else {
      const newContact = {
        fn: number,
        tels: [{ tel: number, default: 1 }]
      };

      props.callTo(newContact);
    }

    toggleDialpad(false);
  }

  const typeNumber = (e) => {
    if (number.length + 1 > MAX_LENGTH) return;

    let value = e.target.value;

    playDialSound(value);


    if (value === 'sharp') {
      value = '#';
    }
    else if (value === 'star') {
      value = '*';
    }
    else if (value === 'plus') {
      value = '+';
    }

    setNumber(number + value);

    loadOptions(number + value);
    selectRef.current.focus();

    console.log(value)
  }

  const removeLastSymbol = (e) => {
    if (number.length) {
      playDialSound(e.target.value);

      if (contact) {
        setContact(null);
      }
      setNumber(prevNumber => prevNumber.slice(0, -1));

      loadOptions(number.slice(0, -1));
    }
    selectRef.current.focus();
  }

  const removeAllSymbols = () => {
    if (searchedContacts) {
      setSearchedContacts(null);
    }
    if (number) {
      setNumber('');

      playDialSound('delAll');
      selectRef.current.focus();
    }
  }

  const playDialSound = (value) => {
    value = value === '+' || value === 'plus'
      ? 'sharp'
      : value;

    let messageSound = new Audio('./media/sounds/dialBtn-' + value + '.mp3');

    messageSound.play();
  }
  const searchByNumber = (number, onLoadCallBack) => {
    return API.searchContacts(0, number, 0, 10)
      .then(res => {
        setSearchedContacts(res.data);
        onLoadCallBack(res.data);
      })
      .catch(console.log);
  }

  const debouncedNumberSearch = useCallback(debounce(searchByNumber, 300), []);

  const loadOptions = (tel = number, callBack = () => { }) => {
    if (tel.length < 2) {
      setSearchedContacts(null);

      return callBack();
    }
    if (tel.length >= 12 && tel.startsWith('00')) {
      setNumber(prevNumber => '+' + prevNumber.slice(2))
    }

    return debouncedNumberSearch(number, callBack);
  }

  const handleChange = (caller) => {
    setNumber(getDefaultField(caller.tels, 'tel'));
    setContact(caller);
    setSearchedContacts(null);
  }

  const handleInputChange = (value, { action }) => {
    value = value.trim();

    if (action !== 'input-change' || value.length > MAX_LENGTH) {
      return false;
    }

    if (Number.isInteger(+value) || value === "+") {
      setNumber(value);
      setContact(null);

      if (value) {
        playDialSound(value[value.length - 1]);
      }
      return value;
    }

    return number;
  }

  const onKeysPress = (e) => {
    if (['Backspace', 'Delete'].includes(e.key) && number.length) {
      playDialSound('del');
    }
  };

  return (
    <div className="dialpad">
      <button
        className="dialpad__btn--open"
        onMouseDown={() => toggleDialpad()}
      >
        <SvgIcon icon="phone" title="dialpad" width="24px" height="24px" fill={isDialpadOpen ? "#BFBFBF" : "#404040"} />
        <svg width="24px" height="24px" fill={isDialpadOpen ? "#BFBFBF" : "#404040"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 288 288">
          <path d="M143 86a42 42 0 110-84 42 42 0 010 84zM42 86a42 42 0 110-84 42 42 0 010 84zM246 86a42 42 0 110-84 42 42 0 010 84zM143 186a42 42 0 110-84 42 42 0 010 84zM42 186a42 42 0 110-84 42 42 0 010 84zM246 186a42 42 0 110-84 42 42 0 010 84zM143 286a42 42 0 110-84 42 42 0 010 84zM42 286a42 42 0 110-84 42 42 0 010 84zM246 286a42 42 0 110-84 42 42 0 010 84z" />
        </svg>
      </button>


      {isDialpadOpen &&
        <DropWrapper
          closeDropWrapper={toggleDialpad}
          dropWrapperRef={dialpadRef}
        >
          <div className="dialpad-box" ref={dialpadRef}>
            <button
              className="dialpad-box__btn--remove-all"
              onClick={removeAllSymbols}
              value="delAll"
            />
            <AsyncSelect
              ref={selectRef}
              autosize={false}
              cacheOptions
              closeMenuOnSelect={false}
              isDisabled={props.isDisabled}
              menuIsOpen={number.length > 1 && !contact}
              defaultOptions={searchedContacts}
              getOptionValue={option => option.id}
              inputValue={number}
              loadOptions={loadOptions}
              maxMenuHeight={150}
              noOptionsMessage={({ inputValue }) => inputValue && '(no items)'}
              onChange={handleChange}
              onInputChange={handleInputChange}
              onKeyDown={onKeysPress}
              pageSize={2}
              placeholder="Enter number"
              value={null}
              components={{
                Option: DialpadSearchItem,
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null
              }}
              className="dialpad__select dialpad-select"
              classNamePrefix="dialpad-select"
            />

            <div className="dialpad-box__btns">
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map(num => (
                <button
                  key={num}
                  className="dialpad-box__btn"
                  onClick={typeNumber}
                  value={num}>
                  {num}
                </button>
              ))}

              <button
                className="dialpad-box__btn dialpad-box__btn--sharp"
                onClick={typeNumber}
                value="sharp">
                #
            </button>
              <button
                className="dialpad-box__btn dialpad-box__btn--star"
                onClick={typeNumber}
                value="star">
                *
            </button>
              <button
                className="dialpad-box__btn dialpad-box__btn--plus"
                onClick={typeNumber}
                value="plus">
                +
            </button>
              <button
                className="dialpad-box__btn dialpad-box__btn--call"
                onClick={callToContact}
                value="call">
                <SvgIcon icon="phone" width="52px" height="52px" fill="#01DF85" className="phone-keyboard__btn--phone" title="call" />

              </button>
              <button
                className="dialpad-box__btn dialpad-box__btn--del dialpad-box__btn--remove"
                onClick={removeLastSymbol}
                value="del">
              </button>
            </div>
          </div>
        </DropWrapper>
      }
    </div>
  );
}

const mapStateToProps = state => ({
  isDisabled: !!state.twilio.connection,
  localStream: state.webrtc.localStream,
  peerConnections: state.webrtc.peerConnections,
  activeConference: selectActiveWebrtcConference(state),
});

const mapDispatchToProps = {
  callTo,
  onHoldActiveConference,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dialpad);