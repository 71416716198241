import React from 'react';

import FullScreenCallItem from '../CallItem/FullScreenCallItem/FullScreenCallItem';
import ClientChat from '../../containers/Chat/ClientChat';
import GirlChat from '../../containers/Chat/GirlChat';

import { CHAT_TYPES } from '../../config/constants';

import { LoadContactCard } from '../../containers/Dialogs/Dialogs';


const FullScreenCallModal = (props) => {
  const {
    callerId,
    chatType,
  } = props;

  const renderChatByType = () => chatType === CHAT_TYPES.CLIENT
    ? <ClientChat
      fullScreenMode
      currentCallerId={callerId}
      customTabs={[callerId]}
    />
    : <GirlChat
      fullScreenMode
      currentCallerId={callerId}
      customTabs={[callerId]}
    />

  return (
    <div className="full-mode__wrap">
      <div className="full-mode__card-wrap">
        <LoadContactCard contact={callerId} />
      </div>
      <div className="full-mode__chat-wrap">
        <FullScreenCallItem
          closeModal={props.closeModal}
        />
        {renderChatByType()}
      </div>
    </div>
  )
};

export default FullScreenCallModal;