import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faMapPin, faStar } from '@fortawesome/free-solid-svg-icons';
import { faTelegram } from '@fortawesome/free-brands-svg-icons';

import { openModal, closeModal, MODAL_TYPES } from '../../../../ducks/activeWindows';
import { callTo } from '../../../../ducks/twilio';
import { startSearchContactsMsgsInChat, updateActiveContact } from '../../../../ducks/clientChats';
import { updateSharedContact } from '../../../../ducks/roomChats';
import {
  getContactAvatar,
  classModifier,
  getDateByTimezoneOffset,
  getShortDate,
  parseMsgText,
  formatDateToTime,
  isEmptyObj,
} from '../../../../utils';
import { selectContactById, selectIsContactActive, selectRelatedOperator, selectUserTimezone } from '../../../../selectors/selectors';
import { useToggle } from '../../../../hooks';
import { CONTACT_TYPES, INTERACTION_TYPES } from '../../../../config/constants';
import { updateActiveSession } from '../../../../ducks/sessions';

import SvgIcon from '../../../../components/SvgIcon/SvgIcon';
import LazyLoadImage from '../../../../components/LazyLoadImage/LazyLoadImage';
import { EditContactFormNew } from '../../../Dialogs/Dialogs';
import BusyStatus from './BusyStatus/BusyStatus';
import SystemMsg from '../../../Chat/components/ChatTimeline/interactions/SystemMsg';
import BookingIcon from '../../../../components/BookingIcon';
import CallButton from '../../../../components/CallButton/CallButton';
import TimeAgo from '../../../../components/TimeAgo';
import Spinner from '../../../../components/UI/Spinner/Spinner';


const ContactItem = (props) => {
  const {
    contact = {},
    style,
    currentIndex,
    isActive,
    isContactOpen,
    isBusy
  } = props;

  const { INCOMING_MSG, INCOMING_CALL, INCOMING_MSG_TELEGRAM, INCOMING_EMAIL } = INTERACTION_TYPES;

  const isLastMsgFormTelegram = !!contact.interactionObject && (
    contact.interactionObject.type === 9 ||  contact.interactionObject.type === 10
  );

  const [isBusyTooltipShowed, setBusyTooltipShowing] = useToggle(false);

  let history = useHistory();

  const handleContactClick = (e) => {
    e.preventDefault();

    if (e.target.closest("div[data-contact-ava]")) {
      props.toggleDescription && props.toggleDescription(isContactOpen, currentIndex);
    }
    else if (isContactOpen) {
      if (!props.toggleDescription) return;

      if (e.target.closest("button[data-action='edit']")) {
        props.openModal(MODAL_TYPES.editContactForm, {
          contact: contact.id,
          editMode: true,
          customSubmit: props.closeModal,
        });

        props.toggleDescription(isContactOpen, currentIndex);
      }
      else if (e.target.closest("button[data-action='call']") && !isBusy && !props.callInProcess) {
        props.callTo(contact);

        props.toggleDescription(isContactOpen, currentIndex);
      }
      else if (e.target.closest("button[data-action='share-contact']")) {
        props.updateSharedContact(contact.id);

        props.toggleDescription(isContactOpen, currentIndex);
      }
      else if (e.target.closest("button[data-action='open-chat']") && !isActive) {
        props.updateActiveContact(contact);

        props.toggleDescription(isContactOpen, currentIndex);
      }
      else if (e.target.closest('.call-btn__dropdown')) {
        props.toggleDescription(isContactOpen, currentIndex);
      }
    }
    else if (e.target.closest("svg[data-booking-icon]")) {
      history.push('/client/sales');
      props.updateActiveSession(contact.closestBooking.session_id);
    }
    else {
      props.updateActiveContact(contact);

      const chatSearch = props.type === CONTACT_TYPES.CLIENT
        ? props.clientsChatSearch
        : props.girlsChatSearch;
      
      // if we search by msgs (global) and search contact is active (opened chat with it),
      // we need to start search inside the contactsChat
      if (isActive && props.searchSource === 'msgs' && !!props.search && chatSearch !== props.search) {
        props.startSearchContactsMsgsInChat(props.type, props.search);
      }
    }
  };


  const onContextMenu = (e) => {
    e.preventDefault();

    return props.isContextMenu
      ? props.openContextMenu([e.clientX, e.clientY], contact)
      : null;
  };

  const renderPin = () => (
    <div className="contact-item__pinned">
      <FontAwesomeIcon icon={faMapPin} title="pinned" />
    </div>
  );

  const formatDescription = () => {
    return (contact.note == null || contact.note === undefined)
      ? 'No notes about this contact...'
      : contact.note;
  };

  // Show count when description is closed
  const showUnreadCount = () => {
    const isRelatedUser = !!contact.relatedUserId && contact.relatedUserId !== props.userId;

    return contact.unreadCount > 0 && !isContactOpen
      ? <span className={
        contact.withImportantUnread
          ? 'contact-item__count contact-item__count--important'
          : classModifier('contact-item__count', isRelatedUser && 'related')
      }>
        {contact.unreadCount >= 100 ? '99+' : contact.unreadCount}
      </span>
      : null;
  }

  const showDraft = contact.draft_message && !isContactOpen
    ? <span className="contact-item__draft">
      <FontAwesomeIcon icon={faPencilAlt} title="draft" />
    </span>
    : null;

  const busyStatus = isBusy
    ? <span className="contact-item__busy"
      style={isActive ? { border: "2px solid #fff" } : { border: "2px solid #e6ebf1" }}
      onMouseEnter={() => setBusyTooltipShowing()}
      onMouseLeave={() => setBusyTooltipShowing()}
    ></span>
    : null;


  const renderRelatedOperator = () => {
    if (!contact.relatedUserId || isContactOpen) {
      return null;
    }
    const name = contact.relatedUserId === props.userId
      ? 'You'
      : props.relatedOperator && props.relatedOperator.username;

    return <span className="contact-item__related contact-item__related">{name}</span>;
  };

  const showTimelineDate = (utcDate) => {
    const dateByTimezone = getDateByTimezoneOffset(props.userTimezone, utcDate);
    const lastActiveByTimezone = getShortDate(dateByTimezone);

    const d = getDateByTimezoneOffset(props.userTimezone);
    const todayByTimezone = getShortDate(getDateByTimezoneOffset(props.userTimezone));
    const yesterdayByTimezone = getShortDate(new Date(d.setDate(d.getDate() - 1)));

    const currentYear = d.getFullYear();
    const interactionYear = new Date(lastActiveByTimezone).getFullYear();

    if (lastActiveByTimezone === todayByTimezone) {
      return formatDateToTime(dateByTimezone, props.userHour12, false);
    }
    else if (lastActiveByTimezone === yesterdayByTimezone) {
      return "Ystd";
    }
    else if (interactionYear !== currentYear) {
      return dateByTimezone.toLocaleDateString('ru-RU', { year: '2-digit', day: 'numeric', month: 'numeric' });
    }
    else {
      return dateByTimezone.toLocaleDateString('en-US', { day: 'numeric', month: 'short' });
    }
  };

  const renderLastInteraction = () => {
    let lastInteraction = '';

    const interactionObject = props.searchSource === 'msgs' && !!contact.interactionObject_searched && !!props.search
      ? contact.interactionObject_searched
      : contact.interactionObject;

    switch (interactionObject.type) {
      case 1: {
        lastInteraction = 'Incoming call';
        break;
      }
      case 2: {
        lastInteraction = 'Outgoing call';
        break;
      }
      case 3: {
        lastInteraction = interactionObject.message;
      }
      case 4: {
        if (interactionObject.user_id === props.userId) {
          lastInteraction = 'You: ' + interactionObject.message;
          break;
        }
        else {
          lastInteraction = interactionObject.message;
          break;
        }
      }
      case 8: {
        // // we don't need parse msg on attachment if it's system msg
        return <SystemMsg
          interaction={interactionObject}
          returnOnlyMsgString={true}
          userHour12={props.userHour12}
        />
      }
      case 9: {
        if (interactionObject.attachments?.hasOwnProperty('voiceMsg')) {
          lastInteraction = 'Incoming voice message'
          break;
        }
        if (
          interactionObject.attachments?.hasOwnProperty('images') &&
          interactionObject.attachments?.hasOwnProperty('videos')
        ) {
          lastInteraction = 'Incoming media'
          break;
        }
        if (interactionObject.attachments?.hasOwnProperty('videos')) {
          lastInteraction = 'Incoming video'
          break;
        }
        if (interactionObject.attachments?.hasOwnProperty('images')) {
          lastInteraction = 'Incoming image'
          break;
        }
        else {
          lastInteraction = interactionObject.message;
          break;
        }
      }
      case 10: {
        if (interactionObject.attachments?.hasOwnProperty('voiceMsg')) {
          lastInteraction = 'Outgoing voice message'
          break;
        }
        if (
          interactionObject.attachments?.hasOwnProperty('images') &&
          interactionObject.attachments?.hasOwnProperty('videos')
        ) {
          lastInteraction = 'Outgoing media'
          break;
        }
        if (interactionObject.attachments?.hasOwnProperty('videos')) {
          lastInteraction = 'Outgoing video'
          break;
        }
        if (interactionObject.attachments?.hasOwnProperty('images')) {
          lastInteraction = 'Outgoing image'
          break;
        }
        else {
          lastInteraction = interactionObject.message;
          break;
        }
      }
      case 12: {
        lastInteraction = 'Incoming email'
        break;
      }
      default:
        lastInteraction = interactionObject.message;
    }

    return parseMsgText(lastInteraction, true);
  };

  const showContactName = () => {
    if (contact.fn.startsWith('EX ')) {
      return <><span className="contact-item__ex">EX </span>{contact.fn.slice(3)}</>
    }

    return contact.fn;
  }

  if (isEmptyObj(contact)) {
    return (
      <div className="contact-item contact-item--pending">
        <Spinner spinnerSize="32px" />
      </div>
    )
  }

  return (
    <div
      style={isActive ? { ...style, border: "1px solid #0092f2", backgroundColor: "#cde8fc" } : style}
      className={classModifier('contact-item', [
        isContactOpen && "open",
        contact.marked && !props.isSimpleView && "marked",
        contact.availability && contact.availability === 'off today'
          ? 'off-today'
          : contact.availability,
        contact.agentId && "with-agent",
      ])}
      data-is-open={isContactOpen}
      onClick={handleContactClick}
      onContextMenu={onContextMenu} >

      <div
        className={`contact-item__img-wrap ${contact.blocked ? "blocked" : ""} ${
          contact.is_active_chat ? "contact-item__img-wrap--active" : ""
        }`}
        data-contact-ava
      >
        <LazyLoadImage src={getContactAvatar(contact)} alt="ava" className="contact-item__img" />
        {busyStatus}

        {contact.type === CONTACT_TYPES.GIRL && contact.exclusive &&
          <span className="contact-item__exclusive">
            <FontAwesomeIcon
              icon={faStar}
              title="exclusive"
              className="contact-item__exclusive--icon"
            />
          </span>
        }

        {isLastMsgFormTelegram &&
          <FontAwesomeIcon icon={faTelegram} color="#0092F2"/>
        }
      </div>

      <div className="contact-item__content-wrap">
        <div className="contact-item__info">
          <BookingIcon
            classPrefix="contact-item__icon"
            booking={contact.closestBooking}
            userTimezone={props.userTimezone}
          />
          <h4 className="contact-item__name">
            {showDraft}
            {showContactName()}

            {contact.type === CONTACT_TYPES.GIRL &&
              <>
                <span className="contact-item__rates">
                  {!!contact.rates && contact.rates}
                </span >

                {!!contact.rating &&
                  <span className="chat-header__rating">
                    ({contact.rating})
                  </span>
                }
              </>
            }

          </h4>

          <span className="contact-item__time">
            {props.isFromListOfGirlsWithStatus
              ? contact.statusUpdatedDate && <TimeAgo minPeriod={60} date={contact.statusUpdatedDate} />
              : contact.last_interaction && showTimelineDate(contact.last_interaction)
            }
          </span>
        </div>

        <div
          className={`contact-item__last-msg  ${contact.interactionObject
            ? ([INCOMING_MSG, INCOMING_CALL, INCOMING_MSG_TELEGRAM, INCOMING_EMAIL].includes(contact.interactionObject.type)
              && 'contact-item__last-msg--operator')
            : ''
            }`
          }
        >

          {(contact.interactionObject || contact.interactionObject_searched) && !isContactOpen &&
            renderLastInteraction()}
        </div>

        {!props.isSimpleView && contact.pinned && renderPin()}

        {showUnreadCount()}

        {renderRelatedOperator()}

        {isBusy && isBusyTooltipShowed &&
          <BusyStatus id={props.id} isContact />
        }

        <button
          className="contact-item__btn contact-item__btn--edit"
          data-action="edit"
          onMouseOver={EditContactFormNew.preload}
        >
          <SvgIcon icon="edit" fill="#404040" height={16} title="edit contact" />
        </button>

        {isContactOpen &&
          <div className="contact-item__btns">
            <button
              className="contact-item__btn contact-item__btn--mess"
              data-action="open-chat"
            >
              <SvgIcon width="20" height="20" icon="messages" fill="#0092F2" title="send message" />
            </button>
            <CallButton
              className="contact-item__btn contact-item__btn--call"
              activeRecipient={contact}
              isConfirm
              dropdownPosition='right'
              width={20}
              height={20}
            />
            {/* <button
              className="contact-item__btn contact-item__btn--call"
              data-action="call"
              //FIXME this 'disabled' doesn't working
              disabled={isBusy || !!props.callInProcess}>
              <SvgIcon width="20" height="20" icon="phone" fill="#0092F2" />
            </button> */}
            <button
              className="contact-item__btn contact-item__btn--share-contact"
              data-action="share-contact"
            >
              <svg width="20px" height="20px" fill="#0092F2" viewBox="0 0 31.939 31.939">
                <path d="M15.6 18.3h-.8a.7.7 0 0 1-.7-.7l.1-.4c.5-.4.9-1 1.1-1.7l.2.1c.5 0 1-1 1-1.6 0-.7 0-1.2-.5-1.2h-.2c0-1.8-.5-4-3.2-4-2.8 0-3.2 2.2-3.2 4h-.1c-.4 0-.5.5-.5 1.2 0 .6.5 1.6 1 1.6h.1c.3.6.7 1.2 1.1 1.6l.2.4c0 .4-.4.7-.8.7h-.7a3 3 0 0 0-3 3v.9c0 .5.4 1 .9 1h10c.6 0 1-.5 1-1v-.9a3 3 0 0 0-3-3zM24.6 10h-8.4l.5 2.1.4.5h7.5c.4 0 .7-.3.7-.7v-1.1c0-.4-.3-.7-.7-.7z" />
                <path d="M24.6 14.7h-7.3c-.2.7-.7 1.5-1.4 1.7V17.2h8.7c.4 0 .7-.3.7-.7v-1.1c0-.4-.3-.7-.7-.7zM24.6 19.3h-5.7c.3.6.6 1.3.6 2v.5h5c.5 0 .8-.3.8-.7V20c0-.4-.3-.7-.7-.7z" />
                <path d="M27.6 3H4.3C2 3 0 5 0 7.5v17.2c0 2.3 2 4.3 4.3 4.3h23.3c2.4 0 4.3-2 4.3-4.3V7.4C32 5 30 3 27.6 3zM30 24.7c0 1.2-1 2.2-2.3 2.2H4.3c-1.2 0-2.3-1-2.3-2.2V7.4C2 6 3 5 4.3 5h23.3c1.3 0 2.3 1 2.3 2.3v17.2z" />
              </svg>
            </button>
          </div>
        }
      </div>

      <div className="contact-item__description hidden">
        {formatDescription()}
      </div>

    </div >
  );
};

const mapStateToProps = (state, ownProps) => ({
  contact: selectContactById(state, ownProps.id),
  isActive: selectIsContactActive(state, ownProps.type, ownProps.id),
  userId: state.user.id,
  userTimezone: selectUserTimezone(state),
  isBusy: !!state.calls.connectedContacts[ownProps.id],
  // agent: selectAgentContactById(state, ownProps.id),
  relatedOperator: selectRelatedOperator(state, ownProps.id),
  userHour12: state.user.hour12,
  callInProcess: state.calls.activeCall.status,
  clientsChatSearch: state.clientChats.search,
  girlsChatSearch: state.girlChats.search,
});

const mapDispatchToProps = {
  callTo,
  openModal,
  closeModal,
  updateActiveContact,
  updateSharedContact,
  updateActiveSession,
  startSearchContactsMsgsInChat,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactItem);