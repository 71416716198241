import React from 'react';
import { connect } from 'react-redux';

import LazyLoadImage from '../../../../components/LazyLoadImage/LazyLoadImage';
import SvgIcon from '../../../../components/SvgIcon/SvgIcon';
import CallButton from '../../../../components/CallButton/CallButton';

import { getContactsById } from '../../../../ducks/contacts';
import { selectContactById } from '../../../../selectors/selectors';
import { updateActiveContact } from '../../../../ducks/clientChats';
import { getContactAvatar, getDefaultField } from '../../../../utils';
import { useDidMount } from '../../../../hooks';
import { openModal, MODAL_TYPES } from '../../../../ducks/activeWindows';


const SharedContact = ({ contact, ...props }) => {
  useDidMount(() => {
    // contact not in entities
    if (!contact && props.contactFromMsg) {
      props.getContactsById(props.contactFromMsg.id, props.contactFromMsg.type)
    }
  });

  const sharedContact = contact
    ? contact
    : props.contactFromMsg;

  return (
    <div className={`attachment-contact ${props.className ? props.className : ""}`}>
      {props.showTitle &&
        <h4 className="attachments__title">Attached contact</h4>
      }

      <div className="attachment-contact__body">
        <div
          onClick={() => props.openModal(MODAL_TYPES.contactCard, { contact: sharedContact.id })}
          className="attachment-contact__img-wrap">
          <LazyLoadImage src={getContactAvatar(sharedContact)} alt="ava" className="round-img" />
        </div>

        <div className="attachment-contact__info">
          <span className="attachment-contact__meta">{sharedContact.short_name || sharedContact.fn}</span>
          <span className="attachment-contact__meta">{sharedContact.tels && getDefaultField(sharedContact.tels, 'tel')}</span>
        </div>

        {props.showActions &&
          <div className="attachment-contact__actions">
            <CallButton
              className="attachment-contact__btn"
              activeRecipient={sharedContact}
              isConfirm
              dropdownPosition='right' />

            {!!contact &&
              <button
                className="attachment-contact__btn"
                onClick={() => props.updateActiveContact(sharedContact)}>
                <SvgIcon icon="messages" fill="#0092F2" title="message" />
              </button>
            }
          </div>
        }
      </div>
    </div>
  );
}


const mapStateToProps = (state, ownProps) => ({
  contact: selectContactById(state, ownProps.contactId),
});

const mapDispatchToProps = {
  updateActiveContact,
  getContactsById,
  openModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(SharedContact);