import React from 'react';
import { connect } from 'react-redux';

import CallItem from '../../../../components/CallItem/CallItem';


const ActiveCall = (props) => {
  if (!props.activeCall.id) {
    return null; // no activeCall
  }
  if (props.activeCall.type === 1 && !['connecting', 'in-progress'].includes(props.activeCall.status)) {
    return null; // incomingCall which not in progress
  }

  const getConnectedContact = () => {
    if (props.activeCall.caller) {
      return props.activeCall.caller;
    }
    else if (props.activeCall.senderId === props.profileId) {
      return props.activeCall.recipient;
    }
    else {
      return props.activeCall.sender;
    }
  };

  return (
    <CallItem
      actions={props.activeCall.caller ? true : false}
      active
      call={props.activeCall}
      caller={getConnectedContact()} />
  );
};

const mapStateToProps = state => ({
  activeCall: state.calls.activeCall,
  profileId: state.user.id
});

export default connect(mapStateToProps)(ActiveCall);