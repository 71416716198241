import React, { useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import ReCAPTCHA from "react-google-recaptcha";

import { useDidMount, useToggle } from '../../hooks';
import API from '../../api/api';
import getCookie from '../../utils/getCookie';
import { LS } from '../../utils'

import './LoginPage.scss';
import GlobalPreloader from '../../components/GlobalPreloader/GlobalPreloader';
import FormTextInput from './components/FormTextInput/FormTextInput';
import Spinner from '../../components/UI/Spinner/Spinner';

const currentHostname = window?.location?.hostname;
const isHideRecaptcha = process.env.NODE_ENV === 'development' ||
  currentHostname.includes('comdiva.local') ||
  currentHostname.includes('commdiva.abcwdl.com');

const LoginPage = (props) => {
  const [authInfo, setAuthInfo] = useState({
    loginPending: false,
    errorMsg: null,
  })

  const [isRecaptchaValid, toggleRecaptchaValid] = useToggle(isHideRecaptcha); //TODO: return false
  const [pagePending, setPagePending] = useState(true);

  const inputRef = useRef();
  const recaptchaRef = useRef();

  //FIXME Clear all cookies on login page on release-46. Delete on new release-47
  useDidMount(() => {
    if (localStorage.getItem('Release46CookiesRemoved')) {
      return;
    }

    (function () {
      var cookies = document.cookie.split("; ");
      for (var c = 0; c < cookies.length; c++) {
        var d = window.location.hostname.split(".");
        while (d.length > 0) {
          var cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=';
          var p = window.location.pathname.split('/');
          document.cookie = cookieBase + '/';
          while (p.length > 0) {
            document.cookie = cookieBase + p.join('/');
            p.pop();
          };
          d.shift();
        }
      }
    })();

    console.log('Cookies cleared!');

    localStorage.Release46CookiesRemoved = 1;
  })

  useDidMount(() => {
    const initApp = async () => {
      await API.getIsIAmLoggedIn()
        .then(() => {
          localStorage.isLoggedIn = 1;

          props.history.push('/');
        })
        .catch((err) => {
          console.log(err);
          setPagePending(false);
          inputRef.current && inputRef.current.focus();
        });
    };

    if (process.env.NODE_ENV === 'development') {
      if (localStorage.isLoggedIn) {
        props.history.push('/');
      }
      else {
        initApp();
      }
    }
    else if (!!getCookie('isAuthenticated')) {
      console.log('go to APP.js cookie steel exist')
      props.history.push('/');
    }
    else {
      setPagePending(false);
      inputRef.current && inputRef.current.focus();
    }
  });

  const loginUser = (values, history) => {
    setAuthInfo({ ...authInfo, loginPending: true });

    return API.loginUser(values)
      .then(res => {
        if (res.data.success === 1 && res.data.token.length > 0) {
          const userId = res.data.user.id;

          localStorage.isLoggedIn = 1; // only for development mode
          localStorage.currentUserId = userId;
          localStorage.isLoginAction = 1;

          const afkTime = LS.getAFKTime(Date.now(), userId);

          if (afkTime > 4) {
            LS.cleanUserChatTabs(userId)
          }

          window.location = '/';
        }
        else {
          setAuthInfo({
            loginPending: false,
            errorMsg: res.data.message
          })

          return 'wrong_credentials';
        }
      })
      .catch(err => {
        setAuthInfo({
          loginPending: false,
          errorMsg: err.response.data.message
        })
      })
  };

  const onSubmit = (values) => {
    return loginUser(values, props.history)
      .then(res => {
        if (res === 'wrong_credentials') {
          recaptchaRef.current.reset();
          toggleRecaptchaValid(false);
        }
      });
  };

  if (pagePending) {
    return <GlobalPreloader />;
  }

  return (
    <div className="login">

      <div className="login__container">
        <aside className='login__info'>
          <h1 className='login__title'>
            Communication Module
          </h1>

          <h2 className='login__subtitle'>
            Please Enter Your Username
          </h2>

          <div className='login__text'>Updates in 0.38 Beta</div>

          <ul className="login__info-list">
            <li className="login__info-list-item">Cancel booking only with reason</li>
            <li className="login__info-list-item">New clients widget feature</li>
            <li className="login__info-list-item">Modification of the Address Book form</li>
            <li className="login__info-list-item">Links Labels</li>
            <li className="login__info-list-item">Modification of the logic for updating contacts from fastmail</li>
            <li className="login__info-list-item">Clients Active Filter</li>
            <li className="login__info-list-item">Global chat search in another place</li>
            <li className="login__info-list-item">Extension of the Telegram Messages component</li>
            <li className="login__info-list-item">Don`t show service messages in the general stream</li>
            <li className="login__info-list-item">Show chat with the agent in the buffer</li>
          </ul>

          <p className='login__text-bottom'>0.38 Beta for Diva</p>
        </aside>

        <Form
          onSubmit={onSubmit}
          validate={validate}>
          {({ handleSubmit, submitting, pristine }) => (
            <form
              className="login__form"
              onSubmit={handleSubmit}
            >
              <div className={authInfo.errorMsg ? "login__error" : "login__error--hidden"} role="alert">
                {authInfo.errorMsg}
              </div>

              <div className='login__form-field'>
                <span className='login__label'>Username</span>

                <div className="login__input-wrap">
                  <Field
                    name="username"
                    inputRef={inputRef}
                    autoFocus
                    tabIndex={1}
                    errorInputClass="login__input--error"
                    errorClass="login__error"
                    component={FormTextInput}
                    placeholder="Username"
                    className="login__input"
                  />
                </div>
              </div>

              <div className='login__form-field'>
                <span className='login__label'>Password</span>
                <div className="login__input-wrap">
                  <Field
                    name="password"
                    component="input"
                    type="password"
                    tabIndex={2}
                    placeholder="Password"
                    className="login__input" />
                </div>
              </div>

              {!isHideRecaptcha &&
                <div className="login__recaptcha">
                  <ReCAPTCHA
                    sitekey='6LcTJLUUAAAAAJ9lgSjRKy0ElVzqt_KxDvroGTdF' // TODO: move to config 
                    onChange={() => toggleRecaptchaValid()}
                    ref={recaptchaRef}
                  />
                </div>
              }

              <div>
                <button
                  type="submit"
                  value="Log In"
                  tabIndex={3}
                  className="login__btn"
                  disabled={submitting || !isRecaptchaValid || authInfo.loginPending}>
                  {authInfo.loginPending
                    ? <span className="login__spinner">
                      <Spinner />
                    </span>
                    : 'Login'
                  }
                </button>

                {/* <AsyncButton
                  type="submit"
                  value="Log In"
                  tabIndex={1}
                  className="login__btn"
                  disabled={submitting || pristine || !isRecaptchaValid}
                  spinnerProps={{
                    width: 16,
                    height: 16
                  }}
                >
                  Login
                </AsyncButton> */}
              </div>
            </form>
          )}
        </Form>
      </div>
    </div>
  );
};


const validate = ({ username, password }) => {
  const errors = {};

  if (!username || !username.trim().length) {
    errors.username = 'Enter username';
  }
  if (!password || !password.trim().length) {
    errors.password = 'Enter the password';
  }
  return errors;
};
export default withRouter(LoginPage);