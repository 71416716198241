import React, { memo } from 'react';
import Highlighter from "react-highlight-words";

import { parseMsgText, classModifier } from '../../../../../utils';
import SvgIcon from '../../../../../components/SvgIcon/SvgIcon';

const MsgText = ({ interaction, highlightedText, ...props }) => {
  console.log('MsgText Render');

  const handleGirlNameClick = (e) => {

    if (props.onGirlNameClick && typeof props.onGirlNameClick === 'function') {
      return props.onGirlNameClick(e);
    }
    return;
  }

  const highlightIfNeed = (text, highlightedText) => highlightedText
    ? <Highlighter
      highlightClassName="YourHighlightClass"
      searchWords={highlightedText.split(' ')}
      autoEscape={true}
      textToHighlight={text}
      key={Math.random()}
    />
    : text;

  const getMsgTextLayout = () => {
    if (interaction.hasUrls) {
      const parsedArray = parseMsgText(interaction.body);
      const parsedMsg = [];

      // if parseRegex find no match. Also it mean that server not parse msg correct;
      if (typeof parsedArray === 'string') {
        console.warn('Custom client error: Server bad parse interaction with id: ', interaction.id)
        return highlightIfNeed(parsedArray, highlightedText);
      }

      parsedArray.forEach((part, idx) => {
        switch (part.type) {
          case 'text':
            parsedMsg.push(part.value);
            break;
          case 'mention':
            parsedMsg.push(
              <span
                className="interaction__mention"
                key={idx}
                data-value={part.value}
              >
                {highlightIfNeed(part.value, highlightedText)}
              </span>
            );
            break;
          case 'link':
            parsedMsg.push(
              <a
                className="interaction__link"
                href={part.value}
                target="_blank"
                rel="noopener noreferrer"
                key={idx}
              >
                {highlightIfNeed(part.value, highlightedText)}
              </a>
            );
            break;
          case 'girlName':
            parsedMsg.push(
              <span
                className="interaction__clickable-name"
                data-value={part.value}
                key={idx}
                onClick={handleGirlNameClick}
              >
                {highlightIfNeed(part.value, highlightedText)}
              </span>
            );
            break;

          default:
            break;
        }
      });
      return parsedMsg;

    }
    else {
      return highlightIfNeed(parseMsgText(interaction.body, true), highlightedText)
    }
  }

  return (
    <p
      className={classModifier('interaction__text', [
        (interaction.is_deleted && !interaction.attachments) && 'deleted',
        props.isMsgExpanded && 'expanded',
        props.mode,
      ])}>
      {getMsgTextLayout(interaction)}
    </p>
  );
}

export default memo(MsgText);