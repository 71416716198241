import React, { useState, useEffect, useMemo } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Loadable from 'react-loadable';

import API from './api/api';
import './config/api-config';
import { TITLE } from './config/constants';
import { useDidMount } from './hooks';
import { initApp } from './ducks/user';
import { getUserInfo } from './ducks/user';
import { detectMobileDevices, getCookie, deleteCookie, capitalizeFirstLetter } from './utils';

import './App.css';
import MainPage from './scenes/MainPage/MainPage';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import NotFound from './scenes/NotFound/NotFound';
import ChatSocket from './components/ChatSocket';
import TwilioSetup from './components/TwilioSetup';
import Dialogs from './containers/Dialogs/Dialogs';
import AsyncComponentLoader from './components/AsyncComponentLoader/AsyncComponentLoader';
import Navbar from './containers/Navbar/Navbar';
import GlobalPreloader from './components/GlobalPreloader/GlobalPreloader';
import LoginPage from './scenes/Login/LoginPage';
import FaviconEditor from './components/FaviconEditor';
import WebrtcAudioElements from './components/WebrtcAudioElements';
import MobileDevicesPlug from './containers/MobileDevicesPlug/MobileDevicesPlug';

export const LoadSalesPage = Loadable({
  loader: () => import(/* webpackChunkName: "SalesPageChunk"*/ './scenes/SalesPage/SalesPage'),
  loading: AsyncComponentLoader,
  delay: 400,
  timeout: 10000, // 10 seconds
  modules: ['SalesPageChunk']
});

export const LoadAdrBookPage = Loadable({
  loader: () => import(/* webpackChunkName: "AdrBookPageChunk"*/ './scenes/AdrBookPage/AdrBookPage'),
  loading: AsyncComponentLoader,
  delay: 400,
  timeout: 10000, // 10 seconds
  modules: ['AdrBookPageChunk']
});

export const LoadMailPage = Loadable({
  loader: () => import(/* webpackChunkName: "LoadMailPageChunk"*/ './scenes/MailPage/MailPage'),
  loading: AsyncComponentLoader,
  delay: 400,
  timeout: 10000, // 10 seconds
  modules: ['LoadMailPageChunk']
});

export const LoadMainPage = Loadable({
  loader: () => import(/* webpackChunkName: "MainPageChunk"*/ './scenes/MainPage/MainPage'),
  loading: AsyncComponentLoader,
  delay: 400,
  timeout: 10000, // 10 seconds
  modules: ['MainPageChunk']
});



const PrivateRoutes = (props) => {
  const [pagePending, setPagePending] = useState(true);

  useEffect(() => {
    const pathName = props.history.location.pathname;
    const tabTitle = pathName.substring(pathName.lastIndexOf('/') + 1);

    if (tabTitle) {
      document.title = `${capitalizeFirstLetter(tabTitle)} | ${TITLE}`;
    }
    else {
      document.title = TITLE;
    }
  }, [props.history.location]);

  useDidMount(() => {
    const initApp = async () => {
      await API.getIsIAmLoggedIn()
        .then(res => {
          console.log(222222222222);

          if (!localStorage.isLoggedIn) {
            localStorage.isLoggedIn = 1;
          }

          localStorage.currentUserId = res.data.id;

          setPagePending(false);

          props.initApp(res.data);
        })
        .catch(() => {
          deleteCookie('isAuthenticated');

          console.log('hello, cookie remove');

          delete localStorage.isLoggedIn;
          delete localStorage.currentUserId;

          props.history.push('/client/login');
        })
    }
    initApp();
  });

  if (pagePending) {
    return <GlobalPreloader />;
  }

  return (
    <div className="main-page">

      <TwilioSetup />
      <ChatSocket />
      <FaviconEditor />

      <WebrtcAudioElements />

      <Dialogs />

      <Navbar
        setPagePending={setPagePending}
      />

      <Switch>
        <Route exact path="/" component={MainPage} />

        <Route path="/client/mail" component={LoadMailPage} />
        <Route exact path="/client/sales" component={LoadSalesPage} />
        <Route exact path="/client/adr-book" component={LoadAdrBookPage} />

        <Redirect to="/" />
      </Switch>
    </div>
  );
};

const mapDispatchToProps = {
  initApp,
  getUserInfo
};

const ConnectedPrivateRoute = connect(null, mapDispatchToProps)(PrivateRoutes);

const App = () => {
  const isMobileDevice = useMemo(detectMobileDevices, []);

  if (isMobileDevice) {
    return <MobileDevicesPlug />;
  }

  return (
    <Switch>
      <Route path="/client/login" component={LoginPage} />

      <PrivateRoute
        path="/"
        component={ConnectedPrivateRoute}
        isAuthenticated={process.env.NODE_ENV === 'development'
          ? !!localStorage.isLoggedIn
          : !!getCookie('isAuthenticated')
        }
      />

      <Route path="/not-found" component={NotFound} />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default App;
