import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import API from "../../../api/api";
import {
  closeSession,
  deleteSession,
  updateActiveSession,
  transferTheSession,
  addAssistantToSession,
  removeVisitedSession,
  duplicateClosedSession,
  continueClosedSession,
  removeAssistantFromSession,
  getSessionById,
  deleteGirlsIdsThatLinksIsSendInChat
} from '../../../ducks/sessions';
import { openModal, MODAL_TYPES } from '../../../ducks/activeWindows';
import {
  selectContactBySessionId,
  selectOperatorBySessionId,
  selectActiveSessionIsPartners,
  selectIsAmSessionOwner,
  selectIsUnreadMsgsInSession,
} from '../../../selectors/selectors';
import {
  convertDateFromUTC,
  LS,
  getContactAvatar,
  classModifier
} from "../../../utils";
import { timerStart } from '../../../ducks/timers';
import { updateActiveContact } from '../../../ducks/clientChats';
import { getContactsById } from '../../../ducks/contacts';
import useDidMount from '../../../hooks/useDidMount';
import { useHideTemporaryTooltip } from "../../../hooks";

import CallTimer from '../../../components/CallItem/CallTimer';
import Menu from '../../../components/Menu/Menu';
import SearchByOperators from "../../../components/SearchByOperators/SearchByOperators";
import SearchByClients from "../../../components/SearchByClients/SearchByClients";
import DropWrapper from '../../../components/DropWrapper/DropWrapper';
import LazyLoadImage from "../../../components/LazyLoadImage/LazyLoadImage";
import SvgIcon from '../../../components/SvgIcon/SvgIcon';


const Session = props => {
  const {
    id,
    isHistory,
    isDefault,
    isMine,
    isPartners,
    isAssistance,
    isAmSessionOwner,
    activeSessionIdIfItPartners,
    contact,
    myId,
    // defaultSession,
    reqForTransferId,
    reqForAssistanceIds = [],
    operatorsIds,
  } = props;
  const isTransferRequest = reqForTransferId === myId;
  const isAssistanceRequest = reqForAssistanceIds.includes(myId);

  const [tooltipStatus, setTooltipStatus] = useHideTemporaryTooltip('', 2000);

  const [searchQuery, setSearchQuery] = useState('');

  const [operatorsMenuModal, setOperatorsMenuModal] = useState({
    show: false,
    text: ''
  });

  const [clientsMenuModal, setClientsMenuModal] = useState({
    show: false,
    text: '',
    type: ''
  });

  const menuBtnRef = useRef();
  const operatorsRef = useRef();
  const clientsRef = useRef();

  useDidMount(() => {
    if (!isDefault && !isHistory) {
      props.timerStart('session_' + id, props.dateCreated);
    }

    if (!contact && !isDefault) {
      props.getContactsById(props.contactId, 1);
    }
  });

  // useEffect(() => { // update default session in localstorage
  //   const defaultSessionFromLS = LS.getUserStorage().defaultSession;
  //   const isUpdated = defaultSession && JSON.stringify(defaultSessionFromLS) !== JSON.stringify(defaultSession);

  //   if (isUpdated) {
  //     LS.setItem('defaultSession', defaultSession, myId);
  //   }
  // }, [defaultSession, myId]);

  const getIgnoreOperatorsIds = useCallback(isPartners =>
    isPartners
      ? [...operatorsIds, ...reqForAssistanceIds]
      : operatorsIds
    , [operatorsIds, reqForAssistanceIds]);

  console.log('SESSION RENDER');

  const closeSearchByOperators = () => setOperatorsMenuModal({
    show: false,
    text: ''
  });

  const openSearchByClients = (text, type) => () => setClientsMenuModal({ show: true, text, type }); // Closure

  const closeSearchByClients = () => setClientsMenuModal({
    show: false,
    text: '',
    type: ''
  });

  const onTransferSession = (operatorId) => props.transferTheSession(id, operatorId);

  const onAddPartnerToSession = (operatorId) => props.addAssistantToSession(id, operatorId);

  const onRemovePartnerFromSession = () => {
    if (window.confirm('Remove assistant from session ?')) {
      props.removeAssistantFromSession(id, operatorsIds.find(id => id !== myId));
    }
  }

  const deleteIdGirlsWholeLinksWereSentFromTheArray = () =>
    props.arrayGirlsIdsThatLinksIsSend?.length && props.deleteGirlsIdsThatLinksIsSendInChat(id);

  const closeSession = () => {
    const question = `Close session with client ${contact.fn}?`;

    if (isHistory) {
      return props.removeVisitedSession(id);
    }

    window.confirm(question) && props.closeSession(id) && deleteIdGirlsWholeLinksWereSentFromTheArray();
  };

  const deleteSession = () => {
    const question = `Delete session with client ${contact.fn}?`;

    window.confirm(question) && props.deleteSession(id) && deleteIdGirlsWholeLinksWereSentFromTheArray();
  };

  const onUpdateActiveSession = e => {
    if (e.target.dataset.sessionRequest) {
      return;
    }
    if (e.target.dataset.avatar) {
      return props.openModal(MODAL_TYPES.contactCard, { contact: props.contactId })
    }
    if (isMine || isAssistance || isHistory) {
      const isInMenuBtn = menuBtnRef.current.contains(e.target);
      const isInOperators = operatorsRef.current.contains(e.target);
      const isInClients = clientsRef.current.contains(e.target);
      const isDropdownMenu = ~e.target.className.indexOf('dropdown-menu');

      if (isInMenuBtn || isInOperators || isInClients || isDropdownMenu) { // Click on menu
        return;
      }
    }

    if (isPartners) { // Look at Partner Session
      props.getSessionById(id, 1);
    }

    if ((activeSessionIdIfItPartners || activeSessionIdIfItPartners === 0) && !isPartners) {
      API.removeViewedInSession(activeSessionIdIfItPartners)
        .catch(console.log);
    }

    props.updateActiveSession(id); // Update Active Session

    if (props.history.location.pathname === '/') {
      props.history.push('/client/sales');
    }

    if (props.isUnreadMsg) {
      props.openModal(MODAL_TYPES.profilesBuffer, { sessionId: id });
    }
  };

  const duplicateOrContinueSession = (isDuplicate, clientId) => () => { // Closure
    const action = isDuplicate
      ? props.duplicateClosedSession
      : props.continueClosedSession;

    action(id, clientId)
      .then(res => {
        if (res.errorMsg === 'Caller can have only one active session') {
          return setTooltipStatus('Session with this client already exist');
        }
      });
  };

  const replyToAssistanceRequest = (status) => {
    API.responseForAddToAssistant(id, status);
  }

  const replyToTransferRequest = (status) => {
    API.responseForTransferSession(id, status);
  }

  const renderMenu = () => {
    const menu = [];

    const addNewMenuItem = (action, name) => menu.push({ action, name });

    // addNewMenuItem(onUpdateActiveContact, 'Open Chat'); // FIXME: ЗАГЛУШКА

    if (canIEditSession) { // If It is my session
      const isAssistanceSession = operatorsIds.length > 1;

      if (!isAssistanceSession) {
        addNewMenuItem(() => setOperatorsMenuModal({
          show: true,
          text: `Add Partner to session with ${contact.fn}:`,
          partner: true
        }), 'Add Partner to Session');

        !reqForTransferId && addNewMenuItem(() => setOperatorsMenuModal({
          show: true,
          text: `Transfer session with ${contact.fn} to:`,
          transfer: true
        }), 'Transfer the Session');
      }
      if (isAssistanceSession && isAmSessionOwner) {
        addNewMenuItem(onRemovePartnerFromSession, 'Remove assistant');
      }
      // addNewMenuItem(
      //   openSearchByClients('Change client in session:', 'change-client-in-session'),
      //   'Change Client'
      // );
      addNewMenuItem(closeSession, 'Close session');
      addNewMenuItem(deleteSession, 'Delete session');
    }
    else if (isHistory) { // It is session from history
      const howMuchTimePassed = new Date() - convertDateFromUTC(new Date(props.dateClosed));
      const howMuchTimePassedInHours = howMuchTimePassed / 1000 / 60 / 60;

      const sessionEnded = !!props.dateClosed;

      const itsMySession = operatorsIds.indexOf(myId) !== -1;

      if (itsMySession && howMuchTimePassedInHours < 24 && sessionEnded) {
        addNewMenuItem(duplicateOrContinueSession(false), 'Continue');
      }
      sessionEnded && addNewMenuItem(duplicateOrContinueSession(true), 'Duplicate');

      sessionEnded && addNewMenuItem(
        openSearchByClients('Duplicate session with another client:', 'duplicate'),
        'Duplicate with'
      );
      addNewMenuItem(closeSession, 'Close session');
    }

    return menu;
  };

  const canIEditSession = isMine || isAssistance;
  const avatarSrc = getContactAvatar(contact);

  return (
    <div
      className={`session${props.isActive ? ' session--active' : ''} ${props.isUnreadMsg ? ' session--unread' : ''}`}
      onClick={onUpdateActiveSession}
    >
      <span
        className="sale-sidebar__tooltip"
        style={{
          padding: tooltipStatus ? '0px 2px' : '0'
        }}
      >
        {tooltipStatus}
      </span>

      <div className="session__avatar-wrap">
        <LazyLoadImage
          src={avatarSrc}
          alt="ava"
          className="session__avatar"
          data-avatar
        />
      </div>

      <div className={`session__info${isDefault ? ' session__info--fullwidth' : ''}`}>
        {isDefault
          ? 'Your Default Session'
          : (<>
            <span className='session__info-text'>{contact && contact.fn}</span>
            <span className='session__info-text'>{contact && contact.tels[0] && contact.tels[0].tel}</span>
          </>)
        }
      </div>

      <div className="session__time">
        {props.timer && !isDefault && !isHistory &&
          <CallTimer
            timer={props.timer}
          />
        }

        <span>
          {(isPartners || isHistory) &&
            <span>{props.operator && props.operator.username}</span>
          }

          {operatorsIds.includes(myId) && props.isSuccessfulSession && (
            <span>Me</span>
          )}
        </span>
      </div>

      {isDefault &&
        <div
          className='session__create-btn-wrap'
        >
          <button
            className='session__create-btn'
            onClick={() => setClientsMenuModal({
              show: true,
              text: 'Create new session based on default session',
              type: 'new-session-based-on-default'
            })}
          >
            <SvgIcon
              width='18px'
              height='18px'
              icon='puzzle'
              title="Create new session based on default session"
              fill='#0092f2'
            />
          </button>
        </div>
      }

      {(canIEditSession || isHistory) &&
        <div
          className='session__menu-btn-wrap'
          ref={menuBtnRef}
        >
          <Menu
            menuItems={renderMenu()}
            // onMenuToggle={props.onMenuToggle} 
            menuPosition='fixed-center'
            isRelativeToBody
          />
        </div>
      }

      <div ref={operatorsRef}>
        {canIEditSession && operatorsMenuModal.show &&
          <DropWrapper
            dropWrapperRef={operatorsRef}
            closeDropWrapper={closeSearchByOperators}
          >
            <SearchByOperators
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              classPrefix='session-operators'
              isSessionMode
              ignoreOperatorsIds={getIgnoreOperatorsIds(operatorsMenuModal.partner)}
              title={operatorsMenuModal.text}
              handleSubmit={operatorsMenuModal.transfer ? onTransferSession : onAddPartnerToSession}
              submitText={operatorsMenuModal.transfer ? 'TRANSFER' : 'ADD PARTNER'}
              isSearchByFreeOperators={!!operatorsMenuModal.transfer}
              closeHandle={closeSearchByOperators}
            />
          </DropWrapper>
        }
      </div>
      {/* FIXME: change SearchByClients to SearchContacts */}
      <div ref={clientsRef}>
        {clientsMenuModal.show &&
          <SearchByClients
            onClose={closeSearchByClients}
            wrapperRef={clientsRef}
            sessionId={id}
            title={clientsMenuModal.text}
            type={clientsMenuModal.type}
            onSubmit={closeSearchByClients}
          />
        }
      </div>

      {(isAssistanceRequest || isTransferRequest) &&
        <div
          className={classModifier('session__request', [
            isAssistanceRequest && 'assistance',
            isTransferRequest && 'transfer',
          ])}
          data-session-request
        >
          <div className="session__request-title" data-session-request>
            {isTransferRequest && 'Transfer'}
            {isAssistanceRequest && 'Assistance'}
          </div>

          <div className="session__request-actions" data-session-request>
            <button
              className="session__request-btn session__request-btn--cancel"
              data-session-request
              onClick={() => isAssistanceRequest
                ? replyToAssistanceRequest('ignore')
                : replyToTransferRequest('ignore')
              }
            >
              Reject
            </button>

            <button
              className="session__request-btn session__request-btn--accept"
              data-session-request
              onClick={() => isAssistanceRequest
                ? replyToAssistanceRequest('accept')
                : replyToTransferRequest('accept')
              }
            >
              Accept
            </button>
          </div>
        </div>
      }
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  dateCreated: state.sessions.entities[ownProps.id].dateCreated,
  dateClosed: state.sessions.entities[ownProps.id].dateClosed,
  contactId: state.sessions.entities[ownProps.id].callerId,
  operatorsIds: state.sessions.entities[ownProps.id].usersIds,
  reqForTransferId: state.sessions.entities[ownProps.id].reqForTransferId,
  reqForAssistanceIds: state.sessions.entities[ownProps.id].reqForAssistanceIds,
  myId: state.user.id,
  contact: selectContactBySessionId(state, ownProps.id),
  operator: selectOperatorBySessionId(state, ownProps.id),
  activeSessionIsPartners: selectActiveSessionIsPartners(state) && state.sessions.activeSession,
  activeSessionIdIfItPartners: selectActiveSessionIsPartners(state) && state.sessions.activeSession, // Id of active session If active session is partners session
  timer: state.timers[`session_${ownProps.id}`],
  // defaultSession: ownProps.isDefault
  //   ? state.sessions.entities[0]
  //   : null,
  isAmSessionOwner: selectIsAmSessionOwner(state, ownProps.id),
  isUnreadMsg: ownProps.isMine && selectIsUnreadMsgsInSession(state, ownProps.id),
  arrayGirlsIdsThatLinksIsSend: state.sessions.entities[state.sessions.activeSession]?.messageSendGirlsIds,
});

const mapDispatchToProps = {
  closeSession,
  deleteSession,
  updateActiveSession,
  timerStart,
  updateActiveContact,
  getContactsById,
  transferTheSession,
  addAssistantToSession,
  removeAssistantFromSession,
  removeVisitedSession,
  duplicateClosedSession,
  continueClosedSession,
  getSessionById,
  openModal,
  deleteGirlsIdsThatLinksIsSendInChat,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Session));
