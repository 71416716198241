import API from "../api/api";
import { PC_CONFIG } from "../config/webrtc-config";
import { closeModal, MODAL_TYPES, openModal } from "./activeWindows";
import { finishCall, toggleMuteActiveCall } from "./calls";
import { timerStart, timerStop } from "./timers";

export const UPDATE_LOCAL_STREAM = 'UPDATE_LOCAL_STREAM';
export const UPDATE_PEER_CONNECTION = 'UPDATE_PEER_CONNECTION';
export const ADD_REMOTE_STREAM = 'ADD_REMOTE_STREAM';

export const GET_WEBRTC_CONFERENCES = 'GET_WEBRTC_CONFERENCES';
export const UPDATE_WEBRTC_CONFERENCE = 'UPDATE_WEBRTC_CONFERENCE';
export const UPDATE_ACTIVE_WEBRTC_CONFERENCE = 'UPDATE_ACTIVE_WEBRTC_CONFERENCE';
export const UPDATE_WEBRTC_PARTICIPANT = 'UPDATE_WEBRTC_PARTICIPANT';
export const REMOVE_WEBRTC_PARTICIPANT = 'REMOVE_WEBRTC_PARTICIPANT';
export const WEBRTC_PARTICIPANT_LEFT = 'WEBRTC_PARTICIPANT_LEFT';
export const TOGGLE_WEBRTC_CONFERENCE_MUTE = 'TOGGLE_WEBRTC_CONFERENCE_MUTE';

export const CONFERENCE_TYPES = {
  general: 'general'
};

export const webrtcCallTo = (operator) => dispatch => {
  navigator.mediaDevices.getUserMedia({ audio: true, video: false })
    .then((stream) => {
      API.offerStartCall(operator.id)
        .then(() => {
          dispatch(openModal(MODAL_TYPES.outgoingCall, { contact: operator }));
          dispatch(updateLocalStream(stream));
        })
        .catch((err) => {
          console.log(err);
          stream && stream.getTracks().forEach((track) => track.stop());
        });
    })
    .catch(console.log);
}

export const webrtcAcceptIncomingCall = (callId, operatorId) => dispatch => {
  navigator.mediaDevices.getUserMedia({ audio: true, video: false })
    .then((stream) => {
      API.answerStartCall(callId)
        .then(() => {
          dispatch(updateLocalStream(stream));
          dispatch(createPeerConnection(operatorId, stream));
        })
        .catch((err) => {
          console.log(err);
          stream && stream.getTracks().forEach((track) => track.stop());
        });
    })
    .catch(console.log);
};


export const killWebrtcCall = (operatorId, currentConnection, localStream) => dispatch => {
  // hang up  
  if (currentConnection) {
    dispatch(deletePC(operatorId, currentConnection));
  }
  // dismiss the call
  else {
    dispatch(closeModal(MODAL_TYPES.outgoingCall));
  }

  dispatch(finishCall());
  dispatch(updateLocalStream(null, localStream));
}

export const updateLocalStream = (stream, localStream) => dispatch => {
  // stop and clear localStream if stream is undefined
  if (!stream && localStream) {
    localStream.getTracks().forEach((track) => track.stop());
  }

  dispatch({
    type: UPDATE_LOCAL_STREAM,
    payload: stream,
  });
}

export const deletePC = (operatorId, currentConnection) => dispatch => {
  if (currentConnection) {
    currentConnection.close();

    dispatch({
      type: UPDATE_PEER_CONNECTION,
      payload: {
        operatorId,
      }
    });
  }
}

export const createPeerConnection = (operatorId, stream, isMine, activeCallId, isConf) => (dispatch) => {
  const pc = new RTCPeerConnection(PC_CONFIG);

  pc.onicecandidate = (event) => {
    if (event.candidate || pc.remoteDescription && !pc.remoteDescription.type) {
      console.log('ICE candidate');
      API.sendIceCandidate(operatorId, event.candidate);
    }
  };

  pc.ontrack = (event) => {
    console.log('Add remote stream');

    dispatch(addRemoteStream(operatorId, event.streams[0]));
  };

  // status: connected / disconnected / connecting / failed / checking / closed
  pc.onconnectionstatechange = () => {
    console.log(`-- ${pc.connectionState} with ${operatorId} --`);

    if (['connected', 'failed'].includes(pc.connectionState)) {
      if (isMine && activeCallId) {
        API.sendConnectionStatus(activeCallId, pc.connectionState);
      }

      if (isConf) {
        API.changeParticipantStatus(operatorId, pc.connectionState);
      }
    }
  };

  stream && stream.getTracks().forEach(track => pc.addTrack(track, stream));

  dispatch({
    type: UPDATE_PEER_CONNECTION,
    payload: {
      operatorId,
      pc,
    }
  })

  return pc;
};

export const webrtcToggleMuteActiveCall = (isMute, localStream) => dispatch => {
  localStream && localStream.getTracks().forEach(track => {
    track.enabled = !isMute;
  });

  dispatch(toggleMuteActiveCall(isMute))
};

export const addRemoteStream = (operatorId, remoteStream) => ({
  type: ADD_REMOTE_STREAM,
  payload: {
    operatorId,
    remoteStream,
  }
});

export const getWebrtcConferences = () => (dispatch) => {
  API.getWebrtcConferences()
    .then(({ data }) => {
      dispatch({
        type: GET_WEBRTC_CONFERENCES,
        payload: data
      })
    });
};

export const updateWebrtcConference = (conference) => (dispatch) => {
  dispatch({
    type: UPDATE_WEBRTC_CONFERENCE,
    payload: conference
  })
};

export const updateActiveConference = (conferenceId) => (dispatch) => {
  dispatch({
    type: UPDATE_ACTIVE_WEBRTC_CONFERENCE,
    payload: conferenceId
  });
};

export const onHoldActiveConference = (conference, peerConnections, localStream) => (dispatch) => {
  conference.participantsIds.forEach(participantId => {
    dispatch(deletePC(participantId, peerConnections[participantId]));
  });

  dispatch(updateLocalStream(null, localStream));
  dispatch(closeModal(MODAL_TYPES.webrtcConferenceFullMode));
};

export const removeFromHoldActiveConference = (conference) => (dispatch, getState) => {
  navigator.mediaDevices.getUserMedia({ audio: true, video: false })
    .then((stream) => {
      const userId = getState().user.id;

      stream.getTracks().forEach(track =>
        track.enabled = !conference.participants[userId].is_muted && !conference.participants[userId].is_muted_conference
      );
      dispatch(updateLocalStream(stream));

      conference.participantsIds.forEach(participantId => {
        if (!['active', 'connecting'].includes(conference.participants[participantId].status)
          || participantId === userId
          || getState().webrtc.peerConnections[participantId]
        ) return;

        const localPC = dispatch(createPeerConnection(participantId, stream, null, null, true));

        sendOfferToOperator(participantId, localPC);
      });
    })
};

export const leaveFromActiveConference = (conference, peerConnections, localStream) => (dispatch) => {
  conference.participantsIds.forEach(participantId => {
    dispatch(deletePC(participantId, peerConnections[participantId]));
  });

  dispatch(updateLocalStream(null, localStream));
  dispatch(updateActiveConference());
  dispatch(toggleWebrtcConferenceMute(false));
  dispatch(timerStop('conf_' + conference.id));
};

export const requestLeaveFromActiveConference = (conference, peerConnections, localStream) => (dispatch) => {
  API.leaveWebrtcConference()
    .then(() => {
      dispatch(closeModal(MODAL_TYPES.webrtcConferenceFullMode));

      dispatch(leaveFromActiveConference(conference, peerConnections, localStream));
    });
};

export const joinToConference = (conference) => async (dispatch, getState) => {
  let stream;
  try {
    stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });

    if (conference.isInvited) {
      await API.acceptWebrtcConference(conference.id);
    }
    else {
      await API.joinToWebrtcConference(conference.id);
    }
    dispatch(updateLocalStream(stream));

    if (conference.participantsIds.length) {
      stream.getTracks().forEach(track => track.enabled = false);
      dispatch(toggleWebrtcConferenceMute(true));
    }

    dispatch(timerStart('conf_' + conference.id));

    dispatch(updateActiveConference(conference.id));
    dispatch(openModal(MODAL_TYPES.webrtcConferenceFullMode, { conferenceId: conference.id }));

    const userId = getState().user.id;

    conference.participantsIds.forEach(participantId => {
      if (!['active', 'connecting'].includes(conference.participants[participantId].status) || participantId === userId) return;

      const localPC = dispatch(createPeerConnection(participantId, stream, null, null, true));

      sendOfferToOperator(participantId, localPC);
    });

  } catch (error) {
    stream && stream.getTracks().forEach((track) => track.stop());
    console.log(error);
  }
};

export const updateWebrtcConferenceParticipant = (participant) => ({
  type: UPDATE_WEBRTC_PARTICIPANT,
  payload: participant,
});

export const webrtcParticipantLeft = (participant) => ({
  type: WEBRTC_PARTICIPANT_LEFT,
  payload: participant,
});

export const removeWebrtcParticipant = (participant) => ({
  type: REMOVE_WEBRTC_PARTICIPANT,
  payload: participant,
});

export const toggleWebrtcConferenceMute = (isMute) => ({
  type: TOGGLE_WEBRTC_CONFERENCE_MUTE,
  payload: isMute,
});

const initialState = {
  activeConferenceId: null,
  conferences: {},
  isConferenceMute: false,
  localStream: null,
  peerConnections: {},
  remoteStreams: {}
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_LOCAL_STREAM: {
      return {
        ...state,
        localStream: payload,
      }
    }

    case UPDATE_PEER_CONNECTION: {
      if (payload.pc) {
        return {
          ...state,
          peerConnections: {
            ...state.peerConnections,
            [payload.operatorId]: payload.pc,
          },
        }
      }
      else {
        const updatePeerConnections = { ...state.peerConnections };
        const updateRemoteStreams = { ...state.remoteStreams };

        delete updatePeerConnections[payload.operatorId];
        delete updateRemoteStreams[payload.operatorId];

        return {
          ...state,
          peerConnections: {
            ...updatePeerConnections,
          },
          remoteStreams: {
            ...updateRemoteStreams,
          },
        }
      }
    }

    case ADD_REMOTE_STREAM: {
      return {
        ...state,
        remoteStreams: {
          ...state.remoteStreams,
          [payload.operatorId]: payload.remoteStream,
        },
      }
    }

    case GET_WEBRTC_CONFERENCES: {
      const conferences = {};

      payload.forEach(conference => {
        conferences[conference.id] = normalizeParticipants(conference);
      });

      return {
        ...state,
        conferences: conferences
      }
    }

    case UPDATE_WEBRTC_CONFERENCE: {
      return {
        ...state,
        conferences: {
          ...state.conferences,
          [payload.id]: {
            ...state.conferences[payload.id],
            ...payload,
          }
        }
      }
    }

    case TOGGLE_WEBRTC_CONFERENCE_MUTE: {
      return {
        ...state,
        isConferenceMute: payload,
      }
    }

    case UPDATE_ACTIVE_WEBRTC_CONFERENCE: {
      return {
        ...state,
        activeConferenceId: payload,
      }
    }

    case UPDATE_WEBRTC_PARTICIPANT: {
      //join if false, update if true
      const isExist = state.conferences[payload.conference_id].participants[payload.user_id];

      return {
        ...state,
        conferences: {
          ...state.conferences,
          [payload.conference_id]: {
            ...state.conferences[payload.conference_id],
            participants: {
              ...state.conferences[payload.conference_id].participants,
              [payload.user_id]: payload
            },
            participantsIds: isExist
              ? state.conferences[payload.conference_id].participantsIds
              : [...state.conferences[payload.conference_id].participantsIds, payload.user_id]
          }
        },
      }
    }

    case WEBRTC_PARTICIPANT_LEFT: {
      const updatedParticipants = { ...state.conferences[payload.conference_id].participants };
      let updatedParticipantsIds = [...state.conferences[payload.conference_id].participantsIds];

      delete updatedParticipants[payload.user_id];
      updatedParticipantsIds = updatedParticipantsIds.filter(id => id !== payload.user_id);

      return {
        ...state,
        conferences: {
          ...state.conferences,
          [payload.conference_id]: {
            ...state.conferences[payload.conference_id],
            participants: updatedParticipants,
            participantsIds: updatedParticipantsIds,
          }
        },
      }
    }

    case REMOVE_WEBRTC_PARTICIPANT: {
      const updatedParticipants = { ...state.conferences[payload.conference_id].participants };
      let updatedParticipantsIds = [...state.conferences[payload.conference_id].participantsIds];

      if (updatedParticipants[payload.user_id] && ['rejected', 'failed'].includes(updatedParticipants[payload.user_id].status)) {
        delete updatedParticipants[payload.user_id];
        updatedParticipantsIds = updatedParticipantsIds.filter(id => id !== payload.user_id);

        return {
          ...state,
          conferences: {
            ...state.conferences,
            [payload.conference_id]: {
              ...state.conferences[payload.conference_id],
              participants: updatedParticipants,
              participantsIds: updatedParticipantsIds,
            }
          },
        }
      }

      return state;
    }

    default:
      return state;
  }
};

// Helpers

export const sendOfferToOperator = (operatorId, localPC) => {
  localPC.createOffer()
    .then((sessionDescription) => {
      localPC.setLocalDescription(sessionDescription);

      API.sendLocalDescription(operatorId, sessionDescription, 'offer');
    });
};

export const normalizeParticipants = (conference) => {
  const participants = {},
    participantsIds = [];

  conference.participants.forEach(participant => {
    participants[participant.user_id] = participant;
    participantsIds.push(participant.user_id);
  });

  return {
    ...conference,
    participants,
    participantsIds,
  }
}