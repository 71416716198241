import React from 'react';
import { connect } from 'react-redux';

import { selectContactById } from '../../../../selectors/selectors';
import { updateActiveContact } from '../../../../ducks/clientChats';
import SvgIcon from '../../../../components/SvgIcon/SvgIcon';


const ContactAgent = (props) => {
  return (
    <button
      onClick={() => props.updateActiveContact(props.agent)}
      className="chat-header__btn--agent">

      <SvgIcon width={16} height={16} icon="operator" fill="#0092F2" />

      AGENT {props.agent.fn}
    </button>
  );
}

const mapStateToProps = (state, ownProps) => ({
  agent: selectContactById(state, ownProps.agentId) || {}
});

const mapDispatchToProps = {
  updateActiveContact
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactAgent);