import API from '../api/api';

import {
  onUpdateTimeline,
  onInteraction,
  onStatusChange,
  NEW_MESSAGE_ATTACHMENTS,
  onAttachmentsAdding,
  onRemoveTab,
  onRemoveTabUpdateActiveContact,
  NEW_INCOMING_MESSAGE,
  getContactMessageContext,
  groupInteractionsByTimezone
} from './clientChats';
import { NEW_INCOMING_OPERATOR_CALL, NEW_OUTGOING_OPERATOR_CALL } from './calls';
import { GET_ROOMS_FROM_TABS, REMOVE_ROOM, UPDATE_ROOM } from './rooms';
import { convertDateFromUTC, getDateByTimezoneOffset } from '../utils';
import { debouncedChangeTypingStatus } from './typingOperators';
import { CHAT_TYPES, INTERACTION_TYPES } from '../config/constants';

export const SET_CHAT_UPDATE_PENDING = 'SET_CHAT_UPDATE_PENDING';

export const GET_SEARCHED_CHAT_MESSAGES = 'GET_SEARCHED_CHAT_MESSAGES';
export const STOP_SEARCH_CHAT_MESSAGES = 'STOP_SEARCH_CHAT_MESSAGES';
export const UPDATE_SEARCHED_CHAT_MESSAGES = 'UPDATE_SEARCHED_CHAT_MESSAGES';

export const UPDATE_ACTIVE_CHAT = 'UPDATE_ACTIVE_CHAT';

export const REMOVE_CHAT_TAB = 'REMOVE_CHAT_TAB';
export const SET_CHAT_TABS = 'SET_CHAT_TABS';

export const GET_CHAT_TIMELINE_PENDING = 'GET_CHAT_TIMELINE_PENDING';
export const GET_CHAT_TIMELINE = 'GET_CHAT_TIMELINE';
export const UPDATE_CHAT_TIMELINE = 'UPDATE_CHAT_TIMELINE';

export const OPERATORS_CHAT_TYPING = 'OPERATORS_CHAT_TYPING';

export const NEW_OUTGOING_CHAT_MESSAGE = 'NEW_OUTGOING_CHAT_MESSAGE';
export const NEW_INCOMING_CHAT_MESSAGE = 'NEW_INCOMING_CHAT_MESSAGE';

export const SELECT_ACTIVE_OPERATOR_TIMELINE = 'SELECT_ACTIVE_OPERATOR_TIMELINE';
export const UPDATE_ACTIVE_OPERATOR_TIMELINE = 'UPDATE_ACTIVE_OPERATOR_TIMELINE';

export const CHANGE_CHAT_CALL_STATUS = 'CHANGE_CHAT_CALL_STATUS';
export const CHANGE_CHAT_MSG_STATUS = 'CHANGE_CHAT_MSG_STATUS';
export const UPDATE_CHAT_MSG = 'UPDATE_CHAT_MSG';
export const REMOVE_CHAT_MSG = 'REMOVE_CHAT_MSG';
export const REMOVE_GIRLS_CHAT_MSG = 'REMOVE_GIRLS_CHAT_MSG';

export const PIN_CHAT_MSG = 'PIN_CHAT_MSG';

export const UPDATE_SHARED_CONTACT = 'UPDATE_SHARED_CONTACT';

export const GET_CHAT_MSG_CONTEXT = 'GET_CHAT_MSG_CONTEXT';
export const UPDATE_CHAT_MSG_CONTEXT = 'UPDATE_CHAT_MSG_CONTEXT';
export const CLEAN_CHAT_MSG_CONTEXT = 'CLEAN_CHAT_MSG_CONTEXT';

export const GET_CHAT_CONVERSATION_MEDIA = 'GET_CHAT_CONVERSATION_MEDIA';
export const UPDATE_CHAT_CONVERSATION_MEDIA = 'UPDATE_CHAT_CONVERSATION_MEDIA';
export const CLEAN_CHAT_CONVERSATION_MEDIA = 'CLEAN_CHAT_CONVERSATION_MEDIA';
export const FIX_ROOM_TAB = 'FIX_ROOM_TAB';
export const INIT_UNFIXED_ROOM_TAB = 'INIT_UNFIXED_ROOM_TAB';

export const ADD_WEBMASTER_TASK = 'ADD_WEBMASTER_TASK';
export const REMOVE_WEBMASTER_TASK = 'REMOVE_WEBMASTER_TASK';

export const CREATE_NEW_ROOM = 'CREATE_NEW_ROOM';
export const ADD_NEW_CHAT_ROOM = 'ADD_NEW_CHAT_ROOM';

export const SET_GIRLS_TODAY_MSG_COUNT = 'SET_GIRLS_TODAY_MSG_COUNT';

export const SET_ROOMS_RECENT_TABS = 'SET_ROOMS_RECENT_TABS';

export const LIMIT = 15;

export const pinMsg = (msg, chat_id) => dispatch => {
  // dispatch({
  //   type: PIN_CHAT_MSG,
  //   payload: null
  // });

  const msgId = msg ? msg.id : null;

  const chatId = msg
    ? msg.chatId || msg.caller_id
    : chat_id;

  return API.pinMsg(msgId, chatId)
    .then(res => {
      dispatch({
        type: PIN_CHAT_MSG,
        payload: msg
      });
    })
    .catch(err => console.log);
};

export const unpinMsg = (chatId) => dispatch => {
  return API.unpinMsg(chatId)
    .then(res => {
      dispatch({
        type: PIN_CHAT_MSG,
        payload: null,
      });
    })
    .catch(err => console.log);
};

export const getChatTimeline = (chatId, userId, userTimezone) => dispatch => {
  dispatch({ type: GET_CHAT_TIMELINE_PENDING });

  if (chatId === 'girls') {
    return API.getGirlsChatInteraction()
      .then(res => {
        dispatch({
          type: GET_CHAT_TIMELINE,
          payload: {
            ...res.data,
            messages: groupInteractionsByTimezone(res.data.messages, userTimezone),
            pinnedMessage: null,
            chatId,
          }
        });
      });
  }
  else if (chatId === 'webmaster') {
    return API.getAllWebmasterTasks()
      .then(res => {
        dispatch({
          type: GET_CHAT_TIMELINE,
          payload: {
            ...res.data,
            messages: groupInteractionsByTimezone(res.data.messages, userTimezone),
            pinnedMessage: null,
            chatId
          }
        })
      })
  }
  else if (chatId === 'new_chat' || chatId === 'bookings' || chatId === 'off_today' || chatId === 'available') {
    return dispatch({
      type: GET_CHAT_TIMELINE,
      payload: {
        chatId: chatId
      }
    })
  }
  else {
    API.getChatInteractions(chatId)
      .then(res => {
        dispatch({
          type: GET_CHAT_TIMELINE,
          payload: {
            ...res.data,
            messages: groupInteractionsByTimezone(res.data.messages, userTimezone, userId),
            chatId,
            userId
          },
        });
      })
      .catch(err => console.log(err));
  }
};

export const addNewChatRoom = id => dispatch => dispatch({ type: ADD_NEW_CHAT_ROOM, payload: id });

export const updateChatTimeline = (chat, page, loadDirection, userId, userTimezone) => dispatch => {
  if (chat.id === 'girls') {
    return API.getGirlsChatInteraction(page)
      .then(res => {
        dispatch({
          type: UPDATE_CHAT_TIMELINE,
          payload: {
            ...res.data,
            messages: groupInteractionsByTimezone(res.data.messages, userTimezone),
            loadDirection,
            userTimezone,
            chatId: "girls"
          },
        });
      });
  } else {
    API.getChatInteractions(chat.id, page)
      .then(res => {
        // if we have 10 messages only and offset = 10 we will get []
        if (!res.data.messages.length) {
          return;
        }

        dispatch({
          type: UPDATE_CHAT_TIMELINE,
          payload: {
            ...res.data,
            messages: groupInteractionsByTimezone(res.data.messages, userTimezone, userId),
            loadDirection,
            userTimezone
          }
        });
      })
      .catch(err => console.log(err));
  }
};

export const updateActiveChat = (chatId, fromMoreTabs = false) => dispatch => {
  dispatch({
    type: UPDATE_ACTIVE_CHAT,
    payload: {
      chatId,
      fromMoreTabs
    }
  });
};

export const sendMessageToChat = (message, chat) => dispatch => {
  return API.sendMessageToChat(message, chat.id)
    .catch(err => console.log(err));
};

export const newOutgoingChatMessage = (message, userTimezone) => dispatch => {
  dispatch({
    type: NEW_OUTGOING_CHAT_MESSAGE,
    payload: {
      interaction: message,
      userTimezone
    }
  });
};

export const newIncomingChatMessage = (message, userTimezone) => dispatch => {
  debouncedChangeTypingStatus(message);

  dispatch({
    type: NEW_INCOMING_CHAT_MESSAGE,
    payload: {
      interaction: message,
      userTimezone
    }
  });
};

export const changeChatMsgStatus = (chatId) => dispatch => {
  dispatch({
    type: CHANGE_CHAT_MSG_STATUS,
    payload: chatId
  });
};

export const updateChatMsg = (msg) => dispatch => {
  dispatch({
    type: UPDATE_CHAT_MSG,
    payload: msg
  });
};

export const removeChatMsg = (msg) => dispatch => {
  dispatch({
    type: REMOVE_CHAT_MSG,
    payload: msg
  });
};

export const markChatAsRead = (chatId) => dispatch => {
  API.markChatAsRead(chatId)
    .then(res => {
      dispatch(updateActiveChat(chatId));
    })
    .catch(err => console.log(err));
};

export const sendDeliveredStatus = (msgId) => {
  return API.sendDeliveredStatus(msgId)
    .catch(err => console.log(err));
};

export const operatorsChatTyping = (operator, isTyping) => dispatch => {
  dispatch({
    type: OPERATORS_CHAT_TYPING,
    payload: { operator, isTyping }
  });
};

export const onRemoveChatTab = (removedTab) => dispatch => {
  dispatch({
    type: REMOVE_CHAT_TAB,
    payload: removedTab
  });
};

export const setChatTabs = (newTabs) => dispatch => {
  dispatch({
    type: SET_CHAT_TABS,
    payload: newTabs,
  });
}

export const changeChatCallStatus = (call) => dispatch => {
  dispatch({
    type: CHANGE_CHAT_CALL_STATUS,
    payload: call
  });
};

export const updateSharedContact = (id = null) => dispatch => {
  dispatch({
    type: UPDATE_SHARED_CONTACT,
    payload: id
  });
};

export const debouncedOperatorChatTypingStop = (() => {
  let timerList = {};

  return (operator) => dispatch => {
    if (timerList[operator]) {
      clearTimeout(timerList[operator]);
    }

    const functionCall = (operator => () =>
      dispatch(operatorsChatTyping(operator, false))
    )(operator);

    timerList[operator] = setTimeout(functionCall, 5000);
  };
})();

export const getChatMessageContext = (contextMsgId, chatId, contact, userId, query = '', userTimezone) => dispatch => {
  // cleanChatMsgContext
  if (!contextMsgId) {
    if (query) {
      return dispatch(searchMessageInChat(query, chatId, userId));
    }
    return dispatch({
      type: CLEAN_CHAT_MSG_CONTEXT
    });
  }
  //TODO: вынести на уровень PublicChat.js
  if (chatId === 'webmaster') {
    return dispatch(getContactMessageContext(contextMsgId, CHAT_TYPES.GIRL, contact, null, userTimezone))
  }


  dispatch({
    type: GET_CHAT_TIMELINE_PENDING
  });

  API.getChatMessageContext(contextMsgId, null, chatId)
    .then(res => {
      dispatch({
        type: GET_CHAT_MSG_CONTEXT,
        payload: {
          ...res.data,
          messages: groupInteractionsByTimezone(res.data.messages, userTimezone, userId),
          contextMsgId,
        }
      });
    })
    .catch(console.error);
};

export const updateChatMessageContext = (contextMsgId, page, loadDirection, userId, chatId, userTimezone) => dispatch => {
  API.getChatMessageContext(contextMsgId, page, chatId)
    .then(res => {
      dispatch({
        type: UPDATE_CHAT_MSG_CONTEXT,
        payload: {
          ...res.data,
          messages: groupInteractionsByTimezone(res.data.messages, userTimezone, userId),
          loadDirection,
          userTimezone
        }
      });
    })
    .catch(console.error);
}

export const getChatTimelineMedia = (chat, userId, userTimezone) => dispatch => {
  dispatch({
    type: GET_CHAT_TIMELINE_PENDING
  });

  return API.getChatConversationMedia(chat.id)
    .then(res => {

      dispatch({
        type: GET_CHAT_CONVERSATION_MEDIA,
        payload: {
          ...res.data,
          messages: groupInteractionsByTimezone(res.data.messages, userTimezone, userId),
        }
      });
    })
    .catch(console.error);
};

export const updateChatTimelineMedia = (chat, page, loadDirection, userId, userTimezone) => dispatch => {
  return API.getChatConversationMedia(chat.id, page)
    .then(res => {
      dispatch({
        type: UPDATE_CHAT_CONVERSATION_MEDIA,
        payload: {
          ...res.data,
          messages: groupInteractionsByTimezone(res.data.messages, userTimezone, userId),
          loadDirection,
          userTimezone
        }
      })
    })
    .catch(console.error);
};

export const cleanChatTimelineMedia = () => dispatch => {
  dispatch({
    type: CLEAN_CHAT_CONVERSATION_MEDIA
  });
};

export const fixRoomTab = (tabId) => dispatch => dispatch({ type: FIX_ROOM_TAB, payload: tabId });

export const initUnfixedRoomTab = (tabId) => dispatch => dispatch({ type: INIT_UNFIXED_ROOM_TAB, payload: tabId });

export const removeGirlsChatMsg = (msgId) => dispatch => {
  dispatch({
    type: REMOVE_GIRLS_CHAT_MSG,
    payload: msgId
  })
}

export const searchMessageInChat = (query, activeChatId, userId, userTimezone) => dispatch => {
  dispatch({
    type: GET_CHAT_TIMELINE_PENDING
  })

  API.searchMessageInChat(activeChatId, query)
    .then(res => {
      dispatch({
        type: GET_SEARCHED_CHAT_MESSAGES,
        payload: {
          ...res.data,
          messages: groupInteractionsByTimezone(res.data.messages, userTimezone, userId),
          query,
          userId
        }
      });
    })
    .catch(err => console.log(err));
};

export const updateChatMessageSearch = (activeChat, page, loadDirection, query, userId, userTimezone) => dispatch => {
  dispatch({
    type: SET_CHAT_UPDATE_PENDING,
    payload: true,
  });

  API.searchMessageInChat(activeChat.id, query, page)
    .then(res => {
      if (!res.data.messages.length) return;

      dispatch({
        type: UPDATE_SEARCHED_CHAT_MESSAGES,
        payload: {
          ...res.data,
          messages: groupInteractionsByTimezone(res.data.messages, userTimezone, userId),
          loadDirection,
          userTimezone
        }
      });
    })
    .catch(err => console.log(err));
};

export const stopChatMessageSearch = () => dispatch => {
  dispatch({
    type: STOP_SEARCH_CHAT_MESSAGES
  });
};

export const addWebmasterTask = (msg) => dispatch => {
  dispatch({
    type: ADD_WEBMASTER_TASK,
    payload: msg
  })
};

export const removeWebmasterTask = (msg) => dispatch => {
  dispatch({
    type: REMOVE_WEBMASTER_TASK,
    payload: msg
  })
};

export const createNewRoom = () => dispatch => dispatch({ type: CREATE_NEW_ROOM });

export const setGirlsTodayMsgCount = (userTimezone = null) => dispatch => {
  API.getGirlsMsgCountToday(userTimezone)
    .then(({ data }) => {
      dispatch({
        type: SET_GIRLS_TODAY_MSG_COUNT,
        payload: data
      });
    })
    .catch(err => console.log(err))
}

const initialState = {
  active: null,

  tabs: [],
  unfixedTab: null,
  recentTabs: {
    all: [], // all recent tabs we worked with
    visible: [], // tabs that can be returned by undo button.
  },

  pinnedMsg: null,

  timelinePending: false,
  updatePending: false,
  timeline: [],
  timelinePageCount: null,
  timelineLowerLoadedPage: null,
  timelineCurrentPage: null,
  newInteractionType: null,

  search: '',

  sharedContactId: null,

  contextMsgId: null,

  auxiliaryTimeline: [],
  auxiliaryPageCount: null,
  auxiliaryLowerLoadedPage: null,
  auxiliaryHigherLoadedPage: null,
  auxiliaryCurrentPage: null,

  girlsTodayMsgCount: null,
};


export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CHAT_UPDATE_PENDING:
      return {
        ...state,
        updatePending: action.payload,
      };

    case GET_ROOMS_FROM_TABS: {
      const [operatorsTab, ...restTabs] = action.payload.ids;

      const getUpdatedActive = () => {
        // if we already have our active contact
        if (action.payload.ids.includes(action.payload.active)) {
          return action.payload.active;
        }
        // if our active has been changed => make girls chat active by default
        else if (
          action.payload.ids.length &&
          !action.payload.ids.includes(action.payload.active)
        ) {
          return 'bookings';
        } else {
          return null;
        }
      };

      const getUpdatedUnfixedTab = () => {
        if (state.unfixedTab && action.payload.ids.includes(state.unfixedTab)) {
          return state.unfixedTab
        }
        // if we have unfixedTab that was removed from server
        else {
          return null
        }
      }

      return {
        ...state,
        tabs: [operatorsTab, 'girls', 'webmaster', 'bookings', 'off_today', 'available', ...restTabs],
        active: getUpdatedActive(),
        unfixedTab: getUpdatedUnfixedTab()
      };
    }
    case INIT_UNFIXED_ROOM_TAB: {
      return {
        ...state,
        unfixedTab: action.payload
      }
    }
    case FIX_ROOM_TAB: {
      if (action.payload !== state.unfixedTab) return state;

      return {
        ...state,
        unfixedTab: null,
      }
    }
    case UPDATE_ACTIVE_CHAT: {
      // if we mark msgs as read
      if (action.payload.chatId === state.active && !action.payload.fromMoreTabs) {
        return state;
      }

      const isInTabs = state.tabs.indexOf(action.payload.chatId) !== -1;

      let updatedTabs = [...state.tabs];

      let updatedUnfixedTab = state.unfixedTab;

      if (action.payload.fromMoreTabs) {
        const [operators, girls, webmaster, bookings, off_today, available, ...rest] = state.tabs.filter(id => id !== action.payload.chatId);

        updatedTabs = [operators, girls, webmaster, bookings, off_today, available, action.payload.chatId, ...rest];

        if (action.payload.chatId === state.active) {
          return {
            ...state,
            tabs: updatedTabs,
          }
        }
      }
      else if (!isInTabs) {
        //if tab not fixed replace her by action.payload.chatId
        if (state.unfixedTab) {
          if (updatedTabs.length === 20) { // limit - 20 msgs;
            updatedTabs[19] = action.payload.chatId;
          }
          else {
            updatedTabs = updatedTabs.map(id => {
              if (id === state.unfixedTab) {
                return action.payload.chatId;
              }
              return id;
            });
          }
        }
        else {
          if (updatedTabs.length === 20) { // limit - 20 msgs;
            updatedTabs[19] = action.payload.chatId;
          }
          else {
            updatedTabs.push(action.payload.chatId);
          }
        }

        updatedUnfixedTab = action.payload.chatId;
      }


      return {
        ...state,
        tabs: updatedTabs,
        unfixedTab: updatedUnfixedTab,
        active: action.payload.chatId,
        timeline: [],
        timelinePageCount: null,
        timelineLowerLoadedPage: null,
        timelineCurrentPage: null,

        auxiliaryTimeline: state.auxiliaryTimeline.length ? [] : state.auxiliaryTimeline,
        auxiliaryCurrentPage: null,
        auxiliaryLowerLoadedPage: null,
        auxiliaryHigherLoadedPage: null,
        auxiliaryPageCount: null,

        contextMsgId: null,

        search: ''
      };
    }

    case GET_CHAT_TIMELINE_PENDING: {
      return {
        ...state,
        timelinePending: true
      };
    }

    case GET_CHAT_TIMELINE: {
      if (action.payload.chatId === 'new_chat' ||
        action.payload.chatId === 'bookings' ||
        action.payload.chatId === 'off_today' ||
        action.payload.chatId === 'available'
      ) {
        return {
          ...state,
          timeline: [],
          timelinePending: false,

          timelinePageCount: 1,
          timelineLowerLoadedPage: 1,
          timelineCurrentPage: 1,
        };
      }
      if (!action.payload.messages.length) {
        return {
          ...state,
          timelinePending: false
        };
      }

      if (action.payload.chatId !== state.active) {
        return state;
      }

      return {
        ...state,
        timelinePending: false,
        timeline: action.payload.messages,
        pinnedMsg: action.payload.pinnedMessage,
        timelinePageCount: action.payload.pageCount,
        timelineLowerLoadedPage: action.payload.currentPage,
        timelineCurrentPage: action.payload.currentPage,
        newInteractionType: null,

        search: '',

        // contextMsgId: null,
        // contextDate: null,
      };
    }

    case UPDATE_CHAT_TIMELINE: {
      return {
        ...state,
        timeline: onUpdateTimeline(state.timeline, action.payload.messages, action.payload.loadDirection, action.payload.userTimezone),
        // pinnedMsg: action.payload.pinnedMsg,
        timelineCurrentPage: action.payload.currentPage,
        // do not update page count if we scroll to top because new msgs can update it dynamically
        timelinePageCount: action.payload.loadDirection === 'up'
          ? state.timelinePageCount
          : action.payload.pageCount,
        newInteractionType: null
      };
    }

    case PIN_CHAT_MSG: {
      return {
        ...state,
        pinnedMsg: action.payload
      };
    }

    case ADD_NEW_CHAT_ROOM: {
      const newTab =
        action.payload || state.tabs.filter(tab => tab !== "new_chat")[0];

      const updatedTabs = state.tabs.filter(
        tab => tab !== "new_chat" && tab !== action.payload
      )

      updatedTabs.splice(3, 0, action.payload);

      return {
        ...state,
        active: newTab,
        tabs: updatedTabs
      };
    }

    case REMOVE_ROOM:
    case REMOVE_CHAT_TAB: {
      const isInTabs = state.tabs.includes(action.payload);

      if (!isInTabs) {
        return state;
      }
      // TODO: refactor this
      return {
        ...state,
        timeline: action.payload === state.active ? [] : state.timeline,
        tabs: onRemoveTab(state.tabs, action.payload),
        unfixedTab: action.payload === state.unfixedTab ? null : state.unfixedTab,
        active: onRemoveTabUpdateActiveContact(state.tabs, state.active, action.payload),

        contextMsgId: action.payload === state.active ? null : state.contextMsgId,
        contextDate: action.payload === state.active ? null : state.contextDate,
        search: action.payload === state.active ? null : state.search,
        auxiliaryTimeline: action.payload === state.active ? [] : state.auxiliaryTimeline,
        auxiliaryPageCount: action.payload === state.active ? null : state.auxiliaryPageCount,
        auxiliaryLowerLoadedPage: action.payload === state.active ? null : state.auxiliaryLowerLoadedPage,
        auxiliaryHigherLoadedPage: action.payload === state.active ? null : state.auxiliaryHigherLoadedPage,
        auxiliaryCurrentPage: action.payload === state.active ? null : state.auxiliaryCurrentPage,

      };
    }
    case SET_CHAT_TABS: {
      if (!state.unfixedTab) {
        return {
          ...state,
          tabs: action.payload,
        }
      }

      const prevIndex = state.tabs.indexOf(state.unfixedTab);
      const newIndex = action.payload.indexOf(state.unfixedTab);

      if (newIndex === -1 || newIndex !== prevIndex) {
        return {
          ...state,
          tabs: action.payload,
          unfixedTab: null,
        }
      }
    }

    case NEW_INCOMING_OPERATOR_CALL:
    case NEW_OUTGOING_OPERATOR_CALL:
    case NEW_INCOMING_CHAT_MESSAGE:
    case NEW_OUTGOING_CHAT_MESSAGE: {
      const newInteraction = { ...action.payload.interaction, isNew: true };
      const chatId = action.payload.interaction.chatId;

      if (state.active === chatId) {
        return {
          ...state,
          timeline: onInteraction(state.timeline, newInteraction, action.payload.userTimezone),
          newInteractionType: newInteraction.type
        };
      }
      if (action.type === NEW_INCOMING_CHAT_MESSAGE && state.tabs.includes(chatId)) {
        const notUpdatableTabs = [1, 'girls', 'webmaster', 'bookings', 'off_today', 'available'];
        const isUpdatableTabs = !notUpdatableTabs.includes(chatId);

        const updatedTabs = isUpdatableTabs
          ? state.tabs.filter(id => id !== chatId)
          : state.tabs;

        if (isUpdatableTabs) {
          updatedTabs.splice(notUpdatableTabs.length, 0, chatId); // paste chatId after notUpdatableTabs
        }

        return {
          ...state,
          tabs: updatedTabs,
        }
      }
      return state;
    }

    case NEW_MESSAGE_ATTACHMENTS: {
      if (state.active === action.payload.chatId) {
        return {
          ...state,
          timeline: onAttachmentsAdding(state.timeline, action.payload),
          newInteractionType: INTERACTION_TYPES.MSG_ATTACHMENT
        };
      }
      return state;
    }

    case NEW_INCOMING_MESSAGE: {
      if (state.active === 'girls' &&
        action.payload.interaction.caller.type === 2 &&
        !action.payload.interaction.is_hidden &&
        (action.payload.interaction.type === 4 || action.payload.interaction.type === 9)
      ) {
        return {
          ...state,
          timeline: onInteraction(state.timeline, action.payload.interaction, action.payload.userTimezone),
          newInteractionType: action.payload.interaction.type,
          girlsTodayMsgCount: state.girlsTodayMsgCount + 1,
        };
      }
      else if (action.payload.interaction.caller.type === 2 &&
        (action.payload.interaction.type === 4 || action.payload.interaction.type === 9)
      ) {
        return {
          ...state,
          girlsTodayMsgCount: state.girlsTodayMsgCount + 1,
        }
      }

      return state;
    }

    case CHANGE_CHAT_CALL_STATUS: {
      if (state.active === action.payload.chatId) {
        return {
          ...state,
          timeline: onStatusChange(state.timeline, action.payload)
        };
      }
      return state;
    }

    case CHANGE_CHAT_MSG_STATUS: {
      if (state.active === action.payload) {
        return {
          ...state,
          timeline: onChatStatusChange(state.timeline)
        };
      }
      return state;
    }

    case UPDATE_CHAT_MSG: {
      if (state.active === action.payload.chatId) {
        return {
          ...state,
          timeline: onMessageUpdate(state.timeline, action.payload.message)
        };
      }
      return state;
    }

    case REMOVE_CHAT_MSG: {
      if (state.active === action.payload.chatId) {
        return {
          ...state,
          timeline: onMessageUpdate(state.timeline, action.payload.message)
          // timeline: onMessageRemove(state.timeline, action.payload.msgId)
        };
      }
      return state;
    }

    case REMOVE_GIRLS_CHAT_MSG: {
      if (state.active !== 'girls') return;

      return {
        ...state,
        timeline: onMessageRemove(state.timeline, action.payload)
      };
    }

    case UPDATE_SHARED_CONTACT: {
      if (action.payload === state.sharedContactId) {
        return state;
      }

      return {
        ...state,
        sharedContactId: action.payload
      };
    }

    case GET_CHAT_CONVERSATION_MEDIA: {
      return {
        ...state,
        timelinePending: false,
        auxiliaryTimeline: action.payload.messages,
        auxiliaryPageCount: action.payload.pageCount,
        auxiliaryLowerLoadedPage: action.payload.currentPage,
        auxiliaryHigherLoadedPage: action.payload.currentPage,
        auxiliaryCurrentPage: action.payload.currentPage,
      };
    }

    case UPDATE_CHAT_CONVERSATION_MEDIA: {
      return {
        ...state,

        auxiliaryTimeline: onUpdateTimeline(state.auxiliaryTimeline, action.payload.messages, action.payload.loadDirection, action.payload.userTimezone),
        auxiliaryPageCount: action.payload.loadDirection === 'up'
          ? state.auxiliaryPageCount
          : action.payload.pageCount,
        auxiliaryHigherLoadedPage: state.auxiliaryHigherLoadedPage > action.payload.currentPage
          ? action.payload.currentPage
          : state.auxiliaryHigherLoadedPage,
        auxiliaryLowerLoadedPage: state.auxiliaryLowerLoadedPage > action.payload.currentPage
          ? state.auxiliaryLowerLoadedPage
          : action.payload.currentPage,
        auxiliaryCurrentPage: action.payload.currentPage,
      }
    }

    case CLEAN_CHAT_CONVERSATION_MEDIA: {
      if (state.search) {
        return state;
      }

      return {
        ...state,
        timelinePending: false,

        auxiliaryTimeline: state.auxiliaryTimeline.length ? [] : state.auxiliaryTimeline
      };
    }

    case GET_CHAT_MSG_CONTEXT: {
      return {
        ...state,
        timelinePending: false,

        contextMsgId: action.payload.contextMsgId,
        auxiliaryCurrentPage: action.payload.currentPage,
        auxiliaryLowerLoadedPage: action.payload.currentPage,
        auxiliaryHigherLoadedPage: action.payload.currentPage,
        auxiliaryPageCount: action.payload.pageCount,
        auxiliaryTimeline: action.payload.messages
      };
    }
    case UPDATE_CHAT_MSG_CONTEXT: {
      return {
        ...state,
        auxiliaryTimeline: onUpdateTimeline(state.auxiliaryTimeline, action.payload.messages, action.payload.loadDirection, action.payload.userTimezone),
        auxiliaryCurrentPage: action.payload.currentPage,

        auxiliaryHigherLoadedPage: state.auxiliaryHigherLoadedPage > action.payload.currentPage
          ? action.payload.currentPage
          : state.auxiliaryHigherLoadedPage,
        auxiliaryLowerLoadedPage: state.auxiliaryLowerLoadedPage > action.payload.currentPage
          ? state.auxiliaryLowerLoadedPage
          : action.payload.currentPage,
        auxiliaryPageCount: action.payload.loadDirection === 'up'
          ? state.auxiliaryPageCount
          : action.payload.pageCount,
      }
    }
    case CLEAN_CHAT_MSG_CONTEXT: {
      return {
        ...state,
        contextMsgId: null,
        auxiliaryCurrentPage: null,
        auxiliaryLowerLoadedPage: null,
        auxiliaryHigherLoadedPage: null,
        auxiliaryPageCount: null,
        auxiliaryTimeline: state.auxiliaryTimeline.length ? [] : state.auxiliaryTimeline,
      }
    }
    case GET_SEARCHED_CHAT_MESSAGES: {
      return {
        ...state,
        search: action.payload.query,
        timelinePending: false,

        auxiliaryCurrentPage: action.payload.currentPage,
        auxiliaryLowerLoadedPage: action.payload.currentPage,
        auxiliaryHigherLoadedPage: action.payload.currentPage,
        auxiliaryPageCount: action.payload.pageCount,
        auxiliaryTimeline: action.payload.messages,

        contextMsgId: null,
      }
    }

    case UPDATE_SEARCHED_CHAT_MESSAGES: {
      return {
        ...state,
        auxiliaryCurrentPage: action.payload.currentPage,
        auxiliaryHigherLoadedPage: state.auxiliaryHigherLoadedPage > action.payload.currentPage
          ? action.payload.currentPage
          : state.auxiliaryHigherLoadedPage,
        auxiliaryLowerLoadedPage: state.auxiliaryLowerLoadedPage > action.payload.currentPage
          ? state.auxiliaryLowerLoadedPage
          : action.payload.currentPage,
        auxiliaryTimeline: onUpdateTimeline(state.auxiliaryTimeline, action.payload.messages, action.payload.loadDirection, action.payload.userTimezone),
        auxiliaryPageCount: action.payload.loadDirection === 'up'
          ? state.auxiliaryPageCount
          : action.payload.pageCount,
        updatePending: false,
      }
    }

    case STOP_SEARCH_CHAT_MESSAGES: {
      return {
        ...state,
        search: '',

        auxiliaryTimeline: state.auxiliaryTimeline.length ? [] : state.auxiliaryTimeline,
        auxiliaryPageCount: null,
        auxiliaryLowerLoadedPage: null,
        auxiliaryHigherLoadedPage: null,
        auxiliaryCurrentPage: null,

        contextMsgId: null,
      };
    }

    case ADD_WEBMASTER_TASK: {
      if (state.active === 'webmaster') {
        return {
          ...state,
          timeline: onInteraction(state.timeline, action.payload)
        };
      }
      return state;
    }

    case REMOVE_WEBMASTER_TASK: {
      if (state.active === 'webmaster') {
        return {
          ...state,
          timeline: onMessageRemove(state.timeline, action.payload.id)
        };
      }
      return state;
    }

    case CREATE_NEW_ROOM: {
      if (state.tabs.includes("new_chat")) {
        return {
          ...state,
          active: "new_chat",
        }
      }
      return {
        ...state,
        active: "new_chat",
        tabs: ["new_chat", ...state.tabs]
      };
    }

    case UPDATE_ROOM: {
      if (!action.payload.isSetActive) {
        return state;
      }

      const updatedTabs = [...state.tabs];
      updatedTabs.splice(4, 0, action.payload.chat.id); //4 couse socket deliver before the XHR responce, XHR responce will delete new_chat at 0 pos

      return {
        ...state,
        active: action.payload.chat.id,
        tabs: updatedTabs
      };
    }

    case SET_GIRLS_TODAY_MSG_COUNT: {
      return {
        ...state,
        girlsTodayMsgCount: action.payload,
      };
    }

    case SET_ROOMS_RECENT_TABS: {
      if (!action.payload) return state;

      return {
        ...state,
        recentTabs: action.payload,
      }
    }

    default:
      return state;
  }
};

// Redux helpers
// const getTypingOperators = (chat, payload, idx) => {
//   if (payload.isTyping && idx === -1) {                                                     // if new operator start typing
//     return [...chat.typingNow, payload.operator]
//   }
//   else if (!payload.isTyping && idx !== -1) {                                               // if operator stop typing
//     return chat.typingNow.filter(operator => operator !== payload.operator)
//   }
//   else return chat.typingNow;                                                               // ignore if operator already typing
// }

export const formatInteractionType = (interaction, userId) => {
  if (interaction.interactionType === 'msg') {
    return userId === interaction.senderId ? 3 : 4;
  }
  else if (interaction.interactionType === 'call') {
    return userId === interaction.senderId ? 2 : 1;
  }
  else if (interaction.interactionType === 'system_msg') {
    return 8;
  }
};

const formatDate = (dt) => {
  return dt.getFullYear() + "/" + (dt.getMonth() + 1) + "/" + dt.getDate();
};

export const groupTimelineByDate = (payload, userId, userTimezone = null) => {
  const result = {};

  payload.forEach((interaction) => {
    const item = {
      ...interaction,
      type: userId ? formatInteractionType(interaction, userId) : interaction.type,
      dateCreated: getDateByTimezoneOffset(userTimezone, interaction.dateCreated),
    };

    const existedItems = result[formatDate(item.dateCreated)]
      ? result[formatDate(item.dateCreated)]
      : [];

    result[formatDate(item.dateCreated)] = [...existedItems, item];
  });

  return Object.values(result);
};

export const onSelectTimelineOperators = (timeline, payload) => {
  if (timeline.length) {
    return onUpdateTimeline(timeline, payload);
  }
  return payload;
};

export const onChatStatusChange = (timeline) => {
  return timeline.map(group => group.map(msg => {
    return msg.type === 3 && msg.status === 'delivered'
      ? { ...msg, status: 'viewed' }
      : msg;
  }));
};

export const onMessageUpdate = (timeline, payload) => {
  let isInTimeline = false;

  const updatedTimeline = timeline.map(group => group.map(interaction => {
    if (payload.id === interaction.id) {
      isInTimeline = true;

      return {
        ...interaction,
        ...payload,
        attachments: null
      };
    }
    else {
      return interaction;
    }
  }));

  return isInTimeline ? updatedTimeline : timeline;
};

export const onMessageRemove = (timeline, removedMsgId) => {
  let isInTimeline = false;

  const updatedTimeline = [];

  for (let i = 0; i < timeline.length; i++) {
    const group = timeline[i];

    const updatedGroup = group.filter(msg => removedMsgId !== msg.id);

    // if msg inside group return updated group
    if (group.length !== updatedGroup.length) {
      isInTimeline = true;

      if (updatedGroup.length) {
        updatedTimeline.push(updatedGroup)
      }
    }
    else {
      updatedTimeline.push(group)
    }
  }

  return isInTimeline ? updatedTimeline : timeline;
};

// export const onMessageRemove = (timeline, removedMsgId) => (
//   timeline
//     .map(group => group
//       .filter(interaction => interaction.id !== removedMsgId))
// );


// Redux helpers stop
