import React from "react";
import { faComments } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import API from "../../../../../api/api";
import { getDateByTimezoneOffset, getShortDate } from "../../../../../utils";
import { useToggle } from "../../../../../hooks";

import Spinner from "../../../../../components/UI/Spinner/Spinner";
import AudioPlayer from "../../../../../components/AudioPlayer/AudioPlayer";

const Voicemail = ({ item: call, userTimezone, playMedia, ...props }) => {
  const [playing, setPlaying] = useToggle(false);

  const showTimelineDate = () => {
    const d = getDateByTimezoneOffset(userTimezone);

    const interactionDate = getShortDate(call.dateCreated);
    const todayByTimezone = getShortDate(getDateByTimezoneOffset(userTimezone));
    const yesterdayByTimezone = getShortDate(new Date(d.setDate(d.getDate() - 1)));

    if (interactionDate === todayByTimezone) {
      return "Today";
    }
    else if (interactionDate === yesterdayByTimezone) {
      return "Yesterday";
    }
    else {
      return interactionDate;
    }
  }

  const setColor = () => {
    if (playing) {
      return { backgroundColor: "#b1e0fc" };
    }

    if (call.voicemail && call.voicemail.listened) {
      return { backgroundColor: "#ececec" };
    }

    return {};
  };

  const onVoicemailPlay = (audio, voicemail) => {
    playMedia(audio);
    setPlaying(true);

    if (voicemail.listened) {
      return;
    }

    API.markVoicemailAsListened(voicemail.id);
  };

  const onContactNameClick = () => {
    return props.getContactMessageContext(call.id, call.caller.type, call.caller);
  }

  if (!call) {
    return (
      <li className='voicemails__item'>
        <Spinner />
      </li>
    );
  }

  return (
    <li className='voicemails__item' style={setColor()}>
      <div className='voicemails__item-name'>
        <FontAwesomeIcon
          icon={faComments}
          color='#0092f2'
          className='voicemails__item-icon'
          title='go to chat'
        />
        <span onClick={onContactNameClick}>
          {call.caller.fn}
        </span>
      </div>
      <div className='voicemails__item-date'>
        {showTimelineDate()}
      </div>

      <div className='voicemails__mini-player'>
        <AudioPlayer
          onPlay={(audio) => onVoicemailPlay(audio, call.voicemail)}
          onPause={() => setPlaying(false)}
          hideVolume
          src={call.voicemail.url}
        />
      </div>
    </li>
  );
};

export default React.memo(Voicemail);
