import { ROOM } from "../config/constants";
import { CONFERENCE } from "../ducks/conferences";

const setRoomAva = (room, isFullSizeImg) => {
  let defaultAvatar;

  switch (room && room.chatType) {
    case 'room':
      defaultAvatar = '/media/avatars/room.png';
      break;
    case 'general':
      defaultAvatar = '/media/avatars/all2.png';
      break;
    case 'saved':
      defaultAvatar = '/media/avatars/saved.png';
      break;
    case 'girls':
      defaultAvatar = '/media/avatars/diva-logo.jpg';
      break;
    default:
      defaultAvatar = '/media/avatars/avatarDefault.png';
  }

  if (!room.photo) {
    return defaultAvatar;
  }
  else {
    return isFullSizeImg
      ? room.photo.src
      : room.photo.min
  }
}

const setContactAva = (contact, isFullSizeImg) => {
  let defaultAvatar;

  switch (contact && contact.type) {
    case 9:
      defaultAvatar = '/media/avatars/avatarDefault.png';
      break;
    case 2:
      defaultAvatar = '/media/avatars/girl.png';
      break;
    case 3:
      defaultAvatar = '/media/avatars/detective-min.png';
      break;
    case 4:
      defaultAvatar = '/media/avatars/hotel-min.png';
      break;
    default:
      defaultAvatar = '/media/avatars/avatarDefault.png';
      break;
  }

  if (!contact || !contact.photo) {
    return defaultAvatar;
  }
  else {
    return isFullSizeImg
      ? contact.photo.src
      : contact.photo.min
  }

}

export default (contact, contactType, isFullSizeImg) => {
  if (contactType === ROOM) {
    return setRoomAva(contact, isFullSizeImg);
  }
  else if (!contact) {
    return setContactAva(contact, isFullSizeImg);
  }
  else if (contact.type === CONFERENCE) {
    return '/media/avatars/all2.png'
  }
  else {
    return setContactAva(contact, isFullSizeImg);
  }
}