import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faTelegram } from '@fortawesome/free-brands-svg-icons';

import {
  getContactAvatar,
  formatDateToTime,
  getDefaultField,
  getDateByTimezoneOffset,
  isEmptyObj, classModifier,
} from '../../../../../utils';
import { useToggle } from '../../../../../hooks';
import ContactInteractionMenu from '../InteractionMenu/ContactInteractionMenu';

import API from '../../../../../api/api';

import SvgIcon from '../../../../../components/SvgIcon/SvgIcon';
import InteractionAttachments from '../../ChatAttachments/InteractionAttachments';
import RoomInteractionMenu from '../InteractionMenu/RoomInteractionMenu';
import MsgText from './MsgText';
import ScheduledMsgInteractionMenu from '../InteractionMenu/ScheduledMsgInteractionMenu';
import AttachmentRepliedMessage from '../../ChatAttachments/AttachmentRepliedMessage';
import ParsedGirlList from '../../ParsedGirlList/ParsedGirlList';

const OutgoingMessage = ({ interaction, search, activeRecipient, ...props }) => {
  const [parsedGirlsList, setParsedGirlsList] = useState({
    query: '',
    list: [],
    isOpen: false,
  });

  const [menu, toggleMenu] = useToggle(false);
  const [isAnimate, setIsAnimate] = useToggle(false);

  const msgRef = useRef();

  const isFromTelegram = interaction.type === 10;
  const isScheduledMsg = interaction.type === 11;

  const isContextMsg = interaction.id === +props.contextMsgId; // cause props.contextMsgId is string

  useEffect(() => {
    if (isContextMsg) {
      setIsAnimate();

      setTimeout(() => setIsAnimate(false), 2000);
    }
  }, [isContextMsg]);

  const showInteractionMenu = () => {
    if (props.isEdited || interaction.is_deleted) {
      return null;
    }

    if (interaction.chatId) {
      return <RoomInteractionMenu
        isOpen={menu}
        closeMenu={toggleMenu}
        interaction={interaction}
        isMine={true}
        editMsg={props.editMsg}
        shareMsg={props.shareMsg}
      />
    }

    return isScheduledMsg
      ? (
        <ScheduledMsgInteractionMenu
          isOpen={menu}
          closeMenu={toggleMenu}
          interaction={interaction}
          userTimezone={props.userTimezone}
          editMsg={props.editMsg}
          openModal={props.openModal}
        />
      )
      : (
        <ContactInteractionMenu
          isOpen={menu}
          closeMenu={toggleMenu}
          interaction={interaction}
          search={search}
          replyMsg={props.replyMsg}
          userTimezone={props.userTimezone}
          activeChannel={activeRecipient.default_channel}
        />
      )
  };

  const formatStatus = (status) => {
    switch (status) {
      case 'pending': {
        return <SvgIcon icon="pending-status" title="pending" fill="#BFBFBF" width="10px" height="10px" />;
      }

      case 'sent': {
        return <SvgIcon icon="sent-status" title="sent" fill="#BFBFBF" width="12px" height="12px" />;
      }

      case 'delivered': {
        return <SvgIcon icon="sent-status" title="delivered" fill="#0092F2" width="12px" height="12px" />;
      }

      case 'viewed': {
        return <SvgIcon icon="eye" title="viewed" fill="#0092F2" width="12px" height="12px" />;
      }
      case 'undelivered':
      case 'failed': {
        return <FontAwesomeIcon icon={faTimes} title="failed" color="#F8737F" width="12px" height="12px" />;
      }

      default:
        return <SvgIcon icon="pending-status" title="pending" fill="#BFBFBF" width="10px" height="10px" />;
    }
  };

  const status = interaction.status
    ? <span className="interaction__status--reverse">{formatStatus(interaction.status)}</span>
    : null;

  const getMsgSender = () => {
    let sender;
    let isIAmSender = false;

    if (interaction.user_id === props.profileId) {
      sender = interaction.user;
      isIAmSender = true;
    }
    else if (interaction.senderId === props.profileId) {
      sender = interaction.sender;
      isIAmSender = true;
    }
    else {
      sender = interaction.user || {};
    }

    return {
      sender,
      isIAmSender
    };
  };

  const renderHeader = () => {
    const { isIAmSender, sender } = getMsgSender();

    return isIAmSender
      ? <span className="interaction__name interaction__name--is-sender">
        You
        {isFromTelegram &&
          <FontAwesomeIcon className="interaction__icon--right" icon={faTelegram} size="lg" color="#0092F2" />
        }
      </span>
      : <Fragment>
        <div className="interaction__ava">
          <img src={getContactAvatar(sender)} alt="ava" />
          {isFromTelegram &&
            <FontAwesomeIcon className="interaction__icon--right" icon={faTelegram} size="lg" color="#0092F2" />
          }
        </div>

        <span className="interaction__name">
          {(isFromTelegram && isEmptyObj(sender)) ? 'System Bot' : sender.username}
        </span>
      </Fragment>;
  };

  const searchByGirlName = useCallback((e) => {
    const text = e.currentTarget.getAttribute('data-value');

    API.searchContacts(2, text)
      .then(res => {
        props.addNewArrayGirlsToState(res.data);

        setParsedGirlsList({ query: text, list: res.data, isOpen: true });
      });
  }, []);

  const getMsgContext = (e) => {
    if (!search) {
      return;
    }

    if (!props.contextMsgId) {                          // if we not in Msg Context
      return e.target.className !== 'lazy-load__image'
        ? props.getMessageContext(interaction.id, props.isGlobalSearch ? interaction.caller : null, search)
        : null;
    }
  };

  const setContextRef = ref => {
    if (isContextMsg) {
      return props.contextMsgRef.current = ref;
    }
  };

  const isSentOnNotDefaultNumber = interaction.channel && getDefaultField(activeRecipient.tels, 'tel') !== interaction.channel;
  // console.log('outgoing Render', props.parsedMsgText);

  const getInteractionDate = () => {
    if (isScheduledMsg) {
      return (
        <time>
          {getDateByTimezoneOffset(props.userTimezone, interaction.date).toLocaleString('eu', {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
            hour12: props.userHour12,
            hour: '2-digit',
            minute: '2-digit'
          })
          }
        </time>
      )
    }

    return formatDateToTime(
      getDateByTimezoneOffset(props.userTimezone, interaction.dateCreated),
      props.userHour12
    );
  }

  return (
    <>
      <li
        className={classModifier('interaction', [
            'out',
            interaction.isNew && 'new',
            interaction.is_deleted && 'deleted',
        ])}
        style={isContextMsg ? { border: "1px solid #0092F2" } : null}
        data-isanimate={isAnimate}
        onClick={getMsgContext}
        ref={setContextRef}
        // if we will use below {() => toggleMenu()} it will rerender this interaction
        onMouseEnter={() => toggleMenu(true)}
        onMouseLeave={() => toggleMenu(false)}
      >
        <header className="interaction__header interaction__header--reverse" ref={msgRef}>
          {renderHeader()}
          {status}

          {!!interaction.edited && <span className="interaction__edited">edited</span>}

          {interaction.is_deleted && <span className="interaction__deleted">deleted</span>}

          <span className="interaction__time">
            {isScheduledMsg && 'Will be sent at: '}
            {getInteractionDate()}
          </span>
          <span className="interaction__channel">
            {isSentOnNotDefaultNumber && `(to: ${interaction.channel})`}
          </span>

          {showInteractionMenu()}
        </header>

        <div className={classModifier('interaction__body-wrap', [
          (interaction.is_deleted && interaction.attachments) && 'deleted',
        ])}>
          {interaction.attachments?.repliedMessage &&
            <div className="interaction__replied-msg">
              <AttachmentRepliedMessage
                profileId={props.profileId}
                userHour12={props.userHour12}
                interaction={interaction.attachments.repliedMessage}
                userTimezone={props.userTimezone}
                getMessageContext={props.getMessageContext}
              />
            </div>
          }

          {(interaction.body || !interaction.attachments) &&
            <div className="interaction__text-wrap">
              <MsgText 
                highlightedText={search} 
                interaction={interaction} 
                onGirlNameClick={searchByGirlName}
                />
            </div>
          }

          {interaction.attachments &&
            <InteractionAttachments
              attachments={interaction.attachments}
              profileId={props.profileId}
              userTimezone={props.userTimezone}
              userHour12={props.userHour12}
            />
          }
        </div>
      </li>
      {parsedGirlsList.isOpen &&
        <ParsedGirlList
          msgRef={msgRef}
          activeRecipient={activeRecipient}
          addNewArrayGirlsToState={props.addNewArrayGirlsToState}
          profileId={props.profileId}
          parsedGirlsList={parsedGirlsList}
          updateActiveContact={props.updateActiveContact}
          setParsedGirlsList={setParsedGirlsList}
        />
      }
    </>
  );
};

export default OutgoingMessage;