import axios from 'axios';

import { handleNetError, LS } from '../utils';


const protocol = 'https://';
const currentHostname = window && window.location && window.location.hostname;

let backendHostname;

if (currentHostname === 'localhost') {
  backendHostname = 'comdiva.local';
}
else if (currentHostname === 'connect.divaes.co') {
  backendHostname = 'connect.divaes.co';
}
else if (currentHostname && currentHostname !== 'chromewebdata') {
  backendHostname = currentHostname;
}
else {
  backendHostname = process.env.REACT_APP_BACKEND_HOST || 'unknownhosterror';
}

export const API_ROOT = protocol + backendHostname + '/api/';
export const ADMIN_PAGE_ROOT = protocol + 'admin.' + backendHostname + '/admin';

axios.defaults.baseURL = API_ROOT;

if (process.env.NODE_ENV === 'development') {
  console.log('dev - add axios credentials conf');

  axios.defaults.withCredentials = true;
}

axios.interceptors.response.use(
  response => {
    // Do something with response data

    LS.setItem('lastInteractionTime', Date.now());

    return Promise.resolve(response);
  },
  error => {
    // Do something with response error
    handleNetError(error);
    return Promise.reject(error);
  }
)
