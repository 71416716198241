import React from 'react';
import { connect } from 'react-redux';

import { convertNumberToSeconds } from '../../../../utils';
import SvgIcon from '../../../../components/SvgIcon/SvgIcon';
import { updateActiveContact } from '../../../../ducks/clientChats';
import TimeAgo from '../../../../components/TimeAgo';
// import formatTime from '../../../../utils/formatTime';


const CallsLogItem = ({ item, ...props }) => {
  const renderCallIcon = () => {
    let icon;
    let color;

    if (item.voicemail) {
      icon = 'missedCall';
      color = '#000';
    }
    else {
      switch (item.status) {
        case 'no-answer':
          icon = 'missedCall';
          color = '#000';
          break;
        case 'transfered':
          icon = 'arrows';
          color = '#000';
          break;
        case 'incomming':
          icon = 'incommingCall';
          color = '#777';
          break;
        case 'outgouing':
          icon = 'outgouingCall';
          color = '#777';
          break;
        default:
          icon = 'incommingCall';
          color = '#777';
          break;
      }
    }
    return <SvgIcon icon={icon} width="12px" height="12px" fill={color} />;
  };

  const getBgColor = () => {
    if (item.voicemail) {
      return '#BDBDBD';
    }

    switch (item.status) {
      case 'no-answer':
        return '#f8737f57';
      case 'transfered':
        return '#f2cc0c6b';
      default:
        return '#F6F9FC';
    }
  };

  return (
    <li
      className="calls-log__item"
      onClick={() => props.updateActiveContact(item.caller)}
      style={{ backgroundColor: getBgColor() }}>
      <span className="calls-log__img-wrapper">
        {renderCallIcon()}
      </span>

      <span className="calls-log__name">
        {item.caller.short_name || item.caller.fn || item.sender.username}
      </span>

      <span className="calls-log__date">
        <TimeAgo minPeriod={60} date={item.dateCreated} />
      </span>

      <span className="calls-log__dash"> - </span>

      <span className="calls-log__duration">
        {item.duration && convertNumberToSeconds(item.duration)}
      </span>

      {/* <span className="calls-log__meta">
        {item.onTransfer !== 0 && 'operatorXYZ'}
      </span> */}

    </li>
  );
};

const mapStateToProps = (state, ownProps) => ({
  //there will be podpiski
});
const mapDispatchToProps = {
  updateActiveContact
};

export default connect(mapStateToProps, mapDispatchToProps)(CallsLogItem);
