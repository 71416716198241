import API from "../api/api";
import { normalizeContacts } from "../utils";

export const GET_OPERATORS_LIST = "GET_OPERATORS_LIST";
export const CHANGE_OPERATOR_STATUS = "CHANGE_OPERATOR_STATUS";
export const CHANGE_OPERATOR_TYPING_STATUS = "CHANGE_OPERATOR_TYPING_STATUS";
export const UPDATE_ACTIVE_OPERATOR = "UPDATE_ACTIVE_OPERATOR";
export const CHANGE_OPERATOR_ENTITY = "CHANGE_OPERATOR_ENTITY";

export const getOperatorsList = () => (dispatch, getState) => {
  const state = getState();

  API.getOperatorsList()
    .then(res => {
      const filteredOperators = res.data.filter(o => o.id !== state.user.id);

      dispatch({
        type: GET_OPERATORS_LIST,
        payload: normalizeContacts(filteredOperators, false)
      });
    })
    .catch(err => console.log(err));
};

export const changeOperatorStatus = operator => dispatch => {
  dispatch({
    type: CHANGE_OPERATOR_STATUS,
    payload: operator
  });
};
// not used
export const changeOperatorTypingStatus = (id, isTyping) => dispatch => {
  dispatch({
    type: CHANGE_OPERATOR_TYPING_STATUS,
    payload: { id, isTyping }
  });
};
//not used TODO: create func for this
export const debouncedOperatorTypingStop = (() => {
  let timerList = {};

  return id => dispatch => {
    if (timerList[id]) {
      clearTimeout(timerList[id]);
    }

    const functionCall = (id => () =>
      dispatch(changeOperatorTypingStatus(id, false)))(id);

    timerList[id] = setTimeout(functionCall, 5000);
  };
})();

export const changeOperatorEntity = entity => dispatch => {
  dispatch({
    type: CHANGE_OPERATOR_ENTITY,
    payload: entity
  });
};

const initialState = {
  entities: {},
  ids: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_OPERATORS_LIST: {
      console.log("GET_OPERATORS_LIST", action.payload);
      return {
        ...state,
        ...action.payload
      };
    }
    case CHANGE_OPERATOR_STATUS: {
      const updatedEntity = state.entities[action.payload.user_id];

      if (!updatedEntity) return state;

      const updatedEntities = {
        ...state.entities,
        [updatedEntity.id]: {
          ...updatedEntity,
          status: action.payload.status
        }
      };

      return {
        ...state,
        entities: updatedEntities
      };
    }

    case CHANGE_OPERATOR_ENTITY: {
      const updatedEntities = {
        ...state.entities,
        [action.payload.id]: action.payload
      };

      return {
        ...state,
        entities: updatedEntities
      };
    }

    default:
      return state;
  }
};
