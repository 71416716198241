import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';

import { openModal, MODAL_TYPES } from '../../ducks/activeWindows';

import './GirlActualBookingsBtn.scss';


const GirlActualBookingsBtn = (props) => {
  const {
    profileId,
  } = props;

  const className = props.className
    ? 'girl-actual-bookings-btn ' + props.className
    : 'girl-actual-bookings-btn'

  return (
    <button
      className={className}
      title='Actual girl bookings'
      onClick={() => props.openModal(MODAL_TYPES.bookingsList, { girlDivaId: profileId })}
    >
      <FontAwesomeIcon icon={faCalendar} />
    </button>
  )
}

const mapDispatchToProps = {
  openModal,
};

export default connect(null, mapDispatchToProps)(GirlActualBookingsBtn);