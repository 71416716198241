//-------helpers------------------------------
const ls = localStorage;

const stringify = value => JSON.stringify(value);
const parse = value => JSON.parse(value);


//---------for export-------------------------

const getUserStorage = (userId) => {
  const userStorage = parse(ls.getItem('user_' + (userId || localStorage.currentUserId)));

  if (userStorage && typeof userStorage === 'object' && userStorage.constructor.name === 'Object') {
    return userStorage;
  }
  return {};
}

const updateUserStorage = (data, userId) => {
  if (ls.getItem('user_' + userId)) {
    const currentStorage = getUserStorage(userId);

    ls.setItem('user_' + userId, stringify({
      ...currentStorage,
      ...data
    }));
  }
}

const cleanUserChatTabs = (userId) => {
  const updatedStorage = {
    aChat: 'girls',
    chTabs: [],
    aClient: null,
    cTabs: [],
    aGirl: null,
    gTabs: [],
    unfixedClientTab: null,
    unfixedChatTab: null,
    unfixedGirlTab: null,
  };

  updateUserStorage(updatedStorage, userId)
}

const setItem = (key, value, userId) => {                  //set key - value to user_id 
  if (!localStorage.currentUserId && !userId) return;

  const userStorage = getUserStorage(userId);

  ls.setItem('user_' + (userId || localStorage.currentUserId), stringify({ ...userStorage, [key]: value }))
};

const deleteItem = (key, userId) => {        // delete key-value by key
  const userStorage = getUserStorage(userId);
  delete userStorage[key];

  ls.setItem('user_' + (userId || localStorage.currentUserId), stringify(userStorage));
};

const getItem = (key, userId) => {                   // get value by key from user_id
  const userStorage = getUserStorage(userId);

  return userStorage[key];
};

const getAFKTime = (currentTime, userId) => {
  const userStorage = getUserStorage(userId);

  const lastInteractionTime = userStorage && userStorage.lastInteractionTime
    ? +userStorage.lastInteractionTime
    : currentTime;

  return (currentTime - lastInteractionTime) / (1000 * 60 * 60);
};


export default {
  getUserStorage,
  updateUserStorage,
  setItem,
  getItem,
  deleteItem,
  getAFKTime,
  cleanUserChatTabs
};


// //-------helpers------------------------------
// const ls = localStorage;

// const stringify = value => JSON.stringify(value);
// const parse = value => JSON.parse(value);

// const currentUserId = parse(ls.getItem('currentUserId'));

// const getUserStorage = (id) => parse(ls.getItem(`user_${id || currentUserId}`));

// //---------for export-------------------------
// const authorizeUser = (user) => {    //register user in LS if doesn't exist or update user info
//   const { settings, ...userInfo } = user;

//   if (ls.getItem('user_' + userInfo.id)) {
//     const currentStorage = getUserStorage(userInfo.id);

//     ls.setItem(`currentUserId`, stringify(userInfo.id));
//     ls.setItem(`user_${userInfo.id}`, stringify({
//       ...currentStorage,
//       'user': { ...userInfo },
//       'settings': { ...currentStorage.settings, ...settings },
//     }));

//     return;
//   }

//   ls.setItem(`currentUserId`, stringify(userInfo.id));
//   ls.setItem(`user_${userInfo.id}`, stringify({ user: userInfo, 'settings': settings }));
// };

// const setItem = (key, value) => {                  //set key - value to user_id 
//   if (!currentUserId) return;

//   const userInfo = getUserStorage();

//   ls.setItem(`user_${currentUserId}`, stringify({ ...userInfo, [key]: value }))
// };

// const deleteItem = (key) => {        // delete key-value by key
//   const userInfo = getUserStorage();

//   delete userInfo[key];

//   ls.setItem(`user_${currentUserId}`, stringify(userInfo));
// };

// const getItem = (key) => {                   // get value by key from user_id
//   const userInfo = getUserStorage();

//   return userInfo
//     ? userInfo[key]
//     : null;
// };

// const getAFKTime = (currentTime) => {
//   const userInfo = getUserStorage();

//   const lastInteractionTime = userInfo && userInfo.lastInteractionTime
//     ? +userInfo.lastInteractionTime
//     : currentTime;

//   return (currentTime - lastInteractionTime) / (1000 * 60 * 60);
// };


// export default {
//   authorizeUser,
//   setItem,
//   getItem,
//   deleteItem,
//   getAFKTime,
// };
