import React from 'react';
import classNames from 'classnames';

import './Labels.scss';
import { getDateByTimezoneOffset } from '../../utils';

const Labels = (props) => {
    const { profile, classPrefix } = props;

    const getLabels = () => {
        const labels = [];

        profile.isNew && labels.push({
            className: 'profile-labels__label--red',
            labelName: 'New'
        });

        let secret_price = profile.prices_secret.find((secret) => secret.name === '1 hour');
        if (secret_price.incall !== '' || secret_price.outcall !== '') {
            labels.push({
                className: 'profile-labels__label--secret',
                labelName: `£ ${secret_price.incall ? secret_price.incall : '-'}/${secret_price.outcall ? secret_price.outcall : '-'}`
            });
        }

        if (profile.orientation !== 'Hetero') {
            labels.push({
                className: 'profile-labels__label--' + profile.orientation.toLowerCase(),
                labelName: profile.orientation
            });
        }

        profile.offer && labels.push({
            className: 'profile-labels__label--lightpink',
            labelName: 'Offer'
        })

        profile.exclusive && labels.push({
            className: 'profile-labels__label--green',
            labelName: 'Exclusive'
        })

        profile.is_banned && labels.push({
            className: 'profile-labels__label--lightpink',
            labelName: 'Banned'
        });

        profile.bestmatch && labels.push({
            className: 'profile-labels__label--green',
            labelName: profile.bestmatch + '%'
        });

        profile.is_avoid && labels.push({
            className: 'profile-labels__label--red',
            labelName: 'Avoid'
        });

        profile.is_archived && labels.push({
            className: 'profile-labels__label--grey',
            labelName: 'Archived'
        });

        profile.featured && labels.push({
            className: 'profile-labels__label--featured',
            labelName: 'Featured'
        });

        profile.is_for_member && labels.push({
            className: 'profile-labels__label--grey',
            labelName: 'For member'
        });

        profile.is_on_holiday && labels.push({
            className: 'profile-labels__label--on-holiday',
            labelName: 'On Holiday',
            tooltip: `On holiday since ${showDateOnHoliday(profile.on_holiday_since)}`
        });

        !profile.contact_uid && labels.push({
            className: 'profile-labels__label--green',
            labelName: 'No contact!'
        });

        profile.recommended && labels.push({
            className: 'profile-labels__label--recommended',
            labelName: 'Recommended'
        });

        profile.is_not_recommended && labels.push({
            className: 'profile-labels__label--lightpink',
            labelName: 'Not Recommended'
        });

        return labels.sort((a, b) => a.labelName.length - b.labelName.length);
    }

    const showDateOnHoliday = (utcDate) => {
      const dateByTimezone = getDateByTimezoneOffset(props.userTimezone, utcDate);
      return dateByTimezone.toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric'});
    };

    return (
      <div className={classNames('profile-labels', `profile-labels--${classPrefix}`)}>
        {getLabels().map((label, index) => (
          <div
            className={classNames('profile-labels__label', `profile-labels__label--${classPrefix}`, label.className)}
            key={index}
          >
            {label.labelName}

            {label.tooltip && 
              <div className='profile-labels__label-tooltip'>
                {label.tooltip}
              </div>
            }
          </div>
        ))}
      </div>
    );
};

export default React.memo(Labels);