import React, { useLayoutEffect, useRef, useState } from 'react'
import ReactCalendar from 'react-calendar'

import './DropReactCalendar.scss'
import DropWrapper from '../DropWrapper/DropWrapper';

const DropReactCalendar = ({ wrapClassName, onClose, ...rest }) => {
  const [wrapStyle, setWrapStyle] = useState(null);

  const wrapRef = useRef(null);

  // Autoposition to fit in viewport
  useLayoutEffect(() => {
    const { top: ownTop, height: ownHeight } =
      wrapRef.current.getBoundingClientRect();
    const { clientHeight } = document.documentElement;

    // Top initial (relatively viewport)
    let top = ownTop;
    // Move higher if does not fit at the bottom
    const bottom = top + ownHeight;
    const bottomOffset = bottom - (clientHeight - 5);
    top = bottomOffset > 0 ? top - bottomOffset : top;
    // Leave at very top if does not fit on high
    top = top < 5 ? 5 : top;

    // Move if new position different from current
    if (top !== ownTop) {
      setWrapStyle({ transform: `translateY(${top - ownTop}px)` });
    }
  }, []);

  return (
    <div
      className={wrapClassName || "drop-react-calendar"}
      style={wrapStyle}
      ref={wrapRef}
    >
      <DropWrapper
        dropWrapperRef={wrapRef}
        closeDropWrapper={onClose}
      >
        <ReactCalendar {...rest} />
      </DropWrapper>
    </div>
  );
};

export default DropReactCalendar;
