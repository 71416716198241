import { combineReducers } from 'redux';

import errorsReducer from '../ducks/errors';
import twilioReducer from '../ducks/twilio';
import callsReducer from '../ducks/calls';
import missedCallsReducer from '../ducks/missedCalls';
import contactsReducer from '../ducks/contacts';
import activeWindowsReducer from '../ducks/activeWindows';
import userReducer from '../ducks/user';
import msgTemplatesReducer from '../ducks/msgTemplates';
import timersReducer from '../ducks/timers';
import operatorsReducer from '../ducks/operators';
import roomChatsReducer from '../ducks/roomChats';
import clientChatsReducer from '../ducks/clientChats';
import girlChatsReducer from '../ducks/girlChats';
import roomsReducer from '../ducks/rooms';
import divaGirlsReducer from "../ducks/divaGirls";
import sessionsReducer from "../ducks/sessions";
import notificationsReducer from "../ducks/notifications";
import typingOperatorsReducer from "../ducks/typingOperators";
import conferencesReducer from "../ducks/conferences";
import settingsReducer from "../ducks/settings";
import saleSessionChatsReducer from "../ducks/saleSessionChats";
import webrtcReducer from "../ducks/webrtc";
import addressBookReducer from "../ducks/addressBook";
import mailReducer from "../ducks/mail";
import bookingsReducer from '../ducks/bookings'
import countersReducer from '../ducks/counters'


export default combineReducers({
  errors: errorsReducer,
  twilio: twilioReducer,
  calls: callsReducer,
  missedCalls: missedCallsReducer,
  activeWindows: activeWindowsReducer,
  user: userReducer,
  msgTemplates: msgTemplatesReducer,
  timers: timersReducer,
  operators: operatorsReducer,
  roomChats: roomChatsReducer,
  clientChats: clientChatsReducer,
  girlChats: girlChatsReducer,
  contacts: contactsReducer,
  rooms: roomsReducer,
  divaGirls: divaGirlsReducer,
  sessions: sessionsReducer,
  notifications: notificationsReducer,
  typingOperators: typingOperatorsReducer,
  conferences: conferencesReducer,
  settings: settingsReducer,
  saleSessionChats: saleSessionChatsReducer,
  webrtc: webrtcReducer,
  addressBook: addressBookReducer,
  mail: mailReducer,
  bookings: bookingsReducer,
  counters: countersReducer,
});