import React from 'react';
import { connect } from 'react-redux';

import LazyLoadImage from '../../../../components/LazyLoadImage/LazyLoadImage';

import { getContactAvatar } from '../../../../utils';
import API from '../../../../api/api';
import { selectOperatorIncludingCurrent } from '../../../../selectors/selectors';


const RoomParticipant = ({ id, operator, isIamAdmin, roomId, adminsIds, ...props }) => {
  const isParticipantAdm = adminsIds.some(admId => admId === id);

  const removeParticipantFromRoom = () => {
    if (window.confirm(`Remove ${operator.username} from room?`)) {
      API.removeUserFromRoom(roomId, id);
    }
  };

  const renderParticipantOption = () => {
    if (!isIamAdmin && isParticipantAdm) {
      return 'Admin';
    }

    if (isIamAdmin) {
      return isParticipantAdm
        ? 'Admin'
        : <div
          className="create-room-dialog__close"
          onClick={removeParticipantFromRoom} />;
    }
  };

  return (
    <li className="room-info-card__list-item">
      <figure className="room-info-card__ava-wrap">
        <LazyLoadImage
          src={getContactAvatar(operator)}
          className="round-img"
          alt="contact_avatar" />
      </figure>

      <span className="room-info-card__name--operator">{operator.username}</span>

      <span className={`room-info-card__name--action${isParticipantAdm ? `-adm` : ``}`}>
        {renderParticipantOption()}
      </span>
    </li>
  );
};

const mapStateToProps = (state, ownProps) => ({
  operator: selectOperatorIncludingCurrent(state, ownProps.id)
});

export default connect(mapStateToProps)(RoomParticipant);