import React from 'react';
import SplitPane from "react-split-pane";

import './Sidebar.scss';
import SplitPaneLS from '../../components/SplitPaneLS/SplitPaneLS';


const Sidebar = (props) => (
  <aside className="sidebar" style={props.style}>
    {props.header && props.header()}

    <div className="sidebar__panes">
      <SplitPaneLS
        id={props.id}
        split="horizontal"
        minSize={props.minSize || 288}
        defaultSize={props.defaultSize || 470}
        maxSize={props.maxSize || 700}
      >
        {props.children}
      </SplitPaneLS>
    </div>
  </aside>
);

export default Sidebar;