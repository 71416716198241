import React from "react";
import { connect } from "react-redux";

import "./ProfileCarousel.scss";
import Carousel from "../../../../components/Carousel";


const ProfileCarousel = (props) => {
  const closeCarousel = (e) => e.target.className === 'lazy-load__container'
    ? props.closeModal()
    : null;

  return (
    <div
      className="profile-carousel"
      onClick={closeCarousel}
    >
      <Carousel
        images={props.images.length
          ? props.images
          : [props.avatar]
        }
        imgClass="profile-carousel__img"
        imgWrapClass='profile-carousel__img-wrap'
        prevBtnClass='profile-carousel__prev-slide'
        nextBtnClass='profile-carousel__next-slide'
        iconWidth="64"
        iconHeight="64"
        spinnerSize={100}
        defaultActiveSlide={props.defaultActiveSlide}
        isKeyNavigation
      />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  images: ownProps.id
    ? state.divaGirls.entities[ownProps.id].images
    : ownProps.images,
  avatar: ownProps.id && state.divaGirls.entities[ownProps.id].avatar,
});

export default connect(mapStateToProps)(ProfileCarousel);