import React, { memo, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHistory, faLink, faMap, faPoundSign } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux';

import { useToggle } from '../../../../hooks';
import { textLineSplitter, getContactAvatar } from '../../../../utils';
import { MONTHS, CARD_TABS } from '../../../../config/constants';
import API from '../../../../api/api';
import { openModal, MODAL_TYPES } from '../../../../ducks/activeWindows';

import './ContactDetails.scss'

import ProfileRates from '../../../../components/ProfileRates/ProfileRates';
import SvgIcon from '../../../../components/SvgIcon/SvgIcon';
import CopyToClipboardButton from '../../../../components/CopyToClipboardButton/CopyToClipboardButton';
import LazyLoadImage from '../../../../components/LazyLoadImage/LazyLoadImage';
import Labels from '../../../../components/Labels/Labels';

const ContactDetails = ({ activeRecipient, ...props }) => {
  console.log('chat details render');

  const [contactInfo, setContactInfo] = useToggle(false);
  const [isRatesOpen, toggleRatesOpen] = useToggle(false);
  const [clientSessionCounts, setClientSessionCounts] = useState(null);

  useEffect(() => {
    if (activeRecipient.type === 1) {
      API.getClientSessionCounts(activeRecipient.id)
        .then(res => {
          setClientSessionCounts(res.data)
        })
        .catch(console.log);
    }
  }, [activeRecipient])

  const renderGirlUrls = urls => urls.map(url => (
    <div key={url.url} className="contact-details__body--item__link">

      <div className="contact-details__body--trim">
        <FontAwesomeIcon icon={faLink} color={'#bfbfbf'} className="item-icon" />
        <span style={{ textTransform: "capitalize" }} >{url.title}: </span>
        <span>
          <a
            href={url.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {url.url}
          </a>
        </span>
      </div>

      <div>
        <CopyToClipboardButton
          className='item__copy'
          value={url.url}
          disabled={!url.url}
          title='Copy link'
          svgWidth={14}
          svgHeight={14}
        />
      </div>
    </div>
  ));

  const getDate = (date, title) => {
    if (!date) {
      return;
    }

    return title === 'created'
      ? `${MONTHS[new Date(date).getMonth()]} ${date.slice(5, 7)} / ${date.slice(8, 10)} / ${date.slice(0, 4)}`
      : `${date.slice(5, 7)} / ${date.slice(8, 10)} / ${date.slice(0, 4)}`
  };

  return (
    <>
      {activeRecipient.type === 2 && activeRecipient.diva_default_id && // If type 2 and contact has diva profile
        <div className="chat-header__btn--prices" 
          onMouseEnter={() => toggleRatesOpen(true)}
          onMouseLeave={() => toggleRatesOpen(false)}
        >
          <FontAwesomeIcon icon={faPoundSign} title='prices' />

          {isRatesOpen &&
            <div className="contact-details__chat-wrapper">
              <ProfileRates
                isEditor
                profileId={props.divaProfile?.id}
                isMember={props.divaProfile?.is_for_member || false}
                defaultPrices={props.divaProfile?.prices || []}
                memberPrices={props.divaProfile?.prices_member || []}
                secretPrices={props.divaProfile?.prices_secret || []}
              />
            </div>
          }
        </div>
      }

      <div className="chat-header__btn--info"
        onMouseEnter={() => setContactInfo(true)}
        onMouseLeave={() => setContactInfo(false)}
      >
        <SvgIcon icon="info" width="16" height="16" title="info" />
      </div>

      {contactInfo &&
        <div
          className="contact-details"
          onMouseEnter={() => setContactInfo(true)}
          onMouseLeave={() => setContactInfo(false)}
        >

          <div className="contact-details__header">
            <div className="contact-details__header--header">
              <div className={`contact-details__header--ava ${activeRecipient.availability ? `contact-details__header--ava-${activeRecipient.availability}` : ''
                }`}>
                <LazyLoadImage
                  src={getContactAvatar(activeRecipient)}
                  className="contact-details__header--ava-img"
                  alt="photo"
                />

                <div className="contact-details__header--actions">
                  <button
                    className="contact-details__header--actions-btn btn-edit"
                    onClick={() => props.openModal(MODAL_TYPES.contactCard, {
                      contact: activeRecipient.id,
                      defaultTab: CARD_TABS.EDIT
                    })}
                  >
                    <SvgIcon icon="edit" fill="#0092f2" height={16} title="Edit contact" />
                  </button>

                  {activeRecipient.type === 1 &&
                    <button
                      className="contact-details__header--actions-btn btn-sessions"
                      onClick={() => props.openModal(MODAL_TYPES.contactCard, {
                        contact: activeRecipient.id,
                        defaultTab: CARD_TABS.SESSIONS
                      })}
                    >
                      <SvgIcon icon="puzzle" fill="#0092f2" height={18} title="Sessions" />
                      {clientSessionCounts &&
                        <span className="btn-sessions__indicator">
                          {clientSessionCounts.successful + clientSessionCounts.unsuccessful}
                        </span>
                      }
                    </button>
                  }

                  {activeRecipient.type === 1 &&
                    <button
                      className="contact-details__header--actions-btn btn-logs"
                      onClick={() => props.openModal(MODAL_TYPES.contactCard, {
                        contact: activeRecipient.id,
                        defaultTab: CARD_TABS.LOGS
                      })}
                    >
                      <FontAwesomeIcon icon={faHistory} color={'#0092f2'} title="Logs" size='lg' />
                    </button>
                  }

                </div>
              </div>
            </div>
            <div className="contact-details__header--footer">

              <span className="contact-details__header--footer-created-at">
                {`Created: ${getDate(activeRecipient.date_created, 'created')}`}
              </span>

              {activeRecipient.date_updated &&
                <span className="contact-details__header--footer-edited-at">
                  {`Edited: ${getDate(activeRecipient.date_updated, 'edited')}`}
                </span>
              }

              {activeRecipient.type === 2 && props.divaProfile &&
                <Labels profile={props.divaProfile} classPrefix={'contact-det'}/>
              }
            </div>
          </div>

          <div className="contact-details__body">

            <span className="contact-details__body--divider"></span>

            <div className="contact-details__body--item">
              {!!activeRecipient.tels.length
                ? activeRecipient.tels.map(phone =>
                  <div
                    className='contact-details__body--item__phone'
                    key={phone.id ? phone.id : phone.tel}
                    title={phone.tel}
                  >
                    <div className="contact-details__body--trim">
                      <SvgIcon icon="phone" fill="#bfbfbf" height={12} title="phone" className="item-icon" />
                      {phone.tel}
                    </div>

                    <div>
                      <CopyToClipboardButton
                        className='item__copy'
                        value={phone.tel}
                        disabled={!phone.tel}
                        title='Copy phone'
                        svgWidth={14}
                        svgHeight={14}
                      />
                    </div>
                  </div>
                )
                : <div className="contact-details__body--item__phone">No phone</div>
              }
            </div>

            <span className="contact-details__body--divider"></span>

            <div className="contact-details__body--item">
              {activeRecipient.emails && !!activeRecipient.emails.length
                ? activeRecipient.emails.map(email =>
                  <div
                    className='contact-details__body--item__mail'
                    key={email.id}
                    title={email.email}
                  >
                    <div className="contact-details__body--trim">
                      <SvgIcon icon="envelope" fill="#bfbfbf" height={12} title="phone" className="item-icon" />
                      {email.email}
                    </div>

                    <div>
                      <CopyToClipboardButton
                        className='item__copy'
                        value={email.email}
                        disabled={!email.email}
                        title='Copy email'
                        svgWidth={14}
                        svgHeight={14}
                      />
                    </div>
                  </div>
                )
                : <div className="contact-details__body--item__mail">No email</div>
              }
            </div>

            <span className="contact-details__body--divider"></span>

            <div className="contact-details__body--item">
              {activeRecipient.adr
                ? <div className='contact-details__body--item__adress'>

                  <div className="contact-details__body--prewrap">
                    <FontAwesomeIcon icon={faMap} color={'#bfbfbf'} className="item-icon" />
                    {textLineSplitter((activeRecipient.adr || 'No address'))}
                  </div>

                  <div className="adress__btn-copy">
                    <CopyToClipboardButton
                      className='item__copy'
                      value={activeRecipient.adr}
                      disabled={!activeRecipient.adr}
                      title='Copy adress'
                      svgWidth={14}
                      svgHeight={14}
                    />
                  </div>
                </div>
                : <div className='contact-details__body--item__adress'>No adress</div>
              }
            </div>

            <span className="contact-details__body--divider"></span>

            <div className="contact-details__body--item">
              <div className='contact-details__body--item__tags'>
                {activeRecipient.callerTags && !!activeRecipient.callerTags.length
                  ? activeRecipient.callerTags.map(tag =>
                    <div
                      className='contact-details__body--item__tag'
                      key={tag.id}
                      style={{
                        background: '#2d91f2',
                        color: '#fff'
                      }}
                    >
                      {tag.title}
                    </div>
                  )
                  : 'No tags'
                }
              </div>
            </div>

            <span className="contact-details__body--divider"></span>

            <div className="contact-details__body--item">
              <div className="contact-details__body--item__notes">
                {!!activeRecipient.note
                  ? <span className='contact-details__body--prewrap'>
                    {activeRecipient.note}
                  </span>
                  : 'No notes'
                }
              </div>
            </div>

            {activeRecipient.map &&
              <>
                <span className="contact-details__body--divider"></span>

                <div className="contact-details__body--item">
                  <div className="contact-details__body--item__map contact-details__body--trim">

                    <div className="contact-details__body--trim">
                      <FontAwesomeIcon icon={faLink} color={'#bfbfbf'} className="item-icon" />
                      <span>Map: </span>
                      <a
                        href={activeRecipient.map}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {activeRecipient.map}
                      </a>
                    </div>

                    <div>
                      <CopyToClipboardButton
                        className='item__copy'
                        value={activeRecipient.map}
                        disabled={!activeRecipient.map}
                        title='Copy map'
                        svgWidth={14}
                        svgHeight={14}
                      />
                    </div>
                  </div>
                </div>
              </>
            }

            {!!activeRecipient.urls.length &&
              <>
                <span className="contact-details__body--divider"></span>

                <div className="contact-details__body--item">
                  {renderGirlUrls(activeRecipient.urls)}
                </div>
              </>
            }
          </div>
        </div>
      }
    </>
  );
}

const mapDispatchToProps = {
  openModal,
};

export default connect(null, mapDispatchToProps)(memo(ContactDetails));