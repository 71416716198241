export default (milliseconds, isClockFormat = false, hour12 = false) => {
  // add 24h if ms < 0 to get correct time
  if (isClockFormat && milliseconds < 0) {
    milliseconds = milliseconds + 3600000 * 24
  }

  const totalSeconds = Math.floor(milliseconds / 1000);
  let hours = Math.floor(totalSeconds / 60 / 60);
  let minutes = Math.floor(totalSeconds / 60) % 60;
  let seconds = totalSeconds % 60;
  let amPm = '';

  if (isClockFormat) {
    hours = hours >= 24 ? hours - 24 : hours;

    if (hour12) {
      amPm = hours < 12 ? " a.m." : " p.m.";
      hours = hours > 12 ? hours - 12 : hours;
      if (hours == 0) {
        hours = 12;
      }
    }

    hours = hours > 9 ? hours + ':' : '0' + hours + ':';
  }
  else {
    hours = hours
      ? hours + ':'
      : '';
  }

  minutes = minutes > 9
    ? minutes + ":"
    : `0${minutes}:`;

  seconds = seconds > 9
    ? seconds
    : '0' + seconds;

  return hours + minutes + seconds + amPm;
}