import React, { useState, useCallback } from "react";
import { connect } from "react-redux";

import API from '../../api/api';
import { selectPrivateRoomIdsByQuery, selectFreeOperatorsIds } from "../../selectors/selectors";
import { classModifier } from "../../utils";

import SearchableList from "../SearchableList/SearchableList";
import OperatorEntity from "../../containers/Sidebar/components/RoomTabs/CreateRoomDialog/OperatorEntity";


const SearchByOperators = ({ setSearchQuery, classPrefix, closeHandle, addModeRoomId, isDialogMode, ...props }) => {
  const [operatorIds, setOperatorIds] = useState([]);

  const handleSubmit = () => {
    if (props.isSessionMode) {
      closeHandle();

      props.handleSubmit(operatorIds[0]);

      return
    }

    if (addModeRoomId) {
      API.addUsersFromRoom(addModeRoomId, operatorIds);

      closeHandle();

      return;
    }

    props.handleSubmit(operatorIds);

    closeHandle();
  };

  const isDisabled = () => {
    if (props.isSessionMode || addModeRoomId) {
      return !operatorIds.length > 0;
    }
    else {
      return props.roomName.trim() && operatorIds.length >= 3
        ? false
        : true;
    }
  };

  const startSearch = useCallback((query) => setSearchQuery(query), []);

  const stopSearch = useCallback(() => setSearchQuery(''), []);

  const loadMore = useCallback(console.log, []);

  const isCommPage = window.location.pathname === '/';

  return (
    <div className={classModifier(classPrefix + '__wrap', [isCommPage && 'comm-page'])}>
      <div className={classPrefix + '__title'}>
        {props.title}
      </div>

      <div
        className={classPrefix + '__close'}
        onClick={closeHandle} />

      <SearchableList
        classPrefix={classPrefix}
        list={props.operators}
        listLimit={20}
        scrollInitialPosition='top'
        loadMore={loadMore}
        listItem={OperatorEntity}
        listItemProps={{
          isDialogMode: isDialogMode,
          isSessionMode: props.isSessionMode,
          operatorIds,
          setOperatorIds,
        }}
        startSearch={startSearch}
        stopSearch={stopSearch}
      />

      <button
        className={classPrefix + '__submit'}
        disabled={isDisabled()}
        onClick={handleSubmit}
      >
        {props.submitText}
      </button>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  operators: ownProps.isSearchByFreeOperators
    ? selectFreeOperatorsIds(state)
    : selectPrivateRoomIdsByQuery(state, ownProps.searchQuery, ownProps.ignoreOperatorsIds),
  user: state.user,
});

export default connect(mapStateToProps)(SearchByOperators);