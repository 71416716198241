import getDateByTimezoneOffset from "./getDateByTimezoneOffset";

export default (booking, userTimezone) => {
  if (!booking) return '#fff';
  // futureBooking
  if (!booking.date) return '#809fff';

  const bookingDurations = {
    '30 minutes': 30 * 60 * 1000,
    '45 minutes': 45 * 60 * 1000,
    '1 hour': 60 * 60 * 1000,
    '90 minutes': 90 * 60 * 1000,
    '2 hours': 2 * 60 * 60 * 1000,
    '3 hours': 3 * 60 * 60 * 1000,
    'Overnight': 12 * 60 * 60 * 1000,
  };

  const bookingStages = {
    sixHours: 6 * 60 * 60 * 1000,
    twoHours: 2 * 60 * 60 * 1000,
    oneHour: 60 * 60 * 1000,
  }

  const dateByTimezone = getDateByTimezoneOffset(userTimezone);
  const bookingDateByTimezone = getDateByTimezoneOffset(userTimezone, booking.date);
  const dateDiff = bookingDateByTimezone - dateByTimezone;

  // if unknown or empty duration
  if (!bookingDurations[booking.duration]) return '#fff';
  // if booking finished
  if (dateDiff + bookingDurations[booking.duration] < 0) return '#fff';

  let bookingColor = '#f0a8d2'; // pink

  if (dateDiff >= bookingStages.sixHours && booking.is_prebooking) {
    bookingColor = '#b380ff'; // purple
  }
  else if (dateDiff >= bookingStages.sixHours) {
    bookingColor = '#32bf45'; // green
  }
  else if (dateDiff <= bookingStages.sixHours && dateDiff >= bookingStages.twoHours) {
    bookingColor = '#99c23a'; // yellow-green
  }
  else if (dateDiff <= bookingStages.twoHours && dateDiff >= bookingStages.oneHour) {
    bookingColor = '#f5aa42'; // orange
  }
  else if (dateDiff <= bookingStages.oneHour && dateDiff >= 0) {
    bookingColor = '#f54242'; // red
  }

  return bookingColor;
}