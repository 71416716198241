import React from 'react'

import './MobileDevicesPlug.scss';

const MobileDevicesPlug = () => (
  <div className="mobile-plug">
    <img
      className="mobile-plug__logo"
      src="/media/logo-commd-110.png"
      alt="CommD Logo"
    />

    <h1 className="mobile-plug__title">
      Looks like you’re on a mobile device
    </h1>
    
    <p className="mobile-plug__text">
      CommD services are not designed for small screens. Please open it on a
      device with bigger screen size e.g. desktop or laptop
    </p>
  </div>
);

export default MobileDevicesPlug;