import React, { useRef } from 'react';

import { useDidMount } from '../../hooks';


const SelectMenuItem = props => {

  const {
    listRef,
    itemIndex,
    updateFocused,
    updateAndClose,
    isScrollToValue,
    selectOption: SelectOption,
    ...selectOptionProps
  } = props;

  const scrollRef = useRef(null);

  useDidMount(() => {
    if(props.isActive && isScrollToValue) {
      listRef.current.scrollTop = scrollRef.current.offsetTop
    }
  });
  
  return (
    <li
      ref={scrollRef}
      className={props.classPrefix + "__item"}
      onMouseEnter={() => updateFocused(itemIndex, props.value)}
      // pass all object in order for onInputChange can get access for all option properties
      onClick={(e) => updateAndClose(e, props.option)}
    >
      <SelectOption {...selectOptionProps} />
    </li>
  )
}

export default SelectMenuItem;