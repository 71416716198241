import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencilAlt,
  faCheck,
  faTimes
} from "@fortawesome/free-solid-svg-icons";

import SvgIcon from "../../../../components/SvgIcon/SvgIcon";
import LazyLoadImage from "../../../../components/LazyLoadImage/LazyLoadImage";
import BusyStatus from "./BusyStatus/BusyStatus";

import {
  selectRoomById,
  selectOperatorActiveSessionsCountByRoomId,
  selectActiveWebrtcConference
} from "../../../../selectors/selectors";

import { onHoldActiveConference, webrtcCallTo } from "../../../../ducks/webrtc";
import { updateActiveChat } from "../../../../ducks/roomChats";
import { removeMissedCallForAll } from '../../../../ducks/missedCalls';
import { isAllObjValuesTrue } from '../../../Navbar/components/UserMenu/UserMenu';

import { getContactAvatar, throttle, Drafts } from "../../../../utils";
import { selectOperatorByRoomId } from "../../../../selectors/selectors";
import { useToggle } from "../../../../hooks";
import { ROOM } from "../../../../config/constants";


const RoomItem = props => {
  const {
    room,
    operator = {},
    isActive,
    isContactOpen,
    style,
    id
  } = props;

  const [isFullMode, setIsFullMode] = useState(true);

  const [isBusyTooltipShowed, setBusyTooltipShowing] = useToggle(false);
  const [isModeTableShowed, toggleModeTableShowed] = useToggle(false);

  useEffect(() => {
    if (operator && operator.mode) {
      setIsFullMode(isAllObjValuesTrue(operator.mode))
    }
  }, [operator.mode]);

  const throttledUpdateActiveContact = throttle(
    () => props.updateActiveChat(room.id),
    300,
    true
  );

  const showDraft = Drafts.getOne(room, props.profileId) && !isContactOpen
    ? <span className="contact-item__draft">
      <FontAwesomeIcon icon={faPencilAlt} title="draft" />
    </span>
    : null;


  const handleRoomClick = e => {
    if (e.currentTarget.className === "contact-item__btn contact-item__btn--room-call") {
      if (props.activeConference) {
        props.onHoldActiveConference(props.activeConference, props.peerConnections, props.localStream);
      };

      props.webrtcCallTo(operator);
      props.removeMissedCallForAll(operator.id, operator.type);
    }

    return throttledUpdateActiveContact();
  };


  const renderCallBtn = () =>
    <>
      {room.chatType === "private"
        && operator.status === "online"
        && !props.isActiveCall
        && (
          <button
            className="contact-item__btn contact-item__btn--room-call"
            onClick={handleRoomClick} >
            <SvgIcon width="20" height="20" icon="phone" fill="#01DF85" title='call' />
          </button>
        )}
    </>

  const showOperatorMode = () => {
    if (room.chatType === 'private' && operator.status !== 'offline' && !isFullMode) {
      return <span className="contact-item__mode">Custom</span>;
    }

    return null;
  };

  const unreadCount = room.unreadCount > 0
    ? <span className={
      room.withImportantUnread
        ? "contact-item__count contact-item__count--important"
        : "contact-item__count"}>
      {room.unreadCount >= 100 ? '99+' : room.unreadCount}
    </span>
    : null;

  const showChatStatus = () => {
    if (room.chatType !== "private") {
      return null;
    }

    let statusColor;

    switch (operator.status) {
      case "busy":
        statusColor = "#F8737F";
        break;
      case "online":
        statusColor = "#01DF85";
        break;
      case "away":
        statusColor = "orange";
        break;
      default:
        statusColor = "grey";
        break;
    }


    return <span
      onMouseEnter={() => setBusyTooltipShowing()}
      onMouseLeave={() => setBusyTooltipShowing()}
      style={{ border: isActive ? "2px solid #fff" : "2px solid #e6ebf1", backgroundColor: statusColor }}
      className="contact-item__status"></span>;
  };

  const renderStylesForWrapper = () => {
    if (isActive) {
      return {
        ...style,
        border: "1px solid #0092f2",
        borderRadius: "4px",
        backgroundColor: "#fcfcfd"
      };
    }
    else {
      return style;
    }
  };

  const renderModeTable = () => {
    if (room.chatType === 'private' && operator.status !== 'offline' && !isFullMode) {
      return (
        <div className="contact-item__mode-table">
          <div className="mode-table__header">

            <div className="mode-table__header--item"></div>
            <div className="mode-table__header--item" title="Client"> Client </div>
            <div className="mode-table__header--item" title="Girl"> Girl </div>
            <div className="mode-table__header--item" title="Agent"> Agent </div>
            <div className="mode-table__header--item" title="Service"> Service </div>
          </div>

          <div className="mode-table__list">
            {Object.keys(operator.mode).map(type => (
              <div className="mode-table__item" key={`operator-${type}`}>
                <div className="mode-table__item--block name">
                  {type}
                </div>

                {Object.keys(operator.mode[type]).map(key => (
                  <div className={`mode-table__item--block ${key}`} key={`operator-${type}-${key}`}>
                    {!!operator.mode[type][key]
                      ? <FontAwesomeIcon icon={faCheck} color="#00A562" />
                      : <FontAwesomeIcon icon={faTimes} color="#f20c0c" />
                    }
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      )
    }
  };

  console.log('RoomItem render', id);

  return (
    <div
      style={renderStylesForWrapper()}
      className="contact-item"
      onClick={handleRoomClick}
    >
      <div
        className="contact-item__img-wrap"
        onMouseEnter={() => toggleModeTableShowed(true)}
        onMouseLeave={() => toggleModeTableShowed(false)}
      >
        {props.operator && showChatStatus()}
        <LazyLoadImage
          src={getContactAvatar(room, ROOM)}
          alt="ava"
          className="contact-item__img" />

      </div>

      {isModeTableShowed &&
        renderModeTable()
      }

      <div className="contact-item__info">
        <h4 className="contact-item__name">
          {showDraft}
          {room.chatTitle}
        </h4>

      </div>

      <div className="contact-item__room-control-wrap">
        {renderCallBtn()}

        {room.chatType === 'private' &&
          <div className='contact-item__sessions-indicator'>
            <SvgIcon
              width="14px"
              height="14px"
              title="sales page"
              icon="puzzle"
              fill='#bfbfbf'
            />
            <span>{props.operatorActiveSessionsCount}</span>
          </div>
        }

        {unreadCount}
      </div>


      {showOperatorMode()}

      {isBusyTooltipShowed && !props.isDialogMode && operator && operator.status === 'busy' &&
        <BusyStatus operatorId={operator.id} />
      }
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  room: selectRoomById(state, ownProps.id),
  operator: selectOperatorByRoomId(state, ownProps.id),
  activeRoomId: state.roomChats.active,
  profileId: state.user.id,
  operatorActiveSessionsCount: selectOperatorActiveSessionsCountByRoomId(state, ownProps.id),
  isActive: state.roomChats.active === ownProps.id,
  isActiveCall: !!Object.keys(state.calls.activeCall).length,
  localStream: state.webrtc.localStream,
  peerConnections: state.webrtc.peerConnections,
  activeConference: selectActiveWebrtcConference(state),
});


const mapDispatchToProps = {
  updateActiveChat,
  removeMissedCallForAll,
  onHoldActiveConference,
  webrtcCallTo,
};

export default connect(mapStateToProps, mapDispatchToProps)(RoomItem);
