import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

import { getContactAvatar } from '../../../../utils';
import { useToggle } from '../../../../hooks';
import API from '../../../../api/api';
import { ADMIN_PAGE_ROOT } from '../../../../config/api-config';
import { openModal, MODAL_TYPES } from '../../../../ducks/activeWindows';
import { NOTIFICATIONS_VOLUME_TYPE } from '../../../../ducks/settings';

import './UserMenu.scss';
import LazyLoadImage from '../../../../components/LazyLoadImage/LazyLoadImage';
import SubMenu from '../../../../components/SubMenu/SubMenu';
import DropWrapper from '../../../../components/DropWrapper/DropWrapper';
import Spinner from '../../../../components/UI/Spinner/Spinner';
import NotificationsVolumeEditor from './components/NotificationsVolumeEditor';


export const isAllObjValuesTrue = (obj) => {
  return Object.values(obj).every(value => value && typeof value === 'object'
    ? isAllObjValuesTrue(value)
    : !!value === true
  );
};


const UserMenu = ({ user, ...props }) => {
  const [isUserMenuShown, toggleUserMenu] = useToggle(false);
  const [pending, setPending] = useState(false);
  const [isFullMode, setIsFullMode] = useState(false);

  const userMenuRef = useRef();

  useEffect(() => {
    if (user.mode) {
      setIsFullMode(isAllObjValuesTrue(user.mode))
    }
  }, [user.mode]);

  const renderAwayButton = () => {
    if (pending) {
      return <Spinner spinnerSize={10} />
    }
    else {
      switch (user.status) {
        case "online":
          return 'Set Away Status';
        case "away":
          return 'Set Online Status';
        default:
          return 'You Busy now';
      }
    }
  };

  const changeProfileStatus = () => {
    if (user.status === 'busy') {
      return;
    }

    if (user.status === 'online') {
      setPending(true);

      return API.changeBusyStatus('away')
        .then(() => setPending(false))
        .catch(console.error)
    }

    setPending(true);
    return API.changeBusyStatus('online')
      .then(() => setPending(false))
      .catch(console.error)
  };

  const logoutUser = () => {
    props.setPagePending(true);

    return API.logoutUser()
      .catch(console.dir)
      .finally(() => {

        delete localStorage.isLoggedIn;
        delete localStorage.currentUserId;

        window.location = '/client/login';
      })
  };


  const generateSubMenuItems = () => ([
    {
      title: <NotificationsVolumeEditor type={NOTIFICATIONS_VOLUME_TYPE.CALL} />,
      notCloseOnClick: true,
      action: () => null,
    },
    {
      title: <NotificationsVolumeEditor type={NOTIFICATIONS_VOLUME_TYPE.SMS} />,
      notCloseOnClick: true,
      action: () => null,
    },
    {
      title: <NotificationsVolumeEditor type={NOTIFICATIONS_VOLUME_TYPE.BOOKING} />,
      notCloseOnClick: true,
      action: () => null,
    },
    {
      title: renderAwayButton(),
      notCloseOnClick: true,
      action: changeProfileStatus,
    },
    {
      title: 'Settings',
      action: () => props.openModal(MODAL_TYPES.userSettings),
    },
    {
      title: 'Go to Admin Page',
      action: () => window.location.href = ADMIN_PAGE_ROOT,
    },
    {
      title: 'Log out',
      action: logoutUser,
    },
  ]);

  const setProfileStatusColor = () => {
    switch (user.status) {
      case "online":
        return '#01DF85';
      case "away":
        return '#F2CC0C';
      case "offline":
        return 'grey';
      default:
        return '#F8737F';
    }
  };

  const showProfileMode = () => {
    if (user.status !== 'offline' && !isFullMode) {
      return <span className="user-menu__mode">Custom</span>;
    }

    return null;
  };

  return (
    <>
      <div
        className="user-menu"
        onMouseDown={() => toggleUserMenu(true)}
      >
        <span
          style={{ backgroundColor: setProfileStatusColor() }}
          className="user-menu__busy-status"
        />
        <div className="user-menu__img-wrap">
          <LazyLoadImage
            src={getContactAvatar(user)}
            className="user-menu__img" />
        </div>

        {showProfileMode()}

        <span className="user-menu__name">{user.username}</span>

        <FontAwesomeIcon
          icon={faChevronDown}
          color={isUserMenuShown ? '#0092f2' : '#777'}
          title="settings"
          className='user-menu__settings-drop'
          size="xs"
        />

        {isUserMenuShown &&
          <DropWrapper
            className='user-menu__wrapper'
            dropWrapperRef={userMenuRef}
            closeDropWrapper={toggleUserMenu}>
            <div
              ref={userMenuRef}
              className="user-menu__menu-wrapper"
            >
              <SubMenu
                classPrefix="user-menu"
                items={generateSubMenuItems()}
                closeSubMenu={toggleUserMenu} />
            </div>
          </DropWrapper>
        }
      </div>
    </>
  );
};


const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = {
  openModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserMenu));