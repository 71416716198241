import React, { useState, useRef } from 'react';
import { connect } from "react-redux";

import API from '../../../../api/api';
import { useDidMount } from "../../../../hooks";
import { sendMessage } from "../../../../ducks/clientChats";
import { CHAT_TYPES } from '../../../../config/constants';

import CallTimer from "../../../../components/CallItem/CallTimer";


const VoiceMessageRecorder = props => {
  const [noMicro, setNoMicro] = useState(false);

  const [recorder, setRecorder] = useState({
    record: false,
    recorder: null,
    mediaStream: null
  });

  const blobDuration = useRef();
  const isImmediatelySend = useRef();

  useDidMount(() => {
    navigator.mediaDevices.getUserMedia({ audio: true, video: false })
      // check audio stream and stop it
      .then(stream => console.log(stream.getTracks().forEach((track) => track.stop())))
      // If user haven't microphone
      .catch(() => setNoMicro(true));
  });

  // useEffect(() => {
  //   navigator.mediaDevices.getUserMedia({ audio: true, video: false })
  //     .then(() => console.log('User has a micro'))
  //     .catch(() => setNoMicro(true)); // If user haven't microphone
  // }, []);

  const setBlobDuration = (duration) => {
    blobDuration.current = duration;
  };

  const setIsImmediatelySend = (flag) => {
    isImmediatelySend.current = flag;
  };

  const startRecord = () => {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        const mimeType = MediaRecorder.isTypeSupported('audio/webm') && 'audio/webm';

        const mediaRecorder = new MediaRecorder(
          stream,
          mimeType
            ? { mimeType }
            : null
        );

        const audioChunks = [];

        mediaRecorder.start();

        mediaRecorder.addEventListener("dataavailable", event => {
          audioChunks.push(event.data);
        });

        mediaRecorder.addEventListener("stop", () => {
          const audioBlob = new Blob(audioChunks, {
            type: 'audio/webm'
          });

          const duration = blobDuration.current;

          if (isImmediatelySend.current) {
            const messageObj = {
              text: '',
              voiceMsg: {
                blob: audioBlob,
                duration: duration
              },
              shortcuts: '',
              sharedMsgId: '',
              sharedVoiceMsg: '',
              sharedContactId: '',
              channel: props.activeRecipient.default_channel,
            };

            setIsImmediatelySend(false);
            props.chatType === CHAT_TYPES.ROOM
              ? API.sendMessageToChat(messageObj, props.activeRecipient.id)
              : props.sendMessage(messageObj, props.activeRecipient);
          }
          else {
            props.updateVoiceMsg(audioBlob, duration);
          }
        });

        setRecorder({
          record: true,
          recorder: mediaRecorder,
          mediaStream: stream
        });
      })
      .catch(err => console.dir(err));
  };

  const stopRecord = (sendRightNow) => {
    if (sendRightNow === true) {
      setIsImmediatelySend(true);
    }

    setRecorder({
      record: false,
      recorder: null,
      mediaStream: null
    });

    recorder.mediaStream.getTracks()[0].stop();
    recorder.recorder.stop();
  };

  const onTick = tick => {
    if (tick >= 600000) {
      stopRecord();
    }
  };

  if (noMicro) {
    return null;
  }

  return (
    <div className='voice-message__record-wrap'>
      <button
        type="button"
        onClick={recorder.record ? null : startRecord}
        className='voice-message__record'
        disabled={props.disabled}
      >
        {recorder.record && <div className='voice-message__record-bg'></div>}

        <svg width='16px' height='16px' fill={recorder.record ? '#fff' : '#808080'}
          xmlns='http://www.w3.org/2000/svg' viewBox='0 0 475 475'>
          <path d='M238 329c25 0 46-9 64-27s27-39 27-64V91c0-25-9-46-27-64a88 88 0 0 0-64-27c-26 0-47 9-65 27a88 88 0 0 0-27 64v147c0 25 9 46 27 64s39 27 65 27z' />
          <path d='M397 188c-4-3-8-5-13-5s-10 2-13 5c-4 4-6 8-6 13v37c0 35-12 65-37 90s-55 37-90 37c-36 0-66-12-91-37s-37-55-37-90v-37c0-5-2-9-6-13-3-3-8-5-13-5s-9 2-12 5c-4 4-6 8-6 13v37c0 42 14 78 42 109s63 49 104 54v38h-73c-5 0-9 1-13 5-3 4-5 8-5 13s2 9 5 13c4 3 8 5 13 5h183c5 0 9-2 13-5 3-4 5-8 5-13s-2-9-5-13c-4-4-8-5-13-5h-73v-38c41-5 76-23 104-54s42-67 42-109v-37c0-5-2-9-5-13z' />
        </svg>
      </button>

      {recorder.record &&
        <div className='voice-message__info'>
          <div className='voice-message__time'>
            <div className='voice-message__indicator'></div>

            <CallTimer
              timer={new Date()}
              onUpdate={onTick}
              onStop={setBlobDuration}
            />
          </div>

          <button
            type="button"
            className='voice-message__send'
            onClick={() => stopRecord(true)}
          >
            <svg fill='#0092F2' width='14' height='14' viewBox='0 0 536 536'>
              <path d='M0 497l536-229L0 38v179l383 51L0 319z' />
            </svg>
          </button>

          <button
            type="button"
            className='voice-message__stop'
            onClick={stopRecord}
          ></button>
        </div>
      }
    </div>
  );
};

const mapDispatchToProps = {
  sendMessage: sendMessage,
};

export default connect(null, mapDispatchToProps)(VoiceMessageRecorder);