import React, { useEffect, useState, memo, useRef } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { faUndo } from '@fortawesome/free-solid-svg-icons';

import useToggle from "../../../../hooks/useToggle";
import { usePrevious } from '../../../../hooks';
import { selectRecentTab } from '../../../../selectors/selectors';

import ChatTab from './ChatTab';
import DropWrapper from "../../../../components/DropWrapper/DropWrapper";
import SvgIcon from '../../../../components/SvgIcon/SvgIcon';


const ChatTabs = (props) => {
  const {
    tabs,
    primaryTabs,
    moreTabs,
    recentTabs,
  } = props;

  const [isOpenMoreTabs, toggleOpenMoreTabs] = useToggle(false);
  const [isDragging, toggleDragging] = useToggle(false);

  const [animationClass, setAnimationClass] = useState('');

  const moreTabsListRef = useRef();

  const prevIsUnreadInMore = usePrevious(props.isUnreadInMore);

  useEffect(() => {
    if (prevIsUnreadInMore !== props.isUnreadInMore && props.isUnreadInMore) {
      setAnimationClass('shake-me');
    }

    if (prevIsUnreadInMore !== props.isUnreadInMore && !props.isUnreadInMore) {
      setAnimationClass('');
    }
  }, [props.isUnreadInMore]);

  useEffect(() => {
    if (isOpenMoreTabs) {
      toggleOpenMoreTabs(false);
    }
  }, [props.activeRecipient]);

  const onUpdateActiveContact = chatId => {
    let fromMoreTabs = false;

    let id = typeof chatId === 'number'
      ? chatId
      : chatId.id;

    if (~moreTabs.indexOf(id)) {
      fromMoreTabs = true;
    };

    props.updateActiveContact(chatId, fromMoreTabs);
  }

  const onOpenRecentTab = () => {
    if (recentTabs && !recentTabs.length) return;

    props.onOpenRecentTab();
    onUpdateActiveContact(props.recentTab);
  }

  const onDragEnd = (result) => {
    toggleDragging();

    if (result.destination === null) return;

    const source = result.source.index;
    const destination = result.destination.index;

    if (destination === source) return;

    const newList = [...tabs];

    if (destination < source) { // Is Backward Movement
      newList.splice(destination, 0, tabs[source]);
      newList.splice(source + 1, 1);
    }
    else { // Is Forward Movement
      newList.splice(destination + 1, 0, tabs[source]);
      newList.splice(source, 1);
    }

    props.setTabs(newList);
  };

  const getShowMoreBtnClassName = () => {
    let className = 'chat__show-more-contacts';

    if (props.isUnreadInMore) {
      className += ' chat__show-more-contacts--have-notread';
    }
    if (isDragging) {
      className += ' chat__show-more-contacts--drag-start';
    }

    return className;
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd} onBeforeDragStart={toggleDragging}>
        <Droppable
          droppableId={1}
          direction='horizontal'
        >
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              <ul
                id="tabs-list"
                className="chat__contacts-list"
                ref={props.innerRef}
              >
                {tabs.map(tab => (
                  <ChatTab
                    key={tab}
                    tab={tab}
                    type={props.type}
                    activeRecipient={props.activeRecipient}
                    updateActiveContact={onUpdateActiveContact}
                    removeContactTab={props.removeContactTab}
                    isTabsDraggable={props.isTabsDraggable}
                    style={primaryTabs.indexOf(tab) === -1
                      ? {
                        position: 'absolute',
                        left: '-9999px'
                      }
                      : null}
                    index={primaryTabs.indexOf(tab)}
                    withUnfixedTab={props.withUnfixedTab}
                    unfixedTab={props.unfixedTab}
                    fixTab={props.fixTab}
                  />
                ))}

                {!!moreTabs.length &&
                  <button
                    data-animationclass={animationClass}
                    className={getShowMoreBtnClassName()}
                    onClick={isOpenMoreTabs ? null : toggleOpenMoreTabs}
                  >
                    <span>
                      {moreTabs.length}
                    </span>

                    <svg width='8' height='8' fill='#0092F2'
                      className='chat__show-more-contacts-icon'
                      viewBox="0 0 371 371"
                    >
                      <path d="M293 25L269 0 78 185l191 186 24-25-165-161z" />
                    </svg>
                  </button>
                }

                {props.isShowUndoTabs && !!recentTabs.length &&
                  <button
                    className='chat-restore chat__restore'
                    onClick={onOpenRecentTab}
                  >
                    <SvgIcon
                      icon={faUndo}
                      type="fa"
                      width="16"
                      height="16"
                    />
                  </button>
                }

                {provided.placeholder}
              </ul>
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {isOpenMoreTabs && !!moreTabs.length &&
        <DropWrapper
          dropWrapperRef={moreTabsListRef}
          closeDropWrapper={toggleOpenMoreTabs}
        >
          <ul
            className="chat__contacts-list--more"
            ref={moreTabsListRef}
          >
            {moreTabs.map(tab => (
              <ChatTab
                tab={tab}
                key={tab}
                type={props.type}
                activeRecipient={props.activeRecipient}
                updateActiveContact={onUpdateActiveContact}
                removeContactTab={props.removeContactTab}
                isMoreTab
                withUnfixedTab={props.withUnfixedTab}
                unfixedTab={props.unfixedTab}
                fixTab={props.fixTab}
              />
            ))}
          </ul>
        </DropWrapper>
      }
    </>
  )
}

const mapStateToProps = (state, ownProps) => ({
  recentTab: selectRecentTab(state, ownProps.type, ownProps.recentTabs[ownProps.recentTabs.length - 1]),
});

export default connect(mapStateToProps)(ChatTabs);