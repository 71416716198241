import API from '../api/api';
import { CONTACT_TYPES } from '../config/constants';
import { addToEntitiesIfMissing, isEmptyObj } from '../utils';
// import { addToEntitiesIfMissing } from "../utils";
import { normalize } from '../utils/normalizeContacts';
import {
  CREATE_CLIENT,
  CREATE_GIRL,
  UPDATE_CLIENT,
  UPDATE_GIRL,
  REMOVE_CLIENT,
  REMOVE_GIRL,
} from './contacts';

const duckName = 'addressBook/';

export const GET_CONTACTS_PENDING = duckName + 'GET_CONTACTS_PENDING';
export const GET_CONTACTS_LIST = duckName + 'GET_CONTACTS_LIST';
export const UPDATE_CONTACTS_LIST = duckName + 'UPDATE_CONTACTS_LIST';

export const UPDATE_ACTIVE_CONTACT_ID = duckName + 'UPDATE_ACTIVE_CONTACT_ID';

export const GET_TAGS_PENDING = duckName + 'GET_TAGS_PENDING';
export const GET_TAG_LIST = duckName + 'GET_TAG_LIST';
export const UPDATE_TAG_LIST = duckName + 'UPDATE_TAG_LIST';

export const FILTER_CONTACTS_LIST = duckName + 'FILTER_CONTACTS_LIST';
export const UPDATE_FILTERED_CONTACTS_LIST = duckName + 'UPDATE_FILTERED_CONTACTS_LIST';

export const UPDATE_TAGS_FILTER = duckName + 'UPDATE_TAGS_FILTER';
export const CHANGE_CONTACT_TYPE = duckName + 'CHANGE_CONTACT_TYPE';
export const CHANGE_CONTACT_SORT = duckName + 'CHANGE_CONTACT_SORT';
export const TOGGLE_INCLUDE_UNSAVED = duckName + 'TOGGLE_INCLUDE_UNSAVED';
export const UPDATE_SEARCH = duckName + 'UPDATE_SEARCH';

export const TOGGLE_CREATE_CONTACT_FORM = duckName + 'TOGGLE_CREATE_CONTACT_FORM';

export const ADR_BOOK_LIMIT = 40;

export const getContactsForAdrBook = (config) => dispatch => {
  if (!config.offset) {
    dispatch({
      type: GET_CONTACTS_PENDING
    });
  }

  return API.getContactsForAdrBook(config)
    .then(res => {
      dispatch({
        type: config.offset ? UPDATE_CONTACTS_LIST : GET_CONTACTS_LIST,
        payload: {
          ...normalize(res.data.contacts),
          totalCount: res.data.totalCount,
        }
      });
    })
    .catch(console.error);
}

export const filterContactsForAdrBook = (config) => dispatch => {
  if (!config.offset) {
    dispatch({
      type: GET_CONTACTS_PENDING
    });
  }

  return API.getContactsForAdrBook(config)
    .then(res => {
      dispatch({
        type: config.offset ? UPDATE_FILTERED_CONTACTS_LIST : FILTER_CONTACTS_LIST,
        payload: {
          ...normalize(res.data.contacts),
          totalCount: res.data.totalCount,
        }
      });
    })
    .catch(console.error);
}

export const getTagsForAdrBook = (config) => dispatch => {
  dispatch({
    type: GET_TAGS_PENDING
  });

  return API.getAdrBookTags(config)
    .then(res => {
      dispatch({
        type: GET_TAG_LIST,
        payload: {
          ...normalize(res.data.tags),
          allContacts: res.data.allContacts
        }
      });
    })
    .catch(console.error);
}

export const updateAdrBookTags = (data) => dispatch => {
  dispatch({
    type: UPDATE_TAG_LIST,
    payload: {
      ...normalize(data.updatedTags),
      allContacts: data.allContactsCount
    }
  })
}

export const updateActiveAdrBookContactId = (contactId) => dispatch => {
  dispatch({
    type: UPDATE_ACTIVE_CONTACT_ID,
    payload: contactId
  });
}

export const toggleCreateContactForm = (show) => dispatch => {
  dispatch({
    type: TOGGLE_CREATE_CONTACT_FORM,
    payload: show
  });
}

export const changeContactType = (type) => dispatch => {
  dispatch({
    type: CHANGE_CONTACT_TYPE,
    payload: type
  });
}

export const changeContactSorting = (sortBy) => dispatch => {
  dispatch({
    type: CHANGE_CONTACT_SORT,
    payload: sortBy
  });
}

export const updateTagsFilter = (newTag) => dispatch => {
  dispatch({
    type: UPDATE_TAGS_FILTER,
    payload: newTag
  });
}

export const updateSearch = (query) => dispatch => {
  dispatch({
    type: UPDATE_SEARCH,
    payload: query
  });
}

export const toggleIncludeUnsaved = () => dispatch => {
  dispatch({
    type: TOGGLE_INCLUDE_UNSAVED
  });
}


const initialState = {
  contactsPending: false,
  entities: {},
  ids: [],
  active: null,
  totalCount: null,

  tags: {
    ids: [],
    entities: {},
    active: {},
    pending: true,
    allContactsCount: null,
  },

  contactType: 0,
  search: '',
  sortBy: '',
  includeUnsaved: 0,

  auxiliaryIds: [],

  isNewContactCreation: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CONTACTS_PENDING: {
      return {
        ...state,
        contactsPending: true,
      }
    }

    case GET_CONTACTS_LIST: {
      return {
        ...state,
        contactsPending: false,
        // entities: addToEntitiesIfMissing(
        //   state.entities,
        //   action.payload.entities,
        //   "id"
        // ),
        ids: action.payload.result,
        totalCount: action.payload.totalCount,
        isNewContactCreation: false,
        auxiliaryIds: state.auxiliaryIds.length ? [] : state.auxiliaryIds
      }
    }

    case UPDATE_CONTACTS_LIST: {
      return {
        ...state,
        // entities: addToEntitiesIfMissing(
        //   state.entities,
        //   action.payload.entities,
        //   "id"
        // ),
        ids: [...state.ids, ...action.payload.result],
        totalCount: action.payload.totalCount
      }
    }

    case FILTER_CONTACTS_LIST: {
      return {
        ...state,
        contactsPending: false,
        // entities: addToEntitiesIfMissing(
        //   state.entities,
        //   action.payload.entities,
        //   "id"
        // ),
        auxiliaryIds: action.payload.result,
        totalCount: action.payload.totalCount,
        isNewContactCreation: false,
        ids: state.ids.length ? [] : state.ids,
      }
    }

    case UPDATE_FILTERED_CONTACTS_LIST: {
      return {
        ...state,
        // entities: addToEntitiesIfMissing(
        //   state.entities,
        //   action.payload.entities,
        //   "id"
        // ),
        auxiliaryIds: [...state.auxiliaryIds, ...action.payload.result],
        totalCount: action.payload.totalCount
      }
    }

    case CHANGE_CONTACT_TYPE: {
      if (action.payload === state.contactType) return state;

      let updatedActiveTags = state.tags.active;

      if (action.payload !== 0 && !isEmptyObj(updatedActiveTags)) {
        updatedActiveTags = {}
      }

      return {
        ...state,
        contactType: action.payload,
        tags: {
          ...state.tags,
          active: updatedActiveTags
        }
      }
    }

    case CHANGE_CONTACT_SORT: {
      if (action.payload === state.sortBy) return state;

      return {
        ...state,
        sortBy: action.payload
      }
    }

    case UPDATE_ACTIVE_CONTACT_ID: {
      if (action.payload === state.active) return state;

      return {
        ...state,
        active: action.payload,
        isNewContactCreation: false,
      }
    }

    case UPDATE_TAGS_FILTER: {
      let updatedActiveTags = { ...state.tags.active };

      // clear tags filter
      if (!action.payload) {
        updatedActiveTags = {};
      }
      // add new tag or remove if exist
      else {
        if (updatedActiveTags[action.payload]) {
          delete updatedActiveTags[action.payload];
        }
        else {
          updatedActiveTags[action.payload] = true;
        }
      }
      return {
        ...state,
        tags: {
          ...state.tags,
          active: updatedActiveTags
        }
      }
    }

    case GET_TAGS_PENDING: {
      return {
        ...state,
        tags: {
          ...state.tags,
          pending: true
        }
      }
    }

    case GET_TAG_LIST: {
      return {
        ...state,
        tags: {
          ...state.tags,
          pending: false,
          ids: action.payload.result,
          entities: addToEntitiesIfMissing(
            state.tags.entities,
            action.payload.entities,
            "id"
          ),
          allContactsCount: action.payload.allContacts,
        }
      }
    }

    case UPDATE_TAG_LIST: {
      const newIds = action.payload.result.filter(id => state.tags.ids.indexOf(id) < 0);
      const updatedIds = newIds.length
        ? [...state.tags.ids, newIds]
        : state.tags.ids;

      return {
        ...state,
        tags: {
          ...state.tags,
          ids: updatedIds,
          entities: {
            ...state.tags.entities,
            ...action.payload.entities
          },
          allContactsCount: action.payload.allContacts,
        }
      }
    }

    case UPDATE_SEARCH: {
      return {
        ...state,
        search: action.payload
      }
    }

    case TOGGLE_INCLUDE_UNSAVED: {
      return {
        ...state,
        includeUnsaved: +!state.includeUnsaved
      }
    }

    case TOGGLE_CREATE_CONTACT_FORM: {
      if (action.payload === state.isNewContactCreation) return state;

      return {
        ...state,
        active: null,
        isNewContactCreation: action.payload,
      }
    }

    case REMOVE_CLIENT:
    case REMOVE_GIRL: {
      const idsKey = state.auxiliaryIds.length ? 'auxiliaryIds' : 'ids';

      let updatedIds = state.auxiliaryIds.length ? state.auxiliaryIds : state.ids;
      let updatedActive = state.active;

      if (updatedIds.length) {
        let idx = updatedIds.indexOf(action.payload);

        if (idx !== -1) {
          updatedIds = [
            ...updatedIds.slice(0, idx),
            ...updatedIds.slice(idx + 1)
          ]
        }
      }
      if (updatedActive = action.payload) {
        updatedActive = null;
      }

      return {
        ...state,
        [idsKey]: updatedIds,
        active: updatedActive
      }
    }
    case UPDATE_CLIENT:
    case UPDATE_GIRL: {
      if (!action.payload.isTypeChanged) return state;

      const updatedContact = action.payload.ids
        ? action.payload.entities[action.payload.ids[0]]
        : action.payload.entities[action.payload.pinIds[0]];

      const isNeedToDeleteIdFromState = state.contactType !== 0 && (
        (state.contactType === CONTACT_TYPES.CLIENT && updatedContact.type !== CONTACT_TYPES.CLIENT)
        || (state.contactType === CONTACT_TYPES.GIRL && updatedContact.type === CONTACT_TYPES.CLIENT)
      )

      if (!isNeedToDeleteIdFromState) return state;

      const idsKey = state.auxiliaryIds.length ? 'auxiliaryIds' : 'ids';

      let updatedIds = state.auxiliaryIds.length ? state.auxiliaryIds : state.ids;

      if (updatedIds.length) {
        let idx = updatedIds.indexOf(updatedContact.id);

        if (idx !== -1) {
          updatedIds = [
            ...updatedIds.slice(0, idx),
            ...updatedIds.slice(idx + 1)
          ]
        }
      }

      return {
        ...state,
        [idsKey]: updatedIds,
      }
    }

    default:
      return state;
  }
}