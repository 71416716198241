import React, { useState, useCallback } from "react";

import API from "../../api/api";
import { DIVA_GIRLS_LIMIT } from "../../ducks/divaGirls";
import { debounce } from "../../utils";
import { useDidMount } from "../../hooks";

import './ProfilesListForEdit.scss';
import ProfileCardForEdit from "./components/ProfileCardForEdit";
import SearchableList from "../SearchableList/SearchableList";

const ProfilesListForEdit = props => {

  const [isLoading, setIsLoading] = useState(false);
  const [nextPaginationPart, setNextPaginationPart] = useState(2);
  const [allParts, setAllParts] = useState(null);
  const [girlsList, setGirlsList] = useState({
    query: '',
    list: [],
    isOpen: false,
  });

  useDidMount(() => {
    setIsLoading(true);

    API.getDivaGirls({ limit: DIVA_GIRLS_LIMIT, part: 1, search: props.query })
      .then(res => {
        setAllParts(res.data.pagination.parts);
        setGirlsList({ ...girlsList, list: res.data.result });
      })
      .catch(console.log)
      .finally(() => setIsLoading(false));
  });

  const handleSearch = (query) => {
    setIsLoading(true);

    API.getDivaGirls({ limit: DIVA_GIRLS_LIMIT, part: 1, search: query })
      .then(res => {  
        setNextPaginationPart(res.data.pagination.part + 1);
        setAllParts(res.data.pagination.parts);
        setGirlsList({ ...girlsList, query, list: res.data.result });
      })
      .catch(() => setGirlsList({ ...girlsList, list: [] }))
      .finally(() => setIsLoading(false));
  };

  const handleSearchDebounce = useCallback(
    debounce((query) => {
      handleSearch(query)
    }, 300),
    []
  );

  const startSearchDivaGirls = (query) => {
    handleSearchDebounce(query);
  };

  const loadMoreDivaGirls = () => {

    const localParams = {
      limit: DIVA_GIRLS_LIMIT,
      search: girlsList.query,
      part: nextPaginationPart
    }

    API.getDivaGirls(localParams)
      .then(res => {

        const nextPart = res.data.pagination.part + 1;

        setNextPaginationPart(nextPart);
        setGirlsList({ ...girlsList, list: [...girlsList.list, ...res.data.result] });
      })
      .catch(console.log);
  };

  const loadMore = useCallback(() => loadMoreDivaGirls(), [girlsList]);
  const startSearch = useCallback((query) => startSearchDivaGirls(query), [girlsList.isOpen]);
  const stopSearch = useCallback((query) => startSearchDivaGirls(query), [girlsList.isOpen]);

  if(props.isHideEmptyResult && (isLoading || !girlsList.list.length)) {
    return null;
  }

  return (
    <div className="profiles-for-edit-wrap">
      <SearchableList 
        classPrefix="profiles-for-edit"
        list={girlsList.list}
        query={props.query}
        hasMore={nextPaginationPart <= allParts}
        initialQuery={girlsList.query}
        listLimit={DIVA_GIRLS_LIMIT}
        scrollInitialPosition='top'
        loadMore={loadMore}
        listItem={ProfileCardForEdit}
        listItemProps={{
          onChangeGirl: props.onChangeGirl
        }}
        isHideInput={props.isHideInput}
        startSearch={startSearch}
        listLoadPending={isLoading}
        stopSearch={stopSearch}
      />

      {props.isShowCloseBtn && 
        <button 
          className="profiles-for-edit__close-btn" 
          onClick={() => props.toggleChangeGirlMode(false)}
        >
        </button>
      }
    </div>
  );
};

export default ProfilesListForEdit;