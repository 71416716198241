import React, { useEffect, useState } from 'react';
import { Scrollbars } from "react-custom-scrollbars";

import { useToggle } from '../../hooks';

import './MainPage.scss';
import '../SalesPage/SalesPage.scss';
import Sidebar from '../../containers/Sidebar/Sidebar';
import CallsSection from '../../containers/CallsSection/CallsSection';
import ContactsTabs from '../../containers/Sidebar/components/ContactsTabs/ContactsTabs';
import ClientChat from '../../containers/Chat/ClientChat';
import GirlChat from '../../containers/Chat/GirlChat';
import PublicChat from '../../containers/Chat/PublicChat';
import SidebarHeader from '../../containers/Sidebar/components/SidebarHeader/SidebarHeader';
import SessionsAccordion from '../../containers/SessionsAccordion.js/SessionsAccordion';
import RoomTabs from '../../containers/Sidebar/components/RoomTabs/RoomTabs';
import GirlTabs from '../../containers/Sidebar/components/GirlTabs/GirlTabs';
import SplitPaneLS from '../../components/SplitPaneLS/SplitPaneLS';
import NotificationsSection from '../../containers/NotificationsSection/NotificationsSection';

const splitPaneStyles = {
  paddingLeft: "15px",
  paddingRight: "15px",
};

const MainPage = () => {
  const [isShowCallSection, toggleCallSection] = useToggle(false);

  const getWidthPane = () => {
    const width = (window.innerWidth - 764) / 2;
    return (width > 400) ? 400 : width
  }

  const [paneMaxWidth, setPaneMaxWidth] = useState(getWidthPane());

  useEffect(() => {
    const handleResize = () => {
      setPaneMaxWidth(getWidthPane())
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [])

  return (
    <main className="main-page__container">
      <SplitPaneLS
        id="main-vertical"
        split="vertical"
        minSize={235}
        maxSize={paneMaxWidth}
        defaultSize={235}
        style={{ paddingLeft: 0 }}
      >
        <Scrollbars autoHide>
          <Sidebar
            id="left-sidebar"
            style={{ width: '100%' }}
            header={() => <SidebarHeader type='Clients' />}
          >
            <ContactsTabs />
            <SessionsAccordion />
          </Sidebar>
        </Scrollbars>

        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            zIndex: '2',
            position: 'relative',
          }}>
          <SplitPaneLS
            id="main-second-vertical"
            split="vertical"
            primary="second"
            minSize={235}
            maxSize={paneMaxWidth}
            defaultSize={235}
          >
            <SplitPaneLS
              id="call-section"
              split="horizontal"
              minSize={200}
              maxSize={350}
              defaultSize={310}
              pane1ClassName={!isShowCallSection ? "pane--close" : ""}
              style={splitPaneStyles}
            >

              <CallsSection
                isShowCallSection={isShowCallSection}
                toggleCallSection={toggleCallSection}
              />

              <section className="main-page__chats-container">

                <div className="main-page__chats">
                  <ClientChat title="Conversations with Clients" />

                  <PublicChat title="Public Chat" className='public-chat' />

                  <GirlChat title="Conversations with Girls" />
                </div>

                <div className="main-page__notifications">
                  <span className="main-page__notifications--divider"/>
                  
                  <NotificationsSection/>
                </div>

              </section>

            </SplitPaneLS>

            <div className='right-sidebar'>
              <Scrollbars autoHide>
                <Sidebar
                  id="right-sidebar-top"
                  style={{ width: '100%' }}
                  header={() => <SidebarHeader type='Girls' />}
                >
                  <GirlTabs />
                  <></>
                </Sidebar>

                <Sidebar
                  id="right-sidebar-bottom"
                  defaultSize={330}
                  style={{
                    width: '100%',
                    position: 'relative',
                    zIndex: 3,
                  }}
                >
                  <RoomTabs />
                  <></>
                </Sidebar>
              </Scrollbars>
            </div>
          </SplitPaneLS>
        </div>
      </SplitPaneLS>
    </main>
  );
};

export default MainPage;