import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { selectFreeOperators } from '../../selectors/selectors';
import { MODAL_TYPES } from '../../ducks/activeWindows';


const CallTransferForm = ({ transferType, ...props }) => {
  const [transferReason, setTransferReason] = useState('');
  const [operatorId, setOperatorId] = useState(null);

  useEffect(() => {
    document.addEventListener('click', closeIfClickedOutside);

    return () => document.removeEventListener('click', closeIfClickedOutside);
  }, []);

  const closeIfClickedOutside = e => {
    if (!e.target.closest('.call-transfer')) {
      props.onClose();
    }
  }

  const transferCall = (e) => {
    e.preventDefault();

    const info = { reason: transferReason, operatorId };

    if (transferType === 'cold') {
      props.createTransferedCall(props.call, info);

      if (props.activeFullScreen) {
        props.closeModal(MODAL_TYPES.fullScreenCall);
      }
    }
    else if (transferType === 'warm') {
      props.createWarmTransferCall(props.call, info);

      if (props.activeFullScreen) {
        props.closeModal(MODAL_TYPES.fullScreenCall);
      }
    }
    props.onClose();
  }

  return (
    <form
      className="call-transfer"
      onSubmit={transferCall}>
      <h5 className="call-transfer__title">Transferring call</h5>

      <ol className="call-transfer__list">
        <li className="call-transfer__item">
          Fill a transfer message
          <textarea
            className="call-transfer__description"
            onChange={e => setTransferReason(e.target.value)}
            maxLength="140"
            value={transferReason} />
        </li>


        <li className="call-transfer__item">
          And than select operator for transfer:

          {props.operators.length
            ? <select
              defaultValue=""
              onChange={e => setOperatorId(e.target.value)}>

              <option value="" disabled>choose a user </option>

              {props.operators.map(operator =>
                <option
                  key={operator.id}
                  value={operator.id}>
                  {operator.username}
                  {operator.mode === 'TEXT'
                    ? '(TEXT MODE)'
                    : null
                  }
                </option>
              )}
            </select>
            : <b> No free operators</b>
          }
        </li>
      </ol>

      <footer className="call-transfer__footer">
        <button
          className="call-transfer__btn"
          type="submit"
          disabled={!operatorId}
          onClick={transferCall}>
          Transfer
        </button>

        <button
          className="call-transfer__btn"
          type="button"
          onClick={props.onClose}>
          Cancel
        </button>
      </footer>
    </form>
  );
}

const mapStateToProps = state => ({
  operators: selectFreeOperators(state)
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(CallTransferForm);