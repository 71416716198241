import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import SplitPane from "react-split-pane";

import { LS } from '../../utils';

const SplitPanesSizeLS = {
  getAll(userId) {
    const sizes = LS.getItem('splitPanesSize', userId);

    return (sizes) || {};
  },
  getOne(paneId, userId) {
    const sizes = this.getAll(userId);

    return sizes[paneId] ? sizes[paneId] : null;
  },
  update(paneId, newValue, userId) {
    const sizes = this.getAll(userId);

    sizes[paneId] = newValue;

    LS.setItem('splitPanesSize', sizes, userId);
  },
}

const SplitPaneLS = ({ children, userId, defaultSize, id, ...props }) => {
  console.log('SplitPaneLS Render');

  const defaultPanesSize = SplitPanesSizeLS.getOne(id, userId) || defaultSize;

  const onDragFinished = useCallback((newSize) => {
    id && SplitPanesSizeLS.update(id, newSize, userId);
  }, []);

  return (
    <SplitPane
      {...props}
      defaultSize={defaultPanesSize}
      onDragFinished={onDragFinished}
    >
      {children}
    </SplitPane>
  );
}

const mapStateToProps = state => ({
  userId: state.user.id
});

export default connect(mapStateToProps)(SplitPaneLS);