import React, { useRef, useLayoutEffect } from 'react';
import { Scrollbars } from "react-custom-scrollbars";

import './List.scss';
import { useDidMount, usePrevious, useDidUpdate } from '../../hooks';
import Spinner from '../UI/Spinner/Spinner';

// TODO: add throttle on scroll
const List = (props) => {
  console.log("LIST RENDER");

  const {
    list = [],
    limit,
    hasMore = true,
    scrollInitialPosition = 'top',
    mode = '',
    classPrefix,
    listItemKey = 'id',
    listItem: ListItem,
    listLoadPending,
    ignoredListItemsCount = 0,
  } = props;

  const listRef = useRef();

  const expectedListLength = useRef(limit);
  const scrollPosition = useRef(0);

  const prevListLength = usePrevious(list.length);
  const prevIgnoredListItemsLength = usePrevious(ignoredListItemsCount);

  useDidMount(() => {
    if (scrollInitialPosition === 'bottom') {
      listRef.current && listRef.current.scrollToBottom();
    }
    // if we already have more list items then LIMIT
    expectedListLength.current = list.length >= limit
      ? list.length
      : limit;
  });

  useDidUpdate(() => {
    expectedListLength.current = list.length >= limit
      ? list.length
      : limit;

    if (listRef.current) {
      listRef.current.scrollTop(0);
    }
  }, [mode]);

  useDidUpdate(() => {
    /// multiple items added or removed from the list
    //  if they were added or removed from the ignore list
    if (prevListLength && ignoredListItemsCount &&
      ((prevListLength === list.length - (ignoredListItemsCount - prevIgnoredListItemsLength)) ||
        (prevListLength === list.length + (ignoredListItemsCount - prevIgnoredListItemsLength)))
    ) {
      expectedListLength.current = list.length;
    }
    //this can happen after loadMore if new items have already been
    else if (ignoredListItemsCount && (prevListLength + limit === list.length + ignoredListItemsCount)) {
      expectedListLength.current -= ignoredListItemsCount;
    }
    // one item was removed from list
    else if (prevListLength && (prevListLength === list.length + 1)) {
      expectedListLength.current -= 1;
    }
    // one item added to the list
    // this can happen either after scrolling (when scroll returns only one item) 
    // or after adding an element
    // therefore it is necessary to exclude scrolling
    else if (
      prevListLength &&
      (prevListLength === list.length - 1) &&
      ( // scroll exclusion
        prevListLength === expectedListLength.current ||
        expectedListLength.current % prevListLength
      )
    ) {
      expectedListLength.current += 1;
    }
  }, [list, ignoredListItemsCount]);

  useLayoutEffect(() => {
    if (!prevListLength && list.length && scrollInitialPosition === 'bottom') {
      listRef.current.scrollToBottom();
    }
    // save scroll on last item if we scroll to top
    else if (prevListLength && list.length !== prevListLength && scrollInitialPosition === 'bottom') {
      listRef.current.scrollTop(listRef.current.getScrollHeight() - scrollPosition.current);
    }
  }, [list.length, scrollInitialPosition]);


  const handleScroll = (e) => {
    if(hasMore) {
      const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    
      const scrollBottom = scrollHeight - scrollTop - clientHeight;
      if (scrollInitialPosition === 'top' && scrollBottom === 0 && expectedListLength.current === list.length) {
        expectedListLength.current = list.length + limit;
  
        props.loadMore && props.loadMore(list.length + ignoredListItemsCount);    // pass offset to loadMore func
      }
      else if (scrollInitialPosition === 'bottom' && scrollTop === 0 && expectedListLength.current === list.length) {
        expectedListLength.current = list.length + limit;
  
        scrollPosition.current = scrollHeight - scrollTop;
  
        props.loadMore && props.loadMore(list.length + ignoredListItemsCount);
      }
    }
  };

  return (
    <div className={classPrefix + "__list-container"}>
      {listLoadPending
        ? <div className={classPrefix + "__load-wrap"}>
          <Spinner spinnerSize='30px' />
        </div>
        : (
          !!list.length
            ? (
              <Scrollbars
                onScroll={handleScroll}
                autoHide
                ref={listRef}
                renderTrackHorizontal={props => <div {...props} style={{ display: 'none' }} className="track-horizontal" />} >

                <ul className={classPrefix + "__list"}>
                  {list.map(item => {
                    return <ListItem
                      key={item[listItemKey] || item}
                      item={item}

                      {...(
                        typeof props.listItemProps === 'function'
                          ? props.listItemProps(item)
                          : props.listItemProps
                      )}

                      className={classPrefix + "__item"} />;
                  })}
                </ul>
              </Scrollbars>
            )
            : (
              <div className={classPrefix + '__no-items'}>
                (no items)
              </div>
            )
        )
      }
    </div>
  );
};

export default React.memo(List);