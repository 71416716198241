import React from 'react';
import { connect } from 'react-redux';
import { faVenus, faAddressCard } from '@fortawesome/free-solid-svg-icons';

import { useDidMount } from '../../../../hooks';
import { openModal, closeModal, MODAL_TYPES } from '../../../../ducks/activeWindows';
import { callToConference } from '../../../../ducks/twilio';

import Menu from '../../../../components/Menu/Menu';
import { EditContactFormNew } from '../../../Dialogs/Dialogs';
import Title from '../../../../components/UI/Title/Title';



const SidebarHeader = (props) => {
  const menu = [];

  useDidMount(() => {
    if (props.type === 'Rooms') {
      menu.push({
        name: 'Start new conference',
        onMouseOver: console.log,
        action: () => props.callToConference()
      })
    }
    if (props.type === 'Clients' || props.type === 'Girls') {
      menu.push({
        name: 'Add new contact',
        onMouseOver: () => EditContactFormNew,
        action: () => props.openModal(MODAL_TYPES.addContactForm, {
          customSubmit: props.closeModal,
          isGirls: props.type === 'Girls',
        }),
      })
    }
  });

  return (
    <div className="sidebar-header">
      <Title
        classPrefix="chat-title"
        icon={props.type === 'Clients' ? faAddressCard : faVenus}
        iconType="fa"
        text={props.type}
      />

      <Menu
        menuItems={menu}
        width="14"
        icon="settings"
        menuPosition="fixed-right"
      />
    </div>
  );
};


const mapDispatchToProps = {
  callToConference,
  openModal,
  closeModal,
};

export default connect(null, mapDispatchToProps)(SidebarHeader);