import React from "react";

import { classModifier } from "../../../utils";

export default function RequirementsButton({ requirements, onClick, highlighted }) {
  const getItemStyle = color => color ? { backgroundColor: color } : null;

  return (
    <button className="requirements-button" onClick={onClick}>
      <span className="requirement-status-list">
        {requirements.map(({ id, isChecked, color }) => 
          isChecked
          ? null
          : <span key={id} className="requirement-status-list__item" style={getItemStyle(color)} />
        )}
      </span>

      <span
        className={classModifier("requirements-button__text", [
          highlighted && "highlighted",
        ])}
      >
        Requirements
      </span>
    </button>
  );
}
