import React from 'react';
import { connect } from 'react-redux';

import './AvailableGirlsList.scss';

import API from '../../../../api/api';
import { useDidMount } from '../../../../hooks';
import { addContactsToEntities } from '../../../../ducks/contacts';
import { setRoomPending, addAvailableGirls } from '../../../../ducks/rooms';
import { CONTACT_TYPES } from '../../../../config/constants';

import Spinner from '../../../../components/UI/Spinner/Spinner';
import ContactItem from '../../../Sidebar/components/ChatList/ContactItem';
import Scrollbars from 'react-custom-scrollbars';

const AvailableGirlsList = ({ availableIds, pending, ...props }) => {
  useDidMount(() => {
    props.setRoomPending();

    API.getGirlsWithStatus('available')
      .then(({ data }) => {
        props.addContactsToEntities(data.map(availableItem => availableItem.caller));
        props.addAvailableGirls(data);
      })
      .catch(console.log);
  });

  if (pending) {
    return (
      <div className="available-girls-list__pending">
        <Spinner spinnerSize="30px"/>
      </div>
    )
  }

  if (!availableIds.length) {
    return (
      <span className="available-girls-list__no-items">
        no items
      </span>
    )
  }

  return (
    <div className="available-girls-list__wrap">
      <ul className="available-girls-list__list">
        <Scrollbars autoHide>
          {availableIds.map(id => (
            <li
              className="available-girls-list__list--item"
              key={id}
            >
              <ContactItem
                id={id}
                type={CONTACT_TYPES.GIRL}
                isSimpleView // don`t show pinned and marked, also we can hide another elements and functionality
                isFromListOfGirlsWithStatus
              />
            </li>
          ))}
        </Scrollbars>
      </ul>
    </div>
  );
};

const mapStateToProps = state => ({
  availableIds: state.rooms.availableIds,
  pending: state.rooms.pending,
});

const mapDispatchToProps = {
  setRoomPending,
  addAvailableGirls,
  addContactsToEntities,
};

export default connect(mapStateToProps, mapDispatchToProps)(AvailableGirlsList);