import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPeopleArrows } from "@fortawesome/free-solid-svg-icons";

import { openModal, MODAL_TYPES } from '../../../../ducks/activeWindows';
import './ContactsForMergeBtn.scss';

const ContactsForMergeBtn = ({contactsForMergeCount, ...props}) => {
  if (!contactsForMergeCount) {
    return null;
  }

  console.log('ContactsForMergeBtn Render');
  return (
    <li className='navbar__item navbar__item--splitter'>
      <button
        onClick={() => props.openModal(
          MODAL_TYPES.contactsMergeList, { openModal: props.openModal }
        )}
        className="contacts-merge-btn"
      >
        {!!contactsForMergeCount && (
          <span className="contacts-merge-btn__unread">
            {contactsForMergeCount > 99 ? "+99" : contactsForMergeCount}
          </span>
        )}

        <FontAwesomeIcon
          icon={faPeopleArrows}
          title='merge contacts'
          color='#404040'
          className='navbar__envelope-icon'
        />
      </button>
    </li >
  );
}

const mapStateToProps = (state) => ({
  contactsForMergeCount: state.counters.mergeContacts,
})

const mapDispatchToProps = {
  openModal
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactsForMergeBtn);