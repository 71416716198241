import React from 'react';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useDebounce, useDidUpdate } from '../../hooks';

const SearchInput = (props) => {
  const {
    query,
    classPrefix,
    onChange,
    setQuery,
    stopSearch,
    startSearch,
    inputRef,
    isDisabled,
    showClearSearchBtn,
    maxLength = "20",
    ...inputProps
  } = props;

  useDidUpdate(() => {
    if (query.length === 0) {
      stopSearch('');
    }
  }, [query]);

  const handleSearch = () => {
    if (query.length > 1) {
      startSearch(query);
    }
  }

  const onInputChange = (e) => {
    if (onChange) {
      onChange(e);
    }
    setQuery(e.target.value)
  }

  useDebounce(handleSearch, 700, query);

  const renderSearchBtn = () => query.length > 1
    ? (
      <FontAwesomeIcon
        icon={faTimes}
        onClick={() => setQuery('')}
        className={classPrefix + "__btn " + classPrefix + "__btn--clear"}
      />
    )
    : (
      <FontAwesomeIcon
        icon={faSearch}
        className={classPrefix + "__btn " + classPrefix + "__btn--search"}
      />
    )

  return (
    <>
      <input
        autoComplete="off"
        className={classPrefix + "__search-input"}
        maxLength={maxLength}
        placeholder="Search"
        disabled={isDisabled}
        tabIndex="0"
        type="search"
        value={query}
        ref={inputRef}
        onChange={onInputChange}
        {...inputProps}
      />

      {showClearSearchBtn && renderSearchBtn()}
    </>
  );
}

export default React.memo(SearchInput);
