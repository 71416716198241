import React from 'react';

import AudioPlayer from '../AudioPlayer/AudioPlayer';
import TimeAgo from '../TimeAgo';


const MissedSubItem = ({ callItem, onVoicemailPlay, playMedia }) => {
  console.log('MissedListItem RENDER', callItem);

  const onPlayHandler = (audio) => {
    onVoicemailPlay(audio, callItem.voicemail);

    playMedia(audio);
  };

  return <li className="missed-sub-item__missed-item" key={callItem.id}>
    <TimeAgo minPeriod={60} date={callItem.dateCreated} />

    {callItem.voicemail &&
      <AudioPlayer
        onPlay={onPlayHandler}
        hideVolume
        src={callItem.voicemail.url} />
    }

    {callItem.voicemail &&
      <button
        className="missed-sub-item__btn--voice">
        <svg
          height="12px"
          width="12px"
          viewBox="1 -28 512 511"
          xmlns="http://www.w3.org/2000/svg"
          fill={callItem.voicemail.listened ? "#000" : "#0092F2"}>
          <path d="M457 1H55C25 1 0 25 0 56v305c0 30 25 55 55 55h155l35 36a15 15 0 0 0 22 0l35-36h155c30 0 55-25 55-55V56c0-31-25-55-55-55zm25 360c0 14-11 25-25 25H296c-4 0-8 2-10 4l-30 30-30-30c-2-2-6-4-10-4H55c-14 0-25-11-25-25V56c0-14 11-26 25-26h402c14 0 25 12 25 26zm0 0" />
          <path d="M359 136a72 72 0 0 0-58 115h-90a72 72 0 1 0-59 30h207a72 72 0 0 0 0-145zm-249 72a42 42 0 1 1 85 0 42 42 0 0 1-85 0zm249 43a42 42 0 1 1 0-85 42 42 0 0 1 0 85zm0 0" />
        </svg>
      </button>
    }
  </li>;
};



export default MissedSubItem;
