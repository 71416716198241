import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import API from '../../../api/api';
import { getContactsById } from '../../../ducks/contacts';
import { getChatMessageContext, updateActiveChat } from '../../../ducks/roomChats';
import { getContactMessageContext, updateActiveContact } from '../../../ducks/clientChats';
import { getContactMessageContext as getSessionContactMessageContext } from '../../../ducks/saleSessionChats';
import { getRoomsFromTabs } from '../../../ducks/rooms';
import { updateActiveSession } from '../../../ducks/sessions';
import { useDidMount, useWillUnmount } from '../../../hooks';
import { classModifier, formatDateToTime, getDateByTimezoneOffset, isEmptyObj, parseMsgText } from '../../../utils';
import { selectObjectOperators, selectUserTimezone } from '../../../selectors/selectors';
import { MODAL_TYPES, openModal } from '../../../ducks/activeWindows';
import { NOTIFICATION_TYPES } from '../../../ducks/notifications';
import { joinToConference } from '../../../ducks/webrtc';
import { MAX_CONFERENCE_PARTICIPANTS } from '../../../config/constants';

import Spinner from '../../../components/UI/Spinner/Spinner';


const getIsChatActive = (chat, userId) => {
  // TODO: add here check for prop 'deleted' in chat, when it will be on backend
  return [...chat.usersIds, ...chat.adminsIds].includes(userId);
};

const Notification = props => {
  const {
    className = '',
    notification,
    operator = {},
    contact,
    userId,
    session,
  } = props;

  const {
    color,
    date_created,
    type,
    body,
    data,
  } = notification;

  const date = getDateByTimezoneOffset(props.userTimezone, date_created);
  const isSalesPage = props.history.location.pathname === '/client/sales';
  const isMailPage = props.history.location.pathname === '/client/mail';

  const [isReadPending, setReadPending] = useState(false);
  const [isRequestForContactSent, setRequestForContactSent] = useState(false);

  useEffect(() => {
    if (notification.duration) {
      const interval = setInterval(() => {
        const diffInSeconds = (getDateByTimezoneOffset(props.userTimezone, new Date()) - date) / 1000;

        if (diffInSeconds > notification.duration) {
          clearInterval(interval);
          readNotification();
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, []);

  useWillUnmount(() => setReadPending(false));

  const getMsgTextIfContactExist = (contact, message) => {
    if (contact) {
      return parseMsgText(message, true);
    }
    else return '-';
  };

  const getMsgTextIfSessionExist = (session, contact, message) => {
    if (session) {
      return getMsgTextIfContactExist(contact, message);
    }
    else return '[SESSION DELETED]'
  };

  useDidMount(() => {
    if (!!data && !!data.caller_id && !contact) {
      props.getContactsById(data.caller_id, 1)
        .then(() => setRequestForContactSent(true));
    }
  });

  if (!!data && data.caller_id && !contact && !isRequestForContactSent) {
    return <div className={classModifier('notification__pending', color)}>
      <Spinner spinnerSize={30} />
    </div>
  }

  const getContactName = () => {
    if (!data.caller_id) {
      return null;
    }
    return contact
      ? contact.fn
      : 'DELETED CONTACT';
  };

  const getText = (notificationType, childData = null) => {
    switch (notificationType) {
      case NOTIFICATION_TYPES.changeOperatorStatus: {
        return `Status of the operator ${operator && operator.username} has changed to ${notification.status}`;
      }
      case NOTIFICATION_TYPES.coldTransfer: {
        return 'Cold Transfer from ' + operator.username;
      }
      case NOTIFICATION_TYPES.warmTransfer: {
        return 'Warm Transfer from ' + operator.username;
      }
      case NOTIFICATION_TYPES.parkingCall: {
        return 'Parking Call from ' + operator.username;
      }
      case NOTIFICATION_TYPES.newMessageReminder: {
        return 'New message reminder was set';
      }
      case NOTIFICATION_TYPES.messageReminder: {
        const message = body ? body : notification.body;
        
        return <>
          You have reminder regarding this
          <button
            className='notification__link notification__link--reminder'
            data-message-reminder
          >
            {parseMsgText(message, true)}
          </button>
        </>
      }
      case 'mention': {
        return <>
          You have a mention in chat
          <button
            className="notification__link"
            disabled={!getIsChatActive(data.chat, userId)}
            data-message
          >
            {data.chat.chatTitle}
          </button>
        </>
      }
      case NOTIFICATION_TYPES.changeContactNumber: {
        return <>
          Way of communication with &nbsp;
          <button
            className='notification__link notification__link--change-number'
            disabled={!contact}
            data-action
          >
            {getContactName()}
          </button>
          has changed
        </>
      }
      case NOTIFICATION_TYPES.addTelegramChannel: {
        return <>
          Added Telegram channel to &nbsp;
          <button
            className='notification__link notification__link--change-number'
            disabled={!contact}
            data-action
          >
            {getContactName()}
          </button>
        </>
      }
      case NOTIFICATION_TYPES.missedClientCall: {
        return <>
          Everybody missed call from
          <button
            className='notification__link'
            disabled={!contact}
            data-action
          >
            {getContactName()}
          </button>
        </>
      }
      case NOTIFICATION_TYPES.missedSessionClientCall: {
        return <>
          You missed session call with
          <button
            className='notification__link'
            disabled={!contact || !session}
            data-action
          >
            {getContactName()}
          </button>
          {!session && '[SESSION DELETED]'}
        </>
      }
      case NOTIFICATION_TYPES.longSession: {
        return <>
          Your session with&nbsp;
          <button
            className='notification__link notification__link--long-session'
            disabled={!contact || !session}
          >
            {getContactName()}
          </button>
          is longer than 2 hours.
          {!session && '[SESSION DELETED]'}
        </>
      }
      case NOTIFICATION_TYPES.clientTextedInSession:
      case NOTIFICATION_TYPES.profileTextedInSession: {
        return <>
          <button
            className='notification__link notification__link--name'
            disabled={!contact || !session}
            data-action
            title={getContactName()}
          >
            {getContactName()}
          </button>
          in session texted:&nbsp;
          <button
            className="notification__link notification__link--message"
            disabled={!contact || !session}
            data-action
          >
            {`"${childData
              ? getMsgTextIfSessionExist(session, contact, childData.callersInteractions.message)
              : getMsgTextIfSessionExist(session, contact, data.callersInteractions.message)
              }"`}
          </button>
        </>
      }

      case NOTIFICATION_TYPES.nightBotReject: {
        return <>
          Clent&nbsp;
          <button
            className='notification__link notification__link--related-name'
            disabled={!contact}
            data-action
            title={getContactName()}
          >
            {getContactName()}
          </button>&nbsp;
          is waiting for your response
        </>
      }

      case NOTIFICATION_TYPES.newRelatedMessage: {
        let message;

        if (data?.callersInteractions.attachment?.voiceMsg || childData?.callersInteractions.attachment?.voiceMsg) {
          message = 'Voice message';
        }
        else if (data?.callersInteractions.attachment?.videos || childData?.callersInteractions.attachment?.videos) {
          message = 'Video';
        }
        else if (data?.callersInteractions.attachment?.images || childData?.callersInteractions.attachment?.images) {
          message = 'Image';
        }
        else {
          message = `"${childData
            ? getMsgTextIfContactExist(contact, childData.callersInteractions.body)
            : getMsgTextIfContactExist(contact, data.callersInteractions.body)
          }"`;
        }

        return <>
          New message:&nbsp;
          <button
            className="notification__link notification__link--related-message"
            disabled={!contact}
            data-action
          >
            {message}
          </button>
          from&nbsp;
          <button
            className='notification__link notification__link--related-name'
            disabled={!contact}
            data-action
            title={getContactName()}
          >
            {getContactName()}
          </button>
        </>
      }
      case NOTIFICATION_TYPES.partnerHasntReactTo: {
        return <>
          Your partner
          <span className="notification__text">
            {operator.username}
          </span>
          hasn’t reacted to
          <span className={`notification__item--${data.color} notification__item--child`}>
            {getText(data.type, data)}
          </span>
        </>
      }
      case NOTIFICATION_TYPES.sessionUsingReminder: {
        return "Don`t forget to use sessions"
      }
      case NOTIFICATION_TYPES.undeliveredMessage: {
        return (<>
          Message not delivered&nbsp;
          <button
            className="notification__link notification__link--message"
            disabled={!contact}
            data-action
          >
            {!contact ? "DELETED CONTACT" : parseMsgText(data.body, true)}
          </button>
        </>)
      }
      case NOTIFICATION_TYPES.conferenceInvitation: {
        const conference = props.conferences[notification.data.conferenceId];

        if (conference){
          const activeParticipantsLength = props.isNewNotification &&
            Object.values(conference.participants).reduce((length, participant) => {
            if (['active', 'connecting','busy'].includes(participant.status)) {
              return ++length;
            }
            return length;
          }, 0);

          return <>
          You {conference.isInvited ?'have': 'had'} an invitation to the conference "{conference.name}"

          {!!conference.isInvited && props.isNewNotification &&
            <div className="notification__invitation-btns">
              <button onClick={() => API.rejectWebrtcConference(conference.id)}>
                Reject
              </button>
              <button onClick={() =>
                !props.activeConferenceId
                && !props.isActiveCall
                && activeParticipantsLength < MAX_CONFERENCE_PARTICIPANTS
                && props.joinToConference(conference)
              }>
                Accept
              </button>
            </div>
          }
        </>
        }
      }
      case NOTIFICATION_TYPES.newMail: {
        return (
          <>
            New
            <button className="notification__link notification__link--mail-message" data-action>
              email
            </button>
            from
            <span className="notification__text notification__text--mail-caller">
              {data.mail.caller.fn}
            </span>
          </>
        )
      }
      default:
        return notification.text ? notification.text : type;
    };
  };

  const onClick = e => {
    const { dataset } = e.target;

    if (dataset.read) {
      return readNotification();
    }

    switch (type) {
      // case NOTIFICATION_TYPES.coldTransfer:
      // case NOTIFICATION_TYPES.warmTransfer:
      // case NOTIFICATION_TYPES.parkingCall:
      case 'new-message-reminder': {
        props.getContactMessageContext(data.message_id, data.callerType, contact, '', props.userTimezone);
        props.toggleOpen && props.toggleOpen(false);
        break;
      }
      case NOTIFICATION_TYPES.messageReminder: {
        if (!dataset.messageReminder) return;

        if (isSalesPage) {
          props.history.push('/');
        }
        props.getContactMessageContext(data.message_id, data.callerType, contact, '', props.userTimezone);
        props.toggleOpen && props.toggleOpen(false);
        break;
      }
      case 'mention': {
        if (!dataset.message) return;

        const openMessageContext = () => props.getChatMessageContext(data.id, data.chatId, null, props.userId, '', props.userTimezone);
        const isActiveChat = props.activeRoomId === data.chatId;

        if (isActiveChat) {
          openMessageContext();
        }
        else if (props.isRoomLoaded) {
          props.updateActiveChat(data.chatId);
          openMessageContext();
        }
        else {
          props.getRoomsFromTabs([data.chatId], data.chatId)
            .then(() => {
              openMessageContext();
            });
        }

        if (isSalesPage) {
          props.history.push('/');
        }
        props.toggleOpen && props.toggleOpen(false);
        break;
      }
      case NOTIFICATION_TYPES.addTelegramChannel:
      case NOTIFICATION_TYPES.changeContactNumber:
      case NOTIFICATION_TYPES.missedClientCall: {
        if (!dataset.action) return;

        if (isSalesPage) {
          props.history.push('/');
        }
        props.updateActiveContact(contact);
        props.toggleOpen && props.toggleOpen(false);
        break;
      }
      case NOTIFICATION_TYPES.missedSessionClientCall: {
        if (!dataset.action || !session) return;

        if (!isSalesPage) {
          props.history.push('/client/sales');
        }
        props.updateActiveSession(session.id);
        props.toggleOpen && props.toggleOpen(false);
        break;
      }
      case NOTIFICATION_TYPES.clientTextedInSession:
      case NOTIFICATION_TYPES.profileTextedInSession: {
        if (!dataset.action || !session) return;

        if (!isSalesPage) {
          props.history.push('/client/sales');
        }
        props.updateActiveSession(session.id);
        props.openModal(MODAL_TYPES.profilesBuffer, { sessionId: session.id });
        props.getSessionContactMessageContext(+data.callersInteractions.id, +contact.type, contact)
        props.toggleOpen && props.toggleOpen(false);
        break;
      }
      // case NOTIFICATION_TYPES.newRelatedMessage: {
      //   if (!dataset.action) return;

      //   if (isSalesPage) {
      //     props.history.push('/');
      //   }
      //   props.updateActiveContact(contact);
      //   props.getContactMessageContext(+data.callersInteractions.id, +contact.type, contact);
      //   props.toggleOpen && props.toggleOpen(false);
      //   break;
      // }
      case NOTIFICATION_TYPES.nightBotReject: {
        if (!dataset.action) return;
        props.updateActiveContact(contact);
        break;
      }
      case NOTIFICATION_TYPES.partnerHasntReactTo: {
        const childType = data.type;
        const childData = data;

        if (childType === NOTIFICATION_TYPES.newRelatedMessage) {
          if (!dataset.action) return;

          if (isSalesPage) {
            props.history.push('/');
          }
          props.updateActiveContact(contact);
          props.getContactMessageContext(childData.callersInteractions.id, contact.type, contact);
          props.toggleOpen && props.toggleOpen(false);
        }
        else if (childType === NOTIFICATION_TYPES.clientTextedInSession || childType === NOTIFICATION_TYPES.profileTextedInSession) {
          if (!dataset.action || !session) return;

          if (!isSalesPage) {
            props.history.push('/client/sales');
          }
          props.updateActiveSession(session.id);
          props.openModal(MODAL_TYPES.profilesBuffer, { sessionId: session.id });
          props.getSessionContactMessageContext(childData.callersInteractions.id, contact.type, contact)
          props.toggleOpen && props.toggleOpen(false);
        }
        else if (childType === NOTIFICATION_TYPES.missedSessionClientCall) {
          if (!dataset.action || !session) return;

          if (!isSalesPage) {
            props.history.push('/client/sales');
          }
          props.updateActiveSession(session.id);
          props.toggleOpen && props.toggleOpen(false);
        }
        else return false
      }
      case NOTIFICATION_TYPES.undeliveredMessage: {
        if (!dataset.action) return;

        if (isSalesPage) {
          props.history.push('/');
        }
        props.updateActiveContact(contact);
        props.getContactMessageContext(data.id, data.caller.type, data.caller);
        props.toggleOpen && props.toggleOpen(false);
        break;
      }
      case NOTIFICATION_TYPES.newMail: {
        if (!dataset.action) return;

        if (!isMailPage) {
          props.history.push(`/client/mail/inbox/email/${data.mail.conversationId}`);
        }

        props.toggleOpen && props.toggleOpen(false);
      }
    };
  };

  const readNotification = () => {
    setReadPending(true);
    props.readNotification && props.readNotification(notification.id);
  };

  return (
    <div
      className={`${className} ${classModifier('notification__item', [
        color,
        notification.viewed && 'viewed',
        props.isNewNotification && 'new',
        props.isLarge && 'large',
        (props.isNewNotification && color) && `new-${color}`
      ])}`}
      onClick={onClick}
    >
      <div className="notification__item-text">
        {getText(type)}
      </div>

      <div className="notification__item-date">
        <span>{props.getDate(date)}</span>
        <span>{formatDateToTime(date, props.userHour12, false)}</span>

        {!notification.viewed &&
          <button
            className="notification__read"
            disabled={isReadPending}
            data-read
          >
            Read
          </button>
        }
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  operator: ownProps.notification.data && selectObjectOperators(state, ownProps.notification.data.user_id),
  contact: ownProps.notification.data && state.contacts.entities[ownProps.notification.data.caller_id],
  session: ownProps.notification.data && state.sessions.entities[ownProps.notification.data.session_id],
  userId: state.user.id,
  userTimezone: selectUserTimezone(state),
  activeRoomId: state.roomChats.active,
  isRoomLoaded: ownProps.notification.data && !!state.rooms.entities[ownProps.notification.data.chatId],
  activeConferenceId: state.webrtc.activeConferenceId,
  isActiveCall: !isEmptyObj(state.calls.activeCall),
  conferences: state.webrtc.conferences,
  userHour12: state.user.hour12,
});

const mapDispatchToProps = {
  getContactMessageContext,
  getChatMessageContext,
  updateActiveChat,
  getContactsById,
  updateActiveContact,
  getRoomsFromTabs,
  updateActiveSession,
  openModal,
  joinToConference,
  getSessionContactMessageContext,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Notification));