import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useDidUpdate } from '../../hooks';

import Select from "../Select/Select";


const TimeSelector = props => {
  const {
    selectClassPrefix,
    selectDisabledClassPrefix,
    timeIntervals = 15, // in minutes
    minTime,
    defaultValue,
  } = props;

  const [timeOptions, setTimeOptions] = useState([]);
  const [hour12, setHour12] = useState(props.userHour12);

  useDidUpdate(() => {
    setHour12(props.userHour12)
  }, [props.userHour12]);

  useEffect(() => {
    const updatedTimeOptions = [];
    const oneDay = 60 * 24;
    let minutes = 0;

    while (minutes < oneDay) {
      let mins = minutes % 60 + '';
      let hours = Math.floor(minutes / 60) + '';
      let amPm = '';
      let amPmHour = '';


      if (hour12) {
        amPm = hours < 12 ? " AM" : " PM";
        amPmHour = hours > 12 ? hours - 12 + '' : hours;
        if (amPmHour == 0) {
          amPmHour = 12 + '';
        }
      }

      mins = mins.length === 1
        ? '0' + mins
        : mins;
      hours = hours.length === 1
        ? '0' + hours
        : hours;
      amPmHour = amPmHour.length === 1
        ? '0' + amPmHour
        : amPmHour

      const time = hours + ':' + mins;
      const label = amPmHour + ':' + mins + amPm

      const isCurrentTimeMoreThanMinTime = isSecondTimeMoreThanFirst(minTime || ':', time);
      const canAddTime = !minTime || minTime === time || isCurrentTimeMoreThanMinTime;

      if (canAddTime) {
        updatedTimeOptions.push({ name: time, label: hour12 ? label : '' });
      }
      minutes += timeIntervals;
    }
    const isNeedToResetSelectedTime = defaultValue && minTime && isSecondTimeMoreThanFirst(defaultValue, minTime);

    if (isNeedToResetSelectedTime) {
      const firstTimeOption = updatedTimeOptions[0].name;

      props.setSelectedTime && props.setSelectedTime(firstTimeOption);
      props.onChange(firstTimeOption);
    }
    setTimeOptions(updatedTimeOptions);
  }, [minTime, defaultValue, hour12]);

  return (
    <Select
      classPrefix={props.isDisabled ? selectDisabledClassPrefix : selectClassPrefix}
      options={timeOptions}
      getOptionLabel={option => option.label}
      getOptionValue={option => option.name}
      defaultValue={defaultValue || timeOptions[0].name}
      onInputChange={value => props.onChange(value.name)}
      isDisabled={props.isDisabled}
      isAlwaysOpen={props.isAlwaysOpen}
    />
  );
};


const isSecondTimeMoreThanFirst = (firstTime, secondTime) => {
  const [firstHours, firstMinutes] = firstTime.split(':').map(str => +str);
  const [secondHours, secondMinutes] = secondTime.split(':').map(str => +str);

  if (secondHours < firstHours) {
    return false;
  }
  if (secondHours > firstHours) {
    return true;
  }

  return secondMinutes > firstMinutes;
};

const mapStateToProps = state => ({
  userHour12: state.user.hour12,
});


export default connect(mapStateToProps, null)(TimeSelector);