import React, { useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';

import API from '../../../api/api';
import { useDidMount, useToggle } from '../../../hooks';

import Spinner from '../../../components/UI/Spinner/Spinner';
import BookingsListItem from './BookingsListItem';


const UncompletedBookings = ({ toggleConfirmOnClose, ...props }) => {
  const [uncompletedBookings, setUncompletedBookings] = useState([]);
  const [pending, setPending] = useToggle(false);

  const updateBookings = (newBooking) => {
    setUncompletedBookings(prevBookings => {
      const index = prevBookings.findIndex(booking => booking.id === newBooking.id);
      const updatedBookings = [...prevBookings];

      updatedBookings[index] = newBooking;

      return updatedBookings;
    });
  }

  useDidMount(() => {
    setPending(true);

    API.getOverdueBookings()
    .then(res => setUncompletedBookings(res.data))
    .catch(console.log)
    .finally(() => setPending(false));
  });

  return (
    <div className='bookings__list-wrap'>
      {pending &&
        <div className="bookings__uncompleted-spinner-wrap">
          <Spinner spinnerSize={30} />
        </div>
      }

      {!pending && uncompletedBookings.length > 0 &&
        <Scrollbars>
          <ul className='bookings__list bookings__list--uncompleted'>
            {uncompletedBookings.map(booking =>
              <BookingsListItem
                isChat={props.isChat}
                key={booking.id}
                booking={booking}
                onClose={props.onClose}
                updateBookings={updateBookings}
                toggleConfirmOnClose={toggleConfirmOnClose}
                askConfirmOnClose
              />
            )}
          </ul>
        </Scrollbars>
      }

      {!pending && !uncompletedBookings.length &&
        <p className="bookings__uncompleted-placeholder">(no items)</p>
      }
    </div>
  )
}

export default UncompletedBookings;