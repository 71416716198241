import React from "react";
import { Link, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faAddressBook } from "@fortawesome/free-regular-svg-icons";

import "./Navbar.scss";
import UserMenu from "./components/UserMenu/UserMenu";
import CallsLog from "./components/CallsLog/CallsLog";
import SalesButton from "./components/SalesButton/SalesButton";
import UserNotifications from "./components/UserNotifications/UserNotifications";
import ActiveCall from "./components/ActiveCall/ActiveCall";
import ActiveConference from "./components/ActiveConference/ActiveConference";
import Dialpad from "./components/Dialpad/Dialpad";
import Bookings from "../Bookings/Bookings";
import TimezoneClock from "./components/TimezoneClock";
import MailingButton from "./components/MailingButton/MailingButton";
import VoicemailsHub from "./components/VoicemailsHub/VoicemailsHub";
import ContactsForMergeBtn from "./components/ContactsForMergeBtn/ContactsForMergeBtn";
import NightBotReportButton from "./components/NightBotReportButton/NightBotReportButton";

const Navbar = (props) => {
  const isMailPage = props.location.pathname.startsWith('/client/mail');
  const isSalesPage = props.location.pathname === "/client/sales";
  const isAdrBookPage = props.location.pathname === "/client/adr-book";

  return (
    <nav className='navbar' style={isSalesPage || isAdrBookPage || isMailPage ? { borderColor: "transparent" } : null}>
      <ul className='navbar__list'>
        <li className='navbar__item navbar__item--splitter'>
          <UserMenu setPagePending={props.setPagePending} />
          <UserNotifications />

          <TimezoneClock />
        </li>

        <li className='navbar__item navbar__item--splitter'>
          <Dialpad />
          <CallsLog />
        </li>

        <li className='navbar__item navbar__item--splitter'>
          <SalesButton />
        </li>
        <li className='navbar__item navbar__item--splitter'>
          <Link
            to={isAdrBookPage ? '/' : '/client/adr-book'}
            title="Address Book"
          >
            <FontAwesomeIcon
              icon={faAddressBook}
              color={isAdrBookPage ? "#0092f2" : "#404040"}
              className='navbar__icon'
            />
          </Link>
        </li>

        <li className='navbar__item navbar__item--splitter'>
          <VoicemailsHub />
        </li>

        <li className='navbar__item navbar__item--splitter'>
          <Bookings />
        </li>

        <li className='navbar__item navbar__item--splitter'>
          <Link
            to={isMailPage ? '/' : '/client/mail/inbox'}
            title="Mail"
          >
            <FontAwesomeIcon
              icon={faEnvelope}
              color={isMailPage ? "#0092f2" : "#404040"}
              className='navbar__icon'
            />
          </Link>
        </li>

        <li className='navbar__item navbar__item--splitter'>
          <MailingButton />
        </li>
      
        <ContactsForMergeBtn />
        
        <NightBotReportButton />

        <li className='navbar__item'>
          <ActiveCall />
          <ActiveConference />
        </li>
      </ul>
    </nav>
  );
};

export default withRouter(Navbar);
