import React, { Fragment, lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { Scrollbars } from "react-custom-scrollbars";

import {
  closeModal, MODAL_TYPES,
} from '../../ducks/activeWindows';

import './Dialogs.scss';
import Modal from '../../components/Modal/Modal';
import DelayedComponent from '../../components/DelayedComponent/DelayedComponent';
import ModalImage from '../../components/ModalImage/ModalImage';
import ModalVideo from '../../components/ModalVideo/ModalVideo';
import IncomingCallModal from './components/IncomingCallModal';
import OutgoingCallModal from './components/OutgoingCallModal';
import ModalError from './components/ModalError/ModalError';
import AwaySystemModal from './components/AwaySystemModal/AwaySystemModal';
import FullScreenCallModal from '../../components/FullScreenCallModal/FullScreenCallModal';
import ProfileCarousel from '../ProfileCard/components/ProfileCarousel/ProfileCarousel';
import BookingsListModal from './components/BookingsListModal/BookingsListModal';
import NightBotReportModal from './components/NightBotReportModal/NightBotReportModal';
// import GirlChat from '../Chat/GirlChat';
// import ClientChat from '../Chat/ClientChat';
// import FullScreenCallItem from '../../components/CallItem/FullScreenCallItem/FullScreenCallItem';


// export const LoadAddContactForm = lazy(() => import(/* webpackChunkName: "AddContactFormChunk"*/ './components/AddContactForm'));
// export const LoadClientChat = lazy(() => import(/* webpackChunkName: "LoadClientChat"*/ '../Chat/ClientChat'));
// export const LoadGirlChat = lazy(() => import(/* webpackChunkName: "LoadGirlChat"*/ '../Chat/GirlChat'));
export const EditContactFormNew = lazy(() => import(/* webpackChunkName: "AddContactFormChunk"*/ './components/EditContactForm/EditContactFormNew'));
export const LoadUserSettings = lazy(() => import(/* webpackChunkName: "UserSettings"*/ './components/UserSettings/UserSettings'));
export const LoadProfileRingtones = lazy(() => import(/* webpackChunkName: "ProfileRingtonesChunk"*/ './components/ProfileRingtones/ProfileRingtones'));
export const LoadTimePicker = lazy(() => import(/* webpackChunkName: "TimePickerChunk"*/ './components/TimePicker/TimePicker'));
export const LoadContactCard = lazy(() => import(/* webpackChunkName: "ContactCardChunk"*/ './components/ContactCard/ContactCard'));
export const LoadBlockForm = lazy(() => import(/* webpackChunkName: "BlockFormChunk"*/ './components/BlockForm/BlockForm'));
export const LoadTransferedCallModal = lazy(() => import(/* webpackChunkName: "TransferedCallModalChunk"*/ './components/TransferedCallModal'));
export const LoadVoicemails = lazy(() => import(/* webpackChunkName: "VoicemailsChunk"*/ './components/Voicemails/Voicemails'));
export const LoadIncomingQueueCall = lazy(() => import(/* webpackChunkName: "IncomingQueueCallChunk"*/ './components/IncomingQueueCallModal'));
export const LoadConferenceFullMode = lazy(() => import(/* webpackChunkName: "ConferenceFullModeChunk"*/ './components/ConferenceFullMode/ConferenceFullMode'));
export const LoadWebrtcConferenceFullMode = lazy(() => import(/* webpackChunkName: "WebrtcConferenceFullModeChunk"*/ './components/ConferenceFullMode/WebrtcConferenceFullMode'));
export const LoadSalesFiltersEditor = lazy(() => import(/* webpackChunkName: "SalesFiltersEditorChunk"*/ './components/FiltersEditor/FiltersEditor'));
// export const LoadBookingsListModal = lazy(() => import(/* webpackChunkName: "BookingsListModalChunk"*/ './components/BookingsListModal/BookingsListModal'));
export const LoadChat = lazy(() => import(/* webpackChunkName: "LoadChat"*/ '../Chat/Chat'));
export const LoadProfileReviews = lazy(() => import(/* webpackChunkName: "LoadProfileReviewsChunk"*/ '../../containers/ProfileCard/components/ProfileReviews/ProfileReviews'));
// export const LoadProfileCarousel = lazy(() => import(/* webpackChunkName: "LoadProfileCarouselChunk"*/ '../../containers/ProfileCard/components/ProfileCarousel/ProfileCarousel'));
export const LoadProfilesBuffer = lazy(() => import(/* webpackChunkName: "LoadProfilesBufferChunk"*/ '../../containers/ProfilesBuffer/ProfilesBuffer'));
export const LoadProfilesBooked = lazy(() => import(/* webpackChunkName: "LoadProfilesBookedChunk"*/ '../../containers/ProfilesBooked/ProfilesBooked'));
export const LoadMergeContactsEditor = lazy(() => import(/* webpackChunkName: "LoadMergeContactsEditorChunk"*/ '../../containers/MergeContactsEditor/MergeContactsEditor'));
export const LoadMailing = lazy(() => import(/* webpackChunkName: "LoadMailingChunk"*/ './components/Mailing/Mailing'));
export const LoadPrivateGallery = lazy(() => import(/* webpackChunkName: "LoadPrivateGalleryChunk"*/ './components/PrivateGallery/PrivateGallery'));
export const LoadContactsMergeList = lazy(() => import(/* webpackChunkName: "LoadContactsMergeListChunk"*/ './components/ContactsMergeList/ContactsMergeList'));
export const LoadBookingExistWarning = lazy(() => import(/* webpackChunkName: "LoadBookingExistWarningChunk"*/ './components/BookingExistWarning/BookingExistWarning'));
export const LoadProfilePricesEditing = lazy(() => import(/* webpackChunkName: "ProfilePricesEditingChunk"*/ './components/ProfilePricesEditingModal/ProfilePricesEditingModal'));
export const LoadBookingsByDates = lazy(() => import(/* webpackChunkName: "BookingsByDatesChunk"*/ './components/BookingsByDates/BookingsByDates'));
export const LoadContactNotes = lazy(() => import(/* webpackChunkName: "LoadContactNotesChunk"*/ './components/ContactNotesModal/ContactNotesModal'));

const components = {
  [MODAL_TYPES.editContactForm]: (props) => (
    <Scrollbars autoHeight autoHeightMax={"90vh"} autoHeightMin={400}>
      <EditContactFormNew {...props} />
    </Scrollbars>
  ),
  [MODAL_TYPES.addContactForm]: (props) => (
    <Scrollbars autoHeight autoHeightMax={"90vh"} autoHeightMin={600}>
      <EditContactFormNew {...props} />
    </Scrollbars>
  ),
  [MODAL_TYPES.userSettings]: LoadUserSettings,
  [MODAL_TYPES.profileRingtones]: LoadProfileRingtones, // not used
  [MODAL_TYPES.timePicker]: LoadTimePicker,
  [MODAL_TYPES.contactCard]: LoadContactCard,
  [MODAL_TYPES.blockForm]: LoadBlockForm, // ChatTab.js -> openContextMenu not working
  [MODAL_TYPES.transferedCall]: LoadTransferedCallModal,
  [MODAL_TYPES.voicemails]: LoadVoicemails, // not used
  [MODAL_TYPES.incomingQueueCall]: LoadIncomingQueueCall,
  [MODAL_TYPES.conferenceFullMode]: LoadConferenceFullMode,
  [MODAL_TYPES.webrtcConferenceFullMode]: LoadWebrtcConferenceFullMode,
  [MODAL_TYPES.salesFiltersEditor]: LoadSalesFiltersEditor,
  // [MODAL_TYPES.bookingsList]: LoadBookingsListModal,
  [MODAL_TYPES.bookingsList]: BookingsListModal,
  [MODAL_TYPES.bookingsByDates]: LoadBookingsByDates,
  [MODAL_TYPES.chat]: LoadChat, // not used
  [MODAL_TYPES.profilesBuffer]: LoadProfilesBuffer,
  [MODAL_TYPES.profilesBooked]: LoadProfilesBooked,
  [MODAL_TYPES.profileReviews]: LoadProfileReviews,
  [MODAL_TYPES.profileCarousel]: ProfileCarousel,
  [MODAL_TYPES.incomingCall]: IncomingCallModal,
  [MODAL_TYPES.outgoingCall]: OutgoingCallModal,
  [MODAL_TYPES.error]: ModalError,
  [MODAL_TYPES.image]: ModalImage,
  [MODAL_TYPES.video]: ModalVideo,
  [MODAL_TYPES.fullScreenCall]: FullScreenCallModal,
  [MODAL_TYPES.awaySystem]: AwaySystemModal, // not used
  [MODAL_TYPES.mergeContacts]: LoadMergeContactsEditor,
  [MODAL_TYPES.mailingComponent]: LoadMailing,
  [MODAL_TYPES.privateGallery]: LoadPrivateGallery,
  [MODAL_TYPES.contactsMergeList]: LoadContactsMergeList,
  [MODAL_TYPES.bookingExistWarning]: LoadBookingExistWarning,
  [MODAL_TYPES.nightBotReport]: NightBotReportModal,
  [MODAL_TYPES.profilePricesEditing]: LoadProfilePricesEditing,
  [MODAL_TYPES.profileNotesList] : LoadContactNotes
};


const DelayedComponents = (props) => {
  const {
    activeModals = [],
  } = props;

  const renderComponent = (modal) => {
    const Component = components[modal.type];

    return <Component closeModal={props.closeModal} {...modal.props} />
  }

  return (
    <>
      {ReactDOM.createPortal(
        <Fragment>
          <Suspense fallback={<div className='suspense'>Loading...</div>}>
            {activeModals.map(modal =>
              <DelayedComponent isMount={modal.isShow} key={modal.type}>
                <Modal
                  isOpen={modal.isShow}
                  closeModal={props.closeModal}
                  isCloseOutside={modal.isCloseOutside}
                >
                  {renderComponent(modal)}
                </Modal>
              </DelayedComponent>
            )}
          </Suspense>
        </Fragment>,
        document.getElementById('dialogs-root')
      )}
    </>
  );
};

const mapStateToProps = state => ({
  activeModals: state.activeWindows.activeModals,
});

const mapDispatchToProps = {
  closeModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(DelayedComponents);