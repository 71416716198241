import deleteCookie from "./deleteCookie";

export default (err, errorHandler = () => null) => {
  if (!err.response) {
    err.message && console.log(err.message);
    return console.error('Unhandled error in handleNetError.js', err);
  }

  switch (err.response.data.code) {
    case 666:
      console.error('Invalid Connect Token');

      deleteCookie('isAuthenticated');

      delete localStorage.isLoggedIn;
      delete localStorage.currentUserId;

      window.location.href = '/';
      break;
    default:
      console.error('Unhandled error in handleNetError.js', err);
      break;
  }
}