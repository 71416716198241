import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';



const TypingOperators = props => {
  const { operatorsIds } = props;

  return <>
    <FontAwesomeIcon icon={faPencilAlt} title="someone is typing" />
    <b>
      {(operatorsIds.length > 1
        ? operatorsIds.length
        : operatorsIds.map(id => (props.operators[id]).username)
      )}
    </b>
  </>;
};

TypingOperators.propTypes = {
  operatorsIds: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number)
  ])
};

const mapStateToProps = state => ({
  operators: state.operators.entities,
});

export default connect(mapStateToProps)(TypingOperators);