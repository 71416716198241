import React, { useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbtack, faBell, faEnvelope, faCopy, faReply, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { markMessageAsUnread, setMessageReminder, deleteTelegramMsgRequest } from '../../../../../ducks/clientChats';
import { pinClientMsg } from '../../../../../ducks/clientChats';
import { copyToClipboard, getDateByTimezoneOffset, parseMsgText } from '../../../../../utils';
import { openModal, MODAL_TYPES } from '../../../../../ducks/activeWindows';
import { INTERACTION_TYPES } from '../../../../../config/constants';

import InteractionMenu from './InteractionMenu';

const deleteMsgConfirmText = "Are you sure you want to delete this message?";

const ContactInteractionMenu = (props) => {
  const {
    interaction,
    activeChannel,
  } = props;

  const msgRef = useRef(null);

  const { INCOMING_MSG_TELEGRAM, OUTGOING_MSG_TELEGRAM } = INTERACTION_TYPES;

  const remindWithTooltip = (time) => {
    return props.setMessageReminder(interaction, time)
  }

  const items = useMemo(() => {
    const itemsArray = [
      {
        component: (props) =>
          <>
            <FontAwesomeIcon icon={faCopy} size="xs" color="#fff" />
            <textarea
              ref={msgRef}
              className="interaction__textarea--hidden"
              readOnly="readonly"
              value={parseMsgText(interaction.body, true)}
            />
          </>,
        action: async (e) => copyToClipboard(e, interaction.body, msgRef.current),
        tooltipOnHover: 'Copy text',
        tooltipAfterAction: 'Copied'
      },
      {
        icon: <FontAwesomeIcon icon={faThumbtack} size="xs" color="#fff" />,
        tooltipOnHover: 'Pin message',
        action: () => props.pinClientMsg(interaction, interaction.caller_id),
        tooltipAfterAction: 'Pinned'
      },
    ];

    if (interaction.type === 4 && !props.search && !!interaction.isRead) {
      itemsArray.push(
        {
          icon: <FontAwesomeIcon icon={faEnvelope} size="xs" color="#fff" />,
          tooltipOnHover: 'Unread message',
          action: () => props.markMessageAsUnread(interaction),
          tooltipAfterAction: 'Success unread'
        }
      )
    }

    itemsArray.push(
      {
        icon: <FontAwesomeIcon
          icon={faBell}
          size="xs"
          color="#fff" />,
        tooltipOnHover: 'Remind ',
        action: () => props.setMessageReminder(interaction, new Date().getTime() + 5 * 60 * 1000),
        subMenu: [
          {
            title: '15 min',
            action: () => props.setMessageReminder(interaction, new Date().getTime() + 15 * 60 * 1000)
          },
          {
            title: 'In hour',
            action: () => props.setMessageReminder(interaction, new Date().getTime() + 3600 * 1000)
          },
          {
            title: 'In 3 hours',
            action: () => props.setMessageReminder(interaction, new Date().getTime() + 3600 * 3000)
          },
          {
            title: 'Custom',
            action: async () => props.openModal(
              MODAL_TYPES.timePicker,
              {
                action: remindWithTooltip,
                isShowTimeMenu: true,
                minDate: +getDateByTimezoneOffset(props.userTimezone) + 900000,
                userTimezone: props.userTimezone,
                onClose: props.closeMenu
              }
            )
          },
        ]
      }
    );

    if ([INCOMING_MSG_TELEGRAM, OUTGOING_MSG_TELEGRAM].includes(interaction.type) && activeChannel === 'telegram') {
      itemsArray.push(
        {
          icon: <FontAwesomeIcon icon={faReply} size="xs" color="#fff" />,
          tooltipOnHover: 'Reply',
          action: async () => props.replyMsg(interaction),
        },
      )

      if (interaction.is_deletable) {
        itemsArray.push(
          {
            icon: <FontAwesomeIcon icon={faTrashAlt} size="xs" color="#fff" />,
            tooltipOnHover: 'Remove',
            classMods: "danger",
            action: async () => {
                if (window.confirm(deleteMsgConfirmText)) {
                    props.deleteTelegramMsgRequest(interaction.id);
                }
            },
          },
        )
      }
    }

    return itemsArray;
  }
  , [activeChannel]);

  // console.log('ContactInteractionMenu Render', items);
  return (
    <InteractionMenu
      isOpen={props.isOpen}
      menuItems={items}
      closeMenu={props.closeMenu}
    />
  );
}

const mapDispatchToProps = {
  markMessageAsUnread,
  setMessageReminder,
  pinClientMsg,
  openModal,
  deleteTelegramMsgRequest,
};

export default connect(null, mapDispatchToProps)(ContactInteractionMenu);
