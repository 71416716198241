export default (date, hour12 = false, withSeconds = true) => new Date(date).toLocaleString('ru-RU', withSeconds
  ? {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12
  }
  : {
    hour: '2-digit',
    minute: '2-digit',
    hour12
  }  
);
