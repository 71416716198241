import React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { selectIsUnreadMessages } from "../../../../selectors/selectors";

import './SalesButton.scss';
import SvgIcon from '../../../../components/SvgIcon/SvgIcon';


const SalesButton = props => {
  const setPath = (currentPath) => {
    if (currentPath === '/client/sales') return '/';
    return '/client/sales';
  };

  return (
    <Link to={setPath(props.location.pathname)}>
      <button
        className={`
          sales-btn 
          ${props.haveUnreadMessages && props.location.pathname === "/client/sales"
            ? "sales-btn--new-messages"
            : ""
          }
        `}
        title="sales page"
      >
        <SvgIcon
          width="24px"
          height="24px"
          icon="puzzle"
          fill={props.location.pathname === "/client/sales" ? "#0092f2" : "#404040"}
        />
      </button>
    </Link>
  );
};

const mapStateToProps = state => ({
  haveUnreadMessages: selectIsUnreadMessages(state)
});

export default connect(mapStateToProps)(withRouter(SalesButton));