import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo, faStar } from '@fortawesome/free-solid-svg-icons';

import {
  DIVA_GIRLS_IMG_HOST_PREVIEW,
} from "../../../ducks/divaGirls";

import LazyLoadImage from "../../LazyLoadImage/LazyLoadImage";
import ProfileRates from "../../ProfileRates/ProfileRates";


const ProfileCardForEdit = props => {

  const profile = props.item;

  const [isRatesOpen, setIsRatesOpen] = useState(false);

  const imgPath = DIVA_GIRLS_IMG_HOST_PREVIEW + profile.avatar;

  const renderProfileRate = () => {
    const prices = profile.prices || [];
    const halfHour = prices.find(rate => rate.name === '30 minutes') || {};
    const hour = prices.find(rate => rate.name === '1 hour') || {};
    const {
      incall = '-',
      outcall = '-',
    } = hour;

    return `${halfHour.incall ? halfHour.incall : '-'} / ${incall} / ${outcall}`;;
  }

  const getProfileShortBrestSize = () => {
    return profile.breast_size
      ? profile.breast_size.split(' ')[0]
      : '';
  }

  return (
    <li className="profiles-for-edit-item" onClick={() => props.onChangeGirl(profile)}>
      <div className="profiles-for-edit-item__img-wrap">
        <LazyLoadImage
          src={imgPath}
          spinnerSize={50}
        />
      </div>

      <div className="profiles-for-edit-item__profile-info-block">

        <button
          className="profiles-for-edit-item__show-prices"
          onMouseEnter={() => setIsRatesOpen(true)}
        >
          <FontAwesomeIcon icon={faInfo} title='rating' />
        </button>

        <div
          className="profiles-for-edit-item__name"
          title={profile.name}
        >
          {profile.name}

          {!!profile.exclusive &&
            <span className="profiles-for-edit-item__name--exclusive">
              <FontAwesomeIcon icon={faStar} title="exclusive"/>
            </span>
          }
        </div>

        <div className="profiles-for-edit-item__profile-info">
          {renderProfileRate()}
          <br/>
          {profile.age}y.o. / {profile.height}cm / {getProfileShortBrestSize()}
          <br/>
          {profile.location_main}
        </div>
      </div>

      {isRatesOpen &&
        <div 
          className="profile-card__rates" 
          onClick={(e) => e.stopPropagation()} 
          onMouseLeave={() => setIsRatesOpen(false)}
        >
          <ProfileRates
            profileId={profile.id}
            defaultPrices={profile.prices} 
            memberPrices={profile.prices_member}
            secretPrices={profile.prices_secret}
          />
        </div>
      }
    </li>
  );
};

export default ProfileCardForEdit;