import React, { useState } from 'react';
import { connect } from 'react-redux';

import Chat from './Chat';
import Spinner from '../../components/UI/Spinner/Spinner';

import {
  getContactTimeline,
  updateContactTimeline,
  updateActiveContact,
  getTimelineMedia,
  cleanTimelineMedia,
  updateContactTimelineMedia,
  getContactMessageContext,
  stopContactMessageSearch,
  searchContactMessage,
  // removeContactTab,
  updateContactMessageSearch,
  updateContactMessageContext,
  updateContactDateMsgContext,
  getContactDateMsgContext,
  cleanContactDateMsgContext,
  getContactChatOfHistorySession,
  updateContactChatOfHistorySession,
  getContactReminders,
  updateContactReminders,
  cleanContactReminders,
  removeMessageReminder,
  getScheduledMsgs,
  updateScheduledMsgs,
  cleanScheduledMsgs,
  getScheduledMsgsCount,
} from '../../ducks/saleSessionChats';
import {
  sendMessage,
  markContactChatAsRead,
  unpinClientMsg,
  pinClientMsg,
} from '../../ducks/clientChats';
import {
  playMedia,
  openModal,
  MODAL_TYPES,
} from '../../ducks/activeWindows';
import { getContactsById } from '../../ducks/contacts';
import { getAllTemplates, getMsgShortcuts } from '../../ducks/msgTemplates';
import { useDidMount, useDidUpdate, usePrevious, useToggle } from '../../hooks';
import { CHAT_SOURCES, CHAT_TYPES } from '../../config/constants';
import { getDateByTimezoneOffset } from '../../utils';
import { selectContactById, selectUserTimezone } from '../../selectors/selectors';


const ClientSessionChat = (props) => {
  const {
    active,
    getContactTimeline,
    activeRecipient,
    userTimezone,
    userHour12,
  } = props;

  const [activeChatSource, setActiveChatSource] = useState(CHAT_SOURCES.MSGS);
  const [editedMsg, setEditedMsg] = useState(null);

  const prevActiveChatSource = usePrevious(activeChatSource);

  useDidMount(() => {
    if (active !== null) {
      if (!props.timeline.length) {

        props.isSessionFromHistory
          ? props.getContactChatOfHistorySession(props.sessionId, active, CHAT_TYPES.CLIENT, userTimezone)
          : getContactTimeline(active, CHAT_TYPES.CLIENT, userTimezone, false);

        props.getScheduledMsgsCount(active, CHAT_TYPES.CLIENT);
      }
    }
  });

  useDidUpdate(() => {
    if (active !== null) {

      props.isSessionFromHistory
        ? props.getContactChatOfHistorySession(props.sessionId, active, CHAT_TYPES.CLIENT, userTimezone)
        : getContactTimeline(active, CHAT_TYPES.CLIENT, userTimezone, false);

      props.getScheduledMsgsCount(active, CHAT_TYPES.CLIENT);
    }
  }, [active]);


  useDidUpdate(() => {
    if (activeChatSource === CHAT_SOURCES.REMINDERS) {
      props.getContactReminders(activeRecipient, userTimezone);
    }
    else if (activeChatSource === CHAT_SOURCES.MEDIA) {
      props.getTimelineMedia(activeRecipient, userTimezone);
    }
    else if (activeChatSource === CHAT_SOURCES.SCHEDULED_MSGS) {
      props.getScheduledMsgs(activeRecipient, userTimezone);
    }
    if (prevActiveChatSource === CHAT_SOURCES.REMINDERS) {
      props.cleanContactReminders(CHAT_TYPES.CLIENT);
    }
    else if (prevActiveChatSource === CHAT_SOURCES.MEDIA) {
      props.cleanTimelineMedia(CHAT_TYPES.CLIENT);
    }
    else if (prevActiveChatSource === CHAT_SOURCES.SCHEDULED_MSGS) {
      props.cleanScheduledMsgs(CHAT_TYPES.CLIENT);
    }
  }, [activeChatSource]);

  const timeline = () => {
    if (
      props.contextMsgId ||
      props.contextDate ||
      props.search ||
      activeChatSource !== CHAT_SOURCES.MSGS
    ) {
      return props.auxiliaryTimeline;
    }
    else {
      return props.timeline;
    }
  }

  const updateTimeline = () => {
    if (props.contextMsgId) {
      return updateMsgContext;
    }
    else if (props.contextDate) {
      return updateDateMsgContext;
    }
    else if (props.search) {
      return updateMsgSearch;
    }
    else if (activeChatSource === CHAT_SOURCES.MEDIA) {
      return updateContactTimelineMedia;
    }
    else if (activeChatSource === CHAT_SOURCES.REMINDERS) {
      return updateContactReminders;
    }
    else if (activeChatSource === CHAT_SOURCES.SCHEDULED_MSGS) {
      return updateScheduledMsgs;
    }
    else if (props.isSessionFromHistory) {
      return updateContactChatOfHistorySession
    }
    return updateContactTimeline;
  }

  const getContactMsgContext = (msgId, contact, searchQuery) =>
    props.getContactMessageContext(msgId, CHAT_TYPES.CLIENT, contact, searchQuery, userTimezone);

  const updateMsgContext = (activeRecipient, page, loadDirection) =>
    props.updateContactMessageContext(props.contextMsgId, page, loadDirection, activeRecipient, userTimezone);

  const getContactDateMsgContext = (date) =>
    props.getContactDateMsgContext(date, props.activeRecipient.id, CHAT_TYPES.CLIENT, userTimezone);

  const updateDateMsgContext = (activeRecipient, page, loadDirection) =>
    props.updateContactDateMsgContext(activeRecipient, page, loadDirection, props.contextDate, userTimezone);

  const updateContactReminders = (activeRecipient, page, loadDirection) =>
    props.updateContactReminders(activeRecipient, page, loadDirection, userTimezone);

  const updateScheduledMsgs = (activeRecipient, page, loadDirection) =>
    props.updateScheduledMsgs(activeRecipient, page, loadDirection, userTimezone);

  const showTimePicker = async () => props.openModal(
    MODAL_TYPES.timePicker,
    {
      action: getContactDateMsgContext,
      maxDate: getDateByTimezoneOffset(userTimezone),
      userTimezone
    }
  );

  const cleanEditedMsg = () => setEditedMsg(null);

  const startMessageSearch = (query) =>
    props.searchContactMessage(query, activeRecipient, userTimezone);

  const updateMsgSearch = (activeRecipient, page, loadDirection) =>
    props.updateContactMessageSearch(activeRecipient, page, loadDirection, props.search, userTimezone);

  const stopMessageSearch = () => props.stopContactMessageSearch(activeRecipient);

  const updateContactChatOfHistorySession = (activeRecipient, page, loadDirection) =>
    props.updateContactChatOfHistorySession(props.sessionId, page, loadDirection, CHAT_TYPES.CLIENT, userTimezone);

  const updateContactTimelineMedia = (activeRecipient, page, loadDirection) =>
    props.updateContactTimelineMedia(activeRecipient, page, loadDirection, userTimezone);

  const updateContactTimeline = (activeRecipient, page, loadDirection) =>
    props.updateContactTimeline(activeRecipient, page, loadDirection, userTimezone);


  const isMainTimelineOpen = props.search ||
    activeChatSource !== CHAT_SOURCES.MSGS ||
    props.contextMsgId ||
    props.contextDate
    ? false
    : true;

  const timelineLowerLoadedPage = !isMainTimelineOpen
    ? props.auxiliaryLowerLoadedPage
    : props.timelineLowerLoadedPage;

  const timelinePageCount = !isMainTimelineOpen
    ? props.auxiliaryPageCount
    : props.timelinePageCount;

  const timelineHigherLoadedPage = !isMainTimelineOpen
    ? props.auxiliaryHigherLoadedPage
    : props.timelineCurrentPage;

  const timelineCurrentPage = !isMainTimelineOpen
    ? props.auxiliaryCurrentPage
    : props.timelineCurrentPage;

  return activeRecipient
    ? (
      <Chat
        isHideMuteBtn
        type={CHAT_TYPES.CLIENT}
        activeRecipient={activeRecipient}
        updateActiveContact={props.updateActiveContact}
        profileId={props.userId}
        userTimezone={userTimezone}
        userHour12={userHour12}

        tabs={props.tabs}
        title='Conversation with Clients'

        timelinePending={props.timelinePending}
        updatePending={props.updatePending}
        isMainTimelineOpen={isMainTimelineOpen}
        timeline={timeline()}
        timelineCurrentPage={timelineCurrentPage}
        timelinePageCount={timelinePageCount}
        timelineHigherLoadedPage={timelineHigherLoadedPage}
        timelineLowerLoadedPage={timelineLowerLoadedPage}
        newInteractionType={props.newInteractionType}
        updateContactTimeline={updateTimeline()}

        msgTemplates={props.msgTemplates}
        shortcuts={props.shortcuts}
        typingStatus={props.typingStatus}

        search={props.search}
        startMessageSearch={startMessageSearch}
        stopMessageSearch={stopMessageSearch}

        contextMsgId={props.contextMsgId}
        getMessageContext={getContactMsgContext}

        contextDate={props.contextDate}
        cleanContactDateMsgContext={props.cleanContactDateMsgContext}
        // showTimePickerForDateContext={isTimelineMedia ? null : showTimePicker}

        pinnedMsg={props.pinnedMsg}
        unpinMsg={props.unpinClientMsg}
        pinMsg={props.pinClientMsg}

        activeChatSource={activeChatSource}
        setActiveChatSource={setActiveChatSource}

        editedMsg={editedMsg}
        cleanEditedMsg={cleanEditedMsg}
        editMsg={setEditedMsg}

        removeMessageReminder={props.removeMessageReminder}

        markChatAsRead={props.markChatAsRead}
        sendMessage={props.sendMessage}
        openModal={props.openModal}
        playMedia={props.playMedia}

        isMsgInput={props.isSessionFromHistory || activeRecipient.id === "new_chat" ? false : true}
        isScheduledMsgsSource={activeChatSource === CHAT_SOURCES.SCHEDULED_MSGS}
        scheduledMsgsCount={props.scheduledMsgsCount}
      />
    )
    : <div className="chat"></div>;
};

const mapStateToProps = (state, { chatStatus, id }) => ({
  active: chatStatus 
    ? chatStatus === 'show' ? state.saleSessionChats.clients.active || id : null 
    : state.saleSessionChats.clients.active,
  activeRecipient: chatStatus
    ? chatStatus === 'show' && 
      state.contacts.entities[state.saleSessionChats.clients.active || id]
    : state.contacts.entities[state.saleSessionChats.clients.active || id],
  tabs: state.saleSessionChats.clients.tabs,

  timeline: state.saleSessionChats.clients.timeline,
  timelinePending: state.saleSessionChats.clients.timelinePending,
  updatePending: state.saleSessionChats.clients.updatePending,
  timelineCurrentPage: state.saleSessionChats.clients.timelineCurrentPage,
  timelinePageCount: state.saleSessionChats.clients.timelinePageCount,
  timelineLowerLoadedPage: state.saleSessionChats.clients.timelineLowerLoadedPage,
  newInteractionType: state.saleSessionChats.clients.newInteractionType,

  auxiliaryTimeline: state.saleSessionChats.clients.auxiliaryTimeline,
  auxiliaryLowerLoadedPage: state.saleSessionChats.clients.auxiliaryLowerLoadedPage,
  auxiliaryPageCount: state.saleSessionChats.clients.auxiliaryPageCount,
  auxiliaryHigherLoadedPage: state.saleSessionChats.clients.auxiliaryHigherLoadedPage,
  auxiliaryCurrentPage: state.saleSessionChats.clients.auxiliaryCurrentPage,

  msgTemplates: state.msgTemplates.clientsTemplates,
  shortcuts: state.msgTemplates.shortcuts,

  search: state.saleSessionChats.clients.search,

  contextMsgId: state.saleSessionChats.clients.contextMsgId,
  contextDate: state.saleSessionChats.clients.contextDate,

  userId: state.user.id,
  userTimezone: selectUserTimezone(state),
  userHour12: state.user.hour12,

  pinnedMsg: state.saleSessionChats.clients.pinnedMsg,

  typingStatus: state.typingOperators.typingOperators[state.saleSessionChats.clients.active + '_' + CHAT_TYPES.CLIENT],

  scheduledMsgsCount: state.saleSessionChats.clients.scheduledMsgsCount,

});



const mapDispatchToProps = {
  getContactTimeline,
  updateContactTimeline,
  updateContactMessageSearch,
  updateActiveContact,
  sendMessage,
  // removeContactTab,
  getContactMessageContext,
  getAllTemplates,
  getMsgShortcuts,
  markChatAsRead: markContactChatAsRead,
  getContactsById,
  getTimelineMedia,
  cleanTimelineMedia,
  updateContactTimelineMedia,
  openModal,

  stopContactMessageSearch,
  searchContactMessage,

  pinClientMsg,
  unpinClientMsg,
  playMedia,
  updateContactMessageSearch,
  updateContactMessageContext,
  updateContactDateMsgContext,
  getContactDateMsgContext,
  cleanContactDateMsgContext,

  getContactReminders,
  updateContactReminders,
  cleanContactReminders,
  removeMessageReminder,

  getContactChatOfHistorySession,
  updateContactChatOfHistorySession,

  getScheduledMsgs,
  updateScheduledMsgs,
  cleanScheduledMsgs,
  getScheduledMsgsCount,
};
export default connect(mapStateToProps, mapDispatchToProps)(ClientSessionChat);