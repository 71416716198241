import React, { useState } from 'react';
import { connect } from 'react-redux';

import API from '../../../api/api';
import { killConnection, acceptConnection } from '../../../ducks/twilio';
import { CONTACT_TYPES } from '../../../config/constants';
import { getContactAvatar } from '../../../utils';
import { selectActiveWebrtcConference, selectFreeCallModesOperators } from '../../../selectors/selectors';
import { onHoldActiveConference } from '../../../ducks/webrtc';
import { useToggle } from '../../../hooks';

import Tag from '../../../components/TagsForm/Tag';
import LazyLoadImage from '../../../components/LazyLoadImage/LazyLoadImage';
import SvgIcon from '../../../components/SvgIcon/SvgIcon';
import Scrollbars from 'react-custom-scrollbars';
import Spinner from '../../../components/UI/Spinner/Spinner';
import CountdownTimer from './CountdownTimer/CountdownTimer';


const IncomingCallModal = ({ connection, contact, activeCall, ...props }) => {
  const [serverError, setServerError] = useState('');
  const [redirectOperatorId, setRedirectOperatorId] = useState('');
  const [pending, togglePending] = useToggle(false);

  const pickUpPhone = (e) => {
    if (props.activeConference) {
      props.onHoldActiveConference(props.activeConference, props.peerConnections, props.localStream);
    }

    e.preventDefault();
    acceptConnection(connection);
  }

  const hangUp = (e) => {
    e.preventDefault();
    killConnection(connection);
  }

  const contactType = () => {
    if (contact.username) return 'Operator';

    return contact.type === 1
      ? 'Client'
      : 'Girl';
  };

  const renderCountryInfo = () => {
    const tel = contact && contact.tels
      ? contact.tels.find(t => t.default === 1)
      : [];

    const country = tel && tel.country
      ? tel.country
      : 'Unknown country';

    const flag = tel && tel.flag
      ? tel.flag
      : 'https://barrakuda.biz/images/stories/virtuemart/product/_________________4d3813533c43a.png';

    return <span>From: <img src={flag} alt="flag" /> {country}</span>
  };

  const onTimerEnd = () => {
    API.redirectIncomingCall(activeCall.id)
      .then(() => {
        console.log("Call has been Redirected!");

        if (props.audio) {
          console.log('stop audio');
          props.audio.pause();
          props.audio.currentTime = 0;
        }

        props.closeModal();
      })
      .catch((err) => {
        setServerError('Something went wrong');
        console.log(err);
      });
  };

  const handleRedirectClick = (operatorId) => {
    setRedirectOperatorId(operatorId);
    togglePending(true);
    setServerError('');

    API.redirectIncomingCall(activeCall.id, operatorId)
      .then(() => {
        console.log("Call has been Redirected!");

        if (props.audio) {
          console.log('stop audio');
          props.audio.pause();
          props.audio.currentTime = 0;
        }

        togglePending(false);
        setRedirectOperatorId('');
        props.closeModal();
      })
      .catch(() => {
        setServerError('Operator is no longer free');
        togglePending(false);
        setRedirectOperatorId('');
      })
  };


  return (
    <div className="call-modal">
      <h1>Incoming Call from {contactType()}</h1>

      <div className="call-modal__wrap">
        <div
          className="call-modal__call-info"
          style={{
            width: activeCall.redirectInSeconds && props.onlineCallModesOperators.length
              ? '55%'
              : '100%'
          }}
        >

          <div className="call-modal__img-wrap">
            <img src={getContactAvatar(contact)} alt="avatar" />
          </div>

          <p className="call-modal__name">{contact.short_name || contact.fn || contact.username || 'Unknown Contact'}</p>

          <div className="call-modal__nationality">
            {renderCountryInfo()}
          </div>

          {contact.callerTags && !!contact.callerTags.length &&
            <ul className="call-modal__tags-list">
              {contact.callerTags.map(tag =>
                <Tag tag={tag} key={tag.id} />
              )}
            </ul>
          }

          <div className="call-modal__btns">
            <button className="call-modal__btn" onClick={pickUpPhone}>
              Answer
            </button>
            <button className="call-modal__btn" onClick={hangUp}>
              Dismiss
            </button>
          </div>
        </div>

        {(!!activeCall.redirectInSeconds && !!props.onlineCallModesOperators.length) &&
          <div className="call-modal__redirect">

            <div className="call-modal__timer-wrap">

              <div className="call-modal__timer-wrap--title">
                The call will be automatically redirect in
              </div>

              <CountdownTimer
                countdownSeconds={activeCall.redirectInSeconds}
                onTimerEnd={onTimerEnd}
              />
            </div>

            <div className="call-modal__active-users">
              <div className="call-modal__active-users--title">
                Or you can redirect manually to your online partner:
              </div>

              <div className="call-modal__active-users--redirect-list">
                <Scrollbars>
                  <ul className="redirect-list">
                    {!!props.onlineCallModesOperators.length &&
                      props.onlineCallModesOperators.map(operator => (
                        <li
                          className="redirect-list__item"
                          key={operator.id}
                        >
                          {(pending && redirectOperatorId === operator.id)
                            ? <div className="redirect-list__item--pending">
                              <Spinner spinnerSize={20} />
                            </div>
                            : <>
                              <div className="redirect-list__item--img-wrap">
                                <LazyLoadImage
                                  src={getContactAvatar(operator, CONTACT_TYPES.OPERATOR)}
                                  alt="ava"
                                  className='redirect-list__item--img'
                                />
                              </div>

                              <div className="redirect-list__item--info">
                                {operator.username}
                              </div>

                              <button
                                className="redirect-list__item--redirect-btn"
                                onClick={() => handleRedirectClick(operator.id)}
                              >
                                <SvgIcon width="20" height="20" icon="phone" fill="#01DF85" title='redirect call' />
                              </button>

                              {(serverError && redirectOperatorId === operator.id) &&
                                <span className="redirect-list__item--error">
                                  Operator is no longer free
                                </span>
                              }
                            </>
                          }
                        </li>
                      ))
                    }
                  </ul>
                </Scrollbars>
              </div>

            </div>
          </div>
        }
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => ({
  contact: state.calls.activeCall.caller || state.calls.activeCall.sender || {},
  connection: state.twilio.connection,
  activeCall: state.calls.activeCall,
  onlineCallModesOperators: selectFreeCallModesOperators(state),
  localStream: state.webrtc.localStream,
  peerConnections: state.webrtc.peerConnections,
  activeConference: selectActiveWebrtcConference(state),
});

const mapDispatchToProps = {
  onHoldActiveConference
}

export default connect(mapStateToProps, mapDispatchToProps)(IncomingCallModal);
