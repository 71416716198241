import React, { useState, useEffect, useCallback, useRef, useLayoutEffect, memo } from 'react';
import Loadable from 'react-loadable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGrinAlt, faPaperclip, faSms } from '@fortawesome/free-solid-svg-icons';
import { faTelegram } from '@fortawesome/free-brands-svg-icons';

import './ChatMessageInput.scss';
import {
  Drafts,
  debounce,
  throttle,
  getContactAvatar,
  classModifier,
  parseMsgText,
  getDefaultField,
  getDateByTimezoneOffset
} from '../../../../utils';
import { useToggle, usePrevious, useDidUpdate } from '../../../../hooks';
import MessageTemplates from './MessageTemplates';
import AsyncComponentLoader from '../../../../components/AsyncComponentLoader/AsyncComponentLoader';
import VoiceMessageRecorder from "./VoiceMessageRecorder";
import API from '../../../../api/api';
import TypingIndicator from './TypingIndicator/TypingIndicator';
import TypingOperators from './TypingIndicator/TypingOperators';
import AudioStatusBtn from './AudioStatusBtn/AudioStatusBtn';
import { MODAL_TYPES, closeModal } from '../../../../ducks/activeWindows';
import { CHAT_TYPES, CONTACT_TYPES } from '../../../../config/constants';
import { connect } from 'react-redux';
import { selectOperatorsShortInfo } from '../../../../selectors/selectors';
import DropWrapper from '../../../../components/DropWrapper/DropWrapper';
import ChatMessageForbidInput from './ChatMessageForbidInput/ChatMessageForbidInput';


export const LoadEmojiPicker = Loadable({
  loader: () => import(/* webpackChunkName: "EmojiPickerChunk"*/ './EmojiPicker'),
  loading: AsyncComponentLoader,
  delay: 400,
  timeout: 10000, // 10 seconds
  modules: ['EmojiPickerChunk']
});

const mentionInitialState = {
  show: false,
  options: [],
  active: null,
}


const MessageInput = (props) => {
  const {
    activeRecipient,
    profileId,
    editedMsg,
    sharedMsg,
    repliedMsg,
    publicChat,
    girlChat,
    clientChat,
    voiceMsg,
    images,
    videos,
    type,
    isDisabled,
    initialMsg,
    isHideMuteBtn,
  } = props;

  const [textareaClassName, setTextareaClassName] = useState('message__input');
  const [message, setMessage] = useState('');
  const [tempMessage, setTempMessage] = useState('');

  const [shortcut, setShortcut] = useState(null);
  const [mention, setMention] = useState(mentionInitialState);

  const [showPicker, togglePicker] = useToggle(false);

  const activeMentionRef = useRef(null);
  const emojiWrapRef = useRef(null);

  const lastWord = useRef('');
  const initialDraft = useRef(Drafts.getOne(activeRecipient, profileId));
  const usedShortcutsIds = useRef([]);

  const isDidMount = useRef(true);
  const isTypingRef = useRef(false);
  const messageInputRef = useRef('');

  const [shortcutPosition, setShortcutPosition] = useState({
    x: null,
    y: null
  });

  const prevMsg = usePrevious(message);
  const prevContactId = usePrevious(activeRecipient.id);

  const changeChannel = (channel) => {
    if (activeRecipient.default_channel === channel) return;

    API.changeContactDefaultChannel(activeRecipient.id, channel);
  };

  const throttledSendTypingPing = useCallback(
    throttle(
      API.sendTyping,
      3000,
      true
    ), []);

  const debouncedSendTyping = useCallback(
    debounce((chatId, isTyping, text) => {
      if (isTypingRef.current) {
        isTypingRef.current = false;
        
        API.sendTyping(chatId, isTyping, text);
      }
    }, 3000),
  []);


  useDidUpdate(() => {
    initialDraft.current = Drafts.getOne(activeRecipient, profileId);
  }, [activeRecipient.id])

  useLayoutEffect(() => {         // change inputHeight of every message change
    if (isDidMount.current) {
      isDidMount.current = false;
    }

    if (props.fixTab) {
      if (
        message.length && 
        props.unfixedTab === activeRecipient.id && 
        activeRecipient.draft_message !== message &&  // For contact
        initialDraft.current !== message  // For room
      ) {
        props.fixTab(activeRecipient.id);
      }
    }

    inputHeightAutosize();
  }, [message, tempMessage]);

  useEffect(() => {
    if (editedMsg) {
      setTempMessage(parseMsgText(editedMsg.body, true));
    }
    if (!editedMsg) {
      setTempMessage('');
    }
  }, [editedMsg]);

  useEffect(() => {     // if user have changed => focus message input
    if (!(isClientOrGirl && isEmptyNumber)) {
      messageInputRef.current.focus();
    }
  }, [activeRecipient.id])

  useEffect(() => {     // if user have changed => check drafts or clean msg
    if (!props.isMailingMode) {
      const draft = Drafts.getOne(activeRecipient, profileId);

      if (activeRecipient.draft_message) {
        setMessage(activeRecipient.draft_message);
      }
      else if (draft && !isClientOrGirl) {
        setMessage(draft);
      }
      else if (message) {
        setMessage('');
      }
      // if (!(isClientOrGirl && isEmptyNumber)) {
      //   messageInputRef.current.focus();
      // }
    } else {
      if (Array.isArray(activeRecipient.id)) {
        setMessage(initialMsg ? initialMsg.body : '');
      }
    }
  }, [activeRecipient.id, activeRecipient.draft_message]);

  useEffect(() => {
    if (props.isAnyAttachments) {
      messageInputRef.current.focus();
    }
  }, [props.isAnyAttachments]);

  useEffect(() => {
    if (shortcut || mention.show) {
      const msg = tempMessage
        ? tempMessage
        : message;

      calcShortcutPosition(messageInputRef.current, msg.trim());
    }

    if (prevMsg !== message && !props.isMailingMode && isTypingRef.current) {
      throttledSendTypingPing(activeRecipient.id + '_' + type, true);
    }
  }, [message, tempMessage, shortcut, mention.show]);

  useDidUpdate(() => {
    if (prevContactId === activeRecipient.id) {
      let timeoutId;

      if (!props.isMailingMode) {
        setTextareaClassName('message__input message__input--changed');

        timeoutId = setTimeout(() => {
          setTextareaClassName('message__input');
        }, 100);
      }

      return () => clearTimeout(timeoutId);
    }
  }, [activeRecipient.draft_message]);

  useDidUpdate(() => {
    if (mention.active && activeMentionRef.current) {
      activeMentionRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'center' });
    }
  }, [mention])


  const updateDraft = (msg) => {
    const drafts = Drafts.getAll(profileId);

    const id = activeRecipient.type
      ? `${activeRecipient.id}_${activeRecipient.type}`
      : `${activeRecipient.id}_${activeRecipient.chatType}`;

    if (msg.length) {
      drafts[id] = msg;
      Drafts.update(drafts, profileId);
    }
    else {
      delete drafts[id];
      Drafts.update(drafts, profileId);
    }
  };

  const inputHeightAutosize = () => {
    messageInputRef.current.style.height = "";
    messageInputRef.current.style.height = messageInputRef.current.scrollHeight + 'px';
  };

  const addEmoji = (emoji) => {
    const sym = emoji.unified.split('-');
    const codesArray = [];

    sym.forEach(el => codesArray.push('0x' + el));
    const emojiPic = String.fromCodePoint(...codesArray);

    isTypingRef.current = true;

    if (!props.isMailingMode) {
      debouncedSendTyping(activeRecipient.id + '_' + type, false, message + emojiPic);
    }
    setMessage(message + emojiPic);

    messageInputRef.current.focus();
  };

  const addBoilerplate = (boilerplate) => {
    setMessage(boilerplate);
    messageInputRef.current.focus();

    isTypingRef.current = true;

    if (!props.isMailingMode) {
      debouncedSendTyping(activeRecipient.id + '_' + type, false, boilerplate);
    }
  };

  const editMsg = () => {
    if (editedMsg.body.trim() !== tempMessage.trim() && tempMessage.trim().length) {
      const updatedMsg = {
        id: editedMsg.id,
        body: tempMessage
      };
      // edit scheduledMsg
      if (props.isScheduledMsgsSource) {
        API.updateScheduledMsg(updatedMsg);
      }
      else {
        API.updateChatMsg(updatedMsg)
      }
    }
    afterSubmit();
  };

  const expandShortcut = () => {
    const shortcutPosition = message.lastIndexOf(lastWord.current);

    const beforeShortcut = message.slice(0, shortcutPosition);

    const afterShortcut = message
      .slice(shortcutPosition)
      .replace(lastWord.current, shortcut.value);

    const updatedMsg = beforeShortcut + afterShortcut;

    usedShortcutsIds.current.push(shortcut.id);

    isTypingRef.current = true;

    if (!props.isMailingMode) {
      debouncedSendTyping(activeRecipient.id + '_' + type, false, updatedMsg);
    }
    
    setMessage(updatedMsg);
    setShortcut(null);

    messageInputRef.current.focus();
  };

  const expandMention = (activeMention) => {
    const mentionPosition = message.lastIndexOf(lastWord.current);

    const mentionShortcut = message.slice(0, mentionPosition);

    const afterMention = message
      .slice(mentionPosition)
      .replace(lastWord.current, '@' + (activeMention || mention.active));

    const updatedMsg = mentionShortcut + afterMention;

    setMessage(updatedMsg);
    setMention(mentionInitialState);

    messageInputRef.current.focus();
  };

  const handleChange = (e) => {
    lastWord.current = e.target.value.split(/\n| /).pop();
    isTypingRef.current = true;

    if (editedMsg) {
      setTempMessage(e.target.value);
    }
    else {
      setMessage(e.target.value);

      if (!isClientOrGirl) {
        updateDraft(e.target.value);
      }
      if (!props.isMailingMode) {
        debouncedSendTyping(activeRecipient.id + '_' + type, false, e.target.value)
      }
    }
    // shortcuts part
    if (props.shortcuts[lastWord.current]) {
      setShortcut(props.shortcuts[lastWord.current]);
    }
    else if (!props.shortcuts[lastWord.current] && shortcut) {
      setShortcut(null);
    }
    // mentions works only in Room chats
    if (props.operatorsShortInfo.length && props.type === CHAT_TYPES.ROOM) {
      if (lastWord.current[0] === '@') {
        // open mentions menu with all options
        if (lastWord.current.length === 1) {
          setMention({
            show: true,
            options: props.operatorsShortInfo,
            active: props.operatorsShortInfo[0].username
          })
        }
        // open mentions menu with possible options
        else {
          const filteredMentionOptions = props.operatorsShortInfo
            .filter(option =>
              ('@' + option.username.toLowerCase()).startsWith(lastWord.current.toLowerCase()) &&
              ('@' + option.username.toLowerCase()) !== lastWord.current.toLowerCase()
            );

          // if possible options exists
          if (filteredMentionOptions.length) {
            setMention({
              show: true,
              options: filteredMentionOptions,
              active: filteredMentionOptions[0].username
            })
          }
          // if no options
          else {
            setMention(mentionInitialState);
          }
        }
      }
      else if (mention.show) {
        setMention(mentionInitialState)
      }
    }
  };

  const calcShortcutPosition = (input, msg) => {
    const {
      offsetTop,
      offsetHeight,
      scrollTop
    } = input;

    const { lineHeight } = getComputedStyle(input);

    const { x, y } = getInputCursorXY(input, msg);

    const newLeft = x;

    const newTop = Math.min(
      y - scrollTop,
      (offsetTop + offsetHeight) - parseInt(lineHeight, 10)
    );

    setShortcutPosition({
      left: newLeft + 'px',
      top: newTop + 'px'
    });
  };

  const getInputCursorXY = (input, msg) => {
    const {
      offsetLeft: inputX,
      offsetTop: inputY,
    } = input;

    const form = document.createElement('div');
    const formStyle = getComputedStyle(input.parentElement);

    for (const prop of formStyle) {
      form.style[prop] = formStyle[prop];
    }

    const buttons = document.createElement('div');

    buttons.style.width = getComputedStyle(input.nextElementSibling).width;

    const div = document.createElement('div');
    const inputStyle = getComputedStyle(input);

    for (const prop of inputStyle) {
      div.style[prop] = inputStyle[prop];
    }

    const textContent = msg.substr(0, msg.length - 1);

    div.textContent = textContent;
    form.classList.add('custom-scroll');

    const span = document.createElement('span');

    span.textContent = msg.substr(msg.length - 1);

    div.appendChild(span);

    form.appendChild(div);
    form.appendChild(buttons);

    const root = document.getElementById('root');

    root.appendChild(form);

    const { offsetLeft: spanX, offsetTop: spanY } = span;

    root.removeChild(form);

    return {
      x: inputX + spanX,
      y: inputY + spanY
    };
  };

  const changeActiveMentionByKeyDown = (key) => {
    if (mention.options.length === 1) return;

    const activeIdx = mention.options.findIndex((option) => option.username === mention.active);
    const maxIdx = mention.options.length - 1;

    let newActiveIdx;

    if (key === 'ArrowUp') {
      if (activeIdx > 0) {
        newActiveIdx = activeIdx - 1;
      }
      else if (activeIdx === 0) {
        newActiveIdx = maxIdx;
      }
    }
    else {
      if (activeIdx < maxIdx) {
        newActiveIdx = activeIdx + 1;
      }
      else if (activeIdx === maxIdx) {
        newActiveIdx = 0;
      }
    }

    setMention(prevMention => ({
      ...prevMention,
      active: mention.options[newActiveIdx].username
    }));
  };

  const createScheduledMsg = () => {
    const messageObj = {
      body: message,
      contactId: activeRecipient.id
    }

    props.openModal(
      MODAL_TYPES.timePicker,
      {
        action: (date) => {
          return API.createScheduledMsg({ ...messageObj, date })
            .then(() => {
              afterSubmit();
            })
            .catch(console.error);
        },
        isShowTimeMenu: true,
        minDate: +getDateByTimezoneOffset(props.userTimezone) + 900000,
        userTimezone: props.userTimezone,
      }
    )
  };

  const handleKeyDownDown = (e) => {
    if (mention.show) {
      if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
        e.preventDefault();
        changeActiveMentionByKeyDown(e.key);
      }
      else if (e.key === 'Enter') {
        e.preventDefault();
        return expandMention();
      }
    }

    if (e.key === 'Enter') {
      if (shortcut) {
        e.preventDefault();
        return expandShortcut();
      }
      if (e.nativeEvent.metaKey || e.shiftKey) {
        if (editedMsg) {
          e.preventDefault();
          return editMsg();
        }
        else if (props.isScheduledMsgsSource) {
          e.preventDefault();
          return createScheduledMsg();
        }
        handleSubmit(e);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    isTypingRef.current = false;

    if (!message.trim().length && !sharedMsg && !voiceMsg && !images && !videos && !repliedMsg) {
      return;
    }

    let files = null;

    if (images && videos) {
      files = [...images, ...videos];
    }
    else if (images) {
      files = images;
    }
    else if (videos) {
      files = videos;
    }

    const messageObj = {
      text: message,
      files: files,
      voiceMsg: voiceMsg
        ? {
          blob: voiceMsg.blob,
          duration: voiceMsg.duration
        }
        : '',
      shortcuts: usedShortcutsIds.current.length
        ? usedShortcutsIds.current
        : '',
      sharedMsgId: sharedMsg
        ? sharedMsg.id
        : '',
      repliedMsgId: repliedMsg 
        ? repliedMsg.id :
        '',
      sharedContactId: props.sharedContactId
        ? props.sharedContactId
        : '',
      channel: activeRecipient.default_channel,
    };

    if (props.isMailingMode) {
      props.sendMessage(messageObj, activeRecipient);
    }
    else {
      props.type === CHAT_TYPES.ROOM
        ? API.sendMessageToChat(messageObj, activeRecipient.id)
          .catch(console.error)
        : API.sendMessage(messageObj, activeRecipient)
          .catch(err => {
            console.log(err);

            if (err.response && err.response.data && err.response.data.status === 403) {
              props.openModal(MODAL_TYPES.error, { text: err.response.data.message });
            }
          });
    }

    afterSubmit();
  };

  const afterSubmit = () => {
    updateDraft('');

    if (sharedMsg) {
      props.cleanSharedMsg();
    }
    if (props.sharedContactId) {
      props.updateSharedContact(null);
    }
    if (images) {
      props.cleanImages();
    }
    if (videos) {
      props.cleanVideos();
    }
    if (voiceMsg) {
      props.updateVoiceMsg();
    }
    if (message && !editedMsg) {
      setMessage('');
    }
    if (editedMsg) {
      props.cleanEditedMsg();
    }
    if (repliedMsg) {
      props.cleanRepliedMsg();
    }

    usedShortcutsIds.current = [];
  };

  const renderTypingIndicator = () => {
    if (!props.typingStatus) {
      return null;
    }

    return (
      <>
        <span className="message__indicator-text">
          <TypingOperators operatorsIds={props.typingStatus} />
        </span>

        <TypingIndicator />
      </>
    );
  };

  const isEmptyNumber = !getDefaultField(activeRecipient.tels, 'tel');
  const isClientOrGirl = [CHAT_TYPES.GIRL, CHAT_TYPES.CLIENT].includes(type);
  const isTelegramNumber = activeRecipient.telegram_tels && !!activeRecipient.telegram_tels.length;

  return (
    <>
      {isEmptyNumber && isClientOrGirl && !isTelegramNumber &&
        <ChatMessageForbidInput
          activeRecipient={activeRecipient}
        />
      }

      <div className="message__input-header">
        <div className="message__indicator">
          {renderTypingIndicator()}
        </div>

        {/* <hr className="message__header-hr"></hr> */}

        {isClientOrGirl &&
          <div className="message__dispatch-type">
            <button
              onClick={() => changeChannel('tel')}
              className={(activeRecipient.default_channel === 'tel' || !activeRecipient.default_channel) ? 'message__dispatch-type--active' : ''}
              disabled={!activeRecipient.tels.length}
            >
              <FontAwesomeIcon icon={faSms} size="lg" color="#797b7c" />
            </button>
            <button
              onClick={() => changeChannel('telegram')}
              className={activeRecipient.default_channel === 'telegram' ? 'message__dispatch-type--active' : ''}
              disabled={!activeRecipient.telegram_tels.length}
            >
              <FontAwesomeIcon icon={faTelegram} size="lg" color="#797b7c" />
            </button>
          </div>
        }

        <span className="message__header-buttons">
          {activeRecipient.id !== 'webmaster' && !isHideMuteBtn &&
            <AudioStatusBtn
              changeRecipientAudioStatus={props.changeRecipientAudioStatus}
              activeRecipient={activeRecipient} />
          }
          <MessageTemplates
            msgTemplates={props.msgTemplates}
            addBoilerplate={addBoilerplate} />

          <button
            className="message__btn message__btn--emoji"
            onMouseDown={togglePicker}
            onMouseOver={LoadEmojiPicker.preload}
            type="button">
            <FontAwesomeIcon icon={faGrinAlt} color="#bdbdbd" title="emoji" />
          </button>

          {showPicker &&
            <DropWrapper
              closeDropWrapper={togglePicker}
              dropWrapperRef={emojiWrapRef}
            >
              <div
                ref={emojiWrapRef}
              >
                <LoadEmojiPicker
                  showPreview={false}
                  onSelect={addEmoji}
                  native={true}
                />
              </div>
            </DropWrapper>
          }
        </span>

      </div>

      <form
        className="message__form custom-scroll"
        onSubmit={handleSubmit}>

        <textarea
          rows="3"
          placeholder="Your message"
          autoComplete="off"
          ref={messageInputRef}
          value={editedMsg ? tempMessage : message}
          onChange={handleChange}
          onKeyDown={handleKeyDownDown}
          disabled={isDisabled}
          className={textareaClassName} />

        <div className="message__controls">
          {props.fileInputProps && (publicChat || ((clientChat || girlChat) && activeRecipient.default_channel === 'telegram')) &&
            <>
              <input 
                {...props.fileInputProps()} 
                id={'dropzone-input' + activeRecipient.id + '_' + activeRecipient.type} 
                disabled={!!voiceMsg}
              />
              <label htmlFor={'dropzone-input' + activeRecipient.id + '_' + activeRecipient.type}>
                <FontAwesomeIcon 
                  icon={faPaperclip} 
                  title="attach file"
                  className={classModifier('message__attach-icon', !!voiceMsg && 'disabled')}
                />
              </label>
            </>
          }

          {(publicChat || ((clientChat || girlChat) && activeRecipient.default_channel === 'telegram')) &&
            <VoiceMessageRecorder
              updateVoiceMsg={props.updateVoiceMsg}
              activeRecipient={activeRecipient}
              chatType={props.type}
              disabled={!!images?.length || !!videos?.length || !!voiceMsg}
            />
          }
        </div>

        {shortcut &&
          <span
            onClick={expandShortcut}
            style={shortcutPosition}
            className="message__shortcut">
            {shortcut.value}
          </span>
        }

        {mention.show &&
          <ul
            className="message__mention-list"
            style={{ left: shortcutPosition.left }}
          >
            {mention.options.map(option => {
              let statusColor;

              switch (option.status) {
                case "busy":
                  statusColor = "#F8737F";
                  break;
                case "online":
                  statusColor = "#01DF85";
                  break;
                case "away":
                  statusColor = "orange";
                  break;
                default:
                  statusColor = "grey";
                  break;
              }

              return (
                <li
                  className={classModifier("mention", [
                    mention.active === option.username && 'active'
                  ])}
                  // className="message__mention-item mention"
                  key={option.username}
                  ref={mention.active === option.username ? activeMentionRef : null}
                  onClick={() => expandMention(option.username)}
                >
                  <div className="mention__ava-wrap">
                    <span
                      className="contact-item__status"
                      style={{ border: "2px solid #fff", backgroundColor: statusColor }}

                    >
                    </span>
                    <img src={getContactAvatar(option.photo)} alt="operator mini ava" className="mention__ava" />
                  </div>
                  <span className="mention__name">{option.username}</span>
                </li>
              )
            })
            }
          </ul>
        }
      </form>
    </>
  );
};

const mapStateToProps = (state) => ({
  operatorsShortInfo: selectOperatorsShortInfo(state)
});

const mapDispatchToProps = {
  closeModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageInput);