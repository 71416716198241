import React, { Component } from 'react';
import { connect } from 'react-redux';

import LS from '../../utils/localStorageAPI';
import {
  getContactTimeline,
  updateContactTimeline,
  updateActiveContact,
  sendMessage,
  removeContactTab,
  removeContactTabsFromContextMenu,
  markContactChatAsRead,
  getTimelineMedia,
  cleanTimelineMedia,
  updateContactTimelineMedia,
  getContactMessageContext,
  updateContactMessageContext,
  unpinClientMsg,
  pinClientMsg,
  getContactReminders,
  cleanContactReminders,
  updateContactReminders,
  removeMessageReminder,
  updateContactMessageSearch,
  stopContactMessageSearch,
  searchContactMessage,
  searchGlobalContactMessage,
  updateGlobalContactMessageSearch,
  getContactDateMsgContext,
  cleanContactDateMsgContext,
  updateContactDateMsgContext,
  getScheduledMsgs,
  cleanScheduledMsgs,
  updateScheduledMsgs,
  getScheduledMsgsCount,
} from '../../ducks/clientChats';
import {
  playMedia,
  openModal,
  MODAL_TYPES,
} from '../../ducks/activeWindows';
import {
  cleanServiceMsgs,
  fixGirlTab,
  getServiceMsgs,
  getServiceMsgsCount,
  updateServiceMsgs
} from '../../ducks/girlChats';
import { updateContactInState, addNewArrayGirlsToState } from '../../ducks/contacts';
import { selectUserTimezone } from '../../selectors/selectors';
import API from '../../api/api';
import { CHAT_SOURCES, CHAT_TYPES } from '../../config/constants';
import { getDateByTimezoneOffset } from '../../utils';

import Title from '../../components/UI/Title/Title';
import ChatRestore from './ChatRestore';
import Chat from './Chat';


class GirlChat extends Component {
  state = {
    activeChatSource: CHAT_SOURCES.MSGS,
    repliedMsg: null,
    editedMsg: null,
    voiceMsg: null,
    images: null,
  }

  componentDidMount() {
    if (this.props.active && !this.props.timeline.length) {
      this.props.getContactTimeline(this.props.active, CHAT_TYPES.GIRL, this.props.userTimezone, false);
      this.props.getScheduledMsgsCount(this.props.active, CHAT_TYPES.GIRL);
      this.props.getServiceMsgsCount(this.props.active);
    }

    let urlParams = new URLSearchParams(window.location.search);
    let webMasterId = urlParams.get('webMasterId');
    let contactId = urlParams.get('contactId');

    window.history.replaceState(null, null, window.location.pathname);

    if (webMasterId && contactId) {
      this.getContactMsgContext(+webMasterId, { id: +contactId, type: CHAT_TYPES.GIRL });
    }
  }

  componentWillUnmount() {
    if (this.state.images) {
      this.cleanImages();
    }
    if (this.state.videos) {
      this.cleanVideos();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { activeChatSource } = this.state;

    if (prevProps.active !== this.props.active) {
      if (this.props.active !== null) {
        const withCancel = !!prevProps.active;
        
        this.props.getScheduledMsgsCount(this.props.active, CHAT_TYPES.GIRL, withCancel);
        this.props.getServiceMsgsCount(this.props.active);

        if (this.props.girlsSearchSource === 'msgs' && this.props.girlsSearch) {
          this.props.getContactTimeline(this.props.active, CHAT_TYPES.GIRL, this.props.userTimezone, withCancel, this.props.girlsSearch);
        }
        else {
          this.props.getContactTimeline(this.props.active, CHAT_TYPES.GIRL, this.props.userTimezone, withCancel);
        }
      }
      if (this.state.editedMsg) {
        this.cleanEditedMsg();
      }
      if (this.state.voiceMsg) {
        this.updateVoiceMsg();
      }
      if (this.state.images) {
        this.cleanImages();
      }
      if (this.state.videos) {
        this.cleanVideos();
      }
      LS.setItem('aGirl', this.props.active, this.props.userId);
    }
    if (prevProps.tabs !== this.props.tabs) {
      LS.setItem('gTabs', this.props.tabs, this.props.userId);
    }
    if (prevProps.unfixedTab !== this.props.unfixedTab) {
      LS.setItem('unfixedGirlTab', this.props.unfixedTab, this.props.userId);
    }

    if (activeChatSource !== prevState.activeChatSource) {
      if (activeChatSource === CHAT_SOURCES.REMINDERS) {
        this.props.getContactReminders(this.props.activeRecipient, this.props.userTimezone);
      }
      else if (activeChatSource === CHAT_SOURCES.MEDIA) {
        this.props.getTimelineMedia(this.props.activeRecipient, this.props.userTimezone);
      }
      else if (activeChatSource === CHAT_SOURCES.SCHEDULED_MSGS) {
        this.props.getScheduledMsgs(this.props.activeRecipient, this.props.userTimezone);
      }
      else if (activeChatSource === CHAT_SOURCES.SYSTEM_MSGS) {
        this.props.getServiceMsgs(this.props.activeRecipient, this.props.userTimezone);
      }

      if (prevState.activeChatSource === CHAT_SOURCES.REMINDERS) {
        this.props.cleanContactReminders(CHAT_TYPES.GIRL);
      }
      else if (prevState.activeChatSource === CHAT_SOURCES.MEDIA) {
        this.props.cleanTimelineMedia(CHAT_TYPES.GIRL);
      }
      else if (prevState.activeChatSource === CHAT_SOURCES.SCHEDULED_MSGS) {
        this.props.cleanScheduledMsgs(CHAT_TYPES.GIRL);
      }
      else if (prevState.activeChatSource === CHAT_SOURCES.SYSTEM_MSGS) {
        this.props.cleanServiceMsgs();
      }
    }
  }

  setActiveChatSource = (chatSource) => {
    if (chatSource !== this.state.activeChatSource) {
      this.setState({ activeChatSource: chatSource });
    }
  }

  replyMsg = (msg) => this.setState({ repliedMsg: msg });
  cleanRepliedMsg = () => this.setState({ repliedMsg: null });

  editMsg = (msg) => this.setState({ editedMsg: msg });
  cleanEditedMsg = () => this.setState({ editedMsg: null });

  updateVoiceMsg = (blob, duration) => {
    if (blob && duration) {                 // add voiceMsg
      this.setState({
        voiceMsg: {
          blob,
          url: URL.createObjectURL(blob),
          duration
        }
      });
    }
    else if (this.state.voiceMsg) {         // clean voiceMsg
      this.setState({ voiceMsg: null });
    }
  }

  updateImages = (images) => {
    const imagesWithUrl = images.map(file => Object.assign(file, {
      url: URL.createObjectURL(file)
    }));

    this.setState({ images: imagesWithUrl });
  }
  cleanImages = () => {
    this.state.images.forEach(file => URL.revokeObjectURL(file.url));
    this.setState({ images: null });
  }

  updateVideos = (videos) => {
    const videosWithUrl = videos.map(file => Object.assign(file, {
      url: URL.createObjectURL(file)
    }));

    this.setState({ videos: videosWithUrl });
  }
  cleanVideos = () => {
    this.state.videos.forEach(file => URL.revokeObjectURL(file.url));
    this.setState({ videos: null });
  }

  timeline = () => {
    if (this.props.contextMsgId) {
      return this.props.auxiliaryTimeline;
    }
    else if (this.props.contextDate) {
      return this.props.auxiliaryTimeline;
    }
    else if (this.props.search) {
      return this.props.auxiliaryTimeline;
    }
    else if (this.state.activeChatSource !== CHAT_SOURCES.MSGS) {
      return this.props.auxiliaryTimeline;
    }
    return this.props.timeline;
  }

  updateTimeline = () => {
    if (this.props.contextMsgId) {
      return this.updateMsgContext;
    }
    else if (this.props.contextDate) {
      return this.updateDateMsgContext;
    }
    else if (this.props.search && this.props.isGlobalSearch) {
      return this.updateGlobalContactMessageSearch;
    }
    else if (this.props.search) {
      return this.updateMsgSearch;
    }
    else if (this.state.activeChatSource === CHAT_SOURCES.MEDIA) {
      return this.updateContactTimelineMedia;
    }
    else if (this.state.activeChatSource === CHAT_SOURCES.REMINDERS) {
      return this.updateContactReminders;
    }
    else if (this.state.activeChatSource === CHAT_SOURCES.SCHEDULED_MSGS) {
      return this.updateScheduledMsgs;
    }
    else if (this.state.activeChatSource === CHAT_SOURCES.SYSTEM_MSGS) {
      return this.updateServiceMsgs;
    }
    return this.updateContactTimeline;
  }

  getContactMsgContext = (msgId, contact, searchQuery) =>
    this.props.getContactMessageContext(msgId, CHAT_TYPES.GIRL, contact, searchQuery, this.props.userTimezone);

  updateMsgContext = (activeRecipient, page, loadDirection) => this.props.updateContactMessageContext(this.props.contextMsgId, page, loadDirection, activeRecipient, this.props.userTimezone);

  getContactDateMsgContext = (date) =>
    this.props.getContactDateMsgContext(date, this.props.activeRecipient.id, CHAT_TYPES.GIRL, this.props.userTimezone);

  updateDateMsgContext = (activeRecipient, page, loadDirection) =>
    this.props.updateContactDateMsgContext(activeRecipient, page, loadDirection, this.props.contextDate, this.props.userTimezone);


  startMessageSearch = (query) =>
    this.props.searchContactMessage(query, this.props.activeRecipient, this.props.userTimezone);

  updateMsgSearch = (activeRecipient, page, loadDirection) =>
    this.props.updateContactMessageSearch(activeRecipient, page, loadDirection, this.props.search, this.props.userTimezone);

  stopMessageSearch = () => this.props.stopContactMessageSearch(this.props.activeRecipient);

  updateContactTimeline = (activeRecipient, page, loadDirection) =>
    this.props.updateContactTimeline(activeRecipient, page, loadDirection, this.props.userTimezone);

  updateGlobalContactMessageSearch = (activeRecipient, page, loadDirection) =>
    this.props.updateGlobalContactMessageSearch(activeRecipient, page, this.props.search, loadDirection, this.props.userTimezone);

  updateContactTimelineMedia = (activeRecipient, page, loadDirection) =>
    this.props.updateContactTimelineMedia(activeRecipient, page, loadDirection, this.props.userTimezone);

  updateContactReminders = (activeRecipient, page, loadDirection) =>
    this.props.updateContactReminders(activeRecipient, page, loadDirection, this.props.userTimezone);

  updateScheduledMsgs = (activeRecipient, page, loadDirection) =>
    this.props.updateScheduledMsgs(activeRecipient, page, loadDirection, this.props.userTimezone);

  updateServiceMsgs = (activeRecipient, page, loadDirection) =>
    this.props.updateServiceMsgs(activeRecipient, page, loadDirection, this.props.userTimezone);

  showTimePicker = async () => this.props.openModal(
    MODAL_TYPES.timePicker,
    {
      action: this.getContactDateMsgContext,
      maxDate: getDateByTimezoneOffset(this.props.userTimezone),
      userTimezone: this.props.userTimezone
    }
  );

  changeRecipientAudioStatus = (action, recipientId) =>
    API.setContactAudioStatus(action, recipientId)
      .then(({ data }) => {
        this.props.updateContactInState(data.caller, data.caller.type);
      })
      .catch(console.error);

  render() {
    const isMainTimelineOpen = this.props.search ||
      this.state.activeChatSource !== CHAT_SOURCES.MSGS ||
      this.props.contextMsgId ||
      this.props.contextDate
      ? false
      : true;

    const timelineLowerLoadedPage = !isMainTimelineOpen
      ? this.props.auxiliaryLowerLoadedPage
      : this.props.timelineLowerLoadedPage;

    const timelinePageCount = !isMainTimelineOpen
      ? this.props.auxiliaryPageCount
      : this.props.timelinePageCount;

    const timelineHigherLoadedPage = !isMainTimelineOpen
      ? this.props.auxiliaryHigherLoadedPage
      : this.props.timelineCurrentPage;

    const timelineCurrentPage = !isMainTimelineOpen
      ? this.props.auxiliaryCurrentPage
      : this.props.timelineCurrentPage;

    return this.props.activeRecipient
      ? (
        <Chat
          girlChat
          title={this.props.title}
          type={CHAT_TYPES.GIRL}
          activeRecipient={this.props.activeRecipient}
          updateActiveContact={this.props.updateActiveContact}
          profileId={this.props.userId}
          userTimezone={this.props.userTimezone}
          userHour12={this.props.userHour12}

          tabs={this.props.tabs}
          isTabsDraggable
          withUnfixedTab
          unfixedTab={this.props.unfixedTab}
          fixTab={this.props.fixGirlTab}
          removeContactTab={this.props.removeContactTab}
          removeContactTabsFromContextMenu={this.props.removeContactTabsFromContextMenu}

          timelinePending={this.props.timelinePending}
          updatePending={this.props.updatePending}
          isMainTimelineOpen={isMainTimelineOpen}
          timeline={this.timeline()}
          timelineCurrentPage={timelineCurrentPage}
          timelinePageCount={timelinePageCount}
          timelineHigherLoadedPage={timelineHigherLoadedPage}
          timelineLowerLoadedPage={timelineLowerLoadedPage}
          newInteractionType={this.props.newInteractionType}
          updateContactTimeline={this.updateTimeline()}

          msgTemplates={this.props.msgTemplates}
          shortcuts={this.props.shortcuts}
          typingStatus={this.props.typingStatus}

          search={this.props.search}
          startMessageSearch={this.startMessageSearch}
          stopMessageSearch={this.stopMessageSearch}
          isGlobalSearch={this.props.isGlobalSearch}
          startGlobalMsgSearch={this.props.searchGlobalContactMessage}
          showSearchQuery={this.props.showSearchQuery}

          contextMsgId={this.props.contextMsgId}
          getMessageContext={this.getContactMsgContext}

          contextDate={this.props.contextDate}
          cleanContactDateMsgContext={this.props.cleanContactDateMsgContext}
          showTimePickerForDateContext={this.state.activeChatSource !== CHAT_SOURCES.MSGS ? null : this.showTimePicker}

          pinnedMsg={this.props.pinnedMsg}
          pinMsg={this.props.pinClientMsg}
          unpinMsg={this.props.unpinClientMsg}

          activeChatSource={this.state.activeChatSource}
          setActiveChatSource={this.setActiveChatSource}

          removeMessageReminder={this.props.removeMessageReminder}

          replyMsg={this.replyMsg}
          repliedMsg={this.state.repliedMsg}
          cleanRepliedMsg={this.cleanRepliedMsg}

          editMsg={this.editMsg}
          editedMsg={this.state.editedMsg}
          cleanEditedMsg={this.cleanEditedMsg}

          voiceMsg={this.state.voiceMsg}
          updateVoiceMsg={this.updateVoiceMsg}

          images={this.state.images}
          updateImages={this.updateImages}
          cleanImages={this.cleanImages}

          videos={this.state.videos}
          updateVideos={this.updateVideos}
          cleanVideos={this.cleanVideos}

          addNewArrayGirlsToState={this.props.addNewArrayGirlsToState}
          sendMessage={this.props.sendMessage}
          openModal={this.props.openModal}
          playMedia={this.props.playMedia}
          markChatAsRead={this.props.markChatAsRead}
          changeRecipientAudioStatus={this.changeRecipientAudioStatus}
          callFromChat
          fullScreenMode={this.props.fullScreenMode}
          isMsgInput={true}
          isShowUndoTabs={true}
          isScheduledMsgsSource={this.state.activeChatSource === CHAT_SOURCES.SCHEDULED_MSGS}
          scheduledMsgsCount={this.props.scheduledMsgsCount}
          serviceMsgCount={this.props.serviceMsgCount}

          searchSource={this.props.girlsSearchSource}
        />
      )
      : <div className="chat">
        {this.props.title &&
          <div className="chat__title-wrap">
            <Title
              classPrefix="chat-title"
              icon="messages"
              text={this.props.title}
              count={this.props.tabs.length}
            />
          </div>
        }

        <ChatRestore
          recentTabs={this.props.recentTabs}
          type={CHAT_TYPES.GIRL}
        />
      </div>;
  }
}

const mapStateToProps = (state, ownProps) => ({
  active: state.girlChats.active,
  activeRecipient: state.contacts.entities[state.girlChats.active || ownProps.currentCallerId],
  tabs: state.girlChats.tabs,
  unfixedTab: state.girlChats.unfixedTab,
  recentTabs: state.girlChats.recentTabs,

  timeline: state.girlChats.timeline,
  timelinePending: state.girlChats.timelinePending,
  updatePending: state.girlChats.updatePending,
  timelineMedia: state.girlChats.timelineMedia,
  timelineCurrentPage: state.girlChats.timelineCurrentPage,
  timelinePageCount: state.girlChats.timelinePageCount,
  timelineLowerLoadedPage: state.girlChats.timelineLowerLoadedPage,
  newInteractionType: state.girlChats.newInteractionType,

  auxiliaryTimeline: state.girlChats.auxiliaryTimeline,
  auxiliaryLowerLoadedPage: state.girlChats.auxiliaryLowerLoadedPage,
  auxiliaryPageCount: state.girlChats.auxiliaryPageCount,
  auxiliaryHigherLoadedPage: state.girlChats.auxiliaryHigherLoadedPage,
  auxiliaryCurrentPage: state.girlChats.auxiliaryCurrentPage,

  reminders: state.girlChats.reminders,

  isGlobalSearch: state.girlChats.isGlobalMsgSearch,
  showSearchQuery: state.girlChats.showSearchQuery,

  msgTemplates: state.msgTemplates.girlsTemplates,
  shortcuts: state.msgTemplates.shortcuts,

  search: state.girlChats.search,

  contextMsgId: state.girlChats.contextMsgId,
  contextDate: state.girlChats.contextDate,

  userId: state.user.id,
  userTimezone: selectUserTimezone(state),
  userHour12: state.user.hour12,

  pinnedMsg: state.girlChats.pinnedMsg,

  typingStatus: state.typingOperators.typingOperators[state.girlChats.active + '_' + CHAT_TYPES.GIRL],
  scheduledMsgsCount: state.girlChats.scheduledMsgsCount,
  serviceMsgCount: state.girlChats.serviceMsgCount,

  girlsSearchSource: state.contacts.girls.searchSource,
  girlsSearch: state.contacts.girls.search,

});


const mapDispatchToProps = {
  updateContactInState,
  getContactTimeline,
  updateContactTimeline,
  updateContactMessageSearch,
  updateActiveContact,
  sendMessage,
  removeContactTab,
  removeContactTabsFromContextMenu,
  getContactMessageContext,
  markChatAsRead: markContactChatAsRead,
  getTimelineMedia,
  getContactReminders,
  cleanContactReminders,
  cleanTimelineMedia,
  updateContactTimelineMedia,
  stopContactMessageSearch,
  searchContactMessage,
  searchGlobalContactMessage,
  updateGlobalContactMessageSearch,
  pinClientMsg,
  unpinClientMsg,
  fixGirlTab,
  playMedia,
  removeMessageReminder,
  updateContactMessageContext,
  updateContactReminders,
  getContactDateMsgContext,
  cleanContactDateMsgContext,
  updateContactDateMsgContext,
  openModal,
  addNewArrayGirlsToState,

  getScheduledMsgs,
  updateScheduledMsgs,
  cleanScheduledMsgs,
  getScheduledMsgsCount,

  getServiceMsgs,
  updateServiceMsgs,
  cleanServiceMsgs,
  getServiceMsgsCount,
};

export default connect(mapStateToProps, mapDispatchToProps)(GirlChat);