import API from "../api/api";

import {
  NEW_OUTGOING_CALL,
  NEW_INCOMING_CALL,
  NEW_INCOMING_QUEUE_CALL,
  GET_ALL_CALLS
} from "./calls";
// import {
//   GET_SEARCHED_CLIENTS,
//   GET_SEARCHED_GIRLS,
//   STOP_CLIENT_SEARCH,
//   UPDATE_SEARCHED_CLIENTS,
//   UPDATE_SEARCHED_GIRLS,
//   STOP_GIRL_SEARCH
// } from "./search";
import {
  UPDATE_ACTIVE_GIRL,
  UNREAD_GIRL_MESSAGES,
  SET_GIRLS_RECENT_TABS,
} from "./girlChats";
import {
  NEW_INCOMING_MESSAGE,
  NEW_OUTGOING_MESSAGE,
  UPDATE_ACTIVE_CLIENT,
  UNREAD_CLIENT_MESSAGES,
  SET_CLIENTS_RECENT_TABS,
  DELETE_TELEGRAM_MSG,
} from "./clientChats";
import { normalizeContacts, addToEntitiesIfMissing } from "../utils";
import LS from '../utils/localStorageAPI';
import { GET_CHAT_TIMELINE, SET_ROOMS_RECENT_TABS, UPDATE_CHAT_TIMELINE } from "./roomChats";
import { CHAT_TYPES, CONTACT_TYPES } from "../config/constants";
import { selectRecentTabs } from "../selectors/selectors";
import {
  FILTER_CONTACTS_LIST,
  GET_CONTACTS_LIST,
  UPDATE_CONTACTS_LIST,
  UPDATE_FILTERED_CONTACTS_LIST
} from "./addressBook";

export const GET_CONTACTS_PENDING = "GET_CONTACTS_PENDING";
export const GET_ALL_CONTACTS_LIST = "GET_ALL_CONTACTS_LIST";
export const UPDATE_CLIENTS_LIST = "UPDATE_CLIENTS_LIST";
export const UPDATE_GIRLS_LIST = "UPDATE_GIRLS_LIST";

export const ADD_CONTACTS_TO_ENTITIES = "ADD_CONTACTS_TO_ENTITIES";

export const GET_CLIENTS_FROM_TABS = "GET_CLIENTS_FROM_TABS";
export const GET_GIRLS_FROM_TABS = "GET_GIRLS_FROM_TABS";

export const GET_CLIENTS_BY_ID = "GET_CLIENTS_BY_ID";
export const GET_GIRLS_BY_ID = "GET_GIRLS_BY_ID";

export const CREATE_CLIENT = "CREATE_CLIENT";
export const CREATE_GIRL = "CREATE_GIRL";
export const UPDATE_CLIENT = "UPDATE_CLIENT";
export const UPDATE_GIRL = "UPDATE_GIRL";
export const REMOVE_CLIENT = "REMOVE_CLIENT";
export const REMOVE_GIRL = "REMOVE_GIRL";

export const ADD_AGENT_DATA = "ADD_AGENT_DATA";
export const REMOVE_AGENT_DATA = "REMOVE_AGENT_DATA";
export const UPDATE_AGENTS_GIRL_IDS = "UPDATE_AGENTS_GIRL_IDS";

export const TOGGLE_CLIENT_PIN = "TOGGLE_CLIENT_PIN";
export const TOGGLE_GIRL_PIN = "TOGGLE_GIRL_PIN";
export const TOGGLE_CLIENT_MARK = "TOGGLE_CLIENT_MARK";
export const TOGGLE_GIRL_MARK = "TOGGLE_GIRL_MARK";
export const TOGGLE_CLIENT_BLOCK = "TOGGLE_CLIENT_BLOCK";
export const TOGGLE_GIRL_BLOCK = "TOGGLE_GIRL_BLOCK";
export const TOGGLE_CLIENT_ACTIVE_CHAT = "TOGGLE_CLIENT_ACTIVE_CHAT";
export const TOGGLE_GIRL_ACTIVE_CHAT = "TOGGLE_GIRL_ACTIVE_CHAT";

export const ADD_CLIENT_TAG = "ADD_CLIENT_TAG";
export const REMOVE_CLIENT_TAG = "REMOVE_CLIENT_TAG";
export const ADD_GIRL_TAG = "ADD_GIRL_TAG";
export const REMOVE_GIRL_TAG = "REMOVE_GIRL_TAG";
export const CHANGE_GIRL_AGENT = "CHANGE_GIRL_AGENT";

export const FILTER_CLIENTS = "FILTER_CLIENTS";
export const FILTER_GIRLS = "FILTER_GIRLS";
export const REMOVE_CLIENT_FILTER = "REMOVE_CLIENT_FILTER";
export const REMOVE_GIRL_FILTER = "REMOVE_GIRL_FILTER";

export const SET_CONTACTS_AND_UNREAD_MESSAGES_COUNT = 'SET_CONTACTS_AND_UNREAD_MESSAGES_COUNT';
export const ADD_ARRAY_GIRLS = "ADD_ARRAY_GIRLS";

export const GET_SEARCHED_CLIENTS = 'GET_SEARCHED_CLIENTS';
export const GET_SEARCHED_GIRLS = 'GET_SEARCHED_GIRLS';
export const STOP_CLIENT_SEARCH = 'STOP_CLIENT_SEARCH';
export const UPDATE_SEARCHED_CLIENTS = 'UPDATE_SEARCHED_CLIENTS';
export const UPDATE_SEARCHED_GIRLS = 'UPDATE_SEARCHED_GIRLS';
export const STOP_GIRL_SEARCH = 'STOP_GIRL_SEARCH';

export const CLEAN_CLIENTS_UNREAD = 'CLEAN_CLIENTS_UNREAD';
export const CLEAN_GIRLS_UNREAD = 'CLEAN_GIRLS_UNREAD';

export const TOGGLE_CONTACTS_SEARCH_SOURCE = 'TOGGLE_CONTACTS_SEARCH_SOURCE';

export const CONTACTS_LIMIT = 20;
export const GIRLS_LIMIT = 20;
export const CLIENTS = 'clients';
export const GIRLS = 'girls';
export const AGENTS = 'agents';
export const SERVICES = 'services';

export const ALL_FILTER = 'All chats';
export const UNREAD_FILTER = 'Unread chats';
export const MARKED_FILTER = 'Marked chats';
export const RELATED_FILTER = 'Related contacts';
export const AVAILABLE_GIRLS_FILTER = 'Available girls';
export const SERVICES_FILTER = 'Show only hotels/services';
export const AGENTS_FILTER = 'Show only agents';
export const NOT_TEXTED_FOR_LONG = 'Not texted for long';
export const ACTIVE_CHATS = 'Active chats';


export const getContactsById = (idsForRequest, type, forWhat, active, userId) => dispatch => {
  return API.getContactsById(idsForRequest)
    .then(res => {
      let contacts = normalizeContacts(res.data, false);
      const sortedRelatedIds = [];

      if (forWhat === "tabs") {
        // check if we have in chatTabs irrelevant ids. It can happens when another operator change type or remove contact , witch in our localStorage
        let relevantIds = [];
        // let removedIds = [];   // if we need to use removedIds => uncomment

        idsForRequest.forEach(id => {
          if (!contacts.entities[id]) {
            // it means that contactId witch was in our localStorage was removed
            // removedIds.push(id);
          } else if (
            contacts.entities[id].type === type ||
            contacts.entities[id].type === 3
          ) {
            // this is quick hack for checking agents
            relevantIds.push(id); // removed ids does not get here
          }
        });

        const isAnyContactTypeChanged =
          relevantIds.length !== idsForRequest.length;


        idsForRequest.forEach(id => {
          if (relevantIds.includes(id)) {
            sortedRelatedIds.push(id);
          }
        });

        if (isAnyContactTypeChanged) {
          contacts = { entities: contacts.entities, ids: sortedRelatedIds };
        }
      }

      const isRemovedIds = idsForRequest.length !== contacts.ids.length;

      if (forWhat === 'recent-tabs' && isRemovedIds) {
        dispatch(updateRecentTabs(null, type, contacts.ids));
      }
      else if (forWhat === "tabs") {
        return dispatch(
          getContactsFromChatTabs(
            { entities: contacts.entities, ids: sortedRelatedIds, active },
            type
          )
        );
      }

      dispatch({
        type: type === 1 ? GET_CLIENTS_BY_ID : GET_GIRLS_BY_ID,
        payload: contacts
      });
    })
    .catch(console.error);
};

export const updateRecentTabs = (newRecentTabs, type, relevantTabsIds) => (dispatch, getState) => {
  if (!newRecentTabs && !relevantTabsIds) return;

  let actionRecentTabsType, LSRecentTabsKey;
  let recentTabs = {
    all: [], // all recent tabs we worked with
    visible: [], // tabs that can be returned by undo button.
  };

  switch (type) {
    case CHAT_TYPES.CLIENT:
      actionRecentTabsType = SET_CLIENTS_RECENT_TABS;
      LSRecentTabsKey = 'clientChatsRecentTabs';
      break;
    case CHAT_TYPES.GIRL:
      actionRecentTabsType = SET_GIRLS_RECENT_TABS;
      LSRecentTabsKey = 'girlChatsRecentTabs';
      break;
    default:
      actionRecentTabsType = SET_ROOMS_RECENT_TABS;
      LSRecentTabsKey = 'roomChatsRecentTabs';
  };

  //if we change recent tabs
  if (newRecentTabs && !relevantTabsIds) {
    recentTabs = { ...newRecentTabs };
  }
  //if we remove deleted recent tabs
  else if (!newRecentTabs && relevantTabsIds) {
    const recentTabsFromState = selectRecentTabs(getState(), type);

    const getIsDeletedTab = (tab) => relevantTabsIds.includes(tab);

    recentTabs.all = recentTabsFromState.all.filter(getIsDeletedTab);
    recentTabs.visible = recentTabsFromState.visible.filter(getIsDeletedTab);
  }

  dispatch({
    type: actionRecentTabsType,
    payload: recentTabs,
  });

  LS.setItem(LSRecentTabsKey, recentTabs, getState().user.id);
}

const getContactsFromChatTabs = (contacts, type) => dispatch => {
  dispatch({
    type: type === 1 ? GET_CLIENTS_FROM_TABS : GET_GIRLS_FROM_TABS,
    payload: contacts
  });
};

export const addContactsToEntities = (contacts) => dispatch => {
  dispatch({
    type: ADD_CONTACTS_TO_ENTITIES,
    payload: contacts
  })
}

export const setContactsAndUnreadMessagesCount = (data) => (dispatch) => {
  const clientsData = {
    unreadCount: data.unreadClientMessages,
    count: data.totalClients,
  };

  const girlsData = {
    unreadCount: data.unreadGirlMessages,
    count: data.totalOthers
  };

  dispatch({
    type: SET_CONTACTS_AND_UNREAD_MESSAGES_COUNT,
    payload: { clientsData, girlsData }
  });
}

export const getContactListPending = (type) => dispatch => {
  dispatch({
    type: GET_CONTACTS_PENDING,
    payload: type
  });
}

export const getAllContactsList = () => dispatch => {
  dispatch(getContactListPending());

  API.getAllContactsList()
    .then(({ clients, girls, agents }) => {
      const girlsData = normalizeContacts(girls.data.contacts);

      const clientsData = normalizeContacts(clients.data.contacts);

      const agentsData = normalizeContacts(agents.data.contacts, false);

      dispatch({
        type: GET_ALL_CONTACTS_LIST,
        payload: {
          clients: clientsData,
          girls: girlsData,
          agents: agentsData
        }
      });
    })
    .catch(err => console.log(err));
};

export const updateContactsList = (type, offset) => dispatch => {
  API.getContactList(type, offset)
    .then(res => {
      dispatch({
        type: type === 1 ? UPDATE_CLIENTS_LIST : UPDATE_GIRLS_LIST,
        payload: normalizeContacts(res.data.contacts)
      });
    })
    .catch(err => console.log(err));
};

export const addNewContact = contact => dispatch => {
  return API.saveContact(contact, "create");
};

export const addNewArrayGirlsToState = contacts => dispatch => {
  if (!contacts.length) {
    return;
  }


  dispatch({
    type: ADD_ARRAY_GIRLS,
    payload: contacts,
  });
};

export const addNewContactToState = contact => dispatch => {
  dispatch({
    type: contact.type === 1 ? CREATE_CLIENT : CREATE_GIRL,
    payload: normalizeContacts(contact)
  });
};

export const updateContact = (contact, initialType) => dispatch => {
  return API.saveContact(contact, "update", initialType);
};

export const changeGirlAgent = (girlId, agentId) => dispatch => {
  dispatch({
    type: CHANGE_GIRL_AGENT,
    payload: { girlId, agentId }
  });
};

export const updateContactFieldsById = (contactId, updatedFields) => (
  dispatch,
  getState
) => {
  const contact = getState().contacts.entities[contactId];

  if (!contact) return;

  const initialType = contact.type;

  const payload = {
    ...normalizeContacts({
      ...contact,
      ...updatedFields
    })
  };

  dispatch({
    type: initialType === 1 ? UPDATE_CLIENT : UPDATE_GIRL,
    payload
  });
}

export const updateContactInState = (contact, initialType, isTypeChanged) => (
  dispatch,
  getState
) => {
  const payload = {
    ...normalizeContacts(contact),
    isTypeChanged
  };

  if (isTypeChanged) {
    // if typeChanged we need to know if contact was in previous Chat tabs
    const activeList = getState().contacts.entities; // if contact was in our list

    const wasContactInList = activeList[contact.id] // was contact in contacts list
      ? true
      : false;

    payload.wasContactInList = wasContactInList;

    const activeContactId =
      initialType === 1
        ? getState().clientChats.active
        : getState().girlChats.active;

    const isActive = activeContactId === contact.id ? true : false;

    if (isActive) {
      // if contact isActive it automatically means he was tabs
      payload.isActive = true;
      payload.wasContactInTabs = true;
    } else if (!isActive) {
      // if contact not active and changed type => get if he in tabs
      const activeTabs =
        initialType === 1
          ? getState().clientChats.tabs
          : getState().girlChats.tabs;

      payload.wasContactInTabs =
        activeTabs.indexOf(contact.id) === -1 ? false : true;
    }
  }

  dispatch({
    type: initialType === 1 ? UPDATE_CLIENT : UPDATE_GIRL,
    payload
  });
};

export const removeContact = contactId => dispatch => {
  API.removeContact(contactId).catch(err => console.log(err));
};

export const removeContactFromState = (contact) => dispatch => {
  dispatch({
    type: contact.type === 1 ? REMOVE_CLIENT : REMOVE_GIRL,
    payload: contact.id
  });
};

export const removeAgentData = contact => dispatch => {
  dispatch({
    type: REMOVE_AGENT_DATA,
    payload: contact
  });
};

export const addAgentData = agentId => dispatch => {
  dispatch({
    type: ADD_AGENT_DATA,
    payload: agentId
  });
};

export const updateAgentsGirlIds = (
  prevAgentId,
  newAgentId,
  girlId
) => dispatch => {
  dispatch({
    type: UPDATE_AGENTS_GIRL_IDS,
    payload: {
      prevAgentId,
      newAgentId,
      girlId
    }
  });
};

export const toggleContactPin = contact => dispatch => {
  return API.toggleContactPin(contact)
    .then(res => {
      dispatch({
        type: contact.type === 1 ? TOGGLE_CLIENT_PIN : TOGGLE_GIRL_PIN,
        payload: contact.id
      });
    })
    .catch(console.log);
};

export const toggleContactMark = contact => dispatch => {
  return API.toggleContactMark(contact)
    .then(res => {
      dispatch({
        type: contact.type === 1 ? TOGGLE_CLIENT_MARK : TOGGLE_GIRL_MARK,
        payload: contact.id
      });
    })
    .catch(err => console.log);
};

export const toggleContactBlock = (contact, reason = "") => dispatch => {
  return API.toggleContactBlock(contact, reason)
    .then(res => {
      if (!contact.blocked) {
        // dispatch(showBlockContactForm());
      }
    })
    .catch(err => {
      const res = err && err.response && err.response.data;

      return {
        isFailed: true,
        msg: !!res.message
          ? JSON.parse(res.message)
          : {},
      };
    });
};

export const addContactTag = (contactId, tag) => API.addContactTag(contactId, tag);

export const removeContactTag = (contactId, tag) => API.removeContactTag(contactId, tag);

export const toggleContactActiveChat = contact => dispatch => {
  dispatch({
    type: contact.type === 1 ? TOGGLE_CLIENT_ACTIVE_CHAT : TOGGLE_GIRL_ACTIVE_CHAT,
    payload: contact
  });
};

export const getTopTags = () =>
  API.getTopTags()
    .then(res => {
      return res.data.map(
        item =>
        (item = {
          id: item.id,
          label: item.title,
          value: item.title,
          color: item.color
        })
      );
    })
    .catch(err => {
      console.log(err);

      return [];
    });

export const searchTags = query =>
  API.searchTags(query)
    .then(res => {
      if (res.data.length) {
        return res.data.map(
          item =>
          (item = {
            id: item.id,
            label: item.title,
            value: item.title,
            color: item.color
          })
        );
      }
      return [];
    })
    .catch(err => console.log(err));

export const getContactsWithUnread = (activeTab, offset = 0) => dispatch => {
  dispatch(getContactListPending(activeTab));

  const contactType = activeTab === GIRLS ? 2 : 1;

  return API.getFilteredContacts("unread", contactType, offset)
    .then(res => {
      const normalizedFilter = normalizeContacts(res.data, false);

      dispatch({
        type: activeTab === GIRLS
          ? FILTER_GIRLS
          : FILTER_CLIENTS,
        payload: { data: normalizedFilter, offset, filter: UNREAD_FILTER }
      });
    })
    .catch(err => console.log(err));
};

export const getMarkedContacts = (activeTab, offset = 0) => dispatch => {
  dispatch(getContactListPending(activeTab));

  const contactType = activeTab === GIRLS ? 2 : 1;

  return API.getFilteredContacts("marked", contactType, offset)
    .then(res => {
      const normalizedFilter = normalizeContacts(res.data, false);

      dispatch({
        type: activeTab === GIRLS
          ? FILTER_GIRLS
          : FILTER_CLIENTS,
        payload: { data: normalizedFilter, offset, filter: MARKED_FILTER }
      });
    })
    .catch(err => console.log(err));
};

export const getRelatedContacts = (activeTab, offset = 0) => dispatch => {
  dispatch(getContactListPending(activeTab));

  const contactType = activeTab === GIRLS ? 2 : 1;

  return API.getFilteredContacts('related', contactType, offset)
    .then(res => {
      const normalizedFilter = normalizeContacts(res.data, false);

      dispatch({
        type: activeTab === GIRLS
          ? FILTER_GIRLS
          : FILTER_CLIENTS,
        payload: { data: normalizedFilter, offset, filter: RELATED_FILTER }
      });
    })
    .catch(err => console.log(err));
};

export const getActiveChats = (activeTab, offset = 0) => dispatch => {
  dispatch(getContactListPending(activeTab));

  const contactType = activeTab === GIRLS ? 2 : 1;

  return API.getActiveChatContacts(contactType, offset)
    .then(res => {
      const normalizedFilter = normalizeContacts(res.data, false);

      dispatch({
        type: activeTab === GIRLS
          ? FILTER_GIRLS
          : FILTER_CLIENTS,
        payload: {data: normalizedFilter, offset, filter: ACTIVE_CHATS}
      });
    })
    .catch(err => console.log(err));
};

export const getAvailableGirls = (activeTab, offset = 0) => dispatch => {
  dispatch(getContactListPending(activeTab));

  return API.getFilteredContacts('available', 2, offset)
    .then(res => {
      const normalizedFilter = normalizeContacts(res.data, false);

      dispatch({
        type: FILTER_GIRLS,
        payload: { data: normalizedFilter, offset, filter: AVAILABLE_GIRLS_FILTER }
      });
    })
    .catch(console.error);
};

export const getOnlyHotels = (activeTab, offset = 0) => dispatch => {
  dispatch(getContactListPending(activeTab));

  return API.getContactList(4, offset)
    .then(res => {
      const normalizedFilter = normalizeContacts(res.data.contacts, false);

      dispatch({
        type: FILTER_GIRLS,
        payload: { data: normalizedFilter, offset, filter: SERVICES_FILTER }
      });
    })
    .catch(err => console.log(err));
};

export const getOnlyAgents = (activeTab, offset = 0) => dispatch => {
  dispatch(getContactListPending(activeTab));

  return API.getContactList(3, offset)
    .then(res => {
      const normalizedFilter = normalizeContacts(res.data.contacts, false);

      dispatch({
        type: FILTER_GIRLS,
        payload: { data: normalizedFilter, offset, filter: AGENTS_FILTER }
      });
    })
    .catch(err => console.log(err));
};

export const getNotTextedForLong = (activeTab, offset = 0) => dispatch => {
  dispatch(getContactListPending(activeTab));

  return API.getActiveDivaProfiles(offset)
    .then(res => {
      const normalizedFilter = normalizeContacts(res.data, false);

      dispatch({
        type: FILTER_GIRLS,
        payload: { data: normalizedFilter, offset, filter: NOT_TEXTED_FOR_LONG }
      });
    })
    .catch(err => console.log(err));
}

export const removeContactFilter = (activeTab) => dispatch => (
  dispatch({
    type: activeTab === GIRLS
      ? REMOVE_GIRL_FILTER
      : REMOVE_CLIENT_FILTER
  })
);

export const searchContactsByType = (query, activeTab, searchSource, cancelToken) => dispatch => {
  dispatch(getContactListPending(activeTab));

  const type = activeTab === CLIENTS ? 1 : 2;

  const promise = searchSource === 'msgs'
    ? API.searchContactsByMsg(type, query, cancelToken)
    : API.searchContacts(type, query, 0, CONTACTS_LIMIT, cancelToken);

  return promise.then(({ data }) => {
    dispatch({
      type: activeTab === CLIENTS
        ? GET_SEARCHED_CLIENTS
        : GET_SEARCHED_GIRLS,
      payload: { data: data || [], query }
    });
  })
  .catch(console.log);
};

export const updateSearchContactsByType = (type, offset, query) => (dispatch) => {
  API.searchContacts(type, query, offset)
    .then(res => {
      dispatch({
        type: type === 1
          ? UPDATE_SEARCHED_CLIENTS
          : UPDATE_SEARCHED_GIRLS,
        payload: res.data
      });
    })
    .catch(err => console.log(err));
};

export const stopContactsSearchByType = (activeTab) => dispatch => {
  dispatch({
    type: activeTab === CLIENTS
      ? STOP_CLIENT_SEARCH
      : STOP_GIRL_SEARCH
  });
};

export const cleanContactsUnreadCount = (activeTab) => dispatch => {
  return API.cleanContactsUnreadCount(activeTab)
    .then(res => {
      dispatch({
        type: activeTab === CLIENTS
          ? CLEAN_CLIENTS_UNREAD
          : CLEAN_GIRLS_UNREAD,
        payload: activeTab
      })
    })
    .catch(console.error);
}

export const toggleSearchSource = (activeTab) => dispatch => {
  dispatch({
    type: TOGGLE_CONTACTS_SEARCH_SOURCE,
    payload: activeTab,
  })
}

const getLsSearchClients = () => LS.getItem('clientsSearch')
  ? LS.getItem('clientsSearch')
  : '';

const getLsSearchGirls = () => LS.getItem('girlsSearch')
  ? LS.getItem('girlsSearch')
  : '';

const initialState = {
  agentsIds: [],
  entities: {
    new_chat: {
      id: "new_chat",
      type: 1
    }
  },

  clients: {
    ids: [],
    pinIds: [],
    totalCount: null,
    activeFilter: ACTIVE_CHATS,
    filteredIds: [],
    search: getLsSearchClients(),
    searchedIds: [],
    pending: false,
    searchSource: 'contacts',
  },
  girls: {
    ids: [],
    pinIds: [],
    totalCount: null,
    activeFilter: ACTIVE_CHATS,
    filteredIds: [],
    search: getLsSearchGirls(),
    searchedIds: [],
    pending: false,
    searchSource: 'contacts',
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CLIENTS_BY_ID:
    case GET_GIRLS_BY_ID:
    case GET_GIRLS_FROM_TABS:
    case GET_CLIENTS_FROM_TABS: {
      return {
        ...state,
        entities: addToEntitiesIfMissing(
          state.entities,
          action.payload.entities,
          "id"
        )
      };
    }

    case GET_CONTACTS_PENDING: {
      if (action.payload) {
        return {
          ...state,
          [action.payload]: {
            ...state[action.payload],
            pending: true
          }
        }
      }
      return {
        ...state,
        clients: {
          ...state.clients,
          pending: true
        },
        girls: {
          ...state.girls,
          pending: true
        }
      };
    }

    case SET_CONTACTS_AND_UNREAD_MESSAGES_COUNT: {
      return {
        ...state,
        clients: {
          ...state.clients,
          totalCount: action.payload.clientsData.count,
          unreadCount: action.payload.clientsData.unreadCount,
        },
        girls: {
          ...state.girls,
          totalCount: action.payload.girlsData.count,
          unreadCount: action.payload.girlsData.unreadCount,
        },
      }
    }

    case GET_ALL_CONTACTS_LIST: {
      const newEntities = {
        ...action.payload.clients.entities,
        ...action.payload.girls.entities,
        ...action.payload.agents.entities
      };

      return {
        ...state,
        entities: addToEntitiesIfMissing(state.entities, newEntities, "id"),
        clients: {
          ...state.clients,
          ids: action.payload.clients.ids,
          pinIds: action.payload.clients.pinIds
            ? action.payload.clients.pinIds
            : [],
          pending: false
        },
        girls: {
          ...state.girls,
          ids: action.payload.girls.ids,
          pinIds: action.payload.girls.pinIds
            ? action.payload.girls.pinIds
            : [],
          pending: false
        },
        agentsIds: action.payload.agents.ids
      };
    }

    case UPDATE_CLIENTS_LIST: {
      return updateContactListHelper(state, action.payload, CLIENTS);
    }

    case UPDATE_GIRLS_LIST: {
      return updateContactListHelper(state, action.payload, GIRLS);
    }

    case UPDATE_ACTIVE_GIRL:
    case UPDATE_ACTIVE_CLIENT: {
      const activeContact = state.entities[action.payload.contact.id];

      if (!activeContact) {
        return {
          ...state,
          entities: {
            ...state.entities,
            [action.payload.contact.id]: action.payload.contact
          }
        };
      }

      const contactType = activeContact.type === 1 ? "clients" : "girls";

      if (!activeContact.unreadCount) {
        return state;
      }

      const updatedEntities = { ...state.entities };

      // reset unreadCount
      updatedEntities[activeContact.id] = { ...activeContact, unreadCount: 0 };

      return {
        ...state,
        entities: updatedEntities,
        [contactType]: {
          ...state[contactType],
          unreadCount: state[contactType].unreadCount - activeContact.unreadCount
        }
      };
    }

    case CREATE_CLIENT: {
      if (!state.clients.ids.includes(action.payload.ids[0])) {
        return createContactHelper(state, action.payload, CLIENTS);
      }
      return state;
    }

    case CREATE_GIRL: {
      return createContactHelper(state, action.payload, GIRLS);
    }

    case UPDATE_CLIENT: {
      if (action.payload.isTypeChanged && action.payload.wasContactInList) {
        // if type was changed and was in list

        let cPrevIds = state.clients.ids;
        let cPrevPinIds = state.clients.pinIds;

        let updatedSearchedIds = state.clients.searchedIds;
        let updatedFilteredIds = state.clients.filteredIds;

        const updatedEntities = {
          ...state.entities,
          ...action.payload.entities
        };

        let updatedContactId;

        if (action.payload.ids) {
          updatedContactId = action.payload.ids[0];

          cPrevIds = state.clients.ids.filter(id => id !== updatedContactId); // remove from ids
        } else if (action.payload.pinIds) {
          updatedContactId = action.payload.pinIds[0];

          cPrevPinIds = state.clients.pinIds.filter(
            id => id !== updatedContactId
          ); // remove from pinned
        }

        // if active filter or search
        if (updatedSearchedIds.includes(updatedContactId)) {
          updatedSearchedIds = updatedSearchedIds.filter(id => id !== updatedContactId);
        }
        if (updatedFilteredIds.includes(updatedContactId)) {
          updatedFilteredIds = updatedFilteredIds.filter(id => id !== updatedContactId);
        }

        let gPrevIds = state.girls.ids;
        let gPrevPinIds = state.girls.pinIds;

        if (action.payload.ids) {
          // add to  ids
          gPrevIds = sortContactIdsByDate(updatedEntities, [
            updatedContactId,
            ...state.girls.ids
          ]);
        } else if (action.payload.pinIds) {
          // add to pinnedIds
          gPrevPinIds = [...state.girls.pinIds, updatedContactId];
        }

        const newGirlsUnreadCount = unreadCountCalcHelper(
          state.girls.unreadCount,
          action.payload,
          "+"
        );

        const newClientsUnreadCount = unreadCountCalcHelper(
          state.clients.unreadCount,
          action.payload,
          "-"
        );

        return {
          ...state,
          entities: updatedEntities,
          clients: {
            ...state.clients,
            ids: cPrevIds,
            pinIds: cPrevPinIds,
            searchedIds: updatedSearchedIds,
            filteredIds: updatedFilteredIds,
            totalCount: state.clients.totalCount - 1,
            unreadCount: newClientsUnreadCount
          },
          girls: {
            ...state.girls,
            ids: gPrevIds,
            pinIds: gPrevPinIds,
            totalCount: state.girls.totalCount + 1,
            unreadCount: newGirlsUnreadCount
          }
        };
      }

      // if contact in list
      const updatedContactId = action.payload.ids
        ? action.payload.ids[0]
        : action.payload.pinIds[0];


      if (state.entities[updatedContactId]) {
        const updatedEntity = action.payload.entities[updatedContactId];

        updatedEntity.unreadCount = state.entities[updatedContactId].unreadCount;

        return {
          ...state,
          entities: { ...state.entities, ...action.payload.entities, updatedEntity }
        };
      }

      return state;
    }

    case UPDATE_GIRL: {
      if (action.payload.isTypeChanged && action.payload.wasContactInList) {
        // if type was changed and was in list

        const updatedEntities = {
          ...state.entities,
          ...action.payload.entities
        };

        let gPrevIds = state.girls.ids;
        let gPrevPinIds = state.girls.pinIds;

        let updatedSearchedIds = state.girls.searchedIds;
        let updatedFilteredIds = state.girls.filteredIds;

        let updatedContactId;

        if (action.payload.ids) {
          updatedContactId = action.payload.ids[0];

          gPrevIds = state.girls.ids.filter(id => id !== updatedContactId); // remove from ids
        } else if (action.payload.pinIds) {
          updatedContactId = action.payload.pinIds[0];

          gPrevPinIds = state.girls.pinIds.filter(
            id => id !== updatedContactId
          ); // remove from pinned
        }

        // if active filter or search
        if (updatedSearchedIds.includes(updatedContactId)) {
          updatedSearchedIds = updatedSearchedIds.filter(id => id !== updatedContactId);
        }
        if (updatedFilteredIds.includes(updatedContactId)) {
          updatedFilteredIds = updatedFilteredIds.filter(id => id !== updatedContactId);
        }

        let cPrevIds = state.clients.ids;
        let cPrevPinIds = state.clients.pinIds;

        if (action.payload.ids) {
          // add to  ids
          cPrevIds = sortContactIdsByDate(updatedEntities, [
            updatedContactId,
            ...state.clients.ids
          ]);
        } else if (action.payload.pinIds) {
          // add to pinnedIds
          cPrevPinIds = [...state.clients.pinIds, updatedContactId];
        }

        const updatedAgentsIds =
          action.payload.entities[updatedContactId].type === 3 // was girl => now agent
            ? [...state.agentsIds, updatedContactId]
            : state.agentsIds;

        const newGirlsUnreadCount = unreadCountCalcHelper(
          state.girls.unreadCount,
          action.payload,
          "-"
        );

        const newClientsUnreadCount = unreadCountCalcHelper(
          state.clients.unreadCount,
          action.payload,
          "+"
        );

        return {
          ...state,
          entities: updatedEntities,
          girls: {
            ...state.girls,
            ids: gPrevIds,
            pinIds: gPrevPinIds,
            searchedIds: updatedSearchedIds,
            filteredIds: updatedFilteredIds,
            totalCount: state.girls.totalCount - 1,
            unreadCount: newGirlsUnreadCount
          },
          clients: {
            ...state.clients,
            ids: cPrevIds,
            pinIds: cPrevPinIds,
            totalCount: state.clients.totalCount + 1,
            unreadCount: newClientsUnreadCount
          },
          agentsIds: updatedAgentsIds
        };
      }
      // if contact in list
      const updatedContactId = action.payload.ids
        ? action.payload.ids[0]
        : action.payload.pinIds[0];

      if (state.entities[updatedContactId]) {
        const updatedEntity = action.payload.entities[updatedContactId];

        updatedEntity.unreadCount = state.entities[updatedContactId].unreadCount;

        return {
          ...state,
          entities: { ...state.entities, ...action.payload.entities }
        };
      }
      return state;
    }

    case REMOVE_CLIENT: {
      return removeContactHelper(state, action.payload, CLIENTS);
    }

    case REMOVE_GIRL: {
      return removeContactHelper(state, action.payload, GIRLS);
    }

    case NEW_INCOMING_QUEUE_CALL:
    case NEW_INCOMING_CALL:
    case NEW_INCOMING_MESSAGE:
    case UNREAD_GIRL_MESSAGES:
    case UNREAD_CLIENT_MESSAGES: {
      return onNewInteraction(state, action.payload.interaction, "inc");
    }

    case NEW_OUTGOING_CALL:
    case NEW_OUTGOING_MESSAGE: {
      return onNewInteraction(state, action.payload.interaction, "out");
    }

    case ADD_AGENT_DATA: {
      return {
        ...state,
        agentsIds: [...state.agentsIds, action.payload]
      };
    }

    case REMOVE_AGENT_DATA: {
      let updatedEntities = state.entities;

      if (action.payload.girlsIds.length) {
        updatedEntities = { ...state.entities };

        action.payload.girlsIds.forEach(id => {
          updatedEntities[id] = {
            ...updatedEntities[id],
            agentId: null
          };
        });
      }

      return {
        ...state,
        entities: updatedEntities,
        agentsIds: state.agentsIds.filter(id => id !== action.payload.id)
      };
    }

    case CHANGE_GIRL_AGENT: {
      if (!state.entities[action.payload.girlId]) {
        return state;
      }

      return {
        ...state,
        entities: {
          ...state.entities,
          [action.payload.girlId]: {
            ...state.entities[action.payload.girlId],
            agentId: action.payload.agentId
          }
        }
      };
    }

    case UPDATE_AGENTS_GIRL_IDS: {
      let updatedPrevAgent = {};
      let updatedNewAgent = {};
      let updatedEntities = state.entities;

      if (state.entities[action.payload.prevAgentId]) {
        const prevAgent = state.entities[action.payload.prevAgentId];

        updatedPrevAgent = {
          ...prevAgent,
          girlsIds: prevAgent.girlsIds.filter(
            id => id !== action.payload.girlId
          )
        };

        updatedEntities = {
          ...state.entities,
          [action.payload.prevAgentId]: updatedPrevAgent
        };
      }
      if (state.entities[action.payload.newAgentId]) {
        const newAgent = state.entities[action.payload.newAgentId];

        updatedNewAgent = {
          ...newAgent,
          girlsIds: [...newAgent.girlsIds, action.payload.girlId]
        };

        updatedEntities = {
          ...updatedEntities,
          [action.payload.newAgentId]: updatedNewAgent
        };
      }

      return {
        ...state,
        entities: updatedEntities
      };
    }

    case TOGGLE_GIRL_MARK:
    case TOGGLE_CLIENT_MARK: {
      const updatedEntities = { ...state.entities };

      const updatedEntity = {
        ...updatedEntities[action.payload],
        marked: !updatedEntities[action.payload].marked
      };

      updatedEntities[updatedEntity.id] = updatedEntity;

      return {
        ...state,
        entities: updatedEntities
      };
    }

    case TOGGLE_CLIENT_ACTIVE_CHAT:
    case TOGGLE_GIRL_ACTIVE_CHAT: {
      const contact = action.payload;
      const contactType = contact.type === 1 ? CLIENTS : GIRLS;

      let updatedFilteredIds = state[contactType].filteredIds;

      if (state[contactType].activeFilter === ACTIVE_CHATS) {
        if (contact.is_active_chat && !updatedFilteredIds.includes(contact.id)) {
          updatedFilteredIds = [ contact.id, ...updatedFilteredIds];
        }
        else if (!contact.is_active_chat) {
          updatedFilteredIds = updatedFilteredIds.filter(id => id !== contact.id)
        }

        return {
          ...state,
          [contactType]: {
            ...state[contactType],
            filteredIds: updatedFilteredIds,
          }
        }
      }
      else return state;
    }

    case TOGGLE_CLIENT_PIN: {
      return toggleContactPinHelper(state, action.payload, CLIENTS);
    }

    case TOGGLE_GIRL_PIN: {
      return toggleContactPinHelper(state, action.payload, GIRLS);
    }

    case FILTER_CLIENTS: {
      return filterContactsHelper(state, action.payload, CLIENTS);
    }

    case FILTER_GIRLS: {
      return filterContactsHelper(state, action.payload, GIRLS);
    }

    case REMOVE_CLIENT_FILTER: {
      if (state.clients.activeFilter === ALL_FILTER) {
        return state;
      }

      return {
        ...state,
        clients: {
          ...state.clients,
          activeFilter: ALL_FILTER,
        }
      };
    }

    case REMOVE_GIRL_FILTER: {
      if (state.girls.activeFilter === ALL_FILTER) {
        return state;
      }

      return {
        ...state,
        girls: {
          ...state.girls,
          activeFilter: ALL_FILTER,
        }
      };
    }

    case GET_SEARCHED_CLIENTS: {
      const data = normalizeContacts(action.payload.data, false);

      if (state.clients.searchSource === 'msgs') {
        data.ids.forEach(id => {
          data.entities[id].last_interaction_searched = data.entities[id].last_interaction;
          data.entities[id].interactionObject_searched = data.entities[id].interactionObject;
  
          data.entities[id].last_interaction = state.entities[id]
            ? state.entities[id].last_interaction
            : '';
          data.entities[id].interactionObject = state.entities[id]
            ? state.entities[id].interactionObject
            : null;
        });
      }

      const updatedEntities = { ...state.entities, ...data.entities };

      return {
        ...state,
        entities: updatedEntities,
        clients: {
          ...state.clients,
          pending: false,
          search: action.payload.query,
          activeFilter: ALL_FILTER,
          searchedIds: data.ids
        }
      };
    }

    case UPDATE_SEARCHED_CLIENTS: {
      const data = normalizeContacts(action.payload, false);

      if (state.clients.searchSource === 'msgs') {
        data.ids.forEach(id => {
          data.entities[id].last_interaction_searched = data.entities[id].last_interaction;
          data.entities[id].interactionObject_searched = data.entities[id].interactionObject;
  
          data.entities[id].last_interaction = state.entities[id]
            ? state.entities[id].last_interaction
            : '';
          data.entities[id].interactionObject = state.entities[id]
            ? state.entities[id].interactionObject
            : null;
        });
      }

      const updatedEntities = { ...state.entities, ...data.entities };

      return {
        ...state,
        entities: updatedEntities,
        clients: {
          ...state.clients,
          searchedIds: [...state.clients.searchedIds, ...data.ids]
        }
      };
    }

    case GET_SEARCHED_GIRLS: {
      const data = normalizeContacts(action.payload.data, false);

      if (state.girls.searchSource === 'msgs') {
        data.ids.forEach(id => {
          data.entities[id].last_interaction_searched = data.entities[id].last_interaction;
          data.entities[id].interactionObject_searched = data.entities[id].interactionObject;
  
          data.entities[id].last_interaction = state.entities[id]
            ? state.entities[id].last_interaction
            : '';
          data.entities[id].interactionObject = state.entities[id]
            ? state.entities[id].interactionObject
            : null;
        });
      }

      const updatedEntities = { ...state.entities, ...data.entities };

      return {
        ...state,
        entities: updatedEntities,
        girls: {
          ...state.girls,
          pending: false,
          search: action.payload.query,
          activeFilter: ALL_FILTER,
          searchedIds: data.ids
        }
      };
    }

    case UPDATE_SEARCHED_GIRLS: {
      const data = normalizeContacts(action.payload, false);
      
      if (state.clients.searchSource === 'msgs') {
        data.ids.forEach(id => {
          data.entities[id].last_interaction_searched = data.entities[id].last_interaction;
          data.entities[id].interactionObject_searched = data.entities[id].interactionObject;
  
          data.entities[id].last_interaction = state.entities[id]
            ? state.entities[id].last_interaction
            : '';
          data.entities[id].interactionObject = state.entities[id]
            ? state.entities[id].interactionObject
            : null;
        });
      }

      const updatedEntities = { ...state.entities, ...data.entities };

      return {
        ...state,
        entities: updatedEntities,
        girls: {
          ...state.girls,
          searchedIds: [...state.girls.searchedIds, ...data.ids]
        }
      };
    }

    case STOP_CLIENT_SEARCH: {
      return {
        ...state,
        clients: {
          ...state.clients,
          search: "",
          searchedIds: [],
          activeFilter: ACTIVE_CHATS,
          pending: false,
        }
      };
    }

    case STOP_GIRL_SEARCH: {
      return {
        ...state,
        girls: {
          ...state.girls,
          search: "",
          searchedIds: [],
          activeFilter: ACTIVE_CHATS,
          pending: false,
        }
      };
    }
    case CLEAN_CLIENTS_UNREAD:
    case CLEAN_GIRLS_UNREAD: {
      const updatedEntities = { ...state.entities };

      const isContactTypeFromPayload = (type) => (action.payload === CLIENTS && type === 1) || (action.payload === GIRLS && type !== 1)

      for (const id in updatedEntities) {
        if (updatedEntities[id].unreadCount && isContactTypeFromPayload(updatedEntities[id].type)) {
          updatedEntities[id] = {
            ...updatedEntities[id],
            unreadCount: 0
          }
        }
      }

      return {
        ...state,
        entities: updatedEntities,
        [action.payload]: {
          ...state[action.payload],
          unreadCount: 0
        }
      }
    }

    case ADD_CONTACTS_TO_ENTITIES:
    case GET_CONTACTS_LIST:
    case FILTER_CONTACTS_LIST:
    case UPDATE_CONTACTS_LIST:
    case UPDATE_FILTERED_CONTACTS_LIST:
    case ADD_ARRAY_GIRLS: {
      const newEntities = action.payload.entities
        ? action.payload.entities
        : action.payload;

      return {
        ...state,
        entities: addToEntitiesIfMissing(state.entities, newEntities, 'id'),
      };
    }

    case GET_ALL_CALLS: {
      if (!action.payload.missed) {
        return state;
      }

      const contacts = action.payload.missed
        .filter(missed => missed.caller)
        .map(missed => missed.caller);

      return {
        ...state,
        entities: addToEntitiesIfMissing(state.entities, contacts, "id")
      };
    }
    case UPDATE_CHAT_TIMELINE:
    case GET_CHAT_TIMELINE: {
      if (action.payload.chatId !== 'girls' || !action.payload.messages.length) {
        return state;
      }

      const contacts = [];

      for (let i = 0; i < action.payload.messages.length; i++) {
        const group = action.payload.messages[i];

        group.forEach(msg => {
          if (msg.caller) {
            contacts.push(msg.caller)
          }
        });
      }

      if (!contacts.length) {
        return state;
      }

      return {
        ...state,
        entities: addToEntitiesIfMissing(state.entities, contacts, "id")
      };
    }
    // TODO: use addToEntitiesIfMissing function
    // case GET_SESSION_BY_ID:
    // case GET_ALL_SESSIONS:
    // case SHOW_SESSION_FROM_HISTORY: {
    // case UPDATE_SESSION_BUFFER: {
    //   return {
    //     ...state,
    //     entities: {
    //       ...state.entities,
    //       ...action.payload.contacts.entities
    //     }
    //   }
    // }

    case TOGGLE_CONTACTS_SEARCH_SOURCE: {
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          searchSource: state[action.payload].searchSource === 'contacts' ? 'msgs' : 'contacts'
        }
      }
    }
    
    case DELETE_TELEGRAM_MSG: {
      const { entities } = state;

      if (!entities[action.payload.caller?.id]) {
        return state;
      }

      const {
        id,
        last_interaction,
        interactionObject,
        date_updated
      } = action.payload.caller;

      return {
        ...state,
        entities: {
          ...entities,
          [id]: {
            ...entities[id],
            last_interaction,
            interactionObject,
            date_updated,
          }
        }
      }
    }

    default:
      return state;
  }
};

// Redux helpers
const updateContactListHelper = (state, payload, contactType) => {
  return {
    ...state,
    entities: { ...state.entities, ...payload.entities },
    [contactType]: {
      ...state[contactType],
      ids: [...state[contactType].ids, ...payload.ids],
      pinIds: payload.pinIds
        ? [...state[contactType].pinIds, ...payload.pinIds]
        : state[contactType].pinIds
    }
  };
};

const createContactHelper = (state, payload, contactType) => {
  return {
    ...state,
    entities: { ...state.entities, ...payload.entities },
    [contactType]: {
      ...state[contactType],
      ids: payload.ids
        ? [...payload.ids, ...state[contactType].ids]
        : state[contactType].ids,
      pinIds: payload.pinIds
        ? [...payload.pinIds, ...state[contactType].pinIds]
        : state[contactType].pinIds,
      totalCount: state[contactType].totalCount + 1
    }
  };
};

const removeContactHelper = (state, payload, contactType) => {
  const removedContact = state.entities[payload];

  if (!removedContact) {
    return state;
  }

  const updatedEntities = { ...state.entities };

  delete updatedEntities[payload];

  let updatedPinIds = state[contactType].pinIds;
  let updatedIds = state[contactType].ids;

  let updatedSearchedIds = state[contactType].searchedIds;
  let updatedFilteredIds = state[contactType].filteredIds;

  if (removedContact.pinned) {
    updatedPinIds = state[contactType].pinIds.filter(
      id => id !== removedContact.id
    );
  } else {
    updatedIds = state[contactType].ids.filter(id => id !== removedContact.id);
  }

  if (updatedSearchedIds.includes(payload)) {
    updatedSearchedIds = updatedSearchedIds.filter(id => id !== payload);
  }
  if (updatedFilteredIds.includes(payload)) {
    updatedFilteredIds = updatedFilteredIds.filter(id => id !== payload);
  }

  return {
    ...state,
    entities: updatedEntities,
    [contactType]: {
      ...state[contactType],
      ids: updatedIds,
      pinIds: updatedPinIds,
      searchedIds: updatedSearchedIds,
      filteredIds: updatedFilteredIds,
      totalCount: state[contactType].totalCount - 1
    }
  };
};

const toggleContactPinHelper = (state, payload, contactType) => {
  const updatedContact = state.entities[payload];
  let prevIds = state[contactType].ids;
  let prevPinIds;

  if (updatedContact.pinned) {
    // remove from pinned
    prevPinIds = state[contactType].pinIds.filter(
      id => id !== updatedContact.id
    );
    // last activeDate in the list
    const lastActiveDate =
      state.entities[state[contactType].ids[state[contactType].ids.length - 1]]
        .last_interaction;

    // stays in the list
    if (
      updatedContact.last_interaction > lastActiveDate ||
      state[contactType].ids.length === state[contactType].totalCount
    ) {
      prevIds = sortContactIdsByDate(state.entities, [
        ...state[contactType].ids,
        updatedContact.id
      ]);
    }
  }
  // add to pinned
  else {
    prevIds = state[contactType].ids.filter(id => id !== updatedContact.id);
    prevPinIds = [updatedContact.id, ...state[contactType].pinIds];
  }

  const updatedEntities = {
    ...state.entities,
    [updatedContact.id]: { ...updatedContact, pinned: !updatedContact.pinned }
  };

  return {
    ...state,
    entities: updatedEntities,
    [contactType]: {
      ...state[contactType],
      ids: prevIds,
      pinIds: prevPinIds
    }
  };
};

const filterContactsHelper = (state, payload, contactType) => {
  const updatedEntities = { ...state.entities, ...payload.data.entities };

  const updatedFilteredIds = payload.offset
    ? [...state[contactType].filteredIds, ...payload.data.ids]
    : payload.data.ids;

  return {
    ...state,
    entities: updatedEntities,
    [contactType]: {
      ...state[contactType],
      activeFilter: payload.filter,
      filteredIds: updatedFilteredIds,
      pending: false,
      search: ''
    }
  };
};

export const unreadCountCalcHelper = (prev, payload, mathOperator) => {
  if (payload.ids && payload.ids.length) {
    if (mathOperator === "+") {
      return prev + payload.entities[payload.ids[0]].unreadCount;
    } else {
      return prev - payload.entities[payload.ids[0]].unreadCount;
    }
  } else {
    if (mathOperator === "+") {
      return prev + payload.entities[payload.pinIds[0]].unreadCount;
    } else {
      return prev - payload.entities[payload.pinIds[0]].unreadCount;
    }
  }
};

export const sortContactIdsByDate = (entities, ids) => {
  return ids.sort((id1, id2) => {
    const entity1 = new Date(entities[id1].last_interaction);
    const entity2 = new Date(entities[id2].last_interaction);

    return entity2.getTime() > entity1.getTime() ? 1 : -1;
  });
};

export const onNewInteraction = (state, payload, direction) => {
  const contacts = payload.caller.type === 1 ? CLIENTS : GIRLS;
  const isInEntities = !!state.entities[payload.caller_id];

  let updatedEntities = { ...state.entities };
  let prevIds = state[contacts].ids;
  let prevFilteredIds = state[contacts].filteredIds;
  let prevPinIds = state[contacts].pinIds;
  let updatedContactsUnreadCount = state[contacts].unreadCount;

  if (!isInEntities) {
    const normalizedData = normalizeContacts(payload.caller);

    updatedEntities = { ...state.entities, ...normalizedData.entities };

    const updatedEntity = updatedEntities[payload.caller_id];

    let updatedUnreadCount = updatedEntity.unreadCount;

    if (direction === "out" || !payload.isIamRelated) {
      updatedUnreadCount = 0;

      updatedContactsUnreadCount -= updatedEntity.unreadCount;
    }
    else if (payload.type !== 8) {
      if (payload.count) { // make msgs unread => UNREAD_CLIENT_MESSAGES
        updatedUnreadCount = payload.count;

        updatedContactsUnreadCount += payload.count;
      }
      else {
        updatedContactsUnreadCount += 1;
      }
    }


    updatedEntity.unreadCount = updatedUnreadCount;
    updatedEntity.last_interaction = payload.caller.last_interaction;
    updatedEntity.interactionObject = payload.caller.interactionObject;


    // update ids
    if (updatedEntity.pinned) {
      prevPinIds = [...state[contacts].pinIds, ...normalizedData.pinIds];
    } else {
      prevIds = [...normalizedData.ids, ...state[contacts].ids];
    }

    // update filteredIds
    if (state[contacts].activeFilter === ACTIVE_CHATS) {
      prevFilteredIds = [ payload.caller_id, ...state[contacts].filteredIds];
    }
  }
  // if in Entities
  else {
    const updatedEntity = { ...updatedEntities[payload.caller_id] };

    let updatedUnreadCount = updatedEntity.unreadCount;


    if (direction === "out" || !payload.isIamRelated) {
      updatedContactsUnreadCount -= updatedEntity.unreadCount;
      updatedUnreadCount = 0;
    }
    // if incoming msg and not SystemMsg
    else if (payload.type !== 8) {
      if (payload.count) {
        updatedUnreadCount = payload.count;

        updatedContactsUnreadCount += payload.count;
      }
      // incoming msg 
      else {
        // if we have custom mode we shouldn't increase unreadCount
        // payload.caller.unreadCount = actual data from server. If it's not => server error!
        if (updatedUnreadCount !== payload.caller.unreadCount) {
          updatedUnreadCount += 1;
          updatedContactsUnreadCount += 1;
        }
      }
    }

    updatedEntity.unreadCount = updatedUnreadCount;
    updatedEntity.last_interaction = payload.caller.last_interaction;
    updatedEntity.interactionObject = payload.caller.interactionObject;


    updatedEntities[updatedEntity.id] = updatedEntity;

    if (!updatedEntity.pinned) {
      const updatedPrevIds = state[contacts].ids.filter(
        id => id !== updatedEntity.id
      );

      prevIds = [updatedEntity.id, ...updatedPrevIds];
    }

    if (state[contacts].activeFilter === ACTIVE_CHATS) {
      const updatedPrevFilteredIds = state[contacts].filteredIds.filter(
        id => id !== updatedEntity.id
      )

      prevFilteredIds = [updatedEntity.id, ...updatedPrevFilteredIds]
    }
  }

  return {
    ...state,
    entities: updatedEntities,
    [contacts]: {
      ...state[contacts],
      ids: prevIds,
      filteredIds: prevFilteredIds,
      pinIds: prevPinIds,
      unreadCount: updatedContactsUnreadCount
    }
  };
};

// export const clearDeletedTabsFromRecent = (relevantIds, userId, type) => {
//   let LSRecentTabsKey;

//   switch (type) {
//     case CHAT_TYPES.CLIENT:
//       LSRecentTabsKey = 'clientChatsRecentTabs';
//       break;
//     case CHAT_TYPES.GIRL:
//       LSRecentTabsKey = 'girlChatsRecentTabs';
//       break;
//     default:
//       LSRecentTabsKey = 'roomChatsRecentTabs';
//   }

//   const recentTabs = LS.getItem(LSRecentTabsKey, userId);

//   if (!recentTabs) return;

//   const getIsDeletedTab = (tab) => relevantIds.includes(tab);

//   recentTabs.all = recentTabs.all.filter(getIsDeletedTab);
//   recentTabs.visible = recentTabs.visible.filter(getIsDeletedTab);

//   LS.setItem(LSRecentTabsKey, recentTabs, userId);
//   return recentTabs;
// }