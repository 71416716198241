import React from 'react';
import { connect } from 'react-redux';
import { faMailBulk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { openModal, MODAL_TYPES } from '../../../../ducks/activeWindows';

const MailingButton = ({ openModal }) => (
  <span
    onClick={() => openModal(MODAL_TYPES.mailingComponent)}
    style={{
      cursor: 'pointer'
    }}
    className="mailing-btn"
  >
    <FontAwesomeIcon icon={faMailBulk} title="mailing" color='#404040' className='navbar__envelope-icon' />
  </span>
);

const mapDispatchToProps = {
  openModal,
};

export default connect(null, mapDispatchToProps)(MailingButton);