import React, { useEffect, useRef, useState, useCallback, memo } from 'react';

import API from '../../../api/api';
import { useHideTemporaryTooltip, useToggle } from '../../../hooks';
import { getDateByTimezoneOffset } from '../../../utils';

import Select from '../../Select/Select';
import ResizableTextarea from '../../ResizableTextarea/ResizableTextarea';
import Spinner from '../../UI/Spinner/Spinner';
import DropReactCalendar from '../../DropReactCalendar/DropReactCalendar';


const ScheduledMsgItem = ({ contactsList, isEditableScheduledMsg, timeOptions, userTimezone, hour12, ...props }) => {

  const normalizedDate = useCallback((initialDate) => {
    const date = getDateByTimezoneOffset(userTimezone, initialDate);
    let hours = date.getHours() + '';
    let minutes = date.getMinutes() + '';
    let amPm = '';

    if (hour12) {
      amPm = hours < 12 ? "AM" : "PM";
      hours = hours > 12 ? hours - 12 : hours;
      if (hours == 0) {
        hours = 12;
      }
    }

    if (minutes.length === 1) {
      minutes = '0' + minutes;
    }

    return {
      time: hours + ':' + minutes,
      dayPart: hour12 ? amPm : '',
    }
  }, [hour12, userTimezone]);

  const [isCalendarScheduledMsgOpen, toggleCalendarScheduledMsgOpen] = useToggle(false);
  const [selectedScheduledMsg, setSelectedScheduledMsg] = useState({
    ...props.selectedScheduledMsg,
    ...normalizedDate(props.selectedScheduledMsg.date),
  });

  useEffect(() => {
    setSelectedScheduledMsg({
      ...props.selectedScheduledMsg,
      ...normalizedDate(props.selectedScheduledMsg.date),
    });
  }, [props.selectedScheduledMsg, normalizedDate]);

  const [customError, setCustomError] = useHideTemporaryTooltip('', 1500);

  const scheduledCalendarRef = useRef();

  const date = new Date(selectedScheduledMsg.date).toLocaleDateString();

  const selectScheduledTime = (time) => {
    setSelectedScheduledMsg(prevState => ({
      ...prevState,
      time: time.type === 'hours'
        ? time.name + ':' + prevState.time.split(':')[1]
        : prevState.time.split(':')[0] + ':' + time.name,
    }));
  };

  const selectScheduledDayPart = dayPart => {
    setSelectedScheduledMsg(prevState => ({
      ...prevState,
      dayPart: dayPart.value
    }));
  };

  const selectScheduledDate = (date) => {
    setSelectedScheduledMsg(prevState => ({
      ...prevState,
      date: new Date(date),
    }));
    toggleCalendarScheduledMsgOpen();
  };

  const createLocalScheduledMsg = data => {
    setSelectedScheduledMsg({
      ...props.selectedScheduledMsg,
      ...normalizedDate(props.selectedScheduledMsg.date),
    });

    props.setNewScheduledMsgsList(prev => [
      {
        ...data,
        id: 'id-' + Math.random().toString(36),
      },
      ...prev,
    ]);
  }

  const createScheduledMsg = data => {
    setSelectedScheduledMsg({
      ...props.selectedScheduledMsg,
      ...normalizedDate(props.selectedScheduledMsg.date),
    });

    API.createScheduledMsg({
      ...data,
      contactId: data.caller.id,
      bookingId: props.bookingId
    });
  }

  const updateLocalScheduledMsg = data => {
    props.setNewScheduledMsgsList(prev => {
      return prev.map(scheduledMsg => {
        if (scheduledMsg.id === data.id) return data;

        return scheduledMsg;
      })
    });

    props.setEditableScheduledMsgId(false);
  }

  const updateScheduledMsg = data => {
    API.updateScheduledMsg({
      ...data,
      contactId: data.caller.id,
      bookingId: props.bookingId
    });

    props.setEditableScheduledMsgId(false);
  }

  const handleSubmitScheduledMsg = () => {
    const {
      dayPart,
      time,
    } = selectedScheduledMsg;

    const [hours, minutes] = time.split(':').map(str => +str);
    let hours24 = hours;

    if (hour12 && dayPart === "PM" && hours24 !== 12) {
      hours24 += 12;
    } else if (hour12 && dayPart === "AM" && hours24 === 12) {
      hours24 = 0;
    }

    const data = {
      ...selectedScheduledMsg,
      date: new Date(selectedScheduledMsg.date).setHours(hours24, minutes, 0, 0) - (userTimezone - (new Date().getTimezoneOffset() * (-1))) * 60000,
    };

    //validation
    if (!data.body) {
      return setCustomError('Enter your message');
    }
    else if (Date.parse(getDateByTimezoneOffset(userTimezone)) >= data.date) {
      return setCustomError('Time is wrong')
    }


    if (props.bookingId) {
      // btn 'Create'
      // created new msg and sent on server
      if (props.isNewScheduledMsg) {
        createScheduledMsg(data);
      }
      // btn 'Submit'
      // updated msg and sent on server
      else {
        updateScheduledMsg(data)
      }
    }
    else {
      // btn 'Create'
      // created new local msg
      if (props.isNewScheduledMsg) {
        createLocalScheduledMsg(data)
      }
      // btn 'Submit' 
      // updated local msg
      else {
        updateLocalScheduledMsg(data);
      }
    }
  };

  const handleCancel = () => {
    setSelectedScheduledMsg({
      ...props.selectedScheduledMsg,
      ...normalizedDate(props.selectedScheduledMsg.date),
    });

    props.setEditableScheduledMsgId(false);
  };

  const handleDelete = () => {
    if (props.bookingId) {
      API.deleteScheduledMsg(selectedScheduledMsg.id);
    }
    else {
      props.setNewScheduledMsgsList && props.setNewScheduledMsgsList(prev => {
        return prev.filter(scheduledMsg => scheduledMsg.id !== selectedScheduledMsg.id);
      });
    }
  };

  const onTextareaChange = (e) => {
    const value = e.target.value;

    setSelectedScheduledMsg(prev => ({
      ...prev,
      body: value,
    }));
  };

  const renderBtnAction = () => {
    if (props.isNewScheduledMsg) {
      return (
        <button
          className="booked-editor__btn booked-editor__btn--primary"
          onClick={handleSubmitScheduledMsg}
        >
          Create
        </button>
      )
    }
    else if (isEditableScheduledMsg) {
      return <>
        <button
          className="booked-editor__btn booked-editor__btn--submit"
          onClick={handleSubmitScheduledMsg}
        >
          Submit
        </button>

        <button
          className="booked-editor__btn booked-editor__btn--danger"
          onClick={handleCancel}
        >
          Cancel
        </button>
      </>
    } else {
      return <>
        <button
          className="booked-editor__btn booked-editor__btn--primary"
          onClick={() => props.setEditableScheduledMsgId(selectedScheduledMsg.id)}
        >
          Edit
        </button>

        <button
          className="booked-editor__btn booked-editor__btn--danger"
          onClick={handleDelete}
        >
          Delete
        </button>
      </>
    }
  }

  return (
    <>
      <div className="booked-editor__field booked-editor__field--contact">
        <div className="booked-editor__field-title">Contact</div>
        {props.isContactPending
          ? <Spinner spinnerSize='18px' />
          : <Select
            classPrefix={(isEditableScheduledMsg && contactsList.length > 1) ? 'booked-editor' : 'booked-editor-disabled'}
            options={contactsList}
            getOptionValue={option => option.fn}
            defaultValue={selectedScheduledMsg.caller && selectedScheduledMsg.caller.fn}
            onInputChange={(option) => setSelectedScheduledMsg(prevValues => ({
              ...prevValues,
              caller: option,
            }))}
            isDisabled={!isEditableScheduledMsg}
            listAutoposition
          />
        }
      </div>

      <div className="booked-editor__field booked-editor__field--date">
        <div className="booked-editor__field-title">Date</div>

        {isEditableScheduledMsg &&
          <button
            className="booked-editor__btn booked-editor__btn--primary"
            onClick={toggleCalendarScheduledMsgOpen}
          >
            Select
          </button>
        }

        <span className='booked-editor__date-string'>
          {date}
        </span>

        {isCalendarScheduledMsgOpen &&
          <DropReactCalendar
            wrapClassName="booked-editor__sheduled-calendar"
            onClose={toggleCalendarScheduledMsgOpen}
            onChange={selectScheduledDate}
            date={selectedScheduledMsg.date}
            minDate={getDateByTimezoneOffset(userTimezone)}
          />
        }
      </div>

      <div className="booked-editor__field booked-editor__field--scheduled-time">
        <div className="booked-editor__field-title">Time</div>

        <div className="booked-editor__time-selectors">
          <Select
            classPrefix={isEditableScheduledMsg ? 'booked-editor' : 'booked-editor-disabled'}
            options={timeOptions.hours}
            getOptionValue={option => option.name}
            defaultValue={selectedScheduledMsg.time.split(':')[0]}
            onInputChange={selectScheduledTime}
            isDisabled={!isEditableScheduledMsg}
            listAutoposition
          />
          <div className='booked-editor__time-separator'>
            :
          </div>
          <Select
            classPrefix={isEditableScheduledMsg ? 'booked-editor' : 'booked-editor-disabled'}
            options={timeOptions.minutes}
            getOptionValue={option => option.name}
            defaultValue={selectedScheduledMsg.time.split(':')[1]}
            onInputChange={selectScheduledTime}
            isDisabled={!isEditableScheduledMsg}
            listAutoposition
          />
          {hour12 &&
            <>
              <div className='booked-editor__time-separator'></div>
              <Select
                classPrefix={isEditableScheduledMsg ? 'booked-editor' : 'booked-editor-disabled'}
                options={timeOptions.dayPart}
                getOptionValue={option => option.name}
                defaultValue={selectedScheduledMsg.dayPart}
                onInputChange={selectScheduledDayPart}
                isDisabled={!isEditableScheduledMsg}
                listAutoposition
              />
            </>
          }
        </div>
      </div>

      <div className="booked-editor__field booked-editor__field--message">
        <div className="booked-editor__field-title">Message</div>
        <ResizableTextarea
          disabled={!isEditableScheduledMsg}
          placeholder="Your message"
          value={selectedScheduledMsg.body}
          setHeight={props.setScheduledMsgBlockHeight}
          onChange={onTextareaChange}
        />
      </div>

      <div className="booked-editor__additional-actions">
        {renderBtnAction()}
      </div>

      {!!customError &&
        <span className="booked-editor__tooltip">
          {customError}
        </span>
      }
    </>
  )
}

export default memo(ScheduledMsgItem)