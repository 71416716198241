import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeMute, faVolumeUp, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import { useDidUpdate } from '../../../../../hooks'
import { AUDIO_SETTINGS_TYPES } from '../../../../../config/constants';


const recipientAudioSettings = [
  {
    icon: faVolumeUp,
    color: "#bdbdbd",
    onClickAction: AUDIO_SETTINGS_TYPES.MUTE,
    title: 'normal'
  },
  {
    icon: faVolumeMute,
    color: "#0092F2",
    onClickAction: AUDIO_SETTINGS_TYPES.IMPORTANT,
    title: 'muted'
  },
  {
    icon: faExclamationTriangle,
    color: "#0092F2",
    onClickAction: AUDIO_SETTINGS_TYPES.NORMAL,
    title: 'chat marked as important'
  },
];

const AudioStatusBtn = ({ activeRecipient, changeRecipientAudioStatus }) => {
  const [button, setButton] = useState(recipientAudioSettings[activeRecipient.audio_status] || recipientAudioSettings[0]);

  useDidUpdate(() => {
    setButton(recipientAudioSettings[activeRecipient.audio_status]);
  }, [activeRecipient.audio_status]);

  if (activeRecipient.id === 'webmaster') return null;

  return (
    <button
      onClick={() => changeRecipientAudioStatus(button.onClickAction, activeRecipient.id)}
      style={{ cursor: 'pointer', padding: ' 10px 0' }}>
      <FontAwesomeIcon icon={button.icon} color={button.color}  title={button.title} />
    </button>
  )
};

export default AudioStatusBtn;