import React, { useState, useRef, useMemo, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelegram } from '@fortawesome/free-brands-svg-icons';

import { getContactAvatar, formatDateToTime, getDefaultField, getDateByTimezoneOffset, classModifier } from '../../../../../utils';
import { useToggle } from '../../../../../hooks';
import API from '../../../../../api/api';

import InteractionMenu from '../InteractionMenu/InteractionMenu';
import InteractionAttachments from '../../ChatAttachments/InteractionAttachments';
import ParsedGirlList from '../../ParsedGirlList/ParsedGirlList';
import ToggleSwitch from '../../../../../components/ToggleSwitch/ToggleSwitch';
import ContactInteractionMenu from '../InteractionMenu/ContactInteractionMenu';
import RoomInteractionMenu from '../InteractionMenu/RoomInteractionMenu';
import MsgText from './MsgText';
import AttachmentRepliedMessage from '../../ChatAttachments/AttachmentRepliedMessage';

const IncomingMessage = ({ interaction, activeRecipient, search, isWebmasterMsg, ...props }) => {
  const [menu, toggleMenu] = useToggle(false);
  const msgRef = useRef();
  const [isAnimate, setIsAnimate] = useToggle(false);

  const isFromTelegram = interaction.type === 9;

  const [parsedGirlsList, setParsedGirlsList] = useState({
    query: '',
    list: [],
    isOpen: false,
  });

  const isContextMsg = interaction.id === +props.contextMsgId; // couse props.contextMsgId is string

  useEffect(() => {
    if (isContextMsg) {
      setIsAnimate();

      setTimeout(() => setIsAnimate(false), 2000);
    }
  }, [isContextMsg]);

  const searchByGirlName = useCallback((e) => {
    const text = e.currentTarget.getAttribute('data-value');

    API.searchContacts(2, text)
      .then(res => {
        props.addNewArrayGirlsToState(res.data);

        setParsedGirlsList({ query: text, list: res.data, isOpen: true });
      });
  }, []);

  const getMsgName = () => {
    if (props.isGlobalSearch && interaction.caller) {
      return interaction.caller.short_name || interaction.caller.fn;
    }
    else if (['girls', 'webmaster', 'bookings'].includes(activeRecipient.id)) {
      return interaction.caller.short_name || interaction.caller.fn;
    }
    return activeRecipient.short_name
      || activeRecipient.fn
      || (interaction.sender && interaction.sender.username)
      || activeRecipient.chatTitle;
  };

  const getMsgAva = () => {
    if (props.isGlobalSearch && interaction.caller) {
      return getContactAvatar(interaction.caller);
    }
    else if (['girls', 'webmaster', 'bookings'].includes(activeRecipient.id)) {
      return getContactAvatar(interaction.caller);
    }
    return !activeRecipient.chatType
      ? getContactAvatar(activeRecipient)
      : getContactAvatar(interaction.sender)
  };

  const isUnread = () => !interaction.hasOwnProperty('isRead')
    ? false
    : !interaction.isRead && 'unread';

  const getMsgContext = (e) => {
    console.log('getContext');
    // if click on interaction menu
    if (e.target.closest(".ii-menu")) {
      return;
    }
    // if click on parsed girl name
    else if (e.target.closest(".interaction__clickable-name")) {
      return;
    }
    // if isWebMasterMsg => getMsgContext in GirlsChats
    if (isWebmasterMsg) {
      props.getMessageContext(interaction.id, interaction.caller)
    }

    // if we not in search return
    if (!search) return;

    // if we already in msgContext return
    if (!props.contextMsgId) {
      return e.target.className !== 'lazy-load__image'
        ? props.getMessageContext(interaction.id, props.isGlobalSearch ? interaction.caller : null, search)
        : null;
    }
  }

  const setContextRef = ref => {
    if (isContextMsg) {
      return props.contextMsgRef.current = ref;
    }
  }

  const items = useMemo(() => [

    {
      component: (props) =>
        <ToggleSwitch
          {...props}
          // isChecked={girl.isAvailable}
          onChange={() => API.completeWebmasterTask(interaction.webMasterTask.id)
          }
        />,
      tooltipOnHover: 'Complete',
      // action: async () => console.log('complete task')
    },

  ], [])

  const showInteractionMenu = () => {
    if (interaction.is_deleted) return null;

    if (isWebmasterMsg) {
      return <InteractionMenu
        isOpen={menu}
        menuItems={items}
        closeMenu={toggleMenu} />
    }

    return interaction.chatId
      ? <RoomInteractionMenu
        isOpen={menu}
        closeMenu={toggleMenu}
        interaction={interaction}
        shareMsg={props.shareMsg}
        search={search}
      />

      : <ContactInteractionMenu
        isOpen={menu}
        closeMenu={toggleMenu}
        interaction={interaction}
        search={search}
        replyMsg={props.replyMsg}
        userTimezone={props.userTimezone}
        activeChannel={activeRecipient.default_channel}
      />
  }
  // FIXME: rename 
  const isGotFromNotDefaultNumber = interaction.channel && (getDefaultField(activeRecipient.tels, 'tel') !== interaction.channel);

  return (
    <>
      <li
        className={classModifier('interaction', [
          'inc',
          interaction.isNew && 'new',
          isWebmasterMsg && 'webmaster',
          interaction.is_deleted && 'deleted',
          isUnread(),
        ])}
        style={isContextMsg ? { border: "1px solid #0092F2" } : null}
        onClick={getMsgContext}
        ref={setContextRef}
        data-isanimate={isAnimate}
        // if we will use below {() => toggleMenu()} it will rerender this interaction
        onMouseEnter={() => toggleMenu(true)}
        onMouseLeave={() => toggleMenu(false)}
      >
        <header ref={msgRef} className="interaction__header">
          <div className="interaction__ava">
            <img
              src={getMsgAva()}
              alt="ava" />
            {isFromTelegram &&
              <FontAwesomeIcon icon={faTelegram} size="lg" color="#0092F2" />
            }
          </div>
          <span className="interaction__name">{getMsgName()}</span>

          {!!interaction.edited && <span className="interaction__edited">edited</span>}

          {interaction.is_deleted && <span className="interaction__deleted">deleted</span>}

          <span className="interaction__time interaction__time--msg">
            {formatDateToTime(getDateByTimezoneOffset(props.userTimezone, interaction.dateCreated), props.userHour12)}
          </span>

          <span className="interaction__channel">
            {isGotFromNotDefaultNumber && `(from: ${interaction.channel})`}
          </span>

          {showInteractionMenu()}
        </header>

        <div className={classModifier('interaction__body-wrap', [
          (interaction.is_deleted && interaction.attachments) && 'deleted',
        ])}>
          {interaction.attachments?.repliedMessage &&
            <div className="interaction__replied-msg">
              <AttachmentRepliedMessage
                profileId={props.profileId}
                userHour12={props.userHour12}
                interaction={interaction.attachments.repliedMessage}
                userTimezone={props.userTimezone}
                getMessageContext={props.getMessageContext}
              />
            </div>
          }

          {(interaction.body || !interaction.attachments) &&
            <div className="interaction__text-wrap">
              <MsgText
                highlightedText={search}
                interaction={interaction}
                onGirlNameClick={searchByGirlName}
              />
            </div>
          }

          {interaction.attachments &&
            <InteractionAttachments
              attachments={interaction.attachments}
              profileId={props.profileId}
              userTimezone={props.userTimezone}
              userHour12={props.userHour12}
            />
          }
        </div>
      </li>

      {parsedGirlsList.isOpen &&
        <ParsedGirlList
          msgRef={msgRef}
          activeRecipient={activeRecipient}
          addNewArrayGirlsToState={props.addNewArrayGirlsToState}
          profileId={props.profileId}
          parsedGirlsList={parsedGirlsList}
          updateActiveContact={props.updateActiveContact}
          setParsedGirlsList={setParsedGirlsList}
        />
      }
    </>
  )
};

export default IncomingMessage;