import API from '../api/api';
import { normalize } from '../utils/normalizeContacts';

const duckName = 'mail/';

export const GET_EMAILS = duckName + 'GET_EMAILS';
export const ADD_EMAIL = duckName + 'ADD_EMAIL';

export const SET_UNREAD_COUNT = duckName + 'SET_UNREAD_COUNT';
export const SET_PENDING = duckName + 'SET_PENDING';
export const SET_OFFSET = duckName + 'SET_OFFSET';

export const SET_ACTIVE_MAIL_CHAT = duckName +'SET_ACTIVE_MAIL_CHAT';
export const CLEAR_ACTIVE_MAIL_CHAT = duckName +'CLEAR_ACTIVE_MAIL_CHAT';
export const ADD_MESSAGE_TO_MAIL_CHAT = duckName +'ADD_MESSAGE_TO_MAIL_CHAT';
export const DELETE_MSG_FROM_MAIL_CHAT = duckName + 'DELETE_MSG_FROM_MAIL_CHAT';
export const UPDATE_MESSAGE_DRAFT = duckName + 'UPDATE_MESSAGE_DRAFT';
export const DELETE_MESSAGE_DRAFT = duckName + 'DELETE_MESSAGE_DRAFT';

export const DELETE_CONVERSATION_TO_TRASH = duckName + 'DELETE_CONVERSATION_TO_TRASH';
export const DELETE_CONVERSATION_PERMANENTLY = duckName + 'DELETE_CONVERSATION_PERMANENTLY';

export const MARK_MAILS_NOT_SPAM = duckName + 'MARK_MAILS_NOT_SPAM';
export const UNDELETE_MAILS = duckName + 'UNDELETE_MAILS';

export const SET_DELAYED_MESSAGE = duckName + 'SET_DELAYED_MESSAGE';
export const UNSET_DELAYED_MESSAGE = duckName + 'UNSET_DELAYED_MESSAGE';

export const MAIL_LIST_LIMIT = 50;

export const LIST_TYPES = {
  inbox: 'inbox',
  sent: 'sent',
  drafts: 'drafts',
  spam: 'spam',
  trash: 'trash',
}

export const getEmails = (config) => (dispatch) => {
  dispatch({
    type: SET_PENDING, 
    payload: true 
  });

  return API.getEmails(config)
    .then(({ data }) => {
      dispatch({
        type: GET_EMAILS,
        payload: {
          mails: normalize(data.mails, 'conversationId'),
          totalCount: data.count,
          type: config.type,
        }
      })
    })
    .catch(console.error);
}

export const addEmail = (mailData) => (dispatch) => {
  dispatch({
    type: ADD_EMAIL,
    payload: mailData
  });
}

export const setUnreadEmailsCount = (payload) => (dispatch) => {
  dispatch({
    type: SET_UNREAD_COUNT,
    payload,
  })
}

export const setEmailsOffset = (offset) => (dispatch) => {
  dispatch({
    type: SET_OFFSET,
    payload: offset
  });
}

export const setActiveMailChat = (id, messages) => (dispatch) => {
  dispatch({
    type: SET_ACTIVE_MAIL_CHAT,
    payload: { id, messages },
  });
}

export const clearActiveMailChat = () => (dispatch) => {
  dispatch({
    type: CLEAR_ACTIVE_MAIL_CHAT,
  });
}

export const addMessageToMailChat = (message) => (dispatch) => {
  dispatch({
    type: ADD_MESSAGE_TO_MAIL_CHAT,
    payload: message,
  });
}

export const deleteMsgFromMailChat = (msgId) => ({
  type: DELETE_MSG_FROM_MAIL_CHAT,
  payload: msgId,
});

export const updateMessageDraft = ({ msgId, draftUpdate }) => ({
  type: UPDATE_MESSAGE_DRAFT,
  payload: { msgId, draftUpdate },
});

export const deleteMessageDraft = (draftId) => ({
  type: DELETE_MESSAGE_DRAFT,
  payload: draftId,
});

export const deleteMessageDraftRequest = (draftId) => (dispatch) => {
  return API.deleteMailDraft(draftId)
    .then(() => {
      dispatch(deleteMessageDraft(draftId));
    });
}

export const deleteConversationToTrash = (conversationId) => ({
  type: DELETE_CONVERSATION_TO_TRASH,
  payload: conversationId,
});

export const deleteConversationPermanently = (payload) => ({
  type: DELETE_CONVERSATION_PERMANENTLY,
  payload,
});

export const deleteMailsToTrash = ({ msgId, conversationId }) => (dispatch) => {
  return API.deleteMailsToTrash({msgId, conversationId})
    .then(() => {
      if (msgId) {
        dispatch(deleteMsgFromMailChat(msgId));
      } else if (conversationId) {
        dispatch(deleteConversationToTrash(conversationId));
      }
    })
    .catch(console.log);
}

export const deleteMailsPermanently = ({ msgId, conversationId, folder }) => (dispatch) => {
  return API.deleteMailsPermanently({ msgId, conversationId, folder })
    .then(() => {
      if (msgId) {
        dispatch(deleteMsgFromMailChat(msgId));
      } else if (conversationId) {
        dispatch(deleteConversationPermanently({ conversationId, folder }));
      }
    })
    .catch(console.log);
}

export const markMailsNotSpam = (conversationId) => ({
  type: MARK_MAILS_NOT_SPAM,
  payload: conversationId,
});

export const markMailsNotSpamRequest = ({ conversationId }) => (dispatch) => {
  return API.markMailsNotSpam({ conversationId })
    .then(() => {
      dispatch(markMailsNotSpam(conversationId));
    })
    .catch(console.log);
}

export const undeleteMails = ({ msgId, conversationId }) => ({
  type: UNDELETE_MAILS,
  payload: { msgId, conversationId },
});

export const undeleteMailsRequest = ({ msgId, conversationId }) => (dispatch) => {
  return API.undeleteMails({ msgId, conversationId })
    .then(() => {
      dispatch(undeleteMails({ msgId, conversationId }));
    })
    .catch(console.log);
}

export const setDelayedMessage = delayedMessage => ({
  type: SET_DELAYED_MESSAGE,
  payload: delayedMessage,
});

export const unsetDelayedMessage = () => ({
  type: UNSET_DELAYED_MESSAGE,
});

const initialState = {
  inbox: {
    ids: [],
    entities: {},
  },
  sent: {
    ids: [],
    entities: {},
  },
  drafts: {
    ids: [],
    entities: {},
  },
  spam: {
    ids: [],
    entities: {},
  },
  trash: {
    ids: [],
    entities: {},
  },
  
  pending: true,
  totalCount: 0,
  unreadCount: 0,
  unreadSpamCount: 0,
  offset: 0,

  activeMailChatId: null,
  activeMailChatMessages: [],

  delayedMessage: null,
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_PENDING: {
      return {
        ...state,
        pending: payload,
      }
    }

    case GET_EMAILS: {
      return {
        ...state,
        pending: false,
        [payload.type]: {
          ids: payload.mails.result,
          entities: payload.mails.entities,
        },
        totalCount: payload.totalCount,
      }
    }

    case ADD_EMAIL: {
      const { mail, message } = payload;
      const type = message.folder_type?.toLowerCase();
      
      if (!Object.values(LIST_TYPES).includes(type)) {
        return state;
      }
      
      const stateIds = state[type].ids;

      const getIds = () => {
        if (stateIds.includes(mail.conversationId)) {
          return [mail.conversationId, ...stateIds.filter(id => id !== mail.conversationId)];
        }
        else {
          return stateIds.length === MAIL_LIST_LIMIT
            ? [mail.conversationId, ...stateIds.slice(0, stateIds.length - 1)]
            : [mail.conversationId, ...stateIds];
        }
      }

      if (state.offset !== 0) {
        return {
          ...state,
          totalCount: !payload.isExisted 
            ? state.totalCount + 1 
            : state.totalCount,
          unreadCount: payload.unreadCount,
          unreadSpamCount: payload.unreadSpamCount,
        }
      }
      else {
        return {
          ...state,
          [type]: {
            ids: getIds(),
            entities: {
              ...state[type].entities,
              [mail.conversationId]: mail
            },
          },
          totalCount: !payload.isExisted
            ? state.totalCount + 1
            : state.totalCount,
          unreadCount: payload.unreadCount,
          unreadSpamCount: payload.unreadSpamCount,
        }
      }
    }

    case SET_OFFSET: {
      return {
        ...state,
        offset: payload,
      }
    }

    case SET_UNREAD_COUNT: {
      const { unreadCount, unreadSpamCount } = payload;

      return {
        ...state,
        unreadCount,
        unreadSpamCount,
      };
    }

    case SET_ACTIVE_MAIL_CHAT: {
      return {
        ...state,
        activeMailChatId: payload.id,
        activeMailChatMessages: payload.messages,
      }
    }

    case CLEAR_ACTIVE_MAIL_CHAT: {
      return {
        ...state,
        activeMailChatId: null,
        activeMailChatMessages: [],
      }
    }

    case ADD_MESSAGE_TO_MAIL_CHAT: {
      return {
        ...state,
        activeMailChatMessages: [...state.activeMailChatMessages, payload]
      }
    }

    case DELETE_MSG_FROM_MAIL_CHAT:
      return {
        ...state,
        activeMailChatMessages: state.activeMailChatMessages.filter(
          ({ id }) => id !== payload
        ),
      };

    case UPDATE_MESSAGE_DRAFT: {
      const { msgId, draftUpdate } = payload;

      return {
        ...state,
        activeMailChatMessages: state.activeMailChatMessages.map(updateMsgIfNeeded),
      };

      function updateMsgIfNeeded (msg) {
        if (msg.id === msgId) {
          const draft = msg?.draft ? { ...msg.draft, ...draftUpdate } : draftUpdate;
          return { ...msg, draft };
        }
        return msg;
      }
    }

    case DELETE_MESSAGE_DRAFT:
      return {
        ...state,
        activeMailChatMessages: state.activeMailChatMessages.map(
          message => message.draft?.id === payload ? { ...message, draft: null } : message
        ),
      };

    case DELETE_CONVERSATION_TO_TRASH:
      return {
        ...state,
        [LIST_TYPES.inbox]: delConversFromFolder(state[LIST_TYPES.inbox], payload),
        [LIST_TYPES.sent]: delConversFromFolder(state[LIST_TYPES.sent], payload),
        [LIST_TYPES.spam]: delConversFromFolder(state[LIST_TYPES.spam], payload),
        [LIST_TYPES.drafts]: delConversFromFolder(state[LIST_TYPES.drafts], payload),
      };

    case DELETE_CONVERSATION_PERMANENTLY: {
      const { conversationId, folder} = payload;
      const normalizedFolder = folder?.toLowerCase();

      if (!Object.values(LIST_TYPES).includes(normalizedFolder)) {
        return state;
      }

      return {
        ...state,
        [normalizedFolder]: delConversFromFolder(state[normalizedFolder], conversationId),
      };
    }

    case MARK_MAILS_NOT_SPAM:
      return {
        ...state,
        [LIST_TYPES.spam]: delConversFromFolder(state[LIST_TYPES.spam], payload),
      };

    case UNDELETE_MAILS: {
      const { msgId, conversationId } = payload;

      if (msgId) {
        return {
          ...state,
          activeMailChatMessages: state.activeMailChatMessages.filter(
            ({ id }) => id !== msgId
          ),
        };
      }

      return {
        ...state,
        [LIST_TYPES.trash]: delConversFromFolder(state[LIST_TYPES.trash], conversationId),
      };
    }

    case SET_DELAYED_MESSAGE:
      return {
        ...state,
        delayedMessage: payload,
      };

    case UNSET_DELAYED_MESSAGE:
      return {
        ...state,
        delayedMessage: null,
      };
  
    default:
      return state;
  }
}

// Helpers

const delConversFromFolder = (folder, delId) => {
  const entities = { ...folder.entities };
  delete entities[delId];

  return {
    ids: folder.ids.filter((id) => id !== delId),
    entities,
  };
}
