import { useEffect } from "react";
import { connect } from "react-redux";


const FaviconEditor = (props) => {
  const {
    isIncomingCall,
    isMissedCall,
    isUnreadMessage,
  } = props;

  useEffect(() => {
    const favicon = document.getElementById('favicon');

    if (isIncomingCall) {
      favicon.href = '/media/favicons/incall.svg';
    }
    else if (isMissedCall) {
      favicon.href = '/media/favicons/missed.svg';
    }
    else if (isUnreadMessage) {
      favicon.href = '/media/favicons/message.svg';
    }
    else {
      favicon.href = 'favicon.ico';
    }
  }, [isIncomingCall, isMissedCall, isUnreadMessage]);

  return null;
};

const mapStateToProps = (state) => ({
  isIncomingCall: state.calls.incomingCall,
  isMissedCall: !!state.missedCalls.ids.length,
  isUnreadMessage: !!state.contacts.clients.unreadCount || !!state.contacts.girls.unreadCount || !!state.rooms.unreadCount,
});

export default connect(mapStateToProps)(FaviconEditor);