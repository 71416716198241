import React, { useRef, useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faBook, faSearch } from '@fortawesome/free-solid-svg-icons';

import { useToggle } from '../../hooks';
import { classModifier, getDateByTimezoneOffset } from '../../utils';
import { selectUserTimezone } from '../../selectors/selectors';
import { openModal, MODAL_TYPES } from '../../ducks/activeWindows';

import './Bookings.scss';
import BookingsList from './components/BookingsList';
import DropWrapper from '../../components/DropWrapper/DropWrapper';
import BookingsCalendar from './components/BookingsCalendar';
import UncompletedBookings from './components/UncompletedBookings';

const Bookings = ({ userTimezone, ...props }) => {
  const twentyFourHours = 86400000;
  const todayByTimezone = getDateByTimezoneOffset(userTimezone).setHours(0, 0, 0, 0) -
    (userTimezone - (new Date().getTimezoneOffset() * (-1))) * 60000;
  const yesterdayByTimezone = todayByTimezone - twentyFourHours;
  const tomorrowByTimezone = +todayByTimezone + twentyFourHours;

  const [state, setState] = useState({
    activeTab: 1,
    date: null,
  });

  const [isBookingsListOpen, toggleBookingsList] = useToggle(props.isChat);

  const [isConfirmOnClose, toggleConfirmOnClose] = useToggle(false);

  const listRef = useRef();

  useEffect(() => {
    const isLoaded = userTimezone !== undefined;

    if (isLoaded) {
      setState({
        activeTab: 1,
        date: todayByTimezone,
      });
    }

  }, [userTimezone]);

  const closeBookings = useCallback(() => {
    if (props.isChat) return;

    toggleBookingsList();
    setState({
      activeTab: 1,
      date: todayByTimezone,
    });
  }, [userTimezone]);

  const startFilteringByDate = (date) => {
    const d = new Date(date).setHours(0, 0, 0, 0);
    const localTimezoneOffset = -new Date().getTimezoneOffset();
    const userOffset = localTimezoneOffset - userTimezone;
    const minute = 60000;

    const day = d + (userOffset * minute);
    let activeTab;

    switch (day) {
      case todayByTimezone:
        activeTab = 1;
        break;
      case yesterdayByTimezone:
        activeTab = 0;
        break;
      case tomorrowByTimezone:
        activeTab = 2;
        break;
      default:
        activeTab = -1;
        break;
    }
    setState({ date: day, activeTab });
  }

  const onSelectDefaultDate = (tabIndex) => {
    let date;
    let updatedTabIndex = tabIndex;

    if (tabIndex === 3 && state.activeTab === 3) {
      updatedTabIndex = 1;
      date = todayByTimezone;
    }

    switch (tabIndex) {
      case 0:
        date = yesterdayByTimezone;
        break;
      case 1:
        date = todayByTimezone;
        break;
      case 2:
        date = tomorrowByTimezone;
        break;
    }
    setState({
      date: date,
      activeTab: updatedTabIndex,
    });
  }

  const changeDateByNavigation = (isNext) => {
    const updatedDate = isNext
      ? state.date + twentyFourHours
      : state.date - twentyFourHours;
    let activeTab;

    switch (updatedDate) {
      case yesterdayByTimezone:
        activeTab = 0;
        break;
      case todayByTimezone:
        activeTab = 1;
        break;
      case tomorrowByTimezone:
        activeTab = 2;
        break;
      default:
        activeTab = -1;
    }

    setState({
      date: updatedDate,
      activeTab,
    });
  }

  const isSelectedCustomDate = state.activeTab === -1;
  const isOpenUncompleted = state.activeTab === 3;

  return (
    <div className='bookings__wrap'>
      {!props.isChat &&
        <button
          className='bookings__btn--open'
          onMouseDown={toggleBookingsList}
        >
          bookings
      </button>
      }

      {isBookingsListOpen &&
        <DropWrapper
          dropWrapperRef={listRef}
          closeDropWrapper={closeBookings}
          onClose={() => isConfirmOnClose}
        >
          <div ref={listRef}>
            <Tabs
              selectedIndex={state.activeTab}
              onSelect={onSelectDefaultDate}
            >
              <div className='bookings'>
                <TabList className='bookings__tabs-wrap'>
                  {!isOpenUncompleted && <>
                    <button
                      className="bookings__navigation bookings__navigation--left"
                      onClick={() => changeDateByNavigation(false)}
                    >
                      <FontAwesomeIcon icon={faChevronLeft} title="Previous day" />
                    </button>

                    <Tab className='bookings__tab'>Yesterday</Tab>
                    <Tab className='bookings__tab'>Today</Tab>
                    <Tab className='bookings__tab'>Tomorrow</Tab>

                    <button
                      className="bookings__navigation bookings__navigation--right"
                      onClick={() => changeDateByNavigation(true)}
                    >
                      <FontAwesomeIcon icon={faChevronRight} title="Next day" />
                    </button>
                  </>}

                  {!isOpenUncompleted &&
                    <button
                      className="bookings__search-btn"
                      onClick={() => props.openModal(MODAL_TYPES.bookingsByDates)}
                    >
                      <FontAwesomeIcon
                        icon={faSearch}
                        color="#404040"
                      />
                    </button>
                  }

                  <Tab className='bookings__uncompleted-btn-wrap'>
                    <button
                      className={classModifier('bookings__uncompleted-btn', isOpenUncompleted && 'open')}
                      title={isOpenUncompleted ? 'Back' : 'Uncompleted bookings'}
                    >
                      <FontAwesomeIcon
                        icon={isOpenUncompleted ? faChevronLeft : faBook}
                        color={isOpenUncompleted ? '#0092f2' : '#404040'}
                      />
                    </button>
                  </Tab>

                  {!isOpenUncompleted &&
                    <BookingsCalendar
                      isActive={isSelectedCustomDate}
                      onChange={startFilteringByDate}
                      date={state.date}
                      userTimezone={userTimezone}
                    />
                  }
                </TabList>

                <TabPanel>
                  <BookingsList
                    isChat={props.isChat}
                    onClose={closeBookings}
                    userTimezone={userTimezone}
                    dateFilter={state.date}
                    toggleConfirmOnClose={toggleConfirmOnClose}
                  />
                </TabPanel>

                <TabPanel>
                  <BookingsList
                    isChat={props.isChat}
                    onClose={closeBookings}
                    userTimezone={userTimezone}
                    dateFilter={state.date}
                    toggleConfirmOnClose={toggleConfirmOnClose}
                  />
                </TabPanel>

                <TabPanel>
                  <BookingsList
                    isChat={props.isChat}
                    onClose={closeBookings}
                    userTimezone={userTimezone}
                    dateFilter={state.date}
                    toggleConfirmOnClose={toggleConfirmOnClose}
                  />
                </TabPanel>

                <TabPanel>
                  <UncompletedBookings
                    isChat={props.isChat}
                    onClose={closeBookings}
                    toggleConfirmOnClose={toggleConfirmOnClose}
                  />
                </TabPanel>

                {isSelectedCustomDate &&
                  <BookingsList
                    isChat={props.isChat}
                    onClose={closeBookings}
                    userTimezone={userTimezone}
                    dateFilter={state.date}
                    toggleConfirmOnClose={toggleConfirmOnClose}
                  />
                }
              </div>
            </Tabs>
          </div>
        </DropWrapper>
      }
    </div>
  );
};

const mapStateToProps = state => ({
  userTimezone: selectUserTimezone(state)
});

const mapDispatchToProps = {
  openModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(Bookings);