import React, { useState } from 'react';
import { Scrollbars } from "react-custom-scrollbars";
import { useDispatch } from 'react-redux';

import API from '../../../../api/api';
import { CHAT_TYPES } from '../../../../config/constants';
import { getContactAvatar, LS } from '../../../../utils';
import { updateRecentTabs } from '../../../../ducks/contacts';
import { useToggle } from '../../../../hooks';

import CreateRoomDialog from '../../../Sidebar/components/RoomTabs/CreateRoomDialog/CreateRoomDialog';
import SvgIcon from '../../../../components/SvgIcon/SvgIcon';
import LazyLoadImage from '../../../../components/LazyLoadImage/LazyLoadImage';
import RoomParticipant from './RoomParticipant';


const RoomDetails = ({ activeRecipient, isIamAdmin, user, ...props }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [isShowAddMenu, menuToggle] = useToggle(false);
  const dispatch = useDispatch();

  const leaveRoom = () => {
    if (window.confirm(`Leave from room ${activeRecipient.chatTitle}?`)) {
      props.updateActiveChat(1);

      API.leaveRoom(activeRecipient.id)
        .then(() => {
          const recentTabs = LS.getItem('roomChatsRecentTabs', user.id);
          if (recentTabs) {
            const relevantTabs = recentTabs.all.filter(tab => tab !== activeRecipient.id);

            dispatch(updateRecentTabs(null, CHAT_TYPES.ROOM, relevantTabs));
          }
        })
        .catch(console.log);
    }
  };

  const removeRoomForAll = () => {
    if (window.confirm(`Remove room ${activeRecipient.chatTitle}?`)) {
      API.removeRoomForAll(activeRecipient.id)
        .then(() => {
          const recentTabs = LS.getItem('roomChatsRecentTabs', user.id);
          if (recentTabs) {
            const relevantTabs = recentTabs.all.filter(tab => tab !== activeRecipient.id);

            dispatch(updateRecentTabs(null, CHAT_TYPES.ROOM, relevantTabs));
          }
        })
        .catch(console.log);
    }
  };

  return (
    <section className="room-info-card__section">
      <header className="room-info-card__header">

        <div className="room-info-card__add-wrap" onClick={() => menuToggle()}>
          <button className="room-info-card__add-icon" >
            <SvgIcon icon="plus" width="18" height="18" fill="#0092F2" title="add participants" />
          </button>

          <span className="room-info-card__add-row">Add Member</span>
        </div>

        {isShowAddMenu &&
          <CreateRoomDialog
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            toggleCreateRoom={menuToggle}
            ignoreOperatorsIds={activeRecipient.usersIds}
            addModeRoomId={activeRecipient.id}
            userId={user.id}
            isShowAddMenu={isShowAddMenu}
          />
        }
      </header>

      <Scrollbars
        hideTracksWhenNotNeeded>
        <ul className="room-info-card__list">
          <li className="room-info-card__list-item">
            <figure className="room-info-card__ava-wrap">
              <LazyLoadImage
                src={getContactAvatar(user)}
                className="round-img"
                alt="contact_avatar" />
            </figure>

            <span className="room-info-card__name--operator">You</span>

            <span className={`room-info-card__name--action${isIamAdmin ? `-adm` : ``}`}>
              {isIamAdmin && 'Admin'}
            </span>
          </li>

          {activeRecipient.usersIds && activeRecipient.usersIds.map(id => {
            return <RoomParticipant
              roomId={activeRecipient.id}
              adminsIds={activeRecipient.adminsIds}
              isIamAdmin={isIamAdmin}
              key={id}
              id={id} />;
          })}

        </ul>
      </Scrollbars>

      <footer className="room-info-card__footer">
        {isIamAdmin
          ? <button
            onClick={removeRoomForAll}
            className="room-info-card__btn--sbmt">
            Remove group
            </button>
          : <button
            onClick={leaveRoom}
            className="room-info-card__btn--sbmt">
            Leave group
            </button>
        }
      </footer>
    </section>
  );
};

export default RoomDetails;
