import convertDateFromUTC from "./convertDateFromUTC";
import formatDateToTime from "./formatDateToTime";


const formatNotificationDate = (date, userHour12) => {
  const oneDay = 1000 * 60 * 60 * 24; // in ms
  const today = new Date().setHours(0, 0, 0, 0);
  let day;

  // console.log(date);

  if (typeof date === 'string') {
    date = convertDateFromUTC(date);
  }
  else {
    date = new Date(date); // if it's number, that create date, if Date keep it unchanged
  }

  const onlyDate = new Date(date).setHours(0, 0, 0, 0);

  if (onlyDate === today) {
    day = 'Today';
  }
  else if (onlyDate === today - oneDay) {
    day = 'Yesterday';
  }
  else if (onlyDate < today && today - onlyDate < oneDay * 6) {
    day = date.toLocaleString('en-US', { weekday: 'long' }); // last 6 days
  }
  else {
    day = date.toLocaleString('en-US', { day: 'numeric', month: 'long' });
  }
  return `${day} at ${formatDateToTime(date, userHour12)}`;
};

export default formatNotificationDate;