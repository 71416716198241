import React, { useState, useRef, useCallback } from "react";

import "./CreateRoomDialog.scss";
import API from "../../../../../api/api";
import { ROOM } from "../../../../../config/constants";
import { getContactAvatar } from "../../../../../utils";
import { useDidMount, useWillUnmount, useToggle } from "../../../../../hooks";

import SvgIcon from "../../../../../components/SvgIcon/SvgIcon";
import LazyLoadImage from "../../../../../components/LazyLoadImage/LazyLoadImage";
import SearchByOperators from "../../../../../components/SearchByOperators/SearchByOperators1";
import OperatorEntity from "./OperatorEntity";


const CreateRoomDialog = ({ addModeRoomId, ignoreOperatorsIds = [], userId, isShowAddMenu, toggleCreateRoom, ...props }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [roomName, setRoomName] = useState('');
  const [room, setRoom] = useState({
    photo: null,
    photoFile: null
  });

  const [isShowOperatorsSearch, toggleIsShowOperatorsSearch] = useToggle(isShowAddMenu || false);
  const [selectedOperatorsNames, setSelectedOperatorsNames] = useState([{ username: 'You', status: 'online', id: userId }]);

  const inputRef = useRef();

  const closeHandle = () => {
    props.onRemoveChatTab('new_chat');
  };

  useDidMount(() => {
    inputRef.current.focus();
  });

  useWillUnmount(() => {
    setSearchQuery('');
  });

  const createNewRoom = (ids) =>
    API.saveRoom(roomName, ids, room.photoFile)
      .then(() => props.onRemoveChatTab("new_chat"));

  const addUsersToRoom = (ids) =>
    API.addUsersFromRoom(addModeRoomId, ids)
      .then(() => toggleCreateRoom && toggleCreateRoom());

  const changeRoomPhoto = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      if (file.type.startsWith('image')) {
        setRoom({
          ...room,
          photo: reader.result,
          photoFile: file,
        });
      }
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const isSubmitDisabled = (operatorsIds) => roomName.trim() && operatorsIds.length >= 3
    ? false
    : true;

  const toggleOperatorsList = () => {
    if (isShowOperatorsSearch) {
      setSelectedOperatorsNames([{ username: 'You', status: 'online', id: userId }]);
    }

    toggleIsShowOperatorsSearch();
  }

  return (
    <div
      className={addModeRoomId
        ? "create-room-dialog__main-wrap create-room-dialog--add-mode"
        : "create-room-dialog__main-wrap"}>
      <div className="create-room-dialog__header">
        <div className="create-room-dialog__room-info">
          <label htmlFor="create-room-dialog__file">
            <div className="create-room-dialog__logo-wrapper">
              <LazyLoadImage
                src={getContactAvatar(room.photo
                  ? { photo: { min: room.photo } }
                  : { photo: null }, ROOM)
                }
                className="round-img"
                alt="contact_avatar"
              />
            </div>
            <input
              style={{ position: 'absolute', width: 0, height: 0, cursor: 'pointer' }}
              type="file"
              id="create-room-dialog__file"
              onChange={changeRoomPhoto} />
          </label>


          <input
            ref={inputRef}
            onChange={(e) => setRoomName(e.target.value)}
            className="create-room-dialog__input"
            type="text"
            value={roomName}
            placeholder="Set room name"
          />
        </div>

        <div className="create-room-dialog__fake-chat-header">
          <button>
            <SvgIcon width="20px" height="20px" fill="#808080" icon="messages" />
          </button>

          <button>
            <svg enableBackground="new 0 0 430.23 430.23" fill="#808080" version="1.1" viewBox="0 0 430.23 430.23" width="18" height="18">
              <path d="m217.88 159.67c-24.237 0-43.886 19.648-43.886 43.886 0 24.237 19.648 43.886 43.886 43.886 24.237 0 43.886-19.648 43.886-43.886s-19.648-43.886-43.886-43.886zm0 66.873c-12.696 0-22.988-10.292-22.988-22.988s10.292-22.988 22.988-22.988 22.988 10.292 22.988 22.988-10.292 22.988-22.988 22.988z" />
              <path d="m392.9 59.357l-285.26-32.391c-11.071-1.574-22.288 1.658-30.824 8.882-8.535 6.618-14.006 16.428-15.151 27.167l-5.224 42.841h-16.197c-22.988 0-40.229 20.375-40.229 43.363v213.68c-0.579 21.921 16.722 40.162 38.644 40.741 0.528 0.014 1.057 0.017 1.585 0.01h286.82c22.988 0 43.886-17.763 43.886-40.751v-8.359c7.127-1.377 13.888-4.224 19.853-8.359 8.465-7.127 13.885-17.22 15.151-28.212l24.033-212.11c2.45-23.041-14.085-43.768-37.094-46.499zm-42.841 303.54c0 11.494-11.494 19.853-22.988 19.853h-286.82c-10.383 0.305-19.047-7.865-19.352-18.248-0.016-0.535-9e-3 -1.07 0.021-1.605v-38.661l80.98-59.559c9.728-7.469 23.43-6.805 32.392 1.567l56.947 50.155c8.648 7.261 19.534 11.32 30.825 11.494 8.828 0.108 17.511-2.243 25.078-6.792l102.92-59.559v101.36zm0-125.91l-113.89 66.351c-9.78 5.794-22.159 4.745-30.825-2.612l-57.469-50.678c-16.471-14.153-40.545-15.021-57.992-2.09l-68.963 50.155v-148.9c0-11.494 7.837-22.465 19.331-22.465h286.82c12.28 0.509 22.197 10.201 22.988 22.465v87.771zm59.057-133.96c-7e-3 0.069-0.013 0.139-0.021 0.208l-24.555 212.11c0.042 5.5-2.466 10.709-6.792 14.106-2.09 2.09-6.792 3.135-6.792 4.18v-184.42c-0.825-23.801-20.077-42.824-43.886-43.363h-249.73l4.702-40.751c1.02-5.277 3.779-10.059 7.837-13.584 4.582-3.168 10.122-4.645 15.674-4.18l284.74 32.914c11.488 1.091 19.918 11.29 18.827 22.78z" />
            </svg>
          </button>

          <button>
            <SvgIcon icon="search" fill="#808080" width="18" height="18" />
          </button>
        </div>


        <div
          className='create-room-dialog__close'
          onClick={closeHandle} />


        <button
          style={{ backgroundColor: isShowOperatorsSearch ? '#808080' : '#0092f2' }}
          className="create-room-dialog__show-search"
          onClick={toggleOperatorsList}>
          {isShowOperatorsSearch
            ? '-'
            : '+'
          }
        </button>
      </div>
      {isShowOperatorsSearch &&
        <ul className="create-room-dialog__chosen">
          {selectedOperatorsNames.map(operator => <li data-isonline={operator.status} key={operator.id}>{operator.username}</li>)}
        </ul>
      }

      {isShowOperatorsSearch &&
        <SearchByOperators
          listItem={OperatorEntity}
          classPrefix='create-room-dialog'
          listItemProps={{
            isDialogMode: true,
            setNames: setSelectedOperatorsNames
          }}
          ignoredIds={ignoreOperatorsIds}
          initialIds={[userId]}
          query={searchQuery}
          setQuery={setSearchQuery}

          withSubmitBtn
          isAsyncSubmit={isShowAddMenu}
          isShowAddMenu={isShowAddMenu}
          submitBtnText={isShowAddMenu ? "Add members" : "Create Chat"}
          onSubmit={isShowAddMenu ? addUsersToRoom : createNewRoom}
          isSubmitBtnDisabled={isSubmitDisabled}
        />
      }
    </div>
  );
};

export default CreateRoomDialog;