import React, { useState, useEffect, useRef } from 'react';

import { usePrevious, useWillUnmount } from '../../hooks';

import Spinner from '../UI/Spinner/Spinner';

const AsyncButton = (props) => {
  const {
    className,
    spinnerSize,
    onClick,
    pending: pendingFromProps = false,
    disabled,
    ...buttonProps
  } = props;

  const [pending, setPending] = useState(pendingFromProps);

  const prevPendingFromProps = usePrevious(pendingFromProps);

  let isUnmount = useRef(false);

  useEffect(() => {
    if (prevPendingFromProps && !pendingFromProps) {
      setPending(false);
    }
    else if (!prevPendingFromProps && pendingFromProps) {
      setPending(true);
    }
  }, [pendingFromProps]);

  useWillUnmount(() => isUnmount.current = true);

  const handleClick = () => {
    if (onClick && typeof onClick === 'function') {
      setPending(true);

      return onClick()
        .then(() => !isUnmount.current && setPending(false))
        .catch(console.error)
    }
  };

  return (
    <button
      className={className}
      onClick={handleClick}
      disabled={pending || disabled}
      {...buttonProps}
    >
      {pending
        ? <Spinner spinnerSize={spinnerSize} />
        : props.children
      }
    </button>
  );
};

export default AsyncButton;
