import React, { useCallback, useState } from "react";
import { connect } from "react-redux";

import API from "../../api/api";
import {
  createSession,
  duplicateClosedSession,
  resetDefaultSessionFilters
} from "../../ducks/sessions";
import {
  useHideTemporaryTooltip,
  useDidMount
} from "../../hooks";
import { selectDefaultSession, selectIsPartnersSession, selectPropertyOfActiveSession } from "../../selectors/selectors";
import { classModifier, isEmptyObj } from "../../utils";

import SearchableList from "../SearchableList/SearchableList";
import SearchContactItem from "./SearchContactItem";
import DropWrapper from "../DropWrapper/DropWrapper";


const SearchByClients = ({ defaultSession, ...props }) => {
  const [selectedContact, setSelectedContact] = useState(null);
  const [isPending, setPending] = useState(true);

  const [search, setSearch] = useState({
    query: '',
    foundClients: []
  });

  const [clients, setClients] = useState([]);

  useDidMount(() => {
    API.getContactList(1, 0)
      .then(res => {
        setClients(res.data.contacts);
      })
      .catch(console.log)
      .finally(() => setPending(false));
  });

  const loadMore = useCallback((offset) => {
    if (search.query) {
      API.searchContacts(1, search.query, offset)
        .then(res => setSearch({
          ...search,
          foundClients: [...search.foundClients, ...res.data]
        }))
        .catch(console.log);
    }
    else {
      API.getContactList(1, offset)
        .then(res => {
          setClients([...clients, ...res.data.contacts]);
        })
        .catch(console.log);
    }
  }, [clients, search]);

  const listItem = useCallback((props) =>
    <SearchContactItem
      sessionMode
      classPrefix="session-operators"
      onClickAction={setSelectedContact}
      {...props}
    />, []);

  const [tooltipStatus, setTooltipStatus] = useHideTemporaryTooltip('', 2000);

  const onSubmit = () => {
    if (!selectedContact) return;

    const serverUniqueError = 'Caller can have only one active session';
    const clientUniqueError = 'Session with this client already exist';

    switch (props.type) {
      case 'duplicate': { // Duplicate session with another client
        return props.duplicateClosedSession(props.sessionId, selectedContact.id)
          .then(res => {
            if (res && res.errorMsg === serverUniqueError) {
              return setTooltipStatus(clientUniqueError);
            }

            props.onSubmit();
          });
      }

      case 'init-session': { // Init session from sales-page
        return props.createSession(selectedContact.id)
          .then(res => {
            if (res && res.errorMsg === serverUniqueError) {
              return setTooltipStatus(clientUniqueError);
            }
            if (props.isPartners) {
              API.removeViewedInSession(props.activeSession);
            }

            props.onClose();
          });
      }

      // case 'change-client-in-session': { // Change client in session
      //   return API.changeClientInSession(props.sessionId, selectedContact.id)
      //     .then(() => {
      //       props.onClose();
      //     })
      //     .catch(err => {
      //       if (err && err.response && err.response.data.message === serverUniqueError) {
      //         setTooltipStatus(clientUniqueError);
      //       }
      //     });
      // }

      case 'new-session-based-on-default': {
        return props.createSession(
          selectedContact.id,
          defaultSession.bufferedIds,
          defaultSession.comparedIds,
          defaultSession.activeFilters,
          defaultSession.additionalFilters,
          defaultSession.recentlyViewedIds,
          )
          .then(res => {
            if (res && res.errorMsg === serverUniqueError) {
              return setTooltipStatus(clientUniqueError);
            }
            if (props.isPartners) {
              API.removeViewedInSession(props.activeSession);
            }

            props.onClose();
          });
      }

      default:
        return;
    }
  };

  const startSearch = (query) => {
    API.searchContacts(1, query)
      .then(res => {
        setSearch({
          foundClients: res.data,
          query
        });
      })
      .catch(console.log);
  };

  const stopSearch = () => {
    setSearch({
      foundClients: [],
      query: ''
    });
  };

  const isCommPage = window.location.pathname === '/';

  return (
    <DropWrapper
      dropWrapperRef={props.wrapperRef}
      closeDropWrapper={props.onClose}
    >
      <div className={classModifier('session-operators__wrap', [isCommPage && 'comm-page'])}>
        <div className="session-operators__title">
          {props.title}
        </div>

        <div
          className='session-operators__close'
          onClick={props.onClose}
        ></div>

        <SearchableList
          classPrefix='session-operators'
          list={search.query ? search.foundClients : clients}
          listLimit={20}
          scrollInitialPosition='top'
          loadMore={loadMore}
          listItem={listItem}
          listItemProps={{
            selectedContact,
          }}
          startSearch={startSearch}
          stopSearch={stopSearch}
          listLoadPending={isPending}
        />

        <button
          className='session-operators__submit'
          disabled={!selectedContact}
          onClick={onSubmit}
        >
          SUBMIT
      </button>

        <div className="session-operators__tooltip-wrap">
          <span
            className="session-operators__tooltip"
            style={{
              padding: tooltipStatus ? '2px 4px' : '0'
            }}
          >
            {tooltipStatus}
          </span>
        </div>
      </div>
    </DropWrapper>
  );
};

const mapStateToProps = (state) => ({
  isPartners: selectIsPartnersSession(state),
  activeSession: state.sessions.activeSession,
  defaultSession: selectDefaultSession(state),
  additionalFilters: (!!state.sessions.entities[state.sessions.activeSession] && selectPropertyOfActiveSession(state, 'additionalFilters')),
});

const mapDispatchToProps = {
  createSession,
  duplicateClosedSession,
  resetDefaultSessionFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchByClients);