import cleanGirlTag from "./cleanGirlTag";

export default (interaction, action) => {
  if (document.hasFocus()) {
    return Promise.resolve(null);
  }
  if ('Notification' in window) {
    return Notification.requestPermission()
      .then(permission => {
        if (permission === 'granted') {
          const notification = new Notification(...generateNotification(interaction));

          notification.onclick = (e) => {
            console.log('notification clicked');

            e.target.close();
            window.focus();
            action();
          };
          return notification;
        }
      });
  }
}

const generateNotification = (interaction) => {
  const interactionType = interaction.type === 1 ? 'call' : 'msg';
  const title = `New incoming ${interactionType} from ${interaction.caller.fn}`;
  const options = {
    icon: '/favicon.ico',
    vibrate: [100, 50, 100],
    data: {
      interaction,
    },
    lang: 'en-GB',
  };
  if (interactionType === 'msg') {
    options.body = cleanGirlTag(interaction.body);
  }
  else {
    options.requireInteraction = true;
  }

  return [title, options]
}