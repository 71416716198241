import React, { useRef } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faCheck } from "@fortawesome/free-solid-svg-icons";

import './CopyToClipboardButton.scss'

import { useToggle } from '../../hooks';
import {
  classModifier,
  // copyToClipboard,
  textLineSplitter,
} from '../../utils';


const CopyToClipboardButton = ({ className, value, title, svgWidth, svgHeight, disabled }) => {

  const inputCopyRef = useRef();

  const [showCheckIcon, toggleCheckIcon] = useToggle(false);


  return (
    <span className={classModifier(className, "wrapper")}>
      <input
        className="copy-input"
        ref={inputCopyRef}
        readOnly="readOnly"
        value={textLineSplitter(value) || ''}
      />

      <button
        className={classModifier(className, "btn")}
        type="button"
        title={title ? title : "Copy"}
        disabled={disabled}
        onClick={(e) => {
          toggleCheckIcon(true);
          // copyToClipboard(e, value, inputCopyRef.current); // copy without line break
          window.navigator.clipboard.writeText(value) // copy with line break
          setTimeout(() => {
            toggleCheckIcon(false)
          }, 700);
        }}
      >
        <FontAwesomeIcon
          icon={showCheckIcon ? faCheck : faCopy}
          color={showCheckIcon ? "#0092F2" : "#00bf0d"}
          className='clipboard-icon'
        />
        {/* <SvgIcon
          fill={showCheckIcon ? "#00bf0d" : "#0092F2"}
          icon={showCheckIcon ? "checked2" : "copy_2"}
          width={svgWidth ? svgWidth : '20'}
          height={svgHeight ? svgHeight : '20'}
          urlPath='/media/sprite-svg.svg'
        /> */}
      </button>
    </span>
  );
};

export default CopyToClipboardButton;