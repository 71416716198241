import React, { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShare } from '@fortawesome/free-solid-svg-icons';

import { useToggle } from '../../../../../hooks';

import EditContactTelsForm from './EditContactTelsForm';
import DropWrapper from '../../../../../components/DropWrapper/DropWrapper';


const ChatMessageForbidInput = (props) => {
  const {
    activeRecipient,
  } = props;

  const [isFormOpen, toggleFormOpen] = useToggle(false);

  const formRef = useRef(null);

  return (<>
    {isFormOpen &&
      <div
        ref={formRef}
      >
        <DropWrapper
          closeDropWrapper={toggleFormOpen}
          dropWrapperRef={formRef}
        >
          <EditContactTelsForm
            activeRecipient={activeRecipient}
            onClose={toggleFormOpen}
          />
        </DropWrapper>
      </div>
    }

    <div className='message-forbid'>
      <button
        className="message-forbid__open-editor"
        onClick={() => toggleFormOpen()}
        disabled={isFormOpen}
      >
        Add Line
      </button>

      <div className='message-forbid__input'>
        <div className="message-forbid__text">
          <div>Communication is NOT possible !</div>
          <div>Please add a default Communication line</div>
        </div>

        <FontAwesomeIcon
          icon={faShare}
          className='message-forbid__send-icon'
          flip='horizontal'
        />
      </div>
    </div>
  </>);
}

export default ChatMessageForbidInput;