import React from 'react';

import { getContactAvatar, classModifier } from '../../../../utils';

// import './SearchAreaCallerItem.scss';
import LazyLoadImage from '../../../../components/LazyLoadImage/LazyLoadImage';


const DialpadSearchItem = ({ data, isFocused, innerProps, innerRef }) => (
  data
    ? (
      <div {...innerProps}
        className={classModifier('contact-item', isFocused && 'focused')}
        ref={innerRef} >
        <div className="contact-item__img-wrap">
          <LazyLoadImage
            src={getContactAvatar(data)}
            alt="User Avatar"
            className="contact-item__img" />
        </div>

        <div className="contact-item__info">
          <h4 className="contact-item__name">{data.fn}</h4>
          <div className="contact-item__number">{data.tel}</div>
        </div>
      </div>
    )
    : null
);

export default DialpadSearchItem;