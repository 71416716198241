import React, { useState } from 'react';

import { classModifier } from '../../utils';

import Spinner from '../UI/Spinner/Spinner';
import VideoPlayer from '../../components/VideoPlayer/VideoPlayer';

import './ModalVideo.scss';

const ModalVideo = (props) => {
  const {
    src,
    spinnerSize = '200px',
  } = props;

  const [pending, setPending] = useState(true);

  return (
    <div className="modal-video">
      {pending && 
        <Spinner
          className="modal-video__spinner"
          spinnerSize={spinnerSize}
        />
      }

      <div className={classModifier('modal-video__player-wrap', pending && 'pending')}>
        <VideoPlayer
          src={src}
          videoId={src}
          onPlay={props.playMedia}
          onInitVideo={() => setPending(false)}
        />
      </div>
    </div>
  )
}


export default ModalVideo;
