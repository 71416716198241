import React, { useEffect, useState, useCallback, useLayoutEffect, useRef } from 'react';
import ReactCalendar from 'react-calendar';

import { useToggle, usePrevious } from '../../hooks';

import './Calendar.scss';
import TimeSelector from "../TimeSelector/TimeSelector";


const Calendar = (props) => {
  const {
    date,
    isShowDatesMenu,
    isShowTimeMenu,
    timeIntervals = 15,
    maxDate,
    minDate,
    autoposition,
  } = props;

  const [activeMenuItem, setActiveMenuItem] = useState(null);
  const [selectedTime, setSelectedTime] = useState('00:00');
  const [calendarStyle, setCalendarStyle] = useState(null);
  const [isActiveCustomRange, toggleActiveCustomRange] = useToggle(false);

  const calendarRef = useRef(null);

  const prevActiveMenuItem = usePrevious(activeMenuItem);

  useLayoutEffect(() => {
    if (!autoposition) return;

    const { top: ownTop, height: ownHeight } =
      calendarRef.current.getBoundingClientRect();

    // Top initial (position relatively viewport)
    let top = ownTop;
    // Move higher if does not fit at the bottom
    const bottom = top + ownHeight;
    const bottomOffset = bottom - (document.documentElement.clientHeight - 5);
    top = bottomOffset > 0 ? top - bottomOffset : top;
    // Leave at very top if does not fit on high
    top = top < 5 ? 5 : top;

    // Move if new position different from current
    if (top !== ownTop) {
      setCalendarStyle({ transform: `translateY(${top - ownTop}px)` });
    }
  }, []);

  useEffect(() => { // Setting active menu item
    if (isActiveCustomRange) {
      setActiveMenuItem('Custom Range');
    }
    else if (!date) {
      setActiveMenuItem('All Time')
    }
    else if (date === today) {
      setActiveMenuItem('Today');
    }
    else if (date === yesterday) {
      setActiveMenuItem('Yesterday');
    }
    else if (JSON.stringify(getMonthRange(true)) === JSON.stringify(date)) {
      setActiveMenuItem('This Month');
    }
    else if (JSON.stringify(getMonthRange()) === JSON.stringify(date)) {
      setActiveMenuItem('Last Month');
    }
    else if (Array.isArray(date)) {
      setActiveMenuItem('Custom Range');
      toggleActiveCustomRange(true);
    }
    else {
      setActiveMenuItem('Custom Date');
    }
  }, [date, isActiveCustomRange]);

  const getDateWithTime = useCallback((date, time) => {
    if (!isShowTimeMenu) {
      return date;
    }
    else if (!date) {
      return null
    }

    const newDate = new Date(date);
    const [hours, minutes] = time.split(':');

    newDate.setHours(hours)
    newDate.setMinutes(minutes);
    newDate.setSeconds(0);

    return +newDate;
  }, []);

  const getDateForView = useCallback(() => {
    const isTogglingToCustomRange = activeMenuItem === 'Custom Range' && (prevActiveMenuItem !== activeMenuItem && prevActiveMenuItem !== null);

    if (!date || isTogglingToCustomRange) {
      return undefined;
    }

    return Array.isArray(date)
      ? [new Date(date[0]), new Date(date[1])]
      : new Date(date);
  }, [date, activeMenuItem]);

  const startFilteringManually = (date) => {
    if (Array.isArray(date)) {
      const start = +date[0];
      const finish = +date[1];

      props.onChange([start, finish]);
    }
    else {
      if (isActiveCustomRange) {
        toggleActiveCustomRange()
      }

      props.onChange(getDateWithTime(+date, selectedTime));
    }
  };

  const getMonthRange = (isCurrentMonth) => { // Current Month or  Last Month
    let year = new Date().getFullYear();
    let month = new Date().getMonth();

    if (!isCurrentMonth && month === 0) {
      year -= 1;
      month += 11;
    }
    else if (!isCurrentMonth) {
      month -= 1;
    }

    const lastDayOfMonth = new Date(year, month + 1, 0).getDate();

    const firstDay = +new Date(year, month, 1);
    const lastDay = +new Date(year, month, lastDayOfMonth);

    return [firstDay, lastDay];
  }

  const selectMenuItem = (name) => {
    if (activeMenuItem === 'Custom Range' && name !== activeMenuItem) {
      toggleActiveCustomRange(false);
    }

    switch (name) {
      case 'All Time':
        props.onChange(null);
        break;
      case 'Today':
        props.onChange(today);
        break;
      case 'Yesterday':
        props.onChange(yesterday);
        break;
      case 'This Month':
        props.onChange(getMonthRange(true));
        break;
      case 'Last Month':
        props.onChange(getMonthRange());
        break;
      case 'Custom Range':
        toggleActiveCustomRange();
        break;
    }
  }

  const getMinTime = () => {
    if (!minDate) return;

    const d = new Date(minDate);
    const isCurrentDay = new Date(date).getDate() === d.getDate();

    if (!isCurrentDay) return;

    const hours = String(d.getHours()).length === 1
      ? '0' + d.getHours()
      : d.getHours();
    const mins = String(d.getMinutes()).length === 1
      ? '0' + d.getMinutes()
      : d.getMinutes();

    return hours + ':' + mins;
  }

  const selectTime = (time) => {
    setSelectedTime(time);
    props.onChange(getDateWithTime(date, time));
  }

  const getClassName = (name) => activeMenuItem === name
    ? 'calendar-menu__item calendar-menu__item--active'
    : 'calendar-menu__item';

  const today = +new Date();
  const yesterday = today - 86400000;
  const menuItems = ['All Time', 'Today', 'Yesterday', 'This Month', 'Last Month', 'Custom Range'];

  return (
    <div className='calendar' ref={calendarRef} style={calendarStyle}>
      <div className="calendar__wrap">
        <ReactCalendar
          onChange={startFilteringManually}
          value={getDateForView()}
          selectRange={isActiveCustomRange}
          maxDate={maxDate ? new Date(maxDate) : maxDate}
          minDate={minDate ? new Date(minDate) : minDate}
        />

        {props.isShowActions &&
          <div className="calendar__actions">
            <button
              className="calendar__cancel"
              onClick={props.onClose}
            >
              Cancel
            </button>

            <button
              className="calendar__submit"
              onClick={props.onSubmit}
            >
              Set
            </button>
          </div>
        }
      </div>

      {isShowDatesMenu &&
        <ul className="calendar-menu">
          {menuItems.map(item =>
            <li
              key={item}
              onClick={() => selectMenuItem(item)}
              className={getClassName(item)}
            >
              {item}
            </li>
          )}
        </ul>
      }

      {isShowTimeMenu &&
        <TimeSelector
          selectClassPrefix='calendar-time'
          onChange={selectTime}
          defaultValue={selectedTime}
          timeIntervals={timeIntervals}
          isAlwaysOpen
          minTime={getMinTime()}
          setSelectedTime={setSelectedTime}
        />
      }
    </div>
  );
};

export default Calendar;