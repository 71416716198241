import { PARTICIPANT_USER, PARTICIPANT_CALLER } from "../ducks/conferences";

export default (conf) => ({
  ...conf,
  ...normalizeParticipants(conf.participants)
})

export const normalizeParticipants = (participants) => {
  const participantEntities = {};

  let data = participants;

  if (!Array.isArray(participants)) {
    data = [participants];
  }

  const participantIds = data.map(participant => {
    if (participant.type === PARTICIPANT_USER) {
      participantEntities[participant.user_id + '_' + PARTICIPANT_USER] = participant;

      return participant.user_id + '_' + PARTICIPANT_USER;
    }
    else {
      participantEntities[participant.caller_id + '_' + PARTICIPANT_CALLER] = participant;

      return participant.caller_id + '_' + PARTICIPANT_CALLER;
    }
  });

  return {
    participantEntities,
    participantIds
  }
}