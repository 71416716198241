import React from 'react';
import { connect } from 'react-redux';

import { playMedia } from '../../../../ducks/activeWindows';

import AudioPlayer from '../../../../components/AudioPlayer/AudioPlayer';


const AttachmentVoiceMessage = ({ voiceMsg, className = '', ...props }) => {
  return (
    <div className={`${className} ${!props.showTitle ? "attachment-voice-msg" : ""}`}>

      {props.showTitle &&
        <h4 className="attachments__title">Voice Message</h4>
      }

      <AudioPlayer
        src={voiceMsg.url}
        blobDuration={voiceMsg.duration}
        mimeType={voiceMsg.blob ? voiceMsg.blob.type : null}
        onPlay={props.playMedia}
        hideVolume
      />
    </div>
  );
}

const mapDispatchToProps = {
  playMedia
};

export default connect(null, mapDispatchToProps)(AttachmentVoiceMessage);