import React, { useState, useCallback, useRef } from 'react';
import { connect } from 'react-redux';

import API from '../../../../api/api';
import { useDidMount } from '../../../../hooks';
import { LIMIT, updateActiveContact } from '../../../../ducks/clientChats';
import { addNewArrayGirlsToState } from '../../../../ducks/contacts';
import { getDateByTimezoneOffset } from '../../../../utils';
import { selectUserTimezone } from '../../../../selectors/selectors';

import './BookingsListModal.scss';
import BookingsListItem from '../../../Bookings/components/BookingsListItem';
import List from '../../../../components/List/List';
import ParsedGirlList from '../../../Chat/components/ParsedGirlList/ParsedGirlList';


let todayDateRange;
const getTodayDateRange = (userTimezone) => {
  const from = getDateByTimezoneOffset(userTimezone).setHours(3, 0, 0, 0) -
    (userTimezone - (new Date().getTimezoneOffset() * (-1))) * 60000;
  const to = from + 86400000 - 1;

  todayDateRange = [from, to];
}


const BookingsListModal = (props) => {
  const {
    contactId,
    girlDivaId,
    userTimezone,
  } = props;

  const [bookings, setBookings] = useState([]);
  const [isPending, setPending] = useState(true);
  const [parsedGirlsList, setParsedGirlsList] = useState({
    query: '',
    list: [],
    isOpen: false,
  });

  const msgRef = useRef();

  useDidMount(() => {
    getTodayDateRange(userTimezone);

    const request = contactId
      ? API.getContactUnfinishedBookings
      : API.getGirlBookings;
    const params = contactId
      ? [contactId, 0]
      : [girlDivaId, 0, todayDateRange];

    request(...params)
      .then(res => {
        setBookings(res.data);
      })
      .catch(console.log)
      .finally(() => setPending(false));
  });

  const loadMore = useCallback((offset) => {
    const request = contactId
      ? API.getContactUnfinishedBookings
      : API.getGirlBookings;
    const params = contactId
      ? [contactId, offset]
      : [girlDivaId, offset, todayDateRange];

    request(...params)
      .then(res => {
        setBookings(prevBookings => [...prevBookings, ...res.data]);
      })
      .catch(console.log);
  }, []);

  const listItem = useCallback((props) => {
    return <BookingsListItem
      booking={props.item}
      onClose={closeUnfinishedBookings}
      updateBookings={onUpdateBooking}
      onBookingDeleted={onBookingDeleted}
    />
  }, []);

  const closeUnfinishedBookings = useCallback(() => props.closeModal(), []);

  const onUpdateBooking = (newBooking) => setBookings(prevBookings => {
    const index = prevBookings.findIndex(booking => booking.id === newBooking.id);
    const updateBookings = [...prevBookings];

    updateBookings[index] = newBooking;

    return updateBookings;
  });

  const onBookingDeleted = (bookingId) => {
    setBookings(prevBookings => prevBookings.filter(booking => booking.id !== bookingId));
  };

  return (
    <div className='bookings-list-modal'>
      <div className='bookings-list-modal__parsed-list' ref={msgRef}>
        {parsedGirlsList.isOpen && 
          <ParsedGirlList 
            msgRef={msgRef}
            activeRecipient={props.activeRecipient}
            addNewArrayGirlsToState={props.addNewArrayGirlsToState}
            parsedGirlsList={parsedGirlsList}
            updateActiveContact={props.updateActiveContact}
            setParsedGirlsList={setParsedGirlsList}
            isParsedGirlsListModal
          />
        }
      </div>
      <List
        list={bookings}
        limit={LIMIT}
        classPrefix='bookings-list-modal'
        scrollInitialPosition='top'
        loadMore={loadMore}
        listItem={listItem}
        listLoadPending={isPending}
      />

      <button 
        onClick={() => setParsedGirlsList({ ...parsedGirlsList, isOpen: true })} 
        className="bookings-list-modal__btn">
          Add booking
      </button>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userTimezone: selectUserTimezone(state),
  activeRecipient: state.contacts.entities[state.clientChats.active],
});

const mapDispatchToProps = {
  addNewArrayGirlsToState,
  updateActiveContact
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingsListModal);