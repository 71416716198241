import React from 'react';
import { connect } from 'react-redux';

import './ConferenceParticipant.scss';
import LazyLoadImage from '../LazyLoadImage/LazyLoadImage';
import { getContactAvatar, classModifier } from '../../utils';
import { selectParticipantById } from '../../selectors/selectors';



const ConferenceParticipant = (props) => {
  console.log('CONFERENCE paritcipnat render', props);

  const updateOperatorIds = () => {
    props.setOperatorIds(prevState => {
      if (prevState.includes(props.item)) {
        const newOperatorIds = prevState.filter(operatorId => operatorId !== props.item);

        return [...newOperatorIds];
      }
      else {
        return [
          ...prevState,
          props.item,
        ];
      }
    });
  };

  const getParticipantStatusColor = () => {
    switch (props.participant.status) {
      case "busy":
        return "#F8737F";
      case "online":
        return "#01DF85";
      case "away":
        return "orange";
      default:
        return "grey";
    }
  }

  if (props.smallSize) {
    return (
      <li className={classModifier("conference-participant", [
        props.smallSize && "small",
        props.participant.status === "online" && "small-online",
      ])}>
        {props.participant.username || "You "}
      </li>
    )
  }

  return (
    <li
      onClick={() => props.isCheckable
        ? updateOperatorIds()
        : null
      }
      className={classModifier("conference-participant", [
        props.participant.status && props.participant.status,
        props.isCreator && 'host',
        props.isCheckable && 'checkable'
      ])}>

      <span
        style={{ backgroundColor: getParticipantStatusColor() }}
        className="conference-participant__status">
      </span>

      <div
        className="conference-participant__img-wrap">
        <LazyLoadImage src={getContactAvatar(props.participant)} alt="ava" className="conference-participant__img" />
      </div>

      <span className="conference-participant__name">{props.participant.username || "You "}</span>


      {props.isCreator &&
        <div className="conference-participant__creator">Host</div>
      }

      {props.participantStatus &&
        <span className="conference-participant__conference-status"> {props.participantStatus}</span>
      }

      {props.isCheckable &&
        <input
          className="conference-participant__checkbox"
          type="checkbox"
          checked={props.isChecked}
          onChange={() => { }} />
      }

    </li>
  )
}

const mapStateToProps = (state, { participantId, conferenceId, item, operatorIds, isCheckable }) => ({
  participantStatus: !isCheckable
    ? state.conferences.participants[conferenceId].entities[participantId].status
    : null,
  participant: !isCheckable
    ? selectParticipantById(state, participantId)
    : state.operators.entities[item],
  operatorIds: null,
  isChecked: isCheckable ? operatorIds.includes(item) : null,
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(ConferenceParticipant));