import React, { memo } from 'react';

import { parseMsgText } from '../../../../../utils';
import { CHAT_TYPES } from '../../../../../config/constants';

import AudioPlayer from '../../../../../components/AudioPlayer/AudioPlayer';

const PinnedMsg = ({ interaction, ...props }) => {
  const unpinMsg = (e) => {
    if (e.target.hasAttribute('data-close')) {
      return props.unpinMsg(props.chatId, props.type);
    }
  };

  const getContext = (e) => {
    if (interaction.attachments && interaction.attachments.voiceMsg && interaction.attachments.voiceMsg.url || e.target.hasAttribute('data-close')) {
      return;
    }

    props.type === CHAT_TYPES.ROOM
      ? props.getMessageContext(interaction.id, props.chatId)
      : props.getMessageContext(interaction.id, null)
  };

  const setName = () => {
    if (interaction.chatId) {
      return interaction.sender && interaction.sender.username;
    }

    if ([2, 4].includes(interaction.type)) {
      return interaction.caller && interaction.caller.fn;
    }

    return interaction.user && interaction.user.username;
  };

  return (
    <div
      className="timeline__pinned"
      onClick={getContext}>
      <div className="timeline__pinned-wrapper">
        <span className="timeline__pinned-name">
          {props.profileId === interaction.senderId || props.profileId === interaction.user_id
            ? 'You'
            : setName()
          }
        </span>

        <span className="timeline__pinned-text">
          {interaction.attachments && interaction.attachments.voiceMsg && interaction.attachments.voiceMsg.url
            ? <AudioPlayer
              onPlay={(audio) => props.playMedia(audio)}
              hideVolume
              blobDuration={interaction.attachments.voiceMsg.duration}
              // src={interaction.attachments.voiceMsg.url}
              blob={interaction.attachments.voiceMsg.url} />
            : parseMsgText(interaction.body, true)
          }
        </span>

        <span className="timeline__pinned-unpin" onClick={unpinMsg} data-close />
      </div>
    </div>
  );
};

export default memo(PinnedMsg);
