import React, { Component } from 'react';
import { connect } from 'react-redux';

import LS from '../../utils/localStorageAPI';
import {
  getContactTimeline,
  updateContactTimeline,
  updateActiveContact,
  sendMessage,
  removeContactTab,
  removeContactTabsFromContextMenu,
  markContactChatAsRead,
  getTimelineMedia,
  cleanTimelineMedia,
  updateContactTimelineMedia,
  getContactMessageContext,
  updateContactMessageContext,
  unpinClientMsg,
  pinClientMsg,
  fixClientTab,
  getContactReminders,
  cleanContactReminders,
  updateContactReminders,
  removeMessageReminder,
  updateContactMessageSearch,
  stopContactMessageSearch,
  searchContactMessage,
  searchGlobalContactMessage,
  updateGlobalContactMessageSearch,
  getContactDateMsgContext,
  updateContactDateMsgContext,
  cleanContactDateMsgContext,
  getScheduledMsgs,
  cleanScheduledMsgs,
  updateScheduledMsgs,
  createContactChat,
  getScheduledMsgsCount
} from '../../ducks/clientChats';
import {
  playMedia,
  openModal,
  MODAL_TYPES,
} from '../../ducks/activeWindows';
import { getContactsById, addNewArrayGirlsToState, updateContactInState } from '../../ducks/contacts';
import { getAllTemplates, getMsgShortcuts } from '../../ducks/msgTemplates';
import API from '../../api/api';
import { CHAT_SOURCES, CHAT_TYPES } from '../../config/constants';
import { getDateByTimezoneOffset } from '../../utils';
import { selectUserTimezone } from '../../selectors/selectors';

import SvgIcon from '../../components/SvgIcon/SvgIcon';
import ChatCreatorButton from './components/ChatCreatorForm/ChatCreatorButton/ChatCreatorButton';
import Title from '../../components/UI/Title/Title';
import ChatRestore from './ChatRestore';
import Chat from './Chat';


class ClientChat extends Component {
  state = {
    activeChatSource: CHAT_SOURCES.MSGS,
    repliedMsg: null,
    editedMsg: null,
    voiceMsg: null,
    images: null,
    videos: null,
  }

  componentDidMount() {
    if (this.props.active && !this.props.timeline.length) {
      console.log(9898989898);

      this.props.getContactTimeline(this.props.active, CHAT_TYPES.CLIENT, this.props.userTimezone, false);
      this.props.getScheduledMsgsCount(this.props.active, CHAT_TYPES.CLIENT);
    }
  }

  componentWillUnmount() {
    if (this.state.images) {
      this.cleanImages();
    }
    if (this.state.videos) {
      this.cleanVideos();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { activeChatSource } = this.state;

    if (prevProps.active !== this.props.active) {
      if (this.props.active !== null) {
        const withCancel = !!prevProps.active;

        this.props.getScheduledMsgsCount(this.props.active, CHAT_TYPES.CLIENT, withCancel);

        if (this.props.clientSearchSource === 'msgs' && this.props.clientSearch) {
          this.props.getContactTimeline(this.props.active, CHAT_TYPES.CLIENT, this.props.userTimezone, withCancel, this.props.clientSearch);
        }
        else {
          this.props.getContactTimeline(this.props.active, CHAT_TYPES.CLIENT, this.props.userTimezone, withCancel);
        }
      }
      if (this.state.editedMsg) {
        this.cleanEditedMsg();
      }
      if (this.state.voiceMsg) {
        this.updateVoiceMsg();
      }
      if (this.state.images) {
        this.cleanImages();
      }
      if (this.state.videos) {
        this.cleanVideos();
      }
      LS.setItem('aClient', this.props.active, this.props.userId);
    }
    if (prevProps.tabs !== this.props.tabs) {
      LS.setItem('cTabs', this.props.tabs, this.props.userId);
    }
    if (prevProps.unfixedTab !== this.props.unfixedTab) {
      LS.setItem('unfixedClientTab', this.props.unfixedTab, this.props.userId);
    }

    if (activeChatSource !== prevState.activeChatSource) {
      if (activeChatSource === CHAT_SOURCES.REMINDERS) {
        this.props.getContactReminders(this.props.activeRecipient, this.props.userTimezone);
      }
      else if (activeChatSource === CHAT_SOURCES.MEDIA) {
        this.props.getTimelineMedia(this.props.activeRecipient, this.props.userTimezone);
      }
      else if (activeChatSource === CHAT_SOURCES.SCHEDULED_MSGS) {
        this.props.getScheduledMsgs(this.props.activeRecipient, this.props.userTimezone);
      }
      if (prevState.activeChatSource === CHAT_SOURCES.REMINDERS) {
        this.props.cleanContactReminders(CHAT_TYPES.CLIENT);
      }
      else if (prevState.activeChatSource === CHAT_SOURCES.MEDIA) {
        this.props.cleanTimelineMedia(CHAT_TYPES.CLIENT);
      }
      else if (prevState.activeChatSource === CHAT_SOURCES.SCHEDULED_MSGS) {
        this.props.cleanScheduledMsgs(CHAT_TYPES.CLIENT);
      }
    }
  }

  setActiveChatSource = (chatSource) => {
    if (chatSource !== this.state.activeChatSource) {
      this.setState({ activeChatSource: chatSource });
    }
  }

  replyMsg = (msg) =>  this.setState({ repliedMsg: msg });
  cleanRepliedMsg = () => this.setState({ repliedMsg: null });

  editMsg = (msg) => this.setState({ editedMsg: msg });
  cleanEditedMsg = () => this.setState({ editedMsg: null });

  updateVoiceMsg = (blob, duration) => {
    if (blob && duration) {                 // add voiceMsg
      this.setState({
        voiceMsg: {
          blob,
          url: URL.createObjectURL(blob),
          duration
        }
      });
    }
    else if (this.state.voiceMsg) {         // clean voiceMsg
      this.setState({ voiceMsg: null });
    }
  }

  updateImages = (images) => {
    const imagesWithUrl = images.map(file => Object.assign(file, {
      url: URL.createObjectURL(file)
    }));

    this.setState({ images: imagesWithUrl });
  }
  cleanImages = () => {
    this.state.images.forEach(file => URL.revokeObjectURL(file.url));
    this.setState({ images: null });
  }

  updateVideos = (videos) => {
    const videosWithUrl = videos.map(file => Object.assign(file, {
      url: URL.createObjectURL(file)
    }));

    this.setState({ videos: videosWithUrl });
  }
  cleanVideos = () => {
    this.state.videos.forEach(file => URL.revokeObjectURL(file.url));
    this.setState({ videos: null });
  }

  timeline = () => {
    if (this.props.contextMsgId) {
      return this.props.auxiliaryTimeline;
    }
    else if (this.props.contextDate) {
      return this.props.auxiliaryTimeline;
    }
    else if (this.props.search) {
      return this.props.auxiliaryTimeline;
    }
    else if (this.state.activeChatSource !== CHAT_SOURCES.MSGS) {
      return this.props.auxiliaryTimeline;
    }
    return this.props.timeline;
  }

  updateTimeline = () => {
    if (this.props.contextMsgId) {
      return this.updateMsgContext;
    }
    else if (this.props.contextDate) {
      return this.updateDateMsgContext;
    }
    else if (this.props.search && this.props.isGlobalSearch) {
      return this.updateGlobalContactMessageSearch;
    }
    else if (this.props.search) {
      return this.updateMsgSearch;
    }
    else if (this.state.activeChatSource === CHAT_SOURCES.MEDIA) {
      return this.updateContactTimelineMedia;
    }
    else if (this.state.activeChatSource === CHAT_SOURCES.REMINDERS) {
      return this.updateContactReminders;
    }
    else if (this.state.activeChatSource === CHAT_SOURCES.SCHEDULED_MSGS) {
      return this.updateScheduledMsgs;
    }
    return this.updateContactTimeline;
  }

  getContactMsgContext = (msgId, contact, searchQuery) =>
    this.props.getContactMessageContext(msgId, CHAT_TYPES.CLIENT, contact, searchQuery, this.props.userTimezone);

  updateMsgContext = (activeRecipient, page, loadDirection) =>
    this.props.updateContactMessageContext(this.props.contextMsgId, page, loadDirection, activeRecipient, this.props.userTimezone);

  getContactDateMsgContext = (date) =>
    this.props.getContactDateMsgContext(date, this.props.activeRecipient.id, CHAT_TYPES.CLIENT, this.props.userTimezone);

  updateDateMsgContext = (activeRecipient, page, loadDirection) =>
    this.props.updateContactDateMsgContext(activeRecipient, page, loadDirection, this.props.contextDate, this.props.userTimezone);

  startMessageSearch = (query) =>
    this.props.searchContactMessage(query, this.props.activeRecipient, this.props.userTimezone);

  updateMsgSearch = (activeRecipient, page, loadDirection) =>
    this.props.updateContactMessageSearch(activeRecipient, page, loadDirection, this.props.search, this.props.userTimezone);

  stopMessageSearch = () => this.props.stopContactMessageSearch(this.props.activeRecipient);

  updateContactTimeline = (activeRecipient, page, loadDirection) =>
    this.props.updateContactTimeline(activeRecipient, page, loadDirection, this.props.userTimezone);

  updateGlobalContactMessageSearch = (activeRecipient, page, loadDirection) =>
    this.props.updateGlobalContactMessageSearch(activeRecipient, page, this.props.search, loadDirection, this.props.userTimezone);

  updateContactTimelineMedia = (activeRecipient, page, loadDirection) =>
    this.props.updateContactTimelineMedia(activeRecipient, page, loadDirection, this.props.userTimezone);

  updateContactReminders = (activeRecipient, page, loadDirection) =>
    this.props.updateContactReminders(activeRecipient, page, loadDirection, this.props.userTimezone);

  updateScheduledMsgs = (activeRecipient, page, loadDirection) =>
    this.props.updateScheduledMsgs(activeRecipient, page, loadDirection, this.props.userTimezone);

  showTimePicker = async () => this.props.openModal(
    MODAL_TYPES.timePicker,
    {
      action: this.getContactDateMsgContext,
      maxDate: getDateByTimezoneOffset(this.props.userTimezone),
      userTimezone: this.props.userTimezone
    }
  );

  changeRecipientAudioStatus = (action, recipientId) =>
    API.setContactAudioStatus(action, recipientId)
      .then(({ data }) => {
        this.props.updateContactInState(data.caller, data.caller.type);
      })
      .catch(console.error);


  render() {
    const isMainTimelineOpen = this.props.search ||
      this.state.activeChatSource !== CHAT_SOURCES.MSGS ||
      this.props.contextMsgId ||
      this.props.contextDate
      ? false
      : true;

    const timelineLowerLoadedPage = !isMainTimelineOpen
      ? this.props.auxiliaryLowerLoadedPage
      : this.props.timelineLowerLoadedPage;

    const timelinePageCount = !isMainTimelineOpen
      ? this.props.auxiliaryPageCount
      : this.props.timelinePageCount;

    const timelineHigherLoadedPage = !isMainTimelineOpen
      ? this.props.auxiliaryHigherLoadedPage
      : this.props.timelineCurrentPage;

    const timelineCurrentPage = !isMainTimelineOpen
      ? this.props.auxiliaryCurrentPage
      : this.props.timelineCurrentPage;

    return this.props.activeRecipient
      ? (
        <Chat
          clientChat
          title={this.props.title}
          type={CHAT_TYPES.CLIENT}
          activeRecipient={this.props.activeRecipient}
          updateActiveContact={this.props.updateActiveContact}
          profileId={this.props.userId}
          userTimezone={this.props.userTimezone}
          userHour12={this.props.userHour12}

          tabs={this.props.tabs}
          isTabsDraggable
          withUnfixedTab
          unfixedTab={this.props.unfixedTab}
          fixTab={this.props.fixClientTab}
          removeContactTab={this.props.removeContactTab}
          removeContactTabsFromContextMenu={this.props.removeContactTabsFromContextMenu}

          timelinePending={this.props.timelinePending}
          updatePending={this.props.updatePending}
          isMainTimelineOpen={isMainTimelineOpen}
          timeline={this.timeline()}
          timelineCurrentPage={timelineCurrentPage}
          timelinePageCount={timelinePageCount}
          timelineHigherLoadedPage={timelineHigherLoadedPage}
          timelineLowerLoadedPage={timelineLowerLoadedPage}
          newInteractionType={this.props.newInteractionType}
          updateContactTimeline={this.updateTimeline()}

          msgTemplates={this.props.msgTemplates}
          shortcuts={this.props.shortcuts}
          typingStatus={this.props.typingStatus}

          search={this.props.search}
          startMessageSearch={this.startMessageSearch}
          stopMessageSearch={this.stopMessageSearch}
          isGlobalSearch={this.props.isGlobalSearch}
          startGlobalMsgSearch={this.props.searchGlobalContactMessage}
          showSearchQuery={this.props.showSearchQuery}

          contextMsgId={this.props.contextMsgId}
          getMessageContext={this.getContactMsgContext}

          contextDate={this.props.contextDate}
          cleanContactDateMsgContext={this.props.cleanContactDateMsgContext}
          showTimePickerForDateContext={this.state.activeChatSource !== CHAT_SOURCES.MSGS ? null : this.showTimePicker}

          pinnedMsg={this.props.pinnedMsg}
          unpinMsg={this.props.unpinClientMsg}
          pinMsg={this.props.pinClientMsg}

          isFullMode={this.props.currentCallerId}

          activeChatSource={this.state.activeChatSource}
          setActiveChatSource={this.setActiveChatSource}

          removeMessageReminder={this.props.removeMessageReminder}

          sendMessage={this.props.sendMessage}
          openModal={this.props.openModal}
          playMedia={this.props.playMedia}
          markChatAsRead={this.props.markChatAsRead}
          changeRecipientAudioStatus={this.changeRecipientAudioStatus}
          callFromChat
          fullScreenMode={this.props.fullScreenMode}
          showSalesButton
          isCreateChatMode

          replyMsg={this.replyMsg}
          repliedMsg={this.state.repliedMsg}
          cleanRepliedMsg={this.cleanRepliedMsg}

          editMsg={this.editMsg}
          editedMsg={this.state.editedMsg}
          cleanEditedMsg={this.cleanEditedMsg}

          voiceMsg={this.state.voiceMsg}
          updateVoiceMsg={this.updateVoiceMsg}

          images={this.state.images}
          updateImages={this.updateImages}
          cleanImages={this.cleanImages}

          videos={this.state.videos}
          updateVideos={this.updateVideos}
          cleanVideos={this.cleanVideos}

          addNewArrayGirlsToState={this.props.addNewArrayGirlsToState}

          createContactChat={this.props.createContactChat}
          isMsgInput={this.props.activeRecipient.id === "new_chat" ? false : true}
          isShowUndoTabs={true}
          isScheduledMsgsSource={this.state.activeChatSource === CHAT_SOURCES.SCHEDULED_MSGS}
          scheduledMsgsCount={this.props.scheduledMsgsCount}

          searchSource={this.props.clientSearchSource}
        />
      )
      : <div className="chat">
        {this.props.title &&
          <div className="chat__title-wrap">
            <Title
              classPrefix="chat-title"
              icon="messages"
              text={this.props.title}
              count={this.props.tabs.length}
            />

            {this.props.active !== 'new_chat' &&
              <ChatCreatorButton
                onClick={this.props.createContactChat}
              >
                <SvgIcon
                  icon="plus"
                  width="18"
                  className="chat-creator-button__img"
                  title="create new chat" />
              </ChatCreatorButton>
            }
          </div>
        }

        <ChatRestore
          recentTabs={this.props.recentTabs}
          type={CHAT_TYPES.CLIENT}
        />
      </div>;
  }
};

const mapStateToProps = (state, ownProps) => ({
  active: state.clientChats.active,
  activeRecipient: state.contacts.entities[state.clientChats.active || ownProps.currentCallerId],
  tabs: state.clientChats.tabs,
  unfixedTab: state.clientChats.unfixedTab,
  recentTabs: state.clientChats.recentTabs,

  timeline: state.clientChats.timeline,
  timelinePending: state.clientChats.timelinePending,
  updatePending: state.clientChats.updatePending,
  timelineCurrentPage: state.clientChats.timelineCurrentPage,
  timelinePageCount: state.clientChats.timelinePageCount,
  timelineLowerLoadedPage: state.clientChats.timelineLowerLoadedPage,
  newInteractionType: state.clientChats.newInteractionType,

  auxiliaryTimeline: state.clientChats.auxiliaryTimeline,
  auxiliaryLowerLoadedPage: state.clientChats.auxiliaryLowerLoadedPage,
  auxiliaryPageCount: state.clientChats.auxiliaryPageCount,
  auxiliaryHigherLoadedPage: state.clientChats.auxiliaryHigherLoadedPage,
  auxiliaryCurrentPage: state.clientChats.auxiliaryCurrentPage,

  msgTemplates: state.msgTemplates.clientsTemplates,
  shortcuts: state.msgTemplates.shortcuts,

  search: state.clientChats.search,

  contextMsgId: state.clientChats.contextMsgId,
  contextDate: state.clientChats.contextDate,

  isGlobalSearch: state.clientChats.isGlobalMsgSearch,
  showSearchQuery: state.clientChats.showSearchQuery,

  userId: state.user.id,
  userTimezone: selectUserTimezone(state),
  userHour12: state.user.hour12,

  pinnedMsg: state.clientChats.pinnedMsg,

  typingStatus: state.typingOperators.typingOperators[state.clientChats.active + '_' + CHAT_TYPES.CLIENT],

  scheduledMsgsCount: state.clientChats.scheduledMsgsCount,

  clientSearchSource: state.contacts.clients.searchSource,
  clientSearch: state.contacts.clients.search,
});

const mapDispatchToProps = {
  updateContactInState,
  getContactTimeline,
  updateContactTimeline,
  updateContactMessageSearch,
  updateActiveContact,
  sendMessage,
  removeContactTab,
  removeContactTabsFromContextMenu,
  openModal,
  getContactMessageContext,
  getAllTemplates,
  getMsgShortcuts,
  markChatAsRead: markContactChatAsRead,
  getContactsById,
  getTimelineMedia,
  getContactReminders,
  cleanContactReminders,
  cleanTimelineMedia,
  updateContactTimelineMedia,
  updateContactReminders,

  searchGlobalContactMessage,
  updateGlobalContactMessageSearch,

  stopContactMessageSearch,
  searchContactMessage,

  createContactChat,

  pinClientMsg,
  unpinClientMsg,
  playMedia,
  fixClientTab,

  addNewArrayGirlsToState,
  removeMessageReminder,
  updateContactMessageContext,

  getContactDateMsgContext,
  updateContactDateMsgContext,
  cleanContactDateMsgContext,

  getScheduledMsgs,
  updateScheduledMsgs,
  cleanScheduledMsgs,
  getScheduledMsgsCount,
};
export default connect(mapStateToProps, mapDispatchToProps)(ClientChat);