import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbtack, faQuoteRight, faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';

import { pinMsg } from '../../../../../ducks/roomChats';

import API from '../../../../../api/api';

import InteractionMenu from './InteractionMenu';
import { parseMsgText } from '../../../../../utils';

const RoomInteractionMenu = (props) => {
  const {
    interaction,
  } = props;

  const removeMsg = async () => {
    if (window.confirm('Remove msg: "' + parseMsgText(interaction.body, true).trim() + '" ?')) {
      return API.removeChatMsg(interaction.id);
    }
    // throw new Error('it s not problem)');
  };

  const editMsg = async () => {
    if (!interaction.body.trim().length) {
      return;
    }

    props.editMsg(interaction);
  };

  const items = useMemo(() => {

    const itemsArray = [
      {
        icon: <FontAwesomeIcon icon={faThumbtack} size="xs" color="#fff" />,
        tooltipOnHover: 'Pin message',
        action: () => props.pinMsg(interaction, interaction.chatId),
        tooltipAfterAction: 'Pinned'
      },
      {
        icon: <FontAwesomeIcon icon={faQuoteRight} size="xs" color="#fff" />,
        tooltipOnHover: 'Quote message',
        action: async () => props.shareMsg(interaction),
      }
    ];

    if (props.isMine) {
      itemsArray.push(
        {
          icon: <FontAwesomeIcon icon={faPencilAlt} size="xs" color="#fff" />,
          tooltipOnHover: 'Edit message',
          action: editMsg,
        },
        {
          icon: <FontAwesomeIcon icon={faTrashAlt} size="xs" color="#fff" />,
          tooltipOnHover: 'Remove message',
          action: removeMsg,
          // tooltipAfterAction: 'Success removed'
        },
      )
    }

    return itemsArray;
  }
    , [interaction]);

  // console.log('RoomInteractionMenu Render', items);
  return (
    <InteractionMenu
      isOpen={props.isOpen}
      menuItems={items}
      closeMenu={props.closeMenu}
    />
  );
}

const mapDispatchToProps = {
  pinMsg,
};

export default connect(null, mapDispatchToProps)(RoomInteractionMenu);