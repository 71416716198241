import React from 'react';

import Select from '../../../../components/Select/Select';


const ChatListFilter = (props) => {
  const onFilterChange = (filter) => {
    if (props.isQuery) {
      props.setQuery('');
    }
    filter.action();
  }

  return (
    <Select
      classPrefix="filter"
      options={props.filters}
      getOptionValue={option => option.name}
      defaultValue={props.defaultFilter}
      onInputChange={onFilterChange}
    />
  )
}

export default React.memo(ChatListFilter);