import React, { memo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

import { useToggle } from '../../../../hooks';


const MessageTemplates = (props) => {
  const [showTemplates, toggleTemplates] = useToggle(false);

  const renderTemplate = (template) => (
    <li
      key={template.id}
      className="template__item"
      onClick={() => handleTemplateClick(template.text)}>
      {template.text}
    </li>
  );

  const handleTemplateClick = (text) => {
    props.addBoilerplate(text);
    toggleTemplates();
  };

  return (
    <div className="chat__template template">
      {props.msgTemplates &&
        <div
          className="template__icon"
          onMouseEnter={() => toggleTemplates()}
          onMouseLeave={() => toggleTemplates()}>

          <FontAwesomeIcon icon={faPlusCircle} color="#bdbdbd" title="message templates" />

          {showTemplates &&
            <ul
              className="template__list">
              {props.msgTemplates.map(item => renderTemplate(item))}
            </ul>
          }
        </div>
      }
    </div>
  );
};

export default memo(MessageTemplates);