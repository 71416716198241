import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';

import SearchableList from '../SearchableList/SearchableList';
import { selectOperatorsIdsByFilter } from '../../selectors/selectors';
import AsyncButton from '../AsyncButton/AsyncButton';


const SearchByOperators = ({ isAsyncSubmit, ...props }) => {
  const {
    classPrefix,
    initialIds,
  } = props;

  const [operatorIds, setOperatorIds] = useState(initialIds || []);

  const loadMore = useCallback(console.log, []);

  const getIsSubmitBtnDisabled = () => {
    if (props.isShowAddMenu) {
      return operatorIds.length > 1 ? '' : 'disabled'
    } else if (props.isSubmitBtnDisabled) {
      return props.isSubmitBtnDisabled(operatorIds, setOperatorIds)
    }

    return !operatorIds.length;
  }

  const getOperatorsForAdd = () => {
    if (!props.isShowAddMenu) return operatorIds;
    let addedOperatorIds = [...operatorIds]
    for (let val of initialIds) {
      addedOperatorIds = addedOperatorIds.filter(id => id !== val);
    }
    return addedOperatorIds
  }

  console.log('SearchByOperators Render', props.query);

  return (
    <div className={classPrefix + "__wrap"}>

      {props.title &&
        <span className={classPrefix + "__title"}>
          {props.title}
        </span>
      }

      <SearchableList
        classPrefix={classPrefix}
        list={props.operators}
        listLimit={20}
        scrollInitialPosition='top'
        loadMore={loadMore}
        listItem={props.listItem}
        listItemProps={{
          operatorIds,
          setOperatorIds,
          ...props.listItemProps
        }}
        startSearch={props.setQuery}
        stopSearch={props.setQuery}
      />

      {(props.withSubmitBtn || props.withCancelBtn) &&
        <div className={classPrefix + "__btns"}>
          {props.withSubmitBtn &&
            (isAsyncSubmit
              ? <AsyncButton
                onClick={() => props.onSubmit(getOperatorsForAdd(), setOperatorIds)}
                disabled={getIsSubmitBtnDisabled()}
                className={classPrefix + "__btn--submit"}
              >
                {props.submitBtnText || 'Submit'}
              </AsyncButton>
              : <button
                className={classPrefix + "__btn--submit"}
                disabled={getIsSubmitBtnDisabled()}
                onClick={() => props.onSubmit(getOperatorsForAdd(), setOperatorIds)}>
                {props.submitBtnText || 'Submit'}
              </button>
            )}
          {props.withCancelBtn &&
            <button
              className={classPrefix + "__btn--cancel"}
              onClick={() => props.onCancel && props.onCancel(false)}>
              {props.cancelBtnText || 'Cancel'}
            </button>
          }
        </div>
      }
    </div >
  );
}

const mapStateToProps = (state, { initialIds, ignoredIds, query }) => ({
  operators: selectOperatorsIdsByFilter(state, initialIds, ignoredIds, query)
});

export default connect(mapStateToProps)(SearchByOperators);