import React, { useMemo } from 'react';
import { faCalendarDay, faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getDateByTimezoneOffset, parseMsgText } from '../../../../../utils';
import { MODAL_TYPES } from '../../../../../ducks/activeWindows';
import API from '../../../../../api/api';

import InteractionMenu from './InteractionMenu';

const ScheduledMsgInteractionMenu = ({ interaction, ...props }) => {
  const removeMsg = async () => {
    if (window.confirm('Remove msg: "' + parseMsgText(interaction.body, true).trim() + '" ?')) {
      return API.deleteScheduledMsg(interaction.id);
      // handle in redux by socket
    }
  };

  const editMsgText = async () => {
    if (!interaction.body.trim().length) {
      return;
    }
    props.editMsg(interaction);
    // handle in redux by socket
    console.log('edited Msg Txt')
  };

  const editMsgDate = async () => {
    props.openModal(
      MODAL_TYPES.timePicker,
      {
        action: (date) => API.updateScheduledMsg({ ...interaction, date }),
        isShowTimeMenu: true,
        minDate: +getDateByTimezoneOffset(props.userTimezone) + 900000,
        userTimezone: props.userTimezone,
        onClose: props.closeMenu
      }
    )
    // handle in redux by socket
  };

  const items = useMemo(() => {
    const menuItems = [
      {
        icon: <FontAwesomeIcon icon={faPencilAlt} size="xs" color="#fff" />,
        tooltipOnHover: 'Edit message text',
        action: editMsgText,
        // tooltipAfterAction: 'Edited'
      },
      {
        icon: <FontAwesomeIcon icon={faCalendarDay} size="xs" color="#fff" />,
        tooltipOnHover: 'Edit message date',
        action: editMsgDate,
        // tooltipAfterAction: 'Edited'
      },
      {
        icon: <FontAwesomeIcon icon={faTrashAlt} size="xs" color="#fff" />,
        tooltipOnHover: 'Remove message',
        action: removeMsg,
      },
    ]

    return menuItems;
  }, [interaction]);

  // console.log('ScheduledMsgInteractionMenu Render');

  return (
    <InteractionMenu
      isOpen={props.isOpen}
      menuItems={items}
      closeMenu={props.closeMenu}
    />
  );
}

export default ScheduledMsgInteractionMenu;