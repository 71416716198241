import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPuzzlePiece } from '@fortawesome/free-solid-svg-icons';
import { faBootstrap } from '@fortawesome/free-brands-svg-icons';

import './ParsedGirlItem.scss';
import { useToggle } from '../../../../../hooks';
import { updateActiveSession, createSession, updateBufferedId } from '../../../../../ducks/sessions';
import { getContactAvatar } from '../../../../../utils';
import { CONTACT_TYPES } from '../../../../../config/constants';
import { selectIsContactActive, selectSessionByContactId, selectUserTimezone } from '../../../../../selectors/selectors';
import { openModal, MODAL_TYPES } from '../../../../../ducks/activeWindows';
import API from '../../../../../api/api';

import DropWrapper from '../../../../../components/DropWrapper/DropWrapper';
import BookedEditor from '../../../../../components/BookedEditor/BookedEditor';
import LazyLoadImage from '../../../../../components/LazyLoadImage/LazyLoadImage';
import SvgIcon from '../../../../../components/SvgIcon/SvgIcon';
import CallButton from '../../../../../components/CallButton/CallButton';

const ParsedGirlItem = ({ updateActiveContact, item, ...props }) => {
  const [isProfileNotFoundError, setProfileNotFoundError] = useState(false);
  const [isConfirmOnClose, toggleConfirmOnClose] = useToggle(false);
  const [isBooking, toggleBooking] = useToggle(false);

  const bookingEditorRef = useRef();
  const bookingEditorBtnRef = useRef();
  const bookingListItemRef = useRef(); 

  let history = useHistory();

  useEffect(() => {
    if (props.toggleConfirmOnClose) {
      props.toggleConfirmOnClose(isConfirmOnClose);
    }
  }, [isConfirmOnClose])

  const handleBookedEditorClose = () => {
    return isConfirmOnClose && !window.confirm(
      'There are unsaved changes. Do you want to close the dialog anyway?'
    );
  }

  const createOrUpdateActiveSession = async () => {
    let divaProfileFromResponse = null;

    await API.getDivaGirlsByContactsIds(item.id)
      .then(res => {
        divaProfileFromResponse = res.data;
      })
      .catch(err => {
        console.log(err);

        setProfileNotFoundError(true);
      })

    if (!divaProfileFromResponse) {
      return console.log("I CAN'T CREATE SESSION - Profile not found");
    }

    if (props.sessionInfo.sessionId) {
      props.updateActiveSession(props.sessionInfo.sessionId);

      props.updateBufferedId(divaProfileFromResponse.id, props.sessionInfo.sessionId, props.sessionInfo.isMineSession);
    }
    else {
      props.createSession(props.activeRecipientId, [divaProfileFromResponse.id], [divaProfileFromResponse.id]);
    }
    history.push('/client/sales');
  }

  const openChatWithGirl = () => {
    updateActiveContact(item);

    props.closeModal();
  }

  const openGirlCard = () => {
    props.toggleConfirmOnClose(true)
    setTimeout(() => props.openModal(MODAL_TYPES.contactCard, { contact: item.id }), 600);
  };

  const onBookedEditorClose = () => {
    toggleBooking();
    props.closeModal();
  }

  const getItemStatusColor = () => {
    let styleValue = '';

    if(!item.availabilty) {
      styleValue = '#933131';
    }

    if(item.availability === 'available') {
      styleValue = '#44ef8e';
    }

    if(item.availability === 'off' || item.availability === 'off today') {
      styleValue = '#f8737f';
    }

    return styleValue;
  }

  const showContactName = () => {
    if (item.fn.startsWith('EX ')) {
      return <><span className="chat-header__ex">EX </span>{item.fn.slice(3)}</>
    }

    return item.fn;
  }

  return (
    <li className="parsed-girl-item__item" ref={bookingListItemRef}>
      <div
        onClick={openGirlCard}
        className="parsed-girl-item__img-wrap">
        <LazyLoadImage src={getContactAvatar(item)} />
      </div>

      <div className="parsed-girl-item__text-info-block">
        <div className="parsed-girl-item__text-info-top">
          <div className="parsed-girl-item__status-wrapper">
            <span className="parsed-girl-item__status-point" style={{ backgroundColor: getItemStatusColor() }}></span>
          </div>
          <span
            onClick={openGirlCard}
            className="parsed-girl-item__name">
            {showContactName()}
          </span>

          <div className="parsed-girl-item__btn-wrap">

            {item.diva_default_id && <div 
              className="parsed-girl-item__booking-btn-wrap" 
              ref={bookingEditorBtnRef}
              onClick={() => toggleBooking(!isBooking)}
              >
              <FontAwesomeIcon
                className={`${"contact-item__icon"} booking-icon`}
                icon={faBootstrap}
                color='#44ef8e'
                title='booking'
                data-booking-icon={true}
              />
            </div>}

            {item.tels.length
              ? <div className="parsed-girl-item__btn">
                <CallButton
                  className="parsed-girl-item__btn"
                  activeRecipient={item} />
              </div>
              : null
            }

            <button
              onClick={openChatWithGirl}
              className="parsed-girl-item__btn">
              <SvgIcon width={16} height={16} fill="#0092F2" icon="messages" title="message" />
            </button>
            {/* TODO: rewrite  selectSessionByContactId selector*/}
            {/* if session doesn't exist || session exist && session is mine*/}
            {(!props.sessionInfo.sessionId || (props.sessionInfo.sessionId && props.sessionInfo.isMineSession))
              && item.type === CONTACT_TYPES.GIRL
              ? <button
                onClick={createOrUpdateActiveSession}
                disabled={isProfileNotFoundError}
                className="parsed-girl-item__btn"
              >
                <FontAwesomeIcon
                  icon={faPuzzlePiece}
                  size="lg"
                  title={isProfileNotFoundError ? 'Profile not found' : 'Start session'}
                />
              </button>
              : null
            }
          </div>
        </div>
        {item.detailsForPopUp && <div className="parsed-girl-item__text-info-bottom">
          <span>￡{item.detailsForPopUp[0]}</span>
          <span>{item.detailsForPopUp[1]}</span>
        </div>}

        {isBooking &&
          <div ref={bookingEditorRef}>
            <DropWrapper
              dropWrapperRef={bookingEditorRef}
              closeDropWrapper={toggleBooking}
              onClose={handleBookedEditorClose}
            >
              <BookedEditor
                parentRef={bookingEditorBtnRef}
                profileId={item.diva_default_id}
                handleCancel={toggleBooking}
                changeEditMode={toggleBooking}
                handleClose={onBookedEditorClose}
                isEditingMode
                isFromHistory
                isAutoPosition
                isChat
                toggleConfirmOnClose={toggleConfirmOnClose}
                isParsedGirlsListModal={props.isParsedGirlsListModal}
              />
            </DropWrapper>
          </div>
        }
      </div>
    </li>
  )
};

const mapStateToProps = (state, ownProps) => ({
  sessionInfo: selectSessionByContactId(state, ownProps.activeRecipientId),
  userTimezone: selectUserTimezone(state),
});

const mapDispatchToProps = {
  createSession,
  updateActiveSession,
  updateBufferedId,
  openModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ParsedGirlItem);
