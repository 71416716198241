import React, { Component, Fragment } from 'react';
import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeList as List } from 'react-window';

import { CONTACTS_LIMIT } from '../../../../ducks/contacts';

import './ChatList.scss';
import ChatListWrapper from './ChatListWrapper';
import Spinner from '../../../../components/UI/Spinner/Spinner';


class ChatList extends Component {
  state = {
    contextMenu: null
  }

  key = -1;
  limit = CONTACTS_LIMIT;

  listRef = React.createRef();

  CustomScrollbarsVirtualList = React.forwardRef((props, ref) => (
    <ChatListWrapper
      {...props}
      forwardedRef={ref}
      customOnScroll={this.handleScroll} />
  ));

  componentDidMount() {
    // if we change contactType
    if (this.props.list.length > this.limit) {
      this.limit = this.props.list.length;
    }
  }

  componentDidUpdate(prevProps) {
    // if we already have more msgs then LIMIT
    if (prevProps.search !== this.props.search || prevProps.filter !== this.props.filter) {
      this.limit = this.props.list.length <= CONTACTS_LIMIT
        ? CONTACTS_LIMIT
        : this.props.list.length;

      if (this.listRef.current) {
        this.listRef.current.scrollTo(0);
      }
      return;
    }
    // if we add new contact
    if (prevProps.list.length + 1 === this.props.list.length && prevProps.list.length < this.props.list.length) {
      return this.limit += 1;
    }
    // if we remove contact
    if (prevProps.list.length - 1 === this.props.list.length) {
      return this.limit -= 1;
    }
  }

  handleScroll = ({ target: { scrollTop, scrollHeight, clientHeight } }) => {
    // part for getting contact by scrolling down 
    const bottomScroll = scrollHeight - scrollTop - clientHeight;
    // console.log(bottomScroll);

    if (this.state.contextMenu) {
      this.closeContextMenu();
    }

    if (bottomScroll < 400 && this.limit === this.props.list.length) {
      this.limit += CONTACTS_LIMIT;
      this.props.updateList(this.props.type, this.props.list.length, this.props.search);
    }
  };

  toggleDescription = (isDescriptionOpen, index) => {
    // if item already opened => close
    if (isDescriptionOpen) {
      console.log('close');
      this.listRef.current.resetAfterIndex(index);
      this.key = -1;
    }
    // if item closed => open
    // if previous opened => close previous
    else {
      console.log('open');
      this.listRef.current.resetAfterIndex(0);
      this.key = index;
    }
  }

  renderContextMenu = () => {
    const ContextMenu = this.props.contextMenu;

    return this.state.contextMenu &&
      <ContextMenu
        closeContextMenu={this.closeContextMenu}
        items={this.state.contextMenu.items}
        coords={this.state.contextMenu.coords} />;
  }


  openContextMenu = (coords, contact) => {
    const { contextMenu } = this.state;

    if (contextMenu && coords[0] === contextMenu.coords[0] && coords[1] === contextMenu.coords[1]) {
      return;
    }

    this.setState({
      contextMenu: {
        coords,
        items: this.props.generateContextItems(contact)
      }
    });
  }

  closeContextMenu = () => {
    this.setState({ contextMenu: null });
  }

  renderListItem = ({ index, style }) => {
    const ChatItem = this.props.chatListItem;

    return (
      <ChatItem
        currentIndex={index}
        isContactOpen={index === this.key}
        // style={{
        //   ...style,
        //   height: style.height - 5

        // }}
        style={style}
        type={this.props.type}
        id={this.props.list[index]}
        toggleDescription={this.toggleDescription}
        // showDescription={this.showDescription}
        isContextMenu={this.props.contextMenu}
        openContextMenu={this.openContextMenu}

        search={this.props.search}
        searchSource={this.props.searchSource}
      />
    );
  };

  render() {
    console.log('render ChatList');

    if (this.props.pending) {
      return (
        <div className="contacts__list--empty">
          <Spinner spinnerSize={30} />
        </div>
      );
    }
    else if (!this.props.list.length) {
      return (
        <div className="contacts__list--empty">
          (no items)
        </div>
      );
    }

    const rowHeight = index => {
      if (index === this.key) {
        return 46 + 46;
      }
      return 46;
    };
    // const rowHeight = index => {
    //   if (index === this.key) {
    //     return 48 + 48;
    //   }
    //   return 48;
    // };

    return (
      <Fragment>
        <AutoSizer>
          {({ width, height }) => (
            <List
              itemCount={this.props.list.length}
              itemSize={rowHeight}
              outerElementType={this.CustomScrollbarsVirtualList}
              ref={this.listRef}
              height={height}
              width={width} >

              {this.renderListItem}

            </List>
          )}
        </AutoSizer>

        {this.props.contextMenu && this.renderContextMenu()}
      </Fragment>
    );
  }
}

export default ChatList;