import React, { useState } from 'react';
// import PropTypes from 'prop-types';

import DropdownMenu from '../components/DropdownMenu/DropdownMenu';

const OPENED = 'opened';
const CLOSED = 'closed';

const withDropdownMenu = WrappedComponent => {
  const HOC = ({ menuItems, menuHeader, menuPosition = '', onMenuToggle, ...props }) => {
    const [componentId] = useState('id-' + Math.random().toString(32).slice(2));
    const [submenuStatus, setSubmenuStatus] = useState(CLOSED);
    const [submenuStyle, setSubmenuStyle] = useState({});

    const submenuToggle = e => {
      const newStatus = submenuStatus === OPENED ? CLOSED : OPENED;
      const menuHeight = menuItems.length * 39 + 20;

      setSubmenuStatus(newStatus);

      const body = document.body;
      let viewPort = e.target;

      while (viewPort !== body) {
        viewPort = viewPort.parentNode;

        const style = getComputedStyle(viewPort);

        if (style.transform !== 'none' || style.perspective !== 'none') {
          break;
        }
      }

      const top = e.target.getBoundingClientRect().bottom - viewPort.getBoundingClientRect().top;
      let heightFromMenuToBottom = viewPort.getBoundingClientRect().height - top;
      let isTopDirection = heightFromMenuToBottom < menuHeight + 10 && menuHeight + 26 < top;

      if (props.isRelativeToBody) {
        const heightFromMenuToBottomRelativeToBody = body.getBoundingClientRect().height
          - e.target.getBoundingClientRect().bottom
          - window.scrollY
          - (viewPort.getBoundingClientRect().top > 0 ? viewPort.getBoundingClientRect().top : 0);

        isTopDirection = heightFromMenuToBottomRelativeToBody < menuHeight + 10 && menuHeight + 26 < top;
      }

      if (menuPosition.includes('fixed') && body.contains(e.target)) {

        if (menuPosition === 'fixed-center') {
          const left = e.target.getBoundingClientRect().left - viewPort.getBoundingClientRect().left;
          setSubmenuStyle(isTopDirection
            ? { top: top - menuHeight - 26, left: left - 100 }
            : { top: top + 10, left: left - 100 }
          );
        }
        else {
          setSubmenuStyle(isTopDirection
            ? { top: top - menuHeight - 26 }
            : { top: top + 10 }
          );
        }

      }
      else if (isTopDirection) {
        setSubmenuStyle({ top: -menuHeight });
      }

      if (onMenuToggle) {
        console.log('new status', newStatus)
        onMenuToggle(newStatus);
      }
    };

    const handleClick = e => {
      if (e.target.closest('.' + componentId)
        && !~e.target.classList.value.indexOf('dropdown-menu__item-btn')) {
        return;
      }

      submenuToggle(e);
    };

    const componentProps = {
      ...props,
      submenuToggle,
      submenuStatus,
    };

    return (
      <div className={componentId} style={{ position: 'relative' }}>
        <WrappedComponent {...componentProps} />

        {submenuStatus === OPENED
          && <DropdownMenu
            position={menuPosition}
            closeDropdownMenu={handleClick}
            header={menuHeader}
            items={menuItems}
            style={submenuStyle}
          />
        }
      </div>
    );
  };

  // HOC.propTypes = {
  //   menuItems: PropTypes.arrayOf(PropTypes.shape({
  //     name: PropTypes.string.isRequired,
  //     action: PropTypes.func,
  //     submenu: PropTypes.elementType,
  //     icon: PropTypes.string,
  //     iconPosition: PropTypes.oneOf(['right']),
  //     onMouseOver: PropTypes.func,
  //   })).isRequired,
  //   menuHeader: PropTypes.elementType,
  //   menuPosition: PropTypes.oneOf(['right', 'center', 'left', 'fixed', 'fixed-right', '', 'top']),
  // };

  const getDisplayName = WrappedComponent => {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
  };

  HOC.displayName = `withDropdownMenu(${getDisplayName(WrappedComponent)})`;

  return React.memo(HOC);
};

export default withDropdownMenu;
