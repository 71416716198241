import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

const AttachmentEmail = () => {
  return (
    <div className="attachment-email">
      <div className="attachment-email__mail-icon">
        <FontAwesomeIcon
          size="xm"
          color="#fff"
          icon={faEnvelope}
        />
      </div>

      <div className="attachment-email__text">
        New email
      </div>
    </div>
  )
}

export default AttachmentEmail;
