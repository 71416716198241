import React, { Fragment } from 'react';

import IncomingMessage from './interactions/IncomingMessage';
import OutgoingMessage from './interactions/OutgoingMessage';
import IncomingCall from './interactions/IncomingCall';
import OutgoingCall from './interactions/OutgoingCall';
import IncomingGirlsChatMessage from './interactions/IncomingGirlsChatMessage';
import RemindMessage from './interactions/RemindMessage';
import SystemMsg from './interactions/SystemMsg';
import DateIndicator from './DateIndicator/DateIndicator';
import UnreadIndicator from './UnreadIndicator';
import Spinner from '../../../../components/UI/Spinner/Spinner';
import { getDateByTimezoneOffset } from '../../../../utils';


const InteractionsList = (props) => {
  const {
    search,
    activeRecipient,
    timeline,
    unreadRef,
    profileId,
    contextMsgId,
    contextMsgRef,
    getMessageContext,
    shareMsg,
  } = props;

  let firstUnread = false;

  const renderInteractions = (interaction, unreadRef) => {
    if (!firstUnread && interaction.hasOwnProperty('isRead') && unreadRef && !interaction.isRead) {
      firstUnread = interaction.id;
    }

    if (activeRecipient.id === 'girls') {
      return <IncomingGirlsChatMessage
        key={interaction.id}
        interaction={interaction}
        activeRecipient={activeRecipient}
        updateActiveContact={props.updateActiveContact}
        profileId={profileId}
        userTimezone={props.userTimezone}
        userHour12={props.userHour12}
      />
    }

    switch (interaction.type) {
      case 1:
        return (
          <Fragment key={interaction.id}>
            {interaction.id === firstUnread && <UnreadIndicator ref={unreadRef} />}

            <IncomingCall
              interaction={interaction}
              profileId={profileId}
              activeRecipient={activeRecipient}
              userTimezone={props.userTimezone}
              contextMsgId={contextMsgId}
              contextMsgRef={contextMsgRef}
              userHour12={props.userHour12}
            />
          </Fragment>
        );
      case 2:
        return (
          <OutgoingCall
            interaction={interaction}
            activeRecipient={activeRecipient}
            profileId={profileId}
            key={interaction.id}
            userTimezone={props.userTimezone}
            userHour12={props.userHour12}
          />
        );
      case 3:
      case 10:
      case 14:
        return (
          <OutgoingMessage
            editMsg={props.editMsg}
            interaction={interaction}
            activeRecipient={activeRecipient}
            search={search}
            profileId={profileId}
            key={interaction.id}
            contextMsgId={contextMsgId}
            contextMsgRef={contextMsgRef}
            shareMsg={shareMsg}
            replyMsg={props.replyMsg}
            isGlobalSearch={props.isGlobalSearch}
            getMessageContext={getMessageContext}
            userTimezone={props.userTimezone}
            userHour12={props.userHour12}
            addNewArrayGirlsToState={props.addNewArrayGirlsToState}
            updateActiveContact={props.updateActiveContact}
          />
        );
      case 4:
      case 9:
      case 13: {
        return (
          <Fragment key={interaction.id}>
            {interaction.id === firstUnread && <UnreadIndicator ref={unreadRef} />}

            <IncomingMessage
              interaction={interaction}
              isWebmasterMsg={!!interaction.webMasterTask}
              search={search}
              activeRecipient={activeRecipient}
              profileId={profileId}
              contextMsgId={contextMsgId}
              contextMsgRef={contextMsgRef}
              shareMsg={shareMsg}
              replyMsg={props.replyMsg}
              addNewArrayGirlsToState={props.addNewArrayGirlsToState}
              isGlobalSearch={props.isGlobalSearch}
              updateActiveContact={props.updateActiveContact}
              getMessageContext={getMessageContext}
              userTimezone={props.userTimezone}
              userHour12={props.userHour12}
            />
          </Fragment>
        );
      }
      case 7:
        return (
          <Fragment key={interaction.id}>
            {/* {interaction.id === firstUnread && <UnreadIndicator ref={unreadRef} />} */}

            <RemindMessage
              getMessageContext={getMessageContext}
              interaction={interaction}
              removeMessageReminder={props.removeMessageReminder}
              setActiveChatSource={props.setActiveChatSource}
              profileId={profileId}
              userTimezone={props.userTimezone}
              isMainTimelineOpen={props.isMainTimelineOpen}
            >
              {renderInteractions(interaction.interaction)}
            </RemindMessage>
          </Fragment>
        );
      case 8:
        return (
          <SystemMsg
            interaction={interaction}
            key={interaction.id}
            userTimezone={props.userTimezone}
            userHour12={props.userHour12}
            activeRecipient={activeRecipient}
          />
        );
      case 11:
        return (
          <OutgoingMessage
            interaction={interaction}
            key={interaction.id}
            userTimezone={props.userTimezone}
            userHour12={props.userHour12}
            editMsg={props.editMsg}
            openModal={props.openModal}
            addNewArrayGirlsToState={props.addNewArrayGirlsToState}
            profileId={profileId}
            updateActiveContact={props.updateActiveContact}
          />
        );
      case 12:
        return (
          <SystemMsg
            interaction={interaction}
            key={interaction.id}
            userTimezone={props.userTimezone}
            userHour12={props.userHour12}
            activeRecipient={activeRecipient}
          />
        );
      default:
        return (
          <div key={interaction.id}>Unknown interaction</div>
        );
    }
  }

  if (props.timelinePending) {
    return (
      <div className="timeline__load-wrap">
        <Spinner spinnerSize={30} />
      </div>
    )
  }
  else if (!timeline.length) {
    return (
      <div className="timeline__load-wrap">
        <span>(no items)</span>

        {!props.publicChat && search && props.startGlobalMsgSearch && !props.isGlobalSearch &&
          <button
            onClick={() => props.startGlobalMsgSearch(search, activeRecipient)}
            className="timeline__btn--global-search">
            Try global search
          </button>
        }
      </div>
    )
  }

  return (
    <ul className="timeline__list" >
      {timeline.map(group => {

        let dateByInteractionType = 'dateCreated';

        if (group[0].type === 7) {
          dateByInteractionType = 'remind_at';
        }
        else if (group[0].type === 11) {
          dateByInteractionType = 'date';
        }


        const groupDateByTimezone = getDateByTimezoneOffset(
          props.userTimezone,
          group[0][dateByInteractionType]
        );

        return (
          <div className="timeline__group" key={groupDateByTimezone}>

            {group[0].type !== 11 &&
              <DateIndicator
                groupDate={groupDateByTimezone}
                showTimePickerForDateContext={props.showTimePickerForDateContext}
                chatType={props.type}
                contextDate={props.contextDate}
                contextDateRef={props.contextDateRef}
                userTimezone={props.userTimezone}
              />
            }

            {group.map(interaction => renderInteractions(interaction, unreadRef))}
          </div>
        )
      }
      )}
    </ul>
  )
}



export default InteractionsList;
